import {
  getDefaultComboComponentChoice,
  ComboComponentChoicePricingService,
  type KioskCombo,
  type KioskComboComponent,
  type KioskComboComponentChoice,
  type IComboComponentChoicePricingService,
  isComboSoldOut,
  KioskTranslator,
  type KioskComboComponentChoiceWithoutItem,
  type KioskComboComponentReduced,
  type KioskComboReduced,
} from '../../models';
import type { OrderComboSelection } from '../../../generated/kioskOrder_pb';
import type { PromotionType } from '../loyalty/loyalty_machine';
import { getOrderDetailsForItemService, item_service, type ItemService } from './item_service';
import {
  derived,
  get,
  readable,
  writable,
  type Readable,
  type Unsubscriber,
  type Writable,
} from 'svelte/store';
import { nanoid } from 'nanoid';
import type { ItemServiceStoreState } from './types';
import type { CartStoreState, IMediaStateManager, IPricesStateManager } from '../../state-managers';
import { isComboLimitReached, isOrderLimitReached } from './utils';
import { assertExists } from '../../services/utils';
import { eighty_six_service } from '../eighty_six/eighty_six_service';
import type { IMenuStorageService } from '../../storage-services/menu_service';

type ComboServiceCopyDetails = {
  order_details: OrderComboSelection | null;
  load_defaults: boolean;
};

export type ComboServiceChoiceState = {
  choice: KioskComboComponentChoice;
  itemService: ItemService;
  choicePricing: IComboComponentChoicePricingService;
};

export type ComboServiceChoices = Map<string, ComboServiceChoiceState>;

export type ComboInternalStoreState = {
  quantity: number;
  addedToOrderTimestamp?: number;
  cartId?: string;
  choices: ComboServiceChoices;
  comboLimitReached: boolean;
  orderLimitReached: boolean;
};

function initChoiceStores(
  mediaStateManager: IMediaStateManager,
  components: KioskComboComponent[],
  quantityLimitPerItem: number,
  quantityLimitPerOrder: number,
  cartStore: Readable<CartStoreState>,
  pricesStateManager: IPricesStateManager,
  menuStorageService: IMenuStorageService,
  startingItem?: ItemService
): ComboServiceChoices {
  const choiceStores: ComboServiceChoices = new Map();
  components.forEach(async (component) => {
    const pricingService = new ComboComponentChoicePricingService(component, pricesStateManager);
    const item = menuStorageService.getCachedItem(component.choices[0].item.menuItemId)!;
    if (component.choices.length === 1) {
      const thisItemService = item_service({
        mediaStateManager,
        pricesStateManager,
        item: item,
        load_defaults: true,
        sourceCategory: null,
        order_details: null,
        quantityLimitPerItem,
        quantityLimitPerOrder,
        cartStore,
        overridedStartingPrice: pricingService.getCalculationPrice(component.choices[0]),
      });
      choiceStores.set(component.id, {
        choice: {
          ...component.choices[0],
          item: item,
        },
        itemService: startingItem ?? thisItemService,
        choicePricing: pricingService,
      });
    } else {
      const defaultChoice = getDefaultComboComponentChoice(component);
      if (!defaultChoice) {
        return;
      }
      const defaultItem = menuStorageService.getCachedItem(defaultChoice.item.menuItemId)!;
      const thisItemService = item_service({
        mediaStateManager,
        pricesStateManager,
        item: defaultItem,
        load_defaults: true,
        sourceCategory: null,
        order_details: null,
        quantityLimitPerItem,
        quantityLimitPerOrder,
        cartStore,
        overridedStartingPrice: pricingService.getCalculationPrice(defaultChoice),
      });

      choiceStores.set(component.id, {
        choice: { ...defaultChoice, item: defaultItem },
        itemService: startingItem ?? thisItemService,
        choicePricing: pricingService,
      });
    }
  });

  return choiceStores;
}

const initFromExistingOrderDetails = (
  mediaStateManager: IMediaStateManager,
  components: KioskComboComponent[],
  orderDetails: OrderComboSelection,
  quantityLimitPerItem: number,
  quantityLimitPerOrder: number,
  cartStoreState: Readable<CartStoreState>,
  pricesStateManager: IPricesStateManager,
  menuStorageService: IMenuStorageService
): ComboServiceChoices => {
  const orderComboSelectionById = new Map(
    orderDetails.components.map((x) => [x.comboComponentId, x])
  );
  const choiceStores: ComboServiceChoices = new Map();

  components.forEach((component) => {
    const pricingService = new ComboComponentChoicePricingService(component, pricesStateManager);
    const selectionForComponent = orderComboSelectionById.get(component.id);
    const itemMatchingSelection = component.choices.find(
      (choice) => choice.item.menuItemId === selectionForComponent?.itemSelection?.menuItemId
    );

    const defaultChoice = itemMatchingSelection ?? getDefaultComboComponentChoice(component);
    if (!defaultChoice) {
      return;
    }
    const defaultItem = menuStorageService.getCachedItem(defaultChoice.item.menuItemId)!;
    const itemOrderDetails =
      itemMatchingSelection && selectionForComponent
        ? (selectionForComponent.itemSelection ?? null)
        : null;

    const thisItemService = item_service({
      mediaStateManager,
      pricesStateManager,
      item: defaultItem,
      load_defaults: !itemOrderDetails,
      sourceCategory: null,
      order_details: itemOrderDetails,
      quantityLimitPerItem,
      quantityLimitPerOrder,
      cartStore: cartStoreState,
      overridedStartingPrice: pricingService.getCalculationPrice(defaultChoice),
    });
    choiceStores.set(component.id, {
      itemService: thisItemService,
      choice: defaultChoice,
      choicePricing: pricingService,
    });
  });

  return choiceStores;
};

export type ComboPriceStoreState = {
  unitPrice: number;
  totalPrice: number;
  comboPrice: number;
};

export type ModifierSelectionStoreState = {
  canAddToCart: boolean;
};

export type ComboStoreState = {
  isInCart: boolean;
  currentChoices: Map<string, ComboServiceChoiceState>;
  quantity: number;
  comboLimitReached: boolean;
  orderLimitReached: boolean;
  isOutOfStock: boolean;
};

export class ComboStateManager {
  private comboStore: Writable<KioskCombo>;

  constructor(
    private combo: KioskComboReduced,
    private mediaStateManager: IMediaStateManager,
    private pricesStateManager: IPricesStateManager,
    private itemCacheManager: IMenuStorageService
  ) {
    const populatedCombo = this.populateCombo(this.combo);
    this.comboStore = writable(populatedCombo);
  }

  private populateCombo(combo: KioskComboReduced): KioskCombo {
    const populatedComponents = combo.components.map((component) =>
      this.populateComponent(component)
    );
    return { ...combo, components: populatedComponents };
  }

  private populateComponent(component: KioskComboComponentReduced): KioskComboComponent {
    const populatedChoices = component.choices.map((choice) => this.populateChoice(choice));
    return { ...component, choices: populatedChoices };
  }

  private populateChoice(choice: KioskComboComponentChoiceWithoutItem) {
    const fullItem = this.itemCacheManager.getCachedItem(choice.itemId)!;
    return {
      ...choice,
      item: fullItem,
    };
  }

  getComboStore(): Readable<KioskCombo> {
    return { subscribe: this.comboStore.subscribe };
  }
}

export class ComboSelectionStateManager {
  combo: KioskComboReduced;
  uid: string | undefined;
  addedToOrderTimestamp?: number;
  selPromotionalOption: PromotionType;
  rewardId: string | undefined;
  private internalStore: Writable<ComboInternalStoreState>;

  private itemStateSubscriptions: Readable<ItemServiceStoreState[]>;
  private comboComponentsById: Map<string, KioskComboComponent>;

  orderDetailsStore: Readable<OrderComboSelection>;
  priceStore: Readable<ComboPriceStoreState>;
  store: Readable<ComboStoreState>;
  comboStore: Readable<KioskCombo>;
  modifierStore: Readable<ModifierSelectionStoreState>;

  constructor(
    private comboStateManager: ComboStateManager,
    private mediaStateManager: IMediaStateManager,
    private pricesStateManager: IPricesStateManager,
    private menuStorageService: IMenuStorageService,
    combo: KioskComboReduced,
    selPromotionalOption: PromotionType,
    copy_details: ComboServiceCopyDetails,
    private quantityLimitPerItem: number,
    private quantityLimitPerOrder: number,
    private cartStoreState: Readable<CartStoreState>,
    uid?: string | undefined,
    rewardId?: string | undefined,
    private startingItem?: ItemService
  ) {
    this.combo = combo;
    this.selPromotionalOption = selPromotionalOption;
    this.uid = uid;
    this.rewardId = rewardId ?? undefined;

    this.comboStore = comboStateManager.getComboStore();
    this.comboComponentsById = new Map(
      get(this.comboStore).components.map((component) => [component.id, component])
    );

    const shouldCopyDefaults = !copy_details.load_defaults;

    this.internalStore = writable({
      cartId: uid,
      quantity:
        shouldCopyDefaults && copy_details?.order_details ? copy_details.order_details.quantity : 1,
      choices:
        shouldCopyDefaults && copy_details?.order_details
          ? initFromExistingOrderDetails(
              mediaStateManager,
              get(this.comboStore).components,
              copy_details.order_details,
              quantityLimitPerItem,
              quantityLimitPerOrder,
              this.cartStoreState,
              this.pricesStateManager,
              this.menuStorageService
            )
          : initChoiceStores(
              mediaStateManager,
              get(this.comboStore).components,
              quantityLimitPerItem,
              quantityLimitPerOrder,
              this.cartStoreState,
              this.pricesStateManager,
              this.menuStorageService,
              this.startingItem
            ),
      comboLimitReached: false,
      orderLimitReached: false,
    });

    this.itemStateSubscriptions = readable([], this.createItemStateSubscriptions);
    this.priceStore = derived(
      [this.itemStateSubscriptions, this.internalStore],
      this.getPriceStore
    );
    this.orderDetailsStore = derived(
      [this.priceStore, this.internalStore],
      this.getOrderDetailsFromStore
    );

    if (!(shouldCopyDefaults && copy_details?.order_details)) {
      this.internalStore.update((state) => ({
        ...state,
        choices: this.initializeChoices(get(this.comboStore).components, startingItem),
      }));
    }

    this.store = derived(
      [this.internalStore, this.comboStore, eighty_six_service.store],
      this.getStoreState
    );

    this.modifierStore = derived([this.itemStateSubscriptions], this.canAddComboToCart);
  }

  private initializeChoices(
    components: KioskComboComponent[],
    startingItem?: ItemService
  ): ComboServiceChoices {
    const choiceStores: ComboServiceChoices = new Map();
    components.forEach((component) => {
      const pricingService = new ComboComponentChoicePricingService(
        component,
        this.pricesStateManager
      );
      const defaultChoice = component.choices[0] || getDefaultComboComponentChoice(component);
      const defaultItem = this.menuStorageService.getCachedItem(defaultChoice.item.menuItemId)!;
      if (defaultChoice) {
        const thisItemService = item_service({
          mediaStateManager: this.mediaStateManager,
          pricesStateManager: this.pricesStateManager,
          item: defaultItem,
          load_defaults: true,
          sourceCategory: null,
          order_details: null,
          quantityLimitPerItem: this.quantityLimitPerItem,
          quantityLimitPerOrder: this.quantityLimitPerOrder,
          cartStore: this.cartStoreState,
          overridedStartingPrice: pricingService.getCalculationPrice(defaultChoice),
        });
        choiceStores.set(component.id, {
          itemService:
            startingItem?.item.menuItemId === thisItemService?.item.menuItemId
              ? startingItem
              : thisItemService,
          choice: { ...defaultChoice, item: defaultItem },
          choicePricing: pricingService,
        });
      }
    });
    return choiceStores;
  }

  private createItemStateSubscriptions = (set: (state: ItemServiceStoreState[]) => void) => {
    let currentChoiceSub: Unsubscriber;

    const sub = this.internalStore.subscribe((state) => {
      if (currentChoiceSub) {
        currentChoiceSub();
      }
      const currentChoices = state.choices;
      const currentChoiceStores = derived(
        [...currentChoices.values()].map((x) => x.itemService),
        (itemServices) => {
          return itemServices;
        }
      );

      currentChoiceSub = currentChoiceStores.subscribe(set);
    });

    return () => {
      sub();
      if (currentChoiceSub) {
        currentChoiceSub();
      }
    };
  };

  private getPriceStore = ([itemStates, state]: [
    ItemServiceStoreState[],
    ComboInternalStoreState,
  ]): ComboPriceStoreState => {
    const currentSelectionPrices = itemStates.map((state) => {
      return state.display.price;
    });

    const startingSelectionPrices = itemStates.map((state) => {
      return state.display.starting_price;
    });

    const sum = currentSelectionPrices.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    const startingPrice = startingSelectionPrices.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    return {
      unitPrice: sum,
      totalPrice: sum * state.quantity,
      comboPrice: startingPrice,
    };
  };

  private getOrderDetailsFromStore = ([priceState, state]: [
    ComboPriceStoreState,
    ComboInternalStoreState,
  ]): OrderComboSelection => {
    const result = {
      itemSessionId: this.uid || '',
      comboId: this.combo.id,
      name:
        KioskTranslator.getLocalizedValue(this.combo?.localizedDisplayName, 'en') ??
        this.combo.name,
      quantity: state.quantity,
      components: this.combo.components.map((component) => {
        const storeForComponent = state.choices.get(component.id)?.itemService;
        const stateForComponent = storeForComponent
          ? getOrderDetailsForItemService(storeForComponent)
          : undefined;

        return {
          comboComponentId: component.id,
          itemSelection: stateForComponent,
          name: storeForComponent?.item.name ?? '',
        };
      }),
      categoryId: '',
      categoryName: '',
      unitPrice: {
        value: priceState.unitPrice,
      },
      totalPrice: {
        value: priceState.totalPrice,
      },
      comboPrice: {
        value: priceState.comboPrice,
      },
      rewardSource: {
        rewardId: this.rewardId ? { value: this.rewardId } : undefined,
      },
    };
    return result;
  };

  private getStoreState = ([internalState, combo, soldOutItemIds]: [
    ComboInternalStoreState,
    KioskCombo,
    Set<string>,
  ]): ComboStoreState => {
    const orderLimitReached = isOrderLimitReached(
      this.quantityLimitPerOrder,
      get(this.cartStoreState),
      internalState.quantity
    );
    const comboQuantityLimitCheck = orderLimitReached
      ? true
      : isComboLimitReached(
          this.quantityLimitPerOrder,
          this.quantityLimitPerItem,
          get(this.cartStoreState),
          combo,
          internalState.quantity
        );

    return {
      currentChoices: internalState.choices,
      isInCart: !!internalState.cartId,
      quantity: internalState.quantity,
      comboLimitReached: comboQuantityLimitCheck,
      orderLimitReached,
      isOutOfStock: isComboSoldOut(this.combo, soldOutItemIds),
    };
  };

  private canAddComboToCart = ([itemStates]: [
    ItemServiceStoreState[],
  ]): ModifierSelectionStoreState => {
    const modInValidList = itemStates
      .map((state) => {
        return state.display.is_valid;
      })
      .filter((x) => !x);

    return { canAddToCart: modInValidList?.length <= 0 };
  };

  onSelectItem = (
    componentId: string,
    choice: KioskComboComponentChoice,
    comboComponents: KioskComboComponent[]
  ) => {
    const fullItem = this.menuStorageService.getCachedItem(choice.item.menuItemId);
    if (!fullItem) {
      console.error(`Item not found: ${choice.item.menuItemId}`);
      return;
    }

    const component = this.comboComponentsById.get(componentId);
    assertExists(component, 'Combo component not found');
    const pricingService = new ComboComponentChoicePricingService(
      component,
      this.pricesStateManager
    );

    const thisItemService = item_service({
      mediaStateManager: this.mediaStateManager,
      pricesStateManager: this.pricesStateManager,
      item: fullItem,
      load_defaults: true,
      sourceCategory: null,
      order_details: null,
      quantityLimitPerItem: this.quantityLimitPerItem,
      quantityLimitPerOrder: this.quantityLimitPerOrder,
      cartStore: this.cartStoreState,
      overridedStartingPrice: pricingService.getCalculationPrice(choice),
    });

    this.internalStore.update((state) => {
      const choiceStores: ComboServiceChoices = new Map();
      comboComponents.forEach((component) => {
        if (component.id === componentId) {
          choiceStores.set(componentId, {
            itemService: thisItemService,
            choice: { ...choice, item: fullItem },
            choicePricing: pricingService,
          });
        } else {
          const tempChoice = state.choices.get(component.id);
          if (tempChoice) {
            const storedChoice = {
              ...tempChoice,
              choice: { ...tempChoice.choice, item: fullItem, itemId: fullItem.menuItemId },
            };
            choiceStores.set(component.id, storedChoice);
          }
        }
      });
      state.choices = choiceStores;
      return state;
    });
  };

  getOrAssignUniqueIdForCart = () => {
    const currentUid = get(this.internalStore).cartId;
    if (!currentUid) {
      const uid = nanoid();
      this.internalStore.update((state) => {
        state.cartId = uid;
        state.addedToOrderTimestamp = Date.now();
        return state;
      });

      return uid;
    } else {
      return currentUid;
    }
  };

  getQuantity = () => {
    return get(this.internalStore).quantity;
  };

  getComboLimitReached = () => {
    return get(this.internalStore).comboLimitReached;
  };

  increaseQuantity = () => {
    this.internalStore.update((state) => {
      state.quantity += 1;
      return state;
    });
    // if (comboService && comboService.copy_details.order_details) {
    //   comboService.copy_details.order_details.quantity += 1;
    //   state.subtotal += comboService.combo.price;
    //   if (comboService.copy_details.order_details.totalPrice) {
    //     comboService.copy_details.order_details.totalPrice.value += comboService.combo.price;
    //   }
    //   state.cartCombos.set(cartComboId, comboService);
    // }
  };

  decreaseQuantity = () => {
    this.internalStore.update((state) => {
      state.quantity = Math.max(1, state.quantity - 1);
      state.comboLimitReached =
        this.quantityLimitPerItem === 0 ? false : this.quantityLimitPerItem <= state.quantity;
      return state;
    });
  };

  updateCartComboId = (uid) => {
    this.internalStore.update((state) => {
      if (!state.cartId) {
        state.cartId = uid;
        state.addedToOrderTimestamp = Date.now();
      }
      return state;
    });
  };
}

export const createDerivedStoreFromComboSelectionStore = (
  comboSelections: Map<string, ItemService>
) => {
  const comboComponentIds = [...comboSelections.keys()];
  return derived([...comboSelections.values()], (itemServices) => {
    return new Map(
      itemServices.map((itemService, index) => {
        return [comboComponentIds[index] as string, itemService] as const;
      })
    );
  });
};
