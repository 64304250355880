/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "kiosk.proto" (package "Kiosk", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { StringValue } from "./google/protobuf/wrappers_pb";
import { DayOfWeekVM } from "./common_pb";
import { BoolValue } from "./google/protobuf/wrappers_pb";
import { CardNetworkMapping } from "./common_pb";
import { DiscountsVM } from "./discount_pb";
import { UpsellConfiguration } from "./menu_pb";
import { MenuLayout } from "./menu_pb";
import { PhoneNumberVM } from "./common_pb";
import { Int32Value } from "./google/protobuf/wrappers_pb";
import { I18nDictionary } from "./i18n_pb";
import { ResponseStatus } from "./common_pb";
/**
 * @generated from protobuf message Kiosk.KioskRegistrationRequest
 */
export interface KioskRegistrationRequest {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
    /**
     * @generated from protobuf field: string appVersion = 2;
     */
    appVersion: string;
    /**
     * @generated from protobuf field: string deviceId = 3;
     */
    deviceId: string;
}
/**
 * @generated from protobuf message Kiosk.KioskRegistrationResponse
 */
export interface KioskRegistrationResponse {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: string refreshToken = 2;
     */
    refreshToken: string;
    /**
     * @generated from protobuf field: Kiosk.ResponseStatus status = 3;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message Kiosk.KioskAuthorizationRequest
 */
export interface KioskAuthorizationRequest {
    /**
     * @generated from protobuf field: string refreshToken = 1;
     */
    refreshToken: string;
}
/**
 * @generated from protobuf message Kiosk.KioskAuthorizationResponse
 */
export interface KioskAuthorizationResponse {
    /**
     * @generated from protobuf field: string accessToken = 1;
     */
    accessToken: string;
    /**
     * @generated from protobuf field: string refreshToken = 2;
     */
    refreshToken: string;
    /**
     * @generated from protobuf field: Kiosk.ResponseStatus status = 3;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message Kiosk.KioskDeregistrationRequest
 */
export interface KioskDeregistrationRequest {
    /**
     * @generated from protobuf field: string deviceId = 1;
     */
    deviceId: string;
    /**
     * @generated from protobuf field: string accessToken = 2;
     */
    accessToken: string;
}
/**
 * @generated from protobuf message Kiosk.KioskDeregistrationResponse
 */
export interface KioskDeregistrationResponse {
    /**
     * @generated from protobuf field: Kiosk.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message Kiosk.KioskAppearance
 */
export interface KioskAppearance {
    /**
     * @generated from protobuf field: Kiosk.KioskTemplate template = 1;
     */
    template?: KioskTemplate;
    /**
     * @generated from protobuf field: Kiosk.KioskColors colors = 2;
     */
    colors?: KioskColors;
    /**
     * @generated from protobuf field: Kiosk.KioskMedia media = 3;
     */
    media?: KioskMedia;
    /**
     * @generated from protobuf field: repeated Kiosk.I18nDictionary dictionaries = 4;
     */
    dictionaries: I18nDictionary[];
    /**
     * @generated from protobuf field: Kiosk.AnimationsVM animations = 5;
     */
    animations?: AnimationsVM;
    /**
     * @generated from protobuf field: Kiosk.LoyaltyColors LoyaltyColors = 6 [json_name = "LoyaltyColors"];
     */
    loyaltyColors?: LoyaltyColors;
    /**
     * @generated from protobuf field: Kiosk.MenuColors menuColors = 7;
     */
    menuColors?: MenuColors;
    /**
     * @generated from protobuf field: Kiosk.KioskStyleOptions styleOptions = 8;
     */
    styleOptions?: KioskStyleOptions;
    /**
     * @generated from protobuf field: Kiosk.FontMediaVM fonts = 9;
     */
    fonts?: FontMediaVM;
}
/**
 * @generated from protobuf message Kiosk.KioskStyleOptions
 */
export interface KioskStyleOptions {
    /**
     * @generated from protobuf field: Kiosk.KioskStyleOptions.ButtonStyle buttonStyle = 1;
     */
    buttonStyle: KioskStyleOptions_ButtonStyle;
    /**
     * @generated from protobuf field: Kiosk.KioskStyleOptions.CategoryHeaderDisplayModeStyle categoryHeaderDisplayMode = 2;
     */
    categoryHeaderDisplayMode: KioskStyleOptions_CategoryHeaderDisplayModeStyle;
    /**
     * @generated from protobuf field: bool showCategoryHighlightedColor = 3;
     */
    showCategoryHighlightedColor: boolean;
    /**
     * @generated from protobuf field: Kiosk.KioskStyleOptions.CategoryHeaderLogoDisplayMode categoryHeaderLogoDisplayMode = 4;
     */
    categoryHeaderLogoDisplayMode: KioskStyleOptions_CategoryHeaderLogoDisplayMode;
    /**
     * @generated from protobuf field: bool displayModifierGroupRestrictions = 5;
     */
    displayModifierGroupRestrictions: boolean;
    /**
     * @generated from protobuf field: bool allowUserToCollapseOrExpandModifierGroups = 6;
     */
    allowUserToCollapseOrExpandModifierGroups: boolean;
    /**
     * @generated from protobuf field: bool showDiscountCodeButton = 7;
     */
    showDiscountCodeButton: boolean;
    /**
     * @generated from protobuf field: bool orderReviewShowDefaultModifiers = 8;
     */
    orderReviewShowDefaultModifiers: boolean;
    /**
     * @generated from protobuf field: bool showModifierGroupNamesOrderReview = 9;
     */
    showModifierGroupNamesOrderReview: boolean;
    /**
     * @generated from protobuf field: bool showLoyaltyPointsOnHeader = 10;
     */
    showLoyaltyPointsOnHeader: boolean;
    /**
     * @generated from protobuf field: bool showAcceptedPaymentOptionsOnScreenSaver = 11;
     */
    showAcceptedPaymentOptionsOnScreenSaver: boolean;
    /**
     * @generated from protobuf field: Kiosk.KioskStyleAcceptedPaymentOptionsVM kioskStyleAcceptedPaymentOptions = 12;
     */
    kioskStyleAcceptedPaymentOptions?: KioskStyleAcceptedPaymentOptionsVM;
    /**
     * @generated from protobuf field: Kiosk.KioskStyleOptions.CategoryNamePostitionEnum categoryNamePostition = 13;
     */
    categoryNamePostition: KioskStyleOptions_CategoryNamePostitionEnum;
    /**
     * @deprecated
     * @generated from protobuf field: bool showStartAtOptionForItem = 14 [deprecated = true];
     */
    showStartAtOptionForItem: boolean;
}
/**
 * @generated from protobuf enum Kiosk.KioskStyleOptions.ButtonStyle
 */
export enum KioskStyleOptions_ButtonStyle {
    /**
     * @generated from protobuf enum value: Rounded = 0;
     */
    Rounded = 0,
    /**
     * @generated from protobuf enum value: Rectangle = 1;
     */
    Rectangle = 1
}
/**
 * @generated from protobuf enum Kiosk.KioskStyleOptions.CategoryHeaderDisplayModeStyle
 */
export enum KioskStyleOptions_CategoryHeaderDisplayModeStyle {
    /**
     * @generated from protobuf enum value: NameBelowHeader = 0;
     */
    NameBelowHeader = 0,
    /**
     * @generated from protobuf enum value: NameInHeader = 1;
     */
    NameInHeader = 1
}
/**
 * @generated from protobuf enum Kiosk.KioskStyleOptions.CategoryHeaderLogoDisplayMode
 */
export enum KioskStyleOptions_CategoryHeaderLogoDisplayMode {
    /**
     * @generated from protobuf enum value: Show = 0;
     */
    Show = 0,
    /**
     * @generated from protobuf enum value: NotShow = 1;
     */
    NotShow = 1
}
/**
 * @generated from protobuf enum Kiosk.KioskStyleOptions.CategoryNamePostitionEnum
 */
export enum KioskStyleOptions_CategoryNamePostitionEnum {
    /**
     * @generated from protobuf enum value: Center = 0;
     */
    Center = 0,
    /**
     * @generated from protobuf enum value: Left = 1;
     */
    Left = 1
}
/**
 * @generated from protobuf message Kiosk.KioskStyleAcceptedPaymentOptionsVM
 */
export interface KioskStyleAcceptedPaymentOptionsVM {
    /**
     * @generated from protobuf field: bool showCard = 1;
     */
    showCard: boolean;
    /**
     * @generated from protobuf field: bool showGooglePay = 2;
     */
    showGooglePay: boolean;
    /**
     * @generated from protobuf field: bool showApplePay = 3;
     */
    showApplePay: boolean;
    /**
     * @generated from protobuf field: bool showCash = 4;
     */
    showCash: boolean;
    /**
     * @generated from protobuf field: string acceptedPaymentText = 5;
     */
    acceptedPaymentText: string;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedAcceptedPaymentText = 6;
     */
    localizedAcceptedPaymentText?: LocalizedStringVM;
}
/**
 * @generated from protobuf message Kiosk.FontMediaVM
 */
export interface FontMediaVM {
    /**
     * @generated from protobuf field: string kioskFont = 1;
     */
    kioskFont: string;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value kioskFontCharacterSpacing = 2;
     */
    kioskFontCharacterSpacing?: Int32Value;
    /**
     * @generated from protobuf field: string kioskLightFont = 3;
     */
    kioskLightFont: string;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value kioskLightFontCharacterSpacing = 4;
     */
    kioskLightFontCharacterSpacing?: Int32Value;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value kioskLightFontScale = 5;
     */
    kioskLightFontScale?: Int32Value;
    /**
     * @generated from protobuf field: string kioskBoldFont = 6;
     */
    kioskBoldFont: string;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value kioskBoldFontCharacterSpacing = 7;
     */
    kioskBoldFontCharacterSpacing?: Int32Value;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value kioskBoldFontScale = 8;
     */
    kioskBoldFontScale?: Int32Value;
}
/**
 * @generated from protobuf message Kiosk.KioskTemplate
 */
export interface KioskTemplate {
    /**
     * @generated from protobuf field: string templateId = 1;
     */
    templateId: string;
}
/**
 * @generated from protobuf message Kiosk.AnimationsVM
 */
export interface AnimationsVM {
    /**
     * @generated from protobuf field: string loading = 1;
     */
    loading: string;
    /**
     * @generated from protobuf field: string orderTimeout = 2;
     */
    orderTimeout: string;
    /**
     * @generated from protobuf field: string scanQRCode = 3;
     */
    scanQRCode: string;
    /**
     * @generated from protobuf field: string calculatingOrder = 4;
     */
    calculatingOrder: string;
    /**
     * @generated from protobuf field: string payWithCard = 5;
     */
    payWithCard: string;
    /**
     * @generated from protobuf field: string paymentSuccess = 6;
     */
    paymentSuccess: string;
    /**
     * @generated from protobuf field: string paymentFail = 7;
     */
    paymentFail: string;
    /**
     * @generated from protobuf field: string storeIsClosed = 8;
     */
    storeIsClosed: string;
}
/**
 * @generated from protobuf message Kiosk.LoyaltyMediaVM
 */
export interface LoyaltyMediaVM {
    /**
     * @generated from protobuf field: string defaultProfileHeaderImage = 1;
     */
    defaultProfileHeaderImage: string;
    /**
     * @generated from protobuf field: string defaultTopCornerImage = 2;
     */
    defaultTopCornerImage: string;
    /**
     * @generated from protobuf field: string defaultRewardsBackgroundImage = 3;
     */
    defaultRewardsBackgroundImage: string;
    /**
     * @generated from protobuf field: string defaultRewardImage = 4;
     */
    defaultRewardImage: string;
    /**
     * @generated from protobuf field: string defaultProfileFooterImage = 5;
     */
    defaultProfileFooterImage: string;
    /**
     * @generated from protobuf field: string defaultCheckoutHeaderImage = 6;
     */
    defaultCheckoutHeaderImage: string;
    /**
     * @generated from protobuf field: string defaultSignInHeaderImage = 7;
     */
    defaultSignInHeaderImage: string;
    /**
     * @generated from protobuf field: string defaultLoyaltyPerksImage = 8;
     */
    defaultLoyaltyPerksImage: string;
    /**
     * @generated from protobuf field: string defaultLoyaltyInstructionsImage = 9;
     */
    defaultLoyaltyInstructionsImage: string;
    /**
     * @generated from protobuf field: string loyaltySignInButtonImage = 12;
     */
    loyaltySignInButtonImage: string;
    /**
     * @generated from protobuf field: string loyaltyWithPointsHeaderImage = 13;
     */
    loyaltyWithPointsHeaderImage: string;
    /**
     * @generated from protobuf field: string loyaltyPointsBackgroundImage = 14;
     */
    loyaltyPointsBackgroundImage: string;
}
/**
 * @generated from protobuf message Kiosk.DiscountMediaVM
 */
export interface DiscountMediaVM {
    /**
     * @generated from protobuf field: string defaultDiscountImage = 1;
     */
    defaultDiscountImage: string;
    /**
     * @generated from protobuf field: string rewardHeaderImage = 2;
     */
    rewardHeaderImage: string;
    /**
     * @generated from protobuf field: string discountHeaderImage = 3;
     */
    discountHeaderImage: string;
    /**
     * @generated from protobuf field: string discountAndRewardHeaderImage = 4;
     */
    discountAndRewardHeaderImage: string;
    /**
     * @generated from protobuf field: string discountCodeInMenuScreenButtonImage = 5;
     */
    discountCodeInMenuScreenButtonImage: string;
    /**
     * @generated from protobuf field: string discountCodeOrderReviewScreenButtonImage = 6;
     */
    discountCodeOrderReviewScreenButtonImage: string;
}
/**
 * @generated from protobuf message Kiosk.ItemMediaVM
 */
export interface ItemMediaVM {
    /**
     * @generated from protobuf field: string defaultItemImage = 1;
     */
    defaultItemImage: string;
    /**
     * @generated from protobuf field: string defaultRewardItemImage = 2;
     */
    defaultRewardItemImage: string;
    /**
     * @generated from protobuf field: string defaultPromoItemImage = 3;
     */
    defaultPromoItemImage: string;
}
/**
 * @generated from protobuf message Kiosk.OrderCheckoutMediaVM
 */
export interface OrderCheckoutMediaVM {
    /**
     * @generated from protobuf field: string confirmationHeaderImage = 1;
     */
    confirmationHeaderImage: string;
}
/**
 * @generated from protobuf message Kiosk.KioskMedia
 */
export interface KioskMedia {
    /**
     * @generated from protobuf field: repeated string screensavers = 1;
     */
    screensavers: string[];
    /**
     * @generated from protobuf field: string defaultCategoryHeaderImage = 2;
     */
    defaultCategoryHeaderImage: string;
    /**
     * @deprecated
     * @generated from protobuf field: string defaultLoyaltyRewardImage = 3 [deprecated = true];
     */
    defaultLoyaltyRewardImage: string;
    /**
     * @generated from protobuf field: Kiosk.LoyaltyMediaVM defaultLoyaltyMedia = 4;
     */
    defaultLoyaltyMedia?: LoyaltyMediaVM;
    /**
     * @generated from protobuf field: Kiosk.DiscountMediaVM discountMedia = 5;
     */
    discountMedia?: DiscountMediaVM;
    /**
     * @generated from protobuf field: Kiosk.OrderCheckoutMediaVM orderCheckoutMedia = 6;
     */
    orderCheckoutMedia?: OrderCheckoutMediaVM;
    /**
     * @generated from protobuf field: string headerLogoImage = 7;
     */
    headerLogoImage: string;
    /**
     * @generated from protobuf field: string itemDetailHeaderBackgroundImage = 8;
     */
    itemDetailHeaderBackgroundImage: string;
    /**
     * @generated from protobuf field: Kiosk.ItemMediaVM itemMedia = 9;
     */
    itemMedia?: ItemMediaVM;
}
/**
 * @generated from protobuf message Kiosk.KioskColors
 */
export interface KioskColors {
    /**
     * @generated from protobuf field: string primary = 1;
     */
    primary: string;
    /**
     * @generated from protobuf field: string secondary = 2;
     */
    secondary: string;
    /**
     * @generated from protobuf field: string tertiary = 3;
     */
    tertiary: string;
    /**
     * @generated from protobuf field: string background = 4;
     */
    background: string;
    /**
     * @generated from protobuf field: string surface = 5;
     */
    surface: string;
    /**
     * @generated from protobuf field: string error = 6;
     */
    error: string;
    /**
     * @generated from protobuf field: string onPrimary = 7;
     */
    onPrimary: string;
    /**
     * @generated from protobuf field: string onSecondary = 8;
     */
    onSecondary: string;
    /**
     * @generated from protobuf field: string onTertiary = 9;
     */
    onTertiary: string;
    /**
     * @generated from protobuf field: string onBackground = 10;
     */
    onBackground: string;
    /**
     * @generated from protobuf field: string onSurface = 11;
     */
    onSurface: string;
    /**
     * @generated from protobuf field: string onError = 12;
     */
    onError: string;
}
/**
 * @generated from protobuf message Kiosk.MenuColors
 */
export interface MenuColors {
    /**
     * @generated from protobuf field: string modifierGroupPositive = 1;
     */
    modifierGroupPositive: string;
    /**
     * @generated from protobuf field: string modifierGroupNegative = 2;
     */
    modifierGroupNegative: string;
    /**
     * @generated from protobuf field: string itemHeaderBackground = 3;
     */
    itemHeaderBackground: string;
    /**
     * @generated from protobuf field: string itemHeaderText = 4;
     */
    itemHeaderText: string;
    /**
     * @generated from protobuf field: string checkoutHeaderBackground = 5;
     */
    checkoutHeaderBackground: string;
    /**
     * @generated from protobuf field: string checkoutHeaderText = 6;
     */
    checkoutHeaderText: string;
    /**
     * @generated from protobuf field: string logoBackground = 7;
     */
    logoBackground: string;
    /**
     * @generated from protobuf field: string cartPreviewBorderColor = 8;
     */
    cartPreviewBorderColor: string;
    /**
     * @generated from protobuf field: string cartPreviewFillColor = 9;
     */
    cartPreviewFillColor: string;
    /**
     * @generated from protobuf field: string categoryHeaderBackgroundColor = 10;
     */
    categoryHeaderBackgroundColor: string;
    /**
     * @generated from protobuf field: string categoryHeaderTextColor = 11;
     */
    categoryHeaderTextColor: string;
}
/**
 * @generated from protobuf message Kiosk.ReceiptConfiguration
 */
export interface ReceiptConfiguration {
    /**
     * @generated from protobuf field: bool emailReceipt = 1;
     */
    emailReceipt: boolean;
    /**
     * @generated from protobuf field: bool smsReceipt = 2;
     */
    smsReceipt: boolean;
    /**
     * @generated from protobuf field: string headerText = 3;
     */
    headerText: string;
    /**
     * @generated from protobuf field: string footerText = 4;
     */
    footerText: string;
    /**
     * @generated from protobuf field: string logoUrl = 5;
     */
    logoUrl: string;
    /**
     * @generated from protobuf field: Kiosk.Address receiptAddress = 6;
     */
    receiptAddress?: Address;
    /**
     * @generated from protobuf field: Kiosk.PhoneNumberVM phone = 7;
     */
    phone?: PhoneNumberVM;
    /**
     * @generated from protobuf field: bool autoPrint = 8;
     */
    autoPrint: boolean;
    /**
     * @generated from protobuf field: Kiosk.ReceiptVisibilityOptions receiptVisibilityOptions = 9;
     */
    receiptVisibilityOptions?: ReceiptVisibilityOptions;
    /**
     * @generated from protobuf field: bool showQrCodeForReceiptUrl = 10;
     */
    showQrCodeForReceiptUrl: boolean;
    /**
     * @generated from protobuf field: bool showQrCodeWhenPrintReceiptFails = 11;
     */
    showQrCodeWhenPrintReceiptFails: boolean;
    /**
     * @generated from protobuf field: optional string generatingQrCodeMsg = 12;
     */
    generatingQrCodeMsg?: string;
    /**
     * @generated from protobuf field: optional string qrReceiptTitle = 13;
     */
    qrReceiptTitle?: string;
    /**
     * @generated from protobuf field: optional string qrReceiptMsg = 14;
     */
    qrReceiptMsg?: string;
    /**
     * @generated from protobuf field: optional string qrReceiptPrinterFailedMsg = 15;
     */
    qrReceiptPrinterFailedMsg?: string;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedHeaderText = 16;
     */
    localizedHeaderText?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedFooterText = 17;
     */
    localizedFooterText?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedQrReceiptTitle = 18;
     */
    localizedQrReceiptTitle?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedQrReceiptMsg = 19;
     */
    localizedQrReceiptMsg?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedGeneratingQrCodeMsg = 20;
     */
    localizedGeneratingQrCodeMsg?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedQrReceiptPrinterFailedMsg = 21;
     */
    localizedQrReceiptPrinterFailedMsg?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedEmailSubject = 22;
     */
    localizedEmailSubject?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedMessageFormat = 23;
     */
    localizedMessageFormat?: LocalizedStringVM;
}
/**
 * @generated from protobuf message Kiosk.ReceiptVisibilityOptions
 */
export interface ReceiptVisibilityOptions {
    /**
     * @generated from protobuf field: bool modifierGroupNames = 1;
     */
    modifierGroupNames: boolean;
    /**
     * @generated from protobuf field: bool defaultModifiers = 2;
     */
    defaultModifiers: boolean;
    /**
     * @generated from protobuf field: bool freeModifiers = 3;
     */
    freeModifiers: boolean;
    /**
     * @generated from protobuf field: bool pricedModifiers = 4;
     */
    pricedModifiers: boolean;
}
/**
 * @generated from protobuf message Kiosk.LoyaltyColors
 */
export interface LoyaltyColors {
    /**
     * @generated from protobuf field: string onLoyaltyAccent = 1;
     */
    onLoyaltyAccent: string;
    /**
     * @generated from protobuf field: string loyaltyAccent = 2;
     */
    loyaltyAccent: string;
    /**
     * @generated from protobuf field: string loyalty = 3;
     */
    loyalty: string;
    /**
     * @generated from protobuf field: string onLoyalty = 4;
     */
    onLoyalty: string;
    /**
     * @generated from protobuf field: string loyaltyBackground = 5;
     */
    loyaltyBackground: string;
}
/**
 * @generated from protobuf message Kiosk.KioskTimeouts
 */
export interface KioskTimeouts {
    /**
     * @generated from protobuf field: int32 idleTimeout = 1;
     */
    idleTimeout: number;
    /**
     * @generated from protobuf field: int32 restartPromptTimer = 2;
     */
    restartPromptTimer: number;
}
/**
 * @generated from protobuf message Kiosk.Tip
 */
export interface Tip {
    /**
     * @generated from protobuf field: Kiosk.CalculationType type = 1;
     */
    type: CalculationType;
    /**
     * @generated from protobuf field: int32 rate = 2;
     */
    rate: number;
    /**
     * @generated from protobuf field: bool isDefault = 3;
     */
    isDefault: boolean;
}
/**
 * @generated from protobuf message Kiosk.KioskTips
 */
export interface KioskTips {
    /**
     * @generated from protobuf field: repeated Kiosk.Tip tips = 1;
     */
    tips: Tip[];
    /**
     * @generated from protobuf field: bool EnableTips = 2 [json_name = "EnableTips"];
     */
    enableTips: boolean;
    /**
     * @generated from protobuf field: bool ShowCalculatedTip = 3 [json_name = "ShowCalculatedTip"];
     */
    showCalculatedTip: boolean;
    /**
     * @generated from protobuf field: bool CustomTipEnabled = 4 [json_name = "CustomTipEnabled"];
     */
    customTipEnabled: boolean;
    /**
     * @generated from protobuf field: string tender = 5;
     */
    tender: string;
}
/**
 * @generated from protobuf message Kiosk.PageCustomization
 */
export interface PageCustomization {
    /**
     * @generated from protobuf field: string backgroundImage = 1;
     */
    backgroundImage: string;
}
/**
 * @generated from protobuf message Kiosk.KioskCurrency
 */
export interface KioskCurrency {
    /**
     * @generated from protobuf field: string currencyId = 1;
     */
    currencyId: string;
    /**
     * @generated from protobuf field: int32 divisor = 2;
     */
    divisor: number;
}
/**
 * @generated from protobuf message Kiosk.PrinterConfiguration
 */
export interface PrinterConfiguration {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string ipAddress = 2;
     */
    ipAddress: string;
    /**
     * @generated from protobuf field: string macAddress = 3;
     */
    macAddress: string;
    /**
     * @generated from protobuf field: string model = 4;
     */
    model: string;
    /**
     * @generated from protobuf field: bool invoicePrinter = 5;
     */
    invoicePrinter: boolean;
    /**
     * @generated from protobuf field: bool tokenPrinter = 6;
     */
    tokenPrinter: boolean;
    /**
     * @generated from protobuf field: bool kitchenPrinter = 7;
     */
    kitchenPrinter: boolean;
    /**
     * @generated from protobuf field: bool stickyPrinter = 8;
     */
    stickyPrinter: boolean;
    /**
     * @generated from protobuf field: bool kdsPrinter = 9;
     */
    kdsPrinter: boolean;
    /**
     * @generated from protobuf field: bool enabled = 10;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: string deviceMode = 11;
     */
    deviceMode: string;
    /**
     * @generated from protobuf field: string type = 12;
     */
    type: string;
    /**
     * @generated from protobuf field: string usbVendorId = 13;
     */
    usbVendorId: string;
}
/**
 * @generated from protobuf message Kiosk.KioskConfiguration
 */
export interface KioskConfiguration {
    /**
     * @generated from protobuf field: Kiosk.KioskCurrency currency = 1;
     */
    currency?: KioskCurrency;
    /**
     * @generated from protobuf field: repeated Kiosk.KioskOrderType orderTypes = 2;
     */
    orderTypes: KioskOrderType[];
    /**
     * @generated from protobuf field: Kiosk.KioskTips tips = 3;
     */
    tips?: KioskTips;
    /**
     * @generated from protobuf field: repeated Kiosk.KioskPaymentDevice paymentDevices = 4;
     */
    paymentDevices: KioskPaymentDevice[];
    /**
     * @generated from protobuf field: Kiosk.LocationDetails LocationDetails = 5 [json_name = "LocationDetails"];
     */
    locationDetails?: LocationDetails;
    /**
     * @generated from protobuf field: Kiosk.KioskTimeouts timeouts = 6;
     */
    timeouts?: KioskTimeouts;
    /**
     * @generated from protobuf field: Kiosk.KioskOrderTokenSettings tokenSettings = 8;
     */
    tokenSettings?: KioskOrderTokenSettings;
    /**
     * @generated from protobuf field: Kiosk.LoyaltyConfiguration loyaltyConfiguration = 9;
     */
    loyaltyConfiguration?: LoyaltyConfiguration;
    /**
     * @generated from protobuf field: Kiosk.KioskAppearance appearance = 10;
     */
    appearance?: KioskAppearance;
    /**
     * @generated from protobuf field: Kiosk.LocationStaffVM locationStaff = 11;
     */
    locationStaff?: LocationStaffVM;
    /**
     * @generated from protobuf oneof: PosConfiguration
     */
    posConfiguration: {
        oneofKind: "cloudPosConfiguration";
        /**
         * @generated from protobuf field: Kiosk.CloudPosConfiguration CloudPosConfiguration = 12 [json_name = "CloudPosConfiguration"];
         */
        cloudPosConfiguration: CloudPosConfiguration;
    } | {
        oneofKind: "proxyPosConfiguration";
        /**
         * @generated from protobuf field: Kiosk.ProxyPosConfiguration ProxyPosConfiguration = 13 [json_name = "ProxyPosConfiguration"];
         */
        proxyPosConfiguration: ProxyPosConfiguration;
    } | {
        oneofKind: "demoPosConfiguration";
        /**
         * @generated from protobuf field: Kiosk.DemoPosConfiguration DemoPosConfiguration = 20 [json_name = "DemoPosConfiguration"];
         */
        demoPosConfiguration: DemoPosConfiguration;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: repeated Kiosk.Concept concepts = 14;
     */
    concepts: Concept[];
    /**
     * @generated from protobuf field: Kiosk.MessageGuardData guards = 15;
     */
    guards?: MessageGuardData;
    /**
     * @generated from protobuf field: repeated Kiosk.MenuLayout layouts = 16;
     */
    layouts: MenuLayout[];
    /**
     * @generated from protobuf field: string defaultMenuLayout = 17;
     */
    defaultMenuLayout: string;
    /**
     * @generated from protobuf field: string defaultPricebook = 18;
     */
    defaultPricebook: string;
    /**
     * @generated from protobuf field: string defaultPaymentTender = 19;
     */
    defaultPaymentTender: string;
    /**
     * @generated from protobuf field: Kiosk.ItemSpecialRequestConfiguration itemSpecialRequestConfiguration = 21;
     */
    itemSpecialRequestConfiguration?: ItemSpecialRequestConfiguration;
    /**
     * @generated from protobuf field: Kiosk.ReceiptConfiguration receiptConfiguration = 22;
     */
    receiptConfiguration?: ReceiptConfiguration;
    /**
     * @generated from protobuf field: Kiosk.BusinessHoursConfigurationVM businessHoursConfiguration = 24;
     */
    businessHoursConfiguration?: BusinessHoursConfigurationVM;
    /**
     * @generated from protobuf field: repeated Kiosk.PrinterConfiguration printerConfigurations = 25;
     */
    printerConfigurations: PrinterConfiguration[];
    /**
     * @generated from protobuf field: Kiosk.UpsellConfiguration upsellConfiguration = 26;
     */
    upsellConfiguration?: UpsellConfiguration;
    /**
     * @generated from protobuf field: Kiosk.KioskAmazonOneConfiguration amazonOneConfiguration = 27;
     */
    amazonOneConfiguration?: KioskAmazonOneConfiguration;
    /**
     * @generated from protobuf field: Kiosk.DisclaimerConfigurationVM disclaimerConfiguration = 28;
     */
    disclaimerConfiguration?: DisclaimerConfigurationVM;
    /**
     * @generated from protobuf field: string deviceName = 29;
     */
    deviceName: string;
    /**
     * @generated from protobuf field: Kiosk.DiscountsVM discounts = 30;
     */
    discounts?: DiscountsVM;
    /**
     * @generated from protobuf field: string companyID = 31;
     */
    companyID: string;
    /**
     * @generated from protobuf field: Kiosk.AdaConfigurationVM adaConfiguration = 32;
     */
    adaConfiguration?: AdaConfigurationVM;
    /**
     * @generated from protobuf field: Kiosk.LegalCopyVM legalCopy = 33;
     */
    legalCopy?: LegalCopyVM;
    /**
     * @generated from protobuf field: Kiosk.BarcodeScannerConfigurationVM barcodeScannerConfiguration = 34;
     */
    barcodeScannerConfiguration?: BarcodeScannerConfigurationVM;
    /**
     * @deprecated
     * @generated from protobuf field: bool discountsEnabled = 35 [deprecated = true];
     */
    discountsEnabled: boolean;
    /**
     * @generated from protobuf field: bool legalCopyEnabled = 36;
     */
    legalCopyEnabled: boolean;
    /**
     * @generated from protobuf field: bool posAutoAppliedDiscount = 37;
     */
    posAutoAppliedDiscount: boolean;
    /**
     * @generated from protobuf field: Kiosk.OrderLimitsConfigVM orderLimitsConfig = 38;
     */
    orderLimitsConfig?: OrderLimitsConfigVM;
    /**
     * @generated from protobuf field: bool checkPosFlow = 39;
     */
    checkPosFlow: boolean;
    /**
     * @deprecated
     * @generated from protobuf field: bool calculateDefaultModifierPrice = 40 [deprecated = true];
     */
    calculateDefaultModifierPrice: boolean;
    /**
     * @generated from protobuf field: Kiosk.DefaultModifierPricingBehavior defaultModifierPricingBehavior = 41;
     */
    defaultModifierPricingBehavior: DefaultModifierPricingBehavior;
    /**
     * @generated from protobuf field: bool loyaltyFeature = 42;
     */
    loyaltyFeature: boolean;
    /**
     * @generated from protobuf field: bool trackKioskUserBehavior = 43;
     */
    trackKioskUserBehavior: boolean;
    /**
     * @generated from protobuf field: bool pickupFlow = 44;
     */
    pickupFlow: boolean;
    /**
     * @generated from protobuf field: Kiosk.CompanyDetails companyDetails = 45;
     */
    companyDetails?: CompanyDetails;
    /**
     * @deprecated
     * @generated from protobuf field: Kiosk.DiscountSettingVM discountSetting = 46 [deprecated = true];
     */
    discountSetting?: DiscountSettingVM;
    /**
     * @generated from protobuf field: Kiosk.HardWareVisualAssetVM VisualAssets = 47 [json_name = "VisualAssets"];
     */
    visualAssets?: HardWareVisualAssetVM;
}
/**
 * @generated from protobuf message Kiosk.OrderLimitsConfigVM
 */
export interface OrderLimitsConfigVM {
    /**
     * @generated from protobuf field: int32 quantityLimitPerItem = 1;
     */
    quantityLimitPerItem: number;
    /**
     * @generated from protobuf field: int32 quantityLimitPerOrder = 2;
     */
    quantityLimitPerOrder: number;
    /**
     * @generated from protobuf field: bool showMakeItAMealOption = 3;
     */
    showMakeItAMealOption: boolean;
    /**
     * @generated from protobuf field: bool showItemAsIsOption = 4;
     */
    showItemAsIsOption: boolean;
    /**
     * @generated from protobuf field: int32 countToShowPromptsForItemUpsell = 5;
     */
    countToShowPromptsForItemUpsell: number;
    /**
     * @generated from protobuf field: bool showStartAtOptionForItem = 6;
     */
    showStartAtOptionForItem: boolean;
    /**
     * @generated from protobuf field: int32 maxDiscountPerOrder = 7;
     */
    maxDiscountPerOrder: number;
    /**
     * @generated from protobuf field: Kiosk.DiscountOrderingOptionsVM DiscountOrderingOptions = 8 [json_name = "DiscountOrderingOptions"];
     */
    discountOrderingOptions?: DiscountOrderingOptionsVM;
}
/**
 * @generated from protobuf message Kiosk.DiscountOrderingOptionsVM
 */
export interface DiscountOrderingOptionsVM {
    /**
     * @generated from protobuf field: bool canEnterCode = 1;
     */
    canEnterCode: boolean;
    /**
     * @generated from protobuf field: bool canScanQRCode = 2;
     */
    canScanQRCode: boolean;
    /**
     * @generated from protobuf field: bool canSelectFromList = 3;
     */
    canSelectFromList: boolean;
    /**
     * @generated from protobuf field: Kiosk.DiscountOrderingOptionsVM.DiscountCodeType discountCodeType = 4;
     */
    discountCodeType: DiscountOrderingOptionsVM_DiscountCodeType;
    /**
     * @generated from protobuf field: int32 discountCodeMaxLength = 5;
     */
    discountCodeMaxLength: number;
    /**
     * @generated from protobuf field: bool allowCodeEntryOnOrderReview = 6;
     */
    allowCodeEntryOnOrderReview: boolean;
    /**
     * @generated from protobuf field: bool allowCodeEntryOnCategoryScreen = 7;
     */
    allowCodeEntryOnCategoryScreen: boolean;
}
/**
 * @generated from protobuf enum Kiosk.DiscountOrderingOptionsVM.DiscountCodeType
 */
export enum DiscountOrderingOptionsVM_DiscountCodeType {
    /**
     * @generated from protobuf enum value: None = 0;
     */
    None = 0,
    /**
     * @generated from protobuf enum value: Alphanumeric = 1;
     */
    Alphanumeric = 1,
    /**
     * @generated from protobuf enum value: NumbersOnly = 2;
     */
    NumbersOnly = 2
}
/**
 * @generated from protobuf message Kiosk.BarcodeScannerConfigurationVM
 */
export interface BarcodeScannerConfigurationVM {
    /**
     * @generated from protobuf field: Kiosk.BarcodeScannerConfigurationVM.ExternalBarcodeScannerModel model = 1;
     */
    model: BarcodeScannerConfigurationVM_ExternalBarcodeScannerModel;
}
/**
 * @generated from protobuf enum Kiosk.BarcodeScannerConfigurationVM.ExternalBarcodeScannerModel
 */
export enum BarcodeScannerConfigurationVM_ExternalBarcodeScannerModel {
    /**
     * @generated from protobuf enum value: NoExternalScanner = 0;
     */
    NoExternalScanner = 0,
    /**
     * @generated from protobuf enum value: ZebraUsb = 1;
     */
    ZebraUsb = 1
}
/**
 * @generated from protobuf message Kiosk.LegalCopyVM
 */
export interface LegalCopyVM {
    /**
     * @generated from protobuf field: string legalButtonText = 1;
     */
    legalButtonText: string;
    /**
     * @generated from protobuf field: string legalMessageTitle = 2;
     */
    legalMessageTitle: string;
    /**
     * @generated from protobuf field: string legalTextContent = 3;
     */
    legalTextContent: string;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedLegalButtonText = 4;
     */
    localizedLegalButtonText?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedLegalMessageTitle = 5;
     */
    localizedLegalMessageTitle?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedLegalTextContent = 6;
     */
    localizedLegalTextContent?: LocalizedStringVM;
}
/**
 * @generated from protobuf message Kiosk.AdaConfigurationVM
 */
export interface AdaConfigurationVM {
    /**
     * @generated from protobuf field: bool adaModeEnabled = 1;
     */
    adaModeEnabled: boolean;
}
/**
 * @generated from protobuf message Kiosk.DisclaimerConfigurationVM
 */
export interface DisclaimerConfigurationVM {
    /**
     * @generated from protobuf field: string disclaimerText = 1;
     */
    disclaimerText: string;
    /**
     * @generated from protobuf field: string imageLink = 2;
     */
    imageLink: string;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedDisclaimerText = 3;
     */
    localizedDisclaimerText?: LocalizedStringVM;
}
/**
 * @generated from protobuf message Kiosk.KioskAmazonOneConfiguration
 */
export interface KioskAmazonOneConfiguration {
    /**
     * @generated from protobuf field: bool amazonOneEnabled = 1;
     */
    amazonOneEnabled: boolean;
    /**
     * @generated from protobuf field: Kiosk.AmazonOneInterfaceConfiguration interfaceConfiguration = 2;
     */
    interfaceConfiguration?: AmazonOneInterfaceConfiguration;
}
/**
 * @generated from protobuf message Kiosk.AmazonOneInterfaceConfiguration
 */
export interface AmazonOneInterfaceConfiguration {
    /**
     * @generated from protobuf oneof: config
     */
    config: {
        oneofKind: "usb";
        /**
         * @generated from protobuf field: Kiosk.AmazonOneUsbInterfaceConfiguration usb = 1;
         */
        usb: AmazonOneUsbInterfaceConfiguration;
    } | {
        oneofKind: "rest";
        /**
         * @generated from protobuf field: Kiosk.AmazonOneRestInterfaceConfiguration rest = 2;
         */
        rest: AmazonOneRestInterfaceConfiguration;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message Kiosk.AmazonOneUsbInterfaceConfiguration
 */
export interface AmazonOneUsbInterfaceConfiguration {
}
/**
 * @generated from protobuf message Kiosk.AmazonOneRestInterfaceConfiguration
 */
export interface AmazonOneRestInterfaceConfiguration {
    /**
     * @generated from protobuf field: string awsRegion = 1;
     */
    awsRegion: string;
    /**
     * @generated from protobuf field: string awsService = 2;
     */
    awsService: string;
    /**
     * @generated from protobuf field: string deviceSerialNumber = 3;
     */
    deviceSerialNumber: string;
    /**
     * @generated from protobuf field: string awsHost = 4;
     */
    awsHost: string;
}
/**
 * @generated from protobuf message Kiosk.ItemSpecialRequestConfiguration
 */
export interface ItemSpecialRequestConfiguration {
    /**
     * @generated from protobuf field: int32 characterLimit = 1;
     */
    characterLimit: number;
    /**
     * @generated from protobuf field: bool enabled = 2;
     */
    enabled: boolean;
}
/**
 * @generated from protobuf message Kiosk.CloudPosConfiguration
 */
export interface CloudPosConfiguration {
}
/**
 * @generated from protobuf message Kiosk.ProxyPosConfiguration
 */
export interface ProxyPosConfiguration {
    /**
     * @generated from protobuf field: string posProxyUrl = 1;
     */
    posProxyUrl: string;
}
/**
 * @generated from protobuf message Kiosk.DemoPosConfiguration
 */
export interface DemoPosConfiguration {
    /**
     * @generated from protobuf field: int32 taxRate = 1;
     */
    taxRate: number;
}
/**
 * @generated from protobuf message Kiosk.LoyaltyConfiguration
 */
export interface LoyaltyConfiguration {
    /**
     * @generated from protobuf field: string integrationId = 1;
     */
    integrationId: string;
    /**
     * @generated from protobuf oneof: configuration
     */
    configuration: {
        oneofKind: "punchh";
        /**
         * @generated from protobuf field: Kiosk.PunchhConfiguration punchh = 2;
         */
        punchh: PunchhConfiguration;
    } | {
        oneofKind: "paytronix";
        /**
         * @generated from protobuf field: Kiosk.PaytronixConfiguration paytronix = 6;
         */
        paytronix: PaytronixConfiguration;
    } | {
        oneofKind: "rbi";
        /**
         * @generated from protobuf field: Kiosk.RbiConfiguration rbi = 8;
         */
        rbi: RbiConfiguration;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: int32 maximum_selectable_rewards = 3;
     */
    maximumSelectableRewards: number;
    /**
     * @deprecated
     * @generated from protobuf field: bool enabled = 4 [deprecated = true];
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: string tender = 5;
     */
    tender: string;
    /**
     * @generated from protobuf field: Kiosk.LoyaltyInputMode inputMode = 7;
     */
    inputMode: LoyaltyInputMode;
    /**
     * @generated from protobuf field: Kiosk.IntegrationProviderRewardFlowBehavior rewardFlowBehavior = 9;
     */
    rewardFlowBehavior: IntegrationProviderRewardFlowBehavior;
    /**
     * @generated from protobuf field: bool printGuestCheckCode = 10;
     */
    printGuestCheckCode: boolean;
    /**
     * @generated from protobuf field: string guestCheckCodePrefaceText = 11;
     */
    guestCheckCodePrefaceText: string;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedGuestCheckCodePrefaceText = 12;
     */
    localizedGuestCheckCodePrefaceText?: LocalizedStringVM;
}
/**
 * @generated from protobuf message Kiosk.BinRange
 */
export interface BinRange {
    /**
     * @generated from protobuf field: string from = 1;
     */
    from: string;
    /**
     * @generated from protobuf field: string to = 2;
     */
    to: string;
}
/**
 * @generated from protobuf message Kiosk.PaytronixConfiguration
 */
export interface PaytronixConfiguration {
    /**
     * @generated from protobuf field: repeated Kiosk.BinRange binRanges = 1;
     */
    binRanges: BinRange[];
}
/**
 * @generated from protobuf message Kiosk.PunchhConfiguration
 */
export interface PunchhConfiguration {
    /**
     * @generated from protobuf field: bool askForDateOfBirth = 1;
     */
    askForDateOfBirth: boolean;
    /**
     * @generated from protobuf field: bool askForGender = 2;
     */
    askForGender: boolean;
    /**
     * @generated from protobuf field: repeated string genderOptions = 3;
     */
    genderOptions: string[];
}
/**
 * @generated from protobuf message Kiosk.RbiConfiguration
 */
export interface RbiConfiguration {
}
/**
 * @generated from protobuf message Kiosk.LocationDetails
 */
export interface LocationDetails {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string logo = 2;
     */
    logo: string;
    /**
     * @generated from protobuf field: Kiosk.Address address = 3;
     */
    address?: Address;
    /**
     * @generated from protobuf field: string localeId = 4;
     */
    localeId: string;
    /**
     * @generated from protobuf field: string homeScreenLogoUrl = 5;
     */
    homeScreenLogoUrl: string;
}
/**
 * @generated from protobuf message Kiosk.CompanyDetails
 */
export interface CompanyDetails {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string organizationId = 2;
     */
    organizationId: string;
}
/**
 * @generated from protobuf message Kiosk.Address
 */
export interface Address {
    /**
     * @generated from protobuf field: string streetOne = 1;
     */
    streetOne: string;
    /**
     * @generated from protobuf field: string streetTwo = 2;
     */
    streetTwo: string;
    /**
     * @generated from protobuf field: string city = 3;
     */
    city: string;
    /**
     * @generated from protobuf field: string state = 4;
     */
    state: string;
    /**
     * @generated from protobuf field: string postalcode = 5;
     */
    postalcode: string;
}
/**
 * @generated from protobuf message Kiosk.KioskOrderType
 */
export interface KioskOrderType {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
    /**
     * @generated from protobuf field: string externalId = 3;
     */
    externalId: string;
    /**
     * @deprecated
     * @generated from protobuf field: int32 orderIdentityMode = 4 [deprecated = true];
     */
    orderIdentityMode: number;
    /**
     * @deprecated
     * @generated from protobuf field: int32 customerIdentityMode = 5 [deprecated = true];
     */
    customerIdentityMode: number;
    /**
     * @deprecated
     * @generated from protobuf field: Kiosk.KioskCustomerNameSettings nameSettings = 7 [deprecated = true];
     */
    nameSettings?: KioskCustomerNameSettings;
    /**
     * @generated from protobuf field: string orderIdPrefix = 9;
     */
    orderIdPrefix: string;
    /**
     * @generated from protobuf field: Kiosk.IdentityMode name = 10;
     */
    name: IdentityMode;
    /**
     * @generated from protobuf field: Kiosk.IdentityMode tableTent = 11;
     */
    tableTent: IdentityMode;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringVM localizedLabel = 12;
     */
    localizedLabel?: LocalizedStringVM;
}
/**
 * @generated from protobuf message Kiosk.LocalizedStringVM
 */
export interface LocalizedStringVM {
    /**
     * @generated from protobuf field: map<string, string> translations = 1;
     */
    translations: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message Kiosk.KioskOrderTokenSettings
 */
export interface KioskOrderTokenSettings {
    /**
     * @generated from protobuf field: int32 orderNumberStart = 1;
     */
    orderNumberStart: number;
    /**
     * @generated from protobuf field: int32 allotment = 2;
     */
    allotment: number;
}
/**
 * @generated from protobuf message Kiosk.KioskCustomerNameSettings
 */
export interface KioskCustomerNameSettings {
    /**
     * @generated from protobuf field: bool askBeforeOrder = 1;
     */
    askBeforeOrder: boolean;
}
/**
 * @generated from protobuf message Kiosk.KioskTableTentSettings
 */
export interface KioskTableTentSettings {
    /**
     * @generated from protobuf field: string prefix = 1;
     */
    prefix: string;
}
/**
 * @generated from protobuf message Kiosk.KioskConfigurationRequest
 */
export interface KioskConfigurationRequest {
}
/**
 * @generated from protobuf message Kiosk.KioskConfigurationResponse
 */
export interface KioskConfigurationResponse {
    /**
     * @generated from protobuf field: Kiosk.KioskConfiguration configuration = 1;
     */
    configuration?: KioskConfiguration;
    /**
     * @generated from protobuf field: Kiosk.ResponseStatus status = 2;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message Kiosk.KioskPaymentDevice
 */
export interface KioskPaymentDevice {
    /**
     * @generated from protobuf field: Kiosk.PaymentMethod paymentMethod = 1;
     */
    paymentMethod: PaymentMethod;
    /**
     * @generated from protobuf field: string paymentIntegrationId = 2;
     */
    paymentIntegrationId: string;
    /**
     * @deprecated
     * @generated from protobuf field: string tender = 8 [deprecated = true];
     */
    tender: string;
    /**
     * @generated from protobuf field: string label = 9;
     */
    label: string;
    /**
     * @generated from protobuf oneof: paymentDevice
     */
    paymentDevice: {
        oneofKind: "verifonePaymentDevice";
        /**
         * @generated from protobuf field: Kiosk.VerifonePaymentDevice verifonePaymentDevice = 3;
         */
        verifonePaymentDevice: VerifonePaymentDevice;
    } | {
        oneofKind: "freedomPayDevice";
        /**
         * @generated from protobuf field: Kiosk.FreedomPayPaymentDevice freedomPayDevice = 4;
         */
        freedomPayDevice: FreedomPayPaymentDevice;
    } | {
        oneofKind: "cloverPaymentDevice";
        /**
         * @generated from protobuf field: Kiosk.CloverPaymentDevice cloverPaymentDevice = 5;
         */
        cloverPaymentDevice: CloverPaymentDevice;
    } | {
        oneofKind: "cloudPaymentDevice";
        /**
         * @generated from protobuf field: Kiosk.CloudPaymentDevice cloudPaymentDevice = 6;
         */
        cloudPaymentDevice: CloudPaymentDevice;
    } | {
        oneofKind: "restPaymentDevice";
        /**
         * @generated from protobuf field: Kiosk.RestPaymentDevice restPaymentDevice = 7;
         */
        restPaymentDevice: RestPaymentDevice;
    } | {
        oneofKind: "paytronixStoredValuePaymentDevice";
        /**
         * @generated from protobuf field: Kiosk.PaytronixStoredValuePaymentDevice paytronixStoredValuePaymentDevice = 12;
         */
        paytronixStoredValuePaymentDevice: PaytronixStoredValuePaymentDevice;
    } | {
        oneofKind: "nCRPaymentDevice";
        /**
         * @generated from protobuf field: Kiosk.NCRPaymentDevice NCRPaymentDevice = 13 [json_name = "NCRPaymentDevice"];
         */
        nCRPaymentDevice: NCRPaymentDevice;
    } | {
        oneofKind: "giftCardPaymentDevice";
        /**
         * @generated from protobuf field: Kiosk.GiftCardPaymentDevice giftCardPaymentDevice = 14;
         */
        giftCardPaymentDevice: GiftCardPaymentDevice;
    } | {
        oneofKind: "squarePaymentDevice";
        /**
         * @generated from protobuf field: Kiosk.SquarePaymentDevice SquarePaymentDevice = 15 [json_name = "SquarePaymentDevice"];
         */
        squarePaymentDevice: SquarePaymentDevice;
    } | {
        oneofKind: "freedomPayGiftCardDevice";
        /**
         * @generated from protobuf field: Kiosk.FreedomPayGiftCardDevice freedomPayGiftCardDevice = 17;
         */
        freedomPayGiftCardDevice: FreedomPayGiftCardDevice;
    } | {
        oneofKind: "worldPayPaymentDevice";
        /**
         * @generated from protobuf field: Kiosk.WorldPayPaymentDevice worldPayPaymentDevice = 18;
         */
        worldPayPaymentDevice: WorldPayPaymentDevice;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf oneof: tenders
     */
    tenders: {
        oneofKind: "creditCards";
        /**
         * @generated from protobuf field: Kiosk.CardNetworkMapping creditCards = 10;
         */
        creditCards: CardNetworkMapping;
    } | {
        oneofKind: "tenderId";
        /**
         * @generated from protobuf field: string tenderId = 11;
         */
        tenderId: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: string checkoutImageUrl = 16;
     */
    checkoutImageUrl: string;
}
/**
 * @generated from protobuf message Kiosk.RestPaymentDevice
 */
export interface RestPaymentDevice {
    /**
     * @generated from protobuf field: string paymentUrl = 1;
     */
    paymentUrl: string;
    /**
     * @generated from protobuf field: string refundUrl = 2;
     */
    refundUrl: string;
    /**
     * @generated from protobuf field: string statusUrl = 3;
     */
    statusUrl: string;
    /**
     * @generated from protobuf field: string settleUrl = 4;
     */
    settleUrl: string;
    /**
     * @generated from protobuf field: map<string, string> data = 5;
     */
    data: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message Kiosk.GiftCardPaymentDevice
 */
export interface GiftCardPaymentDevice {
    /**
     * @generated from protobuf field: repeated Kiosk.BinRange binRanges = 1;
     */
    binRanges: BinRange[];
}
/**
 * @generated from protobuf message Kiosk.CloudPaymentDevice
 */
export interface CloudPaymentDevice {
}
/**
 * @generated from protobuf message Kiosk.PaytronixStoredValuePaymentDevice
 */
export interface PaytronixStoredValuePaymentDevice {
}
/**
 * @generated from protobuf message Kiosk.VerifonePaymentDevice
 */
export interface VerifonePaymentDevice {
    /**
     * @generated from protobuf field: string ipAddress = 1;
     */
    ipAddress: string;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue authorizeOnly = 2;
     */
    authorizeOnly?: BoolValue;
}
/**
 * @generated from protobuf message Kiosk.NCRPaymentDevice
 */
export interface NCRPaymentDevice {
    /**
     * @generated from protobuf field: string clientId = 1;
     */
    clientId: string;
    /**
     * @generated from protobuf field: int32 companyNumber = 3;
     */
    companyNumber: number;
    /**
     * @generated from protobuf field: string configAddress = 4;
     */
    configAddress: string;
    /**
     * @generated from protobuf field: int32 configPort = 5;
     */
    configPort: number;
    /**
     * @generated from protobuf field: int32 laneNumber = 6;
     */
    laneNumber: number;
    /**
     * @generated from protobuf field: int32 storeNumber = 7;
     */
    storeNumber: number;
    /**
     * @generated from protobuf field: string hmacClientKey = 8;
     */
    hmacClientKey: string;
    /**
     * @generated from protobuf field: string hmacSecretKey = 9;
     */
    hmacSecretKey: string;
    /**
     * @generated from protobuf field: string hostAddress = 10;
     */
    hostAddress: string;
    /**
     * @generated from protobuf field: int32 hostPort = 11;
     */
    hostPort: number;
}
/**
 * @generated from protobuf message Kiosk.FreedomPayPaymentDevice
 */
export interface FreedomPayPaymentDevice {
    /**
     * @generated from protobuf field: string terminalId = 1;
     */
    terminalId: string;
    /**
     * @generated from protobuf field: string storeId = 2;
     */
    storeId: string;
    /**
     * @generated from protobuf field: string esKey = 3;
     */
    esKey: string;
    /**
     * @generated from protobuf field: int32 floorLimit = 4;
     */
    floorLimit: number;
}
/**
 * @generated from protobuf message Kiosk.WorldPayPaymentDevice
 */
export interface WorldPayPaymentDevice {
    /**
     * @generated from protobuf field: string accountID = 1;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string applicationID = 2;
     */
    applicationID: string;
    /**
     * @generated from protobuf field: string acceptorID = 3;
     */
    acceptorID: string;
    /**
     * @generated from protobuf field: string accountToken = 4;
     */
    accountToken: string;
    /**
     * @generated from protobuf field: int32 offlineTransactionLimit = 5;
     */
    offlineTransactionLimit: number;
    /**
     * @generated from protobuf field: int32 offlineTotalLimit = 6;
     */
    offlineTotalLimit: number;
}
/**
 * @generated from protobuf message Kiosk.FreedomPayGiftCardDevice
 */
export interface FreedomPayGiftCardDevice {
    /**
     * @generated from protobuf field: string terminalId = 1;
     */
    terminalId: string;
    /**
     * @generated from protobuf field: string storeId = 2;
     */
    storeId: string;
    /**
     * @generated from protobuf field: string esKey = 3;
     */
    esKey: string;
}
/**
 * @generated from protobuf message Kiosk.CloverPaymentDevice
 */
export interface CloverPaymentDevice {
    /**
     * @generated from protobuf field: string baseUrl = 1;
     */
    baseUrl: string;
    /**
     * @generated from protobuf field: string applicationId = 2;
     */
    applicationId: string;
    /**
     * @generated from protobuf field: string deviceId = 3;
     */
    deviceId: string;
    /**
     * @generated from protobuf field: string merchantId = 4;
     */
    merchantId: string;
    /**
     * @generated from protobuf field: string accessToken = 5;
     */
    accessToken: string;
}
/**
 * @generated from protobuf message Kiosk.SquarePaymentDevice
 */
export interface SquarePaymentDevice {
    /**
     * @generated from protobuf field: string configId = 1;
     */
    configId: string;
    /**
     * @generated from protobuf field: string deviceId = 2;
     */
    deviceId: string;
}
/**
 * @generated from protobuf message Kiosk.LogMessage
 */
export interface LogMessage {
    /**
     * @generated from protobuf field: string timestamp = 1;
     */
    timestamp: string;
    /**
     * @generated from protobuf field: Kiosk.LogLevel level = 2;
     */
    level: LogLevel;
    /**
     * @generated from protobuf field: string message = 3;
     */
    message: string;
}
/**
 * @generated from protobuf message Kiosk.StaffVM
 */
export interface StaffVM {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string accessCode = 2;
     */
    accessCode: string;
    /**
     * @generated from protobuf field: string id = 3;
     */
    id: string;
}
/**
 * @generated from protobuf message Kiosk.LocationStaffVM
 */
export interface LocationStaffVM {
    /**
     * @generated from protobuf field: repeated Kiosk.StaffVM staff = 1;
     */
    staff: StaffVM[];
}
/**
 * @generated from protobuf message Kiosk.Concept
 */
export interface Concept {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: Kiosk.ConceptTheme theme = 3;
     */
    theme?: ConceptTheme;
    /**
     * @generated from protobuf field: string layoutId = 4;
     */
    layoutId: string;
}
/**
 * @generated from protobuf message Kiosk.ConceptTheme
 */
export interface ConceptTheme {
    /**
     * @generated from protobuf field: Kiosk.ConceptMedia media = 1;
     */
    media?: ConceptMedia;
    /**
     * @generated from protobuf field: Kiosk.KioskColors colors = 2;
     */
    colors?: KioskColors;
}
/**
 * @generated from protobuf message Kiosk.ConceptMedia
 */
export interface ConceptMedia {
    /**
     * @generated from protobuf field: string categoryHeader = 1;
     */
    categoryHeader: string;
    /**
     * @generated from protobuf field: string logo = 2;
     */
    logo: string;
}
/**
 * @generated from protobuf message Kiosk.MessageVM
 */
export interface MessageVM {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * @generated from protobuf field: string subtitle = 2;
     */
    subtitle: string;
}
/**
 * @generated from protobuf message Kiosk.MessageGuardVM
 */
export interface MessageGuardVM {
    /**
     * @generated from protobuf field: Kiosk.MessageVM message = 1;
     */
    message?: MessageVM;
    /**
     * @generated from protobuf field: string id = 2;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: Kiosk.GuardTrigger trigger = 4;
     */
    trigger: GuardTrigger;
    /**
     * @generated from protobuf field: string image = 5;
     */
    image: string;
    /**
     * @generated from protobuf field: repeated string items = 6;
     */
    items: string[];
    /**
     * @generated from protobuf field: repeated string categories = 7;
     */
    categories: string[];
}
/**
 * @generated from protobuf message Kiosk.MessageGuardData
 */
export interface MessageGuardData {
    /**
     * @generated from protobuf field: repeated Kiosk.MessageGuardVM guards = 1;
     */
    guards: MessageGuardVM[];
}
/**
 * @generated from protobuf message Kiosk.BusinessHoursMessageVM
 */
export interface BusinessHoursMessageVM {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * @generated from protobuf field: string subtitle = 2;
     */
    subtitle: string;
    /**
     * @generated from protobuf field: string message = 3;
     */
    message: string;
    /**
     * @generated from protobuf field: bool showScreensaver = 4;
     */
    showScreensaver: boolean;
}
/**
 * @generated from protobuf message Kiosk.BusinessHoursConfigurationVM
 */
export interface BusinessHoursConfigurationVM {
    /**
     * @generated from protobuf field: repeated Kiosk.BusinessHoursVM businessHours = 1;
     */
    businessHours: BusinessHoursVM[];
    /**
     * @generated from protobuf field: Kiosk.BusinessHoursMessageVM message = 2;
     */
    message?: BusinessHoursMessageVM;
    /**
     * @generated from protobuf field: bool showMessage = 3;
     */
    showMessage: boolean;
    /**
     * @generated from protobuf field: bool enabled = 4;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: bool posHoursEnabled = 5;
     */
    posHoursEnabled: boolean;
}
/**
 * @generated from protobuf message Kiosk.BusinessHoursVM
 */
export interface BusinessHoursVM {
    /**
     * @generated from protobuf field: Kiosk.DayOfWeekVM day = 1;
     */
    day: DayOfWeekVM;
    /**
     * @generated from protobuf field: repeated Kiosk.HourRangeVM hours = 2;
     */
    hours: HourRangeVM[];
}
/**
 * @generated from protobuf message Kiosk.HourRangeVM
 */
export interface HourRangeVM {
    /**
     * @generated from protobuf field: int32 startMinutes = 1;
     */
    startMinutes: number;
    /**
     * @generated from protobuf field: int32 endMinutes = 2;
     */
    endMinutes: number;
}
/**
 * @generated from protobuf message Kiosk.PosIntegrationMenuSettingsVM
 */
export interface PosIntegrationMenuSettingsVM {
    /**
     * @generated from protobuf field: bool supportsFreeModifiers = 1;
     */
    supportsFreeModifiers: boolean;
    /**
     * @generated from protobuf field: bool supportsNegativeModifierBehavior = 2;
     */
    supportsNegativeModifierBehavior: boolean;
    /**
     * @generated from protobuf field: Kiosk.DefaultModifierPricingBehavior defaultModifierPricingBehavior = 3;
     */
    defaultModifierPricingBehavior: DefaultModifierPricingBehavior;
}
/**
 * @generated from protobuf message Kiosk.DiscountCodeEnterOptionVM
 */
export interface DiscountCodeEnterOptionVM {
}
/**
 * @generated from protobuf enum Kiosk.DiscountCodeEnterOptionVM.Options
 */
export enum DiscountCodeEnterOptionVM_Options {
    /**
     * @generated from protobuf enum value: CodeOnly = 0;
     */
    CodeOnly = 0,
    /**
     * @generated from protobuf enum value: ScanCodeOnly = 1;
     */
    ScanCodeOnly = 1,
    /**
     * @generated from protobuf enum value: Both = 2;
     */
    Both = 2
}
/**
 * @generated from protobuf message Kiosk.DiscountSettingVM
 */
export interface DiscountSettingVM {
    /**
     * @generated from protobuf field: Kiosk.DiscountCodeEnterOptionVM.Options discountCodeEnterOption = 1;
     */
    discountCodeEnterOption: DiscountCodeEnterOptionVM_Options;
    /**
     * @generated from protobuf field: bool showDiscountCodeOnOrderReviewScreen = 2;
     */
    showDiscountCodeOnOrderReviewScreen: boolean;
    /**
     * @generated from protobuf field: bool showDiscountCodeOnCategoryScreen = 3;
     */
    showDiscountCodeOnCategoryScreen: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value discountCodeCharacterLimit = 4;
     */
    discountCodeCharacterLimit?: Int32Value;
}
/**
 * @generated from protobuf message Kiosk.HardWareVisualAssetVM
 */
export interface HardWareVisualAssetVM {
    /**
     * @generated from protobuf field: repeated Kiosk.HardwareVisualAssetCollectionVM scanVisuals = 1;
     */
    scanVisuals: HardwareVisualAssetCollectionVM[];
    /**
     * @generated from protobuf field: repeated Kiosk.HardwareVisualAssetCollectionVM payVisuals = 2;
     */
    payVisuals: HardwareVisualAssetCollectionVM[];
}
/**
 * @generated from protobuf message Kiosk.HardwareVisualAssetCollectionVM
 */
export interface HardwareVisualAssetCollectionVM {
    /**
     * @generated from protobuf field: google.protobuf.StringValue scannerId = 1;
     */
    scannerId?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue kioskHardwareId = 2;
     */
    kioskHardwareId?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue paymentDeviceHardwareId = 3;
     */
    paymentDeviceHardwareId?: StringValue;
    /**
     * @generated from protobuf field: string media = 4;
     */
    media: string;
}
/**
 * @generated from protobuf enum Kiosk.CalculationType
 */
export enum CalculationType {
    /**
     * @generated from protobuf enum value: addFlat = 0;
     */
    addFlat = 0,
    /**
     * @generated from protobuf enum value: multiplyPercentage = 1;
     */
    multiplyPercentage = 1
}
/**
 * @generated from protobuf enum Kiosk.DefaultModifierPricingBehavior
 */
export enum DefaultModifierPricingBehavior {
    /**
     * @generated from protobuf enum value: AddDefaultModifierPrice = 0;
     */
    AddDefaultModifierPrice = 0,
    /**
     * @generated from protobuf enum value: ExcludeDefaultModifierPrice = 1;
     */
    ExcludeDefaultModifierPrice = 1
}
/**
 * @generated from protobuf enum Kiosk.LoyaltyInputMode
 */
export enum LoyaltyInputMode {
    /**
     * @generated from protobuf enum value: phone = 0;
     */
    phone = 0,
    /**
     * @generated from protobuf enum value: pin = 1;
     */
    pin = 1
}
/**
 * @generated from protobuf enum Kiosk.IntegrationProviderRewardFlowBehavior
 */
export enum IntegrationProviderRewardFlowBehavior {
    /**
     * @generated from protobuf enum value: RewardsBasedOnCart = 0;
     */
    RewardsBasedOnCart = 0,
    /**
     * @generated from protobuf enum value: RewardValidationRequired = 1;
     */
    RewardValidationRequired = 1
}
/**
 * @generated from protobuf enum Kiosk.IdentityMode
 */
export enum IdentityMode {
    /**
     * @generated from protobuf enum value: disabled = 0;
     */
    disabled = 0,
    /**
     * @generated from protobuf enum value: start = 1;
     */
    start = 1,
    /**
     * @generated from protobuf enum value: end = 2;
     */
    end = 2
}
/**
 * @generated from protobuf enum Kiosk.PaymentMethod
 */
export enum PaymentMethod {
    /**
     * @generated from protobuf enum value: cash = 0;
     */
    cash = 0,
    /**
     * @generated from protobuf enum value: credit = 1;
     */
    credit = 1,
    /**
     * @generated from protobuf enum value: giftCard = 2;
     */
    giftCard = 2,
    /**
     * @generated from protobuf enum value: loyalty = 3;
     */
    loyalty = 3,
    /**
     * @generated from protobuf enum value: storedValue = 4;
     */
    storedValue = 4,
    /**
     * @generated from protobuf enum value: PayAtCounter = 5;
     */
    PayAtCounter = 5,
    /**
     * @generated from protobuf enum value: FreedomPay = 6;
     */
    FreedomPay = 6,
    /**
     * @generated from protobuf enum value: WorldPay = 7;
     */
    WorldPay = 7
}
/**
 * @generated from protobuf enum Kiosk.LogLevel
 */
export enum LogLevel {
    /**
     * @generated from protobuf enum value: Trace = 0;
     */
    Trace = 0,
    /**
     * @generated from protobuf enum value: Debug = 1;
     */
    Debug = 1,
    /**
     * @generated from protobuf enum value: Information = 2;
     */
    Information = 2,
    /**
     * @generated from protobuf enum value: Warning = 3;
     */
    Warning = 3,
    /**
     * @generated from protobuf enum value: Error = 4;
     */
    Error = 4,
    /**
     * @generated from protobuf enum value: Critical = 5;
     */
    Critical = 5
}
/**
 * @generated from protobuf enum Kiosk.GuardTrigger
 */
export enum GuardTrigger {
    /**
     * @generated from protobuf enum value: ADDING_TO_CART = 0;
     */
    ADDING_TO_CART = 0,
    /**
     * @generated from protobuf enum value: AFTER_PAYMENT = 1;
     */
    AFTER_PAYMENT = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class KioskRegistrationRequest$Type extends MessageType<KioskRegistrationRequest> {
    constructor() {
        super("Kiosk.KioskRegistrationRequest", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "appVersion", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "deviceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KioskRegistrationRequest>): KioskRegistrationRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = "";
        message.appVersion = "";
        message.deviceId = "";
        if (value !== undefined)
            reflectionMergePartial<KioskRegistrationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskRegistrationRequest): KioskRegistrationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* string appVersion */ 2:
                    message.appVersion = reader.string();
                    break;
                case /* string deviceId */ 3:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskRegistrationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* string appVersion = 2; */
        if (message.appVersion !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.appVersion);
        /* string deviceId = 3; */
        if (message.deviceId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskRegistrationRequest
 */
export const KioskRegistrationRequest = new KioskRegistrationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskRegistrationResponse$Type extends MessageType<KioskRegistrationResponse> {
    constructor() {
        super("Kiosk.KioskRegistrationResponse", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "refreshToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<KioskRegistrationResponse>): KioskRegistrationResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.locationId = "";
        message.refreshToken = "";
        if (value !== undefined)
            reflectionMergePartial<KioskRegistrationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskRegistrationResponse): KioskRegistrationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* string refreshToken */ 2:
                    message.refreshToken = reader.string();
                    break;
                case /* Kiosk.ResponseStatus status */ 3:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskRegistrationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string refreshToken = 2; */
        if (message.refreshToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.refreshToken);
        /* Kiosk.ResponseStatus status = 3; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskRegistrationResponse
 */
export const KioskRegistrationResponse = new KioskRegistrationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskAuthorizationRequest$Type extends MessageType<KioskAuthorizationRequest> {
    constructor() {
        super("Kiosk.KioskAuthorizationRequest", [
            { no: 1, name: "refreshToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KioskAuthorizationRequest>): KioskAuthorizationRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.refreshToken = "";
        if (value !== undefined)
            reflectionMergePartial<KioskAuthorizationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskAuthorizationRequest): KioskAuthorizationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string refreshToken */ 1:
                    message.refreshToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskAuthorizationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string refreshToken = 1; */
        if (message.refreshToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.refreshToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskAuthorizationRequest
 */
export const KioskAuthorizationRequest = new KioskAuthorizationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskAuthorizationResponse$Type extends MessageType<KioskAuthorizationResponse> {
    constructor() {
        super("Kiosk.KioskAuthorizationResponse", [
            { no: 1, name: "accessToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "refreshToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<KioskAuthorizationResponse>): KioskAuthorizationResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accessToken = "";
        message.refreshToken = "";
        if (value !== undefined)
            reflectionMergePartial<KioskAuthorizationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskAuthorizationResponse): KioskAuthorizationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accessToken */ 1:
                    message.accessToken = reader.string();
                    break;
                case /* string refreshToken */ 2:
                    message.refreshToken = reader.string();
                    break;
                case /* Kiosk.ResponseStatus status */ 3:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskAuthorizationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accessToken = 1; */
        if (message.accessToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accessToken);
        /* string refreshToken = 2; */
        if (message.refreshToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.refreshToken);
        /* Kiosk.ResponseStatus status = 3; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskAuthorizationResponse
 */
export const KioskAuthorizationResponse = new KioskAuthorizationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskDeregistrationRequest$Type extends MessageType<KioskDeregistrationRequest> {
    constructor() {
        super("Kiosk.KioskDeregistrationRequest", [
            { no: 1, name: "deviceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accessToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KioskDeregistrationRequest>): KioskDeregistrationRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deviceId = "";
        message.accessToken = "";
        if (value !== undefined)
            reflectionMergePartial<KioskDeregistrationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskDeregistrationRequest): KioskDeregistrationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string deviceId */ 1:
                    message.deviceId = reader.string();
                    break;
                case /* string accessToken */ 2:
                    message.accessToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskDeregistrationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string deviceId = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        /* string accessToken = 2; */
        if (message.accessToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accessToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskDeregistrationRequest
 */
export const KioskDeregistrationRequest = new KioskDeregistrationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskDeregistrationResponse$Type extends MessageType<KioskDeregistrationResponse> {
    constructor() {
        super("Kiosk.KioskDeregistrationResponse", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<KioskDeregistrationResponse>): KioskDeregistrationResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<KioskDeregistrationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskDeregistrationResponse): KioskDeregistrationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskDeregistrationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskDeregistrationResponse
 */
export const KioskDeregistrationResponse = new KioskDeregistrationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskAppearance$Type extends MessageType<KioskAppearance> {
    constructor() {
        super("Kiosk.KioskAppearance", [
            { no: 1, name: "template", kind: "message", T: () => KioskTemplate },
            { no: 2, name: "colors", kind: "message", T: () => KioskColors },
            { no: 3, name: "media", kind: "message", T: () => KioskMedia },
            { no: 4, name: "dictionaries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => I18nDictionary },
            { no: 5, name: "animations", kind: "message", T: () => AnimationsVM },
            { no: 6, name: "LoyaltyColors", kind: "message", jsonName: "LoyaltyColors", T: () => LoyaltyColors },
            { no: 7, name: "menuColors", kind: "message", T: () => MenuColors },
            { no: 8, name: "styleOptions", kind: "message", T: () => KioskStyleOptions },
            { no: 9, name: "fonts", kind: "message", T: () => FontMediaVM }
        ]);
    }
    create(value?: PartialMessage<KioskAppearance>): KioskAppearance {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.dictionaries = [];
        if (value !== undefined)
            reflectionMergePartial<KioskAppearance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskAppearance): KioskAppearance {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.KioskTemplate template */ 1:
                    message.template = KioskTemplate.internalBinaryRead(reader, reader.uint32(), options, message.template);
                    break;
                case /* Kiosk.KioskColors colors */ 2:
                    message.colors = KioskColors.internalBinaryRead(reader, reader.uint32(), options, message.colors);
                    break;
                case /* Kiosk.KioskMedia media */ 3:
                    message.media = KioskMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* repeated Kiosk.I18nDictionary dictionaries */ 4:
                    message.dictionaries.push(I18nDictionary.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.AnimationsVM animations */ 5:
                    message.animations = AnimationsVM.internalBinaryRead(reader, reader.uint32(), options, message.animations);
                    break;
                case /* Kiosk.LoyaltyColors LoyaltyColors = 6 [json_name = "LoyaltyColors"];*/ 6:
                    message.loyaltyColors = LoyaltyColors.internalBinaryRead(reader, reader.uint32(), options, message.loyaltyColors);
                    break;
                case /* Kiosk.MenuColors menuColors */ 7:
                    message.menuColors = MenuColors.internalBinaryRead(reader, reader.uint32(), options, message.menuColors);
                    break;
                case /* Kiosk.KioskStyleOptions styleOptions */ 8:
                    message.styleOptions = KioskStyleOptions.internalBinaryRead(reader, reader.uint32(), options, message.styleOptions);
                    break;
                case /* Kiosk.FontMediaVM fonts */ 9:
                    message.fonts = FontMediaVM.internalBinaryRead(reader, reader.uint32(), options, message.fonts);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskAppearance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.KioskTemplate template = 1; */
        if (message.template)
            KioskTemplate.internalBinaryWrite(message.template, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.KioskColors colors = 2; */
        if (message.colors)
            KioskColors.internalBinaryWrite(message.colors, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.KioskMedia media = 3; */
        if (message.media)
            KioskMedia.internalBinaryWrite(message.media, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.I18nDictionary dictionaries = 4; */
        for (let i = 0; i < message.dictionaries.length; i++)
            I18nDictionary.internalBinaryWrite(message.dictionaries[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.AnimationsVM animations = 5; */
        if (message.animations)
            AnimationsVM.internalBinaryWrite(message.animations, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LoyaltyColors LoyaltyColors = 6 [json_name = "LoyaltyColors"]; */
        if (message.loyaltyColors)
            LoyaltyColors.internalBinaryWrite(message.loyaltyColors, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.MenuColors menuColors = 7; */
        if (message.menuColors)
            MenuColors.internalBinaryWrite(message.menuColors, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.KioskStyleOptions styleOptions = 8; */
        if (message.styleOptions)
            KioskStyleOptions.internalBinaryWrite(message.styleOptions, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.FontMediaVM fonts = 9; */
        if (message.fonts)
            FontMediaVM.internalBinaryWrite(message.fonts, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskAppearance
 */
export const KioskAppearance = new KioskAppearance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskStyleOptions$Type extends MessageType<KioskStyleOptions> {
    constructor() {
        super("Kiosk.KioskStyleOptions", [
            { no: 1, name: "buttonStyle", kind: "enum", T: () => ["Kiosk.KioskStyleOptions.ButtonStyle", KioskStyleOptions_ButtonStyle] },
            { no: 2, name: "categoryHeaderDisplayMode", kind: "enum", T: () => ["Kiosk.KioskStyleOptions.CategoryHeaderDisplayModeStyle", KioskStyleOptions_CategoryHeaderDisplayModeStyle] },
            { no: 3, name: "showCategoryHighlightedColor", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "categoryHeaderLogoDisplayMode", kind: "enum", T: () => ["Kiosk.KioskStyleOptions.CategoryHeaderLogoDisplayMode", KioskStyleOptions_CategoryHeaderLogoDisplayMode] },
            { no: 5, name: "displayModifierGroupRestrictions", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "allowUserToCollapseOrExpandModifierGroups", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "showDiscountCodeButton", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "orderReviewShowDefaultModifiers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "showModifierGroupNamesOrderReview", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "showLoyaltyPointsOnHeader", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "showAcceptedPaymentOptionsOnScreenSaver", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "kioskStyleAcceptedPaymentOptions", kind: "message", T: () => KioskStyleAcceptedPaymentOptionsVM },
            { no: 13, name: "categoryNamePostition", kind: "enum", T: () => ["Kiosk.KioskStyleOptions.CategoryNamePostitionEnum", KioskStyleOptions_CategoryNamePostitionEnum] },
            { no: 14, name: "showStartAtOptionForItem", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<KioskStyleOptions>): KioskStyleOptions {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.buttonStyle = 0;
        message.categoryHeaderDisplayMode = 0;
        message.showCategoryHighlightedColor = false;
        message.categoryHeaderLogoDisplayMode = 0;
        message.displayModifierGroupRestrictions = false;
        message.allowUserToCollapseOrExpandModifierGroups = false;
        message.showDiscountCodeButton = false;
        message.orderReviewShowDefaultModifiers = false;
        message.showModifierGroupNamesOrderReview = false;
        message.showLoyaltyPointsOnHeader = false;
        message.showAcceptedPaymentOptionsOnScreenSaver = false;
        message.categoryNamePostition = 0;
        message.showStartAtOptionForItem = false;
        if (value !== undefined)
            reflectionMergePartial<KioskStyleOptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskStyleOptions): KioskStyleOptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.KioskStyleOptions.ButtonStyle buttonStyle */ 1:
                    message.buttonStyle = reader.int32();
                    break;
                case /* Kiosk.KioskStyleOptions.CategoryHeaderDisplayModeStyle categoryHeaderDisplayMode */ 2:
                    message.categoryHeaderDisplayMode = reader.int32();
                    break;
                case /* bool showCategoryHighlightedColor */ 3:
                    message.showCategoryHighlightedColor = reader.bool();
                    break;
                case /* Kiosk.KioskStyleOptions.CategoryHeaderLogoDisplayMode categoryHeaderLogoDisplayMode */ 4:
                    message.categoryHeaderLogoDisplayMode = reader.int32();
                    break;
                case /* bool displayModifierGroupRestrictions */ 5:
                    message.displayModifierGroupRestrictions = reader.bool();
                    break;
                case /* bool allowUserToCollapseOrExpandModifierGroups */ 6:
                    message.allowUserToCollapseOrExpandModifierGroups = reader.bool();
                    break;
                case /* bool showDiscountCodeButton */ 7:
                    message.showDiscountCodeButton = reader.bool();
                    break;
                case /* bool orderReviewShowDefaultModifiers */ 8:
                    message.orderReviewShowDefaultModifiers = reader.bool();
                    break;
                case /* bool showModifierGroupNamesOrderReview */ 9:
                    message.showModifierGroupNamesOrderReview = reader.bool();
                    break;
                case /* bool showLoyaltyPointsOnHeader */ 10:
                    message.showLoyaltyPointsOnHeader = reader.bool();
                    break;
                case /* bool showAcceptedPaymentOptionsOnScreenSaver */ 11:
                    message.showAcceptedPaymentOptionsOnScreenSaver = reader.bool();
                    break;
                case /* Kiosk.KioskStyleAcceptedPaymentOptionsVM kioskStyleAcceptedPaymentOptions */ 12:
                    message.kioskStyleAcceptedPaymentOptions = KioskStyleAcceptedPaymentOptionsVM.internalBinaryRead(reader, reader.uint32(), options, message.kioskStyleAcceptedPaymentOptions);
                    break;
                case /* Kiosk.KioskStyleOptions.CategoryNamePostitionEnum categoryNamePostition */ 13:
                    message.categoryNamePostition = reader.int32();
                    break;
                case /* bool showStartAtOptionForItem = 14 [deprecated = true];*/ 14:
                    message.showStartAtOptionForItem = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskStyleOptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.KioskStyleOptions.ButtonStyle buttonStyle = 1; */
        if (message.buttonStyle !== 0)
            writer.tag(1, WireType.Varint).int32(message.buttonStyle);
        /* Kiosk.KioskStyleOptions.CategoryHeaderDisplayModeStyle categoryHeaderDisplayMode = 2; */
        if (message.categoryHeaderDisplayMode !== 0)
            writer.tag(2, WireType.Varint).int32(message.categoryHeaderDisplayMode);
        /* bool showCategoryHighlightedColor = 3; */
        if (message.showCategoryHighlightedColor !== false)
            writer.tag(3, WireType.Varint).bool(message.showCategoryHighlightedColor);
        /* Kiosk.KioskStyleOptions.CategoryHeaderLogoDisplayMode categoryHeaderLogoDisplayMode = 4; */
        if (message.categoryHeaderLogoDisplayMode !== 0)
            writer.tag(4, WireType.Varint).int32(message.categoryHeaderLogoDisplayMode);
        /* bool displayModifierGroupRestrictions = 5; */
        if (message.displayModifierGroupRestrictions !== false)
            writer.tag(5, WireType.Varint).bool(message.displayModifierGroupRestrictions);
        /* bool allowUserToCollapseOrExpandModifierGroups = 6; */
        if (message.allowUserToCollapseOrExpandModifierGroups !== false)
            writer.tag(6, WireType.Varint).bool(message.allowUserToCollapseOrExpandModifierGroups);
        /* bool showDiscountCodeButton = 7; */
        if (message.showDiscountCodeButton !== false)
            writer.tag(7, WireType.Varint).bool(message.showDiscountCodeButton);
        /* bool orderReviewShowDefaultModifiers = 8; */
        if (message.orderReviewShowDefaultModifiers !== false)
            writer.tag(8, WireType.Varint).bool(message.orderReviewShowDefaultModifiers);
        /* bool showModifierGroupNamesOrderReview = 9; */
        if (message.showModifierGroupNamesOrderReview !== false)
            writer.tag(9, WireType.Varint).bool(message.showModifierGroupNamesOrderReview);
        /* bool showLoyaltyPointsOnHeader = 10; */
        if (message.showLoyaltyPointsOnHeader !== false)
            writer.tag(10, WireType.Varint).bool(message.showLoyaltyPointsOnHeader);
        /* bool showAcceptedPaymentOptionsOnScreenSaver = 11; */
        if (message.showAcceptedPaymentOptionsOnScreenSaver !== false)
            writer.tag(11, WireType.Varint).bool(message.showAcceptedPaymentOptionsOnScreenSaver);
        /* Kiosk.KioskStyleAcceptedPaymentOptionsVM kioskStyleAcceptedPaymentOptions = 12; */
        if (message.kioskStyleAcceptedPaymentOptions)
            KioskStyleAcceptedPaymentOptionsVM.internalBinaryWrite(message.kioskStyleAcceptedPaymentOptions, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.KioskStyleOptions.CategoryNamePostitionEnum categoryNamePostition = 13; */
        if (message.categoryNamePostition !== 0)
            writer.tag(13, WireType.Varint).int32(message.categoryNamePostition);
        /* bool showStartAtOptionForItem = 14 [deprecated = true]; */
        if (message.showStartAtOptionForItem !== false)
            writer.tag(14, WireType.Varint).bool(message.showStartAtOptionForItem);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskStyleOptions
 */
export const KioskStyleOptions = new KioskStyleOptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskStyleAcceptedPaymentOptionsVM$Type extends MessageType<KioskStyleAcceptedPaymentOptionsVM> {
    constructor() {
        super("Kiosk.KioskStyleAcceptedPaymentOptionsVM", [
            { no: 1, name: "showCard", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "showGooglePay", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "showApplePay", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "showCash", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "acceptedPaymentText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "localizedAcceptedPaymentText", kind: "message", T: () => LocalizedStringVM }
        ]);
    }
    create(value?: PartialMessage<KioskStyleAcceptedPaymentOptionsVM>): KioskStyleAcceptedPaymentOptionsVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.showCard = false;
        message.showGooglePay = false;
        message.showApplePay = false;
        message.showCash = false;
        message.acceptedPaymentText = "";
        if (value !== undefined)
            reflectionMergePartial<KioskStyleAcceptedPaymentOptionsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskStyleAcceptedPaymentOptionsVM): KioskStyleAcceptedPaymentOptionsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool showCard */ 1:
                    message.showCard = reader.bool();
                    break;
                case /* bool showGooglePay */ 2:
                    message.showGooglePay = reader.bool();
                    break;
                case /* bool showApplePay */ 3:
                    message.showApplePay = reader.bool();
                    break;
                case /* bool showCash */ 4:
                    message.showCash = reader.bool();
                    break;
                case /* string acceptedPaymentText */ 5:
                    message.acceptedPaymentText = reader.string();
                    break;
                case /* Kiosk.LocalizedStringVM localizedAcceptedPaymentText */ 6:
                    message.localizedAcceptedPaymentText = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedAcceptedPaymentText);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskStyleAcceptedPaymentOptionsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool showCard = 1; */
        if (message.showCard !== false)
            writer.tag(1, WireType.Varint).bool(message.showCard);
        /* bool showGooglePay = 2; */
        if (message.showGooglePay !== false)
            writer.tag(2, WireType.Varint).bool(message.showGooglePay);
        /* bool showApplePay = 3; */
        if (message.showApplePay !== false)
            writer.tag(3, WireType.Varint).bool(message.showApplePay);
        /* bool showCash = 4; */
        if (message.showCash !== false)
            writer.tag(4, WireType.Varint).bool(message.showCash);
        /* string acceptedPaymentText = 5; */
        if (message.acceptedPaymentText !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.acceptedPaymentText);
        /* Kiosk.LocalizedStringVM localizedAcceptedPaymentText = 6; */
        if (message.localizedAcceptedPaymentText)
            LocalizedStringVM.internalBinaryWrite(message.localizedAcceptedPaymentText, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskStyleAcceptedPaymentOptionsVM
 */
export const KioskStyleAcceptedPaymentOptionsVM = new KioskStyleAcceptedPaymentOptionsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FontMediaVM$Type extends MessageType<FontMediaVM> {
    constructor() {
        super("Kiosk.FontMediaVM", [
            { no: 1, name: "kioskFont", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "kioskFontCharacterSpacing", kind: "message", T: () => Int32Value },
            { no: 3, name: "kioskLightFont", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "kioskLightFontCharacterSpacing", kind: "message", T: () => Int32Value },
            { no: 5, name: "kioskLightFontScale", kind: "message", T: () => Int32Value },
            { no: 6, name: "kioskBoldFont", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "kioskBoldFontCharacterSpacing", kind: "message", T: () => Int32Value },
            { no: 8, name: "kioskBoldFontScale", kind: "message", T: () => Int32Value }
        ]);
    }
    create(value?: PartialMessage<FontMediaVM>): FontMediaVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.kioskFont = "";
        message.kioskLightFont = "";
        message.kioskBoldFont = "";
        if (value !== undefined)
            reflectionMergePartial<FontMediaVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FontMediaVM): FontMediaVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string kioskFont */ 1:
                    message.kioskFont = reader.string();
                    break;
                case /* google.protobuf.Int32Value kioskFontCharacterSpacing */ 2:
                    message.kioskFontCharacterSpacing = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.kioskFontCharacterSpacing);
                    break;
                case /* string kioskLightFont */ 3:
                    message.kioskLightFont = reader.string();
                    break;
                case /* google.protobuf.Int32Value kioskLightFontCharacterSpacing */ 4:
                    message.kioskLightFontCharacterSpacing = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.kioskLightFontCharacterSpacing);
                    break;
                case /* google.protobuf.Int32Value kioskLightFontScale */ 5:
                    message.kioskLightFontScale = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.kioskLightFontScale);
                    break;
                case /* string kioskBoldFont */ 6:
                    message.kioskBoldFont = reader.string();
                    break;
                case /* google.protobuf.Int32Value kioskBoldFontCharacterSpacing */ 7:
                    message.kioskBoldFontCharacterSpacing = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.kioskBoldFontCharacterSpacing);
                    break;
                case /* google.protobuf.Int32Value kioskBoldFontScale */ 8:
                    message.kioskBoldFontScale = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.kioskBoldFontScale);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FontMediaVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string kioskFont = 1; */
        if (message.kioskFont !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.kioskFont);
        /* google.protobuf.Int32Value kioskFontCharacterSpacing = 2; */
        if (message.kioskFontCharacterSpacing)
            Int32Value.internalBinaryWrite(message.kioskFontCharacterSpacing, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string kioskLightFont = 3; */
        if (message.kioskLightFont !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.kioskLightFont);
        /* google.protobuf.Int32Value kioskLightFontCharacterSpacing = 4; */
        if (message.kioskLightFontCharacterSpacing)
            Int32Value.internalBinaryWrite(message.kioskLightFontCharacterSpacing, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value kioskLightFontScale = 5; */
        if (message.kioskLightFontScale)
            Int32Value.internalBinaryWrite(message.kioskLightFontScale, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string kioskBoldFont = 6; */
        if (message.kioskBoldFont !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.kioskBoldFont);
        /* google.protobuf.Int32Value kioskBoldFontCharacterSpacing = 7; */
        if (message.kioskBoldFontCharacterSpacing)
            Int32Value.internalBinaryWrite(message.kioskBoldFontCharacterSpacing, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value kioskBoldFontScale = 8; */
        if (message.kioskBoldFontScale)
            Int32Value.internalBinaryWrite(message.kioskBoldFontScale, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.FontMediaVM
 */
export const FontMediaVM = new FontMediaVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskTemplate$Type extends MessageType<KioskTemplate> {
    constructor() {
        super("Kiosk.KioskTemplate", [
            { no: 1, name: "templateId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KioskTemplate>): KioskTemplate {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.templateId = "";
        if (value !== undefined)
            reflectionMergePartial<KioskTemplate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskTemplate): KioskTemplate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string templateId */ 1:
                    message.templateId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskTemplate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string templateId = 1; */
        if (message.templateId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.templateId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskTemplate
 */
export const KioskTemplate = new KioskTemplate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnimationsVM$Type extends MessageType<AnimationsVM> {
    constructor() {
        super("Kiosk.AnimationsVM", [
            { no: 1, name: "loading", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "orderTimeout", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "scanQRCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "calculatingOrder", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "payWithCard", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "paymentSuccess", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "paymentFail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "storeIsClosed", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AnimationsVM>): AnimationsVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.loading = "";
        message.orderTimeout = "";
        message.scanQRCode = "";
        message.calculatingOrder = "";
        message.payWithCard = "";
        message.paymentSuccess = "";
        message.paymentFail = "";
        message.storeIsClosed = "";
        if (value !== undefined)
            reflectionMergePartial<AnimationsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnimationsVM): AnimationsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string loading */ 1:
                    message.loading = reader.string();
                    break;
                case /* string orderTimeout */ 2:
                    message.orderTimeout = reader.string();
                    break;
                case /* string scanQRCode */ 3:
                    message.scanQRCode = reader.string();
                    break;
                case /* string calculatingOrder */ 4:
                    message.calculatingOrder = reader.string();
                    break;
                case /* string payWithCard */ 5:
                    message.payWithCard = reader.string();
                    break;
                case /* string paymentSuccess */ 6:
                    message.paymentSuccess = reader.string();
                    break;
                case /* string paymentFail */ 7:
                    message.paymentFail = reader.string();
                    break;
                case /* string storeIsClosed */ 8:
                    message.storeIsClosed = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AnimationsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string loading = 1; */
        if (message.loading !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.loading);
        /* string orderTimeout = 2; */
        if (message.orderTimeout !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.orderTimeout);
        /* string scanQRCode = 3; */
        if (message.scanQRCode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.scanQRCode);
        /* string calculatingOrder = 4; */
        if (message.calculatingOrder !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.calculatingOrder);
        /* string payWithCard = 5; */
        if (message.payWithCard !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.payWithCard);
        /* string paymentSuccess = 6; */
        if (message.paymentSuccess !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.paymentSuccess);
        /* string paymentFail = 7; */
        if (message.paymentFail !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.paymentFail);
        /* string storeIsClosed = 8; */
        if (message.storeIsClosed !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.storeIsClosed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.AnimationsVM
 */
export const AnimationsVM = new AnimationsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyMediaVM$Type extends MessageType<LoyaltyMediaVM> {
    constructor() {
        super("Kiosk.LoyaltyMediaVM", [
            { no: 1, name: "defaultProfileHeaderImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "defaultTopCornerImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "defaultRewardsBackgroundImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "defaultRewardImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "defaultProfileFooterImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "defaultCheckoutHeaderImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "defaultSignInHeaderImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "defaultLoyaltyPerksImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "defaultLoyaltyInstructionsImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "loyaltySignInButtonImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "loyaltyWithPointsHeaderImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "loyaltyPointsBackgroundImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LoyaltyMediaVM>): LoyaltyMediaVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.defaultProfileHeaderImage = "";
        message.defaultTopCornerImage = "";
        message.defaultRewardsBackgroundImage = "";
        message.defaultRewardImage = "";
        message.defaultProfileFooterImage = "";
        message.defaultCheckoutHeaderImage = "";
        message.defaultSignInHeaderImage = "";
        message.defaultLoyaltyPerksImage = "";
        message.defaultLoyaltyInstructionsImage = "";
        message.loyaltySignInButtonImage = "";
        message.loyaltyWithPointsHeaderImage = "";
        message.loyaltyPointsBackgroundImage = "";
        if (value !== undefined)
            reflectionMergePartial<LoyaltyMediaVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyMediaVM): LoyaltyMediaVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defaultProfileHeaderImage */ 1:
                    message.defaultProfileHeaderImage = reader.string();
                    break;
                case /* string defaultTopCornerImage */ 2:
                    message.defaultTopCornerImage = reader.string();
                    break;
                case /* string defaultRewardsBackgroundImage */ 3:
                    message.defaultRewardsBackgroundImage = reader.string();
                    break;
                case /* string defaultRewardImage */ 4:
                    message.defaultRewardImage = reader.string();
                    break;
                case /* string defaultProfileFooterImage */ 5:
                    message.defaultProfileFooterImage = reader.string();
                    break;
                case /* string defaultCheckoutHeaderImage */ 6:
                    message.defaultCheckoutHeaderImage = reader.string();
                    break;
                case /* string defaultSignInHeaderImage */ 7:
                    message.defaultSignInHeaderImage = reader.string();
                    break;
                case /* string defaultLoyaltyPerksImage */ 8:
                    message.defaultLoyaltyPerksImage = reader.string();
                    break;
                case /* string defaultLoyaltyInstructionsImage */ 9:
                    message.defaultLoyaltyInstructionsImage = reader.string();
                    break;
                case /* string loyaltySignInButtonImage */ 12:
                    message.loyaltySignInButtonImage = reader.string();
                    break;
                case /* string loyaltyWithPointsHeaderImage */ 13:
                    message.loyaltyWithPointsHeaderImage = reader.string();
                    break;
                case /* string loyaltyPointsBackgroundImage */ 14:
                    message.loyaltyPointsBackgroundImage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoyaltyMediaVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defaultProfileHeaderImage = 1; */
        if (message.defaultProfileHeaderImage !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.defaultProfileHeaderImage);
        /* string defaultTopCornerImage = 2; */
        if (message.defaultTopCornerImage !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.defaultTopCornerImage);
        /* string defaultRewardsBackgroundImage = 3; */
        if (message.defaultRewardsBackgroundImage !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.defaultRewardsBackgroundImage);
        /* string defaultRewardImage = 4; */
        if (message.defaultRewardImage !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.defaultRewardImage);
        /* string defaultProfileFooterImage = 5; */
        if (message.defaultProfileFooterImage !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.defaultProfileFooterImage);
        /* string defaultCheckoutHeaderImage = 6; */
        if (message.defaultCheckoutHeaderImage !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.defaultCheckoutHeaderImage);
        /* string defaultSignInHeaderImage = 7; */
        if (message.defaultSignInHeaderImage !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.defaultSignInHeaderImage);
        /* string defaultLoyaltyPerksImage = 8; */
        if (message.defaultLoyaltyPerksImage !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.defaultLoyaltyPerksImage);
        /* string defaultLoyaltyInstructionsImage = 9; */
        if (message.defaultLoyaltyInstructionsImage !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.defaultLoyaltyInstructionsImage);
        /* string loyaltySignInButtonImage = 12; */
        if (message.loyaltySignInButtonImage !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.loyaltySignInButtonImage);
        /* string loyaltyWithPointsHeaderImage = 13; */
        if (message.loyaltyWithPointsHeaderImage !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.loyaltyWithPointsHeaderImage);
        /* string loyaltyPointsBackgroundImage = 14; */
        if (message.loyaltyPointsBackgroundImage !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.loyaltyPointsBackgroundImage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LoyaltyMediaVM
 */
export const LoyaltyMediaVM = new LoyaltyMediaVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountMediaVM$Type extends MessageType<DiscountMediaVM> {
    constructor() {
        super("Kiosk.DiscountMediaVM", [
            { no: 1, name: "defaultDiscountImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "rewardHeaderImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "discountHeaderImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "discountAndRewardHeaderImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "discountCodeInMenuScreenButtonImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "discountCodeOrderReviewScreenButtonImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DiscountMediaVM>): DiscountMediaVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.defaultDiscountImage = "";
        message.rewardHeaderImage = "";
        message.discountHeaderImage = "";
        message.discountAndRewardHeaderImage = "";
        message.discountCodeInMenuScreenButtonImage = "";
        message.discountCodeOrderReviewScreenButtonImage = "";
        if (value !== undefined)
            reflectionMergePartial<DiscountMediaVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountMediaVM): DiscountMediaVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defaultDiscountImage */ 1:
                    message.defaultDiscountImage = reader.string();
                    break;
                case /* string rewardHeaderImage */ 2:
                    message.rewardHeaderImage = reader.string();
                    break;
                case /* string discountHeaderImage */ 3:
                    message.discountHeaderImage = reader.string();
                    break;
                case /* string discountAndRewardHeaderImage */ 4:
                    message.discountAndRewardHeaderImage = reader.string();
                    break;
                case /* string discountCodeInMenuScreenButtonImage */ 5:
                    message.discountCodeInMenuScreenButtonImage = reader.string();
                    break;
                case /* string discountCodeOrderReviewScreenButtonImage */ 6:
                    message.discountCodeOrderReviewScreenButtonImage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountMediaVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defaultDiscountImage = 1; */
        if (message.defaultDiscountImage !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.defaultDiscountImage);
        /* string rewardHeaderImage = 2; */
        if (message.rewardHeaderImage !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.rewardHeaderImage);
        /* string discountHeaderImage = 3; */
        if (message.discountHeaderImage !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.discountHeaderImage);
        /* string discountAndRewardHeaderImage = 4; */
        if (message.discountAndRewardHeaderImage !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.discountAndRewardHeaderImage);
        /* string discountCodeInMenuScreenButtonImage = 5; */
        if (message.discountCodeInMenuScreenButtonImage !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.discountCodeInMenuScreenButtonImage);
        /* string discountCodeOrderReviewScreenButtonImage = 6; */
        if (message.discountCodeOrderReviewScreenButtonImage !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.discountCodeOrderReviewScreenButtonImage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountMediaVM
 */
export const DiscountMediaVM = new DiscountMediaVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemMediaVM$Type extends MessageType<ItemMediaVM> {
    constructor() {
        super("Kiosk.ItemMediaVM", [
            { no: 1, name: "defaultItemImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "defaultRewardItemImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "defaultPromoItemImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ItemMediaVM>): ItemMediaVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.defaultItemImage = "";
        message.defaultRewardItemImage = "";
        message.defaultPromoItemImage = "";
        if (value !== undefined)
            reflectionMergePartial<ItemMediaVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemMediaVM): ItemMediaVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defaultItemImage */ 1:
                    message.defaultItemImage = reader.string();
                    break;
                case /* string defaultRewardItemImage */ 2:
                    message.defaultRewardItemImage = reader.string();
                    break;
                case /* string defaultPromoItemImage */ 3:
                    message.defaultPromoItemImage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemMediaVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defaultItemImage = 1; */
        if (message.defaultItemImage !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.defaultItemImage);
        /* string defaultRewardItemImage = 2; */
        if (message.defaultRewardItemImage !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.defaultRewardItemImage);
        /* string defaultPromoItemImage = 3; */
        if (message.defaultPromoItemImage !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.defaultPromoItemImage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ItemMediaVM
 */
export const ItemMediaVM = new ItemMediaVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderCheckoutMediaVM$Type extends MessageType<OrderCheckoutMediaVM> {
    constructor() {
        super("Kiosk.OrderCheckoutMediaVM", [
            { no: 1, name: "confirmationHeaderImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OrderCheckoutMediaVM>): OrderCheckoutMediaVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.confirmationHeaderImage = "";
        if (value !== undefined)
            reflectionMergePartial<OrderCheckoutMediaVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderCheckoutMediaVM): OrderCheckoutMediaVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string confirmationHeaderImage */ 1:
                    message.confirmationHeaderImage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderCheckoutMediaVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string confirmationHeaderImage = 1; */
        if (message.confirmationHeaderImage !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.confirmationHeaderImage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderCheckoutMediaVM
 */
export const OrderCheckoutMediaVM = new OrderCheckoutMediaVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskMedia$Type extends MessageType<KioskMedia> {
    constructor() {
        super("Kiosk.KioskMedia", [
            { no: 1, name: "screensavers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "defaultCategoryHeaderImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "defaultLoyaltyRewardImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "defaultLoyaltyMedia", kind: "message", T: () => LoyaltyMediaVM },
            { no: 5, name: "discountMedia", kind: "message", T: () => DiscountMediaVM },
            { no: 6, name: "orderCheckoutMedia", kind: "message", T: () => OrderCheckoutMediaVM },
            { no: 7, name: "headerLogoImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "itemDetailHeaderBackgroundImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "itemMedia", kind: "message", T: () => ItemMediaVM }
        ]);
    }
    create(value?: PartialMessage<KioskMedia>): KioskMedia {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.screensavers = [];
        message.defaultCategoryHeaderImage = "";
        message.defaultLoyaltyRewardImage = "";
        message.headerLogoImage = "";
        message.itemDetailHeaderBackgroundImage = "";
        if (value !== undefined)
            reflectionMergePartial<KioskMedia>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskMedia): KioskMedia {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string screensavers */ 1:
                    message.screensavers.push(reader.string());
                    break;
                case /* string defaultCategoryHeaderImage */ 2:
                    message.defaultCategoryHeaderImage = reader.string();
                    break;
                case /* string defaultLoyaltyRewardImage = 3 [deprecated = true];*/ 3:
                    message.defaultLoyaltyRewardImage = reader.string();
                    break;
                case /* Kiosk.LoyaltyMediaVM defaultLoyaltyMedia */ 4:
                    message.defaultLoyaltyMedia = LoyaltyMediaVM.internalBinaryRead(reader, reader.uint32(), options, message.defaultLoyaltyMedia);
                    break;
                case /* Kiosk.DiscountMediaVM discountMedia */ 5:
                    message.discountMedia = DiscountMediaVM.internalBinaryRead(reader, reader.uint32(), options, message.discountMedia);
                    break;
                case /* Kiosk.OrderCheckoutMediaVM orderCheckoutMedia */ 6:
                    message.orderCheckoutMedia = OrderCheckoutMediaVM.internalBinaryRead(reader, reader.uint32(), options, message.orderCheckoutMedia);
                    break;
                case /* string headerLogoImage */ 7:
                    message.headerLogoImage = reader.string();
                    break;
                case /* string itemDetailHeaderBackgroundImage */ 8:
                    message.itemDetailHeaderBackgroundImage = reader.string();
                    break;
                case /* Kiosk.ItemMediaVM itemMedia */ 9:
                    message.itemMedia = ItemMediaVM.internalBinaryRead(reader, reader.uint32(), options, message.itemMedia);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskMedia, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string screensavers = 1; */
        for (let i = 0; i < message.screensavers.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.screensavers[i]);
        /* string defaultCategoryHeaderImage = 2; */
        if (message.defaultCategoryHeaderImage !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.defaultCategoryHeaderImage);
        /* string defaultLoyaltyRewardImage = 3 [deprecated = true]; */
        if (message.defaultLoyaltyRewardImage !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.defaultLoyaltyRewardImage);
        /* Kiosk.LoyaltyMediaVM defaultLoyaltyMedia = 4; */
        if (message.defaultLoyaltyMedia)
            LoyaltyMediaVM.internalBinaryWrite(message.defaultLoyaltyMedia, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountMediaVM discountMedia = 5; */
        if (message.discountMedia)
            DiscountMediaVM.internalBinaryWrite(message.discountMedia, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.OrderCheckoutMediaVM orderCheckoutMedia = 6; */
        if (message.orderCheckoutMedia)
            OrderCheckoutMediaVM.internalBinaryWrite(message.orderCheckoutMedia, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string headerLogoImage = 7; */
        if (message.headerLogoImage !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.headerLogoImage);
        /* string itemDetailHeaderBackgroundImage = 8; */
        if (message.itemDetailHeaderBackgroundImage !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.itemDetailHeaderBackgroundImage);
        /* Kiosk.ItemMediaVM itemMedia = 9; */
        if (message.itemMedia)
            ItemMediaVM.internalBinaryWrite(message.itemMedia, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskMedia
 */
export const KioskMedia = new KioskMedia$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskColors$Type extends MessageType<KioskColors> {
    constructor() {
        super("Kiosk.KioskColors", [
            { no: 1, name: "primary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "secondary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tertiary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "background", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "surface", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "error", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "onPrimary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "onSecondary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "onTertiary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "onBackground", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "onSurface", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "onError", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KioskColors>): KioskColors {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.primary = "";
        message.secondary = "";
        message.tertiary = "";
        message.background = "";
        message.surface = "";
        message.error = "";
        message.onPrimary = "";
        message.onSecondary = "";
        message.onTertiary = "";
        message.onBackground = "";
        message.onSurface = "";
        message.onError = "";
        if (value !== undefined)
            reflectionMergePartial<KioskColors>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskColors): KioskColors {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string primary */ 1:
                    message.primary = reader.string();
                    break;
                case /* string secondary */ 2:
                    message.secondary = reader.string();
                    break;
                case /* string tertiary */ 3:
                    message.tertiary = reader.string();
                    break;
                case /* string background */ 4:
                    message.background = reader.string();
                    break;
                case /* string surface */ 5:
                    message.surface = reader.string();
                    break;
                case /* string error */ 6:
                    message.error = reader.string();
                    break;
                case /* string onPrimary */ 7:
                    message.onPrimary = reader.string();
                    break;
                case /* string onSecondary */ 8:
                    message.onSecondary = reader.string();
                    break;
                case /* string onTertiary */ 9:
                    message.onTertiary = reader.string();
                    break;
                case /* string onBackground */ 10:
                    message.onBackground = reader.string();
                    break;
                case /* string onSurface */ 11:
                    message.onSurface = reader.string();
                    break;
                case /* string onError */ 12:
                    message.onError = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskColors, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string primary = 1; */
        if (message.primary !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.primary);
        /* string secondary = 2; */
        if (message.secondary !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.secondary);
        /* string tertiary = 3; */
        if (message.tertiary !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tertiary);
        /* string background = 4; */
        if (message.background !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.background);
        /* string surface = 5; */
        if (message.surface !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.surface);
        /* string error = 6; */
        if (message.error !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.error);
        /* string onPrimary = 7; */
        if (message.onPrimary !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.onPrimary);
        /* string onSecondary = 8; */
        if (message.onSecondary !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.onSecondary);
        /* string onTertiary = 9; */
        if (message.onTertiary !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.onTertiary);
        /* string onBackground = 10; */
        if (message.onBackground !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.onBackground);
        /* string onSurface = 11; */
        if (message.onSurface !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.onSurface);
        /* string onError = 12; */
        if (message.onError !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.onError);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskColors
 */
export const KioskColors = new KioskColors$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuColors$Type extends MessageType<MenuColors> {
    constructor() {
        super("Kiosk.MenuColors", [
            { no: 1, name: "modifierGroupPositive", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "modifierGroupNegative", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "itemHeaderBackground", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "itemHeaderText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "checkoutHeaderBackground", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "checkoutHeaderText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "logoBackground", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "cartPreviewBorderColor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "cartPreviewFillColor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "categoryHeaderBackgroundColor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "categoryHeaderTextColor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MenuColors>): MenuColors {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.modifierGroupPositive = "";
        message.modifierGroupNegative = "";
        message.itemHeaderBackground = "";
        message.itemHeaderText = "";
        message.checkoutHeaderBackground = "";
        message.checkoutHeaderText = "";
        message.logoBackground = "";
        message.cartPreviewBorderColor = "";
        message.cartPreviewFillColor = "";
        message.categoryHeaderBackgroundColor = "";
        message.categoryHeaderTextColor = "";
        if (value !== undefined)
            reflectionMergePartial<MenuColors>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuColors): MenuColors {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modifierGroupPositive */ 1:
                    message.modifierGroupPositive = reader.string();
                    break;
                case /* string modifierGroupNegative */ 2:
                    message.modifierGroupNegative = reader.string();
                    break;
                case /* string itemHeaderBackground */ 3:
                    message.itemHeaderBackground = reader.string();
                    break;
                case /* string itemHeaderText */ 4:
                    message.itemHeaderText = reader.string();
                    break;
                case /* string checkoutHeaderBackground */ 5:
                    message.checkoutHeaderBackground = reader.string();
                    break;
                case /* string checkoutHeaderText */ 6:
                    message.checkoutHeaderText = reader.string();
                    break;
                case /* string logoBackground */ 7:
                    message.logoBackground = reader.string();
                    break;
                case /* string cartPreviewBorderColor */ 8:
                    message.cartPreviewBorderColor = reader.string();
                    break;
                case /* string cartPreviewFillColor */ 9:
                    message.cartPreviewFillColor = reader.string();
                    break;
                case /* string categoryHeaderBackgroundColor */ 10:
                    message.categoryHeaderBackgroundColor = reader.string();
                    break;
                case /* string categoryHeaderTextColor */ 11:
                    message.categoryHeaderTextColor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuColors, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modifierGroupPositive = 1; */
        if (message.modifierGroupPositive !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modifierGroupPositive);
        /* string modifierGroupNegative = 2; */
        if (message.modifierGroupNegative !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.modifierGroupNegative);
        /* string itemHeaderBackground = 3; */
        if (message.itemHeaderBackground !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.itemHeaderBackground);
        /* string itemHeaderText = 4; */
        if (message.itemHeaderText !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.itemHeaderText);
        /* string checkoutHeaderBackground = 5; */
        if (message.checkoutHeaderBackground !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.checkoutHeaderBackground);
        /* string checkoutHeaderText = 6; */
        if (message.checkoutHeaderText !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.checkoutHeaderText);
        /* string logoBackground = 7; */
        if (message.logoBackground !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.logoBackground);
        /* string cartPreviewBorderColor = 8; */
        if (message.cartPreviewBorderColor !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.cartPreviewBorderColor);
        /* string cartPreviewFillColor = 9; */
        if (message.cartPreviewFillColor !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.cartPreviewFillColor);
        /* string categoryHeaderBackgroundColor = 10; */
        if (message.categoryHeaderBackgroundColor !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.categoryHeaderBackgroundColor);
        /* string categoryHeaderTextColor = 11; */
        if (message.categoryHeaderTextColor !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.categoryHeaderTextColor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuColors
 */
export const MenuColors = new MenuColors$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReceiptConfiguration$Type extends MessageType<ReceiptConfiguration> {
    constructor() {
        super("Kiosk.ReceiptConfiguration", [
            { no: 1, name: "emailReceipt", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "smsReceipt", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "headerText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "footerText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "logoUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "receiptAddress", kind: "message", T: () => Address },
            { no: 7, name: "phone", kind: "message", T: () => PhoneNumberVM },
            { no: 8, name: "autoPrint", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "receiptVisibilityOptions", kind: "message", T: () => ReceiptVisibilityOptions },
            { no: 10, name: "showQrCodeForReceiptUrl", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "showQrCodeWhenPrintReceiptFails", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "generatingQrCodeMsg", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "qrReceiptTitle", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "qrReceiptMsg", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "qrReceiptPrinterFailedMsg", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "localizedHeaderText", kind: "message", T: () => LocalizedStringVM },
            { no: 17, name: "localizedFooterText", kind: "message", T: () => LocalizedStringVM },
            { no: 18, name: "localizedQrReceiptTitle", kind: "message", T: () => LocalizedStringVM },
            { no: 19, name: "localizedQrReceiptMsg", kind: "message", T: () => LocalizedStringVM },
            { no: 20, name: "localizedGeneratingQrCodeMsg", kind: "message", T: () => LocalizedStringVM },
            { no: 21, name: "localizedQrReceiptPrinterFailedMsg", kind: "message", T: () => LocalizedStringVM },
            { no: 22, name: "localizedEmailSubject", kind: "message", T: () => LocalizedStringVM },
            { no: 23, name: "localizedMessageFormat", kind: "message", T: () => LocalizedStringVM }
        ]);
    }
    create(value?: PartialMessage<ReceiptConfiguration>): ReceiptConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.emailReceipt = false;
        message.smsReceipt = false;
        message.headerText = "";
        message.footerText = "";
        message.logoUrl = "";
        message.autoPrint = false;
        message.showQrCodeForReceiptUrl = false;
        message.showQrCodeWhenPrintReceiptFails = false;
        if (value !== undefined)
            reflectionMergePartial<ReceiptConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReceiptConfiguration): ReceiptConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool emailReceipt */ 1:
                    message.emailReceipt = reader.bool();
                    break;
                case /* bool smsReceipt */ 2:
                    message.smsReceipt = reader.bool();
                    break;
                case /* string headerText */ 3:
                    message.headerText = reader.string();
                    break;
                case /* string footerText */ 4:
                    message.footerText = reader.string();
                    break;
                case /* string logoUrl */ 5:
                    message.logoUrl = reader.string();
                    break;
                case /* Kiosk.Address receiptAddress */ 6:
                    message.receiptAddress = Address.internalBinaryRead(reader, reader.uint32(), options, message.receiptAddress);
                    break;
                case /* Kiosk.PhoneNumberVM phone */ 7:
                    message.phone = PhoneNumberVM.internalBinaryRead(reader, reader.uint32(), options, message.phone);
                    break;
                case /* bool autoPrint */ 8:
                    message.autoPrint = reader.bool();
                    break;
                case /* Kiosk.ReceiptVisibilityOptions receiptVisibilityOptions */ 9:
                    message.receiptVisibilityOptions = ReceiptVisibilityOptions.internalBinaryRead(reader, reader.uint32(), options, message.receiptVisibilityOptions);
                    break;
                case /* bool showQrCodeForReceiptUrl */ 10:
                    message.showQrCodeForReceiptUrl = reader.bool();
                    break;
                case /* bool showQrCodeWhenPrintReceiptFails */ 11:
                    message.showQrCodeWhenPrintReceiptFails = reader.bool();
                    break;
                case /* optional string generatingQrCodeMsg */ 12:
                    message.generatingQrCodeMsg = reader.string();
                    break;
                case /* optional string qrReceiptTitle */ 13:
                    message.qrReceiptTitle = reader.string();
                    break;
                case /* optional string qrReceiptMsg */ 14:
                    message.qrReceiptMsg = reader.string();
                    break;
                case /* optional string qrReceiptPrinterFailedMsg */ 15:
                    message.qrReceiptPrinterFailedMsg = reader.string();
                    break;
                case /* Kiosk.LocalizedStringVM localizedHeaderText */ 16:
                    message.localizedHeaderText = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedHeaderText);
                    break;
                case /* Kiosk.LocalizedStringVM localizedFooterText */ 17:
                    message.localizedFooterText = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedFooterText);
                    break;
                case /* Kiosk.LocalizedStringVM localizedQrReceiptTitle */ 18:
                    message.localizedQrReceiptTitle = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedQrReceiptTitle);
                    break;
                case /* Kiosk.LocalizedStringVM localizedQrReceiptMsg */ 19:
                    message.localizedQrReceiptMsg = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedQrReceiptMsg);
                    break;
                case /* Kiosk.LocalizedStringVM localizedGeneratingQrCodeMsg */ 20:
                    message.localizedGeneratingQrCodeMsg = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedGeneratingQrCodeMsg);
                    break;
                case /* Kiosk.LocalizedStringVM localizedQrReceiptPrinterFailedMsg */ 21:
                    message.localizedQrReceiptPrinterFailedMsg = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedQrReceiptPrinterFailedMsg);
                    break;
                case /* Kiosk.LocalizedStringVM localizedEmailSubject */ 22:
                    message.localizedEmailSubject = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedEmailSubject);
                    break;
                case /* Kiosk.LocalizedStringVM localizedMessageFormat */ 23:
                    message.localizedMessageFormat = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedMessageFormat);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReceiptConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool emailReceipt = 1; */
        if (message.emailReceipt !== false)
            writer.tag(1, WireType.Varint).bool(message.emailReceipt);
        /* bool smsReceipt = 2; */
        if (message.smsReceipt !== false)
            writer.tag(2, WireType.Varint).bool(message.smsReceipt);
        /* string headerText = 3; */
        if (message.headerText !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.headerText);
        /* string footerText = 4; */
        if (message.footerText !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.footerText);
        /* string logoUrl = 5; */
        if (message.logoUrl !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.logoUrl);
        /* Kiosk.Address receiptAddress = 6; */
        if (message.receiptAddress)
            Address.internalBinaryWrite(message.receiptAddress, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.PhoneNumberVM phone = 7; */
        if (message.phone)
            PhoneNumberVM.internalBinaryWrite(message.phone, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bool autoPrint = 8; */
        if (message.autoPrint !== false)
            writer.tag(8, WireType.Varint).bool(message.autoPrint);
        /* Kiosk.ReceiptVisibilityOptions receiptVisibilityOptions = 9; */
        if (message.receiptVisibilityOptions)
            ReceiptVisibilityOptions.internalBinaryWrite(message.receiptVisibilityOptions, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* bool showQrCodeForReceiptUrl = 10; */
        if (message.showQrCodeForReceiptUrl !== false)
            writer.tag(10, WireType.Varint).bool(message.showQrCodeForReceiptUrl);
        /* bool showQrCodeWhenPrintReceiptFails = 11; */
        if (message.showQrCodeWhenPrintReceiptFails !== false)
            writer.tag(11, WireType.Varint).bool(message.showQrCodeWhenPrintReceiptFails);
        /* optional string generatingQrCodeMsg = 12; */
        if (message.generatingQrCodeMsg !== undefined)
            writer.tag(12, WireType.LengthDelimited).string(message.generatingQrCodeMsg);
        /* optional string qrReceiptTitle = 13; */
        if (message.qrReceiptTitle !== undefined)
            writer.tag(13, WireType.LengthDelimited).string(message.qrReceiptTitle);
        /* optional string qrReceiptMsg = 14; */
        if (message.qrReceiptMsg !== undefined)
            writer.tag(14, WireType.LengthDelimited).string(message.qrReceiptMsg);
        /* optional string qrReceiptPrinterFailedMsg = 15; */
        if (message.qrReceiptPrinterFailedMsg !== undefined)
            writer.tag(15, WireType.LengthDelimited).string(message.qrReceiptPrinterFailedMsg);
        /* Kiosk.LocalizedStringVM localizedHeaderText = 16; */
        if (message.localizedHeaderText)
            LocalizedStringVM.internalBinaryWrite(message.localizedHeaderText, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringVM localizedFooterText = 17; */
        if (message.localizedFooterText)
            LocalizedStringVM.internalBinaryWrite(message.localizedFooterText, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringVM localizedQrReceiptTitle = 18; */
        if (message.localizedQrReceiptTitle)
            LocalizedStringVM.internalBinaryWrite(message.localizedQrReceiptTitle, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringVM localizedQrReceiptMsg = 19; */
        if (message.localizedQrReceiptMsg)
            LocalizedStringVM.internalBinaryWrite(message.localizedQrReceiptMsg, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringVM localizedGeneratingQrCodeMsg = 20; */
        if (message.localizedGeneratingQrCodeMsg)
            LocalizedStringVM.internalBinaryWrite(message.localizedGeneratingQrCodeMsg, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringVM localizedQrReceiptPrinterFailedMsg = 21; */
        if (message.localizedQrReceiptPrinterFailedMsg)
            LocalizedStringVM.internalBinaryWrite(message.localizedQrReceiptPrinterFailedMsg, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringVM localizedEmailSubject = 22; */
        if (message.localizedEmailSubject)
            LocalizedStringVM.internalBinaryWrite(message.localizedEmailSubject, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringVM localizedMessageFormat = 23; */
        if (message.localizedMessageFormat)
            LocalizedStringVM.internalBinaryWrite(message.localizedMessageFormat, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ReceiptConfiguration
 */
export const ReceiptConfiguration = new ReceiptConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReceiptVisibilityOptions$Type extends MessageType<ReceiptVisibilityOptions> {
    constructor() {
        super("Kiosk.ReceiptVisibilityOptions", [
            { no: 1, name: "modifierGroupNames", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "defaultModifiers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "freeModifiers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "pricedModifiers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ReceiptVisibilityOptions>): ReceiptVisibilityOptions {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.modifierGroupNames = false;
        message.defaultModifiers = false;
        message.freeModifiers = false;
        message.pricedModifiers = false;
        if (value !== undefined)
            reflectionMergePartial<ReceiptVisibilityOptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReceiptVisibilityOptions): ReceiptVisibilityOptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool modifierGroupNames */ 1:
                    message.modifierGroupNames = reader.bool();
                    break;
                case /* bool defaultModifiers */ 2:
                    message.defaultModifiers = reader.bool();
                    break;
                case /* bool freeModifiers */ 3:
                    message.freeModifiers = reader.bool();
                    break;
                case /* bool pricedModifiers */ 4:
                    message.pricedModifiers = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReceiptVisibilityOptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool modifierGroupNames = 1; */
        if (message.modifierGroupNames !== false)
            writer.tag(1, WireType.Varint).bool(message.modifierGroupNames);
        /* bool defaultModifiers = 2; */
        if (message.defaultModifiers !== false)
            writer.tag(2, WireType.Varint).bool(message.defaultModifiers);
        /* bool freeModifiers = 3; */
        if (message.freeModifiers !== false)
            writer.tag(3, WireType.Varint).bool(message.freeModifiers);
        /* bool pricedModifiers = 4; */
        if (message.pricedModifiers !== false)
            writer.tag(4, WireType.Varint).bool(message.pricedModifiers);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ReceiptVisibilityOptions
 */
export const ReceiptVisibilityOptions = new ReceiptVisibilityOptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyColors$Type extends MessageType<LoyaltyColors> {
    constructor() {
        super("Kiosk.LoyaltyColors", [
            { no: 1, name: "onLoyaltyAccent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "loyaltyAccent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "loyalty", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "onLoyalty", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "loyaltyBackground", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LoyaltyColors>): LoyaltyColors {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.onLoyaltyAccent = "";
        message.loyaltyAccent = "";
        message.loyalty = "";
        message.onLoyalty = "";
        message.loyaltyBackground = "";
        if (value !== undefined)
            reflectionMergePartial<LoyaltyColors>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyColors): LoyaltyColors {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string onLoyaltyAccent */ 1:
                    message.onLoyaltyAccent = reader.string();
                    break;
                case /* string loyaltyAccent */ 2:
                    message.loyaltyAccent = reader.string();
                    break;
                case /* string loyalty */ 3:
                    message.loyalty = reader.string();
                    break;
                case /* string onLoyalty */ 4:
                    message.onLoyalty = reader.string();
                    break;
                case /* string loyaltyBackground */ 5:
                    message.loyaltyBackground = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoyaltyColors, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string onLoyaltyAccent = 1; */
        if (message.onLoyaltyAccent !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.onLoyaltyAccent);
        /* string loyaltyAccent = 2; */
        if (message.loyaltyAccent !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.loyaltyAccent);
        /* string loyalty = 3; */
        if (message.loyalty !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.loyalty);
        /* string onLoyalty = 4; */
        if (message.onLoyalty !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.onLoyalty);
        /* string loyaltyBackground = 5; */
        if (message.loyaltyBackground !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.loyaltyBackground);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LoyaltyColors
 */
export const LoyaltyColors = new LoyaltyColors$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskTimeouts$Type extends MessageType<KioskTimeouts> {
    constructor() {
        super("Kiosk.KioskTimeouts", [
            { no: 1, name: "idleTimeout", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "restartPromptTimer", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<KioskTimeouts>): KioskTimeouts {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.idleTimeout = 0;
        message.restartPromptTimer = 0;
        if (value !== undefined)
            reflectionMergePartial<KioskTimeouts>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskTimeouts): KioskTimeouts {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 idleTimeout */ 1:
                    message.idleTimeout = reader.int32();
                    break;
                case /* int32 restartPromptTimer */ 2:
                    message.restartPromptTimer = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskTimeouts, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 idleTimeout = 1; */
        if (message.idleTimeout !== 0)
            writer.tag(1, WireType.Varint).int32(message.idleTimeout);
        /* int32 restartPromptTimer = 2; */
        if (message.restartPromptTimer !== 0)
            writer.tag(2, WireType.Varint).int32(message.restartPromptTimer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskTimeouts
 */
export const KioskTimeouts = new KioskTimeouts$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Tip$Type extends MessageType<Tip> {
    constructor() {
        super("Kiosk.Tip", [
            { no: 1, name: "type", kind: "enum", T: () => ["Kiosk.CalculationType", CalculationType] },
            { no: 2, name: "rate", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "isDefault", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Tip>): Tip {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.rate = 0;
        message.isDefault = false;
        if (value !== undefined)
            reflectionMergePartial<Tip>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Tip): Tip {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.CalculationType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* int32 rate */ 2:
                    message.rate = reader.int32();
                    break;
                case /* bool isDefault */ 3:
                    message.isDefault = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Tip, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.CalculationType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* int32 rate = 2; */
        if (message.rate !== 0)
            writer.tag(2, WireType.Varint).int32(message.rate);
        /* bool isDefault = 3; */
        if (message.isDefault !== false)
            writer.tag(3, WireType.Varint).bool(message.isDefault);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.Tip
 */
export const Tip = new Tip$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskTips$Type extends MessageType<KioskTips> {
    constructor() {
        super("Kiosk.KioskTips", [
            { no: 1, name: "tips", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tip },
            { no: 2, name: "EnableTips", kind: "scalar", jsonName: "EnableTips", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "ShowCalculatedTip", kind: "scalar", jsonName: "ShowCalculatedTip", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "CustomTipEnabled", kind: "scalar", jsonName: "CustomTipEnabled", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "tender", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KioskTips>): KioskTips {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tips = [];
        message.enableTips = false;
        message.showCalculatedTip = false;
        message.customTipEnabled = false;
        message.tender = "";
        if (value !== undefined)
            reflectionMergePartial<KioskTips>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskTips): KioskTips {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.Tip tips */ 1:
                    message.tips.push(Tip.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool EnableTips = 2 [json_name = "EnableTips"];*/ 2:
                    message.enableTips = reader.bool();
                    break;
                case /* bool ShowCalculatedTip = 3 [json_name = "ShowCalculatedTip"];*/ 3:
                    message.showCalculatedTip = reader.bool();
                    break;
                case /* bool CustomTipEnabled = 4 [json_name = "CustomTipEnabled"];*/ 4:
                    message.customTipEnabled = reader.bool();
                    break;
                case /* string tender */ 5:
                    message.tender = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskTips, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.Tip tips = 1; */
        for (let i = 0; i < message.tips.length; i++)
            Tip.internalBinaryWrite(message.tips[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool EnableTips = 2 [json_name = "EnableTips"]; */
        if (message.enableTips !== false)
            writer.tag(2, WireType.Varint).bool(message.enableTips);
        /* bool ShowCalculatedTip = 3 [json_name = "ShowCalculatedTip"]; */
        if (message.showCalculatedTip !== false)
            writer.tag(3, WireType.Varint).bool(message.showCalculatedTip);
        /* bool CustomTipEnabled = 4 [json_name = "CustomTipEnabled"]; */
        if (message.customTipEnabled !== false)
            writer.tag(4, WireType.Varint).bool(message.customTipEnabled);
        /* string tender = 5; */
        if (message.tender !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.tender);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskTips
 */
export const KioskTips = new KioskTips$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PageCustomization$Type extends MessageType<PageCustomization> {
    constructor() {
        super("Kiosk.PageCustomization", [
            { no: 1, name: "backgroundImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PageCustomization>): PageCustomization {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.backgroundImage = "";
        if (value !== undefined)
            reflectionMergePartial<PageCustomization>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PageCustomization): PageCustomization {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string backgroundImage */ 1:
                    message.backgroundImage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PageCustomization, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string backgroundImage = 1; */
        if (message.backgroundImage !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.backgroundImage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.PageCustomization
 */
export const PageCustomization = new PageCustomization$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskCurrency$Type extends MessageType<KioskCurrency> {
    constructor() {
        super("Kiosk.KioskCurrency", [
            { no: 1, name: "currencyId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "divisor", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<KioskCurrency>): KioskCurrency {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.currencyId = "";
        message.divisor = 0;
        if (value !== undefined)
            reflectionMergePartial<KioskCurrency>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskCurrency): KioskCurrency {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string currencyId */ 1:
                    message.currencyId = reader.string();
                    break;
                case /* int32 divisor */ 2:
                    message.divisor = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskCurrency, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string currencyId = 1; */
        if (message.currencyId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.currencyId);
        /* int32 divisor = 2; */
        if (message.divisor !== 0)
            writer.tag(2, WireType.Varint).int32(message.divisor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskCurrency
 */
export const KioskCurrency = new KioskCurrency$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PrinterConfiguration$Type extends MessageType<PrinterConfiguration> {
    constructor() {
        super("Kiosk.PrinterConfiguration", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ipAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "macAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "model", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "invoicePrinter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "tokenPrinter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "kitchenPrinter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "stickyPrinter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "kdsPrinter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "deviceMode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "usbVendorId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PrinterConfiguration>): PrinterConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.ipAddress = "";
        message.macAddress = "";
        message.model = "";
        message.invoicePrinter = false;
        message.tokenPrinter = false;
        message.kitchenPrinter = false;
        message.stickyPrinter = false;
        message.kdsPrinter = false;
        message.enabled = false;
        message.deviceMode = "";
        message.type = "";
        message.usbVendorId = "";
        if (value !== undefined)
            reflectionMergePartial<PrinterConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PrinterConfiguration): PrinterConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string ipAddress */ 2:
                    message.ipAddress = reader.string();
                    break;
                case /* string macAddress */ 3:
                    message.macAddress = reader.string();
                    break;
                case /* string model */ 4:
                    message.model = reader.string();
                    break;
                case /* bool invoicePrinter */ 5:
                    message.invoicePrinter = reader.bool();
                    break;
                case /* bool tokenPrinter */ 6:
                    message.tokenPrinter = reader.bool();
                    break;
                case /* bool kitchenPrinter */ 7:
                    message.kitchenPrinter = reader.bool();
                    break;
                case /* bool stickyPrinter */ 8:
                    message.stickyPrinter = reader.bool();
                    break;
                case /* bool kdsPrinter */ 9:
                    message.kdsPrinter = reader.bool();
                    break;
                case /* bool enabled */ 10:
                    message.enabled = reader.bool();
                    break;
                case /* string deviceMode */ 11:
                    message.deviceMode = reader.string();
                    break;
                case /* string type */ 12:
                    message.type = reader.string();
                    break;
                case /* string usbVendorId */ 13:
                    message.usbVendorId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PrinterConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string ipAddress = 2; */
        if (message.ipAddress !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.ipAddress);
        /* string macAddress = 3; */
        if (message.macAddress !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.macAddress);
        /* string model = 4; */
        if (message.model !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.model);
        /* bool invoicePrinter = 5; */
        if (message.invoicePrinter !== false)
            writer.tag(5, WireType.Varint).bool(message.invoicePrinter);
        /* bool tokenPrinter = 6; */
        if (message.tokenPrinter !== false)
            writer.tag(6, WireType.Varint).bool(message.tokenPrinter);
        /* bool kitchenPrinter = 7; */
        if (message.kitchenPrinter !== false)
            writer.tag(7, WireType.Varint).bool(message.kitchenPrinter);
        /* bool stickyPrinter = 8; */
        if (message.stickyPrinter !== false)
            writer.tag(8, WireType.Varint).bool(message.stickyPrinter);
        /* bool kdsPrinter = 9; */
        if (message.kdsPrinter !== false)
            writer.tag(9, WireType.Varint).bool(message.kdsPrinter);
        /* bool enabled = 10; */
        if (message.enabled !== false)
            writer.tag(10, WireType.Varint).bool(message.enabled);
        /* string deviceMode = 11; */
        if (message.deviceMode !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.deviceMode);
        /* string type = 12; */
        if (message.type !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.type);
        /* string usbVendorId = 13; */
        if (message.usbVendorId !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.usbVendorId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.PrinterConfiguration
 */
export const PrinterConfiguration = new PrinterConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskConfiguration$Type extends MessageType<KioskConfiguration> {
    constructor() {
        super("Kiosk.KioskConfiguration", [
            { no: 1, name: "currency", kind: "message", T: () => KioskCurrency },
            { no: 2, name: "orderTypes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => KioskOrderType },
            { no: 3, name: "tips", kind: "message", T: () => KioskTips },
            { no: 4, name: "paymentDevices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => KioskPaymentDevice },
            { no: 5, name: "LocationDetails", kind: "message", jsonName: "LocationDetails", T: () => LocationDetails },
            { no: 6, name: "timeouts", kind: "message", T: () => KioskTimeouts },
            { no: 8, name: "tokenSettings", kind: "message", T: () => KioskOrderTokenSettings },
            { no: 9, name: "loyaltyConfiguration", kind: "message", T: () => LoyaltyConfiguration },
            { no: 10, name: "appearance", kind: "message", T: () => KioskAppearance },
            { no: 11, name: "locationStaff", kind: "message", T: () => LocationStaffVM },
            { no: 12, name: "CloudPosConfiguration", kind: "message", jsonName: "CloudPosConfiguration", oneof: "posConfiguration", T: () => CloudPosConfiguration },
            { no: 13, name: "ProxyPosConfiguration", kind: "message", jsonName: "ProxyPosConfiguration", oneof: "posConfiguration", T: () => ProxyPosConfiguration },
            { no: 20, name: "DemoPosConfiguration", kind: "message", jsonName: "DemoPosConfiguration", oneof: "posConfiguration", T: () => DemoPosConfiguration },
            { no: 14, name: "concepts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Concept },
            { no: 15, name: "guards", kind: "message", T: () => MessageGuardData },
            { no: 16, name: "layouts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MenuLayout },
            { no: 17, name: "defaultMenuLayout", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "defaultPricebook", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "defaultPaymentTender", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "itemSpecialRequestConfiguration", kind: "message", T: () => ItemSpecialRequestConfiguration },
            { no: 22, name: "receiptConfiguration", kind: "message", T: () => ReceiptConfiguration },
            { no: 24, name: "businessHoursConfiguration", kind: "message", T: () => BusinessHoursConfigurationVM },
            { no: 25, name: "printerConfigurations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PrinterConfiguration },
            { no: 26, name: "upsellConfiguration", kind: "message", T: () => UpsellConfiguration },
            { no: 27, name: "amazonOneConfiguration", kind: "message", T: () => KioskAmazonOneConfiguration },
            { no: 28, name: "disclaimerConfiguration", kind: "message", T: () => DisclaimerConfigurationVM },
            { no: 29, name: "deviceName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "discounts", kind: "message", T: () => DiscountsVM },
            { no: 31, name: "companyID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "adaConfiguration", kind: "message", T: () => AdaConfigurationVM },
            { no: 33, name: "legalCopy", kind: "message", T: () => LegalCopyVM },
            { no: 34, name: "barcodeScannerConfiguration", kind: "message", T: () => BarcodeScannerConfigurationVM },
            { no: 35, name: "discountsEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 36, name: "legalCopyEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 37, name: "posAutoAppliedDiscount", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 38, name: "orderLimitsConfig", kind: "message", T: () => OrderLimitsConfigVM },
            { no: 39, name: "checkPosFlow", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 40, name: "calculateDefaultModifierPrice", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 41, name: "defaultModifierPricingBehavior", kind: "enum", T: () => ["Kiosk.DefaultModifierPricingBehavior", DefaultModifierPricingBehavior] },
            { no: 42, name: "loyaltyFeature", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 43, name: "trackKioskUserBehavior", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 44, name: "pickupFlow", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 45, name: "companyDetails", kind: "message", T: () => CompanyDetails },
            { no: 46, name: "discountSetting", kind: "message", T: () => DiscountSettingVM },
            { no: 47, name: "VisualAssets", kind: "message", jsonName: "VisualAssets", T: () => HardWareVisualAssetVM }
        ]);
    }
    create(value?: PartialMessage<KioskConfiguration>): KioskConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.orderTypes = [];
        message.paymentDevices = [];
        message.posConfiguration = { oneofKind: undefined };
        message.concepts = [];
        message.layouts = [];
        message.defaultMenuLayout = "";
        message.defaultPricebook = "";
        message.defaultPaymentTender = "";
        message.printerConfigurations = [];
        message.deviceName = "";
        message.companyID = "";
        message.discountsEnabled = false;
        message.legalCopyEnabled = false;
        message.posAutoAppliedDiscount = false;
        message.checkPosFlow = false;
        message.calculateDefaultModifierPrice = false;
        message.defaultModifierPricingBehavior = 0;
        message.loyaltyFeature = false;
        message.trackKioskUserBehavior = false;
        message.pickupFlow = false;
        if (value !== undefined)
            reflectionMergePartial<KioskConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskConfiguration): KioskConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.KioskCurrency currency */ 1:
                    message.currency = KioskCurrency.internalBinaryRead(reader, reader.uint32(), options, message.currency);
                    break;
                case /* repeated Kiosk.KioskOrderType orderTypes */ 2:
                    message.orderTypes.push(KioskOrderType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.KioskTips tips */ 3:
                    message.tips = KioskTips.internalBinaryRead(reader, reader.uint32(), options, message.tips);
                    break;
                case /* repeated Kiosk.KioskPaymentDevice paymentDevices */ 4:
                    message.paymentDevices.push(KioskPaymentDevice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.LocationDetails LocationDetails = 5 [json_name = "LocationDetails"];*/ 5:
                    message.locationDetails = LocationDetails.internalBinaryRead(reader, reader.uint32(), options, message.locationDetails);
                    break;
                case /* Kiosk.KioskTimeouts timeouts */ 6:
                    message.timeouts = KioskTimeouts.internalBinaryRead(reader, reader.uint32(), options, message.timeouts);
                    break;
                case /* Kiosk.KioskOrderTokenSettings tokenSettings */ 8:
                    message.tokenSettings = KioskOrderTokenSettings.internalBinaryRead(reader, reader.uint32(), options, message.tokenSettings);
                    break;
                case /* Kiosk.LoyaltyConfiguration loyaltyConfiguration */ 9:
                    message.loyaltyConfiguration = LoyaltyConfiguration.internalBinaryRead(reader, reader.uint32(), options, message.loyaltyConfiguration);
                    break;
                case /* Kiosk.KioskAppearance appearance */ 10:
                    message.appearance = KioskAppearance.internalBinaryRead(reader, reader.uint32(), options, message.appearance);
                    break;
                case /* Kiosk.LocationStaffVM locationStaff */ 11:
                    message.locationStaff = LocationStaffVM.internalBinaryRead(reader, reader.uint32(), options, message.locationStaff);
                    break;
                case /* Kiosk.CloudPosConfiguration CloudPosConfiguration = 12 [json_name = "CloudPosConfiguration"];*/ 12:
                    message.posConfiguration = {
                        oneofKind: "cloudPosConfiguration",
                        cloudPosConfiguration: CloudPosConfiguration.internalBinaryRead(reader, reader.uint32(), options, (message.posConfiguration as any).cloudPosConfiguration)
                    };
                    break;
                case /* Kiosk.ProxyPosConfiguration ProxyPosConfiguration = 13 [json_name = "ProxyPosConfiguration"];*/ 13:
                    message.posConfiguration = {
                        oneofKind: "proxyPosConfiguration",
                        proxyPosConfiguration: ProxyPosConfiguration.internalBinaryRead(reader, reader.uint32(), options, (message.posConfiguration as any).proxyPosConfiguration)
                    };
                    break;
                case /* Kiosk.DemoPosConfiguration DemoPosConfiguration = 20 [json_name = "DemoPosConfiguration"];*/ 20:
                    message.posConfiguration = {
                        oneofKind: "demoPosConfiguration",
                        demoPosConfiguration: DemoPosConfiguration.internalBinaryRead(reader, reader.uint32(), options, (message.posConfiguration as any).demoPosConfiguration)
                    };
                    break;
                case /* repeated Kiosk.Concept concepts */ 14:
                    message.concepts.push(Concept.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.MessageGuardData guards */ 15:
                    message.guards = MessageGuardData.internalBinaryRead(reader, reader.uint32(), options, message.guards);
                    break;
                case /* repeated Kiosk.MenuLayout layouts */ 16:
                    message.layouts.push(MenuLayout.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string defaultMenuLayout */ 17:
                    message.defaultMenuLayout = reader.string();
                    break;
                case /* string defaultPricebook */ 18:
                    message.defaultPricebook = reader.string();
                    break;
                case /* string defaultPaymentTender */ 19:
                    message.defaultPaymentTender = reader.string();
                    break;
                case /* Kiosk.ItemSpecialRequestConfiguration itemSpecialRequestConfiguration */ 21:
                    message.itemSpecialRequestConfiguration = ItemSpecialRequestConfiguration.internalBinaryRead(reader, reader.uint32(), options, message.itemSpecialRequestConfiguration);
                    break;
                case /* Kiosk.ReceiptConfiguration receiptConfiguration */ 22:
                    message.receiptConfiguration = ReceiptConfiguration.internalBinaryRead(reader, reader.uint32(), options, message.receiptConfiguration);
                    break;
                case /* Kiosk.BusinessHoursConfigurationVM businessHoursConfiguration */ 24:
                    message.businessHoursConfiguration = BusinessHoursConfigurationVM.internalBinaryRead(reader, reader.uint32(), options, message.businessHoursConfiguration);
                    break;
                case /* repeated Kiosk.PrinterConfiguration printerConfigurations */ 25:
                    message.printerConfigurations.push(PrinterConfiguration.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.UpsellConfiguration upsellConfiguration */ 26:
                    message.upsellConfiguration = UpsellConfiguration.internalBinaryRead(reader, reader.uint32(), options, message.upsellConfiguration);
                    break;
                case /* Kiosk.KioskAmazonOneConfiguration amazonOneConfiguration */ 27:
                    message.amazonOneConfiguration = KioskAmazonOneConfiguration.internalBinaryRead(reader, reader.uint32(), options, message.amazonOneConfiguration);
                    break;
                case /* Kiosk.DisclaimerConfigurationVM disclaimerConfiguration */ 28:
                    message.disclaimerConfiguration = DisclaimerConfigurationVM.internalBinaryRead(reader, reader.uint32(), options, message.disclaimerConfiguration);
                    break;
                case /* string deviceName */ 29:
                    message.deviceName = reader.string();
                    break;
                case /* Kiosk.DiscountsVM discounts */ 30:
                    message.discounts = DiscountsVM.internalBinaryRead(reader, reader.uint32(), options, message.discounts);
                    break;
                case /* string companyID */ 31:
                    message.companyID = reader.string();
                    break;
                case /* Kiosk.AdaConfigurationVM adaConfiguration */ 32:
                    message.adaConfiguration = AdaConfigurationVM.internalBinaryRead(reader, reader.uint32(), options, message.adaConfiguration);
                    break;
                case /* Kiosk.LegalCopyVM legalCopy */ 33:
                    message.legalCopy = LegalCopyVM.internalBinaryRead(reader, reader.uint32(), options, message.legalCopy);
                    break;
                case /* Kiosk.BarcodeScannerConfigurationVM barcodeScannerConfiguration */ 34:
                    message.barcodeScannerConfiguration = BarcodeScannerConfigurationVM.internalBinaryRead(reader, reader.uint32(), options, message.barcodeScannerConfiguration);
                    break;
                case /* bool discountsEnabled = 35 [deprecated = true];*/ 35:
                    message.discountsEnabled = reader.bool();
                    break;
                case /* bool legalCopyEnabled */ 36:
                    message.legalCopyEnabled = reader.bool();
                    break;
                case /* bool posAutoAppliedDiscount */ 37:
                    message.posAutoAppliedDiscount = reader.bool();
                    break;
                case /* Kiosk.OrderLimitsConfigVM orderLimitsConfig */ 38:
                    message.orderLimitsConfig = OrderLimitsConfigVM.internalBinaryRead(reader, reader.uint32(), options, message.orderLimitsConfig);
                    break;
                case /* bool checkPosFlow */ 39:
                    message.checkPosFlow = reader.bool();
                    break;
                case /* bool calculateDefaultModifierPrice = 40 [deprecated = true];*/ 40:
                    message.calculateDefaultModifierPrice = reader.bool();
                    break;
                case /* Kiosk.DefaultModifierPricingBehavior defaultModifierPricingBehavior */ 41:
                    message.defaultModifierPricingBehavior = reader.int32();
                    break;
                case /* bool loyaltyFeature */ 42:
                    message.loyaltyFeature = reader.bool();
                    break;
                case /* bool trackKioskUserBehavior */ 43:
                    message.trackKioskUserBehavior = reader.bool();
                    break;
                case /* bool pickupFlow */ 44:
                    message.pickupFlow = reader.bool();
                    break;
                case /* Kiosk.CompanyDetails companyDetails */ 45:
                    message.companyDetails = CompanyDetails.internalBinaryRead(reader, reader.uint32(), options, message.companyDetails);
                    break;
                case /* Kiosk.DiscountSettingVM discountSetting = 46 [deprecated = true];*/ 46:
                    message.discountSetting = DiscountSettingVM.internalBinaryRead(reader, reader.uint32(), options, message.discountSetting);
                    break;
                case /* Kiosk.HardWareVisualAssetVM VisualAssets = 47 [json_name = "VisualAssets"];*/ 47:
                    message.visualAssets = HardWareVisualAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.visualAssets);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.KioskCurrency currency = 1; */
        if (message.currency)
            KioskCurrency.internalBinaryWrite(message.currency, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.KioskOrderType orderTypes = 2; */
        for (let i = 0; i < message.orderTypes.length; i++)
            KioskOrderType.internalBinaryWrite(message.orderTypes[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.KioskTips tips = 3; */
        if (message.tips)
            KioskTips.internalBinaryWrite(message.tips, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.KioskPaymentDevice paymentDevices = 4; */
        for (let i = 0; i < message.paymentDevices.length; i++)
            KioskPaymentDevice.internalBinaryWrite(message.paymentDevices[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocationDetails LocationDetails = 5 [json_name = "LocationDetails"]; */
        if (message.locationDetails)
            LocationDetails.internalBinaryWrite(message.locationDetails, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.KioskTimeouts timeouts = 6; */
        if (message.timeouts)
            KioskTimeouts.internalBinaryWrite(message.timeouts, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.KioskOrderTokenSettings tokenSettings = 8; */
        if (message.tokenSettings)
            KioskOrderTokenSettings.internalBinaryWrite(message.tokenSettings, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LoyaltyConfiguration loyaltyConfiguration = 9; */
        if (message.loyaltyConfiguration)
            LoyaltyConfiguration.internalBinaryWrite(message.loyaltyConfiguration, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.KioskAppearance appearance = 10; */
        if (message.appearance)
            KioskAppearance.internalBinaryWrite(message.appearance, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocationStaffVM locationStaff = 11; */
        if (message.locationStaff)
            LocationStaffVM.internalBinaryWrite(message.locationStaff, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.CloudPosConfiguration CloudPosConfiguration = 12 [json_name = "CloudPosConfiguration"]; */
        if (message.posConfiguration.oneofKind === "cloudPosConfiguration")
            CloudPosConfiguration.internalBinaryWrite(message.posConfiguration.cloudPosConfiguration, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.ProxyPosConfiguration ProxyPosConfiguration = 13 [json_name = "ProxyPosConfiguration"]; */
        if (message.posConfiguration.oneofKind === "proxyPosConfiguration")
            ProxyPosConfiguration.internalBinaryWrite(message.posConfiguration.proxyPosConfiguration, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DemoPosConfiguration DemoPosConfiguration = 20 [json_name = "DemoPosConfiguration"]; */
        if (message.posConfiguration.oneofKind === "demoPosConfiguration")
            DemoPosConfiguration.internalBinaryWrite(message.posConfiguration.demoPosConfiguration, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.Concept concepts = 14; */
        for (let i = 0; i < message.concepts.length; i++)
            Concept.internalBinaryWrite(message.concepts[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.MessageGuardData guards = 15; */
        if (message.guards)
            MessageGuardData.internalBinaryWrite(message.guards, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.MenuLayout layouts = 16; */
        for (let i = 0; i < message.layouts.length; i++)
            MenuLayout.internalBinaryWrite(message.layouts[i], writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* string defaultMenuLayout = 17; */
        if (message.defaultMenuLayout !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.defaultMenuLayout);
        /* string defaultPricebook = 18; */
        if (message.defaultPricebook !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.defaultPricebook);
        /* string defaultPaymentTender = 19; */
        if (message.defaultPaymentTender !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.defaultPaymentTender);
        /* Kiosk.ItemSpecialRequestConfiguration itemSpecialRequestConfiguration = 21; */
        if (message.itemSpecialRequestConfiguration)
            ItemSpecialRequestConfiguration.internalBinaryWrite(message.itemSpecialRequestConfiguration, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.ReceiptConfiguration receiptConfiguration = 22; */
        if (message.receiptConfiguration)
            ReceiptConfiguration.internalBinaryWrite(message.receiptConfiguration, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.BusinessHoursConfigurationVM businessHoursConfiguration = 24; */
        if (message.businessHoursConfiguration)
            BusinessHoursConfigurationVM.internalBinaryWrite(message.businessHoursConfiguration, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.PrinterConfiguration printerConfigurations = 25; */
        for (let i = 0; i < message.printerConfigurations.length; i++)
            PrinterConfiguration.internalBinaryWrite(message.printerConfigurations[i], writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.UpsellConfiguration upsellConfiguration = 26; */
        if (message.upsellConfiguration)
            UpsellConfiguration.internalBinaryWrite(message.upsellConfiguration, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.KioskAmazonOneConfiguration amazonOneConfiguration = 27; */
        if (message.amazonOneConfiguration)
            KioskAmazonOneConfiguration.internalBinaryWrite(message.amazonOneConfiguration, writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DisclaimerConfigurationVM disclaimerConfiguration = 28; */
        if (message.disclaimerConfiguration)
            DisclaimerConfigurationVM.internalBinaryWrite(message.disclaimerConfiguration, writer.tag(28, WireType.LengthDelimited).fork(), options).join();
        /* string deviceName = 29; */
        if (message.deviceName !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.deviceName);
        /* Kiosk.DiscountsVM discounts = 30; */
        if (message.discounts)
            DiscountsVM.internalBinaryWrite(message.discounts, writer.tag(30, WireType.LengthDelimited).fork(), options).join();
        /* string companyID = 31; */
        if (message.companyID !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.companyID);
        /* Kiosk.AdaConfigurationVM adaConfiguration = 32; */
        if (message.adaConfiguration)
            AdaConfigurationVM.internalBinaryWrite(message.adaConfiguration, writer.tag(32, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LegalCopyVM legalCopy = 33; */
        if (message.legalCopy)
            LegalCopyVM.internalBinaryWrite(message.legalCopy, writer.tag(33, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.BarcodeScannerConfigurationVM barcodeScannerConfiguration = 34; */
        if (message.barcodeScannerConfiguration)
            BarcodeScannerConfigurationVM.internalBinaryWrite(message.barcodeScannerConfiguration, writer.tag(34, WireType.LengthDelimited).fork(), options).join();
        /* bool discountsEnabled = 35 [deprecated = true]; */
        if (message.discountsEnabled !== false)
            writer.tag(35, WireType.Varint).bool(message.discountsEnabled);
        /* bool legalCopyEnabled = 36; */
        if (message.legalCopyEnabled !== false)
            writer.tag(36, WireType.Varint).bool(message.legalCopyEnabled);
        /* bool posAutoAppliedDiscount = 37; */
        if (message.posAutoAppliedDiscount !== false)
            writer.tag(37, WireType.Varint).bool(message.posAutoAppliedDiscount);
        /* Kiosk.OrderLimitsConfigVM orderLimitsConfig = 38; */
        if (message.orderLimitsConfig)
            OrderLimitsConfigVM.internalBinaryWrite(message.orderLimitsConfig, writer.tag(38, WireType.LengthDelimited).fork(), options).join();
        /* bool checkPosFlow = 39; */
        if (message.checkPosFlow !== false)
            writer.tag(39, WireType.Varint).bool(message.checkPosFlow);
        /* bool calculateDefaultModifierPrice = 40 [deprecated = true]; */
        if (message.calculateDefaultModifierPrice !== false)
            writer.tag(40, WireType.Varint).bool(message.calculateDefaultModifierPrice);
        /* Kiosk.DefaultModifierPricingBehavior defaultModifierPricingBehavior = 41; */
        if (message.defaultModifierPricingBehavior !== 0)
            writer.tag(41, WireType.Varint).int32(message.defaultModifierPricingBehavior);
        /* bool loyaltyFeature = 42; */
        if (message.loyaltyFeature !== false)
            writer.tag(42, WireType.Varint).bool(message.loyaltyFeature);
        /* bool trackKioskUserBehavior = 43; */
        if (message.trackKioskUserBehavior !== false)
            writer.tag(43, WireType.Varint).bool(message.trackKioskUserBehavior);
        /* bool pickupFlow = 44; */
        if (message.pickupFlow !== false)
            writer.tag(44, WireType.Varint).bool(message.pickupFlow);
        /* Kiosk.CompanyDetails companyDetails = 45; */
        if (message.companyDetails)
            CompanyDetails.internalBinaryWrite(message.companyDetails, writer.tag(45, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountSettingVM discountSetting = 46 [deprecated = true]; */
        if (message.discountSetting)
            DiscountSettingVM.internalBinaryWrite(message.discountSetting, writer.tag(46, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.HardWareVisualAssetVM VisualAssets = 47 [json_name = "VisualAssets"]; */
        if (message.visualAssets)
            HardWareVisualAssetVM.internalBinaryWrite(message.visualAssets, writer.tag(47, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskConfiguration
 */
export const KioskConfiguration = new KioskConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderLimitsConfigVM$Type extends MessageType<OrderLimitsConfigVM> {
    constructor() {
        super("Kiosk.OrderLimitsConfigVM", [
            { no: 1, name: "quantityLimitPerItem", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "quantityLimitPerOrder", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "showMakeItAMealOption", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "showItemAsIsOption", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "countToShowPromptsForItemUpsell", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "showStartAtOptionForItem", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "maxDiscountPerOrder", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "DiscountOrderingOptions", kind: "message", jsonName: "DiscountOrderingOptions", T: () => DiscountOrderingOptionsVM }
        ]);
    }
    create(value?: PartialMessage<OrderLimitsConfigVM>): OrderLimitsConfigVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.quantityLimitPerItem = 0;
        message.quantityLimitPerOrder = 0;
        message.showMakeItAMealOption = false;
        message.showItemAsIsOption = false;
        message.countToShowPromptsForItemUpsell = 0;
        message.showStartAtOptionForItem = false;
        message.maxDiscountPerOrder = 0;
        if (value !== undefined)
            reflectionMergePartial<OrderLimitsConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderLimitsConfigVM): OrderLimitsConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 quantityLimitPerItem */ 1:
                    message.quantityLimitPerItem = reader.int32();
                    break;
                case /* int32 quantityLimitPerOrder */ 2:
                    message.quantityLimitPerOrder = reader.int32();
                    break;
                case /* bool showMakeItAMealOption */ 3:
                    message.showMakeItAMealOption = reader.bool();
                    break;
                case /* bool showItemAsIsOption */ 4:
                    message.showItemAsIsOption = reader.bool();
                    break;
                case /* int32 countToShowPromptsForItemUpsell */ 5:
                    message.countToShowPromptsForItemUpsell = reader.int32();
                    break;
                case /* bool showStartAtOptionForItem */ 6:
                    message.showStartAtOptionForItem = reader.bool();
                    break;
                case /* int32 maxDiscountPerOrder */ 7:
                    message.maxDiscountPerOrder = reader.int32();
                    break;
                case /* Kiosk.DiscountOrderingOptionsVM DiscountOrderingOptions = 8 [json_name = "DiscountOrderingOptions"];*/ 8:
                    message.discountOrderingOptions = DiscountOrderingOptionsVM.internalBinaryRead(reader, reader.uint32(), options, message.discountOrderingOptions);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderLimitsConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 quantityLimitPerItem = 1; */
        if (message.quantityLimitPerItem !== 0)
            writer.tag(1, WireType.Varint).int32(message.quantityLimitPerItem);
        /* int32 quantityLimitPerOrder = 2; */
        if (message.quantityLimitPerOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantityLimitPerOrder);
        /* bool showMakeItAMealOption = 3; */
        if (message.showMakeItAMealOption !== false)
            writer.tag(3, WireType.Varint).bool(message.showMakeItAMealOption);
        /* bool showItemAsIsOption = 4; */
        if (message.showItemAsIsOption !== false)
            writer.tag(4, WireType.Varint).bool(message.showItemAsIsOption);
        /* int32 countToShowPromptsForItemUpsell = 5; */
        if (message.countToShowPromptsForItemUpsell !== 0)
            writer.tag(5, WireType.Varint).int32(message.countToShowPromptsForItemUpsell);
        /* bool showStartAtOptionForItem = 6; */
        if (message.showStartAtOptionForItem !== false)
            writer.tag(6, WireType.Varint).bool(message.showStartAtOptionForItem);
        /* int32 maxDiscountPerOrder = 7; */
        if (message.maxDiscountPerOrder !== 0)
            writer.tag(7, WireType.Varint).int32(message.maxDiscountPerOrder);
        /* Kiosk.DiscountOrderingOptionsVM DiscountOrderingOptions = 8 [json_name = "DiscountOrderingOptions"]; */
        if (message.discountOrderingOptions)
            DiscountOrderingOptionsVM.internalBinaryWrite(message.discountOrderingOptions, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderLimitsConfigVM
 */
export const OrderLimitsConfigVM = new OrderLimitsConfigVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountOrderingOptionsVM$Type extends MessageType<DiscountOrderingOptionsVM> {
    constructor() {
        super("Kiosk.DiscountOrderingOptionsVM", [
            { no: 1, name: "canEnterCode", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "canScanQRCode", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "canSelectFromList", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "discountCodeType", kind: "enum", T: () => ["Kiosk.DiscountOrderingOptionsVM.DiscountCodeType", DiscountOrderingOptionsVM_DiscountCodeType] },
            { no: 5, name: "discountCodeMaxLength", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "allowCodeEntryOnOrderReview", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "allowCodeEntryOnCategoryScreen", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DiscountOrderingOptionsVM>): DiscountOrderingOptionsVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.canEnterCode = false;
        message.canScanQRCode = false;
        message.canSelectFromList = false;
        message.discountCodeType = 0;
        message.discountCodeMaxLength = 0;
        message.allowCodeEntryOnOrderReview = false;
        message.allowCodeEntryOnCategoryScreen = false;
        if (value !== undefined)
            reflectionMergePartial<DiscountOrderingOptionsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountOrderingOptionsVM): DiscountOrderingOptionsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool canEnterCode */ 1:
                    message.canEnterCode = reader.bool();
                    break;
                case /* bool canScanQRCode */ 2:
                    message.canScanQRCode = reader.bool();
                    break;
                case /* bool canSelectFromList */ 3:
                    message.canSelectFromList = reader.bool();
                    break;
                case /* Kiosk.DiscountOrderingOptionsVM.DiscountCodeType discountCodeType */ 4:
                    message.discountCodeType = reader.int32();
                    break;
                case /* int32 discountCodeMaxLength */ 5:
                    message.discountCodeMaxLength = reader.int32();
                    break;
                case /* bool allowCodeEntryOnOrderReview */ 6:
                    message.allowCodeEntryOnOrderReview = reader.bool();
                    break;
                case /* bool allowCodeEntryOnCategoryScreen */ 7:
                    message.allowCodeEntryOnCategoryScreen = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountOrderingOptionsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool canEnterCode = 1; */
        if (message.canEnterCode !== false)
            writer.tag(1, WireType.Varint).bool(message.canEnterCode);
        /* bool canScanQRCode = 2; */
        if (message.canScanQRCode !== false)
            writer.tag(2, WireType.Varint).bool(message.canScanQRCode);
        /* bool canSelectFromList = 3; */
        if (message.canSelectFromList !== false)
            writer.tag(3, WireType.Varint).bool(message.canSelectFromList);
        /* Kiosk.DiscountOrderingOptionsVM.DiscountCodeType discountCodeType = 4; */
        if (message.discountCodeType !== 0)
            writer.tag(4, WireType.Varint).int32(message.discountCodeType);
        /* int32 discountCodeMaxLength = 5; */
        if (message.discountCodeMaxLength !== 0)
            writer.tag(5, WireType.Varint).int32(message.discountCodeMaxLength);
        /* bool allowCodeEntryOnOrderReview = 6; */
        if (message.allowCodeEntryOnOrderReview !== false)
            writer.tag(6, WireType.Varint).bool(message.allowCodeEntryOnOrderReview);
        /* bool allowCodeEntryOnCategoryScreen = 7; */
        if (message.allowCodeEntryOnCategoryScreen !== false)
            writer.tag(7, WireType.Varint).bool(message.allowCodeEntryOnCategoryScreen);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountOrderingOptionsVM
 */
export const DiscountOrderingOptionsVM = new DiscountOrderingOptionsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BarcodeScannerConfigurationVM$Type extends MessageType<BarcodeScannerConfigurationVM> {
    constructor() {
        super("Kiosk.BarcodeScannerConfigurationVM", [
            { no: 1, name: "model", kind: "enum", T: () => ["Kiosk.BarcodeScannerConfigurationVM.ExternalBarcodeScannerModel", BarcodeScannerConfigurationVM_ExternalBarcodeScannerModel] }
        ]);
    }
    create(value?: PartialMessage<BarcodeScannerConfigurationVM>): BarcodeScannerConfigurationVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.model = 0;
        if (value !== undefined)
            reflectionMergePartial<BarcodeScannerConfigurationVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BarcodeScannerConfigurationVM): BarcodeScannerConfigurationVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.BarcodeScannerConfigurationVM.ExternalBarcodeScannerModel model */ 1:
                    message.model = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BarcodeScannerConfigurationVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.BarcodeScannerConfigurationVM.ExternalBarcodeScannerModel model = 1; */
        if (message.model !== 0)
            writer.tag(1, WireType.Varint).int32(message.model);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.BarcodeScannerConfigurationVM
 */
export const BarcodeScannerConfigurationVM = new BarcodeScannerConfigurationVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LegalCopyVM$Type extends MessageType<LegalCopyVM> {
    constructor() {
        super("Kiosk.LegalCopyVM", [
            { no: 1, name: "legalButtonText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "legalMessageTitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "legalTextContent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "localizedLegalButtonText", kind: "message", T: () => LocalizedStringVM },
            { no: 5, name: "localizedLegalMessageTitle", kind: "message", T: () => LocalizedStringVM },
            { no: 6, name: "localizedLegalTextContent", kind: "message", T: () => LocalizedStringVM }
        ]);
    }
    create(value?: PartialMessage<LegalCopyVM>): LegalCopyVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.legalButtonText = "";
        message.legalMessageTitle = "";
        message.legalTextContent = "";
        if (value !== undefined)
            reflectionMergePartial<LegalCopyVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LegalCopyVM): LegalCopyVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string legalButtonText */ 1:
                    message.legalButtonText = reader.string();
                    break;
                case /* string legalMessageTitle */ 2:
                    message.legalMessageTitle = reader.string();
                    break;
                case /* string legalTextContent */ 3:
                    message.legalTextContent = reader.string();
                    break;
                case /* Kiosk.LocalizedStringVM localizedLegalButtonText */ 4:
                    message.localizedLegalButtonText = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedLegalButtonText);
                    break;
                case /* Kiosk.LocalizedStringVM localizedLegalMessageTitle */ 5:
                    message.localizedLegalMessageTitle = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedLegalMessageTitle);
                    break;
                case /* Kiosk.LocalizedStringVM localizedLegalTextContent */ 6:
                    message.localizedLegalTextContent = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedLegalTextContent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LegalCopyVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string legalButtonText = 1; */
        if (message.legalButtonText !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.legalButtonText);
        /* string legalMessageTitle = 2; */
        if (message.legalMessageTitle !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.legalMessageTitle);
        /* string legalTextContent = 3; */
        if (message.legalTextContent !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.legalTextContent);
        /* Kiosk.LocalizedStringVM localizedLegalButtonText = 4; */
        if (message.localizedLegalButtonText)
            LocalizedStringVM.internalBinaryWrite(message.localizedLegalButtonText, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringVM localizedLegalMessageTitle = 5; */
        if (message.localizedLegalMessageTitle)
            LocalizedStringVM.internalBinaryWrite(message.localizedLegalMessageTitle, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringVM localizedLegalTextContent = 6; */
        if (message.localizedLegalTextContent)
            LocalizedStringVM.internalBinaryWrite(message.localizedLegalTextContent, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LegalCopyVM
 */
export const LegalCopyVM = new LegalCopyVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdaConfigurationVM$Type extends MessageType<AdaConfigurationVM> {
    constructor() {
        super("Kiosk.AdaConfigurationVM", [
            { no: 1, name: "adaModeEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AdaConfigurationVM>): AdaConfigurationVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.adaModeEnabled = false;
        if (value !== undefined)
            reflectionMergePartial<AdaConfigurationVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdaConfigurationVM): AdaConfigurationVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool adaModeEnabled */ 1:
                    message.adaModeEnabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AdaConfigurationVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool adaModeEnabled = 1; */
        if (message.adaModeEnabled !== false)
            writer.tag(1, WireType.Varint).bool(message.adaModeEnabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.AdaConfigurationVM
 */
export const AdaConfigurationVM = new AdaConfigurationVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisclaimerConfigurationVM$Type extends MessageType<DisclaimerConfigurationVM> {
    constructor() {
        super("Kiosk.DisclaimerConfigurationVM", [
            { no: 1, name: "disclaimerText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "imageLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "localizedDisclaimerText", kind: "message", T: () => LocalizedStringVM }
        ]);
    }
    create(value?: PartialMessage<DisclaimerConfigurationVM>): DisclaimerConfigurationVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.disclaimerText = "";
        message.imageLink = "";
        if (value !== undefined)
            reflectionMergePartial<DisclaimerConfigurationVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisclaimerConfigurationVM): DisclaimerConfigurationVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string disclaimerText */ 1:
                    message.disclaimerText = reader.string();
                    break;
                case /* string imageLink */ 2:
                    message.imageLink = reader.string();
                    break;
                case /* Kiosk.LocalizedStringVM localizedDisclaimerText */ 3:
                    message.localizedDisclaimerText = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedDisclaimerText);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisclaimerConfigurationVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string disclaimerText = 1; */
        if (message.disclaimerText !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.disclaimerText);
        /* string imageLink = 2; */
        if (message.imageLink !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.imageLink);
        /* Kiosk.LocalizedStringVM localizedDisclaimerText = 3; */
        if (message.localizedDisclaimerText)
            LocalizedStringVM.internalBinaryWrite(message.localizedDisclaimerText, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DisclaimerConfigurationVM
 */
export const DisclaimerConfigurationVM = new DisclaimerConfigurationVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskAmazonOneConfiguration$Type extends MessageType<KioskAmazonOneConfiguration> {
    constructor() {
        super("Kiosk.KioskAmazonOneConfiguration", [
            { no: 1, name: "amazonOneEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "interfaceConfiguration", kind: "message", T: () => AmazonOneInterfaceConfiguration }
        ]);
    }
    create(value?: PartialMessage<KioskAmazonOneConfiguration>): KioskAmazonOneConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.amazonOneEnabled = false;
        if (value !== undefined)
            reflectionMergePartial<KioskAmazonOneConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskAmazonOneConfiguration): KioskAmazonOneConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool amazonOneEnabled */ 1:
                    message.amazonOneEnabled = reader.bool();
                    break;
                case /* Kiosk.AmazonOneInterfaceConfiguration interfaceConfiguration */ 2:
                    message.interfaceConfiguration = AmazonOneInterfaceConfiguration.internalBinaryRead(reader, reader.uint32(), options, message.interfaceConfiguration);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskAmazonOneConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool amazonOneEnabled = 1; */
        if (message.amazonOneEnabled !== false)
            writer.tag(1, WireType.Varint).bool(message.amazonOneEnabled);
        /* Kiosk.AmazonOneInterfaceConfiguration interfaceConfiguration = 2; */
        if (message.interfaceConfiguration)
            AmazonOneInterfaceConfiguration.internalBinaryWrite(message.interfaceConfiguration, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskAmazonOneConfiguration
 */
export const KioskAmazonOneConfiguration = new KioskAmazonOneConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AmazonOneInterfaceConfiguration$Type extends MessageType<AmazonOneInterfaceConfiguration> {
    constructor() {
        super("Kiosk.AmazonOneInterfaceConfiguration", [
            { no: 1, name: "usb", kind: "message", oneof: "config", T: () => AmazonOneUsbInterfaceConfiguration },
            { no: 2, name: "rest", kind: "message", oneof: "config", T: () => AmazonOneRestInterfaceConfiguration }
        ]);
    }
    create(value?: PartialMessage<AmazonOneInterfaceConfiguration>): AmazonOneInterfaceConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.config = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<AmazonOneInterfaceConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AmazonOneInterfaceConfiguration): AmazonOneInterfaceConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.AmazonOneUsbInterfaceConfiguration usb */ 1:
                    message.config = {
                        oneofKind: "usb",
                        usb: AmazonOneUsbInterfaceConfiguration.internalBinaryRead(reader, reader.uint32(), options, (message.config as any).usb)
                    };
                    break;
                case /* Kiosk.AmazonOneRestInterfaceConfiguration rest */ 2:
                    message.config = {
                        oneofKind: "rest",
                        rest: AmazonOneRestInterfaceConfiguration.internalBinaryRead(reader, reader.uint32(), options, (message.config as any).rest)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AmazonOneInterfaceConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.AmazonOneUsbInterfaceConfiguration usb = 1; */
        if (message.config.oneofKind === "usb")
            AmazonOneUsbInterfaceConfiguration.internalBinaryWrite(message.config.usb, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.AmazonOneRestInterfaceConfiguration rest = 2; */
        if (message.config.oneofKind === "rest")
            AmazonOneRestInterfaceConfiguration.internalBinaryWrite(message.config.rest, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.AmazonOneInterfaceConfiguration
 */
export const AmazonOneInterfaceConfiguration = new AmazonOneInterfaceConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AmazonOneUsbInterfaceConfiguration$Type extends MessageType<AmazonOneUsbInterfaceConfiguration> {
    constructor() {
        super("Kiosk.AmazonOneUsbInterfaceConfiguration", []);
    }
    create(value?: PartialMessage<AmazonOneUsbInterfaceConfiguration>): AmazonOneUsbInterfaceConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AmazonOneUsbInterfaceConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AmazonOneUsbInterfaceConfiguration): AmazonOneUsbInterfaceConfiguration {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AmazonOneUsbInterfaceConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.AmazonOneUsbInterfaceConfiguration
 */
export const AmazonOneUsbInterfaceConfiguration = new AmazonOneUsbInterfaceConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AmazonOneRestInterfaceConfiguration$Type extends MessageType<AmazonOneRestInterfaceConfiguration> {
    constructor() {
        super("Kiosk.AmazonOneRestInterfaceConfiguration", [
            { no: 1, name: "awsRegion", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "awsService", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "deviceSerialNumber", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "awsHost", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AmazonOneRestInterfaceConfiguration>): AmazonOneRestInterfaceConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.awsRegion = "";
        message.awsService = "";
        message.deviceSerialNumber = "";
        message.awsHost = "";
        if (value !== undefined)
            reflectionMergePartial<AmazonOneRestInterfaceConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AmazonOneRestInterfaceConfiguration): AmazonOneRestInterfaceConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string awsRegion */ 1:
                    message.awsRegion = reader.string();
                    break;
                case /* string awsService */ 2:
                    message.awsService = reader.string();
                    break;
                case /* string deviceSerialNumber */ 3:
                    message.deviceSerialNumber = reader.string();
                    break;
                case /* string awsHost */ 4:
                    message.awsHost = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AmazonOneRestInterfaceConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string awsRegion = 1; */
        if (message.awsRegion !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.awsRegion);
        /* string awsService = 2; */
        if (message.awsService !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.awsService);
        /* string deviceSerialNumber = 3; */
        if (message.deviceSerialNumber !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.deviceSerialNumber);
        /* string awsHost = 4; */
        if (message.awsHost !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.awsHost);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.AmazonOneRestInterfaceConfiguration
 */
export const AmazonOneRestInterfaceConfiguration = new AmazonOneRestInterfaceConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemSpecialRequestConfiguration$Type extends MessageType<ItemSpecialRequestConfiguration> {
    constructor() {
        super("Kiosk.ItemSpecialRequestConfiguration", [
            { no: 1, name: "characterLimit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ItemSpecialRequestConfiguration>): ItemSpecialRequestConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.characterLimit = 0;
        message.enabled = false;
        if (value !== undefined)
            reflectionMergePartial<ItemSpecialRequestConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemSpecialRequestConfiguration): ItemSpecialRequestConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 characterLimit */ 1:
                    message.characterLimit = reader.int32();
                    break;
                case /* bool enabled */ 2:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemSpecialRequestConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 characterLimit = 1; */
        if (message.characterLimit !== 0)
            writer.tag(1, WireType.Varint).int32(message.characterLimit);
        /* bool enabled = 2; */
        if (message.enabled !== false)
            writer.tag(2, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ItemSpecialRequestConfiguration
 */
export const ItemSpecialRequestConfiguration = new ItemSpecialRequestConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CloudPosConfiguration$Type extends MessageType<CloudPosConfiguration> {
    constructor() {
        super("Kiosk.CloudPosConfiguration", []);
    }
    create(value?: PartialMessage<CloudPosConfiguration>): CloudPosConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CloudPosConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CloudPosConfiguration): CloudPosConfiguration {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CloudPosConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.CloudPosConfiguration
 */
export const CloudPosConfiguration = new CloudPosConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProxyPosConfiguration$Type extends MessageType<ProxyPosConfiguration> {
    constructor() {
        super("Kiosk.ProxyPosConfiguration", [
            { no: 1, name: "posProxyUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProxyPosConfiguration>): ProxyPosConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.posProxyUrl = "";
        if (value !== undefined)
            reflectionMergePartial<ProxyPosConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProxyPosConfiguration): ProxyPosConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string posProxyUrl */ 1:
                    message.posProxyUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProxyPosConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string posProxyUrl = 1; */
        if (message.posProxyUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.posProxyUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ProxyPosConfiguration
 */
export const ProxyPosConfiguration = new ProxyPosConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DemoPosConfiguration$Type extends MessageType<DemoPosConfiguration> {
    constructor() {
        super("Kiosk.DemoPosConfiguration", [
            { no: 1, name: "taxRate", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DemoPosConfiguration>): DemoPosConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.taxRate = 0;
        if (value !== undefined)
            reflectionMergePartial<DemoPosConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DemoPosConfiguration): DemoPosConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 taxRate */ 1:
                    message.taxRate = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DemoPosConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 taxRate = 1; */
        if (message.taxRate !== 0)
            writer.tag(1, WireType.Varint).int32(message.taxRate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DemoPosConfiguration
 */
export const DemoPosConfiguration = new DemoPosConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyConfiguration$Type extends MessageType<LoyaltyConfiguration> {
    constructor() {
        super("Kiosk.LoyaltyConfiguration", [
            { no: 1, name: "integrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "punchh", kind: "message", oneof: "configuration", T: () => PunchhConfiguration },
            { no: 6, name: "paytronix", kind: "message", oneof: "configuration", T: () => PaytronixConfiguration },
            { no: 8, name: "rbi", kind: "message", oneof: "configuration", T: () => RbiConfiguration },
            { no: 3, name: "maximum_selectable_rewards", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "tender", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "inputMode", kind: "enum", T: () => ["Kiosk.LoyaltyInputMode", LoyaltyInputMode] },
            { no: 9, name: "rewardFlowBehavior", kind: "enum", T: () => ["Kiosk.IntegrationProviderRewardFlowBehavior", IntegrationProviderRewardFlowBehavior] },
            { no: 10, name: "printGuestCheckCode", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "guestCheckCodePrefaceText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "localizedGuestCheckCodePrefaceText", kind: "message", T: () => LocalizedStringVM }
        ]);
    }
    create(value?: PartialMessage<LoyaltyConfiguration>): LoyaltyConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.integrationId = "";
        message.configuration = { oneofKind: undefined };
        message.maximumSelectableRewards = 0;
        message.enabled = false;
        message.tender = "";
        message.inputMode = 0;
        message.rewardFlowBehavior = 0;
        message.printGuestCheckCode = false;
        message.guestCheckCodePrefaceText = "";
        if (value !== undefined)
            reflectionMergePartial<LoyaltyConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyConfiguration): LoyaltyConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string integrationId */ 1:
                    message.integrationId = reader.string();
                    break;
                case /* Kiosk.PunchhConfiguration punchh */ 2:
                    message.configuration = {
                        oneofKind: "punchh",
                        punchh: PunchhConfiguration.internalBinaryRead(reader, reader.uint32(), options, (message.configuration as any).punchh)
                    };
                    break;
                case /* Kiosk.PaytronixConfiguration paytronix */ 6:
                    message.configuration = {
                        oneofKind: "paytronix",
                        paytronix: PaytronixConfiguration.internalBinaryRead(reader, reader.uint32(), options, (message.configuration as any).paytronix)
                    };
                    break;
                case /* Kiosk.RbiConfiguration rbi */ 8:
                    message.configuration = {
                        oneofKind: "rbi",
                        rbi: RbiConfiguration.internalBinaryRead(reader, reader.uint32(), options, (message.configuration as any).rbi)
                    };
                    break;
                case /* int32 maximum_selectable_rewards */ 3:
                    message.maximumSelectableRewards = reader.int32();
                    break;
                case /* bool enabled = 4 [deprecated = true];*/ 4:
                    message.enabled = reader.bool();
                    break;
                case /* string tender */ 5:
                    message.tender = reader.string();
                    break;
                case /* Kiosk.LoyaltyInputMode inputMode */ 7:
                    message.inputMode = reader.int32();
                    break;
                case /* Kiosk.IntegrationProviderRewardFlowBehavior rewardFlowBehavior */ 9:
                    message.rewardFlowBehavior = reader.int32();
                    break;
                case /* bool printGuestCheckCode */ 10:
                    message.printGuestCheckCode = reader.bool();
                    break;
                case /* string guestCheckCodePrefaceText */ 11:
                    message.guestCheckCodePrefaceText = reader.string();
                    break;
                case /* Kiosk.LocalizedStringVM localizedGuestCheckCodePrefaceText */ 12:
                    message.localizedGuestCheckCodePrefaceText = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedGuestCheckCodePrefaceText);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoyaltyConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string integrationId = 1; */
        if (message.integrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.integrationId);
        /* Kiosk.PunchhConfiguration punchh = 2; */
        if (message.configuration.oneofKind === "punchh")
            PunchhConfiguration.internalBinaryWrite(message.configuration.punchh, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.PaytronixConfiguration paytronix = 6; */
        if (message.configuration.oneofKind === "paytronix")
            PaytronixConfiguration.internalBinaryWrite(message.configuration.paytronix, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.RbiConfiguration rbi = 8; */
        if (message.configuration.oneofKind === "rbi")
            RbiConfiguration.internalBinaryWrite(message.configuration.rbi, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* int32 maximum_selectable_rewards = 3; */
        if (message.maximumSelectableRewards !== 0)
            writer.tag(3, WireType.Varint).int32(message.maximumSelectableRewards);
        /* bool enabled = 4 [deprecated = true]; */
        if (message.enabled !== false)
            writer.tag(4, WireType.Varint).bool(message.enabled);
        /* string tender = 5; */
        if (message.tender !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.tender);
        /* Kiosk.LoyaltyInputMode inputMode = 7; */
        if (message.inputMode !== 0)
            writer.tag(7, WireType.Varint).int32(message.inputMode);
        /* Kiosk.IntegrationProviderRewardFlowBehavior rewardFlowBehavior = 9; */
        if (message.rewardFlowBehavior !== 0)
            writer.tag(9, WireType.Varint).int32(message.rewardFlowBehavior);
        /* bool printGuestCheckCode = 10; */
        if (message.printGuestCheckCode !== false)
            writer.tag(10, WireType.Varint).bool(message.printGuestCheckCode);
        /* string guestCheckCodePrefaceText = 11; */
        if (message.guestCheckCodePrefaceText !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.guestCheckCodePrefaceText);
        /* Kiosk.LocalizedStringVM localizedGuestCheckCodePrefaceText = 12; */
        if (message.localizedGuestCheckCodePrefaceText)
            LocalizedStringVM.internalBinaryWrite(message.localizedGuestCheckCodePrefaceText, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LoyaltyConfiguration
 */
export const LoyaltyConfiguration = new LoyaltyConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BinRange$Type extends MessageType<BinRange> {
    constructor() {
        super("Kiosk.BinRange", [
            { no: 1, name: "from", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "to", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BinRange>): BinRange {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.from = "";
        message.to = "";
        if (value !== undefined)
            reflectionMergePartial<BinRange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BinRange): BinRange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string from */ 1:
                    message.from = reader.string();
                    break;
                case /* string to */ 2:
                    message.to = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BinRange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string from = 1; */
        if (message.from !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.from);
        /* string to = 2; */
        if (message.to !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.to);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.BinRange
 */
export const BinRange = new BinRange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaytronixConfiguration$Type extends MessageType<PaytronixConfiguration> {
    constructor() {
        super("Kiosk.PaytronixConfiguration", [
            { no: 1, name: "binRanges", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BinRange }
        ]);
    }
    create(value?: PartialMessage<PaytronixConfiguration>): PaytronixConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.binRanges = [];
        if (value !== undefined)
            reflectionMergePartial<PaytronixConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaytronixConfiguration): PaytronixConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.BinRange binRanges */ 1:
                    message.binRanges.push(BinRange.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaytronixConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.BinRange binRanges = 1; */
        for (let i = 0; i < message.binRanges.length; i++)
            BinRange.internalBinaryWrite(message.binRanges[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.PaytronixConfiguration
 */
export const PaytronixConfiguration = new PaytronixConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PunchhConfiguration$Type extends MessageType<PunchhConfiguration> {
    constructor() {
        super("Kiosk.PunchhConfiguration", [
            { no: 1, name: "askForDateOfBirth", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "askForGender", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "genderOptions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PunchhConfiguration>): PunchhConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.askForDateOfBirth = false;
        message.askForGender = false;
        message.genderOptions = [];
        if (value !== undefined)
            reflectionMergePartial<PunchhConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PunchhConfiguration): PunchhConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool askForDateOfBirth */ 1:
                    message.askForDateOfBirth = reader.bool();
                    break;
                case /* bool askForGender */ 2:
                    message.askForGender = reader.bool();
                    break;
                case /* repeated string genderOptions */ 3:
                    message.genderOptions.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PunchhConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool askForDateOfBirth = 1; */
        if (message.askForDateOfBirth !== false)
            writer.tag(1, WireType.Varint).bool(message.askForDateOfBirth);
        /* bool askForGender = 2; */
        if (message.askForGender !== false)
            writer.tag(2, WireType.Varint).bool(message.askForGender);
        /* repeated string genderOptions = 3; */
        for (let i = 0; i < message.genderOptions.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.genderOptions[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.PunchhConfiguration
 */
export const PunchhConfiguration = new PunchhConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RbiConfiguration$Type extends MessageType<RbiConfiguration> {
    constructor() {
        super("Kiosk.RbiConfiguration", []);
    }
    create(value?: PartialMessage<RbiConfiguration>): RbiConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RbiConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RbiConfiguration): RbiConfiguration {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RbiConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.RbiConfiguration
 */
export const RbiConfiguration = new RbiConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationDetails$Type extends MessageType<LocationDetails> {
    constructor() {
        super("Kiosk.LocationDetails", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "logo", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "address", kind: "message", T: () => Address },
            { no: 4, name: "localeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "homeScreenLogoUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LocationDetails>): LocationDetails {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.logo = "";
        message.localeId = "";
        message.homeScreenLogoUrl = "";
        if (value !== undefined)
            reflectionMergePartial<LocationDetails>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationDetails): LocationDetails {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string logo */ 2:
                    message.logo = reader.string();
                    break;
                case /* Kiosk.Address address */ 3:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* string localeId */ 4:
                    message.localeId = reader.string();
                    break;
                case /* string homeScreenLogoUrl */ 5:
                    message.homeScreenLogoUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationDetails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string logo = 2; */
        if (message.logo !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.logo);
        /* Kiosk.Address address = 3; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string localeId = 4; */
        if (message.localeId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.localeId);
        /* string homeScreenLogoUrl = 5; */
        if (message.homeScreenLogoUrl !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.homeScreenLogoUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LocationDetails
 */
export const LocationDetails = new LocationDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompanyDetails$Type extends MessageType<CompanyDetails> {
    constructor() {
        super("Kiosk.CompanyDetails", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "organizationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompanyDetails>): CompanyDetails {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.organizationId = "";
        if (value !== undefined)
            reflectionMergePartial<CompanyDetails>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompanyDetails): CompanyDetails {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string organizationId */ 2:
                    message.organizationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompanyDetails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string organizationId = 2; */
        if (message.organizationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.organizationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.CompanyDetails
 */
export const CompanyDetails = new CompanyDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Address$Type extends MessageType<Address> {
    constructor() {
        super("Kiosk.Address", [
            { no: 1, name: "streetOne", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "streetTwo", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "postalcode", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Address>): Address {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.streetOne = "";
        message.streetTwo = "";
        message.city = "";
        message.state = "";
        message.postalcode = "";
        if (value !== undefined)
            reflectionMergePartial<Address>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Address): Address {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string streetOne */ 1:
                    message.streetOne = reader.string();
                    break;
                case /* string streetTwo */ 2:
                    message.streetTwo = reader.string();
                    break;
                case /* string city */ 3:
                    message.city = reader.string();
                    break;
                case /* string state */ 4:
                    message.state = reader.string();
                    break;
                case /* string postalcode */ 5:
                    message.postalcode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Address, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string streetOne = 1; */
        if (message.streetOne !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.streetOne);
        /* string streetTwo = 2; */
        if (message.streetTwo !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.streetTwo);
        /* string city = 3; */
        if (message.city !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.city);
        /* string state = 4; */
        if (message.state !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.state);
        /* string postalcode = 5; */
        if (message.postalcode !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.postalcode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.Address
 */
export const Address = new Address$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskOrderType$Type extends MessageType<KioskOrderType> {
    constructor() {
        super("Kiosk.KioskOrderType", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "externalId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "orderIdentityMode", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "customerIdentityMode", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "nameSettings", kind: "message", T: () => KioskCustomerNameSettings },
            { no: 9, name: "orderIdPrefix", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "name", kind: "enum", T: () => ["Kiosk.IdentityMode", IdentityMode] },
            { no: 11, name: "tableTent", kind: "enum", T: () => ["Kiosk.IdentityMode", IdentityMode] },
            { no: 12, name: "localizedLabel", kind: "message", T: () => LocalizedStringVM }
        ]);
    }
    create(value?: PartialMessage<KioskOrderType>): KioskOrderType {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.label = "";
        message.externalId = "";
        message.orderIdentityMode = 0;
        message.customerIdentityMode = 0;
        message.orderIdPrefix = "";
        message.name = 0;
        message.tableTent = 0;
        if (value !== undefined)
            reflectionMergePartial<KioskOrderType>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskOrderType): KioskOrderType {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                case /* string externalId */ 3:
                    message.externalId = reader.string();
                    break;
                case /* int32 orderIdentityMode = 4 [deprecated = true];*/ 4:
                    message.orderIdentityMode = reader.int32();
                    break;
                case /* int32 customerIdentityMode = 5 [deprecated = true];*/ 5:
                    message.customerIdentityMode = reader.int32();
                    break;
                case /* Kiosk.KioskCustomerNameSettings nameSettings = 7 [deprecated = true];*/ 7:
                    message.nameSettings = KioskCustomerNameSettings.internalBinaryRead(reader, reader.uint32(), options, message.nameSettings);
                    break;
                case /* string orderIdPrefix */ 9:
                    message.orderIdPrefix = reader.string();
                    break;
                case /* Kiosk.IdentityMode name */ 10:
                    message.name = reader.int32();
                    break;
                case /* Kiosk.IdentityMode tableTent */ 11:
                    message.tableTent = reader.int32();
                    break;
                case /* Kiosk.LocalizedStringVM localizedLabel */ 12:
                    message.localizedLabel = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedLabel);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskOrderType, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        /* string externalId = 3; */
        if (message.externalId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.externalId);
        /* int32 orderIdentityMode = 4 [deprecated = true]; */
        if (message.orderIdentityMode !== 0)
            writer.tag(4, WireType.Varint).int32(message.orderIdentityMode);
        /* int32 customerIdentityMode = 5 [deprecated = true]; */
        if (message.customerIdentityMode !== 0)
            writer.tag(5, WireType.Varint).int32(message.customerIdentityMode);
        /* Kiosk.KioskCustomerNameSettings nameSettings = 7 [deprecated = true]; */
        if (message.nameSettings)
            KioskCustomerNameSettings.internalBinaryWrite(message.nameSettings, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string orderIdPrefix = 9; */
        if (message.orderIdPrefix !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.orderIdPrefix);
        /* Kiosk.IdentityMode name = 10; */
        if (message.name !== 0)
            writer.tag(10, WireType.Varint).int32(message.name);
        /* Kiosk.IdentityMode tableTent = 11; */
        if (message.tableTent !== 0)
            writer.tag(11, WireType.Varint).int32(message.tableTent);
        /* Kiosk.LocalizedStringVM localizedLabel = 12; */
        if (message.localizedLabel)
            LocalizedStringVM.internalBinaryWrite(message.localizedLabel, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskOrderType
 */
export const KioskOrderType = new KioskOrderType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocalizedStringVM$Type extends MessageType<LocalizedStringVM> {
    constructor() {
        super("Kiosk.LocalizedStringVM", [
            { no: 1, name: "translations", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<LocalizedStringVM>): LocalizedStringVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.translations = {};
        if (value !== undefined)
            reflectionMergePartial<LocalizedStringVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocalizedStringVM): LocalizedStringVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> translations */ 1:
                    this.binaryReadMap1(message.translations, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: LocalizedStringVM["translations"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LocalizedStringVM["translations"] | undefined, val: LocalizedStringVM["translations"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Kiosk.LocalizedStringVM.translations");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: LocalizedStringVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> translations = 1; */
        for (let k of globalThis.Object.keys(message.translations))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.translations[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LocalizedStringVM
 */
export const LocalizedStringVM = new LocalizedStringVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskOrderTokenSettings$Type extends MessageType<KioskOrderTokenSettings> {
    constructor() {
        super("Kiosk.KioskOrderTokenSettings", [
            { no: 1, name: "orderNumberStart", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "allotment", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<KioskOrderTokenSettings>): KioskOrderTokenSettings {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.orderNumberStart = 0;
        message.allotment = 0;
        if (value !== undefined)
            reflectionMergePartial<KioskOrderTokenSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskOrderTokenSettings): KioskOrderTokenSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 orderNumberStart */ 1:
                    message.orderNumberStart = reader.int32();
                    break;
                case /* int32 allotment */ 2:
                    message.allotment = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskOrderTokenSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 orderNumberStart = 1; */
        if (message.orderNumberStart !== 0)
            writer.tag(1, WireType.Varint).int32(message.orderNumberStart);
        /* int32 allotment = 2; */
        if (message.allotment !== 0)
            writer.tag(2, WireType.Varint).int32(message.allotment);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskOrderTokenSettings
 */
export const KioskOrderTokenSettings = new KioskOrderTokenSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskCustomerNameSettings$Type extends MessageType<KioskCustomerNameSettings> {
    constructor() {
        super("Kiosk.KioskCustomerNameSettings", [
            { no: 1, name: "askBeforeOrder", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<KioskCustomerNameSettings>): KioskCustomerNameSettings {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.askBeforeOrder = false;
        if (value !== undefined)
            reflectionMergePartial<KioskCustomerNameSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskCustomerNameSettings): KioskCustomerNameSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool askBeforeOrder */ 1:
                    message.askBeforeOrder = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskCustomerNameSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool askBeforeOrder = 1; */
        if (message.askBeforeOrder !== false)
            writer.tag(1, WireType.Varint).bool(message.askBeforeOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskCustomerNameSettings
 */
export const KioskCustomerNameSettings = new KioskCustomerNameSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskTableTentSettings$Type extends MessageType<KioskTableTentSettings> {
    constructor() {
        super("Kiosk.KioskTableTentSettings", [
            { no: 1, name: "prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KioskTableTentSettings>): KioskTableTentSettings {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.prefix = "";
        if (value !== undefined)
            reflectionMergePartial<KioskTableTentSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskTableTentSettings): KioskTableTentSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string prefix */ 1:
                    message.prefix = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskTableTentSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string prefix = 1; */
        if (message.prefix !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.prefix);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskTableTentSettings
 */
export const KioskTableTentSettings = new KioskTableTentSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskConfigurationRequest$Type extends MessageType<KioskConfigurationRequest> {
    constructor() {
        super("Kiosk.KioskConfigurationRequest", []);
    }
    create(value?: PartialMessage<KioskConfigurationRequest>): KioskConfigurationRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<KioskConfigurationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskConfigurationRequest): KioskConfigurationRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: KioskConfigurationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskConfigurationRequest
 */
export const KioskConfigurationRequest = new KioskConfigurationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskConfigurationResponse$Type extends MessageType<KioskConfigurationResponse> {
    constructor() {
        super("Kiosk.KioskConfigurationResponse", [
            { no: 1, name: "configuration", kind: "message", T: () => KioskConfiguration },
            { no: 2, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<KioskConfigurationResponse>): KioskConfigurationResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<KioskConfigurationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskConfigurationResponse): KioskConfigurationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.KioskConfiguration configuration */ 1:
                    message.configuration = KioskConfiguration.internalBinaryRead(reader, reader.uint32(), options, message.configuration);
                    break;
                case /* Kiosk.ResponseStatus status */ 2:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskConfigurationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.KioskConfiguration configuration = 1; */
        if (message.configuration)
            KioskConfiguration.internalBinaryWrite(message.configuration, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.ResponseStatus status = 2; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskConfigurationResponse
 */
export const KioskConfigurationResponse = new KioskConfigurationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskPaymentDevice$Type extends MessageType<KioskPaymentDevice> {
    constructor() {
        super("Kiosk.KioskPaymentDevice", [
            { no: 1, name: "paymentMethod", kind: "enum", T: () => ["Kiosk.PaymentMethod", PaymentMethod] },
            { no: 2, name: "paymentIntegrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "tender", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "verifonePaymentDevice", kind: "message", oneof: "paymentDevice", T: () => VerifonePaymentDevice },
            { no: 4, name: "freedomPayDevice", kind: "message", oneof: "paymentDevice", T: () => FreedomPayPaymentDevice },
            { no: 5, name: "cloverPaymentDevice", kind: "message", oneof: "paymentDevice", T: () => CloverPaymentDevice },
            { no: 6, name: "cloudPaymentDevice", kind: "message", oneof: "paymentDevice", T: () => CloudPaymentDevice },
            { no: 7, name: "restPaymentDevice", kind: "message", oneof: "paymentDevice", T: () => RestPaymentDevice },
            { no: 12, name: "paytronixStoredValuePaymentDevice", kind: "message", oneof: "paymentDevice", T: () => PaytronixStoredValuePaymentDevice },
            { no: 13, name: "NCRPaymentDevice", kind: "message", jsonName: "NCRPaymentDevice", oneof: "paymentDevice", T: () => NCRPaymentDevice },
            { no: 14, name: "giftCardPaymentDevice", kind: "message", oneof: "paymentDevice", T: () => GiftCardPaymentDevice },
            { no: 15, name: "SquarePaymentDevice", kind: "message", jsonName: "SquarePaymentDevice", oneof: "paymentDevice", T: () => SquarePaymentDevice },
            { no: 17, name: "freedomPayGiftCardDevice", kind: "message", oneof: "paymentDevice", T: () => FreedomPayGiftCardDevice },
            { no: 18, name: "worldPayPaymentDevice", kind: "message", oneof: "paymentDevice", T: () => WorldPayPaymentDevice },
            { no: 10, name: "creditCards", kind: "message", oneof: "tenders", T: () => CardNetworkMapping },
            { no: 11, name: "tenderId", kind: "scalar", oneof: "tenders", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "checkoutImageUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KioskPaymentDevice>): KioskPaymentDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.paymentMethod = 0;
        message.paymentIntegrationId = "";
        message.tender = "";
        message.label = "";
        message.paymentDevice = { oneofKind: undefined };
        message.tenders = { oneofKind: undefined };
        message.checkoutImageUrl = "";
        if (value !== undefined)
            reflectionMergePartial<KioskPaymentDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskPaymentDevice): KioskPaymentDevice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.PaymentMethod paymentMethod */ 1:
                    message.paymentMethod = reader.int32();
                    break;
                case /* string paymentIntegrationId */ 2:
                    message.paymentIntegrationId = reader.string();
                    break;
                case /* string tender = 8 [deprecated = true];*/ 8:
                    message.tender = reader.string();
                    break;
                case /* string label */ 9:
                    message.label = reader.string();
                    break;
                case /* Kiosk.VerifonePaymentDevice verifonePaymentDevice */ 3:
                    message.paymentDevice = {
                        oneofKind: "verifonePaymentDevice",
                        verifonePaymentDevice: VerifonePaymentDevice.internalBinaryRead(reader, reader.uint32(), options, (message.paymentDevice as any).verifonePaymentDevice)
                    };
                    break;
                case /* Kiosk.FreedomPayPaymentDevice freedomPayDevice */ 4:
                    message.paymentDevice = {
                        oneofKind: "freedomPayDevice",
                        freedomPayDevice: FreedomPayPaymentDevice.internalBinaryRead(reader, reader.uint32(), options, (message.paymentDevice as any).freedomPayDevice)
                    };
                    break;
                case /* Kiosk.CloverPaymentDevice cloverPaymentDevice */ 5:
                    message.paymentDevice = {
                        oneofKind: "cloverPaymentDevice",
                        cloverPaymentDevice: CloverPaymentDevice.internalBinaryRead(reader, reader.uint32(), options, (message.paymentDevice as any).cloverPaymentDevice)
                    };
                    break;
                case /* Kiosk.CloudPaymentDevice cloudPaymentDevice */ 6:
                    message.paymentDevice = {
                        oneofKind: "cloudPaymentDevice",
                        cloudPaymentDevice: CloudPaymentDevice.internalBinaryRead(reader, reader.uint32(), options, (message.paymentDevice as any).cloudPaymentDevice)
                    };
                    break;
                case /* Kiosk.RestPaymentDevice restPaymentDevice */ 7:
                    message.paymentDevice = {
                        oneofKind: "restPaymentDevice",
                        restPaymentDevice: RestPaymentDevice.internalBinaryRead(reader, reader.uint32(), options, (message.paymentDevice as any).restPaymentDevice)
                    };
                    break;
                case /* Kiosk.PaytronixStoredValuePaymentDevice paytronixStoredValuePaymentDevice */ 12:
                    message.paymentDevice = {
                        oneofKind: "paytronixStoredValuePaymentDevice",
                        paytronixStoredValuePaymentDevice: PaytronixStoredValuePaymentDevice.internalBinaryRead(reader, reader.uint32(), options, (message.paymentDevice as any).paytronixStoredValuePaymentDevice)
                    };
                    break;
                case /* Kiosk.NCRPaymentDevice NCRPaymentDevice = 13 [json_name = "NCRPaymentDevice"];*/ 13:
                    message.paymentDevice = {
                        oneofKind: "nCRPaymentDevice",
                        nCRPaymentDevice: NCRPaymentDevice.internalBinaryRead(reader, reader.uint32(), options, (message.paymentDevice as any).nCRPaymentDevice)
                    };
                    break;
                case /* Kiosk.GiftCardPaymentDevice giftCardPaymentDevice */ 14:
                    message.paymentDevice = {
                        oneofKind: "giftCardPaymentDevice",
                        giftCardPaymentDevice: GiftCardPaymentDevice.internalBinaryRead(reader, reader.uint32(), options, (message.paymentDevice as any).giftCardPaymentDevice)
                    };
                    break;
                case /* Kiosk.SquarePaymentDevice SquarePaymentDevice = 15 [json_name = "SquarePaymentDevice"];*/ 15:
                    message.paymentDevice = {
                        oneofKind: "squarePaymentDevice",
                        squarePaymentDevice: SquarePaymentDevice.internalBinaryRead(reader, reader.uint32(), options, (message.paymentDevice as any).squarePaymentDevice)
                    };
                    break;
                case /* Kiosk.FreedomPayGiftCardDevice freedomPayGiftCardDevice */ 17:
                    message.paymentDevice = {
                        oneofKind: "freedomPayGiftCardDevice",
                        freedomPayGiftCardDevice: FreedomPayGiftCardDevice.internalBinaryRead(reader, reader.uint32(), options, (message.paymentDevice as any).freedomPayGiftCardDevice)
                    };
                    break;
                case /* Kiosk.WorldPayPaymentDevice worldPayPaymentDevice */ 18:
                    message.paymentDevice = {
                        oneofKind: "worldPayPaymentDevice",
                        worldPayPaymentDevice: WorldPayPaymentDevice.internalBinaryRead(reader, reader.uint32(), options, (message.paymentDevice as any).worldPayPaymentDevice)
                    };
                    break;
                case /* Kiosk.CardNetworkMapping creditCards */ 10:
                    message.tenders = {
                        oneofKind: "creditCards",
                        creditCards: CardNetworkMapping.internalBinaryRead(reader, reader.uint32(), options, (message.tenders as any).creditCards)
                    };
                    break;
                case /* string tenderId */ 11:
                    message.tenders = {
                        oneofKind: "tenderId",
                        tenderId: reader.string()
                    };
                    break;
                case /* string checkoutImageUrl */ 16:
                    message.checkoutImageUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskPaymentDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.PaymentMethod paymentMethod = 1; */
        if (message.paymentMethod !== 0)
            writer.tag(1, WireType.Varint).int32(message.paymentMethod);
        /* string paymentIntegrationId = 2; */
        if (message.paymentIntegrationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.paymentIntegrationId);
        /* string tender = 8 [deprecated = true]; */
        if (message.tender !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.tender);
        /* string label = 9; */
        if (message.label !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.label);
        /* Kiosk.VerifonePaymentDevice verifonePaymentDevice = 3; */
        if (message.paymentDevice.oneofKind === "verifonePaymentDevice")
            VerifonePaymentDevice.internalBinaryWrite(message.paymentDevice.verifonePaymentDevice, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.FreedomPayPaymentDevice freedomPayDevice = 4; */
        if (message.paymentDevice.oneofKind === "freedomPayDevice")
            FreedomPayPaymentDevice.internalBinaryWrite(message.paymentDevice.freedomPayDevice, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.CloverPaymentDevice cloverPaymentDevice = 5; */
        if (message.paymentDevice.oneofKind === "cloverPaymentDevice")
            CloverPaymentDevice.internalBinaryWrite(message.paymentDevice.cloverPaymentDevice, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.CloudPaymentDevice cloudPaymentDevice = 6; */
        if (message.paymentDevice.oneofKind === "cloudPaymentDevice")
            CloudPaymentDevice.internalBinaryWrite(message.paymentDevice.cloudPaymentDevice, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.RestPaymentDevice restPaymentDevice = 7; */
        if (message.paymentDevice.oneofKind === "restPaymentDevice")
            RestPaymentDevice.internalBinaryWrite(message.paymentDevice.restPaymentDevice, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.PaytronixStoredValuePaymentDevice paytronixStoredValuePaymentDevice = 12; */
        if (message.paymentDevice.oneofKind === "paytronixStoredValuePaymentDevice")
            PaytronixStoredValuePaymentDevice.internalBinaryWrite(message.paymentDevice.paytronixStoredValuePaymentDevice, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.NCRPaymentDevice NCRPaymentDevice = 13 [json_name = "NCRPaymentDevice"]; */
        if (message.paymentDevice.oneofKind === "nCRPaymentDevice")
            NCRPaymentDevice.internalBinaryWrite(message.paymentDevice.nCRPaymentDevice, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.GiftCardPaymentDevice giftCardPaymentDevice = 14; */
        if (message.paymentDevice.oneofKind === "giftCardPaymentDevice")
            GiftCardPaymentDevice.internalBinaryWrite(message.paymentDevice.giftCardPaymentDevice, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.SquarePaymentDevice SquarePaymentDevice = 15 [json_name = "SquarePaymentDevice"]; */
        if (message.paymentDevice.oneofKind === "squarePaymentDevice")
            SquarePaymentDevice.internalBinaryWrite(message.paymentDevice.squarePaymentDevice, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.FreedomPayGiftCardDevice freedomPayGiftCardDevice = 17; */
        if (message.paymentDevice.oneofKind === "freedomPayGiftCardDevice")
            FreedomPayGiftCardDevice.internalBinaryWrite(message.paymentDevice.freedomPayGiftCardDevice, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.WorldPayPaymentDevice worldPayPaymentDevice = 18; */
        if (message.paymentDevice.oneofKind === "worldPayPaymentDevice")
            WorldPayPaymentDevice.internalBinaryWrite(message.paymentDevice.worldPayPaymentDevice, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.CardNetworkMapping creditCards = 10; */
        if (message.tenders.oneofKind === "creditCards")
            CardNetworkMapping.internalBinaryWrite(message.tenders.creditCards, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* string tenderId = 11; */
        if (message.tenders.oneofKind === "tenderId")
            writer.tag(11, WireType.LengthDelimited).string(message.tenders.tenderId);
        /* string checkoutImageUrl = 16; */
        if (message.checkoutImageUrl !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.checkoutImageUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskPaymentDevice
 */
export const KioskPaymentDevice = new KioskPaymentDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestPaymentDevice$Type extends MessageType<RestPaymentDevice> {
    constructor() {
        super("Kiosk.RestPaymentDevice", [
            { no: 1, name: "paymentUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "refundUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "statusUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "settleUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "data", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<RestPaymentDevice>): RestPaymentDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.paymentUrl = "";
        message.refundUrl = "";
        message.statusUrl = "";
        message.settleUrl = "";
        message.data = {};
        if (value !== undefined)
            reflectionMergePartial<RestPaymentDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestPaymentDevice): RestPaymentDevice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string paymentUrl */ 1:
                    message.paymentUrl = reader.string();
                    break;
                case /* string refundUrl */ 2:
                    message.refundUrl = reader.string();
                    break;
                case /* string statusUrl */ 3:
                    message.statusUrl = reader.string();
                    break;
                case /* string settleUrl */ 4:
                    message.settleUrl = reader.string();
                    break;
                case /* map<string, string> data */ 5:
                    this.binaryReadMap5(message.data, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: RestPaymentDevice["data"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof RestPaymentDevice["data"] | undefined, val: RestPaymentDevice["data"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Kiosk.RestPaymentDevice.data");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: RestPaymentDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string paymentUrl = 1; */
        if (message.paymentUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.paymentUrl);
        /* string refundUrl = 2; */
        if (message.refundUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.refundUrl);
        /* string statusUrl = 3; */
        if (message.statusUrl !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.statusUrl);
        /* string settleUrl = 4; */
        if (message.settleUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.settleUrl);
        /* map<string, string> data = 5; */
        for (let k of globalThis.Object.keys(message.data))
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.data[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.RestPaymentDevice
 */
export const RestPaymentDevice = new RestPaymentDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GiftCardPaymentDevice$Type extends MessageType<GiftCardPaymentDevice> {
    constructor() {
        super("Kiosk.GiftCardPaymentDevice", [
            { no: 1, name: "binRanges", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BinRange }
        ]);
    }
    create(value?: PartialMessage<GiftCardPaymentDevice>): GiftCardPaymentDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.binRanges = [];
        if (value !== undefined)
            reflectionMergePartial<GiftCardPaymentDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GiftCardPaymentDevice): GiftCardPaymentDevice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.BinRange binRanges */ 1:
                    message.binRanges.push(BinRange.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GiftCardPaymentDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.BinRange binRanges = 1; */
        for (let i = 0; i < message.binRanges.length; i++)
            BinRange.internalBinaryWrite(message.binRanges[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.GiftCardPaymentDevice
 */
export const GiftCardPaymentDevice = new GiftCardPaymentDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CloudPaymentDevice$Type extends MessageType<CloudPaymentDevice> {
    constructor() {
        super("Kiosk.CloudPaymentDevice", []);
    }
    create(value?: PartialMessage<CloudPaymentDevice>): CloudPaymentDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CloudPaymentDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CloudPaymentDevice): CloudPaymentDevice {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CloudPaymentDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.CloudPaymentDevice
 */
export const CloudPaymentDevice = new CloudPaymentDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaytronixStoredValuePaymentDevice$Type extends MessageType<PaytronixStoredValuePaymentDevice> {
    constructor() {
        super("Kiosk.PaytronixStoredValuePaymentDevice", []);
    }
    create(value?: PartialMessage<PaytronixStoredValuePaymentDevice>): PaytronixStoredValuePaymentDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<PaytronixStoredValuePaymentDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaytronixStoredValuePaymentDevice): PaytronixStoredValuePaymentDevice {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PaytronixStoredValuePaymentDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.PaytronixStoredValuePaymentDevice
 */
export const PaytronixStoredValuePaymentDevice = new PaytronixStoredValuePaymentDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifonePaymentDevice$Type extends MessageType<VerifonePaymentDevice> {
    constructor() {
        super("Kiosk.VerifonePaymentDevice", [
            { no: 1, name: "ipAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "authorizeOnly", kind: "message", T: () => BoolValue }
        ]);
    }
    create(value?: PartialMessage<VerifonePaymentDevice>): VerifonePaymentDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ipAddress = "";
        if (value !== undefined)
            reflectionMergePartial<VerifonePaymentDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifonePaymentDevice): VerifonePaymentDevice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ipAddress */ 1:
                    message.ipAddress = reader.string();
                    break;
                case /* google.protobuf.BoolValue authorizeOnly */ 2:
                    message.authorizeOnly = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.authorizeOnly);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifonePaymentDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ipAddress = 1; */
        if (message.ipAddress !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ipAddress);
        /* google.protobuf.BoolValue authorizeOnly = 2; */
        if (message.authorizeOnly)
            BoolValue.internalBinaryWrite(message.authorizeOnly, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.VerifonePaymentDevice
 */
export const VerifonePaymentDevice = new VerifonePaymentDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NCRPaymentDevice$Type extends MessageType<NCRPaymentDevice> {
    constructor() {
        super("Kiosk.NCRPaymentDevice", [
            { no: 1, name: "clientId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "companyNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "configAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "configPort", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "laneNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "storeNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "hmacClientKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "hmacSecretKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "hostAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "hostPort", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<NCRPaymentDevice>): NCRPaymentDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.clientId = "";
        message.companyNumber = 0;
        message.configAddress = "";
        message.configPort = 0;
        message.laneNumber = 0;
        message.storeNumber = 0;
        message.hmacClientKey = "";
        message.hmacSecretKey = "";
        message.hostAddress = "";
        message.hostPort = 0;
        if (value !== undefined)
            reflectionMergePartial<NCRPaymentDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NCRPaymentDevice): NCRPaymentDevice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string clientId */ 1:
                    message.clientId = reader.string();
                    break;
                case /* int32 companyNumber */ 3:
                    message.companyNumber = reader.int32();
                    break;
                case /* string configAddress */ 4:
                    message.configAddress = reader.string();
                    break;
                case /* int32 configPort */ 5:
                    message.configPort = reader.int32();
                    break;
                case /* int32 laneNumber */ 6:
                    message.laneNumber = reader.int32();
                    break;
                case /* int32 storeNumber */ 7:
                    message.storeNumber = reader.int32();
                    break;
                case /* string hmacClientKey */ 8:
                    message.hmacClientKey = reader.string();
                    break;
                case /* string hmacSecretKey */ 9:
                    message.hmacSecretKey = reader.string();
                    break;
                case /* string hostAddress */ 10:
                    message.hostAddress = reader.string();
                    break;
                case /* int32 hostPort */ 11:
                    message.hostPort = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NCRPaymentDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string clientId = 1; */
        if (message.clientId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.clientId);
        /* int32 companyNumber = 3; */
        if (message.companyNumber !== 0)
            writer.tag(3, WireType.Varint).int32(message.companyNumber);
        /* string configAddress = 4; */
        if (message.configAddress !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.configAddress);
        /* int32 configPort = 5; */
        if (message.configPort !== 0)
            writer.tag(5, WireType.Varint).int32(message.configPort);
        /* int32 laneNumber = 6; */
        if (message.laneNumber !== 0)
            writer.tag(6, WireType.Varint).int32(message.laneNumber);
        /* int32 storeNumber = 7; */
        if (message.storeNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.storeNumber);
        /* string hmacClientKey = 8; */
        if (message.hmacClientKey !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.hmacClientKey);
        /* string hmacSecretKey = 9; */
        if (message.hmacSecretKey !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.hmacSecretKey);
        /* string hostAddress = 10; */
        if (message.hostAddress !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.hostAddress);
        /* int32 hostPort = 11; */
        if (message.hostPort !== 0)
            writer.tag(11, WireType.Varint).int32(message.hostPort);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.NCRPaymentDevice
 */
export const NCRPaymentDevice = new NCRPaymentDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FreedomPayPaymentDevice$Type extends MessageType<FreedomPayPaymentDevice> {
    constructor() {
        super("Kiosk.FreedomPayPaymentDevice", [
            { no: 1, name: "terminalId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "storeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "esKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "floorLimit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<FreedomPayPaymentDevice>): FreedomPayPaymentDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.terminalId = "";
        message.storeId = "";
        message.esKey = "";
        message.floorLimit = 0;
        if (value !== undefined)
            reflectionMergePartial<FreedomPayPaymentDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FreedomPayPaymentDevice): FreedomPayPaymentDevice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string terminalId */ 1:
                    message.terminalId = reader.string();
                    break;
                case /* string storeId */ 2:
                    message.storeId = reader.string();
                    break;
                case /* string esKey */ 3:
                    message.esKey = reader.string();
                    break;
                case /* int32 floorLimit */ 4:
                    message.floorLimit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FreedomPayPaymentDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string terminalId = 1; */
        if (message.terminalId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.terminalId);
        /* string storeId = 2; */
        if (message.storeId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.storeId);
        /* string esKey = 3; */
        if (message.esKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.esKey);
        /* int32 floorLimit = 4; */
        if (message.floorLimit !== 0)
            writer.tag(4, WireType.Varint).int32(message.floorLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.FreedomPayPaymentDevice
 */
export const FreedomPayPaymentDevice = new FreedomPayPaymentDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WorldPayPaymentDevice$Type extends MessageType<WorldPayPaymentDevice> {
    constructor() {
        super("Kiosk.WorldPayPaymentDevice", [
            { no: 1, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "applicationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "acceptorID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "accountToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "offlineTransactionLimit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "offlineTotalLimit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<WorldPayPaymentDevice>): WorldPayPaymentDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = "";
        message.applicationID = "";
        message.acceptorID = "";
        message.accountToken = "";
        message.offlineTransactionLimit = 0;
        message.offlineTotalLimit = 0;
        if (value !== undefined)
            reflectionMergePartial<WorldPayPaymentDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WorldPayPaymentDevice): WorldPayPaymentDevice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountID */ 1:
                    message.accountID = reader.string();
                    break;
                case /* string applicationID */ 2:
                    message.applicationID = reader.string();
                    break;
                case /* string acceptorID */ 3:
                    message.acceptorID = reader.string();
                    break;
                case /* string accountToken */ 4:
                    message.accountToken = reader.string();
                    break;
                case /* int32 offlineTransactionLimit */ 5:
                    message.offlineTransactionLimit = reader.int32();
                    break;
                case /* int32 offlineTotalLimit */ 6:
                    message.offlineTotalLimit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WorldPayPaymentDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountID = 1; */
        if (message.accountID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountID);
        /* string applicationID = 2; */
        if (message.applicationID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.applicationID);
        /* string acceptorID = 3; */
        if (message.acceptorID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.acceptorID);
        /* string accountToken = 4; */
        if (message.accountToken !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountToken);
        /* int32 offlineTransactionLimit = 5; */
        if (message.offlineTransactionLimit !== 0)
            writer.tag(5, WireType.Varint).int32(message.offlineTransactionLimit);
        /* int32 offlineTotalLimit = 6; */
        if (message.offlineTotalLimit !== 0)
            writer.tag(6, WireType.Varint).int32(message.offlineTotalLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.WorldPayPaymentDevice
 */
export const WorldPayPaymentDevice = new WorldPayPaymentDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FreedomPayGiftCardDevice$Type extends MessageType<FreedomPayGiftCardDevice> {
    constructor() {
        super("Kiosk.FreedomPayGiftCardDevice", [
            { no: 1, name: "terminalId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "storeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "esKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FreedomPayGiftCardDevice>): FreedomPayGiftCardDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.terminalId = "";
        message.storeId = "";
        message.esKey = "";
        if (value !== undefined)
            reflectionMergePartial<FreedomPayGiftCardDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FreedomPayGiftCardDevice): FreedomPayGiftCardDevice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string terminalId */ 1:
                    message.terminalId = reader.string();
                    break;
                case /* string storeId */ 2:
                    message.storeId = reader.string();
                    break;
                case /* string esKey */ 3:
                    message.esKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FreedomPayGiftCardDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string terminalId = 1; */
        if (message.terminalId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.terminalId);
        /* string storeId = 2; */
        if (message.storeId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.storeId);
        /* string esKey = 3; */
        if (message.esKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.esKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.FreedomPayGiftCardDevice
 */
export const FreedomPayGiftCardDevice = new FreedomPayGiftCardDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CloverPaymentDevice$Type extends MessageType<CloverPaymentDevice> {
    constructor() {
        super("Kiosk.CloverPaymentDevice", [
            { no: 1, name: "baseUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "applicationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "deviceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "merchantId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "accessToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CloverPaymentDevice>): CloverPaymentDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.baseUrl = "";
        message.applicationId = "";
        message.deviceId = "";
        message.merchantId = "";
        message.accessToken = "";
        if (value !== undefined)
            reflectionMergePartial<CloverPaymentDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CloverPaymentDevice): CloverPaymentDevice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string baseUrl */ 1:
                    message.baseUrl = reader.string();
                    break;
                case /* string applicationId */ 2:
                    message.applicationId = reader.string();
                    break;
                case /* string deviceId */ 3:
                    message.deviceId = reader.string();
                    break;
                case /* string merchantId */ 4:
                    message.merchantId = reader.string();
                    break;
                case /* string accessToken */ 5:
                    message.accessToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CloverPaymentDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string baseUrl = 1; */
        if (message.baseUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.baseUrl);
        /* string applicationId = 2; */
        if (message.applicationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.applicationId);
        /* string deviceId = 3; */
        if (message.deviceId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.deviceId);
        /* string merchantId = 4; */
        if (message.merchantId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.merchantId);
        /* string accessToken = 5; */
        if (message.accessToken !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.accessToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.CloverPaymentDevice
 */
export const CloverPaymentDevice = new CloverPaymentDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SquarePaymentDevice$Type extends MessageType<SquarePaymentDevice> {
    constructor() {
        super("Kiosk.SquarePaymentDevice", [
            { no: 1, name: "configId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "deviceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SquarePaymentDevice>): SquarePaymentDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.configId = "";
        message.deviceId = "";
        if (value !== undefined)
            reflectionMergePartial<SquarePaymentDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SquarePaymentDevice): SquarePaymentDevice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string configId */ 1:
                    message.configId = reader.string();
                    break;
                case /* string deviceId */ 2:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SquarePaymentDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string configId = 1; */
        if (message.configId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.configId);
        /* string deviceId = 2; */
        if (message.deviceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.SquarePaymentDevice
 */
export const SquarePaymentDevice = new SquarePaymentDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogMessage$Type extends MessageType<LogMessage> {
    constructor() {
        super("Kiosk.LogMessage", [
            { no: 1, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "level", kind: "enum", T: () => ["Kiosk.LogLevel", LogLevel] },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LogMessage>): LogMessage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.timestamp = "";
        message.level = 0;
        message.message = "";
        if (value !== undefined)
            reflectionMergePartial<LogMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogMessage): LogMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string timestamp */ 1:
                    message.timestamp = reader.string();
                    break;
                case /* Kiosk.LogLevel level */ 2:
                    message.level = reader.int32();
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string timestamp = 1; */
        if (message.timestamp !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.timestamp);
        /* Kiosk.LogLevel level = 2; */
        if (message.level !== 0)
            writer.tag(2, WireType.Varint).int32(message.level);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LogMessage
 */
export const LogMessage = new LogMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StaffVM$Type extends MessageType<StaffVM> {
    constructor() {
        super("Kiosk.StaffVM", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accessCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StaffVM>): StaffVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.accessCode = "";
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<StaffVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StaffVM): StaffVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string accessCode */ 2:
                    message.accessCode = reader.string();
                    break;
                case /* string id */ 3:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StaffVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string accessCode = 2; */
        if (message.accessCode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accessCode);
        /* string id = 3; */
        if (message.id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.StaffVM
 */
export const StaffVM = new StaffVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationStaffVM$Type extends MessageType<LocationStaffVM> {
    constructor() {
        super("Kiosk.LocationStaffVM", [
            { no: 1, name: "staff", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StaffVM }
        ]);
    }
    create(value?: PartialMessage<LocationStaffVM>): LocationStaffVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.staff = [];
        if (value !== undefined)
            reflectionMergePartial<LocationStaffVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationStaffVM): LocationStaffVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.StaffVM staff */ 1:
                    message.staff.push(StaffVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationStaffVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.StaffVM staff = 1; */
        for (let i = 0; i < message.staff.length; i++)
            StaffVM.internalBinaryWrite(message.staff[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LocationStaffVM
 */
export const LocationStaffVM = new LocationStaffVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Concept$Type extends MessageType<Concept> {
    constructor() {
        super("Kiosk.Concept", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "theme", kind: "message", T: () => ConceptTheme },
            { no: 4, name: "layoutId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Concept>): Concept {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.layoutId = "";
        if (value !== undefined)
            reflectionMergePartial<Concept>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Concept): Concept {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* Kiosk.ConceptTheme theme */ 3:
                    message.theme = ConceptTheme.internalBinaryRead(reader, reader.uint32(), options, message.theme);
                    break;
                case /* string layoutId */ 4:
                    message.layoutId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Concept, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* Kiosk.ConceptTheme theme = 3; */
        if (message.theme)
            ConceptTheme.internalBinaryWrite(message.theme, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string layoutId = 4; */
        if (message.layoutId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.layoutId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.Concept
 */
export const Concept = new Concept$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConceptTheme$Type extends MessageType<ConceptTheme> {
    constructor() {
        super("Kiosk.ConceptTheme", [
            { no: 1, name: "media", kind: "message", T: () => ConceptMedia },
            { no: 2, name: "colors", kind: "message", T: () => KioskColors }
        ]);
    }
    create(value?: PartialMessage<ConceptTheme>): ConceptTheme {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ConceptTheme>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConceptTheme): ConceptTheme {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.ConceptMedia media */ 1:
                    message.media = ConceptMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* Kiosk.KioskColors colors */ 2:
                    message.colors = KioskColors.internalBinaryRead(reader, reader.uint32(), options, message.colors);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConceptTheme, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.ConceptMedia media = 1; */
        if (message.media)
            ConceptMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.KioskColors colors = 2; */
        if (message.colors)
            KioskColors.internalBinaryWrite(message.colors, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ConceptTheme
 */
export const ConceptTheme = new ConceptTheme$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConceptMedia$Type extends MessageType<ConceptMedia> {
    constructor() {
        super("Kiosk.ConceptMedia", [
            { no: 1, name: "categoryHeader", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "logo", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ConceptMedia>): ConceptMedia {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.categoryHeader = "";
        message.logo = "";
        if (value !== undefined)
            reflectionMergePartial<ConceptMedia>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConceptMedia): ConceptMedia {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string categoryHeader */ 1:
                    message.categoryHeader = reader.string();
                    break;
                case /* string logo */ 2:
                    message.logo = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConceptMedia, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string categoryHeader = 1; */
        if (message.categoryHeader !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.categoryHeader);
        /* string logo = 2; */
        if (message.logo !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.logo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ConceptMedia
 */
export const ConceptMedia = new ConceptMedia$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageVM$Type extends MessageType<MessageVM> {
    constructor() {
        super("Kiosk.MessageVM", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subtitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MessageVM>): MessageVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.title = "";
        message.subtitle = "";
        if (value !== undefined)
            reflectionMergePartial<MessageVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageVM): MessageVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string subtitle */ 2:
                    message.subtitle = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string subtitle = 2; */
        if (message.subtitle !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subtitle);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MessageVM
 */
export const MessageVM = new MessageVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageGuardVM$Type extends MessageType<MessageGuardVM> {
    constructor() {
        super("Kiosk.MessageGuardVM", [
            { no: 1, name: "message", kind: "message", T: () => MessageVM },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "trigger", kind: "enum", T: () => ["Kiosk.GuardTrigger", GuardTrigger] },
            { no: 5, name: "image", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "items", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "categories", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MessageGuardVM>): MessageGuardVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.trigger = 0;
        message.image = "";
        message.items = [];
        message.categories = [];
        if (value !== undefined)
            reflectionMergePartial<MessageGuardVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageGuardVM): MessageGuardVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.MessageVM message */ 1:
                    message.message = MessageVM.internalBinaryRead(reader, reader.uint32(), options, message.message);
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* Kiosk.GuardTrigger trigger */ 4:
                    message.trigger = reader.int32();
                    break;
                case /* string image */ 5:
                    message.image = reader.string();
                    break;
                case /* repeated string items */ 6:
                    message.items.push(reader.string());
                    break;
                case /* repeated string categories */ 7:
                    message.categories.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageGuardVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.MessageVM message = 1; */
        if (message.message)
            MessageVM.internalBinaryWrite(message.message, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* Kiosk.GuardTrigger trigger = 4; */
        if (message.trigger !== 0)
            writer.tag(4, WireType.Varint).int32(message.trigger);
        /* string image = 5; */
        if (message.image !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.image);
        /* repeated string items = 6; */
        for (let i = 0; i < message.items.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.items[i]);
        /* repeated string categories = 7; */
        for (let i = 0; i < message.categories.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.categories[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MessageGuardVM
 */
export const MessageGuardVM = new MessageGuardVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageGuardData$Type extends MessageType<MessageGuardData> {
    constructor() {
        super("Kiosk.MessageGuardData", [
            { no: 1, name: "guards", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageGuardVM }
        ]);
    }
    create(value?: PartialMessage<MessageGuardData>): MessageGuardData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.guards = [];
        if (value !== undefined)
            reflectionMergePartial<MessageGuardData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageGuardData): MessageGuardData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.MessageGuardVM guards */ 1:
                    message.guards.push(MessageGuardVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageGuardData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.MessageGuardVM guards = 1; */
        for (let i = 0; i < message.guards.length; i++)
            MessageGuardVM.internalBinaryWrite(message.guards[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MessageGuardData
 */
export const MessageGuardData = new MessageGuardData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BusinessHoursMessageVM$Type extends MessageType<BusinessHoursMessageVM> {
    constructor() {
        super("Kiosk.BusinessHoursMessageVM", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subtitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "showScreensaver", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BusinessHoursMessageVM>): BusinessHoursMessageVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.title = "";
        message.subtitle = "";
        message.message = "";
        message.showScreensaver = false;
        if (value !== undefined)
            reflectionMergePartial<BusinessHoursMessageVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BusinessHoursMessageVM): BusinessHoursMessageVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string subtitle */ 2:
                    message.subtitle = reader.string();
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                case /* bool showScreensaver */ 4:
                    message.showScreensaver = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BusinessHoursMessageVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string subtitle = 2; */
        if (message.subtitle !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subtitle);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        /* bool showScreensaver = 4; */
        if (message.showScreensaver !== false)
            writer.tag(4, WireType.Varint).bool(message.showScreensaver);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.BusinessHoursMessageVM
 */
export const BusinessHoursMessageVM = new BusinessHoursMessageVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BusinessHoursConfigurationVM$Type extends MessageType<BusinessHoursConfigurationVM> {
    constructor() {
        super("Kiosk.BusinessHoursConfigurationVM", [
            { no: 1, name: "businessHours", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BusinessHoursVM },
            { no: 2, name: "message", kind: "message", T: () => BusinessHoursMessageVM },
            { no: 3, name: "showMessage", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "posHoursEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BusinessHoursConfigurationVM>): BusinessHoursConfigurationVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.businessHours = [];
        message.showMessage = false;
        message.enabled = false;
        message.posHoursEnabled = false;
        if (value !== undefined)
            reflectionMergePartial<BusinessHoursConfigurationVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BusinessHoursConfigurationVM): BusinessHoursConfigurationVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.BusinessHoursVM businessHours */ 1:
                    message.businessHours.push(BusinessHoursVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.BusinessHoursMessageVM message */ 2:
                    message.message = BusinessHoursMessageVM.internalBinaryRead(reader, reader.uint32(), options, message.message);
                    break;
                case /* bool showMessage */ 3:
                    message.showMessage = reader.bool();
                    break;
                case /* bool enabled */ 4:
                    message.enabled = reader.bool();
                    break;
                case /* bool posHoursEnabled */ 5:
                    message.posHoursEnabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BusinessHoursConfigurationVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.BusinessHoursVM businessHours = 1; */
        for (let i = 0; i < message.businessHours.length; i++)
            BusinessHoursVM.internalBinaryWrite(message.businessHours[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.BusinessHoursMessageVM message = 2; */
        if (message.message)
            BusinessHoursMessageVM.internalBinaryWrite(message.message, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool showMessage = 3; */
        if (message.showMessage !== false)
            writer.tag(3, WireType.Varint).bool(message.showMessage);
        /* bool enabled = 4; */
        if (message.enabled !== false)
            writer.tag(4, WireType.Varint).bool(message.enabled);
        /* bool posHoursEnabled = 5; */
        if (message.posHoursEnabled !== false)
            writer.tag(5, WireType.Varint).bool(message.posHoursEnabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.BusinessHoursConfigurationVM
 */
export const BusinessHoursConfigurationVM = new BusinessHoursConfigurationVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BusinessHoursVM$Type extends MessageType<BusinessHoursVM> {
    constructor() {
        super("Kiosk.BusinessHoursVM", [
            { no: 1, name: "day", kind: "enum", T: () => ["Kiosk.DayOfWeekVM", DayOfWeekVM] },
            { no: 2, name: "hours", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HourRangeVM }
        ]);
    }
    create(value?: PartialMessage<BusinessHoursVM>): BusinessHoursVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.day = 0;
        message.hours = [];
        if (value !== undefined)
            reflectionMergePartial<BusinessHoursVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BusinessHoursVM): BusinessHoursVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.DayOfWeekVM day */ 1:
                    message.day = reader.int32();
                    break;
                case /* repeated Kiosk.HourRangeVM hours */ 2:
                    message.hours.push(HourRangeVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BusinessHoursVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.DayOfWeekVM day = 1; */
        if (message.day !== 0)
            writer.tag(1, WireType.Varint).int32(message.day);
        /* repeated Kiosk.HourRangeVM hours = 2; */
        for (let i = 0; i < message.hours.length; i++)
            HourRangeVM.internalBinaryWrite(message.hours[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.BusinessHoursVM
 */
export const BusinessHoursVM = new BusinessHoursVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HourRangeVM$Type extends MessageType<HourRangeVM> {
    constructor() {
        super("Kiosk.HourRangeVM", [
            { no: 1, name: "startMinutes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "endMinutes", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<HourRangeVM>): HourRangeVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startMinutes = 0;
        message.endMinutes = 0;
        if (value !== undefined)
            reflectionMergePartial<HourRangeVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HourRangeVM): HourRangeVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 startMinutes */ 1:
                    message.startMinutes = reader.int32();
                    break;
                case /* int32 endMinutes */ 2:
                    message.endMinutes = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HourRangeVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 startMinutes = 1; */
        if (message.startMinutes !== 0)
            writer.tag(1, WireType.Varint).int32(message.startMinutes);
        /* int32 endMinutes = 2; */
        if (message.endMinutes !== 0)
            writer.tag(2, WireType.Varint).int32(message.endMinutes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.HourRangeVM
 */
export const HourRangeVM = new HourRangeVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosIntegrationMenuSettingsVM$Type extends MessageType<PosIntegrationMenuSettingsVM> {
    constructor() {
        super("Kiosk.PosIntegrationMenuSettingsVM", [
            { no: 1, name: "supportsFreeModifiers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "supportsNegativeModifierBehavior", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "defaultModifierPricingBehavior", kind: "enum", T: () => ["Kiosk.DefaultModifierPricingBehavior", DefaultModifierPricingBehavior] }
        ]);
    }
    create(value?: PartialMessage<PosIntegrationMenuSettingsVM>): PosIntegrationMenuSettingsVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.supportsFreeModifiers = false;
        message.supportsNegativeModifierBehavior = false;
        message.defaultModifierPricingBehavior = 0;
        if (value !== undefined)
            reflectionMergePartial<PosIntegrationMenuSettingsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosIntegrationMenuSettingsVM): PosIntegrationMenuSettingsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool supportsFreeModifiers */ 1:
                    message.supportsFreeModifiers = reader.bool();
                    break;
                case /* bool supportsNegativeModifierBehavior */ 2:
                    message.supportsNegativeModifierBehavior = reader.bool();
                    break;
                case /* Kiosk.DefaultModifierPricingBehavior defaultModifierPricingBehavior */ 3:
                    message.defaultModifierPricingBehavior = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosIntegrationMenuSettingsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool supportsFreeModifiers = 1; */
        if (message.supportsFreeModifiers !== false)
            writer.tag(1, WireType.Varint).bool(message.supportsFreeModifiers);
        /* bool supportsNegativeModifierBehavior = 2; */
        if (message.supportsNegativeModifierBehavior !== false)
            writer.tag(2, WireType.Varint).bool(message.supportsNegativeModifierBehavior);
        /* Kiosk.DefaultModifierPricingBehavior defaultModifierPricingBehavior = 3; */
        if (message.defaultModifierPricingBehavior !== 0)
            writer.tag(3, WireType.Varint).int32(message.defaultModifierPricingBehavior);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.PosIntegrationMenuSettingsVM
 */
export const PosIntegrationMenuSettingsVM = new PosIntegrationMenuSettingsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountCodeEnterOptionVM$Type extends MessageType<DiscountCodeEnterOptionVM> {
    constructor() {
        super("Kiosk.DiscountCodeEnterOptionVM", []);
    }
    create(value?: PartialMessage<DiscountCodeEnterOptionVM>): DiscountCodeEnterOptionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DiscountCodeEnterOptionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountCodeEnterOptionVM): DiscountCodeEnterOptionVM {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DiscountCodeEnterOptionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountCodeEnterOptionVM
 */
export const DiscountCodeEnterOptionVM = new DiscountCodeEnterOptionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountSettingVM$Type extends MessageType<DiscountSettingVM> {
    constructor() {
        super("Kiosk.DiscountSettingVM", [
            { no: 1, name: "discountCodeEnterOption", kind: "enum", T: () => ["Kiosk.DiscountCodeEnterOptionVM.Options", DiscountCodeEnterOptionVM_Options] },
            { no: 2, name: "showDiscountCodeOnOrderReviewScreen", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "showDiscountCodeOnCategoryScreen", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "discountCodeCharacterLimit", kind: "message", T: () => Int32Value }
        ]);
    }
    create(value?: PartialMessage<DiscountSettingVM>): DiscountSettingVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.discountCodeEnterOption = 0;
        message.showDiscountCodeOnOrderReviewScreen = false;
        message.showDiscountCodeOnCategoryScreen = false;
        if (value !== undefined)
            reflectionMergePartial<DiscountSettingVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountSettingVM): DiscountSettingVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.DiscountCodeEnterOptionVM.Options discountCodeEnterOption */ 1:
                    message.discountCodeEnterOption = reader.int32();
                    break;
                case /* bool showDiscountCodeOnOrderReviewScreen */ 2:
                    message.showDiscountCodeOnOrderReviewScreen = reader.bool();
                    break;
                case /* bool showDiscountCodeOnCategoryScreen */ 3:
                    message.showDiscountCodeOnCategoryScreen = reader.bool();
                    break;
                case /* google.protobuf.Int32Value discountCodeCharacterLimit */ 4:
                    message.discountCodeCharacterLimit = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.discountCodeCharacterLimit);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountSettingVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.DiscountCodeEnterOptionVM.Options discountCodeEnterOption = 1; */
        if (message.discountCodeEnterOption !== 0)
            writer.tag(1, WireType.Varint).int32(message.discountCodeEnterOption);
        /* bool showDiscountCodeOnOrderReviewScreen = 2; */
        if (message.showDiscountCodeOnOrderReviewScreen !== false)
            writer.tag(2, WireType.Varint).bool(message.showDiscountCodeOnOrderReviewScreen);
        /* bool showDiscountCodeOnCategoryScreen = 3; */
        if (message.showDiscountCodeOnCategoryScreen !== false)
            writer.tag(3, WireType.Varint).bool(message.showDiscountCodeOnCategoryScreen);
        /* google.protobuf.Int32Value discountCodeCharacterLimit = 4; */
        if (message.discountCodeCharacterLimit)
            Int32Value.internalBinaryWrite(message.discountCodeCharacterLimit, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountSettingVM
 */
export const DiscountSettingVM = new DiscountSettingVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HardWareVisualAssetVM$Type extends MessageType<HardWareVisualAssetVM> {
    constructor() {
        super("Kiosk.HardWareVisualAssetVM", [
            { no: 1, name: "scanVisuals", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HardwareVisualAssetCollectionVM },
            { no: 2, name: "payVisuals", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HardwareVisualAssetCollectionVM }
        ]);
    }
    create(value?: PartialMessage<HardWareVisualAssetVM>): HardWareVisualAssetVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.scanVisuals = [];
        message.payVisuals = [];
        if (value !== undefined)
            reflectionMergePartial<HardWareVisualAssetVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HardWareVisualAssetVM): HardWareVisualAssetVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.HardwareVisualAssetCollectionVM scanVisuals */ 1:
                    message.scanVisuals.push(HardwareVisualAssetCollectionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Kiosk.HardwareVisualAssetCollectionVM payVisuals */ 2:
                    message.payVisuals.push(HardwareVisualAssetCollectionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HardWareVisualAssetVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.HardwareVisualAssetCollectionVM scanVisuals = 1; */
        for (let i = 0; i < message.scanVisuals.length; i++)
            HardwareVisualAssetCollectionVM.internalBinaryWrite(message.scanVisuals[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.HardwareVisualAssetCollectionVM payVisuals = 2; */
        for (let i = 0; i < message.payVisuals.length; i++)
            HardwareVisualAssetCollectionVM.internalBinaryWrite(message.payVisuals[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.HardWareVisualAssetVM
 */
export const HardWareVisualAssetVM = new HardWareVisualAssetVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HardwareVisualAssetCollectionVM$Type extends MessageType<HardwareVisualAssetCollectionVM> {
    constructor() {
        super("Kiosk.HardwareVisualAssetCollectionVM", [
            { no: 1, name: "scannerId", kind: "message", T: () => StringValue },
            { no: 2, name: "kioskHardwareId", kind: "message", T: () => StringValue },
            { no: 3, name: "paymentDeviceHardwareId", kind: "message", T: () => StringValue },
            { no: 4, name: "media", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HardwareVisualAssetCollectionVM>): HardwareVisualAssetCollectionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.media = "";
        if (value !== undefined)
            reflectionMergePartial<HardwareVisualAssetCollectionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HardwareVisualAssetCollectionVM): HardwareVisualAssetCollectionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.StringValue scannerId */ 1:
                    message.scannerId = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.scannerId);
                    break;
                case /* google.protobuf.StringValue kioskHardwareId */ 2:
                    message.kioskHardwareId = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.kioskHardwareId);
                    break;
                case /* google.protobuf.StringValue paymentDeviceHardwareId */ 3:
                    message.paymentDeviceHardwareId = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.paymentDeviceHardwareId);
                    break;
                case /* string media */ 4:
                    message.media = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HardwareVisualAssetCollectionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.StringValue scannerId = 1; */
        if (message.scannerId)
            StringValue.internalBinaryWrite(message.scannerId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue kioskHardwareId = 2; */
        if (message.kioskHardwareId)
            StringValue.internalBinaryWrite(message.kioskHardwareId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue paymentDeviceHardwareId = 3; */
        if (message.paymentDeviceHardwareId)
            StringValue.internalBinaryWrite(message.paymentDeviceHardwareId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string media = 4; */
        if (message.media !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.media);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.HardwareVisualAssetCollectionVM
 */
export const HardwareVisualAssetCollectionVM = new HardwareVisualAssetCollectionVM$Type();
