import { writable } from 'svelte/store';

type AmazonOneModalState =
  | {
      type: 'hidden';
    }
  | {
      type: 'modal-shown';
      state: 'signed-in' | 'error';
    };

export type AmazonOneUIState = {
  modalState: AmazonOneModalState;
  shouldSkipLoyaltySignIn?: boolean;
};

export class AmazonOneUIStateManager {
  public store = writable<AmazonOneUIState>({
    modalState: {
      type: 'hidden',
    },
  });

  public showModal(state: 'signed-in' | 'error') {
    this.store.set({
      modalState: {
        type: 'modal-shown',
        state,
      },
    });
  }

  public hideModal(shouldSkipLoyaltySignIn?: boolean) {
    this.store.set({
      modalState: {
        type: 'hidden',
      },
      shouldSkipLoyaltySignIn,
    });
  }
}

export const amazonOneUiStateManager = new AmazonOneUIStateManager();
