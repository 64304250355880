/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "i18n.proto" (package "Kiosk", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message Kiosk.I18nDictionary
 */
export interface I18nDictionary {
    /**
     * @generated from protobuf field: int32 isDefault = 1;
     */
    isDefault: number;
    /**
     * @generated from protobuf field: string locale = 2;
     */
    locale: string; // en, en-US...
    /**
     * @generated from protobuf field: string language = 3;
     */
    language: string; // label
    /**
     * @generated from protobuf field: string image = 4;
     */
    image: string;
    /**
     * @generated from protobuf field: map<string, string> dictionary = 5;
     */
    dictionary: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: string friendlyName = 6;
     */
    friendlyName: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class I18nDictionary$Type extends MessageType<I18nDictionary> {
    constructor() {
        super("Kiosk.I18nDictionary", [
            { no: 1, name: "isDefault", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "locale", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "language", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "image", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "dictionary", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 6, name: "friendlyName", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<I18nDictionary>): I18nDictionary {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isDefault = 0;
        message.locale = "";
        message.language = "";
        message.image = "";
        message.dictionary = {};
        message.friendlyName = "";
        if (value !== undefined)
            reflectionMergePartial<I18nDictionary>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: I18nDictionary): I18nDictionary {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 isDefault */ 1:
                    message.isDefault = reader.int32();
                    break;
                case /* string locale */ 2:
                    message.locale = reader.string();
                    break;
                case /* string language */ 3:
                    message.language = reader.string();
                    break;
                case /* string image */ 4:
                    message.image = reader.string();
                    break;
                case /* map<string, string> dictionary */ 5:
                    this.binaryReadMap5(message.dictionary, reader, options);
                    break;
                case /* string friendlyName */ 6:
                    message.friendlyName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: I18nDictionary["dictionary"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof I18nDictionary["dictionary"] | undefined, val: I18nDictionary["dictionary"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Kiosk.I18nDictionary.dictionary");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: I18nDictionary, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 isDefault = 1; */
        if (message.isDefault !== 0)
            writer.tag(1, WireType.Varint).int32(message.isDefault);
        /* string locale = 2; */
        if (message.locale !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.locale);
        /* string language = 3; */
        if (message.language !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.language);
        /* string image = 4; */
        if (message.image !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.image);
        /* map<string, string> dictionary = 5; */
        for (let k of globalThis.Object.keys(message.dictionary))
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.dictionary[k]).join();
        /* string friendlyName = 6; */
        if (message.friendlyName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.friendlyName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.I18nDictionary
 */
export const I18nDictionary = new I18nDictionary$Type();
