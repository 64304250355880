import type { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';
import { assign, createMachine, sendParent, type ActorRefFrom } from 'xstate';
import type {
  BalanceRequest,
  OrderPaymentRequest,
  OrderRefundRequest,
} from '../../../../generated/kioskPay_pb';
import { KioskPaymentServiceClient } from '../../../../generated/kioskPaymentService_pb.client';
import type { PaytronixStoredValuePaymentDevice } from '../../../../generated/kiosk_pb';
import { loyalty_service } from '../../loyalty/loyalty_machine';
import { payment_request_total } from '../payment_request_total';
import type { PaymentMachineEvent } from '../types';

export type PaytronixStoredValueContext = {
  transport: GrpcWebFetchTransport;
  config: PaytronixStoredValuePaymentDevice;
  client?: KioskPaymentServiceClient;
  balance?: number;
};

export type PaytronixStoredValueEvent =
  | PaymentMachineEvent
  | {
      type: 'SET_PAYMENT_INFO';
      data: { cardNumber: string; regCode: string };
    }
  | {
      type: 'REFUND';
      data: string;
    };

export const paytronix_stored_value_machine = createMachine(
  {
    id: 'paytronix_stored_value_machine',
    tsTypes: {} as import('./paytronix_stored_value_machine.typegen').Typegen0,
    predictableActionArguments: true,
    preserveActionOrder: true,
    schema: {
      context: {} as PaytronixStoredValueContext,
      events: {} as PaytronixStoredValueEvent,
      services: {} as {
        get_balance: {
          data: { balance: number } | undefined;
        };
      },
    },
    initial: 'idle',
    entry: 'assignPaymentClient',
    states: {
      idle: {
        tags: 'can_get_balance',
        on: {
          PAY: {
            target: 'paying',
          },
          REFUND: {
            target: 'refunding',
          },
          GET_BALANCE: {
            target: 'getting_balance',
          },
          CANCEL: {
            actions: 'cancelled',
          },
        },
      },
      getting_balance: {
        tags: 'getting_balance',
        invoke: {
          src: 'get_balance',
          onDone: {
            target: 'idle',
            actions: ['set_balance', 'send_balance'],
          },
          onError: {
            target: 'idle',
            actions: 'set_error',
          },
        },
      },
      paying: {
        invoke: {
          src: 'pay',
          onDone: {
            target: 'idle',
            actions: 'pay',
          },
          onError: {
            target: 'idle',
            actions: ['pay_error'],
          },
        },
      },
      refunding: {
        invoke: {
          src: 'refund',
          onDone: {
            target: 'idle',
            actions: 'refunded',
          },
          onError: {
            target: 'idle',
            actions: ['pay_error'],
          },
        },
      },
    },
  },
  {
    actions: {
      assignPaymentClient: assign({
        client: (ctx) => {
          const client = new KioskPaymentServiceClient(ctx.transport);
          return client;
        },
      }),
      set_balance: assign({
        balance: (_, evt) => evt.data?.balance,
      }),
      send_balance: sendParent((ctx, evt) => ({
        type: 'GOT_BALANCE',
        data: evt.data?.balance != undefined ? evt.data.balance.toString() : undefined,
      })),
      pay: sendParent((ctx, evt) => ({ type: 'PAID', data: evt.data })),
      refunded: sendParent((ctx, evt) => ({ type: 'REFUNDED', data: evt.data })),
      pay_error: sendParent({ type: 'ERROR' }),
      set_error: (ctx, evt) => console.log('ERROR', evt.data),
      cancelled: sendParent({ type: 'CANCELLED' }),
    },
    services: {
      get_balance: async (ctx) => {
        if (!ctx.client) throw new Error('No client');

        const loyaltyUserId = loyalty_service.getSnapshot().context.profile?.externalId;

        try {
          const request: BalanceRequest = {
            paymentIntegrationId: 'payment-integration-paytronix-stored-value',
            accountId: '',
            values: {
              oneofKind: 'paytronixStoredValue',
              paytronixStoredValue: {
                cardNumber: loyaltyUserId ?? '',
              },
            },
          };

          const result = await ctx.client.getBalance(request);
          return {
            balance: result.response.credit,
          };
        } catch {
          return undefined;
        }
      },
      pay: async (ctx, evt) => {
        const cardNumber = loyalty_service.getSnapshot().context.profile?.externalId;

        if (!ctx.client) throw new Error('No client');
        if (!cardNumber) throw new Error('Must sign into loyalty first.');

        const amountDue = payment_request_total(evt.data.requestData);
        const paymentAmount = Math.min(ctx.balance ?? 0, amountDue);

        const request: OrderPaymentRequest = {
          paymentIntegrationId: 'payment-integration-paytronix-stored-value',
          paymentAmount,
          accountId: '',
          values: {
            oneofKind: 'paytronixStoredValue',
            paytronixStoredValue: {
              cardNumber,
            },
          },
        };

        const result = await ctx.client.pay(request);

        return {
          response: result.response,
          info: {
            time: new Date(),
            transactionId: result.response.transactionId,
            amount: result.response.totalPaid,
            type: 'Stored Value',
          },
        };
      },
      refund: async (ctx, evt) => {
        if (!ctx.client) throw new Error('No client');
        if (typeof evt.data !== 'string') throw new Error('Bad transaction Id!');
        const request: OrderRefundRequest = {
          paymentIntegrationId: 'payment-integration-paytronix-stored-value',
          transactionId: evt.data,
        };

        const result = await ctx.client.refund(request);

        return {
          response: result.response,
          info: {
            time: new Date(),
            transactionId: result.response.transactionId,
            amount: result.response.totalPaid,
            type: 'Stored Value',
          },
        };
      },
    },
  }
);
export type PaytronixService = ActorRefFrom<typeof paytronix_stored_value_machine>;
