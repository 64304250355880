/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "kioskPaymentService.proto" (package "Kiosk", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { KioskPaymentService } from "./kioskPaymentService_pb";
import type { OrderRefundRequest } from "./kioskPay_pb";
import type { Balance } from "./kioskPay_pb";
import type { BalanceRequest } from "./kioskPay_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { OrderPayment } from "./kioskOrder_pb";
import type { OrderPaymentRequest } from "./kioskPay_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Kiosk.KioskPaymentService
 */
export interface IKioskPaymentServiceClient {
    /**
     * @generated from protobuf rpc: Pay(Kiosk.OrderPaymentRequest) returns (Kiosk.OrderPayment);
     */
    pay(input: OrderPaymentRequest, options?: RpcOptions): UnaryCall<OrderPaymentRequest, OrderPayment>;
    /**
     * @generated from protobuf rpc: GetBalance(Kiosk.BalanceRequest) returns (Kiosk.Balance);
     */
    getBalance(input: BalanceRequest, options?: RpcOptions): UnaryCall<BalanceRequest, Balance>;
    /**
     * @generated from protobuf rpc: Refund(Kiosk.OrderRefundRequest) returns (Kiosk.OrderPayment);
     */
    refund(input: OrderRefundRequest, options?: RpcOptions): UnaryCall<OrderRefundRequest, OrderPayment>;
}
/**
 * @generated from protobuf service Kiosk.KioskPaymentService
 */
export class KioskPaymentServiceClient implements IKioskPaymentServiceClient, ServiceInfo {
    typeName = KioskPaymentService.typeName;
    methods = KioskPaymentService.methods;
    options = KioskPaymentService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: Pay(Kiosk.OrderPaymentRequest) returns (Kiosk.OrderPayment);
     */
    pay(input: OrderPaymentRequest, options?: RpcOptions): UnaryCall<OrderPaymentRequest, OrderPayment> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<OrderPaymentRequest, OrderPayment>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBalance(Kiosk.BalanceRequest) returns (Kiosk.Balance);
     */
    getBalance(input: BalanceRequest, options?: RpcOptions): UnaryCall<BalanceRequest, Balance> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<BalanceRequest, Balance>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Refund(Kiosk.OrderRefundRequest) returns (Kiosk.OrderPayment);
     */
    refund(input: OrderRefundRequest, options?: RpcOptions): UnaryCall<OrderRefundRequest, OrderPayment> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<OrderRefundRequest, OrderPayment>("unary", this._transport, method, opt, input);
    }
}
