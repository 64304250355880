import type { ModGroupService } from '../states/menu/types';
import { ModifierCodeBehavior } from '../../generated/common_pb';
import type { KioskModifierCode } from '../models';
import type { DownloadedImageAsset } from '../models';

export const getCompoundIdForCategoryAndLayout = (layoutId: string, categoryId: string) => {
  return `${layoutId}-${categoryId}`;
};

// hack used to get around TS errors where discriminated union's are not properly narrowed
type InvalidOneOf = {
  oneofKind: undefined;
};

export const isValidOneOf = <T>(x: T | InvalidOneOf): x is T => {
  return x && !((x as InvalidOneOf).oneofKind === undefined);
};

export const isDownloadedAsset = (
  asset: string | Blob | DownloadedImageAsset | undefined
): asset is DownloadedImageAsset => {
  if (!asset) {
    return false;
  }

  return !!(asset as DownloadedImageAsset).mime;
};

export const isNegativeModifierCode = (modifierCode: KioskModifierCode) => {
  return modifierCode.modifierCodeBehavior === ModifierCodeBehavior.Negative;
};

export const isExtraModifierCode = (modifierCode: KioskModifierCode) => {
  return modifierCode.modifierCodeBehavior === ModifierCodeBehavior.Extra;
};

export const isSelectedQtyWithinModGroupMaxQty = (
  modifier_group_service: ModGroupService,
  selectedQty: number
) => {
  if (modifier_group_service.group.selectMax === 1) return false;
  if ([0, null, undefined].some((x) => x === modifier_group_service.group.selectMax)) return true;
  if (
    modifier_group_service.group.selectMax > 0 &&
    modifier_group_service.group.selectMax >= selectedQty
  )
    return true;

  return false;
};
