import type { KioskOrderItem } from '../models';
import type { Discount } from '@grubbrr/grubbrr-discounts';
import type { OrderDiscount as AgentOrderDiscount } from '@grubbrr/agent-api';
import type { OrderDiscount } from '../../generated/kioskOrder_pb';
import type { LoyaltyReward, RedeemableBalance, RedeemablePoint } from '../../generated/loyalty_pb';
import { isValidOneOf } from '../data-management/utils';
import type { DiscountOrder } from '@grubbrr/grubbrr-discounts';

export type KioskDiscountOrder = DiscountOrder;

export type JsonReward = { name: string } & { value: string } & { externalId: string } & {
  integrationMetadata: string;
} & ({ redeemablePoint: RedeemablePoint } | { redeemableBalance: RedeemableBalance });

export const getDiscountAmount = (d: LoyaltyReward): number => {
  const reward = d.reward;
  if (isValidOneOf(reward)) {
    switch (reward.oneofKind) {
      case 'redeemablePoint':
        return Number(reward.redeemablePoint.discountApplied);
      case 'redeemableBalance':
        return Number(reward.redeemableBalance.discountApplied);
      default:
        return 0;
    }
  }

  return 0;
};

export const getRewardAmount = (d: LoyaltyReward): JsonReward => {
  const reward = d.reward;
  const formattedReward = {
    name: d.name,
    value: d.name,
    externalId: d.externalId,
    integrationMetadata: d.integrationMetadata,
  };
  if (isValidOneOf(reward)) {
    switch (reward.oneofKind) {
      case 'redeemablePoint':
        return {
          ...formattedReward,
          redeemablePoint: {
            discountApplied: reward.redeemablePoint.discountApplied,
          },
        };
      case 'redeemableBalance':
        return {
          ...formattedReward,
          redeemableBalance: {
            discountApplied: reward.redeemableBalance.discountApplied,
          },
        };
    }
  }
  return {
    ...formattedReward,
    redeemablePoint: {
      discountApplied: '0',
    },
  };
};

export const toOrderDiscount = (d: LoyaltyReward): OrderDiscount => {
  return {
    discountId: d.externalId,
    value: { oneofKind: 'flat', flat: getDiscountAmount(d) },
    discountAmount: getDiscountAmount(d),
  };
};

export type KioskDiscount = Discount;

export const getOverridePrice = (discount: KioskDiscount) => {
  if (discount.discountType.kind === 'pricerOverride') {
    return discount.discountType.overridePrice;
  }
};

export const fromDiscountToOrderDiscount = (discount: KioskDiscount, obj: KioskOrderItem) => {
  const overridePrice = getOverridePrice(discount) ?? null;
  return {
    discountId: discount.posDiscountId,
    flat: overridePrice ? obj.finalItemPrice! - overridePrice : discount.discountAmount,
    discountAmount: discount.discountAmount,
  };
};

export const fromLoyaltyToOrderDiscount = (d: LoyaltyReward): AgentOrderDiscount => {
  return {
    discountId: d.externalId,
    flat: getDiscountAmount(d),
  };
};
