type CacheKeyFunction<P> = (...args: P[]) => string;
type AnyPromiseFunction<P, R> = (...args: P[]) => Promise<R>;
// eslint-disable-next-line @typescript-eslint/ban-types
type ArgumentTypes<F extends Function> = F extends (...args: infer A) => unknown ? A : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type CacheKeyFunctionFactory<T extends Function> = (...args: ArgumentTypes<T>) => string;

// const tick = () => new Promise((resolve) => setTimeout(resolve, 0));

export function memoizePromise<P, R>(
  cachedValues: Map<string, R>,
  keyFn: CacheKeyFunction<P>,
  fn: AnyPromiseFunction<P, R>
): AnyPromiseFunction<P, R> {
  return async (...args) => {
    const hashKey = keyFn(...args);
    const cachedValue = cachedValues.get(hashKey);
    // console.log('calling memoize', args, hashKey, cachedValues, cachedValue);
    if (cachedValue) {
      return cachedValue;
    }

    const result = await fn(...args);
    cachedValues.set(hashKey, result);

    return result;
  };
}

export const cacheKeyFunctions = {
  singleString(x: string): string {
    return x;
  },
  array<T>(x: T[]): string {
    const copy = [...x];
    copy.sort();
    return copy.join('_');
  },
};

export const groupByGeneric = <T, U>(
  list: T[],
  getGroupingkey: (x: T) => number | string | undefined,
  transform: (input: T) => U
): { [id: string]: U } => {
  return list.reduce(
    (grouped, item) => {
      const key = getGroupingkey(item);
      if (!key) {
        return grouped;
      }
      grouped[key] = transform(item);
      return grouped;
    },
    {} as { [id: string]: U }
  );
};

// Meant to be used in reduce calls to combine list of lists of T into a Set<T>
export const accumulateListIntoSet = <T>(accumulator: Set<T>, list: T[]) => {
  for (const itemId of list) {
    accumulator.add(itemId);
  }
  return accumulator;
};
