import { nanoid } from 'nanoid';
import { derived, get, writable, type Readable, type Updater, type Writable } from 'svelte/store';
import type {
  ItemModifierModifierCode,
  OrderItem,
  OrderItemUpsellSourceInformation,
} from '../../../generated/kioskOrder_pb';
import type {
  KioskCategory,
  KioskItemModifier,
  KioskMenuItem,
  KioskModifier,
  KioskOrderItem,
} from '../../models';
import { eighty_six_service } from '../eighty_six/eighty_six_service';
import { PromotionType } from '../loyalty/loyalty_machine';
import { load_modifier_groups } from './load_modifier_groups.js';
import { createInitialModifierGroupsState } from './mod_group_service';
import type {
  ItemServiceStoreState,
  ItemServiceInternalState,
  ModGroupService,
  ModifierGroupState,
  ModifierGroupStateManager,
  ModifierState,
  ModifierStateManager,
  ModifierDisplay,
  ModifierGroupsState,
  DerivedModifierGroupsState,
  DerivedModifierState,
  DerivedModifierGroupState,
} from './types';
import { createItemDerivedState } from './utils';
import type { CartStoreState, IMediaStateManager } from '../../state-managers';
import type { IPricesStateManager } from '../../state-managers';
import type { Int32Value } from '../../../generated/google/protobuf/wrappers_pb';
import { ModifierCodeBehavior } from '../../../generated/common_pb';
import type { ModifierCode } from '../../../generated/menu_pb';
import { isDefined } from '../../services/utils';

const MIN_QUANTITY = 1;

function modifier_selection_key(item_modifiers: ModifierGroupsState): (string | number)[] {
  return [...item_modifiers.entries()].flatMap(([modifierGroupId, modifierGroup]) => {
    return [...modifierGroup.modifiers.entries()].flatMap(([modifierId, modifier]) => {
      const children = modifier_selection_key(modifier.mod_groups);
      const quantity = modifier.quantity;
      return [modifierGroupId, '|', modifierId, quantity, '('].concat(children, ')');
    });
  });
}

function getModifierCodeOnCodeSelection(modifierCode: ModifierCode): ItemModifierModifierCode {
  return {
    modifierCodeId: modifierCode.id,
    modifierCodeName: modifierCode.name,
  };
}

const getPriceForMod = (
  modifier: KioskModifier,
  rootItemId: string,
  priceStateManager: IPricesStateManager
) => {
  return priceStateManager.getPriceForModifierInItem(rootItemId, modifier.modifierId) ?? 0;
};

const getPriceForExtraModifierCode = (
  mod: KioskModifier,
  mod_state: ModifierDisplay,
  rootItemId: string,
  priceStateManager: IPricesStateManager
) => {
  if (!mod_state.selectedModifierCode || mod.isDefault) return 0;
  if (mod_state.selectedModifierCode.modifierCodeBehavior == ModifierCodeBehavior.Extra) {
    return getPriceForMod(mod, rootItemId, priceStateManager);
  }
  return 0;
};

const getNegativeModifierCode = (selectedModifierCode: ModifierCode[]) => {
  const negModifier = selectedModifierCode?.filter(
    (x) => x.modifierCodeBehavior === ModifierCodeBehavior.Negative
  );
  return negModifier;
};

export function isContainsNegativeModifierCode(selectedModifierCode: ModifierCode[]): boolean {
  const negModifier = getNegativeModifierCode(selectedModifierCode);
  if (negModifier?.length > 0) {
    return true;
  }
  return false;
}

function getModifierCodeSelection(
  derivedModifierState: DerivedModifierState
): ItemModifierModifierCode | undefined {
  let selectedModifierCode: ItemModifierModifierCode | undefined = undefined;
  if (!derivedModifierState) return selectedModifierCode;

  const mod_state = derivedModifierState.state.display;
  if (mod_state.selectedModifierCode) {
    selectedModifierCode = getModifierCodeOnCodeSelection(mod_state.selectedModifierCode);
  }

  if (mod_state.isUnselectedDefaultModifier) {
    const negModifier = getNegativeModifierCode(derivedModifierState.modifier.modifierCodes);
    selectedModifierCode = getModifierCodeOnCodeSelection(negModifier?.[0]);
  }
  return selectedModifierCode;
}

export function getOrderDetailsForModifierService(
  derivedModifierState: DerivedModifierState,
  parentModifierGroupState: DerivedModifierGroupState,
  itemService: ItemService
): KioskItemModifier | undefined {
  const modifier = derivedModifierState.modifier;
  const parentModifierGroup = parentModifierGroupState.modifierGroup;
  const mod_state = derivedModifierState.state;
  const quantity = mod_state.display.quantity;
  const isDefaultModifier = modifier.defaultQuantity > 0;
  const children = getOrderDetailsForModifierGroupService(
    derivedModifierState.modifierGroups,
    itemService
  );
  const shouldModifierPriceBeZero =
    isDefaultModifier &&
    parentModifierGroup.freeModifierCount === 0 &&
    (!mod_state.display.selectedModifierCode ||
      (mod_state.display.selectedModifierCode &&
        mod_state.display.selectedModifierCode?.modifierCodeBehavior !==
          ModifierCodeBehavior.Extra));

  const modifierPrice =
    getPriceForMod(modifier, itemService.item.menuItemId, itemService.getPricesStateManager()) +
    getPriceForExtraModifierCode(
      modifier,
      mod_state.display,
      itemService.item.menuItemId,
      itemService.getPricesStateManager()
    );

  // do not send modifiers with zero quantity unless it is an unselected default modifier
  if (mod_state.display.quantity === 0 && !mod_state.display.isUnselectedDefaultModifier) {
    return undefined;
  }

  const modifierCodeSelection = getModifierCodeSelection(derivedModifierState);

  return {
    posOptionGroupId: parentModifierGroup.posId?.value ?? null,
    posId: modifier.menuItem.itemExternalId,
    posData: modifier.menuItem.posData,
    menuItemId: modifier.menuItem.menuItemId ?? '',
    modifierId: modifier.modifierId,
    optionGroupId: parentModifierGroup.modifierGroupId,
    quantity: quantity,
    transformRulesMetadata: modifier.menuItem?.transformRulesMetadata ?? '',
    name: modifier.menuItem?.name ?? '',
    modifierCode: '',
    freeQuantity: mod_state.display.free_quantity,
    modifiers: children,
    modifierCodeSelection,
    isDefault: modifier.isDefault,
    unitPrice: {
      value: shouldModifierPriceBeZero ? 0 : modifierPrice,
    } as Int32Value,
    totalPrice: {
      value: shouldModifierPriceBeZero ? 0 : modifierPrice * quantity,
    } as Int32Value,
  };
}

export function getOrderDetailsForModifierGroupService(
  modifierGroups: DerivedModifierGroupsState,
  itemService: ItemService
): KioskItemModifier[] {
  return [...modifierGroups.values()].flatMap((modGroupState) => {
    return [...modGroupState.modifiersState.values()]
      .flatMap((modState) => {
        return getOrderDetailsForModifierService(modState, modGroupState, itemService);
      })
      .filter(isDefined);
  });
}

export function getOrderDetailsForItemService(itemService: ItemService): KioskOrderItem {
  const itemDerivedState = get(itemService);
  let price =
    itemService.getOverridedStartingPrice() ??
    itemService.getPricesStateManager().getPriceForItem(itemService.item.menuItemId)!;
  const modifierItems = getOrderDetailsForModifierGroupService(
    itemDerivedState.derivedModifierGroupState,
    itemService
  );
  const quantity = itemService.getCurrentQuantity();

  for (const derivedGroup of itemDerivedState.derivedModifierGroupState.values()) {
    price += derivedGroup.state.display?.price ?? 0;
  }

  return {
    posId: itemService.item.itemExternalId,
    menuItemId: itemService.item.menuItemId,
    quantity: quantity,
    modifiers: modifierItems,
    categoryId: itemDerivedState.context.sourceCategory?.categoryId ?? '',
    categoryName: itemDerivedState.context.sourceCategory?.name ?? '',
    transformRulesMetadata: '{}',
    name: itemService.item.name,
    specialRequest: itemService.getSpecialRequest(),
    upsellSource: itemService.getUpsellRespurce(),
    unitPrice: { value: price } as Int32Value,
    totalPrice: { value: price * quantity } as Int32Value,
    itemPrice: { value: itemService.item.price } as Int32Value,
    localizedName: itemService.item.localizedDisplayName,
    localizedDisplayName: itemService.item.localizedDisplayName,
    localizedDescription: itemService.item.localizedDescription,
    posData: itemService.item.posData,
    rewardSource: {
      rewardId: itemService.getRewardId()
        ? {
            value: itemService.getRewardId(),
          }
        : undefined,
    },
    itemSessionId: itemDerivedState.itemSessionId,
  };
}

function set_special_request(special_request: string, ctx: ItemServiceInternalState) {
  ctx.special_request = special_request;
  return ctx;
}

const createChildModifierGroupStateManager = (
  parent: ModifierStateManager,
  modifierId: string
): ModifierGroupStateManager => {
  const updateModGroupStateForId = (
    modifierGroupId: string,
    updater: Updater<ModifierGroupState>
  ) => {
    // const currentState = get(parent.getModStateForId(modifierId))!;
    parent.updateModStateForId(modifierId, (s) => {
      const individualModifierGroupState = s.mod_groups.get(modifierGroupId)!;
      updater(individualModifierGroupState);
      s.mod_groups.set(modifierGroupId, individualModifierGroupState);
      return s;
    });
  };

  const modifierGroupStateManager: ModifierGroupStateManager = {
    updateModGroupStateForId,
    getDerivedModGroupStateForId(modifierGroupId) {
      return derived(parent.getDerivedModStateForId(modifierId), (s) => {
        return s?.modifierGroups.get(modifierGroupId);
      });
    },
    getChildModifierStateManager(modifierGroupId) {
      return createChildModifierStateManager(modifierGroupStateManager, modifierGroupId);
    },
  };

  return modifierGroupStateManager;
};

const createChildModifierStateManager = (
  parent: ModifierGroupStateManager,
  modifierGroupId: string
) => {
  const manager: ModifierStateManager = {
    getDerivedModStateForId(modifierId) {
      return derived(parent.getDerivedModGroupStateForId(modifierGroupId), (s) => {
        return s?.modifiersState.get(modifierId);
      });
    },
    updateModStateForId: (modifierId: string, updater: Updater<ModifierState>) => {
      parent.updateModGroupStateForId(modifierGroupId, (currentState) => {
        const state = currentState.modifiers.get(modifierId)!;
        updater(state);
        currentState?.modifiers.set(modifierId, state);
        return currentState;
      });
    },
    getChildModifierGroupStateManager(modifierId: string) {
      return createChildModifierGroupStateManager(manager, modifierId);
    },
  };
  return manager;
};

const createModifierGroupStateManager = (
  itemStateStore: Writable<ItemServiceInternalState>,
  derivedStateStore: Readable<ItemServiceStoreState>
): ModifierGroupStateManager => {
  const updateModGroupStateForId = (
    modifierGroupId: string,
    updater: Updater<ModifierGroupState>
  ) => {
    itemStateStore.update((x) => {
      const s = x.mod_group_states.get(modifierGroupId)!;
      updater(s);
      x.mod_group_states.set(modifierGroupId, s);
      return x;
    });
  };

  const modifierGroupStateManager: ModifierGroupStateManager = {
    updateModGroupStateForId,
    getDerivedModGroupStateForId(modifierGroupId) {
      return derived(derivedStateStore, (s) => {
        return s.derivedModifierGroupState.get(modifierGroupId);
      });
    },
    getChildModifierStateManager(modifierGroupId) {
      return createChildModifierStateManager(modifierGroupStateManager, modifierGroupId);
    },
  };

  return modifierGroupStateManager;
};

export function item_service({
  mediaStateManager,
  pricesStateManager,
  order_details,
  item,
  uid,
  load_defaults,
  sourceCategory,
  selPromotionalOption,
  addedToOrderTimestamp,
  overridedStartingPrice,
  quantityLimitPerItem,
  quantityLimitPerOrder,
  cartStore,
  rewardId,
}: {
  mediaStateManager: IMediaStateManager;
  pricesStateManager: IPricesStateManager;
  item: KioskMenuItem;
  sourceCategory: KioskCategory | null;
  order_details: OrderItem | null;
  load_defaults: boolean;
  uid?: string | undefined;
  selPromotionalOption?: PromotionType;
  addedToOrderTimestamp?: number;
  overridedStartingPrice?: number; // this is used when an item is used in a combo
  quantityLimitPerItem: number;
  quantityLimitPerOrder: number;
  cartStore: Readable<CartStoreState>;
  rewardId?: string | undefined;
}): ItemService {
  const firstModifierGroupId =
    item.modifierGroups.length === 0 ? '' : item.modifierGroups[0].modifierGroupId;

  const itemState = writable<ItemServiceInternalState>({
    sourceCategory,
    quantity: order_details?.quantity ?? 1,
    selected_group_id: firstModifierGroupId,
    special_request: order_details?.specialRequest ?? '',
    mod_group_states: createInitialModifierGroupsState({
      order_details,
      item,
      load_defaults,
    }),
    upsellSource: order_details?.upsellSource,
    cartItemId: item.cartItemId,
    promotionalOption: selPromotionalOption ?? PromotionType.None,
    overridedStartingPrice,
    itemLimitReached: false,
    orderLimitReached: false,
    rewardId: rewardId ?? undefined,
  });

  const { update } = itemState;

  function set_quantity(update_function: (current_state: ItemServiceInternalState) => number) {
    update((item_state) => {
      const new_quantity = Math.max(MIN_QUANTITY, update_function(item_state));
      item_state.quantity = new_quantity;
      return item_state;
    });
  }

  const derivedState = derived(
    [itemState, eighty_six_service.store],
    ([state, eightySixItems]): ItemServiceStoreState => {
      const itemSessionId = uid || nanoid();
      return createItemDerivedState(
        state,
        item,
        eightySixItems,
        modifierGroupServices,
        quantityLimitPerItem,
        quantityLimitPerOrder,
        get(cartStore),
        pricesStateManager,
        mediaStateManager,
        itemSessionId
      );
    }
  );

  const modifierGroupStateManager = createModifierGroupStateManager(itemState, derivedState);
  const modifierGroupServices = load_modifier_groups(modifierGroupStateManager, item);

  function update_index(index: number, ctx: ItemServiceInternalState) {
    const keys = Array.from(modifierGroupServices.keys());
    if (index >= keys.length) {
      index = 0;
    }

    ctx.selected_group_id = keys[index];
    return ctx;
  }

  return {
    subscribe: derivedState.subscribe,
    // getModifierGroupServices() {
    //   return modifierGroupServices.entries();
    // },
    modifierGroupServices,
    copy_details: {
      order_details,
      load_defaults,
    },
    item,
    uid,
    addedToOrderTimestamp: addedToOrderTimestamp ?? 0,
    /**
     * selects the next mod group for display
     * defaults to first mod group if none selected or currently on last item
     */
    select_next_mod_group: () => {
      if (!modifierGroupServices.size) {
        return;
      }
      update((s) => {
        const keys = Array.from(modifierGroupServices.keys());
        const selectedIndex = keys.findIndex((x) => x === s.selected_group_id);
        if (selectedIndex === -1 || selectedIndex === keys.length - 1) {
          return update_index(0, s);
        }

        return update_index(selectedIndex + 1, s);
      });
    },

    /**
     * selects the previous mod group for display
     */
    select_previous_mod_group: () => {
      update((s) => {
        const keys = Array.from(modifierGroupServices.keys());
        const selectedIndex = keys.findIndex((x) => x === s.selected_group_id);
        if (selectedIndex === -1 || selectedIndex === 0) {
          return update_index(0, s);
        }

        return update_index(selectedIndex - 1, s);
      });
    },

    /**
     * selects mod group for display, deselects it if it matches the current displayed group
     * @param group_id modifier_group_id
     */
    select_mod_group: (group_id: string) => {
      update((s) => {
        const keys = Array.from(modifierGroupServices.keys());
        const index = keys.findIndex((x) => x === group_id);
        return update_index(index, s);
      });
    },

    /**
     * sets quantity to a new value clamped by min and max
     * @param quantity new value for quantity
     * @returns
     */
    set_quantity: (quantity: number) => set_quantity(() => quantity),

    /**
     * increments quantity by one
     *
     * only call when **display.can_increase** is true
     * @returns void
     */
    increase_quantity: () => set_quantity((state) => state.quantity + 1),

    /**
     * decrements quantity by one
     *
     * only call when **display.can_decrease** is true
     * @returns void
     */
    decrease_quantity: () => set_quantity((state) => state.quantity - 1),

    /**
     * add special request for an item
     *
     * @param special_request
     */
    set_special_request: (special_request: string) => {
      update((s) => {
        return set_special_request(special_request, s);
      });
    },

    set_upsell_source: (upsellSource: OrderItemUpsellSourceInformation) => {
      update((src) => {
        src.upsellSource = upsellSource;
        return src;
      });
    },

    getCurrentQuantity: () => {
      return get(itemState).quantity;
    },

    getItemLimitReached: () => {
      return get(itemState).itemLimitReached;
    },

    getOrderLimitReached: () => {
      return get(itemState).orderLimitReached;
    },

    getSpecialRequest: () => {
      return get(itemState).special_request ?? '';
    },

    getUpsellRespurce: () => {
      return get(itemState).upsellSource;
    },

    getRewardId: () => {
      return get(itemState).rewardId ?? '';
    },

    getPricesStateManager: () => {
      return pricesStateManager;
    },

    getOverridedStartingPrice: () => {
      return overridedStartingPrice;
    },

    getInvalidRequiredModGroups: () => {
      const modGroupState = get(derivedState).derivedModifierGroupState;
      const invalidModGroups = Array.from(modGroupState).filter(
        (_mod) => !_mod[1].state.display.is_valid
      );
      const invalidModGroupIds = invalidModGroups.map((_mod) => _mod[1].state.modifierGroupId);
      return invalidModGroupIds;
    },

    getSelectionKey: () => {
      const state = get(itemState);
      return ([state.sourceCategory?.categoryId, ':', item.menuItemId, '-'] as (string | number)[])
        .concat(modifier_selection_key(state.mod_group_states))
        .join('_');
    },
  };
}

export type ItemService = {
  set_upsell_source: (upsellSource: OrderItemUpsellSourceInformation) => void;
  set_special_request: (special_request: string) => void;
  decrease_quantity: () => void;
  increase_quantity: () => void;
  set_quantity: (quantity: number) => void;
  select_mod_group: (group_id: string) => void;
  select_previous_mod_group: () => void;
  select_next_mod_group: () => void;
  subscribe: Readable<ItemServiceStoreState>['subscribe'];
  getCurrentQuantity(): number;
  getItemLimitReached(): boolean;
  getOrderLimitReached(): boolean;
  getSpecialRequest(): string;
  getUpsellRespurce(): OrderItemUpsellSourceInformation | undefined;
  getRewardId(): string;
  getPricesStateManager(): IPricesStateManager;
  getOverridedStartingPrice(): number | undefined;
  getInvalidRequiredModGroups(): string[];
  getSelectionKey(): string;
  item: KioskMenuItem;
  uid: string | undefined;
  modifierGroupServices: Map<string, ModGroupService>;
  addedToOrderTimestamp?: number;
  copy_details: {
    order_details: OrderItem | null;
    load_defaults: boolean;
  };
};
