/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "kioskPaymentService.proto" (package "Kiosk", syntax proto3)
// tslint:disable
// @ts-nocheck
import { OrderRefundRequest } from "./kioskPay_pb";
import { Balance } from "./kioskPay_pb";
import { BalanceRequest } from "./kioskPay_pb";
import { OrderPayment } from "./kioskOrder_pb";
import { OrderPaymentRequest } from "./kioskPay_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
/**
 * @generated ServiceType for protobuf service Kiosk.KioskPaymentService
 */
export const KioskPaymentService = new ServiceType("Kiosk.KioskPaymentService", [
    { name: "Pay", options: { "google.api.http": { post: "/v1/KioskPaymentService/Pay", body: "*" } }, I: OrderPaymentRequest, O: OrderPayment },
    { name: "GetBalance", options: { "google.api.http": { post: "/v1/KioskPaymentService/GetBalance", body: "*" } }, I: BalanceRequest, O: Balance },
    { name: "Refund", options: { "google.api.http": { post: "/v1/KioskPaymentService/Refund", body: "*" } }, I: OrderRefundRequest, O: OrderPayment }
]);
