import type { PaymentRequestData } from './types';

export function taxes_total({ taxes }: Pick<PaymentRequestData, 'taxes'>) {
  return Object.values(taxes).reduce((total, t) => total + t, 0);
}

// the order validate request does not include tip so the total we get back
// will already have the math done to add subtotal + taxes
// sometimes there is 0.01 difference b/w our subtotal + taxes and the total from the POS
// in order to avoid a discrepancy, we just add our tip on top
export function payment_request_total({ tip, preTipTotal }: PaymentRequestData) {
  return preTipTotal + tip;
}

export function value_as_float(value: number, divisor = 100) {
  return (value / divisor).toFixed(2);
}

const number_regex = /(.*)\W(\d+)/;
const non_digits = /\D+/g;
export function value_from_float_string(float: string, divisor = 100) {
  const match = float.match(number_regex);
  if (!match) {
    return 0;
  }
  const [, major, minor] = match;
  const majorInt = parseInt(major.replace(non_digits, ''), 10) * divisor;
  const minorInt = parseInt(minor, 10);
  return majorInt + minorInt;
}
