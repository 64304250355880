import { value_from_float_string } from '../payment_request_total';
import { CardNetwork, type PaymentInfo } from '../types';
import type { VerifoneCaptureResponse } from './types';

function* verifone_receipt_columns(response: VerifoneCaptureResponse) {
  // const {
  //   RESULT,
  //   TERMID,
  //   CTROUTD,
  //   INVOICE,
  //   MERCHID,
  //   AUTHNWID,
  //   AUTH_CODE,
  //   REFERENCE,
  //   RESPONSE_TEXT,
  // } = response;

  const { TERMID, CTROUTD } = response;

  if (TERMID) {
    yield { left: 'Term ID', right: TERMID };
  }
  // if (MERCHID) {
  //   yield { left: 'Merchant ID', right: MERCHID?.slice(-4) };
  // }
  if (CTROUTD) {
    yield { left: 'Transaction ID', right: CTROUTD };
  }
  // if (REFERENCE) {
  //   yield { left: 'Ref', right: REFERENCE };
  // }
  // if (AUTHNWID) {
  //   yield { left: 'Net ID', right: AUTHNWID };
  // }
  // yield { left: 'Appr Code', right: AUTH_CODE };
  // yield { left: 'Invoice', right: INVOICE };
  // yield { left: 'Result', right: RESULT };
  // yield { left: 'Response', right: RESPONSE_TEXT };

  //const sequence = response.EMV_TAG_9F41;
  const aid = response.EMV_TAG_4F || response.EMV_TAG_9F06 || response.EMV_TAG_84;
  // const tvr = response.EMV_TAG_95;
  // const tsi = response.EMV_TAG_9B;
  // const ac = response.EMV_TAG_9F26;
  // const iad = response.EMV_TAG_9F10;
  // if (sequence) {
  //   yield { left: 'Sequence', right: sequence };
  // }
  if (aid) {
    yield { left: 'AID', right: aid };
  }
  // if (tvr) {
  //   yield { left: 'TVR', right: tvr };
  // }
  // if (tsi) {
  //   yield { left: 'TSI', right: tsi };
  // }
  // if (ac) {
  //   yield { left: 'AC', right: ac };
  // }
  // if (iad) {
  //   yield { left: 'IAD', right: iad };
  // }
}

function get_verifone_time(response: VerifoneCaptureResponse) {
  const { TRANS_TIME, TRANS_DATE } = response;
  if (TRANS_TIME === undefined || TRANS_DATE === undefined) {
    return new Date();
  }
  const date = new Date(`${TRANS_DATE.replaceAll('.', '-')} ${TRANS_TIME}`);
  return isNaN(date.valueOf()) ? new Date() : date;
}

const networks = /visa|mc|master|disc|amex|american|debit/g;

function string_to_network(network: string): CardNetwork {
  const matches = network?.toLowerCase()?.match(networks);
  if (matches === null) {
    return CardNetwork.Unknown;
  }
  switch (matches[0]) {
    case 'visa':
      return CardNetwork.Visa;
    case 'mc':
    case 'master':
      return CardNetwork.MasterCard;
    case 'disc':
      return CardNetwork.Discover;
    case 'amex':
    case 'american':
      return CardNetwork.AMEX;
    case 'debit':
      return CardNetwork.Debit;
    default:
      return CardNetwork.Unknown;
  }
}

export function parse_verifone_response(
  response: VerifoneCaptureResponse,
  divisor: number,
  tipAmount: number
): PaymentInfo {
  const {
    ACCT_NUM,
    MIME_TYPE,
    CARDHOLDER,
    PAYMENT_TYPE,
    PAYMENT_MEDIA,
    SIGNATUREDATA,
    APPROVED_AMOUNT,
    CARD_ENTRY_MODE,
    CTROUTD,
    INVOICE,
    CARD_EXP_YEAR,
    CARD_EXP_MONTH,
    AUTH_CODE,
    CUSTOMER_ZIP,
    AVAILABLE_BALANCE,
  } = response;
  const amount = APPROVED_AMOUNT ? value_from_float_string(APPROVED_AMOUNT, divisor) : 0;

  return {
    transactionId: CTROUTD || '',
    time: get_verifone_time(response),
    additional_info: Array.from(verifone_receipt_columns(response)),
    signature: SIGNATUREDATA,
    signature_mime: MIME_TYPE,
    card_info: {
      holder_name: CARDHOLDER,
      network: string_to_network(PAYMENT_MEDIA),
      method: CARD_ENTRY_MODE,
      ending: ACCT_NUM?.slice(-4),
      maskedCardNumber: ACCT_NUM,
      expirationYear: CARD_EXP_YEAR,
      expirationMonth: CARD_EXP_MONTH,
      availableBalance: AVAILABLE_BALANCE
        ? value_from_float_string(AVAILABLE_BALANCE, divisor)
        : undefined,
    },
    authorizationCode: AUTH_CODE,
    zipCode: CUSTOMER_ZIP || '00000',
    amount,
    tipAmount,
    type: PAYMENT_TYPE,
    invoiceId: INVOICE || '',
  };
}
