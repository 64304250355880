import { derived, writable, type Writable } from 'svelte/store';
import type { IKioskConfigStateManager } from './kiosk_config_state_manager';
import rgba from 'color-rgba';
import type { KioskColors, LoyaltyColors, MenuColors } from '../../generated/kiosk_pb';
import type { PosStoreData } from '../storage/location_db';
import type { ThemeStore } from '../context/apperance_context';

export function colors_to_style(
  colors: KioskColors | LoyaltyColors | MenuColors | undefined
): string {
  if (!colors) {
    return '';
  }
  const capRegex = /on([A-Z])/g;
  return Object.entries(colors)
    .map(([name, value]) => {
      if (!value) {
        return undefined;
      }
      const color = rgba(value);
      if (!color) {
        return undefined;
      }

      return `--color-${name.replace(capRegex, 'on-$1').toLowerCase()}: ${color[0]}, ${color[1]}, ${
        color[2]
      }`;
    })
    .filter((x): x is string => x !== undefined)
    .join('; ');
}

export type Theme = {
  theme: KioskColors;
  loyalty_theme?: LoyaltyColors;
  menu_theme?: MenuColors;
};

export interface IThemeStateManager {
  themeStore: ThemeStore;
  setPrimaryTheme(theme: Theme): void;
  setSecondaryTheme(theme: Theme): void;
  clearPrimaryTheme(): void;
  clearSecondaryTheme(): void;
}

const getConfigValues = (store: PosStoreData) => {
  const color_row = store.colors;
  const loyalty_row = store.loyalty_colors;
  const menu_row = store.menu_colors;
  return {
    theme: color_row,
    loyalty_theme: loyalty_row,
    menu_theme: menu_row,
  };
};

export class ThemeStateManager implements IThemeStateManager {
  private kioskConfigStateManager: IKioskConfigStateManager;
  public themeStore: ThemeStore;
  private primaryTheme: Writable<Theme | undefined>;
  private secondaryTheme: Writable<Theme | undefined>;

  constructor(kioskConfigStateManager: IKioskConfigStateManager) {
    this.kioskConfigStateManager = kioskConfigStateManager;
    this.primaryTheme = writable(undefined);
    this.secondaryTheme = writable(undefined);

    this.themeStore = derived(
      [this.primaryTheme, this.secondaryTheme, this.kioskConfigStateManager.posStore],
      ([$primaryTheme, $secondaryTheme, $configStore]) => {
        const baseConfigValues = getConfigValues($configStore);
        const theme = colors_to_style($primaryTheme?.theme ?? baseConfigValues.theme);
        const loyaltyTheme = colors_to_style(
          $primaryTheme?.loyalty_theme ?? baseConfigValues.loyalty_theme
        );
        const menuTheme = colors_to_style($primaryTheme?.menu_theme ?? baseConfigValues.menu_theme);
        const secondaryTheme = colors_to_style($secondaryTheme?.theme ?? baseConfigValues.theme);

        // if (evt.data.loyalty_theme) ctx.loyalty_theme = colors_to_style(evt.data.loyalty_theme);
        // if (evt.data.menu_theme) ctx.menu_theme = `${colors_to_style(evt.data.menu_theme)}; `;
        // ctx.primary_theme = `${colors_to_style(evt.data.theme)}; ${ctx.loyalty_theme};  ${
        //   ctx.menu_theme
        // };`;
        return {
          primaryTheme: `${theme}; ${loyaltyTheme};  ${menuTheme};`,
          secondaryTheme: `${secondaryTheme}; ${loyaltyTheme};  ${menuTheme};`,
          loyaltyTheme: loyaltyTheme,
          menuTheme: menuTheme,
        };
      }
    );
  }

  setPrimaryTheme(theme: Theme) {
    this.primaryTheme.set(theme);
  }

  setSecondaryTheme(theme: Theme) {
    this.secondaryTheme.set(theme);
  }

  clearPrimaryTheme(): void {
    this.primaryTheme.set(undefined);
  }

  clearSecondaryTheme(): void {
    this.secondaryTheme.set(undefined);
  }
}
