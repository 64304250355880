export const enum VerifoneEmvReversal {
  CardRemoved = '401',
  ChipDeclined = '402',
  OtherProblem = '403',
  NULL = 'NULL',
}

export const enum VerifoneResultCodes {
  Success = '-1',
  Settled = '2',
  Captured = '4',
  Approved = '5',
  Declined = '6',
  Voided = '7',
  Completed = '10',
  OkOrCommError = '14',
  TipModified = '17',
  SettlementScheduled = '21',
  Reversed = '22',
  TimedOut = '23',
  NotAvailable = '24',
  Base64EncodeError = '40001',
  EncryptionError = '40002',
  DecryptionError = '40006',
  MacNotFound = '40007',
  EntryCodeMismatch = '40009',
  InvoiceMismatch = '56023',
  DuplicateTransaction = '56783',
  Cancelled = '59001',
  DeviceBusy = '59002',
  SessionInProgress = '59003',
  NoSession = '59004',
  UnsupportedOperation = '59005',
  CardMismatch = '59009',
  MacOrPinMismatch = '59040',
  CounterError = '59043',
  CancelledByPos = '59049',
  DeviceReboot = '59050',
  MaxPOSConnections = '59051',
  Unknown = '99995',
  Suspended = '99996',
  Starting = '99997',
  Loading = '99998',
  Terminated = '99999',
}

export const enum VerifoneSecondaryData {
  Success = '0',
  NotAllowed = '1',
  NoTransactionToCancel = '2',
  NoSession = '10',
  InSession = '11',
  InSessionLineItem = '12',
  InSessionPayment = '13',
  PostingPayment = '14',
  PostingReport = '15',
  CapturingVoid = '16',
  PostingVoid = '17',
  CapturingRefund = '18',
  PostingRefund = '19',
  CapturingGift = '20',
  PostingGift = '21',
  Register = '22',
  SafQuery = '23',
  SafRemoval = '24',
  CapturingLoyalty = '28',
  CapturingVersion = '31',
  LaneClosed = '33',
  WelcomeScreen = '34',
  TransactionCompleted = '41',
  ProcessingGetParm = '43',
  ProcessingDisplayMessage = '46',
}
