import { XMLBuilder, XMLParser } from 'fast-xml-parser';
import { waitFor } from 'xstate/lib/waitFor';
import type { InterpretedTcpSocketMachine } from '../../proxies/tcp_socket_proxy_machine';
import type {
  VerifoneResponse,
  VerifoneScaSession,
  VerifoneSecureMessage,
  VerifoneSecureSecureSessionResponse,
  VerifoneTransactionMessage,
} from './types';
import { VerifoneResultCodes } from './verifone_constants';
import { decode, sign_verifone_counter } from './verifone_encryption_methods';
import { gem_service } from '../../../states/events/gem_event_service';

// function concat_buffers(buffers: ArrayBuffer[]) {
//   function* iterate_buffers() {
//     for (const buffer of buffers) {
//       yield* new Uint8Array(buffer);
//     }
//   }
//   return new Uint8Array(iterate_buffers());
// }

// </RESPONSE>\r\n
const closeResponse = new Uint8Array([
  0x3c, 0x2f, 0x52, 0x45, 0x53, 0x50, 0x4f, 0x4e, 0x53, 0x45, 0x3e, 0x0d, 0x0a,
]);
const xmlBuilder = new XMLBuilder({ format: false });
// due to inconsistent values from verifone (strings | numbers) it makes more sense to not parse anything unless explicitly done
const xmlParser = new XMLParser({ parseTagValue: false, processEntities: false });
export async function transact_and_receive_response<R extends VerifoneResponse>(
  message: VerifoneTransactionMessage,
  socket: InterpretedTcpSocketMachine,
  halt = true
  // compare: (response: R) => boolean = () => true
) {
  const xml_message = xmlBuilder.build({ TRANSACTION: message });
  gem_service.verifone_request(xml_message);
  socket.send({ type: 'SEND', data: xml_message });

  let received_data: Uint8Array | undefined;
  do {
    try {
      const { context } = await waitFor(
        socket,
        (state) => {
          if (!state.context.received_data) {
            return false;
          }
          const endOfMessage = state.context.received_data.slice(-closeResponse.length);
          return closeResponse.every((val, i) => val === endOfMessage[i]);
        },
        {
          timeout: 120000,
        }
      );
      received_data = context.received_data;
    } catch (ex) {
      // console.warn(ex);
    }
  } while (halt && !received_data);

  if (!received_data) {
    throw new Error('Timeout');
  }

  const decodedMessage = decode(received_data);
  gem_service.verifone_response(decodedMessage);
  const { RESPONSE }: { RESPONSE: R } = xmlParser.parse(decodedMessage);
  return RESPONSE;
}

export async function sign_trasact_and_receive<R extends VerifoneSecureSecureSessionResponse>(
  message: VerifoneTransactionMessage,
  socket: InterpretedTcpSocketMachine,
  { MAC_KEY, COUNTER, MAC_LABEL }: VerifoneScaSession
): Promise<[Pick<VerifoneScaSession, 'COUNTER'>, R]> {
  let result: R;
  do {
    const MAC = sign_verifone_counter(MAC_KEY, COUNTER.toString());
    result = await transact_and_receive_response<R>(
      Object.assign(message, {
        COUNTER,
        MAC_LABEL,
        MAC,
      }) as VerifoneTransactionMessage & VerifoneSecureMessage,
      socket
      // (res) =>
      //   parseInt(res.COUNTER, 10) === COUNTER ||
      //   res.RESULT_CODE === VerifoneResultCodes.CounterError
    );
    if (result.RESULT_CODE === VerifoneResultCodes.CounterError) {
      const counter_result =
        await transact_and_receive_response<VerifoneSecureSecureSessionResponse>(
          { FUNCTION_TYPE: 'ADMIN', COMMAND: 'GET_COUNTER', MAC_LABEL },
          socket
        );
      if (counter_result.RESULT_CODE !== VerifoneResultCodes.Success) {
        throw new Error('Invalid Session State');
      }
      COUNTER = (parseInt(counter_result.COUNTER, 10) || COUNTER) + 1;
    }
  } while (result.RESULT_CODE === VerifoneResultCodes.CounterError);
  return [
    {
      COUNTER: (parseInt(result.COUNTER, 10) || COUNTER) + 1,
    },
    result,
  ];
}
