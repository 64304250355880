export async function load_script(src: string) {
  if (document.querySelector(`script[src="${src}"]`)) {
    return true;
  }
  return new Promise<boolean>(function (resolve, reject) {
    const script = document.createElement('script');
    script.onload = function () {
      resolve(true);
    };
    script.onerror = function (e) {
      reject(e);
    };
    script.src = src;
    document.querySelector('head')?.appendChild(script);
  });
}
