/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "common.proto" (package "Kiosk", syntax proto3)
// tslint:disable
// @ts-nocheck
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { NullValue } from "./google/protobuf/struct_pb";
/**
 * @generated from protobuf message Kiosk.EmptyResponse
 */
export interface EmptyResponse {
}
/**
 * @generated from protobuf message Kiosk.EmptyRequest
 */
export interface EmptyRequest {
}
/**
 * @generated from protobuf message Kiosk.OrderTypeTestVM
 */
export interface OrderTypeTestVM {
    /**
     * @generated from protobuf field: repeated string orderTypes = 1;
     */
    orderTypes: string[];
}
/**
 * @generated from protobuf message Kiosk.SectionsTestVM
 */
export interface SectionsTestVM {
    /**
     * @generated from protobuf field: repeated string sections = 1;
     */
    sections: string[];
}
/**
 * @generated from protobuf message Kiosk.TestVM
 */
export interface TestVM {
    /**
     * @generated from protobuf oneof: test
     */
    test: {
        oneofKind: "orderTypeTest";
        /**
         * @generated from protobuf field: Kiosk.OrderTypeTestVM orderTypeTest = 1;
         */
        orderTypeTest: OrderTypeTestVM;
    } | {
        oneofKind: "sectionsTest";
        /**
         * @generated from protobuf field: Kiosk.SectionsTestVM sectionsTest = 2;
         */
        sectionsTest: SectionsTestVM;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message Kiosk.PhoneNumberVM
 */
export interface PhoneNumberVM {
    /**
     * @generated from protobuf field: string countryCode = 1;
     */
    countryCode: string;
    /**
     * @generated from protobuf field: string number = 2;
     */
    number: string;
}
/**
 * @generated from protobuf message Kiosk.ConditionsVM
 */
export interface ConditionsVM {
    /**
     * @generated from protobuf field: repeated Kiosk.TestVM Tests = 1 [json_name = "Tests"];
     */
    tests: TestVM[];
}
/**
 * @generated from protobuf message Kiosk.ResponseStatus
 */
export interface ResponseStatus {
    /**
     * @generated from protobuf oneof: StatusType
     */
    statusType: {
        oneofKind: "success";
        /**
         * @generated from protobuf field: bool success = 1;
         */
        success: boolean;
    } | {
        oneofKind: "failure";
        /**
         * @generated from protobuf field: bool failure = 2;
         */
        failure: boolean;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: string message = 3;
     */
    message: string;
    /**
     * @generated from protobuf field: string localization_key = 4;
     */
    localizationKey: string;
    /**
     * @generated from protobuf field: int32 error_code = 5;
     */
    errorCode: number;
}
/**
 * @generated from protobuf message Kiosk.ErrorResponse
 */
export interface ErrorResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
    /**
     * @generated from protobuf field: string localization_key = 2;
     */
    localizationKey: string;
    /**
     * @generated from protobuf field: int32 error_code = 3;
     */
    errorCode: number;
}
/**
 * @generated from protobuf message Kiosk.CardNetworkMapping
 */
export interface CardNetworkMapping {
    /**
     * @generated from protobuf field: string unknown = 1;
     */
    unknown: string;
    /**
     * @generated from protobuf field: string visa = 2;
     */
    visa: string;
    /**
     * @generated from protobuf field: string masterCard = 3;
     */
    masterCard: string;
    /**
     * @generated from protobuf field: string amex = 4;
     */
    amex: string;
    /**
     * @generated from protobuf field: string discover = 5;
     */
    discover: string;
}
/**
 * @generated from protobuf message Kiosk.OptionalDayOfTheDayOfWeekVM
 */
export interface OptionalDayOfTheDayOfWeekVM {
    /**
     * @generated from protobuf oneof: dayOfWeek
     */
    dayOfWeek: {
        oneofKind: "value";
        /**
         * @generated from protobuf field: Kiosk.DayOfWeekVM value = 1;
         */
        value: DayOfWeekVM;
    } | {
        oneofKind: "nullValue";
        /**
         * @generated from protobuf field: google.protobuf.NullValue nullValue = 2;
         */
        nullValue: NullValue;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Common status result message for all requests
 *
 * @generated from protobuf message Kiosk.StatusResult
 */
export interface StatusResult {
    /**
     * @generated from protobuf field: bool isSuccess = 1;
     */
    isSuccess: boolean;
    /**
     * @generated from protobuf field: Kiosk.ErrorCode errorCode = 2;
     */
    errorCode: ErrorCode;
    /**
     * @generated from protobuf field: string message = 3;
     */
    message: string;
}
/**
 * @generated from protobuf enum Kiosk.ModifierCodeBehavior
 */
export enum ModifierCodeBehavior {
    /**
     * @generated from protobuf enum value: None = 0;
     */
    None = 0,
    /**
     * @generated from protobuf enum value: Negative = 1;
     */
    Negative = 1,
    /**
     * @generated from protobuf enum value: Extra = 2;
     */
    Extra = 2
}
/**
 * @generated from protobuf enum Kiosk.DayOfWeekVM
 */
export enum DayOfWeekVM {
    /**
     * @generated from protobuf enum value: SUNDAY = 0;
     */
    SUNDAY = 0,
    /**
     * @generated from protobuf enum value: MONDAY = 1;
     */
    MONDAY = 1,
    /**
     * @generated from protobuf enum value: TUESDAY = 2;
     */
    TUESDAY = 2,
    /**
     * @generated from protobuf enum value: WEDNESDAY = 3;
     */
    WEDNESDAY = 3,
    /**
     * @generated from protobuf enum value: THURSDAY = 4;
     */
    THURSDAY = 4,
    /**
     * @generated from protobuf enum value: FRIDAY = 5;
     */
    FRIDAY = 5,
    /**
     * @generated from protobuf enum value: SATURDAY = 6;
     */
    SATURDAY = 6
}
/**
 * @generated from protobuf enum Kiosk.ErrorCode
 */
export enum ErrorCode {
    /**
     * @generated from protobuf enum value: UnknownError = 0;
     */
    UnknownError = 0,
    /**
     * @generated from protobuf enum value: UnableToResolveMenuPrices = 1;
     */
    UnableToResolveMenuPrices = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class EmptyResponse$Type extends MessageType<EmptyResponse> {
    constructor() {
        super("Kiosk.EmptyResponse", []);
    }
    create(value?: PartialMessage<EmptyResponse>): EmptyResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EmptyResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmptyResponse): EmptyResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EmptyResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.EmptyResponse
 */
export const EmptyResponse = new EmptyResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmptyRequest$Type extends MessageType<EmptyRequest> {
    constructor() {
        super("Kiosk.EmptyRequest", []);
    }
    create(value?: PartialMessage<EmptyRequest>): EmptyRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EmptyRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmptyRequest): EmptyRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EmptyRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.EmptyRequest
 */
export const EmptyRequest = new EmptyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderTypeTestVM$Type extends MessageType<OrderTypeTestVM> {
    constructor() {
        super("Kiosk.OrderTypeTestVM", [
            { no: 1, name: "orderTypes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OrderTypeTestVM>): OrderTypeTestVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.orderTypes = [];
        if (value !== undefined)
            reflectionMergePartial<OrderTypeTestVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderTypeTestVM): OrderTypeTestVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string orderTypes */ 1:
                    message.orderTypes.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderTypeTestVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string orderTypes = 1; */
        for (let i = 0; i < message.orderTypes.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.orderTypes[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderTypeTestVM
 */
export const OrderTypeTestVM = new OrderTypeTestVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SectionsTestVM$Type extends MessageType<SectionsTestVM> {
    constructor() {
        super("Kiosk.SectionsTestVM", [
            { no: 1, name: "sections", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SectionsTestVM>): SectionsTestVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sections = [];
        if (value !== undefined)
            reflectionMergePartial<SectionsTestVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SectionsTestVM): SectionsTestVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string sections */ 1:
                    message.sections.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SectionsTestVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string sections = 1; */
        for (let i = 0; i < message.sections.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.sections[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.SectionsTestVM
 */
export const SectionsTestVM = new SectionsTestVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TestVM$Type extends MessageType<TestVM> {
    constructor() {
        super("Kiosk.TestVM", [
            { no: 1, name: "orderTypeTest", kind: "message", oneof: "test", T: () => OrderTypeTestVM },
            { no: 2, name: "sectionsTest", kind: "message", oneof: "test", T: () => SectionsTestVM }
        ]);
    }
    create(value?: PartialMessage<TestVM>): TestVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.test = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<TestVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TestVM): TestVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.OrderTypeTestVM orderTypeTest */ 1:
                    message.test = {
                        oneofKind: "orderTypeTest",
                        orderTypeTest: OrderTypeTestVM.internalBinaryRead(reader, reader.uint32(), options, (message.test as any).orderTypeTest)
                    };
                    break;
                case /* Kiosk.SectionsTestVM sectionsTest */ 2:
                    message.test = {
                        oneofKind: "sectionsTest",
                        sectionsTest: SectionsTestVM.internalBinaryRead(reader, reader.uint32(), options, (message.test as any).sectionsTest)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TestVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.OrderTypeTestVM orderTypeTest = 1; */
        if (message.test.oneofKind === "orderTypeTest")
            OrderTypeTestVM.internalBinaryWrite(message.test.orderTypeTest, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.SectionsTestVM sectionsTest = 2; */
        if (message.test.oneofKind === "sectionsTest")
            SectionsTestVM.internalBinaryWrite(message.test.sectionsTest, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.TestVM
 */
export const TestVM = new TestVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PhoneNumberVM$Type extends MessageType<PhoneNumberVM> {
    constructor() {
        super("Kiosk.PhoneNumberVM", [
            { no: 1, name: "countryCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PhoneNumberVM>): PhoneNumberVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.countryCode = "";
        message.number = "";
        if (value !== undefined)
            reflectionMergePartial<PhoneNumberVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PhoneNumberVM): PhoneNumberVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string countryCode */ 1:
                    message.countryCode = reader.string();
                    break;
                case /* string number */ 2:
                    message.number = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PhoneNumberVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string countryCode = 1; */
        if (message.countryCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.countryCode);
        /* string number = 2; */
        if (message.number !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.number);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.PhoneNumberVM
 */
export const PhoneNumberVM = new PhoneNumberVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConditionsVM$Type extends MessageType<ConditionsVM> {
    constructor() {
        super("Kiosk.ConditionsVM", [
            { no: 1, name: "Tests", kind: "message", jsonName: "Tests", repeat: 1 /*RepeatType.PACKED*/, T: () => TestVM }
        ]);
    }
    create(value?: PartialMessage<ConditionsVM>): ConditionsVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tests = [];
        if (value !== undefined)
            reflectionMergePartial<ConditionsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConditionsVM): ConditionsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.TestVM Tests = 1 [json_name = "Tests"];*/ 1:
                    message.tests.push(TestVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConditionsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.TestVM Tests = 1 [json_name = "Tests"]; */
        for (let i = 0; i < message.tests.length; i++)
            TestVM.internalBinaryWrite(message.tests[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ConditionsVM
 */
export const ConditionsVM = new ConditionsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResponseStatus$Type extends MessageType<ResponseStatus> {
    constructor() {
        super("Kiosk.ResponseStatus", [
            { no: 1, name: "success", kind: "scalar", oneof: "statusType", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "failure", kind: "scalar", oneof: "statusType", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "localization_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "error_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ResponseStatus>): ResponseStatus {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.statusType = { oneofKind: undefined };
        message.message = "";
        message.localizationKey = "";
        message.errorCode = 0;
        if (value !== undefined)
            reflectionMergePartial<ResponseStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResponseStatus): ResponseStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.statusType = {
                        oneofKind: "success",
                        success: reader.bool()
                    };
                    break;
                case /* bool failure */ 2:
                    message.statusType = {
                        oneofKind: "failure",
                        failure: reader.bool()
                    };
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                case /* string localization_key */ 4:
                    message.localizationKey = reader.string();
                    break;
                case /* int32 error_code */ 5:
                    message.errorCode = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResponseStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.statusType.oneofKind === "success")
            writer.tag(1, WireType.Varint).bool(message.statusType.success);
        /* bool failure = 2; */
        if (message.statusType.oneofKind === "failure")
            writer.tag(2, WireType.Varint).bool(message.statusType.failure);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        /* string localization_key = 4; */
        if (message.localizationKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.localizationKey);
        /* int32 error_code = 5; */
        if (message.errorCode !== 0)
            writer.tag(5, WireType.Varint).int32(message.errorCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ResponseStatus
 */
export const ResponseStatus = new ResponseStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ErrorResponse$Type extends MessageType<ErrorResponse> {
    constructor() {
        super("Kiosk.ErrorResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "localization_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "error_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ErrorResponse>): ErrorResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.message = "";
        message.localizationKey = "";
        message.errorCode = 0;
        if (value !== undefined)
            reflectionMergePartial<ErrorResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ErrorResponse): ErrorResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                case /* string localization_key */ 2:
                    message.localizationKey = reader.string();
                    break;
                case /* int32 error_code */ 3:
                    message.errorCode = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ErrorResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        /* string localization_key = 2; */
        if (message.localizationKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.localizationKey);
        /* int32 error_code = 3; */
        if (message.errorCode !== 0)
            writer.tag(3, WireType.Varint).int32(message.errorCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ErrorResponse
 */
export const ErrorResponse = new ErrorResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardNetworkMapping$Type extends MessageType<CardNetworkMapping> {
    constructor() {
        super("Kiosk.CardNetworkMapping", [
            { no: 1, name: "unknown", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "visa", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "masterCard", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "amex", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "discover", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CardNetworkMapping>): CardNetworkMapping {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.unknown = "";
        message.visa = "";
        message.masterCard = "";
        message.amex = "";
        message.discover = "";
        if (value !== undefined)
            reflectionMergePartial<CardNetworkMapping>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CardNetworkMapping): CardNetworkMapping {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string unknown */ 1:
                    message.unknown = reader.string();
                    break;
                case /* string visa */ 2:
                    message.visa = reader.string();
                    break;
                case /* string masterCard */ 3:
                    message.masterCard = reader.string();
                    break;
                case /* string amex */ 4:
                    message.amex = reader.string();
                    break;
                case /* string discover */ 5:
                    message.discover = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CardNetworkMapping, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string unknown = 1; */
        if (message.unknown !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.unknown);
        /* string visa = 2; */
        if (message.visa !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.visa);
        /* string masterCard = 3; */
        if (message.masterCard !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.masterCard);
        /* string amex = 4; */
        if (message.amex !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.amex);
        /* string discover = 5; */
        if (message.discover !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.discover);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.CardNetworkMapping
 */
export const CardNetworkMapping = new CardNetworkMapping$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OptionalDayOfTheDayOfWeekVM$Type extends MessageType<OptionalDayOfTheDayOfWeekVM> {
    constructor() {
        super("Kiosk.OptionalDayOfTheDayOfWeekVM", [
            { no: 1, name: "value", kind: "enum", oneof: "dayOfWeek", T: () => ["Kiosk.DayOfWeekVM", DayOfWeekVM] },
            { no: 2, name: "nullValue", kind: "enum", oneof: "dayOfWeek", T: () => ["google.protobuf.NullValue", NullValue] }
        ]);
    }
    create(value?: PartialMessage<OptionalDayOfTheDayOfWeekVM>): OptionalDayOfTheDayOfWeekVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.dayOfWeek = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<OptionalDayOfTheDayOfWeekVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OptionalDayOfTheDayOfWeekVM): OptionalDayOfTheDayOfWeekVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.DayOfWeekVM value */ 1:
                    message.dayOfWeek = {
                        oneofKind: "value",
                        value: reader.int32()
                    };
                    break;
                case /* google.protobuf.NullValue nullValue */ 2:
                    message.dayOfWeek = {
                        oneofKind: "nullValue",
                        nullValue: reader.int32()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OptionalDayOfTheDayOfWeekVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.DayOfWeekVM value = 1; */
        if (message.dayOfWeek.oneofKind === "value")
            writer.tag(1, WireType.Varint).int32(message.dayOfWeek.value);
        /* google.protobuf.NullValue nullValue = 2; */
        if (message.dayOfWeek.oneofKind === "nullValue")
            writer.tag(2, WireType.Varint).int32(message.dayOfWeek.nullValue);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OptionalDayOfTheDayOfWeekVM
 */
export const OptionalDayOfTheDayOfWeekVM = new OptionalDayOfTheDayOfWeekVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StatusResult$Type extends MessageType<StatusResult> {
    constructor() {
        super("Kiosk.StatusResult", [
            { no: 1, name: "isSuccess", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "errorCode", kind: "enum", T: () => ["Kiosk.ErrorCode", ErrorCode] },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StatusResult>): StatusResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isSuccess = false;
        message.errorCode = 0;
        message.message = "";
        if (value !== undefined)
            reflectionMergePartial<StatusResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StatusResult): StatusResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isSuccess */ 1:
                    message.isSuccess = reader.bool();
                    break;
                case /* Kiosk.ErrorCode errorCode */ 2:
                    message.errorCode = reader.int32();
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StatusResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isSuccess = 1; */
        if (message.isSuccess !== false)
            writer.tag(1, WireType.Varint).bool(message.isSuccess);
        /* Kiosk.ErrorCode errorCode = 2; */
        if (message.errorCode !== 0)
            writer.tag(2, WireType.Varint).int32(message.errorCode);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.StatusResult
 */
export const StatusResult = new StatusResult$Type();
