import { hmac, pki, util } from 'node-forge';

export function generate_verifone_keypair() {
  return new Promise<pki.rsa.KeyPair>((res, rej) =>
    pki.rsa.generateKeyPair({ bits: 2048, workers: -1 }, function (err, keyPair) {
      if (err) {
        rej(err);
        return;
      }
      res(keyPair);
    })
  );
}

export function export_verifone_key(keyPair: pki.rsa.KeyPair) {
  const pem = pki.publicKeyToPem(keyPair.publicKey);
  return pem.slice(27, -27);
}

export function decode_verifone_mac_key(encodedKey: string, keyPair: pki.rsa.KeyPair) {
  const base64Decode = util.decode64(encodedKey);
  return keyPair.privateKey.decrypt(base64Decode);
}

export function sign_verifone_counter(mac_key: string, counter: string) {
  const digester = hmac.create();
  digester.start('sha256', mac_key);
  digester.update(counter);
  return util.encode64(digester.digest().getBytes());
}

export function decode(str: ArrayBuffer) {
  return String.fromCharCode.apply(null, new Uint8Array(str) as unknown as number[]);
}
