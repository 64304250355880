export function isDefined<T>(x: T | undefined | null): x is T {
  return x !== null && x !== undefined;
}

export function assertExists<T>(x: T | undefined | null, errorMessage: string): asserts x is T {
  if (!x) {
    console.log(`assert exists failed`);
    throw new Error(errorMessage);
  }
}
