/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "kioskOrder.proto" (package "Kiosk", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { LocalizedStringSet } from "./menu_pb";
import { LoyaltyReward } from "./loyalty_pb";
import { Int32Value } from "./google/protobuf/wrappers_pb";
import { ResponseStatus } from "./common_pb";
import { LoyaltyUser } from "./loyalty_pb";
import { StringValue } from "./google/protobuf/wrappers_pb";
/**
 * @generated from protobuf message Kiosk.ValidateOrderRequest
 */
export interface ValidateOrderRequest {
    /**
     * @generated from protobuf field: Kiosk.MenuOrder order = 1;
     */
    order?: MenuOrder;
    /**
     * @generated from protobuf field: repeated Kiosk.OrderDiscount discounts = 2;
     */
    discounts: OrderDiscount[];
    /**
     * @generated from protobuf field: google.protobuf.StringValue orderId = 3;
     */
    orderId?: StringValue;
    /**
     * @generated from protobuf field: Kiosk.LoyaltyUser loyaltyUser = 4;
     */
    loyaltyUser?: LoyaltyUser;
}
/**
 * @generated from protobuf message Kiosk.ValidateOrderResponse
 */
export interface ValidateOrderResponse {
    /**
     * @generated from protobuf field: Kiosk.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: Kiosk.OrderDetails orderDetails = 2;
     */
    orderDetails?: OrderDetails;
}
/**
 * @generated from protobuf message Kiosk.UpdateOrderRequest
 */
export interface UpdateOrderRequest {
    /**
     * @generated from protobuf field: string orderId = 1;
     */
    orderId: string;
    /**
     * @generated from protobuf field: Kiosk.MenuOrder order = 2;
     */
    order?: MenuOrder;
}
/**
 * @generated from protobuf message Kiosk.CloseOrderRequest
 */
export interface CloseOrderRequest {
    /**
     * @generated from protobuf field: Kiosk.MenuOrder order = 1;
     */
    order?: MenuOrder;
    /**
     * @generated from protobuf field: Kiosk.OrderDetails details = 2;
     */
    details?: OrderDetails;
    /**
     * @generated from protobuf field: repeated Kiosk.OrderPayment payments = 3;
     */
    payments: OrderPayment[];
    /**
     * @generated from protobuf field: repeated Kiosk.OrderDiscount discounts = 4;
     */
    discounts: OrderDiscount[];
    /**
     * @generated from protobuf field: string tipTender = 5;
     */
    tipTender: string;
    /**
     * @generated from protobuf field: string discountTender = 6;
     */
    discountTender: string;
    /**
     * @generated from protobuf field: string userId = 7;
     */
    userId: string;
    /**
     * @generated from protobuf field: bool failedToSend = 8;
     */
    failedToSend: boolean;
    /**
     * @generated from protobuf field: Kiosk.LoyaltyUser loyaltyUser = 9;
     */
    loyaltyUser?: LoyaltyUser;
}
/**
 * @generated from protobuf message Kiosk.LogOrderRequest
 */
export interface LogOrderRequest {
    /**
     * @generated from protobuf field: string orderId = 1;
     */
    orderId: string;
    /**
     * @generated from protobuf field: Kiosk.CloseOrderRequest request = 2;
     */
    request?: CloseOrderRequest;
    /**
     * @generated from protobuf field: string receiptImage = 3;
     */
    receiptImage: string;
}
/**
 * @generated from protobuf message Kiosk.LogOrderReply
 */
export interface LogOrderReply {
    /**
     * @generated from protobuf field: Kiosk.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message Kiosk.CloseOrderReply
 */
export interface CloseOrderReply {
    /**
     * @generated from protobuf field: bool isSuccess = 1;
     */
    isSuccess: boolean;
    /**
     * @generated from protobuf field: string orderId = 2;
     */
    orderId: string;
    /**
     * @generated from protobuf field: Kiosk.ResponseStatus status = 3;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message Kiosk.DeleteOrderRequest
 */
export interface DeleteOrderRequest {
    /**
     * @generated from protobuf field: string sessionid = 1;
     */
    sessionid: string;
    /**
     * @generated from protobuf field: string orderId = 2;
     */
    orderId: string;
}
/**
 * @generated from protobuf message Kiosk.DeleteOrderReply
 */
export interface DeleteOrderReply {
    /**
     * @generated from protobuf field: bool isSuccess = 1;
     */
    isSuccess: boolean;
    /**
     * @generated from protobuf field: Kiosk.ResponseStatus status = 2;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message Kiosk.OrderPayment
 */
export interface OrderPayment {
    /**
     * @generated from protobuf field: string paymentIntegrationId = 1;
     */
    paymentIntegrationId: string;
    /**
     * @generated from protobuf field: int32 totalPaid = 2;
     */
    totalPaid: number;
    /**
     * @generated from protobuf field: string transactionId = 3;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: string paymentIntegrationLabel = 4;
     */
    paymentIntegrationLabel: string;
    /**
     * @generated from protobuf field: string tenderId = 5;
     */
    tenderId: string;
    /**
     * @generated from protobuf field: optional string paymentCardName = 6;
     */
    paymentCardName?: string;
    /**
     * @generated from protobuf field: optional bool isAmazonOnePayment = 7;
     */
    isAmazonOnePayment?: boolean;
    /**
     * @generated from protobuf field: int32 tipAmount = 8;
     */
    tipAmount: number;
    /**
     * @generated from protobuf field: Kiosk.TenderInfo tenderInfo = 9;
     */
    tenderInfo?: TenderInfo;
}
/**
 * @generated from protobuf message Kiosk.TenderInfo
 */
export interface TenderInfo {
    /**
     * @generated from protobuf oneof: kind
     */
    kind: {
        oneofKind: "cardInfo";
        /**
         * @generated from protobuf field: Kiosk.CardInfo cardInfo = 2;
         */
        cardInfo: CardInfo;
    } | {
        oneofKind: "storedValueInfo";
        /**
         * @generated from protobuf field: Kiosk.StoredValueInfo storedValueInfo = 3;
         */
        storedValueInfo: StoredValueInfo;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message Kiosk.StoredValueInfo
 */
export interface StoredValueInfo {
    /**
     * @generated from protobuf field: string accountId = 1;
     */
    accountId: string;
    /**
     * @generated from protobuf field: string integrationMetadata = 2;
     */
    integrationMetadata: string;
}
/**
 * @generated from protobuf message Kiosk.CardInfo
 */
export interface CardInfo {
    /**
     * @generated from protobuf field: Kiosk.CardInfo.CardNetworkType cardType = 1;
     */
    cardType: CardInfo_CardNetworkType;
    /**
     * @generated from protobuf field: string lastFour = 2;
     */
    lastFour: string;
    /**
     * @generated from protobuf field: google.protobuf.StringValue zipCode = 3;
     */
    zipCode?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue processorAuthCode = 4;
     */
    processorAuthCode?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue expirationMonth = 5;
     */
    expirationMonth?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue expirationYear = 6;
     */
    expirationYear?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue maskedCardNumber = 7;
     */
    maskedCardNumber?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value availableBalance = 8;
     */
    availableBalance?: Int32Value;
}
/**
 * @generated from protobuf enum Kiosk.CardInfo.CardNetworkType
 */
export enum CardInfo_CardNetworkType {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: VISA = 1;
     */
    VISA = 1,
    /**
     * @generated from protobuf enum value: MASTERCARD = 2;
     */
    MASTERCARD = 2,
    /**
     * @generated from protobuf enum value: AMERICAN_EXPRESS = 3;
     */
    AMERICAN_EXPRESS = 3,
    /**
     * @generated from protobuf enum value: DISCOVER = 4;
     */
    DISCOVER = 4,
    /**
     * @generated from protobuf enum value: DINERS_CLUB = 5;
     */
    DINERS_CLUB = 5,
    /**
     * @generated from protobuf enum value: JCB = 6;
     */
    JCB = 6,
    /**
     * @generated from protobuf enum value: CHINA_UNION_PAY = 7;
     */
    CHINA_UNION_PAY = 7
}
/**
 * @generated from protobuf message Kiosk.OrderDetails
 */
export interface OrderDetails {
    /**
     * @generated from protobuf field: string orderId = 1;
     */
    orderId: string;
    /**
     * @generated from protobuf field: int32 subtotal = 2;
     */
    subtotal: number;
    /**
     * @generated from protobuf field: int32 tax = 3;
     */
    tax: number;
    /**
     * @generated from protobuf field: int32 total = 4;
     */
    total: number;
    /**
     * @generated from protobuf field: int32 tip = 5;
     */
    tip: number;
    /**
     * @generated from protobuf field: int32 discount = 6;
     */
    discount: number;
    /**
     * @generated from protobuf field: int32 rewards = 7;
     */
    rewards: number;
}
/**
 * @generated from protobuf message Kiosk.OrderIdentity
 */
export interface OrderIdentity {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * @generated from protobuf field: string phone = 3;
     */
    phone: string;
    /**
     * @generated from protobuf field: string orderToken = 4;
     */
    orderToken: string;
    /**
     * @generated from protobuf field: string tableTent = 5;
     */
    tableTent: string;
    /**
     * @generated from protobuf field: string orderIdPrefix = 6;
     */
    orderIdPrefix: string;
}
/**
 * @generated from protobuf message Kiosk.SetupChannelEnum
 */
export interface SetupChannelEnum {
}
/**
 * @generated from protobuf enum Kiosk.SetupChannelEnum.ChannelType
 */
export enum SetupChannelEnum_ChannelType {
    /**
     * @generated from protobuf enum value: kiosk = 0;
     */
    kiosk = 0,
    /**
     * @generated from protobuf enum value: OnlineOrdering = 1;
     */
    OnlineOrdering = 1
}
/**
 * @generated from protobuf message Kiosk.MenuOrder
 */
export interface MenuOrder {
    /**
     * @generated from protobuf field: string externalOrderTypeId = 1;
     */
    externalOrderTypeId: string;
    /**
     * @generated from protobuf field: repeated Kiosk.OrderItem items = 2;
     */
    items: OrderItem[];
    /**
     * @generated from protobuf field: Kiosk.OrderIdentity orderIdentity = 3;
     */
    orderIdentity?: OrderIdentity;
    /**
     * @generated from protobuf field: string sessionId = 4;
     */
    sessionId: string;
    /**
     * @generated from protobuf field: string externalOrderTypeLabel = 5;
     */
    externalOrderTypeLabel: string;
    /**
     * @generated from protobuf field: repeated Kiosk.LoyaltyReward rewards = 6;
     */
    rewards: LoyaltyReward[];
    /**
     * @generated from protobuf field: string defaultPaymentTender = 7;
     */
    defaultPaymentTender: string;
    /**
     * @generated from protobuf field: string orderDate = 8;
     */
    orderDate: string;
    /**
     * @generated from protobuf field: repeated Kiosk.OrderComboSelection combos = 9;
     */
    combos: OrderComboSelection[];
    /**
     * @generated from protobuf field: Kiosk.SetupChannelEnum.ChannelType channel = 10;
     */
    channel: SetupChannelEnum_ChannelType;
}
/**
 * @generated from protobuf message Kiosk.OrderComboSelection
 */
export interface OrderComboSelection {
    /**
     * @generated from protobuf field: string comboId = 1;
     */
    comboId: string;
    /**
     * @generated from protobuf field: int32 quantity = 2;
     */
    quantity: number;
    /**
     * @generated from protobuf field: repeated Kiosk.OrderComboComponentSelection components = 3;
     */
    components: OrderComboComponentSelection[];
    /**
     * @generated from protobuf field: string categoryId = 4;
     */
    categoryId: string;
    /**
     * @generated from protobuf field: string categoryName = 5;
     */
    categoryName: string;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value unitPrice = 6;
     */
    unitPrice?: Int32Value; // shoud equal the sum of the total prices of the components
    /**
     * @generated from protobuf field: google.protobuf.Int32Value totalPrice = 7;
     */
    totalPrice?: Int32Value; // shoud always equal unitPrice * quantity
    /**
     * @generated from protobuf field: google.protobuf.Int32Value unitPriceCents = 8;
     */
    unitPriceCents?: Int32Value; // shoud equal the sum of the total prices of the components
    /**
     * @generated from protobuf field: google.protobuf.Int32Value totalPriceCents = 9;
     */
    totalPriceCents?: Int32Value; // shoud always equal unitPrice * quantity
    /**
     * @generated from protobuf field: Kiosk.OrderItemRewardSourceInformation rewardSource = 10;
     */
    rewardSource?: OrderItemRewardSourceInformation;
    /**
     * @generated from protobuf field: string name = 11;
     */
    name: string;
    /**
     * @generated from protobuf field: string itemSessionId = 12;
     */
    itemSessionId: string;
}
/**
 * @generated from protobuf message Kiosk.OrderComboComponentSelection
 */
export interface OrderComboComponentSelection {
    /**
     * @generated from protobuf field: string comboComponentId = 1;
     */
    comboComponentId: string;
    /**
     * @generated from protobuf field: Kiosk.OrderItem itemSelection = 2;
     */
    itemSelection?: OrderItem;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
}
/**
 * @generated from protobuf message Kiosk.OrderItem
 */
export interface OrderItem {
    /**
     * @generated from protobuf field: string menuItemId = 1;
     */
    menuItemId: string;
    /**
     * @generated from protobuf field: int32 quantity = 2;
     */
    quantity: number;
    /**
     * @generated from protobuf field: repeated Kiosk.ItemModifier modifiers = 3;
     */
    modifiers: ItemModifier[];
    /**
     * @generated from protobuf field: string categoryId = 4;
     */
    categoryId: string;
    /**
     * @generated from protobuf field: string categoryName = 5;
     */
    categoryName: string;
    /**
     * @generated from protobuf field: string transformRulesMetadata = 6;
     */
    transformRulesMetadata: string;
    /**
     * @generated from protobuf field: string name = 7;
     */
    name: string;
    /**
     * @generated from protobuf field: string specialRequest = 8;
     */
    specialRequest: string;
    /**
     * @generated from protobuf field: Kiosk.OrderItemUpsellSourceInformation upsellSource = 9;
     */
    upsellSource?: OrderItemUpsellSourceInformation;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value unitPrice = 10;
     */
    unitPrice?: Int32Value; // shoud be the price of the item with modifiers Price
    /**
     * @generated from protobuf field: google.protobuf.Int32Value totalPrice = 11;
     */
    totalPrice?: Int32Value; // shoud always equal (unitPrice + modifier prices) * quantity
    /**
     * @generated from protobuf field: optional Kiosk.LocalizedStringSet localizedName = 12;
     */
    localizedName?: LocalizedStringSet; // for kiosk use only 
    /**
     * @generated from protobuf field: optional Kiosk.LocalizedStringSet localizedDescription = 13;
     */
    localizedDescription?: LocalizedStringSet; // for kiosk use only  
    /**
     * @generated from protobuf field: optional Kiosk.LocalizedStringSet localizedDisplayName = 14;
     */
    localizedDisplayName?: LocalizedStringSet; // for kiosk use only 
    /**
     * @generated from protobuf field: Kiosk.OrderItemRewardSourceInformation rewardSource = 15;
     */
    rewardSource?: OrderItemRewardSourceInformation;
    /**
     * @generated from protobuf field: string itemSessionId = 16;
     */
    itemSessionId: string;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value itemPrice = 17;
     */
    itemPrice?: Int32Value; // shoud be the price of the item
}
/**
 * @generated from protobuf message Kiosk.OrderItemRewardSourceInformation
 */
export interface OrderItemRewardSourceInformation {
    /**
     * @generated from protobuf field: google.protobuf.StringValue rewardId = 1;
     */
    rewardId?: StringValue; // the id of the reward that was used to pay for this item, or null if no reward
}
/**
 * @generated from protobuf message Kiosk.OrderItemUpsellSourceInformation
 */
export interface OrderItemUpsellSourceInformation {
    /**
     * @generated from protobuf field: string upsellId = 1;
     */
    upsellId: string;
    /**
     * @generated from protobuf field: Kiosk.UpsellLevelType upsellLevel = 2;
     */
    upsellLevel: UpsellLevelType;
    /**
     * @generated from protobuf field: string upsellPromptItemId = 3;
     */
    upsellPromptItemId: string;
}
/**
 * @generated from protobuf message Kiosk.ProductChoiceGroup
 */
export interface ProductChoiceGroup {
    /**
     * @generated from protobuf field: string modifierGroupId = 1;
     */
    modifierGroupId: string;
    /**
     * @generated from protobuf field: repeated Kiosk.ProductChoice choices = 2;
     */
    choices: ProductChoice[];
}
/**
 * @generated from protobuf message Kiosk.ProductChoice
 */
export interface ProductChoice {
    /**
     * @generated from protobuf field: string modifierId = 1;
     */
    modifierId: string;
    /**
     * @generated from protobuf field: int32 quantity = 2;
     */
    quantity: number;
    /**
     * @generated from protobuf field: repeated Kiosk.ProductChoiceGroup choiceGroups = 3;
     */
    choiceGroups: ProductChoiceGroup[];
}
/**
 * @generated from protobuf message Kiosk.ItemModifier
 */
export interface ItemModifier {
    /**
     * @deprecated
     * @generated from protobuf field: string menuItemId = 1 [deprecated = true];
     */
    menuItemId: string;
    /**
     * @generated from protobuf field: string modifierId = 5;
     */
    modifierId: string;
    /**
     * @generated from protobuf field: string optionGroupId = 2;
     */
    optionGroupId: string;
    /**
     * @generated from protobuf field: int32 quantity = 3;
     */
    quantity: number;
    /**
     * @generated from protobuf field: repeated Kiosk.ItemModifier modifiers = 4;
     */
    modifiers: ItemModifier[];
    /**
     * @generated from protobuf field: string transformRulesMetadata = 6;
     */
    transformRulesMetadata: string;
    /**
     * @generated from protobuf field: string name = 7;
     */
    name: string;
    /**
     * @deprecated
     * @generated from protobuf field: string modifierCode = 8 [deprecated = true];
     */
    modifierCode: string;
    /**
     * @generated from protobuf field: int32 freeQuantity = 9;
     */
    freeQuantity: number;
    /**
     * @generated from protobuf field: Kiosk.ItemModifierModifierCode modifierCodeSelection = 10;
     */
    modifierCodeSelection?: ItemModifierModifierCode;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value unitPrice = 11;
     */
    unitPrice?: Int32Value; // shoud be the price of the modifier without any nested modifier pricing
    /**
     * @generated from protobuf field: google.protobuf.Int32Value totalPrice = 12;
     */
    totalPrice?: Int32Value; // shoud always equal (unitPrice + nested modifier prices) * quantity
    /**
     * @generated from protobuf field: bool isDefault = 13;
     */
    isDefault: boolean;
}
/**
 * @generated from protobuf message Kiosk.ItemModifierModifierCode
 */
export interface ItemModifierModifierCode {
    /**
     * @generated from protobuf field: string modifierCodeId = 1;
     */
    modifierCodeId: string;
    /**
     * @generated from protobuf field: string modifierCodeName = 2;
     */
    modifierCodeName: string;
}
/**
 * @generated from protobuf message Kiosk.OrderDiscount
 */
export interface OrderDiscount {
    /**
     * @generated from protobuf field: string discountId = 1;
     */
    discountId: string;
    /**
     * @generated from protobuf oneof: value
     */
    value: {
        oneofKind: "flat";
        /**
         * @generated from protobuf field: int32 flat = 2;
         */
        flat: number;
    } | {
        oneofKind: "points";
        /**
         * @generated from protobuf field: int32 points = 3;
         */
        points: number;
    } | {
        oneofKind: "percent";
        /**
         * @generated from protobuf field: int32 percent = 4;
         */
        percent: number;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: double discountAmount = 5;
     */
    discountAmount: number;
}
/**
 * @generated from protobuf message Kiosk.ReceiptLink
 */
export interface ReceiptLink {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * @generated from protobuf field: string shortURL = 2;
     */
    shortURL: string;
    /**
     * @generated from protobuf field: string longURL = 3;
     */
    longURL: string;
}
/**
 * @generated from protobuf message Kiosk.GetPreviousOrderRequest
 */
export interface GetPreviousOrderRequest {
    /**
     * @generated from protobuf field: string orderId = 2;
     */
    orderId: string;
}
/**
 * @generated from protobuf message Kiosk.GetPreviousOrderReply
 */
export interface GetPreviousOrderReply {
    /**
     * @generated from protobuf field: Kiosk.MenuOrder order = 1;
     */
    order?: MenuOrder;
    /**
     * @generated from protobuf field: Kiosk.OrderDetails details = 2;
     */
    details?: OrderDetails;
}
/**
 * @generated from protobuf enum Kiosk.UpsellLevelType
 */
export enum UpsellLevelType {
    /**
     * @generated from protobuf enum value: Item = 0;
     */
    Item = 0,
    /**
     * @generated from protobuf enum value: Order = 1;
     */
    Order = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class ValidateOrderRequest$Type extends MessageType<ValidateOrderRequest> {
    constructor() {
        super("Kiosk.ValidateOrderRequest", [
            { no: 1, name: "order", kind: "message", T: () => MenuOrder },
            { no: 2, name: "discounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderDiscount },
            { no: 3, name: "orderId", kind: "message", T: () => StringValue },
            { no: 4, name: "loyaltyUser", kind: "message", T: () => LoyaltyUser }
        ]);
    }
    create(value?: PartialMessage<ValidateOrderRequest>): ValidateOrderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.discounts = [];
        if (value !== undefined)
            reflectionMergePartial<ValidateOrderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidateOrderRequest): ValidateOrderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.MenuOrder order */ 1:
                    message.order = MenuOrder.internalBinaryRead(reader, reader.uint32(), options, message.order);
                    break;
                case /* repeated Kiosk.OrderDiscount discounts */ 2:
                    message.discounts.push(OrderDiscount.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.StringValue orderId */ 3:
                    message.orderId = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.orderId);
                    break;
                case /* Kiosk.LoyaltyUser loyaltyUser */ 4:
                    message.loyaltyUser = LoyaltyUser.internalBinaryRead(reader, reader.uint32(), options, message.loyaltyUser);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValidateOrderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.MenuOrder order = 1; */
        if (message.order)
            MenuOrder.internalBinaryWrite(message.order, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.OrderDiscount discounts = 2; */
        for (let i = 0; i < message.discounts.length; i++)
            OrderDiscount.internalBinaryWrite(message.discounts[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue orderId = 3; */
        if (message.orderId)
            StringValue.internalBinaryWrite(message.orderId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LoyaltyUser loyaltyUser = 4; */
        if (message.loyaltyUser)
            LoyaltyUser.internalBinaryWrite(message.loyaltyUser, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ValidateOrderRequest
 */
export const ValidateOrderRequest = new ValidateOrderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidateOrderResponse$Type extends MessageType<ValidateOrderResponse> {
    constructor() {
        super("Kiosk.ValidateOrderResponse", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "orderDetails", kind: "message", T: () => OrderDetails }
        ]);
    }
    create(value?: PartialMessage<ValidateOrderResponse>): ValidateOrderResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ValidateOrderResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidateOrderResponse): ValidateOrderResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* Kiosk.OrderDetails orderDetails */ 2:
                    message.orderDetails = OrderDetails.internalBinaryRead(reader, reader.uint32(), options, message.orderDetails);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValidateOrderResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.OrderDetails orderDetails = 2; */
        if (message.orderDetails)
            OrderDetails.internalBinaryWrite(message.orderDetails, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ValidateOrderResponse
 */
export const ValidateOrderResponse = new ValidateOrderResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOrderRequest$Type extends MessageType<UpdateOrderRequest> {
    constructor() {
        super("Kiosk.UpdateOrderRequest", [
            { no: 1, name: "orderId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "order", kind: "message", T: () => MenuOrder }
        ]);
    }
    create(value?: PartialMessage<UpdateOrderRequest>): UpdateOrderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.orderId = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateOrderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateOrderRequest): UpdateOrderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string orderId */ 1:
                    message.orderId = reader.string();
                    break;
                case /* Kiosk.MenuOrder order */ 2:
                    message.order = MenuOrder.internalBinaryRead(reader, reader.uint32(), options, message.order);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateOrderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string orderId = 1; */
        if (message.orderId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.orderId);
        /* Kiosk.MenuOrder order = 2; */
        if (message.order)
            MenuOrder.internalBinaryWrite(message.order, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.UpdateOrderRequest
 */
export const UpdateOrderRequest = new UpdateOrderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CloseOrderRequest$Type extends MessageType<CloseOrderRequest> {
    constructor() {
        super("Kiosk.CloseOrderRequest", [
            { no: 1, name: "order", kind: "message", T: () => MenuOrder },
            { no: 2, name: "details", kind: "message", T: () => OrderDetails },
            { no: 3, name: "payments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderPayment },
            { no: 4, name: "discounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderDiscount },
            { no: 5, name: "tipTender", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "discountTender", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "userId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "failedToSend", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "loyaltyUser", kind: "message", T: () => LoyaltyUser }
        ]);
    }
    create(value?: PartialMessage<CloseOrderRequest>): CloseOrderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.payments = [];
        message.discounts = [];
        message.tipTender = "";
        message.discountTender = "";
        message.userId = "";
        message.failedToSend = false;
        if (value !== undefined)
            reflectionMergePartial<CloseOrderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CloseOrderRequest): CloseOrderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.MenuOrder order */ 1:
                    message.order = MenuOrder.internalBinaryRead(reader, reader.uint32(), options, message.order);
                    break;
                case /* Kiosk.OrderDetails details */ 2:
                    message.details = OrderDetails.internalBinaryRead(reader, reader.uint32(), options, message.details);
                    break;
                case /* repeated Kiosk.OrderPayment payments */ 3:
                    message.payments.push(OrderPayment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Kiosk.OrderDiscount discounts */ 4:
                    message.discounts.push(OrderDiscount.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string tipTender */ 5:
                    message.tipTender = reader.string();
                    break;
                case /* string discountTender */ 6:
                    message.discountTender = reader.string();
                    break;
                case /* string userId */ 7:
                    message.userId = reader.string();
                    break;
                case /* bool failedToSend */ 8:
                    message.failedToSend = reader.bool();
                    break;
                case /* Kiosk.LoyaltyUser loyaltyUser */ 9:
                    message.loyaltyUser = LoyaltyUser.internalBinaryRead(reader, reader.uint32(), options, message.loyaltyUser);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CloseOrderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.MenuOrder order = 1; */
        if (message.order)
            MenuOrder.internalBinaryWrite(message.order, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.OrderDetails details = 2; */
        if (message.details)
            OrderDetails.internalBinaryWrite(message.details, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.OrderPayment payments = 3; */
        for (let i = 0; i < message.payments.length; i++)
            OrderPayment.internalBinaryWrite(message.payments[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.OrderDiscount discounts = 4; */
        for (let i = 0; i < message.discounts.length; i++)
            OrderDiscount.internalBinaryWrite(message.discounts[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string tipTender = 5; */
        if (message.tipTender !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.tipTender);
        /* string discountTender = 6; */
        if (message.discountTender !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.discountTender);
        /* string userId = 7; */
        if (message.userId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.userId);
        /* bool failedToSend = 8; */
        if (message.failedToSend !== false)
            writer.tag(8, WireType.Varint).bool(message.failedToSend);
        /* Kiosk.LoyaltyUser loyaltyUser = 9; */
        if (message.loyaltyUser)
            LoyaltyUser.internalBinaryWrite(message.loyaltyUser, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.CloseOrderRequest
 */
export const CloseOrderRequest = new CloseOrderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogOrderRequest$Type extends MessageType<LogOrderRequest> {
    constructor() {
        super("Kiosk.LogOrderRequest", [
            { no: 1, name: "orderId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "request", kind: "message", T: () => CloseOrderRequest },
            { no: 3, name: "receiptImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LogOrderRequest>): LogOrderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.orderId = "";
        message.receiptImage = "";
        if (value !== undefined)
            reflectionMergePartial<LogOrderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogOrderRequest): LogOrderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string orderId */ 1:
                    message.orderId = reader.string();
                    break;
                case /* Kiosk.CloseOrderRequest request */ 2:
                    message.request = CloseOrderRequest.internalBinaryRead(reader, reader.uint32(), options, message.request);
                    break;
                case /* string receiptImage */ 3:
                    message.receiptImage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogOrderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string orderId = 1; */
        if (message.orderId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.orderId);
        /* Kiosk.CloseOrderRequest request = 2; */
        if (message.request)
            CloseOrderRequest.internalBinaryWrite(message.request, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string receiptImage = 3; */
        if (message.receiptImage !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.receiptImage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LogOrderRequest
 */
export const LogOrderRequest = new LogOrderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogOrderReply$Type extends MessageType<LogOrderReply> {
    constructor() {
        super("Kiosk.LogOrderReply", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<LogOrderReply>): LogOrderReply {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<LogOrderReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogOrderReply): LogOrderReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogOrderReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LogOrderReply
 */
export const LogOrderReply = new LogOrderReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CloseOrderReply$Type extends MessageType<CloseOrderReply> {
    constructor() {
        super("Kiosk.CloseOrderReply", [
            { no: 1, name: "isSuccess", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "orderId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<CloseOrderReply>): CloseOrderReply {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isSuccess = false;
        message.orderId = "";
        if (value !== undefined)
            reflectionMergePartial<CloseOrderReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CloseOrderReply): CloseOrderReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isSuccess */ 1:
                    message.isSuccess = reader.bool();
                    break;
                case /* string orderId */ 2:
                    message.orderId = reader.string();
                    break;
                case /* Kiosk.ResponseStatus status */ 3:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CloseOrderReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isSuccess = 1; */
        if (message.isSuccess !== false)
            writer.tag(1, WireType.Varint).bool(message.isSuccess);
        /* string orderId = 2; */
        if (message.orderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.orderId);
        /* Kiosk.ResponseStatus status = 3; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.CloseOrderReply
 */
export const CloseOrderReply = new CloseOrderReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteOrderRequest$Type extends MessageType<DeleteOrderRequest> {
    constructor() {
        super("Kiosk.DeleteOrderRequest", [
            { no: 1, name: "sessionid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "orderId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteOrderRequest>): DeleteOrderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sessionid = "";
        message.orderId = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteOrderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteOrderRequest): DeleteOrderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string sessionid */ 1:
                    message.sessionid = reader.string();
                    break;
                case /* string orderId */ 2:
                    message.orderId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteOrderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string sessionid = 1; */
        if (message.sessionid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionid);
        /* string orderId = 2; */
        if (message.orderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.orderId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DeleteOrderRequest
 */
export const DeleteOrderRequest = new DeleteOrderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteOrderReply$Type extends MessageType<DeleteOrderReply> {
    constructor() {
        super("Kiosk.DeleteOrderReply", [
            { no: 1, name: "isSuccess", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<DeleteOrderReply>): DeleteOrderReply {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isSuccess = false;
        if (value !== undefined)
            reflectionMergePartial<DeleteOrderReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteOrderReply): DeleteOrderReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isSuccess */ 1:
                    message.isSuccess = reader.bool();
                    break;
                case /* Kiosk.ResponseStatus status */ 2:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteOrderReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isSuccess = 1; */
        if (message.isSuccess !== false)
            writer.tag(1, WireType.Varint).bool(message.isSuccess);
        /* Kiosk.ResponseStatus status = 2; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DeleteOrderReply
 */
export const DeleteOrderReply = new DeleteOrderReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderPayment$Type extends MessageType<OrderPayment> {
    constructor() {
        super("Kiosk.OrderPayment", [
            { no: 1, name: "paymentIntegrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "totalPaid", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "transactionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "paymentIntegrationLabel", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tenderId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "paymentCardName", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "isAmazonOnePayment", kind: "scalar", opt: true, T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "tipAmount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "tenderInfo", kind: "message", T: () => TenderInfo }
        ]);
    }
    create(value?: PartialMessage<OrderPayment>): OrderPayment {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.paymentIntegrationId = "";
        message.totalPaid = 0;
        message.transactionId = "";
        message.paymentIntegrationLabel = "";
        message.tenderId = "";
        message.tipAmount = 0;
        if (value !== undefined)
            reflectionMergePartial<OrderPayment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderPayment): OrderPayment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string paymentIntegrationId */ 1:
                    message.paymentIntegrationId = reader.string();
                    break;
                case /* int32 totalPaid */ 2:
                    message.totalPaid = reader.int32();
                    break;
                case /* string transactionId */ 3:
                    message.transactionId = reader.string();
                    break;
                case /* string paymentIntegrationLabel */ 4:
                    message.paymentIntegrationLabel = reader.string();
                    break;
                case /* string tenderId */ 5:
                    message.tenderId = reader.string();
                    break;
                case /* optional string paymentCardName */ 6:
                    message.paymentCardName = reader.string();
                    break;
                case /* optional bool isAmazonOnePayment */ 7:
                    message.isAmazonOnePayment = reader.bool();
                    break;
                case /* int32 tipAmount */ 8:
                    message.tipAmount = reader.int32();
                    break;
                case /* Kiosk.TenderInfo tenderInfo */ 9:
                    message.tenderInfo = TenderInfo.internalBinaryRead(reader, reader.uint32(), options, message.tenderInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderPayment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string paymentIntegrationId = 1; */
        if (message.paymentIntegrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.paymentIntegrationId);
        /* int32 totalPaid = 2; */
        if (message.totalPaid !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalPaid);
        /* string transactionId = 3; */
        if (message.transactionId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.transactionId);
        /* string paymentIntegrationLabel = 4; */
        if (message.paymentIntegrationLabel !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.paymentIntegrationLabel);
        /* string tenderId = 5; */
        if (message.tenderId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.tenderId);
        /* optional string paymentCardName = 6; */
        if (message.paymentCardName !== undefined)
            writer.tag(6, WireType.LengthDelimited).string(message.paymentCardName);
        /* optional bool isAmazonOnePayment = 7; */
        if (message.isAmazonOnePayment !== undefined)
            writer.tag(7, WireType.Varint).bool(message.isAmazonOnePayment);
        /* int32 tipAmount = 8; */
        if (message.tipAmount !== 0)
            writer.tag(8, WireType.Varint).int32(message.tipAmount);
        /* Kiosk.TenderInfo tenderInfo = 9; */
        if (message.tenderInfo)
            TenderInfo.internalBinaryWrite(message.tenderInfo, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderPayment
 */
export const OrderPayment = new OrderPayment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TenderInfo$Type extends MessageType<TenderInfo> {
    constructor() {
        super("Kiosk.TenderInfo", [
            { no: 2, name: "cardInfo", kind: "message", oneof: "kind", T: () => CardInfo },
            { no: 3, name: "storedValueInfo", kind: "message", oneof: "kind", T: () => StoredValueInfo }
        ]);
    }
    create(value?: PartialMessage<TenderInfo>): TenderInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.kind = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<TenderInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TenderInfo): TenderInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.CardInfo cardInfo */ 2:
                    message.kind = {
                        oneofKind: "cardInfo",
                        cardInfo: CardInfo.internalBinaryRead(reader, reader.uint32(), options, (message.kind as any).cardInfo)
                    };
                    break;
                case /* Kiosk.StoredValueInfo storedValueInfo */ 3:
                    message.kind = {
                        oneofKind: "storedValueInfo",
                        storedValueInfo: StoredValueInfo.internalBinaryRead(reader, reader.uint32(), options, (message.kind as any).storedValueInfo)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TenderInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.CardInfo cardInfo = 2; */
        if (message.kind.oneofKind === "cardInfo")
            CardInfo.internalBinaryWrite(message.kind.cardInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.StoredValueInfo storedValueInfo = 3; */
        if (message.kind.oneofKind === "storedValueInfo")
            StoredValueInfo.internalBinaryWrite(message.kind.storedValueInfo, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.TenderInfo
 */
export const TenderInfo = new TenderInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StoredValueInfo$Type extends MessageType<StoredValueInfo> {
    constructor() {
        super("Kiosk.StoredValueInfo", [
            { no: 1, name: "accountId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "integrationMetadata", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StoredValueInfo>): StoredValueInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountId = "";
        message.integrationMetadata = "";
        if (value !== undefined)
            reflectionMergePartial<StoredValueInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StoredValueInfo): StoredValueInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountId */ 1:
                    message.accountId = reader.string();
                    break;
                case /* string integrationMetadata */ 2:
                    message.integrationMetadata = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StoredValueInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountId = 1; */
        if (message.accountId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountId);
        /* string integrationMetadata = 2; */
        if (message.integrationMetadata !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.integrationMetadata);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.StoredValueInfo
 */
export const StoredValueInfo = new StoredValueInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardInfo$Type extends MessageType<CardInfo> {
    constructor() {
        super("Kiosk.CardInfo", [
            { no: 1, name: "cardType", kind: "enum", T: () => ["Kiosk.CardInfo.CardNetworkType", CardInfo_CardNetworkType] },
            { no: 2, name: "lastFour", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "zipCode", kind: "message", T: () => StringValue },
            { no: 4, name: "processorAuthCode", kind: "message", T: () => StringValue },
            { no: 5, name: "expirationMonth", kind: "message", T: () => StringValue },
            { no: 6, name: "expirationYear", kind: "message", T: () => StringValue },
            { no: 7, name: "maskedCardNumber", kind: "message", T: () => StringValue },
            { no: 8, name: "availableBalance", kind: "message", T: () => Int32Value }
        ]);
    }
    create(value?: PartialMessage<CardInfo>): CardInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.cardType = 0;
        message.lastFour = "";
        if (value !== undefined)
            reflectionMergePartial<CardInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CardInfo): CardInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.CardInfo.CardNetworkType cardType */ 1:
                    message.cardType = reader.int32();
                    break;
                case /* string lastFour */ 2:
                    message.lastFour = reader.string();
                    break;
                case /* google.protobuf.StringValue zipCode */ 3:
                    message.zipCode = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.zipCode);
                    break;
                case /* google.protobuf.StringValue processorAuthCode */ 4:
                    message.processorAuthCode = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.processorAuthCode);
                    break;
                case /* google.protobuf.StringValue expirationMonth */ 5:
                    message.expirationMonth = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.expirationMonth);
                    break;
                case /* google.protobuf.StringValue expirationYear */ 6:
                    message.expirationYear = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.expirationYear);
                    break;
                case /* google.protobuf.StringValue maskedCardNumber */ 7:
                    message.maskedCardNumber = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.maskedCardNumber);
                    break;
                case /* google.protobuf.Int32Value availableBalance */ 8:
                    message.availableBalance = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.availableBalance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CardInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.CardInfo.CardNetworkType cardType = 1; */
        if (message.cardType !== 0)
            writer.tag(1, WireType.Varint).int32(message.cardType);
        /* string lastFour = 2; */
        if (message.lastFour !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.lastFour);
        /* google.protobuf.StringValue zipCode = 3; */
        if (message.zipCode)
            StringValue.internalBinaryWrite(message.zipCode, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue processorAuthCode = 4; */
        if (message.processorAuthCode)
            StringValue.internalBinaryWrite(message.processorAuthCode, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue expirationMonth = 5; */
        if (message.expirationMonth)
            StringValue.internalBinaryWrite(message.expirationMonth, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue expirationYear = 6; */
        if (message.expirationYear)
            StringValue.internalBinaryWrite(message.expirationYear, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue maskedCardNumber = 7; */
        if (message.maskedCardNumber)
            StringValue.internalBinaryWrite(message.maskedCardNumber, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value availableBalance = 8; */
        if (message.availableBalance)
            Int32Value.internalBinaryWrite(message.availableBalance, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.CardInfo
 */
export const CardInfo = new CardInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderDetails$Type extends MessageType<OrderDetails> {
    constructor() {
        super("Kiosk.OrderDetails", [
            { no: 1, name: "orderId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subtotal", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "tax", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "tip", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "discount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "rewards", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<OrderDetails>): OrderDetails {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.orderId = "";
        message.subtotal = 0;
        message.tax = 0;
        message.total = 0;
        message.tip = 0;
        message.discount = 0;
        message.rewards = 0;
        if (value !== undefined)
            reflectionMergePartial<OrderDetails>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderDetails): OrderDetails {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string orderId */ 1:
                    message.orderId = reader.string();
                    break;
                case /* int32 subtotal */ 2:
                    message.subtotal = reader.int32();
                    break;
                case /* int32 tax */ 3:
                    message.tax = reader.int32();
                    break;
                case /* int32 total */ 4:
                    message.total = reader.int32();
                    break;
                case /* int32 tip */ 5:
                    message.tip = reader.int32();
                    break;
                case /* int32 discount */ 6:
                    message.discount = reader.int32();
                    break;
                case /* int32 rewards */ 7:
                    message.rewards = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderDetails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string orderId = 1; */
        if (message.orderId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.orderId);
        /* int32 subtotal = 2; */
        if (message.subtotal !== 0)
            writer.tag(2, WireType.Varint).int32(message.subtotal);
        /* int32 tax = 3; */
        if (message.tax !== 0)
            writer.tag(3, WireType.Varint).int32(message.tax);
        /* int32 total = 4; */
        if (message.total !== 0)
            writer.tag(4, WireType.Varint).int32(message.total);
        /* int32 tip = 5; */
        if (message.tip !== 0)
            writer.tag(5, WireType.Varint).int32(message.tip);
        /* int32 discount = 6; */
        if (message.discount !== 0)
            writer.tag(6, WireType.Varint).int32(message.discount);
        /* int32 rewards = 7; */
        if (message.rewards !== 0)
            writer.tag(7, WireType.Varint).int32(message.rewards);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderDetails
 */
export const OrderDetails = new OrderDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderIdentity$Type extends MessageType<OrderIdentity> {
    constructor() {
        super("Kiosk.OrderIdentity", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "orderToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tableTent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "orderIdPrefix", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OrderIdentity>): OrderIdentity {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.email = "";
        message.phone = "";
        message.orderToken = "";
        message.tableTent = "";
        message.orderIdPrefix = "";
        if (value !== undefined)
            reflectionMergePartial<OrderIdentity>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderIdentity): OrderIdentity {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string phone */ 3:
                    message.phone = reader.string();
                    break;
                case /* string orderToken */ 4:
                    message.orderToken = reader.string();
                    break;
                case /* string tableTent */ 5:
                    message.tableTent = reader.string();
                    break;
                case /* string orderIdPrefix */ 6:
                    message.orderIdPrefix = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderIdentity, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string phone = 3; */
        if (message.phone !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.phone);
        /* string orderToken = 4; */
        if (message.orderToken !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.orderToken);
        /* string tableTent = 5; */
        if (message.tableTent !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.tableTent);
        /* string orderIdPrefix = 6; */
        if (message.orderIdPrefix !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.orderIdPrefix);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderIdentity
 */
export const OrderIdentity = new OrderIdentity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetupChannelEnum$Type extends MessageType<SetupChannelEnum> {
    constructor() {
        super("Kiosk.SetupChannelEnum", []);
    }
    create(value?: PartialMessage<SetupChannelEnum>): SetupChannelEnum {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SetupChannelEnum>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetupChannelEnum): SetupChannelEnum {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetupChannelEnum, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.SetupChannelEnum
 */
export const SetupChannelEnum = new SetupChannelEnum$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuOrder$Type extends MessageType<MenuOrder> {
    constructor() {
        super("Kiosk.MenuOrder", [
            { no: 1, name: "externalOrderTypeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderItem },
            { no: 3, name: "orderIdentity", kind: "message", T: () => OrderIdentity },
            { no: 4, name: "sessionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "externalOrderTypeLabel", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "rewards", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LoyaltyReward },
            { no: 7, name: "defaultPaymentTender", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "orderDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "combos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderComboSelection },
            { no: 10, name: "channel", kind: "enum", T: () => ["Kiosk.SetupChannelEnum.ChannelType", SetupChannelEnum_ChannelType] }
        ]);
    }
    create(value?: PartialMessage<MenuOrder>): MenuOrder {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.externalOrderTypeId = "";
        message.items = [];
        message.sessionId = "";
        message.externalOrderTypeLabel = "";
        message.rewards = [];
        message.defaultPaymentTender = "";
        message.orderDate = "";
        message.combos = [];
        message.channel = 0;
        if (value !== undefined)
            reflectionMergePartial<MenuOrder>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuOrder): MenuOrder {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string externalOrderTypeId */ 1:
                    message.externalOrderTypeId = reader.string();
                    break;
                case /* repeated Kiosk.OrderItem items */ 2:
                    message.items.push(OrderItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.OrderIdentity orderIdentity */ 3:
                    message.orderIdentity = OrderIdentity.internalBinaryRead(reader, reader.uint32(), options, message.orderIdentity);
                    break;
                case /* string sessionId */ 4:
                    message.sessionId = reader.string();
                    break;
                case /* string externalOrderTypeLabel */ 5:
                    message.externalOrderTypeLabel = reader.string();
                    break;
                case /* repeated Kiosk.LoyaltyReward rewards */ 6:
                    message.rewards.push(LoyaltyReward.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string defaultPaymentTender */ 7:
                    message.defaultPaymentTender = reader.string();
                    break;
                case /* string orderDate */ 8:
                    message.orderDate = reader.string();
                    break;
                case /* repeated Kiosk.OrderComboSelection combos */ 9:
                    message.combos.push(OrderComboSelection.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.SetupChannelEnum.ChannelType channel */ 10:
                    message.channel = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuOrder, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string externalOrderTypeId = 1; */
        if (message.externalOrderTypeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.externalOrderTypeId);
        /* repeated Kiosk.OrderItem items = 2; */
        for (let i = 0; i < message.items.length; i++)
            OrderItem.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.OrderIdentity orderIdentity = 3; */
        if (message.orderIdentity)
            OrderIdentity.internalBinaryWrite(message.orderIdentity, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string sessionId = 4; */
        if (message.sessionId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.sessionId);
        /* string externalOrderTypeLabel = 5; */
        if (message.externalOrderTypeLabel !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.externalOrderTypeLabel);
        /* repeated Kiosk.LoyaltyReward rewards = 6; */
        for (let i = 0; i < message.rewards.length; i++)
            LoyaltyReward.internalBinaryWrite(message.rewards[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string defaultPaymentTender = 7; */
        if (message.defaultPaymentTender !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.defaultPaymentTender);
        /* string orderDate = 8; */
        if (message.orderDate !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.orderDate);
        /* repeated Kiosk.OrderComboSelection combos = 9; */
        for (let i = 0; i < message.combos.length; i++)
            OrderComboSelection.internalBinaryWrite(message.combos[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.SetupChannelEnum.ChannelType channel = 10; */
        if (message.channel !== 0)
            writer.tag(10, WireType.Varint).int32(message.channel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuOrder
 */
export const MenuOrder = new MenuOrder$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderComboSelection$Type extends MessageType<OrderComboSelection> {
    constructor() {
        super("Kiosk.OrderComboSelection", [
            { no: 1, name: "comboId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "components", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderComboComponentSelection },
            { no: 4, name: "categoryId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "categoryName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "unitPrice", kind: "message", T: () => Int32Value },
            { no: 7, name: "totalPrice", kind: "message", T: () => Int32Value },
            { no: 8, name: "unitPriceCents", kind: "message", T: () => Int32Value },
            { no: 9, name: "totalPriceCents", kind: "message", T: () => Int32Value },
            { no: 10, name: "rewardSource", kind: "message", T: () => OrderItemRewardSourceInformation },
            { no: 11, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "itemSessionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OrderComboSelection>): OrderComboSelection {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.comboId = "";
        message.quantity = 0;
        message.components = [];
        message.categoryId = "";
        message.categoryName = "";
        message.name = "";
        message.itemSessionId = "";
        if (value !== undefined)
            reflectionMergePartial<OrderComboSelection>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderComboSelection): OrderComboSelection {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string comboId */ 1:
                    message.comboId = reader.string();
                    break;
                case /* int32 quantity */ 2:
                    message.quantity = reader.int32();
                    break;
                case /* repeated Kiosk.OrderComboComponentSelection components */ 3:
                    message.components.push(OrderComboComponentSelection.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string categoryId */ 4:
                    message.categoryId = reader.string();
                    break;
                case /* string categoryName */ 5:
                    message.categoryName = reader.string();
                    break;
                case /* google.protobuf.Int32Value unitPrice */ 6:
                    message.unitPrice = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.unitPrice);
                    break;
                case /* google.protobuf.Int32Value totalPrice */ 7:
                    message.totalPrice = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.totalPrice);
                    break;
                case /* google.protobuf.Int32Value unitPriceCents */ 8:
                    message.unitPriceCents = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.unitPriceCents);
                    break;
                case /* google.protobuf.Int32Value totalPriceCents */ 9:
                    message.totalPriceCents = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.totalPriceCents);
                    break;
                case /* Kiosk.OrderItemRewardSourceInformation rewardSource */ 10:
                    message.rewardSource = OrderItemRewardSourceInformation.internalBinaryRead(reader, reader.uint32(), options, message.rewardSource);
                    break;
                case /* string name */ 11:
                    message.name = reader.string();
                    break;
                case /* string itemSessionId */ 12:
                    message.itemSessionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderComboSelection, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string comboId = 1; */
        if (message.comboId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.comboId);
        /* int32 quantity = 2; */
        if (message.quantity !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantity);
        /* repeated Kiosk.OrderComboComponentSelection components = 3; */
        for (let i = 0; i < message.components.length; i++)
            OrderComboComponentSelection.internalBinaryWrite(message.components[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string categoryId = 4; */
        if (message.categoryId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.categoryId);
        /* string categoryName = 5; */
        if (message.categoryName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.categoryName);
        /* google.protobuf.Int32Value unitPrice = 6; */
        if (message.unitPrice)
            Int32Value.internalBinaryWrite(message.unitPrice, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value totalPrice = 7; */
        if (message.totalPrice)
            Int32Value.internalBinaryWrite(message.totalPrice, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value unitPriceCents = 8; */
        if (message.unitPriceCents)
            Int32Value.internalBinaryWrite(message.unitPriceCents, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value totalPriceCents = 9; */
        if (message.totalPriceCents)
            Int32Value.internalBinaryWrite(message.totalPriceCents, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.OrderItemRewardSourceInformation rewardSource = 10; */
        if (message.rewardSource)
            OrderItemRewardSourceInformation.internalBinaryWrite(message.rewardSource, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* string name = 11; */
        if (message.name !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.name);
        /* string itemSessionId = 12; */
        if (message.itemSessionId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.itemSessionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderComboSelection
 */
export const OrderComboSelection = new OrderComboSelection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderComboComponentSelection$Type extends MessageType<OrderComboComponentSelection> {
    constructor() {
        super("Kiosk.OrderComboComponentSelection", [
            { no: 1, name: "comboComponentId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "itemSelection", kind: "message", T: () => OrderItem },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OrderComboComponentSelection>): OrderComboComponentSelection {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.comboComponentId = "";
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<OrderComboComponentSelection>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderComboComponentSelection): OrderComboComponentSelection {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string comboComponentId */ 1:
                    message.comboComponentId = reader.string();
                    break;
                case /* Kiosk.OrderItem itemSelection */ 2:
                    message.itemSelection = OrderItem.internalBinaryRead(reader, reader.uint32(), options, message.itemSelection);
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderComboComponentSelection, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string comboComponentId = 1; */
        if (message.comboComponentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.comboComponentId);
        /* Kiosk.OrderItem itemSelection = 2; */
        if (message.itemSelection)
            OrderItem.internalBinaryWrite(message.itemSelection, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderComboComponentSelection
 */
export const OrderComboComponentSelection = new OrderComboComponentSelection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderItem$Type extends MessageType<OrderItem> {
    constructor() {
        super("Kiosk.OrderItem", [
            { no: 1, name: "menuItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "modifiers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemModifier },
            { no: 4, name: "categoryId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "categoryName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "transformRulesMetadata", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "specialRequest", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "upsellSource", kind: "message", T: () => OrderItemUpsellSourceInformation },
            { no: 10, name: "unitPrice", kind: "message", T: () => Int32Value },
            { no: 11, name: "totalPrice", kind: "message", T: () => Int32Value },
            { no: 12, name: "localizedName", kind: "message", T: () => LocalizedStringSet },
            { no: 13, name: "localizedDescription", kind: "message", T: () => LocalizedStringSet },
            { no: 14, name: "localizedDisplayName", kind: "message", T: () => LocalizedStringSet },
            { no: 15, name: "rewardSource", kind: "message", T: () => OrderItemRewardSourceInformation },
            { no: 16, name: "itemSessionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "itemPrice", kind: "message", T: () => Int32Value }
        ]);
    }
    create(value?: PartialMessage<OrderItem>): OrderItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.menuItemId = "";
        message.quantity = 0;
        message.modifiers = [];
        message.categoryId = "";
        message.categoryName = "";
        message.transformRulesMetadata = "";
        message.name = "";
        message.specialRequest = "";
        message.itemSessionId = "";
        if (value !== undefined)
            reflectionMergePartial<OrderItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderItem): OrderItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string menuItemId */ 1:
                    message.menuItemId = reader.string();
                    break;
                case /* int32 quantity */ 2:
                    message.quantity = reader.int32();
                    break;
                case /* repeated Kiosk.ItemModifier modifiers */ 3:
                    message.modifiers.push(ItemModifier.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string categoryId */ 4:
                    message.categoryId = reader.string();
                    break;
                case /* string categoryName */ 5:
                    message.categoryName = reader.string();
                    break;
                case /* string transformRulesMetadata */ 6:
                    message.transformRulesMetadata = reader.string();
                    break;
                case /* string name */ 7:
                    message.name = reader.string();
                    break;
                case /* string specialRequest */ 8:
                    message.specialRequest = reader.string();
                    break;
                case /* Kiosk.OrderItemUpsellSourceInformation upsellSource */ 9:
                    message.upsellSource = OrderItemUpsellSourceInformation.internalBinaryRead(reader, reader.uint32(), options, message.upsellSource);
                    break;
                case /* google.protobuf.Int32Value unitPrice */ 10:
                    message.unitPrice = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.unitPrice);
                    break;
                case /* google.protobuf.Int32Value totalPrice */ 11:
                    message.totalPrice = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.totalPrice);
                    break;
                case /* optional Kiosk.LocalizedStringSet localizedName */ 12:
                    message.localizedName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedName);
                    break;
                case /* optional Kiosk.LocalizedStringSet localizedDescription */ 13:
                    message.localizedDescription = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedDescription);
                    break;
                case /* optional Kiosk.LocalizedStringSet localizedDisplayName */ 14:
                    message.localizedDisplayName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedDisplayName);
                    break;
                case /* Kiosk.OrderItemRewardSourceInformation rewardSource */ 15:
                    message.rewardSource = OrderItemRewardSourceInformation.internalBinaryRead(reader, reader.uint32(), options, message.rewardSource);
                    break;
                case /* string itemSessionId */ 16:
                    message.itemSessionId = reader.string();
                    break;
                case /* google.protobuf.Int32Value itemPrice */ 17:
                    message.itemPrice = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.itemPrice);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string menuItemId = 1; */
        if (message.menuItemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.menuItemId);
        /* int32 quantity = 2; */
        if (message.quantity !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantity);
        /* repeated Kiosk.ItemModifier modifiers = 3; */
        for (let i = 0; i < message.modifiers.length; i++)
            ItemModifier.internalBinaryWrite(message.modifiers[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string categoryId = 4; */
        if (message.categoryId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.categoryId);
        /* string categoryName = 5; */
        if (message.categoryName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.categoryName);
        /* string transformRulesMetadata = 6; */
        if (message.transformRulesMetadata !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.transformRulesMetadata);
        /* string name = 7; */
        if (message.name !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.name);
        /* string specialRequest = 8; */
        if (message.specialRequest !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.specialRequest);
        /* Kiosk.OrderItemUpsellSourceInformation upsellSource = 9; */
        if (message.upsellSource)
            OrderItemUpsellSourceInformation.internalBinaryWrite(message.upsellSource, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value unitPrice = 10; */
        if (message.unitPrice)
            Int32Value.internalBinaryWrite(message.unitPrice, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value totalPrice = 11; */
        if (message.totalPrice)
            Int32Value.internalBinaryWrite(message.totalPrice, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* optional Kiosk.LocalizedStringSet localizedName = 12; */
        if (message.localizedName)
            LocalizedStringSet.internalBinaryWrite(message.localizedName, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* optional Kiosk.LocalizedStringSet localizedDescription = 13; */
        if (message.localizedDescription)
            LocalizedStringSet.internalBinaryWrite(message.localizedDescription, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* optional Kiosk.LocalizedStringSet localizedDisplayName = 14; */
        if (message.localizedDisplayName)
            LocalizedStringSet.internalBinaryWrite(message.localizedDisplayName, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.OrderItemRewardSourceInformation rewardSource = 15; */
        if (message.rewardSource)
            OrderItemRewardSourceInformation.internalBinaryWrite(message.rewardSource, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* string itemSessionId = 16; */
        if (message.itemSessionId !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.itemSessionId);
        /* google.protobuf.Int32Value itemPrice = 17; */
        if (message.itemPrice)
            Int32Value.internalBinaryWrite(message.itemPrice, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderItem
 */
export const OrderItem = new OrderItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderItemRewardSourceInformation$Type extends MessageType<OrderItemRewardSourceInformation> {
    constructor() {
        super("Kiosk.OrderItemRewardSourceInformation", [
            { no: 1, name: "rewardId", kind: "message", T: () => StringValue }
        ]);
    }
    create(value?: PartialMessage<OrderItemRewardSourceInformation>): OrderItemRewardSourceInformation {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<OrderItemRewardSourceInformation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderItemRewardSourceInformation): OrderItemRewardSourceInformation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.StringValue rewardId */ 1:
                    message.rewardId = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.rewardId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderItemRewardSourceInformation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.StringValue rewardId = 1; */
        if (message.rewardId)
            StringValue.internalBinaryWrite(message.rewardId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderItemRewardSourceInformation
 */
export const OrderItemRewardSourceInformation = new OrderItemRewardSourceInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderItemUpsellSourceInformation$Type extends MessageType<OrderItemUpsellSourceInformation> {
    constructor() {
        super("Kiosk.OrderItemUpsellSourceInformation", [
            { no: 1, name: "upsellId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "upsellLevel", kind: "enum", T: () => ["Kiosk.UpsellLevelType", UpsellLevelType] },
            { no: 3, name: "upsellPromptItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OrderItemUpsellSourceInformation>): OrderItemUpsellSourceInformation {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.upsellId = "";
        message.upsellLevel = 0;
        message.upsellPromptItemId = "";
        if (value !== undefined)
            reflectionMergePartial<OrderItemUpsellSourceInformation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderItemUpsellSourceInformation): OrderItemUpsellSourceInformation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string upsellId */ 1:
                    message.upsellId = reader.string();
                    break;
                case /* Kiosk.UpsellLevelType upsellLevel */ 2:
                    message.upsellLevel = reader.int32();
                    break;
                case /* string upsellPromptItemId */ 3:
                    message.upsellPromptItemId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderItemUpsellSourceInformation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string upsellId = 1; */
        if (message.upsellId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.upsellId);
        /* Kiosk.UpsellLevelType upsellLevel = 2; */
        if (message.upsellLevel !== 0)
            writer.tag(2, WireType.Varint).int32(message.upsellLevel);
        /* string upsellPromptItemId = 3; */
        if (message.upsellPromptItemId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.upsellPromptItemId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderItemUpsellSourceInformation
 */
export const OrderItemUpsellSourceInformation = new OrderItemUpsellSourceInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProductChoiceGroup$Type extends MessageType<ProductChoiceGroup> {
    constructor() {
        super("Kiosk.ProductChoiceGroup", [
            { no: 1, name: "modifierGroupId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "choices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProductChoice }
        ]);
    }
    create(value?: PartialMessage<ProductChoiceGroup>): ProductChoiceGroup {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.modifierGroupId = "";
        message.choices = [];
        if (value !== undefined)
            reflectionMergePartial<ProductChoiceGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProductChoiceGroup): ProductChoiceGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modifierGroupId */ 1:
                    message.modifierGroupId = reader.string();
                    break;
                case /* repeated Kiosk.ProductChoice choices */ 2:
                    message.choices.push(ProductChoice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProductChoiceGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modifierGroupId = 1; */
        if (message.modifierGroupId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modifierGroupId);
        /* repeated Kiosk.ProductChoice choices = 2; */
        for (let i = 0; i < message.choices.length; i++)
            ProductChoice.internalBinaryWrite(message.choices[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ProductChoiceGroup
 */
export const ProductChoiceGroup = new ProductChoiceGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProductChoice$Type extends MessageType<ProductChoice> {
    constructor() {
        super("Kiosk.ProductChoice", [
            { no: 1, name: "modifierId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "choiceGroups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProductChoiceGroup }
        ]);
    }
    create(value?: PartialMessage<ProductChoice>): ProductChoice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.modifierId = "";
        message.quantity = 0;
        message.choiceGroups = [];
        if (value !== undefined)
            reflectionMergePartial<ProductChoice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProductChoice): ProductChoice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modifierId */ 1:
                    message.modifierId = reader.string();
                    break;
                case /* int32 quantity */ 2:
                    message.quantity = reader.int32();
                    break;
                case /* repeated Kiosk.ProductChoiceGroup choiceGroups */ 3:
                    message.choiceGroups.push(ProductChoiceGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProductChoice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modifierId = 1; */
        if (message.modifierId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modifierId);
        /* int32 quantity = 2; */
        if (message.quantity !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantity);
        /* repeated Kiosk.ProductChoiceGroup choiceGroups = 3; */
        for (let i = 0; i < message.choiceGroups.length; i++)
            ProductChoiceGroup.internalBinaryWrite(message.choiceGroups[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ProductChoice
 */
export const ProductChoice = new ProductChoice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemModifier$Type extends MessageType<ItemModifier> {
    constructor() {
        super("Kiosk.ItemModifier", [
            { no: 1, name: "menuItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "modifierId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "optionGroupId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "modifiers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemModifier },
            { no: 6, name: "transformRulesMetadata", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "modifierCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "freeQuantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "modifierCodeSelection", kind: "message", T: () => ItemModifierModifierCode },
            { no: 11, name: "unitPrice", kind: "message", T: () => Int32Value },
            { no: 12, name: "totalPrice", kind: "message", T: () => Int32Value },
            { no: 13, name: "isDefault", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ItemModifier>): ItemModifier {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.menuItemId = "";
        message.modifierId = "";
        message.optionGroupId = "";
        message.quantity = 0;
        message.modifiers = [];
        message.transformRulesMetadata = "";
        message.name = "";
        message.modifierCode = "";
        message.freeQuantity = 0;
        message.isDefault = false;
        if (value !== undefined)
            reflectionMergePartial<ItemModifier>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemModifier): ItemModifier {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string menuItemId = 1 [deprecated = true];*/ 1:
                    message.menuItemId = reader.string();
                    break;
                case /* string modifierId */ 5:
                    message.modifierId = reader.string();
                    break;
                case /* string optionGroupId */ 2:
                    message.optionGroupId = reader.string();
                    break;
                case /* int32 quantity */ 3:
                    message.quantity = reader.int32();
                    break;
                case /* repeated Kiosk.ItemModifier modifiers */ 4:
                    message.modifiers.push(ItemModifier.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string transformRulesMetadata */ 6:
                    message.transformRulesMetadata = reader.string();
                    break;
                case /* string name */ 7:
                    message.name = reader.string();
                    break;
                case /* string modifierCode = 8 [deprecated = true];*/ 8:
                    message.modifierCode = reader.string();
                    break;
                case /* int32 freeQuantity */ 9:
                    message.freeQuantity = reader.int32();
                    break;
                case /* Kiosk.ItemModifierModifierCode modifierCodeSelection */ 10:
                    message.modifierCodeSelection = ItemModifierModifierCode.internalBinaryRead(reader, reader.uint32(), options, message.modifierCodeSelection);
                    break;
                case /* google.protobuf.Int32Value unitPrice */ 11:
                    message.unitPrice = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.unitPrice);
                    break;
                case /* google.protobuf.Int32Value totalPrice */ 12:
                    message.totalPrice = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.totalPrice);
                    break;
                case /* bool isDefault */ 13:
                    message.isDefault = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemModifier, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string menuItemId = 1 [deprecated = true]; */
        if (message.menuItemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.menuItemId);
        /* string modifierId = 5; */
        if (message.modifierId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.modifierId);
        /* string optionGroupId = 2; */
        if (message.optionGroupId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.optionGroupId);
        /* int32 quantity = 3; */
        if (message.quantity !== 0)
            writer.tag(3, WireType.Varint).int32(message.quantity);
        /* repeated Kiosk.ItemModifier modifiers = 4; */
        for (let i = 0; i < message.modifiers.length; i++)
            ItemModifier.internalBinaryWrite(message.modifiers[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string transformRulesMetadata = 6; */
        if (message.transformRulesMetadata !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.transformRulesMetadata);
        /* string name = 7; */
        if (message.name !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.name);
        /* string modifierCode = 8 [deprecated = true]; */
        if (message.modifierCode !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.modifierCode);
        /* int32 freeQuantity = 9; */
        if (message.freeQuantity !== 0)
            writer.tag(9, WireType.Varint).int32(message.freeQuantity);
        /* Kiosk.ItemModifierModifierCode modifierCodeSelection = 10; */
        if (message.modifierCodeSelection)
            ItemModifierModifierCode.internalBinaryWrite(message.modifierCodeSelection, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value unitPrice = 11; */
        if (message.unitPrice)
            Int32Value.internalBinaryWrite(message.unitPrice, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value totalPrice = 12; */
        if (message.totalPrice)
            Int32Value.internalBinaryWrite(message.totalPrice, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* bool isDefault = 13; */
        if (message.isDefault !== false)
            writer.tag(13, WireType.Varint).bool(message.isDefault);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ItemModifier
 */
export const ItemModifier = new ItemModifier$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemModifierModifierCode$Type extends MessageType<ItemModifierModifierCode> {
    constructor() {
        super("Kiosk.ItemModifierModifierCode", [
            { no: 1, name: "modifierCodeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "modifierCodeName", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ItemModifierModifierCode>): ItemModifierModifierCode {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.modifierCodeId = "";
        message.modifierCodeName = "";
        if (value !== undefined)
            reflectionMergePartial<ItemModifierModifierCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemModifierModifierCode): ItemModifierModifierCode {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modifierCodeId */ 1:
                    message.modifierCodeId = reader.string();
                    break;
                case /* string modifierCodeName */ 2:
                    message.modifierCodeName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemModifierModifierCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modifierCodeId = 1; */
        if (message.modifierCodeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modifierCodeId);
        /* string modifierCodeName = 2; */
        if (message.modifierCodeName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.modifierCodeName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ItemModifierModifierCode
 */
export const ItemModifierModifierCode = new ItemModifierModifierCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderDiscount$Type extends MessageType<OrderDiscount> {
    constructor() {
        super("Kiosk.OrderDiscount", [
            { no: 1, name: "discountId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flat", kind: "scalar", oneof: "value", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "points", kind: "scalar", oneof: "value", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "percent", kind: "scalar", oneof: "value", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "discountAmount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<OrderDiscount>): OrderDiscount {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.discountId = "";
        message.value = { oneofKind: undefined };
        message.discountAmount = 0;
        if (value !== undefined)
            reflectionMergePartial<OrderDiscount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderDiscount): OrderDiscount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string discountId */ 1:
                    message.discountId = reader.string();
                    break;
                case /* int32 flat */ 2:
                    message.value = {
                        oneofKind: "flat",
                        flat: reader.int32()
                    };
                    break;
                case /* int32 points */ 3:
                    message.value = {
                        oneofKind: "points",
                        points: reader.int32()
                    };
                    break;
                case /* int32 percent */ 4:
                    message.value = {
                        oneofKind: "percent",
                        percent: reader.int32()
                    };
                    break;
                case /* double discountAmount */ 5:
                    message.discountAmount = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderDiscount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string discountId = 1; */
        if (message.discountId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.discountId);
        /* int32 flat = 2; */
        if (message.value.oneofKind === "flat")
            writer.tag(2, WireType.Varint).int32(message.value.flat);
        /* int32 points = 3; */
        if (message.value.oneofKind === "points")
            writer.tag(3, WireType.Varint).int32(message.value.points);
        /* int32 percent = 4; */
        if (message.value.oneofKind === "percent")
            writer.tag(4, WireType.Varint).int32(message.value.percent);
        /* double discountAmount = 5; */
        if (message.discountAmount !== 0)
            writer.tag(5, WireType.Bit64).double(message.discountAmount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderDiscount
 */
export const OrderDiscount = new OrderDiscount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReceiptLink$Type extends MessageType<ReceiptLink> {
    constructor() {
        super("Kiosk.ReceiptLink", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "shortURL", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "longURL", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReceiptLink>): ReceiptLink {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.title = "";
        message.shortURL = "";
        message.longURL = "";
        if (value !== undefined)
            reflectionMergePartial<ReceiptLink>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReceiptLink): ReceiptLink {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string shortURL */ 2:
                    message.shortURL = reader.string();
                    break;
                case /* string longURL */ 3:
                    message.longURL = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReceiptLink, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string shortURL = 2; */
        if (message.shortURL !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.shortURL);
        /* string longURL = 3; */
        if (message.longURL !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.longURL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ReceiptLink
 */
export const ReceiptLink = new ReceiptLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPreviousOrderRequest$Type extends MessageType<GetPreviousOrderRequest> {
    constructor() {
        super("Kiosk.GetPreviousOrderRequest", [
            { no: 2, name: "orderId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPreviousOrderRequest>): GetPreviousOrderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.orderId = "";
        if (value !== undefined)
            reflectionMergePartial<GetPreviousOrderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPreviousOrderRequest): GetPreviousOrderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string orderId */ 2:
                    message.orderId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPreviousOrderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string orderId = 2; */
        if (message.orderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.orderId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.GetPreviousOrderRequest
 */
export const GetPreviousOrderRequest = new GetPreviousOrderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPreviousOrderReply$Type extends MessageType<GetPreviousOrderReply> {
    constructor() {
        super("Kiosk.GetPreviousOrderReply", [
            { no: 1, name: "order", kind: "message", T: () => MenuOrder },
            { no: 2, name: "details", kind: "message", T: () => OrderDetails }
        ]);
    }
    create(value?: PartialMessage<GetPreviousOrderReply>): GetPreviousOrderReply {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetPreviousOrderReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPreviousOrderReply): GetPreviousOrderReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.MenuOrder order */ 1:
                    message.order = MenuOrder.internalBinaryRead(reader, reader.uint32(), options, message.order);
                    break;
                case /* Kiosk.OrderDetails details */ 2:
                    message.details = OrderDetails.internalBinaryRead(reader, reader.uint32(), options, message.details);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPreviousOrderReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.MenuOrder order = 1; */
        if (message.order)
            MenuOrder.internalBinaryWrite(message.order, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.OrderDetails details = 2; */
        if (message.details)
            OrderDetails.internalBinaryWrite(message.details, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.GetPreviousOrderReply
 */
export const GetPreviousOrderReply = new GetPreviousOrderReply$Type();
