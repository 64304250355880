import { get, type Readable, readonly, writable } from 'svelte/store';
import { Logger } from '../../logger';
import { gem_service } from '../events/gem_event_service';
import { order_service } from '..';

export interface IOfflineCheckService {
  start_monitoring: () => void;
}

const checkInternet = (): Promise<boolean> => {
  /* Calling Grubber portal url to check if it is able to fetch it 
       Third party url have CORS Issue with them where browser does not call 
       Third Party url if its not marked on server that current domain can use it */

  return new Promise<boolean>((response) => {
    const timerHandle = setTimeout(() => {
      response(false);
    }, 5000);

    fetch(import.meta.env.VITE_GRPC_ENDPOINT, {
      cache: 'no-store',
    })
      .then((data) => {
        clearTimeout(timerHandle);
        response(data.ok);
      })
      .catch(() => {
        clearTimeout(timerHandle);
        response(false);
      });
  });
};

const beautifyDuration = (duration: number) => {
  const minutes = Math.floor(duration / 1000 / 60);
  const seconds = Math.floor(duration / 1000) - minutes * 60;
  return `Duration ${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s`;
};

export interface IOnlineStatusStateManager {
  isOnline: Readable<boolean>;
  getIsOnline(): boolean;
  start(): Promise<void>;
  destory(): void;
}

export class OnlineStatusStateManager implements IOnlineStatusStateManager {
  public isOnline: Readable<boolean>;
  private offlineTime: Date | undefined;
  private offlineError: Error | undefined;

  private internalIsOnlineStore = writable(true);

  private checkTimer: NodeJS.Timeout | number | undefined;

  constructor(private checkInterval: number) {
    this.isOnline = readonly(this.internalIsOnlineStore);
  }

  public start = async () => {
    await this.healthCheck();
  };

  public getIsOnline = (): boolean => {
    return get(this.internalIsOnlineStore);
  };

  public destory(): void {
    if (this.checkTimer) {
      clearTimeout(this.checkTimer as number);
    }
  }

  private startTimerForCheck = () => {
    this.checkTimer = setTimeout(this.healthCheck, this.checkInterval);
  };

  private healthCheck = async () => {
    Logger.log('Dev', 'Starting offline check');
    try {
      const res = await checkInternet();
      if (res) {
        await this.set_kiosk_online();
      } else {
        this.set_kiosk_offline(this.offlineError);
      }
      // await client.healthCheck({});
    } catch (e) {
      this.set_kiosk_offline(e);
    }

    // start next check
    this.startTimerForCheck();
  };

  private set_kiosk_offline = (e: unknown) => {
    this.internalIsOnlineStore.set(false);
    if (!this.offlineError && !this.offlineTime) {
      this.offlineError = e as Error;
      this.offlineTime = new Date();
      gem_service.session_kiosk_offline(this.offlineTime, this.offlineError);
    }
  };

  private set_kiosk_online = async () => {
    this.internalIsOnlineStore.set(true);
    if (this.offlineTime) {
      const now = new Date();
      const duration = Math.abs(now.getTime() - this.offlineTime.getTime());
      gem_service.session_kiosk_back_online(
        this.offlineTime,
        now,
        duration,
        beautifyDuration(duration),
        this.offlineError
      );
      this.offlineTime = undefined;
    }

    Logger.log('Dev', 'start offline monitoring');
    order_service.send({
      type: 'OFFLINE_ORDER_UP',
      data: undefined,
    });
  };
}

// this is global for now b/c the previous code was a global
// and would require a larger refactor to make it not global
export const onlineStateManager: IOnlineStatusStateManager = new OnlineStatusStateManager(
  2 * 60 * 1000 /* check every 2 mins */
);
