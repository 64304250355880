/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "kioskOrderService.proto" (package "Kiosk", syntax proto3)
// tslint:disable
// @ts-nocheck
import { GetPreviousOrderReply } from "./kioskOrder_pb";
import { GetPreviousOrderRequest } from "./kioskOrder_pb";
import { LogOrderReply } from "./kioskOrder_pb";
import { LogOrderRequest } from "./kioskOrder_pb";
import { CloseOrderReply } from "./kioskOrder_pb";
import { CloseOrderRequest } from "./kioskOrder_pb";
import { UpdateOrderRequest } from "./kioskOrder_pb";
import { DeleteOrderReply } from "./kioskOrder_pb";
import { DeleteOrderRequest } from "./kioskOrder_pb";
import { ValidateOrderResponse } from "./kioskOrder_pb";
import { OrderDetails } from "./kioskOrder_pb";
import { ValidateOrderRequest } from "./kioskOrder_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
/**
 * @generated ServiceType for protobuf service Kiosk.KioskOrderService
 */
export const KioskOrderService = new ServiceType("Kiosk.KioskOrderService", [
    { name: "ValidateOrder", options: { "google.api.http": { post: "/v1/KioskOrderService/ValidateOrder", body: "*" } }, I: ValidateOrderRequest, O: OrderDetails },
    { name: "ValidateOrderWithStatus", options: { "google.api.http": { post: "/v1/KioskOrderService/ValidateOrderWithStatus", body: "*" } }, I: ValidateOrderRequest, O: ValidateOrderResponse },
    { name: "DeleteOrder", options: { "google.api.http": { post: "/v1/KioskOrderService/DeleteOrder", body: "*" } }, I: DeleteOrderRequest, O: DeleteOrderReply },
    { name: "UpdateOrder", options: { "google.api.http": { post: "/v1/KioskOrderService/UpdateOrder", body: "*" } }, I: UpdateOrderRequest, O: OrderDetails },
    { name: "CloseOrder", options: { "google.api.http": { post: "/v1/KioskOrderService/CloseOrder", body: "*" } }, I: CloseOrderRequest, O: CloseOrderReply },
    { name: "LogOrder", options: { "google.api.http": { post: "/v1/KioskOrderService/LogOrder", body: "*" } }, I: LogOrderRequest, O: LogOrderReply },
    { name: "GetPreviousOrder", options: { "google.api.http": { post: "/v1/KioskOrderService/GetPreviousOrder", body: "*" } }, I: GetPreviousOrderRequest, O: GetPreviousOrderReply }
]);
