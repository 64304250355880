import type { ActorRefFrom, Receiver, Sender } from 'xstate';
import type { SocketCloseOptions, TCPSocketOpenInfo, TCPSocketOptions } from './direct-sockets';
import {
  proxy_machine,
  type Proxy_Context,
  type Proxy_Event,
  type Proxy_Events,
} from './proxy_machine';

/**
 * TCPSocket interface defined by the Direct Sockets API.
 */
declare class TCPSocket {
  constructor(remoteAddress: string, remotePort: number, options?: TCPSocketOptions);

  opened: Promise<TCPSocketOpenInfo>;
  closed: Promise<void>;

  close(options?: SocketCloseOptions): Promise<void>;
}

export type Tcp_Socket_Context = {
  ip: string;
  port: number;
  send_data?: string | ArrayBufferLike | Blob | ArrayBufferView;
  received_data?: Uint8Array;
};

export const tcp_socket_callback =
  ({ options }: Proxy_Context) =>
  (callback: Sender<Proxy_Events>, onReceive: Receiver<Proxy_Event>) => {
    try {
      const { ip, port } = options;
      if (typeof ip !== 'string') {
        callback('CLOSE');
        return;
      }
      if (typeof port !== 'number') {
        callback('CLOSE');
        return;
      }

      const encoder = new TextEncoder();
      const socket = new TCPSocket(ip, port);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const streamsPromise = socket.opened.then(({ readable, writable }) => {
        callback('CONNECTED');

        const writer = writable.getWriter();
        const reader = readable
          .pipeThrough(
            new TransformStream<Uint8Array, Uint8Array>({
              transform(chunk, controller) {
                callback({ type: 'RECEIVED', data: chunk });
                controller.enqueue(chunk);
              },
            })
          )
          .getReader();

        onReceive((ev) => {
          switch (ev.type) {
            case 'SEND':
              writer.ready.then(() => {
                if (typeof ev.data !== 'string') {
                  callback('CLOSE');
                  return;
                }
                writer.write(encoder.encode(ev.data));
              });
              return;
            case 'CLOSE':
              callback('CLOSE');
              return;
          }
        });
        return { writer, reader };
      });

      socket.closed.then(() => {
        callback('CLOSE');
      });

      return () => {
        socket.close();
      };
    } catch (ex) {
      console.warn(`Cannot load TCPSocket: ${ex}`);
      callback('CLOSE');
    }
  };

declare global {
  interface Window {
    TCPSocket: TCPSocket;
  }
}

export const tcp_socket_proxy_machine = !window.TCPSocket
  ? proxy_machine
  : proxy_machine.withConfig({
      services: {
        proxy_callback: tcp_socket_callback,
      },
    });
//  createMachine<Tcp_Socket_Context, Tcp_Socket_Events>(
//   {
//     id: 'tcp-proxy',
//     predictableActionArguments: true,
//     initial: 'connection',
//     states: {
//       connection: {
//         invoke: {
//           src: 'socket_callback',
//           id: 'socket',
//         },
//         initial: 'unavailable',
//         states: {
//           unavailable: {
//             entry: ['clear_receive'],
//             on: {
//               CONNECTED: {target: 'available'},
//               SEND: {
//                 actions: ['queue_send'],
//               },
//             },
//           },
//           available: {
//             entry: ['send_to_socket', 'clear_send_queue'],
//             on: {
//               RECEIVED: {actions: ['received_data']},
//               SEND: {actions: ['clear_receive', 'send_to_socket']},
//               CLOSE: {target: '#tcp-proxy.disconnected'},
//             },
//           },
//         },
//       },
//       disconnected: {
//         on: {
//           SEND: {
//             target: 'connection',
//             actions: ['queue-send'],
//           },
//         },
//       },
//     },
//   },
//   {
//     services: !window.TCPSocket
//       ? {
//           socket_callback: web_socket_callback,
//         }
//       : {
//           socket_callback: tcp_socket_callback,
//         },
//     actions: {
//       clear_receive: assign<Tcp_Socket_Context, Tcp_Socket_Events>({received_data: undefined}),
//       received_data: assign<Tcp_Socket_Context, Tcp_Socket_Events>({
//         received_data: (ctx, evt) => {
//           if (evt.type !== 'RECEIVED') {
//             return ctx.received_data;
//           }
//           if (!ctx.received_data) {
//             return evt.data;
//           }
//           return new Uint8Array(merge_array_buffers(ctx.received_data, evt.data));
//         },
//       }),
//       queue_send: assign({send_data: (_, evt) => (evt.type === 'SEND' ? evt.data : undefined)}),
//       send_to_socket: send(
//         (ctx, evt) => ({
//           type: 'SEND',
//           data: ctx.send_data || (evt.type === 'SEND' ? evt.data : undefined),
//         }),
//         {
//           to: 'socket',
//         }
//       ),
//       clear_send_queue: assign<Tcp_Socket_Context, Tcp_Socket_Events>({send_data: undefined}),
//     },
//   }
// );

export type InterpretedTcpSocketMachine = ActorRefFrom<typeof tcp_socket_proxy_machine>;
