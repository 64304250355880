import type {
  KioskComboComponent,
  KioskComboComponentChoiceWithoutItem,
  KioskComboComponentReduced,
} from './kiosk_combo_component';
import type { LocalizedStringSet, MenuItemPromotionalDetails } from '../../generated/menu_pb';

import type { DownloadedImageUrl } from '../data-management/types';
import type { KioskTimeSchedule } from './kiosk_time_schedule';

export type KioskCombo = {
  id: string;
  name: string;
  quantity: number;
  description: string;
  calorieText: string;
  components: KioskComboComponent[];
  promotionalDetails: MenuItemPromotionalDetails | null;
  selectedDisplayName: string;
  sizeLabel: string;
  localizedDescription: LocalizedStringSet | undefined;
  localizedName: LocalizedStringSet | undefined;
  localizedSizeLabel: LocalizedStringSet | undefined;
  localizedDisplayName: LocalizedStringSet | undefined;
  timeSchedule: KioskTimeSchedule;
};

export type KioskComboReduced = Omit<KioskCombo, 'components'> & {
  components: KioskComboComponentReduced[];
};

export type KioskComboMedia = {
  selectedImagePath: DownloadedImageUrl | null;
  listingImagePath: DownloadedImageUrl | null;
};

// Function to check if all items in a component are out of stock
const areAllChoicesOutOfStock = (
  choices: KioskComboComponentChoiceWithoutItem[],
  soldOutItems: Set<string>
) => {
  return choices.every((choice) => soldOutItems.has(choice.itemId ?? ''));
};

export const isComboSoldOut = (combo: KioskComboReduced, soldOutItems: Set<string>) => {
  // Iterate over each component of the combo
  for (const component of combo.components) {
    // If the component has only one choice and that choice is out of stock
    if (component.choices.length === 1) {
      if (soldOutItems.has(component.choices[0].itemId ?? '')) {
        return true;
      }
    }

    // If all choices within a component are out of stock
    if (areAllChoicesOutOfStock(component.choices, soldOutItems)) {
      return true;
    }

    // Check if the default item is out of stock
    const defaultChoice = component.choices.find((choice) => choice.isDefault);
    if (defaultChoice && soldOutItems.has(defaultChoice.itemId)) {
      return true;
    }
  }

  return false;
};
