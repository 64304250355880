import { readable } from 'svelte/store';

export const clock = readable<{ date: Date; day: number; minutes: number }>(undefined, (set) => {
  const update = () => {
    const date = new Date();
    const day = date.getDay();
    const minutes = date.getMinutes();
    const hours = date.getHours();
    set({ date, day, minutes: 60 * hours + minutes });
  };

  update();

  const interval = setInterval(update, 1000 * 60);
  return () => clearInterval(interval);
});
