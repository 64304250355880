/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "discount.proto" (package "Kiosk", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "./google/protobuf/timestamp_pb";
import { Int32Value } from "./google/protobuf/wrappers_pb";
import { DayOfWeekVM } from "./common_pb";
/**
 * @generated from protobuf message Kiosk.DiscountsVM
 */
export interface DiscountsVM {
    /**
     * @generated from protobuf field: repeated Kiosk.DiscountBaseVM discounts = 1;
     */
    discounts: DiscountBaseVM[];
}
/**
 * @generated from protobuf message Kiosk.SetupDiscountLevelEnum
 */
export interface SetupDiscountLevelEnum {
}
/**
 * @generated from protobuf enum Kiosk.SetupDiscountLevelEnum.DiscountLevel
 */
export enum SetupDiscountLevelEnum_DiscountLevel {
    /**
     * @generated from protobuf enum value: NotApplicable = 0;
     */
    NotApplicable = 0,
    /**
     * @generated from protobuf enum value: Item = 1;
     */
    Item = 1,
    /**
     * @generated from protobuf enum value: SubTotal = 2;
     */
    SubTotal = 2,
    /**
     * @generated from protobuf enum value: Bogo = 3;
     */
    Bogo = 3,
    /**
     * @generated from protobuf enum value: Bundle = 4;
     */
    Bundle = 4
}
/**
 * @generated from protobuf message Kiosk.SetupDiscountApplicationModeEnum
 */
export interface SetupDiscountApplicationModeEnum {
}
/**
 * @generated from protobuf enum Kiosk.SetupDiscountApplicationModeEnum.ApplicationMode
 */
export enum SetupDiscountApplicationModeEnum_ApplicationMode {
    /**
     * @generated from protobuf enum value: NotApplicable = 0;
     */
    NotApplicable = 0,
    /**
     * @generated from protobuf enum value: AutoApplied = 1;
     */
    AutoApplied = 1,
    /**
     * @generated from protobuf enum value: UserSelectable = 2;
     */
    UserSelectable = 2,
    /**
     * @generated from protobuf enum value: Code = 3;
     */
    Code = 3
}
/**
 * @generated from protobuf message Kiosk.SetupDiscountTypesEnum
 */
export interface SetupDiscountTypesEnum {
}
/**
 * @generated from protobuf enum Kiosk.SetupDiscountTypesEnum.DiscountTypes
 */
export enum SetupDiscountTypesEnum_DiscountTypes {
    /**
     * @generated from protobuf enum value: NotApplicable = 0;
     */
    NotApplicable = 0,
    /**
     * @generated from protobuf enum value: FreeItem = 1;
     */
    FreeItem = 1,
    /**
     * @generated from protobuf enum value: FixedAmount = 2;
     */
    FixedAmount = 2,
    /**
     * @generated from protobuf enum value: Percentage = 3;
     */
    Percentage = 3,
    /**
     * @generated from protobuf enum value: PriceOverride = 4;
     */
    PriceOverride = 4
}
/**
 * @generated from protobuf message Kiosk.SetupDiscountConditionEnum
 */
export interface SetupDiscountConditionEnum {
}
/**
 * @generated from protobuf enum Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum
 */
export enum SetupDiscountConditionEnum_DiscountConditionEnum {
    /**
     * @generated from protobuf enum value: NotApplicable = 0;
     */
    NotApplicable = 0,
    /**
     * @generated from protobuf enum value: CartIncludesItem = 1;
     */
    CartIncludesItem = 1,
    /**
     * @generated from protobuf enum value: CartIncludesDiscount = 2;
     */
    CartIncludesDiscount = 2,
    /**
     * @generated from protobuf enum value: MinimumSubtotal = 3;
     */
    MinimumSubtotal = 3,
    /**
     * @generated from protobuf enum value: DayOfWeek = 4;
     */
    DayOfWeek = 4,
    /**
     * @generated from protobuf enum value: OrderTime = 5;
     */
    OrderTime = 5,
    /**
     * @generated from protobuf enum value: And = 6;
     */
    And = 6,
    /**
     * @generated from protobuf enum value: Or = 7;
     */
    Or = 7,
    /**
     * @generated from protobuf enum value: Not = 8;
     */
    Not = 8,
    /**
     * @generated from protobuf enum value: Single = 9;
     */
    Single = 9,
    /**
     * @generated from protobuf enum value: CanOfferItems = 10;
     */
    CanOfferItems = 10,
    /**
     * @generated from protobuf enum value: CartIncludesCatagory = 11;
     */
    CartIncludesCatagory = 11,
    /**
     * @generated from protobuf enum value: QualifiedItems = 12;
     */
    QualifiedItems = 12,
    /**
     * @generated from protobuf enum value: DayAndTime = 13;
     */
    DayAndTime = 13,
    /**
     * @generated from protobuf enum value: CanOfferCategory = 14;
     */
    CanOfferCategory = 14
}
/**
 * @generated from protobuf message Kiosk.DiscountScheduleVM
 */
export interface DiscountScheduleVM {
    /**
     * @generated from protobuf field: Kiosk.DayOfWeekVM day = 1;
     */
    day: DayOfWeekVM;
    /**
     * @generated from protobuf field: repeated Kiosk.DiscountScheduleHourRangeVM hours = 2;
     */
    hours: DiscountScheduleHourRangeVM[];
}
/**
 * @generated from protobuf message Kiosk.DiscountScheduleHourRangeVM
 */
export interface DiscountScheduleHourRangeVM {
    /**
     * @generated from protobuf field: int32 startMinutes = 1;
     */
    startMinutes: number;
    /**
     * @generated from protobuf field: int32 endMinutes = 2;
     */
    endMinutes: number;
}
/**
 * @generated from protobuf message Kiosk.DiscountFreeItemVM
 */
export interface DiscountFreeItemVM {
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountTypesEnum.DiscountTypes kind = 1;
     */
    kind: SetupDiscountTypesEnum_DiscountTypes;
    /**
     * @generated from protobuf field: string itemId = 2;
     */
    itemId: string;
}
/**
 * @generated from protobuf message Kiosk.DiscountFixedAmountVM
 */
export interface DiscountFixedAmountVM {
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountTypesEnum.DiscountTypes kind = 1;
     */
    kind: SetupDiscountTypesEnum_DiscountTypes;
    /**
     * @generated from protobuf field: double amount = 2;
     */
    amount: number;
}
/**
 * @generated from protobuf message Kiosk.DiscountPercentageVM
 */
export interface DiscountPercentageVM {
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountTypesEnum.DiscountTypes kind = 1;
     */
    kind: SetupDiscountTypesEnum_DiscountTypes;
    /**
     * @generated from protobuf field: double percent = 2;
     */
    percent: number;
}
/**
 * @generated from protobuf message Kiosk.DiscountPriceOverrideVM
 */
export interface DiscountPriceOverrideVM {
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountTypesEnum.DiscountTypes kind = 1;
     */
    kind: SetupDiscountTypesEnum_DiscountTypes;
    /**
     * @generated from protobuf field: double overridePrice = 2;
     */
    overridePrice: number;
}
/**
 * @generated from protobuf message Kiosk.DiscountCartIncludesItemConditionVM
 */
export interface DiscountCartIncludesItemConditionVM {
    /**
     * @generated from protobuf field: string itemId = 1;
     */
    itemId: string;
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2;
     */
    kind: SetupDiscountConditionEnum_DiscountConditionEnum;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value quantity = 3;
     */
    quantity?: Int32Value;
}
/**
 * @generated from protobuf message Kiosk.DiscountCartIncludesCategoryConditionVM
 */
export interface DiscountCartIncludesCategoryConditionVM {
    /**
     * @generated from protobuf field: string categoryId = 1;
     */
    categoryId: string;
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2;
     */
    kind: SetupDiscountConditionEnum_DiscountConditionEnum;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value quantity = 3;
     */
    quantity?: Int32Value;
}
/**
 * @generated from protobuf message Kiosk.DiscountItemsToOfferConditionVM
 */
export interface DiscountItemsToOfferConditionVM {
    /**
     * @generated from protobuf field: string itemId = 1;
     */
    itemId: string;
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2;
     */
    kind: SetupDiscountConditionEnum_DiscountConditionEnum;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value quantity = 3;
     */
    quantity?: Int32Value;
}
/**
 * @generated from protobuf message Kiosk.DiscountCategoryToOfferConditionVM
 */
export interface DiscountCategoryToOfferConditionVM {
    /**
     * @generated from protobuf field: string categoryId = 1;
     */
    categoryId: string;
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2;
     */
    kind: SetupDiscountConditionEnum_DiscountConditionEnum;
}
/**
 * @generated from protobuf message Kiosk.DiscountCartIncludesDiscountConditionVM
 */
export interface DiscountCartIncludesDiscountConditionVM {
    /**
     * @generated from protobuf field: string discountId = 1;
     */
    discountId: string;
    /**
     * @generated from protobuf field: optional double redeemableLimit = 2;
     */
    redeemableLimit?: number;
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 3;
     */
    kind: SetupDiscountConditionEnum_DiscountConditionEnum;
}
/**
 * @generated from protobuf message Kiosk.DiscountMinimumSubtotalConditionVM
 */
export interface DiscountMinimumSubtotalConditionVM {
    /**
     * @generated from protobuf field: double minimumSubtotal = 1;
     */
    minimumSubtotal: number;
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2;
     */
    kind: SetupDiscountConditionEnum_DiscountConditionEnum;
}
/**
 * @generated from protobuf message Kiosk.DiscountDayOfWeekConditionVM
 */
export interface DiscountDayOfWeekConditionVM {
    /**
     * @generated from protobuf field: int32 dayOfWeek = 1;
     */
    dayOfWeek: number;
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2;
     */
    kind: SetupDiscountConditionEnum_DiscountConditionEnum;
}
/**
 * @generated from protobuf message Kiosk.DiscountOrderTimeConditionVM
 */
export interface DiscountOrderTimeConditionVM {
    /**
     * @generated from protobuf field: int32 startHour = 1;
     */
    startHour: number;
    /**
     * @generated from protobuf field: int32 startMinutes = 2;
     */
    startMinutes: number;
    /**
     * @generated from protobuf field: int32 endHour = 3;
     */
    endHour: number;
    /**
     * @generated from protobuf field: int32 endMinutes = 4;
     */
    endMinutes: number;
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 5;
     */
    kind: SetupDiscountConditionEnum_DiscountConditionEnum;
}
/**
 * @generated from protobuf message Kiosk.DiscountAndConditionVM
 */
export interface DiscountAndConditionVM {
    /**
     * @generated from protobuf field: repeated Kiosk.DiscountConditionVM and = 1;
     */
    and: DiscountConditionVM[];
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2;
     */
    kind: SetupDiscountConditionEnum_DiscountConditionEnum;
}
/**
 * @generated from protobuf message Kiosk.DiscountOrConditionVM
 */
export interface DiscountOrConditionVM {
    /**
     * @generated from protobuf field: repeated Kiosk.DiscountConditionVM or = 1;
     */
    or: DiscountConditionVM[];
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2;
     */
    kind: SetupDiscountConditionEnum_DiscountConditionEnum;
}
/**
 * @generated from protobuf message Kiosk.DiscountNotConditionVM
 */
export interface DiscountNotConditionVM {
    /**
     * @generated from protobuf field: Kiosk.DiscountConditionVM not = 1;
     */
    not?: DiscountConditionVM;
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2;
     */
    kind: SetupDiscountConditionEnum_DiscountConditionEnum;
}
/**
 * @generated from protobuf message Kiosk.DiscountSingleConditionVM
 */
export interface DiscountSingleConditionVM {
    /**
     * @generated from protobuf field: Kiosk.DiscountConditionVM single = 1;
     */
    single?: DiscountConditionVM;
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2;
     */
    kind: SetupDiscountConditionEnum_DiscountConditionEnum;
}
/**
 * @generated from protobuf message Kiosk.DiscountQualifiedItemsConditionVM
 */
export interface DiscountQualifiedItemsConditionVM {
    /**
     * @generated from protobuf field: repeated Kiosk.DiscountConditionVM and = 1;
     */
    and: DiscountConditionVM[];
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2;
     */
    kind: SetupDiscountConditionEnum_DiscountConditionEnum;
}
/**
 * @generated from protobuf message Kiosk.DiscountDayAndTimeConditionVM
 */
export interface DiscountDayAndTimeConditionVM {
    /**
     * @generated from protobuf field: Kiosk.DiscountDayOfWeekConditionVM daysOfWeek = 1;
     */
    daysOfWeek?: DiscountDayOfWeekConditionVM;
    /**
     * @generated from protobuf field: Kiosk.DiscountOrderTimeConditionVM orderTimes = 2;
     */
    orderTimes?: DiscountOrderTimeConditionVM;
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 3;
     */
    kind: SetupDiscountConditionEnum_DiscountConditionEnum;
}
/**
 * @generated from protobuf message Kiosk.DiscountConditionVM
 */
export interface DiscountConditionVM {
    /**
     * @generated from protobuf oneof: condition
     */
    condition: {
        oneofKind: "cartIncludesItem";
        /**
         * @generated from protobuf field: Kiosk.DiscountCartIncludesItemConditionVM cartIncludesItem = 1;
         */
        cartIncludesItem: DiscountCartIncludesItemConditionVM;
    } | {
        oneofKind: "cartIncludesDiscount";
        /**
         * @generated from protobuf field: Kiosk.DiscountCartIncludesDiscountConditionVM cartIncludesDiscount = 2;
         */
        cartIncludesDiscount: DiscountCartIncludesDiscountConditionVM;
    } | {
        oneofKind: "minimumSubtotal";
        /**
         * @generated from protobuf field: Kiosk.DiscountMinimumSubtotalConditionVM minimumSubtotal = 3;
         */
        minimumSubtotal: DiscountMinimumSubtotalConditionVM;
    } | {
        oneofKind: "dayOfWeek";
        /**
         * @generated from protobuf field: Kiosk.DiscountDayOfWeekConditionVM dayOfWeek = 4;
         */
        dayOfWeek: DiscountDayOfWeekConditionVM;
    } | {
        oneofKind: "orderTime";
        /**
         * @generated from protobuf field: Kiosk.DiscountOrderTimeConditionVM orderTime = 5;
         */
        orderTime: DiscountOrderTimeConditionVM;
    } | {
        oneofKind: "and";
        /**
         * @generated from protobuf field: Kiosk.DiscountAndConditionVM and = 6;
         */
        and: DiscountAndConditionVM;
    } | {
        oneofKind: "or";
        /**
         * @generated from protobuf field: Kiosk.DiscountOrConditionVM or = 7;
         */
        or: DiscountOrConditionVM;
    } | {
        oneofKind: "not";
        /**
         * @generated from protobuf field: Kiosk.DiscountNotConditionVM not = 8;
         */
        not: DiscountNotConditionVM;
    } | {
        oneofKind: "single";
        /**
         * @generated from protobuf field: Kiosk.DiscountSingleConditionVM single = 9;
         */
        single: DiscountSingleConditionVM;
    } | {
        oneofKind: "canOfferItem";
        /**
         * @generated from protobuf field: Kiosk.DiscountItemsToOfferConditionVM canOfferItem = 10;
         */
        canOfferItem: DiscountItemsToOfferConditionVM;
    } | {
        oneofKind: "cartIncludesCategory";
        /**
         * @generated from protobuf field: Kiosk.DiscountCartIncludesCategoryConditionVM cartIncludesCategory = 11;
         */
        cartIncludesCategory: DiscountCartIncludesCategoryConditionVM;
    } | {
        oneofKind: "qualifiedItems";
        /**
         * @generated from protobuf field: Kiosk.DiscountQualifiedItemsConditionVM qualifiedItems = 12;
         */
        qualifiedItems: DiscountQualifiedItemsConditionVM;
    } | {
        oneofKind: "dayAndTime";
        /**
         * @generated from protobuf field: Kiosk.DiscountDayAndTimeConditionVM dayAndTime = 13;
         */
        dayAndTime: DiscountDayAndTimeConditionVM;
    } | {
        oneofKind: "canOfferCategory";
        /**
         * @generated from protobuf field: Kiosk.DiscountCategoryToOfferConditionVM canOfferCategory = 14;
         */
        canOfferCategory: DiscountCategoryToOfferConditionVM;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message Kiosk.DiscountOrderScope
 */
export interface DiscountOrderScope {
}
/**
 * @generated from protobuf message Kiosk.DiscountSingleItemScope
 */
export interface DiscountSingleItemScope {
    /**
     * @generated from protobuf field: repeated string menuItemIds = 1;
     */
    menuItemIds: string[];
    /**
     * @generated from protobuf field: repeated string comboIds = 2;
     */
    comboIds: string[];
    /**
     * @generated from protobuf field: repeated string categoryIds = 3;
     */
    categoryIds: string[];
}
/**
 * @generated from protobuf message Kiosk.DiscountMultipleItemScope
 */
export interface DiscountMultipleItemScope {
    /**
     * @generated from protobuf field: google.protobuf.Int32Value maxDiscountedItems = 1;
     */
    maxDiscountedItems?: Int32Value;
    /**
     * @generated from protobuf field: repeated string menuItemIds = 2;
     */
    menuItemIds: string[];
    /**
     * @generated from protobuf field: repeated string comboIds = 3;
     */
    comboIds: string[];
    /**
     * @generated from protobuf field: repeated string categoryIds = 4;
     */
    categoryIds: string[];
}
/**
 * @generated from protobuf message Kiosk.DiscountScope
 */
export interface DiscountScope {
    /**
     * @generated from protobuf oneof: scope
     */
    scope: {
        oneofKind: "order";
        /**
         * @generated from protobuf field: Kiosk.DiscountOrderScope order = 1;
         */
        order: DiscountOrderScope;
    } | {
        oneofKind: "singleItem";
        /**
         * @generated from protobuf field: Kiosk.DiscountSingleItemScope singleItem = 2;
         */
        singleItem: DiscountSingleItemScope;
    } | {
        oneofKind: "multipleItems";
        /**
         * @generated from protobuf field: Kiosk.DiscountMultipleItemScope multipleItems = 3;
         */
        multipleItems: DiscountMultipleItemScope;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message Kiosk.DiscountBaseVM
 */
export interface DiscountBaseVM {
    /**
     * @generated from protobuf field: string Id = 1 [json_name = "Id"];
     */
    id: string;
    /**
     * @generated from protobuf field: string Name = 2 [json_name = "Name"];
     */
    name: string;
    /**
     * @generated from protobuf field: string DisplayName = 3 [json_name = "DisplayName"];
     */
    displayName: string;
    /**
     * @generated from protobuf field: string ShortDescription = 4 [json_name = "ShortDescription"];
     */
    shortDescription: string;
    /**
     * @generated from protobuf oneof: DiscountType
     */
    discountType: {
        oneofKind: "freeItem";
        /**
         * @generated from protobuf field: Kiosk.DiscountFreeItemVM freeItem = 5;
         */
        freeItem: DiscountFreeItemVM;
    } | {
        oneofKind: "fixedAmount";
        /**
         * @generated from protobuf field: Kiosk.DiscountFixedAmountVM fixedAmount = 6;
         */
        fixedAmount: DiscountFixedAmountVM;
    } | {
        oneofKind: "percentage";
        /**
         * @generated from protobuf field: Kiosk.DiscountPercentageVM percentage = 7;
         */
        percentage: DiscountPercentageVM;
    } | {
        oneofKind: "pricerOverride";
        /**
         * @generated from protobuf field: Kiosk.DiscountPriceOverrideVM pricerOverride = 19;
         */
        pricerOverride: DiscountPriceOverrideVM;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: string POSDiscountId = 8 [json_name = "POSDiscountId"];
     */
    pOSDiscountId: string;
    /**
     * @generated from protobuf field: optional google.protobuf.Timestamp StartDate = 9 [json_name = "StartDate"];
     */
    startDate?: Timestamp;
    /**
     * @generated from protobuf field: optional google.protobuf.Timestamp EndDate = 10 [json_name = "EndDate"];
     */
    endDate?: Timestamp;
    /**
     * @generated from protobuf field: bool Enabled = 11 [json_name = "Enabled"];
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: Kiosk.SetupDiscountApplicationModeEnum.ApplicationMode Mode = 13 [json_name = "Mode"];
     */
    mode: SetupDiscountApplicationModeEnum_ApplicationMode;
    /**
     * @generated from protobuf field: string DiscountImageUrl = 14 [json_name = "DiscountImageUrl"];
     */
    discountImageUrl: string;
    /**
     * @generated from protobuf field: optional string DiscountCode = 15 [json_name = "DiscountCode"];
     */
    discountCode?: string;
    /**
     * @generated from protobuf field: double DiscountRate = 16 [json_name = "DiscountRate"];
     */
    discountRate: number;
    /**
     * @generated from protobuf field: Kiosk.DiscountConditionVM Condition = 18 [json_name = "Condition"];
     */
    condition?: DiscountConditionVM;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value maxApplicationsPerOrder = 21;
     */
    maxApplicationsPerOrder?: Int32Value;
    /**
     * @generated from protobuf field: Kiosk.DiscountScope scope = 22;
     */
    scope?: DiscountScope;
}
// @generated message type with reflection information, may provide speed optimized methods
class DiscountsVM$Type extends MessageType<DiscountsVM> {
    constructor() {
        super("Kiosk.DiscountsVM", [
            { no: 1, name: "discounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DiscountBaseVM }
        ]);
    }
    create(value?: PartialMessage<DiscountsVM>): DiscountsVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.discounts = [];
        if (value !== undefined)
            reflectionMergePartial<DiscountsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountsVM): DiscountsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.DiscountBaseVM discounts */ 1:
                    message.discounts.push(DiscountBaseVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.DiscountBaseVM discounts = 1; */
        for (let i = 0; i < message.discounts.length; i++)
            DiscountBaseVM.internalBinaryWrite(message.discounts[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountsVM
 */
export const DiscountsVM = new DiscountsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetupDiscountLevelEnum$Type extends MessageType<SetupDiscountLevelEnum> {
    constructor() {
        super("Kiosk.SetupDiscountLevelEnum", []);
    }
    create(value?: PartialMessage<SetupDiscountLevelEnum>): SetupDiscountLevelEnum {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SetupDiscountLevelEnum>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetupDiscountLevelEnum): SetupDiscountLevelEnum {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetupDiscountLevelEnum, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.SetupDiscountLevelEnum
 */
export const SetupDiscountLevelEnum = new SetupDiscountLevelEnum$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetupDiscountApplicationModeEnum$Type extends MessageType<SetupDiscountApplicationModeEnum> {
    constructor() {
        super("Kiosk.SetupDiscountApplicationModeEnum", []);
    }
    create(value?: PartialMessage<SetupDiscountApplicationModeEnum>): SetupDiscountApplicationModeEnum {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SetupDiscountApplicationModeEnum>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetupDiscountApplicationModeEnum): SetupDiscountApplicationModeEnum {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetupDiscountApplicationModeEnum, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.SetupDiscountApplicationModeEnum
 */
export const SetupDiscountApplicationModeEnum = new SetupDiscountApplicationModeEnum$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetupDiscountTypesEnum$Type extends MessageType<SetupDiscountTypesEnum> {
    constructor() {
        super("Kiosk.SetupDiscountTypesEnum", []);
    }
    create(value?: PartialMessage<SetupDiscountTypesEnum>): SetupDiscountTypesEnum {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SetupDiscountTypesEnum>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetupDiscountTypesEnum): SetupDiscountTypesEnum {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetupDiscountTypesEnum, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.SetupDiscountTypesEnum
 */
export const SetupDiscountTypesEnum = new SetupDiscountTypesEnum$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetupDiscountConditionEnum$Type extends MessageType<SetupDiscountConditionEnum> {
    constructor() {
        super("Kiosk.SetupDiscountConditionEnum", []);
    }
    create(value?: PartialMessage<SetupDiscountConditionEnum>): SetupDiscountConditionEnum {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SetupDiscountConditionEnum>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetupDiscountConditionEnum): SetupDiscountConditionEnum {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetupDiscountConditionEnum, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.SetupDiscountConditionEnum
 */
export const SetupDiscountConditionEnum = new SetupDiscountConditionEnum$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountScheduleVM$Type extends MessageType<DiscountScheduleVM> {
    constructor() {
        super("Kiosk.DiscountScheduleVM", [
            { no: 1, name: "day", kind: "enum", T: () => ["Kiosk.DayOfWeekVM", DayOfWeekVM] },
            { no: 2, name: "hours", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DiscountScheduleHourRangeVM }
        ]);
    }
    create(value?: PartialMessage<DiscountScheduleVM>): DiscountScheduleVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.day = 0;
        message.hours = [];
        if (value !== undefined)
            reflectionMergePartial<DiscountScheduleVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountScheduleVM): DiscountScheduleVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.DayOfWeekVM day */ 1:
                    message.day = reader.int32();
                    break;
                case /* repeated Kiosk.DiscountScheduleHourRangeVM hours */ 2:
                    message.hours.push(DiscountScheduleHourRangeVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountScheduleVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.DayOfWeekVM day = 1; */
        if (message.day !== 0)
            writer.tag(1, WireType.Varint).int32(message.day);
        /* repeated Kiosk.DiscountScheduleHourRangeVM hours = 2; */
        for (let i = 0; i < message.hours.length; i++)
            DiscountScheduleHourRangeVM.internalBinaryWrite(message.hours[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountScheduleVM
 */
export const DiscountScheduleVM = new DiscountScheduleVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountScheduleHourRangeVM$Type extends MessageType<DiscountScheduleHourRangeVM> {
    constructor() {
        super("Kiosk.DiscountScheduleHourRangeVM", [
            { no: 1, name: "startMinutes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "endMinutes", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DiscountScheduleHourRangeVM>): DiscountScheduleHourRangeVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startMinutes = 0;
        message.endMinutes = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountScheduleHourRangeVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountScheduleHourRangeVM): DiscountScheduleHourRangeVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 startMinutes */ 1:
                    message.startMinutes = reader.int32();
                    break;
                case /* int32 endMinutes */ 2:
                    message.endMinutes = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountScheduleHourRangeVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 startMinutes = 1; */
        if (message.startMinutes !== 0)
            writer.tag(1, WireType.Varint).int32(message.startMinutes);
        /* int32 endMinutes = 2; */
        if (message.endMinutes !== 0)
            writer.tag(2, WireType.Varint).int32(message.endMinutes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountScheduleHourRangeVM
 */
export const DiscountScheduleHourRangeVM = new DiscountScheduleHourRangeVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountFreeItemVM$Type extends MessageType<DiscountFreeItemVM> {
    constructor() {
        super("Kiosk.DiscountFreeItemVM", [
            { no: 1, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountTypesEnum.DiscountTypes", SetupDiscountTypesEnum_DiscountTypes] },
            { no: 2, name: "itemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DiscountFreeItemVM>): DiscountFreeItemVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.kind = 0;
        message.itemId = "";
        if (value !== undefined)
            reflectionMergePartial<DiscountFreeItemVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountFreeItemVM): DiscountFreeItemVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.SetupDiscountTypesEnum.DiscountTypes kind */ 1:
                    message.kind = reader.int32();
                    break;
                case /* string itemId */ 2:
                    message.itemId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountFreeItemVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.SetupDiscountTypesEnum.DiscountTypes kind = 1; */
        if (message.kind !== 0)
            writer.tag(1, WireType.Varint).int32(message.kind);
        /* string itemId = 2; */
        if (message.itemId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.itemId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountFreeItemVM
 */
export const DiscountFreeItemVM = new DiscountFreeItemVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountFixedAmountVM$Type extends MessageType<DiscountFixedAmountVM> {
    constructor() {
        super("Kiosk.DiscountFixedAmountVM", [
            { no: 1, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountTypesEnum.DiscountTypes", SetupDiscountTypesEnum_DiscountTypes] },
            { no: 2, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<DiscountFixedAmountVM>): DiscountFixedAmountVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.kind = 0;
        message.amount = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountFixedAmountVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountFixedAmountVM): DiscountFixedAmountVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.SetupDiscountTypesEnum.DiscountTypes kind */ 1:
                    message.kind = reader.int32();
                    break;
                case /* double amount */ 2:
                    message.amount = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountFixedAmountVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.SetupDiscountTypesEnum.DiscountTypes kind = 1; */
        if (message.kind !== 0)
            writer.tag(1, WireType.Varint).int32(message.kind);
        /* double amount = 2; */
        if (message.amount !== 0)
            writer.tag(2, WireType.Bit64).double(message.amount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountFixedAmountVM
 */
export const DiscountFixedAmountVM = new DiscountFixedAmountVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountPercentageVM$Type extends MessageType<DiscountPercentageVM> {
    constructor() {
        super("Kiosk.DiscountPercentageVM", [
            { no: 1, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountTypesEnum.DiscountTypes", SetupDiscountTypesEnum_DiscountTypes] },
            { no: 2, name: "percent", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<DiscountPercentageVM>): DiscountPercentageVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.kind = 0;
        message.percent = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountPercentageVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountPercentageVM): DiscountPercentageVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.SetupDiscountTypesEnum.DiscountTypes kind */ 1:
                    message.kind = reader.int32();
                    break;
                case /* double percent */ 2:
                    message.percent = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountPercentageVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.SetupDiscountTypesEnum.DiscountTypes kind = 1; */
        if (message.kind !== 0)
            writer.tag(1, WireType.Varint).int32(message.kind);
        /* double percent = 2; */
        if (message.percent !== 0)
            writer.tag(2, WireType.Bit64).double(message.percent);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountPercentageVM
 */
export const DiscountPercentageVM = new DiscountPercentageVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountPriceOverrideVM$Type extends MessageType<DiscountPriceOverrideVM> {
    constructor() {
        super("Kiosk.DiscountPriceOverrideVM", [
            { no: 1, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountTypesEnum.DiscountTypes", SetupDiscountTypesEnum_DiscountTypes] },
            { no: 2, name: "overridePrice", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<DiscountPriceOverrideVM>): DiscountPriceOverrideVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.kind = 0;
        message.overridePrice = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountPriceOverrideVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountPriceOverrideVM): DiscountPriceOverrideVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.SetupDiscountTypesEnum.DiscountTypes kind */ 1:
                    message.kind = reader.int32();
                    break;
                case /* double overridePrice */ 2:
                    message.overridePrice = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountPriceOverrideVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.SetupDiscountTypesEnum.DiscountTypes kind = 1; */
        if (message.kind !== 0)
            writer.tag(1, WireType.Varint).int32(message.kind);
        /* double overridePrice = 2; */
        if (message.overridePrice !== 0)
            writer.tag(2, WireType.Bit64).double(message.overridePrice);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountPriceOverrideVM
 */
export const DiscountPriceOverrideVM = new DiscountPriceOverrideVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountCartIncludesItemConditionVM$Type extends MessageType<DiscountCartIncludesItemConditionVM> {
    constructor() {
        super("Kiosk.DiscountCartIncludesItemConditionVM", [
            { no: 1, name: "itemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum", SetupDiscountConditionEnum_DiscountConditionEnum] },
            { no: 3, name: "quantity", kind: "message", T: () => Int32Value }
        ]);
    }
    create(value?: PartialMessage<DiscountCartIncludesItemConditionVM>): DiscountCartIncludesItemConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.itemId = "";
        message.kind = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountCartIncludesItemConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountCartIncludesItemConditionVM): DiscountCartIncludesItemConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string itemId */ 1:
                    message.itemId = reader.string();
                    break;
                case /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind */ 2:
                    message.kind = reader.int32();
                    break;
                case /* google.protobuf.Int32Value quantity */ 3:
                    message.quantity = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.quantity);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountCartIncludesItemConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string itemId = 1; */
        if (message.itemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.itemId);
        /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2; */
        if (message.kind !== 0)
            writer.tag(2, WireType.Varint).int32(message.kind);
        /* google.protobuf.Int32Value quantity = 3; */
        if (message.quantity)
            Int32Value.internalBinaryWrite(message.quantity, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountCartIncludesItemConditionVM
 */
export const DiscountCartIncludesItemConditionVM = new DiscountCartIncludesItemConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountCartIncludesCategoryConditionVM$Type extends MessageType<DiscountCartIncludesCategoryConditionVM> {
    constructor() {
        super("Kiosk.DiscountCartIncludesCategoryConditionVM", [
            { no: 1, name: "categoryId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum", SetupDiscountConditionEnum_DiscountConditionEnum] },
            { no: 3, name: "quantity", kind: "message", T: () => Int32Value }
        ]);
    }
    create(value?: PartialMessage<DiscountCartIncludesCategoryConditionVM>): DiscountCartIncludesCategoryConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.categoryId = "";
        message.kind = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountCartIncludesCategoryConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountCartIncludesCategoryConditionVM): DiscountCartIncludesCategoryConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string categoryId */ 1:
                    message.categoryId = reader.string();
                    break;
                case /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind */ 2:
                    message.kind = reader.int32();
                    break;
                case /* google.protobuf.Int32Value quantity */ 3:
                    message.quantity = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.quantity);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountCartIncludesCategoryConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string categoryId = 1; */
        if (message.categoryId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.categoryId);
        /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2; */
        if (message.kind !== 0)
            writer.tag(2, WireType.Varint).int32(message.kind);
        /* google.protobuf.Int32Value quantity = 3; */
        if (message.quantity)
            Int32Value.internalBinaryWrite(message.quantity, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountCartIncludesCategoryConditionVM
 */
export const DiscountCartIncludesCategoryConditionVM = new DiscountCartIncludesCategoryConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountItemsToOfferConditionVM$Type extends MessageType<DiscountItemsToOfferConditionVM> {
    constructor() {
        super("Kiosk.DiscountItemsToOfferConditionVM", [
            { no: 1, name: "itemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum", SetupDiscountConditionEnum_DiscountConditionEnum] },
            { no: 3, name: "quantity", kind: "message", T: () => Int32Value }
        ]);
    }
    create(value?: PartialMessage<DiscountItemsToOfferConditionVM>): DiscountItemsToOfferConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.itemId = "";
        message.kind = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountItemsToOfferConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountItemsToOfferConditionVM): DiscountItemsToOfferConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string itemId */ 1:
                    message.itemId = reader.string();
                    break;
                case /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind */ 2:
                    message.kind = reader.int32();
                    break;
                case /* google.protobuf.Int32Value quantity */ 3:
                    message.quantity = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.quantity);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountItemsToOfferConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string itemId = 1; */
        if (message.itemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.itemId);
        /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2; */
        if (message.kind !== 0)
            writer.tag(2, WireType.Varint).int32(message.kind);
        /* google.protobuf.Int32Value quantity = 3; */
        if (message.quantity)
            Int32Value.internalBinaryWrite(message.quantity, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountItemsToOfferConditionVM
 */
export const DiscountItemsToOfferConditionVM = new DiscountItemsToOfferConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountCategoryToOfferConditionVM$Type extends MessageType<DiscountCategoryToOfferConditionVM> {
    constructor() {
        super("Kiosk.DiscountCategoryToOfferConditionVM", [
            { no: 1, name: "categoryId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum", SetupDiscountConditionEnum_DiscountConditionEnum] }
        ]);
    }
    create(value?: PartialMessage<DiscountCategoryToOfferConditionVM>): DiscountCategoryToOfferConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.categoryId = "";
        message.kind = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountCategoryToOfferConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountCategoryToOfferConditionVM): DiscountCategoryToOfferConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string categoryId */ 1:
                    message.categoryId = reader.string();
                    break;
                case /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind */ 2:
                    message.kind = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountCategoryToOfferConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string categoryId = 1; */
        if (message.categoryId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.categoryId);
        /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2; */
        if (message.kind !== 0)
            writer.tag(2, WireType.Varint).int32(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountCategoryToOfferConditionVM
 */
export const DiscountCategoryToOfferConditionVM = new DiscountCategoryToOfferConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountCartIncludesDiscountConditionVM$Type extends MessageType<DiscountCartIncludesDiscountConditionVM> {
    constructor() {
        super("Kiosk.DiscountCartIncludesDiscountConditionVM", [
            { no: 1, name: "discountId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "redeemableLimit", kind: "scalar", opt: true, T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum", SetupDiscountConditionEnum_DiscountConditionEnum] }
        ]);
    }
    create(value?: PartialMessage<DiscountCartIncludesDiscountConditionVM>): DiscountCartIncludesDiscountConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.discountId = "";
        message.kind = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountCartIncludesDiscountConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountCartIncludesDiscountConditionVM): DiscountCartIncludesDiscountConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string discountId */ 1:
                    message.discountId = reader.string();
                    break;
                case /* optional double redeemableLimit */ 2:
                    message.redeemableLimit = reader.double();
                    break;
                case /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind */ 3:
                    message.kind = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountCartIncludesDiscountConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string discountId = 1; */
        if (message.discountId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.discountId);
        /* optional double redeemableLimit = 2; */
        if (message.redeemableLimit !== undefined)
            writer.tag(2, WireType.Bit64).double(message.redeemableLimit);
        /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 3; */
        if (message.kind !== 0)
            writer.tag(3, WireType.Varint).int32(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountCartIncludesDiscountConditionVM
 */
export const DiscountCartIncludesDiscountConditionVM = new DiscountCartIncludesDiscountConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountMinimumSubtotalConditionVM$Type extends MessageType<DiscountMinimumSubtotalConditionVM> {
    constructor() {
        super("Kiosk.DiscountMinimumSubtotalConditionVM", [
            { no: 1, name: "minimumSubtotal", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum", SetupDiscountConditionEnum_DiscountConditionEnum] }
        ]);
    }
    create(value?: PartialMessage<DiscountMinimumSubtotalConditionVM>): DiscountMinimumSubtotalConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.minimumSubtotal = 0;
        message.kind = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountMinimumSubtotalConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountMinimumSubtotalConditionVM): DiscountMinimumSubtotalConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double minimumSubtotal */ 1:
                    message.minimumSubtotal = reader.double();
                    break;
                case /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind */ 2:
                    message.kind = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountMinimumSubtotalConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double minimumSubtotal = 1; */
        if (message.minimumSubtotal !== 0)
            writer.tag(1, WireType.Bit64).double(message.minimumSubtotal);
        /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2; */
        if (message.kind !== 0)
            writer.tag(2, WireType.Varint).int32(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountMinimumSubtotalConditionVM
 */
export const DiscountMinimumSubtotalConditionVM = new DiscountMinimumSubtotalConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountDayOfWeekConditionVM$Type extends MessageType<DiscountDayOfWeekConditionVM> {
    constructor() {
        super("Kiosk.DiscountDayOfWeekConditionVM", [
            { no: 1, name: "dayOfWeek", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum", SetupDiscountConditionEnum_DiscountConditionEnum] }
        ]);
    }
    create(value?: PartialMessage<DiscountDayOfWeekConditionVM>): DiscountDayOfWeekConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.dayOfWeek = 0;
        message.kind = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountDayOfWeekConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountDayOfWeekConditionVM): DiscountDayOfWeekConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 dayOfWeek */ 1:
                    message.dayOfWeek = reader.int32();
                    break;
                case /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind */ 2:
                    message.kind = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountDayOfWeekConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 dayOfWeek = 1; */
        if (message.dayOfWeek !== 0)
            writer.tag(1, WireType.Varint).int32(message.dayOfWeek);
        /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2; */
        if (message.kind !== 0)
            writer.tag(2, WireType.Varint).int32(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountDayOfWeekConditionVM
 */
export const DiscountDayOfWeekConditionVM = new DiscountDayOfWeekConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountOrderTimeConditionVM$Type extends MessageType<DiscountOrderTimeConditionVM> {
    constructor() {
        super("Kiosk.DiscountOrderTimeConditionVM", [
            { no: 1, name: "startHour", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "startMinutes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "endHour", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "endMinutes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum", SetupDiscountConditionEnum_DiscountConditionEnum] }
        ]);
    }
    create(value?: PartialMessage<DiscountOrderTimeConditionVM>): DiscountOrderTimeConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startHour = 0;
        message.startMinutes = 0;
        message.endHour = 0;
        message.endMinutes = 0;
        message.kind = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountOrderTimeConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountOrderTimeConditionVM): DiscountOrderTimeConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 startHour */ 1:
                    message.startHour = reader.int32();
                    break;
                case /* int32 startMinutes */ 2:
                    message.startMinutes = reader.int32();
                    break;
                case /* int32 endHour */ 3:
                    message.endHour = reader.int32();
                    break;
                case /* int32 endMinutes */ 4:
                    message.endMinutes = reader.int32();
                    break;
                case /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind */ 5:
                    message.kind = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountOrderTimeConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 startHour = 1; */
        if (message.startHour !== 0)
            writer.tag(1, WireType.Varint).int32(message.startHour);
        /* int32 startMinutes = 2; */
        if (message.startMinutes !== 0)
            writer.tag(2, WireType.Varint).int32(message.startMinutes);
        /* int32 endHour = 3; */
        if (message.endHour !== 0)
            writer.tag(3, WireType.Varint).int32(message.endHour);
        /* int32 endMinutes = 4; */
        if (message.endMinutes !== 0)
            writer.tag(4, WireType.Varint).int32(message.endMinutes);
        /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 5; */
        if (message.kind !== 0)
            writer.tag(5, WireType.Varint).int32(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountOrderTimeConditionVM
 */
export const DiscountOrderTimeConditionVM = new DiscountOrderTimeConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountAndConditionVM$Type extends MessageType<DiscountAndConditionVM> {
    constructor() {
        super("Kiosk.DiscountAndConditionVM", [
            { no: 1, name: "and", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DiscountConditionVM },
            { no: 2, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum", SetupDiscountConditionEnum_DiscountConditionEnum] }
        ]);
    }
    create(value?: PartialMessage<DiscountAndConditionVM>): DiscountAndConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.and = [];
        message.kind = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountAndConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountAndConditionVM): DiscountAndConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.DiscountConditionVM and */ 1:
                    message.and.push(DiscountConditionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind */ 2:
                    message.kind = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountAndConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.DiscountConditionVM and = 1; */
        for (let i = 0; i < message.and.length; i++)
            DiscountConditionVM.internalBinaryWrite(message.and[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2; */
        if (message.kind !== 0)
            writer.tag(2, WireType.Varint).int32(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountAndConditionVM
 */
export const DiscountAndConditionVM = new DiscountAndConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountOrConditionVM$Type extends MessageType<DiscountOrConditionVM> {
    constructor() {
        super("Kiosk.DiscountOrConditionVM", [
            { no: 1, name: "or", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DiscountConditionVM },
            { no: 2, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum", SetupDiscountConditionEnum_DiscountConditionEnum] }
        ]);
    }
    create(value?: PartialMessage<DiscountOrConditionVM>): DiscountOrConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.or = [];
        message.kind = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountOrConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountOrConditionVM): DiscountOrConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.DiscountConditionVM or */ 1:
                    message.or.push(DiscountConditionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind */ 2:
                    message.kind = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountOrConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.DiscountConditionVM or = 1; */
        for (let i = 0; i < message.or.length; i++)
            DiscountConditionVM.internalBinaryWrite(message.or[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2; */
        if (message.kind !== 0)
            writer.tag(2, WireType.Varint).int32(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountOrConditionVM
 */
export const DiscountOrConditionVM = new DiscountOrConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountNotConditionVM$Type extends MessageType<DiscountNotConditionVM> {
    constructor() {
        super("Kiosk.DiscountNotConditionVM", [
            { no: 1, name: "not", kind: "message", T: () => DiscountConditionVM },
            { no: 2, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum", SetupDiscountConditionEnum_DiscountConditionEnum] }
        ]);
    }
    create(value?: PartialMessage<DiscountNotConditionVM>): DiscountNotConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.kind = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountNotConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountNotConditionVM): DiscountNotConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.DiscountConditionVM not */ 1:
                    message.not = DiscountConditionVM.internalBinaryRead(reader, reader.uint32(), options, message.not);
                    break;
                case /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind */ 2:
                    message.kind = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountNotConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.DiscountConditionVM not = 1; */
        if (message.not)
            DiscountConditionVM.internalBinaryWrite(message.not, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2; */
        if (message.kind !== 0)
            writer.tag(2, WireType.Varint).int32(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountNotConditionVM
 */
export const DiscountNotConditionVM = new DiscountNotConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountSingleConditionVM$Type extends MessageType<DiscountSingleConditionVM> {
    constructor() {
        super("Kiosk.DiscountSingleConditionVM", [
            { no: 1, name: "single", kind: "message", T: () => DiscountConditionVM },
            { no: 2, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum", SetupDiscountConditionEnum_DiscountConditionEnum] }
        ]);
    }
    create(value?: PartialMessage<DiscountSingleConditionVM>): DiscountSingleConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.kind = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountSingleConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountSingleConditionVM): DiscountSingleConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.DiscountConditionVM single */ 1:
                    message.single = DiscountConditionVM.internalBinaryRead(reader, reader.uint32(), options, message.single);
                    break;
                case /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind */ 2:
                    message.kind = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountSingleConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.DiscountConditionVM single = 1; */
        if (message.single)
            DiscountConditionVM.internalBinaryWrite(message.single, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2; */
        if (message.kind !== 0)
            writer.tag(2, WireType.Varint).int32(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountSingleConditionVM
 */
export const DiscountSingleConditionVM = new DiscountSingleConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountQualifiedItemsConditionVM$Type extends MessageType<DiscountQualifiedItemsConditionVM> {
    constructor() {
        super("Kiosk.DiscountQualifiedItemsConditionVM", [
            { no: 1, name: "and", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DiscountConditionVM },
            { no: 2, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum", SetupDiscountConditionEnum_DiscountConditionEnum] }
        ]);
    }
    create(value?: PartialMessage<DiscountQualifiedItemsConditionVM>): DiscountQualifiedItemsConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.and = [];
        message.kind = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountQualifiedItemsConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountQualifiedItemsConditionVM): DiscountQualifiedItemsConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.DiscountConditionVM and */ 1:
                    message.and.push(DiscountConditionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind */ 2:
                    message.kind = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountQualifiedItemsConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.DiscountConditionVM and = 1; */
        for (let i = 0; i < message.and.length; i++)
            DiscountConditionVM.internalBinaryWrite(message.and[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 2; */
        if (message.kind !== 0)
            writer.tag(2, WireType.Varint).int32(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountQualifiedItemsConditionVM
 */
export const DiscountQualifiedItemsConditionVM = new DiscountQualifiedItemsConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountDayAndTimeConditionVM$Type extends MessageType<DiscountDayAndTimeConditionVM> {
    constructor() {
        super("Kiosk.DiscountDayAndTimeConditionVM", [
            { no: 1, name: "daysOfWeek", kind: "message", T: () => DiscountDayOfWeekConditionVM },
            { no: 2, name: "orderTimes", kind: "message", T: () => DiscountOrderTimeConditionVM },
            { no: 3, name: "kind", kind: "enum", T: () => ["Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum", SetupDiscountConditionEnum_DiscountConditionEnum] }
        ]);
    }
    create(value?: PartialMessage<DiscountDayAndTimeConditionVM>): DiscountDayAndTimeConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.kind = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountDayAndTimeConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountDayAndTimeConditionVM): DiscountDayAndTimeConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.DiscountDayOfWeekConditionVM daysOfWeek */ 1:
                    message.daysOfWeek = DiscountDayOfWeekConditionVM.internalBinaryRead(reader, reader.uint32(), options, message.daysOfWeek);
                    break;
                case /* Kiosk.DiscountOrderTimeConditionVM orderTimes */ 2:
                    message.orderTimes = DiscountOrderTimeConditionVM.internalBinaryRead(reader, reader.uint32(), options, message.orderTimes);
                    break;
                case /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind */ 3:
                    message.kind = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountDayAndTimeConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.DiscountDayOfWeekConditionVM daysOfWeek = 1; */
        if (message.daysOfWeek)
            DiscountDayOfWeekConditionVM.internalBinaryWrite(message.daysOfWeek, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountOrderTimeConditionVM orderTimes = 2; */
        if (message.orderTimes)
            DiscountOrderTimeConditionVM.internalBinaryWrite(message.orderTimes, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.SetupDiscountConditionEnum.DiscountConditionEnum kind = 3; */
        if (message.kind !== 0)
            writer.tag(3, WireType.Varint).int32(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountDayAndTimeConditionVM
 */
export const DiscountDayAndTimeConditionVM = new DiscountDayAndTimeConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountConditionVM$Type extends MessageType<DiscountConditionVM> {
    constructor() {
        super("Kiosk.DiscountConditionVM", [
            { no: 1, name: "cartIncludesItem", kind: "message", oneof: "condition", T: () => DiscountCartIncludesItemConditionVM },
            { no: 2, name: "cartIncludesDiscount", kind: "message", oneof: "condition", T: () => DiscountCartIncludesDiscountConditionVM },
            { no: 3, name: "minimumSubtotal", kind: "message", oneof: "condition", T: () => DiscountMinimumSubtotalConditionVM },
            { no: 4, name: "dayOfWeek", kind: "message", oneof: "condition", T: () => DiscountDayOfWeekConditionVM },
            { no: 5, name: "orderTime", kind: "message", oneof: "condition", T: () => DiscountOrderTimeConditionVM },
            { no: 6, name: "and", kind: "message", oneof: "condition", T: () => DiscountAndConditionVM },
            { no: 7, name: "or", kind: "message", oneof: "condition", T: () => DiscountOrConditionVM },
            { no: 8, name: "not", kind: "message", oneof: "condition", T: () => DiscountNotConditionVM },
            { no: 9, name: "single", kind: "message", oneof: "condition", T: () => DiscountSingleConditionVM },
            { no: 10, name: "canOfferItem", kind: "message", oneof: "condition", T: () => DiscountItemsToOfferConditionVM },
            { no: 11, name: "cartIncludesCategory", kind: "message", oneof: "condition", T: () => DiscountCartIncludesCategoryConditionVM },
            { no: 12, name: "qualifiedItems", kind: "message", oneof: "condition", T: () => DiscountQualifiedItemsConditionVM },
            { no: 13, name: "dayAndTime", kind: "message", oneof: "condition", T: () => DiscountDayAndTimeConditionVM },
            { no: 14, name: "canOfferCategory", kind: "message", oneof: "condition", T: () => DiscountCategoryToOfferConditionVM }
        ]);
    }
    create(value?: PartialMessage<DiscountConditionVM>): DiscountConditionVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.condition = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<DiscountConditionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountConditionVM): DiscountConditionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.DiscountCartIncludesItemConditionVM cartIncludesItem */ 1:
                    message.condition = {
                        oneofKind: "cartIncludesItem",
                        cartIncludesItem: DiscountCartIncludesItemConditionVM.internalBinaryRead(reader, reader.uint32(), options, (message.condition as any).cartIncludesItem)
                    };
                    break;
                case /* Kiosk.DiscountCartIncludesDiscountConditionVM cartIncludesDiscount */ 2:
                    message.condition = {
                        oneofKind: "cartIncludesDiscount",
                        cartIncludesDiscount: DiscountCartIncludesDiscountConditionVM.internalBinaryRead(reader, reader.uint32(), options, (message.condition as any).cartIncludesDiscount)
                    };
                    break;
                case /* Kiosk.DiscountMinimumSubtotalConditionVM minimumSubtotal */ 3:
                    message.condition = {
                        oneofKind: "minimumSubtotal",
                        minimumSubtotal: DiscountMinimumSubtotalConditionVM.internalBinaryRead(reader, reader.uint32(), options, (message.condition as any).minimumSubtotal)
                    };
                    break;
                case /* Kiosk.DiscountDayOfWeekConditionVM dayOfWeek */ 4:
                    message.condition = {
                        oneofKind: "dayOfWeek",
                        dayOfWeek: DiscountDayOfWeekConditionVM.internalBinaryRead(reader, reader.uint32(), options, (message.condition as any).dayOfWeek)
                    };
                    break;
                case /* Kiosk.DiscountOrderTimeConditionVM orderTime */ 5:
                    message.condition = {
                        oneofKind: "orderTime",
                        orderTime: DiscountOrderTimeConditionVM.internalBinaryRead(reader, reader.uint32(), options, (message.condition as any).orderTime)
                    };
                    break;
                case /* Kiosk.DiscountAndConditionVM and */ 6:
                    message.condition = {
                        oneofKind: "and",
                        and: DiscountAndConditionVM.internalBinaryRead(reader, reader.uint32(), options, (message.condition as any).and)
                    };
                    break;
                case /* Kiosk.DiscountOrConditionVM or */ 7:
                    message.condition = {
                        oneofKind: "or",
                        or: DiscountOrConditionVM.internalBinaryRead(reader, reader.uint32(), options, (message.condition as any).or)
                    };
                    break;
                case /* Kiosk.DiscountNotConditionVM not */ 8:
                    message.condition = {
                        oneofKind: "not",
                        not: DiscountNotConditionVM.internalBinaryRead(reader, reader.uint32(), options, (message.condition as any).not)
                    };
                    break;
                case /* Kiosk.DiscountSingleConditionVM single */ 9:
                    message.condition = {
                        oneofKind: "single",
                        single: DiscountSingleConditionVM.internalBinaryRead(reader, reader.uint32(), options, (message.condition as any).single)
                    };
                    break;
                case /* Kiosk.DiscountItemsToOfferConditionVM canOfferItem */ 10:
                    message.condition = {
                        oneofKind: "canOfferItem",
                        canOfferItem: DiscountItemsToOfferConditionVM.internalBinaryRead(reader, reader.uint32(), options, (message.condition as any).canOfferItem)
                    };
                    break;
                case /* Kiosk.DiscountCartIncludesCategoryConditionVM cartIncludesCategory */ 11:
                    message.condition = {
                        oneofKind: "cartIncludesCategory",
                        cartIncludesCategory: DiscountCartIncludesCategoryConditionVM.internalBinaryRead(reader, reader.uint32(), options, (message.condition as any).cartIncludesCategory)
                    };
                    break;
                case /* Kiosk.DiscountQualifiedItemsConditionVM qualifiedItems */ 12:
                    message.condition = {
                        oneofKind: "qualifiedItems",
                        qualifiedItems: DiscountQualifiedItemsConditionVM.internalBinaryRead(reader, reader.uint32(), options, (message.condition as any).qualifiedItems)
                    };
                    break;
                case /* Kiosk.DiscountDayAndTimeConditionVM dayAndTime */ 13:
                    message.condition = {
                        oneofKind: "dayAndTime",
                        dayAndTime: DiscountDayAndTimeConditionVM.internalBinaryRead(reader, reader.uint32(), options, (message.condition as any).dayAndTime)
                    };
                    break;
                case /* Kiosk.DiscountCategoryToOfferConditionVM canOfferCategory */ 14:
                    message.condition = {
                        oneofKind: "canOfferCategory",
                        canOfferCategory: DiscountCategoryToOfferConditionVM.internalBinaryRead(reader, reader.uint32(), options, (message.condition as any).canOfferCategory)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountConditionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.DiscountCartIncludesItemConditionVM cartIncludesItem = 1; */
        if (message.condition.oneofKind === "cartIncludesItem")
            DiscountCartIncludesItemConditionVM.internalBinaryWrite(message.condition.cartIncludesItem, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountCartIncludesDiscountConditionVM cartIncludesDiscount = 2; */
        if (message.condition.oneofKind === "cartIncludesDiscount")
            DiscountCartIncludesDiscountConditionVM.internalBinaryWrite(message.condition.cartIncludesDiscount, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountMinimumSubtotalConditionVM minimumSubtotal = 3; */
        if (message.condition.oneofKind === "minimumSubtotal")
            DiscountMinimumSubtotalConditionVM.internalBinaryWrite(message.condition.minimumSubtotal, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountDayOfWeekConditionVM dayOfWeek = 4; */
        if (message.condition.oneofKind === "dayOfWeek")
            DiscountDayOfWeekConditionVM.internalBinaryWrite(message.condition.dayOfWeek, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountOrderTimeConditionVM orderTime = 5; */
        if (message.condition.oneofKind === "orderTime")
            DiscountOrderTimeConditionVM.internalBinaryWrite(message.condition.orderTime, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountAndConditionVM and = 6; */
        if (message.condition.oneofKind === "and")
            DiscountAndConditionVM.internalBinaryWrite(message.condition.and, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountOrConditionVM or = 7; */
        if (message.condition.oneofKind === "or")
            DiscountOrConditionVM.internalBinaryWrite(message.condition.or, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountNotConditionVM not = 8; */
        if (message.condition.oneofKind === "not")
            DiscountNotConditionVM.internalBinaryWrite(message.condition.not, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountSingleConditionVM single = 9; */
        if (message.condition.oneofKind === "single")
            DiscountSingleConditionVM.internalBinaryWrite(message.condition.single, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountItemsToOfferConditionVM canOfferItem = 10; */
        if (message.condition.oneofKind === "canOfferItem")
            DiscountItemsToOfferConditionVM.internalBinaryWrite(message.condition.canOfferItem, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountCartIncludesCategoryConditionVM cartIncludesCategory = 11; */
        if (message.condition.oneofKind === "cartIncludesCategory")
            DiscountCartIncludesCategoryConditionVM.internalBinaryWrite(message.condition.cartIncludesCategory, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountQualifiedItemsConditionVM qualifiedItems = 12; */
        if (message.condition.oneofKind === "qualifiedItems")
            DiscountQualifiedItemsConditionVM.internalBinaryWrite(message.condition.qualifiedItems, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountDayAndTimeConditionVM dayAndTime = 13; */
        if (message.condition.oneofKind === "dayAndTime")
            DiscountDayAndTimeConditionVM.internalBinaryWrite(message.condition.dayAndTime, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountCategoryToOfferConditionVM canOfferCategory = 14; */
        if (message.condition.oneofKind === "canOfferCategory")
            DiscountCategoryToOfferConditionVM.internalBinaryWrite(message.condition.canOfferCategory, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountConditionVM
 */
export const DiscountConditionVM = new DiscountConditionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountOrderScope$Type extends MessageType<DiscountOrderScope> {
    constructor() {
        super("Kiosk.DiscountOrderScope", []);
    }
    create(value?: PartialMessage<DiscountOrderScope>): DiscountOrderScope {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DiscountOrderScope>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountOrderScope): DiscountOrderScope {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DiscountOrderScope, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountOrderScope
 */
export const DiscountOrderScope = new DiscountOrderScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountSingleItemScope$Type extends MessageType<DiscountSingleItemScope> {
    constructor() {
        super("Kiosk.DiscountSingleItemScope", [
            { no: 1, name: "menuItemIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "comboIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "categoryIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DiscountSingleItemScope>): DiscountSingleItemScope {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.menuItemIds = [];
        message.comboIds = [];
        message.categoryIds = [];
        if (value !== undefined)
            reflectionMergePartial<DiscountSingleItemScope>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountSingleItemScope): DiscountSingleItemScope {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string menuItemIds */ 1:
                    message.menuItemIds.push(reader.string());
                    break;
                case /* repeated string comboIds */ 2:
                    message.comboIds.push(reader.string());
                    break;
                case /* repeated string categoryIds */ 3:
                    message.categoryIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountSingleItemScope, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string menuItemIds = 1; */
        for (let i = 0; i < message.menuItemIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.menuItemIds[i]);
        /* repeated string comboIds = 2; */
        for (let i = 0; i < message.comboIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.comboIds[i]);
        /* repeated string categoryIds = 3; */
        for (let i = 0; i < message.categoryIds.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.categoryIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountSingleItemScope
 */
export const DiscountSingleItemScope = new DiscountSingleItemScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountMultipleItemScope$Type extends MessageType<DiscountMultipleItemScope> {
    constructor() {
        super("Kiosk.DiscountMultipleItemScope", [
            { no: 1, name: "maxDiscountedItems", kind: "message", T: () => Int32Value },
            { no: 2, name: "menuItemIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "comboIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "categoryIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DiscountMultipleItemScope>): DiscountMultipleItemScope {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.menuItemIds = [];
        message.comboIds = [];
        message.categoryIds = [];
        if (value !== undefined)
            reflectionMergePartial<DiscountMultipleItemScope>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountMultipleItemScope): DiscountMultipleItemScope {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Int32Value maxDiscountedItems */ 1:
                    message.maxDiscountedItems = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.maxDiscountedItems);
                    break;
                case /* repeated string menuItemIds */ 2:
                    message.menuItemIds.push(reader.string());
                    break;
                case /* repeated string comboIds */ 3:
                    message.comboIds.push(reader.string());
                    break;
                case /* repeated string categoryIds */ 4:
                    message.categoryIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountMultipleItemScope, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Int32Value maxDiscountedItems = 1; */
        if (message.maxDiscountedItems)
            Int32Value.internalBinaryWrite(message.maxDiscountedItems, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string menuItemIds = 2; */
        for (let i = 0; i < message.menuItemIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.menuItemIds[i]);
        /* repeated string comboIds = 3; */
        for (let i = 0; i < message.comboIds.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.comboIds[i]);
        /* repeated string categoryIds = 4; */
        for (let i = 0; i < message.categoryIds.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.categoryIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountMultipleItemScope
 */
export const DiscountMultipleItemScope = new DiscountMultipleItemScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountScope$Type extends MessageType<DiscountScope> {
    constructor() {
        super("Kiosk.DiscountScope", [
            { no: 1, name: "order", kind: "message", oneof: "scope", T: () => DiscountOrderScope },
            { no: 2, name: "singleItem", kind: "message", oneof: "scope", T: () => DiscountSingleItemScope },
            { no: 3, name: "multipleItems", kind: "message", oneof: "scope", T: () => DiscountMultipleItemScope }
        ]);
    }
    create(value?: PartialMessage<DiscountScope>): DiscountScope {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.scope = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<DiscountScope>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountScope): DiscountScope {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.DiscountOrderScope order */ 1:
                    message.scope = {
                        oneofKind: "order",
                        order: DiscountOrderScope.internalBinaryRead(reader, reader.uint32(), options, (message.scope as any).order)
                    };
                    break;
                case /* Kiosk.DiscountSingleItemScope singleItem */ 2:
                    message.scope = {
                        oneofKind: "singleItem",
                        singleItem: DiscountSingleItemScope.internalBinaryRead(reader, reader.uint32(), options, (message.scope as any).singleItem)
                    };
                    break;
                case /* Kiosk.DiscountMultipleItemScope multipleItems */ 3:
                    message.scope = {
                        oneofKind: "multipleItems",
                        multipleItems: DiscountMultipleItemScope.internalBinaryRead(reader, reader.uint32(), options, (message.scope as any).multipleItems)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountScope, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.DiscountOrderScope order = 1; */
        if (message.scope.oneofKind === "order")
            DiscountOrderScope.internalBinaryWrite(message.scope.order, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountSingleItemScope singleItem = 2; */
        if (message.scope.oneofKind === "singleItem")
            DiscountSingleItemScope.internalBinaryWrite(message.scope.singleItem, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountMultipleItemScope multipleItems = 3; */
        if (message.scope.oneofKind === "multipleItems")
            DiscountMultipleItemScope.internalBinaryWrite(message.scope.multipleItems, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountScope
 */
export const DiscountScope = new DiscountScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountBaseVM$Type extends MessageType<DiscountBaseVM> {
    constructor() {
        super("Kiosk.DiscountBaseVM", [
            { no: 1, name: "Id", kind: "scalar", jsonName: "Id", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Name", kind: "scalar", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "DisplayName", kind: "scalar", jsonName: "DisplayName", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "ShortDescription", kind: "scalar", jsonName: "ShortDescription", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "freeItem", kind: "message", oneof: "discountType", T: () => DiscountFreeItemVM },
            { no: 6, name: "fixedAmount", kind: "message", oneof: "discountType", T: () => DiscountFixedAmountVM },
            { no: 7, name: "percentage", kind: "message", oneof: "discountType", T: () => DiscountPercentageVM },
            { no: 19, name: "pricerOverride", kind: "message", oneof: "discountType", T: () => DiscountPriceOverrideVM },
            { no: 8, name: "POSDiscountId", kind: "scalar", jsonName: "POSDiscountId", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "StartDate", kind: "message", jsonName: "StartDate", T: () => Timestamp },
            { no: 10, name: "EndDate", kind: "message", jsonName: "EndDate", T: () => Timestamp },
            { no: 11, name: "Enabled", kind: "scalar", jsonName: "Enabled", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "Mode", kind: "enum", jsonName: "Mode", T: () => ["Kiosk.SetupDiscountApplicationModeEnum.ApplicationMode", SetupDiscountApplicationModeEnum_ApplicationMode] },
            { no: 14, name: "DiscountImageUrl", kind: "scalar", jsonName: "DiscountImageUrl", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "DiscountCode", kind: "scalar", jsonName: "DiscountCode", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "DiscountRate", kind: "scalar", jsonName: "DiscountRate", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 18, name: "Condition", kind: "message", jsonName: "Condition", T: () => DiscountConditionVM },
            { no: 21, name: "maxApplicationsPerOrder", kind: "message", T: () => Int32Value },
            { no: 22, name: "scope", kind: "message", T: () => DiscountScope }
        ]);
    }
    create(value?: PartialMessage<DiscountBaseVM>): DiscountBaseVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.displayName = "";
        message.shortDescription = "";
        message.discountType = { oneofKind: undefined };
        message.pOSDiscountId = "";
        message.enabled = false;
        message.mode = 0;
        message.discountImageUrl = "";
        message.discountRate = 0;
        if (value !== undefined)
            reflectionMergePartial<DiscountBaseVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountBaseVM): DiscountBaseVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Id = 1 [json_name = "Id"];*/ 1:
                    message.id = reader.string();
                    break;
                case /* string Name = 2 [json_name = "Name"];*/ 2:
                    message.name = reader.string();
                    break;
                case /* string DisplayName = 3 [json_name = "DisplayName"];*/ 3:
                    message.displayName = reader.string();
                    break;
                case /* string ShortDescription = 4 [json_name = "ShortDescription"];*/ 4:
                    message.shortDescription = reader.string();
                    break;
                case /* Kiosk.DiscountFreeItemVM freeItem */ 5:
                    message.discountType = {
                        oneofKind: "freeItem",
                        freeItem: DiscountFreeItemVM.internalBinaryRead(reader, reader.uint32(), options, (message.discountType as any).freeItem)
                    };
                    break;
                case /* Kiosk.DiscountFixedAmountVM fixedAmount */ 6:
                    message.discountType = {
                        oneofKind: "fixedAmount",
                        fixedAmount: DiscountFixedAmountVM.internalBinaryRead(reader, reader.uint32(), options, (message.discountType as any).fixedAmount)
                    };
                    break;
                case /* Kiosk.DiscountPercentageVM percentage */ 7:
                    message.discountType = {
                        oneofKind: "percentage",
                        percentage: DiscountPercentageVM.internalBinaryRead(reader, reader.uint32(), options, (message.discountType as any).percentage)
                    };
                    break;
                case /* Kiosk.DiscountPriceOverrideVM pricerOverride */ 19:
                    message.discountType = {
                        oneofKind: "pricerOverride",
                        pricerOverride: DiscountPriceOverrideVM.internalBinaryRead(reader, reader.uint32(), options, (message.discountType as any).pricerOverride)
                    };
                    break;
                case /* string POSDiscountId = 8 [json_name = "POSDiscountId"];*/ 8:
                    message.pOSDiscountId = reader.string();
                    break;
                case /* optional google.protobuf.Timestamp StartDate = 9 [json_name = "StartDate"];*/ 9:
                    message.startDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.startDate);
                    break;
                case /* optional google.protobuf.Timestamp EndDate = 10 [json_name = "EndDate"];*/ 10:
                    message.endDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.endDate);
                    break;
                case /* bool Enabled = 11 [json_name = "Enabled"];*/ 11:
                    message.enabled = reader.bool();
                    break;
                case /* Kiosk.SetupDiscountApplicationModeEnum.ApplicationMode Mode = 13 [json_name = "Mode"];*/ 13:
                    message.mode = reader.int32();
                    break;
                case /* string DiscountImageUrl = 14 [json_name = "DiscountImageUrl"];*/ 14:
                    message.discountImageUrl = reader.string();
                    break;
                case /* optional string DiscountCode = 15 [json_name = "DiscountCode"];*/ 15:
                    message.discountCode = reader.string();
                    break;
                case /* double DiscountRate = 16 [json_name = "DiscountRate"];*/ 16:
                    message.discountRate = reader.double();
                    break;
                case /* Kiosk.DiscountConditionVM Condition = 18 [json_name = "Condition"];*/ 18:
                    message.condition = DiscountConditionVM.internalBinaryRead(reader, reader.uint32(), options, message.condition);
                    break;
                case /* google.protobuf.Int32Value maxApplicationsPerOrder */ 21:
                    message.maxApplicationsPerOrder = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.maxApplicationsPerOrder);
                    break;
                case /* Kiosk.DiscountScope scope */ 22:
                    message.scope = DiscountScope.internalBinaryRead(reader, reader.uint32(), options, message.scope);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountBaseVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Id = 1 [json_name = "Id"]; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string Name = 2 [json_name = "Name"]; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string DisplayName = 3 [json_name = "DisplayName"]; */
        if (message.displayName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.displayName);
        /* string ShortDescription = 4 [json_name = "ShortDescription"]; */
        if (message.shortDescription !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.shortDescription);
        /* Kiosk.DiscountFreeItemVM freeItem = 5; */
        if (message.discountType.oneofKind === "freeItem")
            DiscountFreeItemVM.internalBinaryWrite(message.discountType.freeItem, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountFixedAmountVM fixedAmount = 6; */
        if (message.discountType.oneofKind === "fixedAmount")
            DiscountFixedAmountVM.internalBinaryWrite(message.discountType.fixedAmount, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountPercentageVM percentage = 7; */
        if (message.discountType.oneofKind === "percentage")
            DiscountPercentageVM.internalBinaryWrite(message.discountType.percentage, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountPriceOverrideVM pricerOverride = 19; */
        if (message.discountType.oneofKind === "pricerOverride")
            DiscountPriceOverrideVM.internalBinaryWrite(message.discountType.pricerOverride, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* string POSDiscountId = 8 [json_name = "POSDiscountId"]; */
        if (message.pOSDiscountId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.pOSDiscountId);
        /* optional google.protobuf.Timestamp StartDate = 9 [json_name = "StartDate"]; */
        if (message.startDate)
            Timestamp.internalBinaryWrite(message.startDate, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.Timestamp EndDate = 10 [json_name = "EndDate"]; */
        if (message.endDate)
            Timestamp.internalBinaryWrite(message.endDate, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* bool Enabled = 11 [json_name = "Enabled"]; */
        if (message.enabled !== false)
            writer.tag(11, WireType.Varint).bool(message.enabled);
        /* Kiosk.SetupDiscountApplicationModeEnum.ApplicationMode Mode = 13 [json_name = "Mode"]; */
        if (message.mode !== 0)
            writer.tag(13, WireType.Varint).int32(message.mode);
        /* string DiscountImageUrl = 14 [json_name = "DiscountImageUrl"]; */
        if (message.discountImageUrl !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.discountImageUrl);
        /* optional string DiscountCode = 15 [json_name = "DiscountCode"]; */
        if (message.discountCode !== undefined)
            writer.tag(15, WireType.LengthDelimited).string(message.discountCode);
        /* double DiscountRate = 16 [json_name = "DiscountRate"]; */
        if (message.discountRate !== 0)
            writer.tag(16, WireType.Bit64).double(message.discountRate);
        /* Kiosk.DiscountConditionVM Condition = 18 [json_name = "Condition"]; */
        if (message.condition)
            DiscountConditionVM.internalBinaryWrite(message.condition, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value maxApplicationsPerOrder = 21; */
        if (message.maxApplicationsPerOrder)
            Int32Value.internalBinaryWrite(message.maxApplicationsPerOrder, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.DiscountScope scope = 22; */
        if (message.scope)
            DiscountScope.internalBinaryWrite(message.scope, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.DiscountBaseVM
 */
export const DiscountBaseVM = new DiscountBaseVM$Type();
