/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "loyalty.proto" (package "Kiosk", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { StringValue } from "./google/protobuf/wrappers_pb";
/**
 * @generated from protobuf message Kiosk.LoyaltyUser
 */
export interface LoyaltyUser {
    /**
     * @generated from protobuf field: string externalId = 1;
     */
    externalId: string;
    /**
     * @generated from protobuf field: string firstName = 2;
     */
    firstName: string;
    /**
     * @generated from protobuf field: optional string lastName = 3;
     */
    lastName?: string;
    /**
     * @generated from protobuf field: optional double primaryPointBalance = 4;
     */
    primaryPointBalance?: number;
    /**
     * @generated from protobuf field: optional double storedValue = 5;
     */
    storedValue?: number;
    /**
     * @generated from protobuf field: string integrationMetadata = 6;
     */
    integrationMetadata: string;
    /**
     * @generated from protobuf field: google.protobuf.StringValue POSOrderMetadataJson = 7 [json_name = "POSOrderMetadataJson"];
     */
    pOSOrderMetadataJson?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue POSOrderId = 8 [json_name = "POSOrderId"];
     */
    pOSOrderId?: StringValue;
}
/**
 * @generated from protobuf message Kiosk.PaytronixLoyaltyReward
 */
export interface PaytronixLoyaltyReward {
    /**
     * @generated from protobuf field: optional string id = 1;
     */
    id?: string;
    /**
     * @generated from protobuf field: int32 walletCode = 2;
     */
    walletCode: number;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: double value = 4;
     */
    value: number;
}
/**
 * @generated from protobuf message Kiosk.RedeemablePoint
 */
export interface RedeemablePoint {
    /**
     * @generated from protobuf field: string DiscountApplied = 1 [json_name = "DiscountApplied"];
     */
    discountApplied: string;
}
/**
 * @generated from protobuf message Kiosk.RedeemableBalance
 */
export interface RedeemableBalance {
    /**
     * @generated from protobuf field: string DiscountApplied = 1 [json_name = "DiscountApplied"];
     */
    discountApplied: string;
}
/**
 * @generated from protobuf message Kiosk.EarnedPoint
 */
export interface EarnedPoint {
}
/**
 * @generated from protobuf message Kiosk.EarnedBalance
 */
export interface EarnedBalance {
}
/**
 * @generated from protobuf message Kiosk.LoyaltyReward
 */
export interface LoyaltyReward {
    /**
     * @generated from protobuf oneof: reward
     */
    reward: {
        oneofKind: "redeemablePoint";
        /**
         * @generated from protobuf field: Kiosk.RedeemablePoint redeemable_point = 1;
         */
        redeemablePoint: RedeemablePoint;
    } | {
        oneofKind: "redeemableBalance";
        /**
         * @generated from protobuf field: Kiosk.RedeemableBalance redeemable_balance = 2;
         */
        redeemableBalance: RedeemableBalance;
    } | {
        oneofKind: "earnedPoint";
        /**
         * @generated from protobuf field: Kiosk.EarnedPoint earned_point = 3;
         */
        earnedPoint: EarnedPoint;
    } | {
        oneofKind: "earnedBalance";
        /**
         * @generated from protobuf field: Kiosk.EarnedBalance earned_balance = 4;
         */
        earnedBalance: EarnedBalance;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: string name = 5;
     */
    name: string;
    /**
     * @generated from protobuf field: string value = 6;
     */
    value: string;
    /**
     * @generated from protobuf field: string external_id = 7;
     */
    externalId: string;
    /**
     * @deprecated
     * @generated from protobuf field: map<string, string> fields = 8 [deprecated = true];
     */
    fields: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: string integrationMetadata = 9;
     */
    integrationMetadata: string;
    /**
     * @generated from protobuf field: Kiosk.LoyaltyRewardAssociatedCartItem associatedCartItem = 10;
     */
    associatedCartItem?: LoyaltyRewardAssociatedCartItem;
    /**
     * @generated from protobuf field: Kiosk.LoyaltyRewardApplicationMode applicationMode = 11;
     */
    applicationMode: LoyaltyRewardApplicationMode;
    /**
     * @generated from protobuf field: Kiosk.LoyaltyRewardEntityType entityType = 12;
     */
    entityType: LoyaltyRewardEntityType;
    /**
     * @generated from protobuf field: bool isValid = 13;
     */
    isValid: boolean; // sometimes we want to show rewards so that the user sees all the rewards that match another interface (i.e. an app) but we don't want to allow them to redeem it
}
/**
 * @generated from protobuf message Kiosk.LoyaltyRewardAssociatedCartItem
 */
export interface LoyaltyRewardAssociatedCartItem {
    /**
     * @generated from protobuf field: string menuItemId = 1;
     */
    menuItemId: string;
    /**
     * @generated from protobuf field: string comboId = 2;
     */
    comboId: string;
}
/**
 * @generated from protobuf message Kiosk.LoyaltyOrder
 */
export interface LoyaltyOrder {
    /**
     * @generated from protobuf field: string orderId = 1;
     */
    orderId: string;
    /**
     * @generated from protobuf field: repeated Kiosk.LoyaltyProductChoice items = 2;
     */
    items: LoyaltyProductChoice[];
    /**
     * @generated from protobuf field: string externalOrderType = 3;
     */
    externalOrderType: string;
    /**
     * @generated from protobuf field: string externalOrderTypeLabel = 4;
     */
    externalOrderTypeLabel: string;
}
/**
 * @generated from protobuf message Kiosk.LoyaltyProductChoice
 */
export interface LoyaltyProductChoice {
    /**
     * @generated from protobuf field: string itemId = 1;
     */
    itemId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated Kiosk.LoyaltyOptionChoice optionChoices = 3;
     */
    optionChoices: LoyaltyOptionChoice[];
    /**
     * @generated from protobuf field: int32 quantity = 4;
     */
    quantity: number;
    /**
     * @generated from protobuf field: string posData = 5;
     */
    posData: string;
    /**
     * @generated from protobuf field: string specialRequest = 6;
     */
    specialRequest: string;
}
/**
 * @generated from protobuf message Kiosk.LoyaltyOptionChoice
 */
export interface LoyaltyOptionChoice {
    /**
     * @generated from protobuf field: string itemId = 1;
     */
    itemId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated Kiosk.LoyaltyOptionChoice nestedChoices = 3;
     */
    nestedChoices: LoyaltyOptionChoice[];
    /**
     * @generated from protobuf field: int32 quantity = 4;
     */
    quantity: number;
    /**
     * @generated from protobuf field: string posData = 5;
     */
    posData: string;
}
/**
 * @generated from protobuf enum Kiosk.LoyaltyRewardApplicationMode
 */
export enum LoyaltyRewardApplicationMode {
    /**
     * @generated from protobuf enum value: PreApplied = 0;
     */
    PreApplied = 0,
    /**
     * @generated from protobuf enum value: Redeemable = 1;
     */
    Redeemable = 1
}
/**
 * @generated from protobuf enum Kiosk.LoyaltyRewardEntityType
 */
export enum LoyaltyRewardEntityType {
    /**
     * @generated from protobuf enum value: EARNED_REWARD = 0;
     */
    EARNED_REWARD = 0,
    /**
     * @generated from protobuf enum value: AVAILABLE_OFFER = 1;
     */
    AVAILABLE_OFFER = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyUser$Type extends MessageType<LoyaltyUser> {
    constructor() {
        super("Kiosk.LoyaltyUser", [
            { no: 1, name: "externalId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "firstName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "lastName", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "primaryPointBalance", kind: "scalar", opt: true, T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "storedValue", kind: "scalar", opt: true, T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "integrationMetadata", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "POSOrderMetadataJson", kind: "message", jsonName: "POSOrderMetadataJson", T: () => StringValue },
            { no: 8, name: "POSOrderId", kind: "message", jsonName: "POSOrderId", T: () => StringValue }
        ]);
    }
    create(value?: PartialMessage<LoyaltyUser>): LoyaltyUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.externalId = "";
        message.firstName = "";
        message.integrationMetadata = "";
        if (value !== undefined)
            reflectionMergePartial<LoyaltyUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyUser): LoyaltyUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string externalId */ 1:
                    message.externalId = reader.string();
                    break;
                case /* string firstName */ 2:
                    message.firstName = reader.string();
                    break;
                case /* optional string lastName */ 3:
                    message.lastName = reader.string();
                    break;
                case /* optional double primaryPointBalance */ 4:
                    message.primaryPointBalance = reader.double();
                    break;
                case /* optional double storedValue */ 5:
                    message.storedValue = reader.double();
                    break;
                case /* string integrationMetadata */ 6:
                    message.integrationMetadata = reader.string();
                    break;
                case /* google.protobuf.StringValue POSOrderMetadataJson = 7 [json_name = "POSOrderMetadataJson"];*/ 7:
                    message.pOSOrderMetadataJson = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.pOSOrderMetadataJson);
                    break;
                case /* google.protobuf.StringValue POSOrderId = 8 [json_name = "POSOrderId"];*/ 8:
                    message.pOSOrderId = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.pOSOrderId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoyaltyUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string externalId = 1; */
        if (message.externalId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.externalId);
        /* string firstName = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* optional string lastName = 3; */
        if (message.lastName !== undefined)
            writer.tag(3, WireType.LengthDelimited).string(message.lastName);
        /* optional double primaryPointBalance = 4; */
        if (message.primaryPointBalance !== undefined)
            writer.tag(4, WireType.Bit64).double(message.primaryPointBalance);
        /* optional double storedValue = 5; */
        if (message.storedValue !== undefined)
            writer.tag(5, WireType.Bit64).double(message.storedValue);
        /* string integrationMetadata = 6; */
        if (message.integrationMetadata !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.integrationMetadata);
        /* google.protobuf.StringValue POSOrderMetadataJson = 7 [json_name = "POSOrderMetadataJson"]; */
        if (message.pOSOrderMetadataJson)
            StringValue.internalBinaryWrite(message.pOSOrderMetadataJson, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue POSOrderId = 8 [json_name = "POSOrderId"]; */
        if (message.pOSOrderId)
            StringValue.internalBinaryWrite(message.pOSOrderId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LoyaltyUser
 */
export const LoyaltyUser = new LoyaltyUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaytronixLoyaltyReward$Type extends MessageType<PaytronixLoyaltyReward> {
    constructor() {
        super("Kiosk.PaytronixLoyaltyReward", [
            { no: 1, name: "id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "walletCode", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<PaytronixLoyaltyReward>): PaytronixLoyaltyReward {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.walletCode = 0;
        message.name = "";
        message.value = 0;
        if (value !== undefined)
            reflectionMergePartial<PaytronixLoyaltyReward>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaytronixLoyaltyReward): PaytronixLoyaltyReward {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional string id */ 1:
                    message.id = reader.string();
                    break;
                case /* int32 walletCode */ 2:
                    message.walletCode = reader.int32();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* double value */ 4:
                    message.value = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaytronixLoyaltyReward, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional string id = 1; */
        if (message.id !== undefined)
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int32 walletCode = 2; */
        if (message.walletCode !== 0)
            writer.tag(2, WireType.Varint).int32(message.walletCode);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* double value = 4; */
        if (message.value !== 0)
            writer.tag(4, WireType.Bit64).double(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.PaytronixLoyaltyReward
 */
export const PaytronixLoyaltyReward = new PaytronixLoyaltyReward$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RedeemablePoint$Type extends MessageType<RedeemablePoint> {
    constructor() {
        super("Kiosk.RedeemablePoint", [
            { no: 1, name: "DiscountApplied", kind: "scalar", jsonName: "DiscountApplied", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RedeemablePoint>): RedeemablePoint {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.discountApplied = "";
        if (value !== undefined)
            reflectionMergePartial<RedeemablePoint>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RedeemablePoint): RedeemablePoint {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string DiscountApplied = 1 [json_name = "DiscountApplied"];*/ 1:
                    message.discountApplied = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RedeemablePoint, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string DiscountApplied = 1 [json_name = "DiscountApplied"]; */
        if (message.discountApplied !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.discountApplied);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.RedeemablePoint
 */
export const RedeemablePoint = new RedeemablePoint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RedeemableBalance$Type extends MessageType<RedeemableBalance> {
    constructor() {
        super("Kiosk.RedeemableBalance", [
            { no: 1, name: "DiscountApplied", kind: "scalar", jsonName: "DiscountApplied", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RedeemableBalance>): RedeemableBalance {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.discountApplied = "";
        if (value !== undefined)
            reflectionMergePartial<RedeemableBalance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RedeemableBalance): RedeemableBalance {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string DiscountApplied = 1 [json_name = "DiscountApplied"];*/ 1:
                    message.discountApplied = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RedeemableBalance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string DiscountApplied = 1 [json_name = "DiscountApplied"]; */
        if (message.discountApplied !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.discountApplied);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.RedeemableBalance
 */
export const RedeemableBalance = new RedeemableBalance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EarnedPoint$Type extends MessageType<EarnedPoint> {
    constructor() {
        super("Kiosk.EarnedPoint", []);
    }
    create(value?: PartialMessage<EarnedPoint>): EarnedPoint {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EarnedPoint>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EarnedPoint): EarnedPoint {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EarnedPoint, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.EarnedPoint
 */
export const EarnedPoint = new EarnedPoint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EarnedBalance$Type extends MessageType<EarnedBalance> {
    constructor() {
        super("Kiosk.EarnedBalance", []);
    }
    create(value?: PartialMessage<EarnedBalance>): EarnedBalance {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EarnedBalance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EarnedBalance): EarnedBalance {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EarnedBalance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.EarnedBalance
 */
export const EarnedBalance = new EarnedBalance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyReward$Type extends MessageType<LoyaltyReward> {
    constructor() {
        super("Kiosk.LoyaltyReward", [
            { no: 1, name: "redeemable_point", kind: "message", oneof: "reward", T: () => RedeemablePoint },
            { no: 2, name: "redeemable_balance", kind: "message", oneof: "reward", T: () => RedeemableBalance },
            { no: 3, name: "earned_point", kind: "message", oneof: "reward", T: () => EarnedPoint },
            { no: 4, name: "earned_balance", kind: "message", oneof: "reward", T: () => EarnedBalance },
            { no: 5, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "external_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "fields", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 9, name: "integrationMetadata", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "associatedCartItem", kind: "message", T: () => LoyaltyRewardAssociatedCartItem },
            { no: 11, name: "applicationMode", kind: "enum", T: () => ["Kiosk.LoyaltyRewardApplicationMode", LoyaltyRewardApplicationMode] },
            { no: 12, name: "entityType", kind: "enum", T: () => ["Kiosk.LoyaltyRewardEntityType", LoyaltyRewardEntityType] },
            { no: 13, name: "isValid", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<LoyaltyReward>): LoyaltyReward {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.reward = { oneofKind: undefined };
        message.name = "";
        message.value = "";
        message.externalId = "";
        message.fields = {};
        message.integrationMetadata = "";
        message.applicationMode = 0;
        message.entityType = 0;
        message.isValid = false;
        if (value !== undefined)
            reflectionMergePartial<LoyaltyReward>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyReward): LoyaltyReward {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.RedeemablePoint redeemable_point */ 1:
                    message.reward = {
                        oneofKind: "redeemablePoint",
                        redeemablePoint: RedeemablePoint.internalBinaryRead(reader, reader.uint32(), options, (message.reward as any).redeemablePoint)
                    };
                    break;
                case /* Kiosk.RedeemableBalance redeemable_balance */ 2:
                    message.reward = {
                        oneofKind: "redeemableBalance",
                        redeemableBalance: RedeemableBalance.internalBinaryRead(reader, reader.uint32(), options, (message.reward as any).redeemableBalance)
                    };
                    break;
                case /* Kiosk.EarnedPoint earned_point */ 3:
                    message.reward = {
                        oneofKind: "earnedPoint",
                        earnedPoint: EarnedPoint.internalBinaryRead(reader, reader.uint32(), options, (message.reward as any).earnedPoint)
                    };
                    break;
                case /* Kiosk.EarnedBalance earned_balance */ 4:
                    message.reward = {
                        oneofKind: "earnedBalance",
                        earnedBalance: EarnedBalance.internalBinaryRead(reader, reader.uint32(), options, (message.reward as any).earnedBalance)
                    };
                    break;
                case /* string name */ 5:
                    message.name = reader.string();
                    break;
                case /* string value */ 6:
                    message.value = reader.string();
                    break;
                case /* string external_id */ 7:
                    message.externalId = reader.string();
                    break;
                case /* map<string, string> fields = 8 [deprecated = true];*/ 8:
                    this.binaryReadMap8(message.fields, reader, options);
                    break;
                case /* string integrationMetadata */ 9:
                    message.integrationMetadata = reader.string();
                    break;
                case /* Kiosk.LoyaltyRewardAssociatedCartItem associatedCartItem */ 10:
                    message.associatedCartItem = LoyaltyRewardAssociatedCartItem.internalBinaryRead(reader, reader.uint32(), options, message.associatedCartItem);
                    break;
                case /* Kiosk.LoyaltyRewardApplicationMode applicationMode */ 11:
                    message.applicationMode = reader.int32();
                    break;
                case /* Kiosk.LoyaltyRewardEntityType entityType */ 12:
                    message.entityType = reader.int32();
                    break;
                case /* bool isValid */ 13:
                    message.isValid = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap8(map: LoyaltyReward["fields"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LoyaltyReward["fields"] | undefined, val: LoyaltyReward["fields"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Kiosk.LoyaltyReward.fields");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: LoyaltyReward, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.RedeemablePoint redeemable_point = 1; */
        if (message.reward.oneofKind === "redeemablePoint")
            RedeemablePoint.internalBinaryWrite(message.reward.redeemablePoint, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.RedeemableBalance redeemable_balance = 2; */
        if (message.reward.oneofKind === "redeemableBalance")
            RedeemableBalance.internalBinaryWrite(message.reward.redeemableBalance, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.EarnedPoint earned_point = 3; */
        if (message.reward.oneofKind === "earnedPoint")
            EarnedPoint.internalBinaryWrite(message.reward.earnedPoint, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.EarnedBalance earned_balance = 4; */
        if (message.reward.oneofKind === "earnedBalance")
            EarnedBalance.internalBinaryWrite(message.reward.earnedBalance, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string name = 5; */
        if (message.name !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.name);
        /* string value = 6; */
        if (message.value !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.value);
        /* string external_id = 7; */
        if (message.externalId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.externalId);
        /* map<string, string> fields = 8 [deprecated = true]; */
        for (let k of globalThis.Object.keys(message.fields))
            writer.tag(8, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.fields[k]).join();
        /* string integrationMetadata = 9; */
        if (message.integrationMetadata !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.integrationMetadata);
        /* Kiosk.LoyaltyRewardAssociatedCartItem associatedCartItem = 10; */
        if (message.associatedCartItem)
            LoyaltyRewardAssociatedCartItem.internalBinaryWrite(message.associatedCartItem, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LoyaltyRewardApplicationMode applicationMode = 11; */
        if (message.applicationMode !== 0)
            writer.tag(11, WireType.Varint).int32(message.applicationMode);
        /* Kiosk.LoyaltyRewardEntityType entityType = 12; */
        if (message.entityType !== 0)
            writer.tag(12, WireType.Varint).int32(message.entityType);
        /* bool isValid = 13; */
        if (message.isValid !== false)
            writer.tag(13, WireType.Varint).bool(message.isValid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LoyaltyReward
 */
export const LoyaltyReward = new LoyaltyReward$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyRewardAssociatedCartItem$Type extends MessageType<LoyaltyRewardAssociatedCartItem> {
    constructor() {
        super("Kiosk.LoyaltyRewardAssociatedCartItem", [
            { no: 1, name: "menuItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "comboId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LoyaltyRewardAssociatedCartItem>): LoyaltyRewardAssociatedCartItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.menuItemId = "";
        message.comboId = "";
        if (value !== undefined)
            reflectionMergePartial<LoyaltyRewardAssociatedCartItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyRewardAssociatedCartItem): LoyaltyRewardAssociatedCartItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string menuItemId */ 1:
                    message.menuItemId = reader.string();
                    break;
                case /* string comboId */ 2:
                    message.comboId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoyaltyRewardAssociatedCartItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string menuItemId = 1; */
        if (message.menuItemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.menuItemId);
        /* string comboId = 2; */
        if (message.comboId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.comboId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LoyaltyRewardAssociatedCartItem
 */
export const LoyaltyRewardAssociatedCartItem = new LoyaltyRewardAssociatedCartItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyOrder$Type extends MessageType<LoyaltyOrder> {
    constructor() {
        super("Kiosk.LoyaltyOrder", [
            { no: 1, name: "orderId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LoyaltyProductChoice },
            { no: 3, name: "externalOrderType", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "externalOrderTypeLabel", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LoyaltyOrder>): LoyaltyOrder {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.orderId = "";
        message.items = [];
        message.externalOrderType = "";
        message.externalOrderTypeLabel = "";
        if (value !== undefined)
            reflectionMergePartial<LoyaltyOrder>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyOrder): LoyaltyOrder {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string orderId */ 1:
                    message.orderId = reader.string();
                    break;
                case /* repeated Kiosk.LoyaltyProductChoice items */ 2:
                    message.items.push(LoyaltyProductChoice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string externalOrderType */ 3:
                    message.externalOrderType = reader.string();
                    break;
                case /* string externalOrderTypeLabel */ 4:
                    message.externalOrderTypeLabel = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoyaltyOrder, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string orderId = 1; */
        if (message.orderId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.orderId);
        /* repeated Kiosk.LoyaltyProductChoice items = 2; */
        for (let i = 0; i < message.items.length; i++)
            LoyaltyProductChoice.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string externalOrderType = 3; */
        if (message.externalOrderType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.externalOrderType);
        /* string externalOrderTypeLabel = 4; */
        if (message.externalOrderTypeLabel !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.externalOrderTypeLabel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LoyaltyOrder
 */
export const LoyaltyOrder = new LoyaltyOrder$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyProductChoice$Type extends MessageType<LoyaltyProductChoice> {
    constructor() {
        super("Kiosk.LoyaltyProductChoice", [
            { no: 1, name: "itemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "optionChoices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LoyaltyOptionChoice },
            { no: 4, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "posData", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "specialRequest", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LoyaltyProductChoice>): LoyaltyProductChoice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.itemId = "";
        message.name = "";
        message.optionChoices = [];
        message.quantity = 0;
        message.posData = "";
        message.specialRequest = "";
        if (value !== undefined)
            reflectionMergePartial<LoyaltyProductChoice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyProductChoice): LoyaltyProductChoice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string itemId */ 1:
                    message.itemId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated Kiosk.LoyaltyOptionChoice optionChoices */ 3:
                    message.optionChoices.push(LoyaltyOptionChoice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 quantity */ 4:
                    message.quantity = reader.int32();
                    break;
                case /* string posData */ 5:
                    message.posData = reader.string();
                    break;
                case /* string specialRequest */ 6:
                    message.specialRequest = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoyaltyProductChoice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string itemId = 1; */
        if (message.itemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.itemId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated Kiosk.LoyaltyOptionChoice optionChoices = 3; */
        for (let i = 0; i < message.optionChoices.length; i++)
            LoyaltyOptionChoice.internalBinaryWrite(message.optionChoices[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity = 4; */
        if (message.quantity !== 0)
            writer.tag(4, WireType.Varint).int32(message.quantity);
        /* string posData = 5; */
        if (message.posData !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.posData);
        /* string specialRequest = 6; */
        if (message.specialRequest !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.specialRequest);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LoyaltyProductChoice
 */
export const LoyaltyProductChoice = new LoyaltyProductChoice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyOptionChoice$Type extends MessageType<LoyaltyOptionChoice> {
    constructor() {
        super("Kiosk.LoyaltyOptionChoice", [
            { no: 1, name: "itemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "nestedChoices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LoyaltyOptionChoice },
            { no: 4, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "posData", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LoyaltyOptionChoice>): LoyaltyOptionChoice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.itemId = "";
        message.name = "";
        message.nestedChoices = [];
        message.quantity = 0;
        message.posData = "";
        if (value !== undefined)
            reflectionMergePartial<LoyaltyOptionChoice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyOptionChoice): LoyaltyOptionChoice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string itemId */ 1:
                    message.itemId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated Kiosk.LoyaltyOptionChoice nestedChoices */ 3:
                    message.nestedChoices.push(LoyaltyOptionChoice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 quantity */ 4:
                    message.quantity = reader.int32();
                    break;
                case /* string posData */ 5:
                    message.posData = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoyaltyOptionChoice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string itemId = 1; */
        if (message.itemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.itemId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated Kiosk.LoyaltyOptionChoice nestedChoices = 3; */
        for (let i = 0; i < message.nestedChoices.length; i++)
            LoyaltyOptionChoice.internalBinaryWrite(message.nestedChoices[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity = 4; */
        if (message.quantity !== 0)
            writer.tag(4, WireType.Varint).int32(message.quantity);
        /* string posData = 5; */
        if (message.posData !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.posData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LoyaltyOptionChoice
 */
export const LoyaltyOptionChoice = new LoyaltyOptionChoice$Type();
