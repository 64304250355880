/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "kioskService.proto" (package "Kiosk", syntax proto3)
// tslint:disable
// @ts-nocheck
import { EmptyRequest } from "./common_pb";
import { EmptyResponse } from "./common_pb";
import { KioskDeregistrationResponse } from "./kiosk_pb";
import { KioskDeregistrationRequest } from "./kiosk_pb";
import { KioskAuthorizationResponse } from "./kiosk_pb";
import { KioskAuthorizationRequest } from "./kiosk_pb";
import { KioskRegistrationResponse } from "./kiosk_pb";
import { KioskRegistrationRequest } from "./kiosk_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { StatusResult } from "./common_pb";
import { LogLevel } from "./kiosk_pb";
import { Menu } from "./menu_pb";
import { MenuVM } from "./menu_pb";
import { KioskConfiguration } from "./kiosk_pb";
import { ResponseStatus } from "./common_pb";
import { Timestamp } from "./google/protobuf/timestamp_pb";
/**
 * @generated from protobuf message Kiosk.AmazonOneCredentialsResponse
 */
export interface AmazonOneCredentialsResponse {
    /**
     * @generated from protobuf field: string sessionToken = 1;
     */
    sessionToken: string;
    /**
     * @generated from protobuf field: string accessKeyId = 2;
     */
    accessKeyId: string;
    /**
     * @generated from protobuf field: string secretAccessKey = 3;
     */
    secretAccessKey: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp expiration = 4;
     */
    expiration?: Timestamp;
}
/**
 * @generated from protobuf message Kiosk.GetPosHealthStatusRequest
 */
export interface GetPosHealthStatusRequest {
    /**
     * @deprecated
     * @generated from protobuf field: string id = 1 [deprecated = true];
     */
    id: string;
}
/**
 * @generated from protobuf message Kiosk.GetPosHealthStatusResponse
 */
export interface GetPosHealthStatusResponse {
    /**
     * @generated from protobuf field: Kiosk.PosHealthStatus status = 1;
     */
    status: PosHealthStatus;
}
/**
 * @generated from protobuf message Kiosk.KioskRequest
 */
export interface KioskRequest {
    /**
     * @generated from protobuf field: string syncVersion = 1;
     */
    syncVersion: string;
    /**
     * @generated from protobuf field: string appVersion = 2;
     */
    appVersion: string;
}
/**
 * @generated from protobuf message Kiosk.KioskSyncStatusResponse
 */
export interface KioskSyncStatusResponse {
    /**
     * @generated from protobuf field: Kiosk.KioskSyncStatus status = 1;
     */
    status: KioskSyncStatus;
}
/**
 * @generated from protobuf message Kiosk.KioskResponse
 */
export interface KioskResponse {
    /**
     * @generated from protobuf field: Kiosk.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: string syncVersion = 2;
     */
    syncVersion: string;
    /**
     * @generated from protobuf field: Kiosk.KioskConfiguration config = 3;
     */
    config?: KioskConfiguration;
    /**
     * @deprecated
     * @generated from protobuf field: Kiosk.MenuVM menu = 4 [deprecated = true];
     */
    menu?: MenuVM;
    /**
     * @generated from protobuf field: Kiosk.Menu posMenu = 5;
     */
    posMenu?: Menu;
}
/**
 * @generated from protobuf message Kiosk.EventLogRequest
 */
export interface EventLogRequest {
    /**
     * @generated from protobuf field: repeated Kiosk.EventLog events = 1;
     */
    events: EventLog[];
}
/**
 * @generated from protobuf message Kiosk.EventLog
 */
export interface EventLog {
    /**
     * @generated from protobuf field: string category = 1;
     */
    category: string;
    /**
     * @generated from protobuf field: string type = 2;
     */
    type: string;
    /**
     * @generated from protobuf field: Kiosk.LogLevel severity = 3;
     */
    severity: LogLevel;
    /**
     * @generated from protobuf field: string data = 4;
     */
    data: string;
    /**
     * @generated from protobuf field: string summary = 5;
     */
    summary: string;
    /**
     * @generated from protobuf field: string instant = 6;
     */
    instant: string;
    /**
     * @generated from protobuf field: optional string sessionId = 7;
     */
    sessionId?: string;
}
/**
 * @generated from protobuf message Kiosk.KioskEventLogRequest
 */
export interface KioskEventLogRequest {
    /**
     * @generated from protobuf field: Kiosk.LogLevel level = 1;
     */
    level: LogLevel;
    /**
     * @generated from protobuf field: string source = 2;
     */
    source: string;
    /**
     * @generated from protobuf field: string category = 3;
     */
    category: string;
    /**
     * @generated from protobuf field: string eventId = 4;
     */
    eventId: string;
    /**
     * @generated from protobuf field: string details = 5;
     */
    details: string;
    /**
     * @generated from protobuf field: optional string sessionId = 6;
     */
    sessionId?: string;
    /**
     * @generated from protobuf field: string timestamp = 7;
     */
    timestamp: string;
}
/**
 * @generated from protobuf message Kiosk.SyncPricesRequest
 */
export interface SyncPricesRequest {
    /**
     * @generated from protobuf field: string syncVersion = 1;
     */
    syncVersion: string;
}
/**
 * @generated from protobuf message Kiosk.SyncPricesResponse
 */
export interface SyncPricesResponse {
    /**
     * @generated from protobuf field: string syncVersion = 1;
     */
    syncVersion: string;
    /**
     * @generated from protobuf field: repeated Kiosk.PriceItem prices = 2;
     */
    prices: PriceItem[];
    /**
     * @generated from protobuf field: Kiosk.StatusResult status = 3;
     */
    status?: StatusResult;
}
/**
 * @generated from protobuf message Kiosk.PriceItem
 */
export interface PriceItem {
    /**
     * @generated from protobuf field: string itemId = 1;
     */
    itemId: string;
    /**
     * @generated from protobuf field: string itemVariationId = 2;
     */
    itemVariationId: string;
    /**
     * @generated from protobuf field: string modifierGroupId = 3;
     */
    modifierGroupId: string;
    /**
     * @generated from protobuf field: string modifierId = 4;
     */
    modifierId: string;
    /**
     * @generated from protobuf field: string comboMasterId = 5;
     */
    comboMasterId: string;
    /**
     * @generated from protobuf field: string comboFamilyId = 6;
     */
    comboFamilyId: string;
    /**
     * @generated from protobuf field: string comboComponentId = 7;
     */
    comboComponentId: string;
    /**
     * @generated from protobuf field: int32 price = 8;
     */
    price: number;
}
/**
 * @generated from protobuf enum Kiosk.PosHealthStatus
 */
export enum PosHealthStatus {
    /**
     * @generated from protobuf enum value: UNHEALTHY = 0;
     */
    UNHEALTHY = 0,
    /**
     * @generated from protobuf enum value: HEALTHY = 1;
     */
    HEALTHY = 1
}
/**
 * @generated from protobuf enum Kiosk.KioskSyncStatus
 */
export enum KioskSyncStatus {
    /**
     * @generated from protobuf enum value: UP_TO_DATE = 0;
     */
    UP_TO_DATE = 0,
    /**
     * @generated from protobuf enum value: REQUIRES_SYNC = 1;
     */
    REQUIRES_SYNC = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class AmazonOneCredentialsResponse$Type extends MessageType<AmazonOneCredentialsResponse> {
    constructor() {
        super("Kiosk.AmazonOneCredentialsResponse", [
            { no: 1, name: "sessionToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accessKeyId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "secretAccessKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "expiration", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<AmazonOneCredentialsResponse>): AmazonOneCredentialsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sessionToken = "";
        message.accessKeyId = "";
        message.secretAccessKey = "";
        if (value !== undefined)
            reflectionMergePartial<AmazonOneCredentialsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AmazonOneCredentialsResponse): AmazonOneCredentialsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string sessionToken */ 1:
                    message.sessionToken = reader.string();
                    break;
                case /* string accessKeyId */ 2:
                    message.accessKeyId = reader.string();
                    break;
                case /* string secretAccessKey */ 3:
                    message.secretAccessKey = reader.string();
                    break;
                case /* google.protobuf.Timestamp expiration */ 4:
                    message.expiration = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expiration);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AmazonOneCredentialsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string sessionToken = 1; */
        if (message.sessionToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionToken);
        /* string accessKeyId = 2; */
        if (message.accessKeyId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accessKeyId);
        /* string secretAccessKey = 3; */
        if (message.secretAccessKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.secretAccessKey);
        /* google.protobuf.Timestamp expiration = 4; */
        if (message.expiration)
            Timestamp.internalBinaryWrite(message.expiration, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.AmazonOneCredentialsResponse
 */
export const AmazonOneCredentialsResponse = new AmazonOneCredentialsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPosHealthStatusRequest$Type extends MessageType<GetPosHealthStatusRequest> {
    constructor() {
        super("Kiosk.GetPosHealthStatusRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPosHealthStatusRequest>): GetPosHealthStatusRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<GetPosHealthStatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPosHealthStatusRequest): GetPosHealthStatusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id = 1 [deprecated = true];*/ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPosHealthStatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1 [deprecated = true]; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.GetPosHealthStatusRequest
 */
export const GetPosHealthStatusRequest = new GetPosHealthStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPosHealthStatusResponse$Type extends MessageType<GetPosHealthStatusResponse> {
    constructor() {
        super("Kiosk.GetPosHealthStatusResponse", [
            { no: 1, name: "status", kind: "enum", T: () => ["Kiosk.PosHealthStatus", PosHealthStatus] }
        ]);
    }
    create(value?: PartialMessage<GetPosHealthStatusResponse>): GetPosHealthStatusResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<GetPosHealthStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPosHealthStatusResponse): GetPosHealthStatusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.PosHealthStatus status */ 1:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPosHealthStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.PosHealthStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.GetPosHealthStatusResponse
 */
export const GetPosHealthStatusResponse = new GetPosHealthStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskRequest$Type extends MessageType<KioskRequest> {
    constructor() {
        super("Kiosk.KioskRequest", [
            { no: 1, name: "syncVersion", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "appVersion", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KioskRequest>): KioskRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.syncVersion = "";
        message.appVersion = "";
        if (value !== undefined)
            reflectionMergePartial<KioskRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskRequest): KioskRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string syncVersion */ 1:
                    message.syncVersion = reader.string();
                    break;
                case /* string appVersion */ 2:
                    message.appVersion = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string syncVersion = 1; */
        if (message.syncVersion !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.syncVersion);
        /* string appVersion = 2; */
        if (message.appVersion !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.appVersion);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskRequest
 */
export const KioskRequest = new KioskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskSyncStatusResponse$Type extends MessageType<KioskSyncStatusResponse> {
    constructor() {
        super("Kiosk.KioskSyncStatusResponse", [
            { no: 1, name: "status", kind: "enum", T: () => ["Kiosk.KioskSyncStatus", KioskSyncStatus] }
        ]);
    }
    create(value?: PartialMessage<KioskSyncStatusResponse>): KioskSyncStatusResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<KioskSyncStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskSyncStatusResponse): KioskSyncStatusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.KioskSyncStatus status */ 1:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskSyncStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.KioskSyncStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskSyncStatusResponse
 */
export const KioskSyncStatusResponse = new KioskSyncStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskResponse$Type extends MessageType<KioskResponse> {
    constructor() {
        super("Kiosk.KioskResponse", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "syncVersion", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "config", kind: "message", T: () => KioskConfiguration },
            { no: 4, name: "menu", kind: "message", T: () => MenuVM },
            { no: 5, name: "posMenu", kind: "message", T: () => Menu }
        ]);
    }
    create(value?: PartialMessage<KioskResponse>): KioskResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.syncVersion = "";
        if (value !== undefined)
            reflectionMergePartial<KioskResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskResponse): KioskResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* string syncVersion */ 2:
                    message.syncVersion = reader.string();
                    break;
                case /* Kiosk.KioskConfiguration config */ 3:
                    message.config = KioskConfiguration.internalBinaryRead(reader, reader.uint32(), options, message.config);
                    break;
                case /* Kiosk.MenuVM menu = 4 [deprecated = true];*/ 4:
                    message.menu = MenuVM.internalBinaryRead(reader, reader.uint32(), options, message.menu);
                    break;
                case /* Kiosk.Menu posMenu */ 5:
                    message.posMenu = Menu.internalBinaryRead(reader, reader.uint32(), options, message.posMenu);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string syncVersion = 2; */
        if (message.syncVersion !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.syncVersion);
        /* Kiosk.KioskConfiguration config = 3; */
        if (message.config)
            KioskConfiguration.internalBinaryWrite(message.config, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.MenuVM menu = 4 [deprecated = true]; */
        if (message.menu)
            MenuVM.internalBinaryWrite(message.menu, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.Menu posMenu = 5; */
        if (message.posMenu)
            Menu.internalBinaryWrite(message.posMenu, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskResponse
 */
export const KioskResponse = new KioskResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventLogRequest$Type extends MessageType<EventLogRequest> {
    constructor() {
        super("Kiosk.EventLogRequest", [
            { no: 1, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventLog }
        ]);
    }
    create(value?: PartialMessage<EventLogRequest>): EventLogRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<EventLogRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventLogRequest): EventLogRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.EventLog events */ 1:
                    message.events.push(EventLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventLogRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.EventLog events = 1; */
        for (let i = 0; i < message.events.length; i++)
            EventLog.internalBinaryWrite(message.events[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.EventLogRequest
 */
export const EventLogRequest = new EventLogRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventLog$Type extends MessageType<EventLog> {
    constructor() {
        super("Kiosk.EventLog", [
            { no: 1, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "severity", kind: "enum", T: () => ["Kiosk.LogLevel", LogLevel] },
            { no: 4, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "summary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "instant", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "sessionId", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EventLog>): EventLog {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.category = "";
        message.type = "";
        message.severity = 0;
        message.data = "";
        message.summary = "";
        message.instant = "";
        if (value !== undefined)
            reflectionMergePartial<EventLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventLog): EventLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string category */ 1:
                    message.category = reader.string();
                    break;
                case /* string type */ 2:
                    message.type = reader.string();
                    break;
                case /* Kiosk.LogLevel severity */ 3:
                    message.severity = reader.int32();
                    break;
                case /* string data */ 4:
                    message.data = reader.string();
                    break;
                case /* string summary */ 5:
                    message.summary = reader.string();
                    break;
                case /* string instant */ 6:
                    message.instant = reader.string();
                    break;
                case /* optional string sessionId */ 7:
                    message.sessionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string category = 1; */
        if (message.category !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.category);
        /* string type = 2; */
        if (message.type !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.type);
        /* Kiosk.LogLevel severity = 3; */
        if (message.severity !== 0)
            writer.tag(3, WireType.Varint).int32(message.severity);
        /* string data = 4; */
        if (message.data !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.data);
        /* string summary = 5; */
        if (message.summary !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.summary);
        /* string instant = 6; */
        if (message.instant !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.instant);
        /* optional string sessionId = 7; */
        if (message.sessionId !== undefined)
            writer.tag(7, WireType.LengthDelimited).string(message.sessionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.EventLog
 */
export const EventLog = new EventLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskEventLogRequest$Type extends MessageType<KioskEventLogRequest> {
    constructor() {
        super("Kiosk.KioskEventLogRequest", [
            { no: 1, name: "level", kind: "enum", T: () => ["Kiosk.LogLevel", LogLevel] },
            { no: 2, name: "source", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "eventId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "details", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "sessionId", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KioskEventLogRequest>): KioskEventLogRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.level = 0;
        message.source = "";
        message.category = "";
        message.eventId = "";
        message.details = "";
        message.timestamp = "";
        if (value !== undefined)
            reflectionMergePartial<KioskEventLogRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskEventLogRequest): KioskEventLogRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.LogLevel level */ 1:
                    message.level = reader.int32();
                    break;
                case /* string source */ 2:
                    message.source = reader.string();
                    break;
                case /* string category */ 3:
                    message.category = reader.string();
                    break;
                case /* string eventId */ 4:
                    message.eventId = reader.string();
                    break;
                case /* string details */ 5:
                    message.details = reader.string();
                    break;
                case /* optional string sessionId */ 6:
                    message.sessionId = reader.string();
                    break;
                case /* string timestamp */ 7:
                    message.timestamp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskEventLogRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.LogLevel level = 1; */
        if (message.level !== 0)
            writer.tag(1, WireType.Varint).int32(message.level);
        /* string source = 2; */
        if (message.source !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.source);
        /* string category = 3; */
        if (message.category !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.category);
        /* string eventId = 4; */
        if (message.eventId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.eventId);
        /* string details = 5; */
        if (message.details !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.details);
        /* optional string sessionId = 6; */
        if (message.sessionId !== undefined)
            writer.tag(6, WireType.LengthDelimited).string(message.sessionId);
        /* string timestamp = 7; */
        if (message.timestamp !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.timestamp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.KioskEventLogRequest
 */
export const KioskEventLogRequest = new KioskEventLogRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncPricesRequest$Type extends MessageType<SyncPricesRequest> {
    constructor() {
        super("Kiosk.SyncPricesRequest", [
            { no: 1, name: "syncVersion", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SyncPricesRequest>): SyncPricesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.syncVersion = "";
        if (value !== undefined)
            reflectionMergePartial<SyncPricesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncPricesRequest): SyncPricesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string syncVersion */ 1:
                    message.syncVersion = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncPricesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string syncVersion = 1; */
        if (message.syncVersion !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.syncVersion);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.SyncPricesRequest
 */
export const SyncPricesRequest = new SyncPricesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncPricesResponse$Type extends MessageType<SyncPricesResponse> {
    constructor() {
        super("Kiosk.SyncPricesResponse", [
            { no: 1, name: "syncVersion", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "prices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PriceItem },
            { no: 3, name: "status", kind: "message", T: () => StatusResult }
        ]);
    }
    create(value?: PartialMessage<SyncPricesResponse>): SyncPricesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.syncVersion = "";
        message.prices = [];
        if (value !== undefined)
            reflectionMergePartial<SyncPricesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncPricesResponse): SyncPricesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string syncVersion */ 1:
                    message.syncVersion = reader.string();
                    break;
                case /* repeated Kiosk.PriceItem prices */ 2:
                    message.prices.push(PriceItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.StatusResult status */ 3:
                    message.status = StatusResult.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncPricesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string syncVersion = 1; */
        if (message.syncVersion !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.syncVersion);
        /* repeated Kiosk.PriceItem prices = 2; */
        for (let i = 0; i < message.prices.length; i++)
            PriceItem.internalBinaryWrite(message.prices[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.StatusResult status = 3; */
        if (message.status)
            StatusResult.internalBinaryWrite(message.status, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.SyncPricesResponse
 */
export const SyncPricesResponse = new SyncPricesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PriceItem$Type extends MessageType<PriceItem> {
    constructor() {
        super("Kiosk.PriceItem", [
            { no: 1, name: "itemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "itemVariationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "modifierGroupId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "modifierId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "comboMasterId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "comboFamilyId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "comboComponentId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "price", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PriceItem>): PriceItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.itemId = "";
        message.itemVariationId = "";
        message.modifierGroupId = "";
        message.modifierId = "";
        message.comboMasterId = "";
        message.comboFamilyId = "";
        message.comboComponentId = "";
        message.price = 0;
        if (value !== undefined)
            reflectionMergePartial<PriceItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PriceItem): PriceItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string itemId */ 1:
                    message.itemId = reader.string();
                    break;
                case /* string itemVariationId */ 2:
                    message.itemVariationId = reader.string();
                    break;
                case /* string modifierGroupId */ 3:
                    message.modifierGroupId = reader.string();
                    break;
                case /* string modifierId */ 4:
                    message.modifierId = reader.string();
                    break;
                case /* string comboMasterId */ 5:
                    message.comboMasterId = reader.string();
                    break;
                case /* string comboFamilyId */ 6:
                    message.comboFamilyId = reader.string();
                    break;
                case /* string comboComponentId */ 7:
                    message.comboComponentId = reader.string();
                    break;
                case /* int32 price */ 8:
                    message.price = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PriceItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string itemId = 1; */
        if (message.itemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.itemId);
        /* string itemVariationId = 2; */
        if (message.itemVariationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.itemVariationId);
        /* string modifierGroupId = 3; */
        if (message.modifierGroupId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.modifierGroupId);
        /* string modifierId = 4; */
        if (message.modifierId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.modifierId);
        /* string comboMasterId = 5; */
        if (message.comboMasterId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.comboMasterId);
        /* string comboFamilyId = 6; */
        if (message.comboFamilyId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.comboFamilyId);
        /* string comboComponentId = 7; */
        if (message.comboComponentId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.comboComponentId);
        /* int32 price = 8; */
        if (message.price !== 0)
            writer.tag(8, WireType.Varint).int32(message.price);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.PriceItem
 */
export const PriceItem = new PriceItem$Type();
/**
 * @generated ServiceType for protobuf service Kiosk.KioskService
 */
export const KioskService = new ServiceType("Kiosk.KioskService", [
    { name: "Register", options: { "google.api.http": { post: "/v1/KioskService/Register", body: "*" } }, I: KioskRegistrationRequest, O: KioskRegistrationResponse },
    { name: "Authorize", options: { "google.api.http": { post: "/v1/KioskService/Authorize", body: "*" } }, I: KioskAuthorizationRequest, O: KioskAuthorizationResponse },
    { name: "Deregister", options: { "google.api.http": { post: "/v1/KioskService/Deregister", body: "*" } }, I: KioskDeregistrationRequest, O: KioskDeregistrationResponse },
    { name: "GetKioskSyncStatus", options: { "google.api.http": { post: "/v1/KioskService/GetKioskSyncStatus", body: "*" } }, I: KioskRequest, O: KioskSyncStatusResponse },
    { name: "GetKiosk", options: { "google.api.http": { post: "/v1/KioskService/GetKiosk", body: "*" } }, I: KioskRequest, O: KioskResponse },
    { name: "EventLogs", options: { "google.api.http": { post: "/v1/KioskService/EventLogs", body: "*" } }, I: EventLogRequest, O: EmptyResponse },
    { name: "HealthCheck", options: { "google.api.http": { post: "/v1/KioskService/HealthCheck", body: "*" } }, I: EmptyRequest, O: EmptyResponse },
    { name: "PosHealthCheck", options: { "google.api.http": { post: "/v1/KioskService/PosHealthCheck", body: "*" } }, I: GetPosHealthStatusRequest, O: GetPosHealthStatusResponse },
    { name: "GetAmazonOneCredentials", options: { "google.api.http": { post: "/v1/KioskService/GetAmazonOneCredentials", body: "*" } }, I: EmptyRequest, O: AmazonOneCredentialsResponse },
    { name: "SyncPrices", options: { "google.api.http": { post: "/v1/KioskService/SyncPrices", body: "*" } }, I: SyncPricesRequest, O: SyncPricesResponse }
]);
