import { get } from 'svelte/store';
import { ModifierCodeBehavior } from '../../../generated/common_pb';
import {
  ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType,
  NegativeModifierBehavior,
} from '../../../generated/menu_pb';
import type {
  KioskAvailableTimes,
  KioskCategory,
  KioskCategoryDisplayableItem,
  KioskCombo,
  KioskMenuItem,
  KioskModifier,
  KioskModifierGroup,
} from '../../models';
import type { EightySixedItems } from '../eighty_six/eighty_six_service';
import type {
  DerivedModifierGroupState,
  DerivedModifierGroupsState,
  DerivedModifierState,
  ItemServiceStoreState,
  ItemServiceInternalState,
  ModGroupService,
  ModifierGroupState,
  ModifierService,
  ModifierState,
  ModifierServiceDerivedState,
  ModGroupServiceContext,
} from './types';
import type { IPricesStateManager, CartStoreState, IMediaStateManager } from '../../state-managers';
import type { AppearanceConfig } from '../../context/apperance_context';
import { isContainsNegativeModifierCode } from './item_service';
import { isExtraModifierCode, isValidOneOf } from '../../data-management/utils';

const MIN_QUANTITY = 1;

export const firstModifierGroupId = (item: KioskMenuItem) => {
  return item.modifierGroups.length === 0 ? null : item.modifierGroups[0].modifierGroupId;
};

export const lastModifierGroupId = (item: KioskMenuItem) => {
  return item.modifierGroups.length === 0
    ? ''
    : item.modifierGroups[item.modifierGroups.length - 1].modifierGroupId;
};

const isSelectedChildModifierGroupValid = (
  modifierState: ModifierState | ItemServiceInternalState,
  childModifierGroupDerivedState: DerivedModifierGroupsState
) => {
  if (!modifierState.selected_group_id) {
    return true;
  }

  const derivedModifierGroupState = childModifierGroupDerivedState.get(
    modifierState.selected_group_id
  );
  return derivedModifierGroupState?.state.display.is_valid ?? true;
};

const getDerivedStateForModGroup = (
  rootItemId: string,
  mediaStateManager: IMediaStateManager,
  priceStateManager: IPricesStateManager,
  eightySixItems: EightySixedItems,
  parentState: {
    selectedGroupId?: string;
  },
  modGroupState: ModifierGroupState,
  service: ModGroupService,
  group: KioskModifierGroup
): DerivedModifierGroupState => {
  const modServicesById = new Map(service.modifierServices.map((x) => [x.modifier.modifierId, x]));
  const getPriceForMod = (modifier: KioskModifier) => {
    return priceStateManager.getPriceForModifierInItem(rootItemId, modifier.modifierId) ?? 0;
  };
  const getPriceForExtraModifierCode = (mod: KioskModifier, mod_state: ModifierState) => {
    if (!mod_state.selectedModifierCode || mod.isDefault) return 0;
    if (mod_state.selectedModifierCode.modifierCodeBehavior == ModifierCodeBehavior.Extra) {
      return getPriceForMod(mod);
    }
    return 0;
  };

  const isModifierPizzaTopping = () => {
    if (
      group.domainSpecificAttributes &&
      isValidOneOf(group.domainSpecificAttributes.attributes) &&
      group.domainSpecificAttributes.attributes.oneofKind === 'pizzaAttributes'
    ) {
      return (
        group.domainSpecificAttributes.attributes.pizzaAttributes.type ===
        ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Topping
      );
    }
    return false;
  };

  const {
    starting_price,
    price,
    is_valid,
    quantity,
    available_free_mods,
    mod_states,
    negativeModifierQuantity,
  } = [...modGroupState.modifiers.entries()]
    .map(([modifierId, modifierState]) => {
      {
        return {
          // TODO: Use dictionary
          mod: group.modifiers.find((x) => x.modifierId === modifierId)!,
          mod_state: modifierState,
          neg_mod_behaviour: group.negativeModifierBehavior,
        };
      }
    })
    .sort((a, b) => getPriceForMod(a.mod) - getPriceForMod(b.mod))
    .reduce(
      (
        {
          starting_price,
          price,
          is_valid,
          quantity,
          counted,
          available_free_mods,
          mod_states,
          negativeModifierQuantity,
        },
        _mod
      ) => {
        const { mod, mod_state, neg_mod_behaviour } = _mod;

        const isModifier86 = mod?.menuItemId ? eightySixItems.has(mod.menuItemId) : false;

        const modifier_selected_quantity = !isModifier86 ? mod_state.quantity : 0;

        const new_quantity = quantity + modifier_selected_quantity;

        const modService = modServicesById.get(mod.modifierId);
        const modifierPrice =
          getPriceForMod(mod) +
          getPriceForExtraModifierCode(mod, mod_state) * modifier_selected_quantity;
        let new_price_with_modifiers = modifierPrice;
        let new_starting_price = modifierPrice;
        let new_is_valid = true;
        let first_invalid_group_id: string | undefined = undefined;
        let priced_quantity = modifier_selected_quantity;

        // let itemModifiers: ItemModifier[] = [];
        const derivedModifierGroupState: DerivedModifierGroupsState = new Map();

        for (const childModifierGroupService of modService?.modifierGroupServices?.values() ?? []) {
          const childState = mod_state.mod_groups.get(
            childModifierGroupService.group.modifierGroupId
          );
          const derivedState = getDerivedStateForModGroup(
            rootItemId,
            mediaStateManager,
            priceStateManager,
            eightySixItems,
            {
              selectedGroupId: mod_state.selected_group_id,
            },
            childState!,
            childModifierGroupService,
            childModifierGroupService.group
          );

          new_price_with_modifiers += derivedState.state.display.price;
          new_starting_price += derivedState.state.display.starting_price;

          // only check if a child modifier is valid if its parent is selected
          if (modifier_selected_quantity > 0) {
            new_is_valid = new_is_valid && derivedState.state.display.is_valid;
          }
          if (first_invalid_group_id === undefined && !derivedState.state.display.is_valid) {
            first_invalid_group_id = childModifierGroupService.group.modifierGroupId;
          }

          derivedModifierGroupState.set(
            childModifierGroupService.group.modifierGroupId,
            derivedState
          );
        }

        new_price_with_modifiers *= modifier_selected_quantity;

        //free mods..
        let new_selected_free_mods = available_free_mods;
        let price_delta = new_price_with_modifiers;
        let new_free_quantity: number;
        if (group.freeModifierCount > 0 && modifier_selected_quantity >= 1 && price_delta !== 0) {
          const free_quantity = Math.max(
            0,
            Math.min(modifier_selected_quantity, available_free_mods)
          );
          const price_per_unit = modifierPrice;
          let free_mod_price: number;
          if (free_quantity === 0) {
            free_mod_price = price_per_unit * modifier_selected_quantity;
          } else {
            free_mod_price = price_per_unit * (modifier_selected_quantity - free_quantity);
          }

          new_free_quantity = free_quantity;

          new_selected_free_mods = available_free_mods - free_quantity;
          priced_quantity -= new_free_quantity;
          price_delta = free_mod_price;
        } else {
          new_free_quantity = 0;
        }
        is_valid = is_valid && new_is_valid;

        if (counted < group.selectMin && mod) {
          const remaining = group.selectMin - counted;
          const count = Math.floor(
            (remaining >= mod.maxQuantity ? mod.maxQuantity : remaining) / mod.incrementStep
          );
          starting_price +=
            (getPriceForMod(mod) + getPriceForExtraModifierCode(mod, mod_state)) * count;
          counted += count * mod.incrementStep;
        }

        const maxQuantity = mod!.maxQuantity;

        const isDefaultModifier = mod.defaultQuantity > 0;
        const isPizzaTopping = isModifierPizzaTopping();
        let negativeModifierCount =
          (neg_mod_behaviour === NegativeModifierBehavior.ShowDefaultModifiersAsPreSelected ||
            neg_mod_behaviour === NegativeModifierBehavior.ShowNegativeModifiersAsUnselected ||
            isPizzaTopping) &&
          !isModifier86 &&
          modifier_selected_quantity === 0 &&
          isDefaultModifier
            ? 1
            : 0;

        // const hasNegativeModifierCode = hasNegativeModifier(modService)[0];
        let hasNegativeModifierCode = false;

        if (mod_state.selectedModifierCode) {
          hasNegativeModifierCode = isContainsNegativeModifierCode([
            mod_state.selectedModifierCode,
          ]);
        }

        if (negativeModifierCount > 0) {
          hasNegativeModifierCode = isContainsNegativeModifierCode(mod.modifierCodes);
        }

        if (!hasNegativeModifierCode) {
          negativeModifierCount = 0;
        }

        const newNegativeModCount = negativeModifierQuantity + negativeModifierCount;

        const hidePrice =
          isDefaultModifier &&
          group.freeModifierCount === 0 &&
          !hasNegativeModifierCode &&
          !mod_state.selectedModifierCode;
        const shouldModifierPriceBeZero =
          isDefaultModifier &&
          group.freeModifierCount === 0 &&
          (!mod_state.selectedModifierCode ||
            (mod_state.selectedModifierCode &&
              mod_state.selectedModifierCode?.modifierCodeBehavior !== ModifierCodeBehavior.Extra));
        if (shouldModifierPriceBeZero) {
          price_delta = 0;
        }

        const derivedState: DerivedModifierState = {
          modifier: mod,
          state: {
            media: mediaStateManager.getItemMedia(mod.menuItem.menuItemId),
            modifierId: mod.modifierId,
            context: {
              // order_details: {
              //   menuItemId: mod.menuItem?.menuItemId ?? '',
              //   modifierId: mod.modifierId,
              //   optionGroupId: group.modifierGroupId,
              //   quantity: mod_state.quantity,
              //   transformRulesMetadata: mod.menuItem?.transformRulesMetadata ?? '',
              //   name: mod.menuItem?.name ?? '',
              //   modifierCode: '',
              //   freeQuantity: new_free_quantity,
              //   modifiers: itemModifiers,
              //   modifierCodeSelection,
              //   isDefault: mod.isDefault,
              //   unitPrice: {
              //     value: shouldModifierPriceBeZero ? 0 : modifierPrice,
              //   } as Int32Value,
              //   totalPrice: {
              //     value: shouldModifierPriceBeZero ? 0 : modifierPrice * mod_state.quantity,
              //   } as Int32Value,
              // },
              last_mod_group_id: lastModifierGroupId(mod.menuItem),
            },
            startingPrice: getPriceForMod(mod) + getPriceForExtraModifierCode(mod, mod_state),
            display: {
              ...mod_state,
              negativeModifierQuantity: negativeModifierCount,
              default_quantity: isModifier86 ? 0 : mod.defaultQuantity,
              free_quantity: new_free_quantity,
              is_valid: !isModifier86 && new_is_valid,
              starting_price: shouldModifierPriceBeZero ? 0 : new_starting_price,
              hide_price: hidePrice,
              price:
                (getPriceForMod(mod) + getPriceForExtraModifierCode(mod, mod_state)) *
                priced_quantity,
              can_decrease: modifier_selected_quantity > MIN_QUANTITY,
              can_increase: maxQuantity === 0 || modifier_selected_quantity < maxQuantity,
              out_of_stock: isModifier86,
              mod_groups: new Map(
                [...(modService?.modifierGroupServices?.values() ?? [])].map((x) => {
                  return [x.group.modifierGroupId, x];
                })
              ),
              hasNestedModifiers: (modService?.modifierGroupServices.size ?? 0) > 0,
              modifierCode: negativeModifierCount > 0 ? 'No ' : '',
              isUnselectedDefaultModifier: negativeModifierCount > 0,
              isSelectedDefaultModifier: isDefaultModifier && modifier_selected_quantity > 0,
              isSelectedNonDefaultModifier: !isDefaultModifier && modifier_selected_quantity > 0,
              isSelectedChildModifierGroupValid: isSelectedChildModifierGroupValid(
                mod_state,
                derivedModifierGroupState
              ),
            },
          },
          modifierGroups: derivedModifierGroupState,
        };

        mod_states.push(derivedState);

        return {
          counted,
          starting_price,
          // order_details:
          //   new_quantity === quantity
          //     ? negativeModifierCount > 0
          //       ? order_details.concat(derivedState.state.context.order_details)
          //       : order_details
          //     : order_details.concat(derivedState.state.context.order_details),
          price: price + price_delta,
          is_valid,
          quantity: new_quantity,
          available_free_mods: new_selected_free_mods,
          mod_states,
          negativeModifierQuantity: newNegativeModCount,
        };
      },
      {
        counted: 0,
        // order_details: [] as ItemModifier[],
        quantity: 0,
        price: 0,
        starting_price: 0,
        is_valid: true,
        available_free_mods: group.freeModifierCount,
        mod_states: [] as DerivedModifierState[],
        negativeModifierQuantity: 0,
      }
    );

  const remainingQuantity = !group.selectMax ? null : group.selectMax - quantity;
  return {
    modifierGroup: group,
    modifiersState: new Map<string, DerivedModifierState>(
      mod_states.map((m) => {
        return [m.state.modifierId, m];
      })
    ),
    state: {
      modifierGroupId: group.modifierGroupId,
      // order_details: order_details,
      display: {
        modifiers: service.modifierServices,
        isSelected: parentState.selectedGroupId === group.modifierGroupId,
        starting_price: starting_price,
        price: price,
        is_valid:
          is_valid &&
          quantity >= group.selectMin &&
          (!group.selectMax || quantity <= group.selectMax),
        quantity: quantity,
        remaining_quantity: remainingQuantity,
        hasReachedMax: remainingQuantity === null ? false : remainingQuantity > 0,
        selected_free_mods: Math.max(
          0,
          Math.min(Math.abs(available_free_mods - group.freeModifierCount), group.freeModifierCount)
        ),
        isContainsNegativeMod: negativeModifierQuantity > 0,
      },
    },
  };
};

/**
 * Returns no. of CartItems from CartStore
 * @param cartStore
 * @returns
 */
export const cartItemsQuantity = (cartStore: CartStoreState): number => {
  return Array.from(cartStore.cartItems.values()).reduce(
    (sum, curr) => sum + curr.getCurrentQuantity(),
    0
  );
};

/**
 * Returns no. of CartCombos from CartStore
 * @param cartStore
 * @returns
 */
export const cartCombosQuantity = (cartStore: CartStoreState): number => {
  return Array.from(cartStore.cartCombos.values()).reduce(
    (sum, curr) => sum + curr.getQuantity(),
    0
  );
};

/**
 * Check if the Order Limit is reached
 * @param quantityLimitPerOrder
 * @param cartStore
 * @returns TRUE | FALSE
 */
export const isOrderLimitReached = (
  quantityLimitPerOrder: number,
  cartStore: CartStoreState,
  itemStateQuantity: number
): boolean => {
  if (quantityLimitPerOrder === 0) return false;

  const itemsInCart = cartItemsQuantity(cartStore);
  const combosInCart = cartCombosQuantity(cartStore);
  if (itemsInCart === 0 && combosInCart === 0) {
    return quantityLimitPerOrder < itemsInCart + combosInCart + itemStateQuantity;
  } else {
    return quantityLimitPerOrder <= itemsInCart + combosInCart;
  }
};

/**
 * Check if the Item Limit is reached
 * @param quantityLimitPerItem
 * @param cartStore
 * @param item
 * @param itemState
 * @returns TRUE | FALSE
 */
export const isItemLimitReached = (
  quantityLimitPerOrder: number,
  quantityLimitPerItem: number,
  cartStore: CartStoreState,
  item: KioskMenuItem,
  itemState: ItemServiceInternalState
): boolean => {
  if (quantityLimitPerItem === 0) return false;

  const itemQtyInCart = Array.from(cartStore.cartItems.values())
    .filter((x) => x.item.menuItemId === item.menuItemId)
    .reduce((sum, curr) => sum + curr.getCurrentQuantity(), 0);
  const itemsInCart = cartItemsQuantity(cartStore);
  const combosInCart = cartCombosQuantity(cartStore);
  if (itemQtyInCart === 0) {
    if (quantityLimitPerOrder <= itemsInCart + combosInCart + itemState.quantity) return true;

    return quantityLimitPerItem <= itemQtyInCart + itemState.quantity;
  } else {
    if (quantityLimitPerOrder <= itemsInCart + combosInCart) return true;

    return quantityLimitPerItem <= itemQtyInCart;
  }
};

export const isComboLimitReached = (
  quantityLimitPerOrder: number,
  quantityLimitPerItem: number,
  cartStore: CartStoreState,
  combo: KioskCombo,
  comboQuantity: number
) => {
  if (quantityLimitPerItem === 0) return false;

  const comboQtyInCart = Array.from(cartStore.cartCombos.values())
    .filter((x) => x.combo.id === combo.id)
    .reduce((sum, curr) => sum + curr.getQuantity(), 0);

  const itemsInCart = cartItemsQuantity(cartStore);
  const combosInCart = cartCombosQuantity(cartStore);
  if (comboQtyInCart === 0) {
    if (quantityLimitPerOrder <= itemsInCart + combosInCart + comboQuantity) return true;

    return quantityLimitPerItem === comboQuantity || quantityLimitPerItem === comboQtyInCart;
  } else {
    if (quantityLimitPerOrder <= itemsInCart + combosInCart) return true;

    return quantityLimitPerItem === comboQtyInCart;
  }
};

export const createItemDerivedState = (
  itemState: ItemServiceInternalState,
  item: KioskMenuItem,
  eightySixItems: EightySixedItems,
  modifierGroupServices: Map<string, ModGroupService>,
  quantityLimitPerItem: number,
  quantityLimitPerOrder: number,
  cartStoreState: CartStoreState,
  pricesStateManager: IPricesStateManager,
  mediaStateManager: IMediaStateManager,
  itemSessionId: string
): ItemServiceStoreState => {
  let price =
    itemState.overridedStartingPrice ?? pricesStateManager.getPriceForItem(item.menuItemId)!;
  let starting_price =
    itemState.overridedStartingPrice ?? pricesStateManager.getPriceForItem(item.menuItemId)!;
  let is_valid = true;
  let first_invalid_group_id: string | undefined = undefined;
  // let itemModifiers: ItemModifier[] = [];
  const derivedModifierGroupState: DerivedModifierGroupsState = new Map();

  for (const [modifierGroupId, state] of itemState.mod_group_states) {
    const group = item.modifierGroups.find((g) => g.modifierGroupId === modifierGroupId);
    const modGroupService = modifierGroupServices.get(modifierGroupId);
    const derivedState = getDerivedStateForModGroup(
      item.menuItemId,
      mediaStateManager,
      pricesStateManager,
      eightySixItems,
      {
        selectedGroupId: itemState.selected_group_id,
      },
      state,
      modGroupService!,
      group!
    );
    price += derivedState.state.display.price;
    starting_price += derivedState.state.display.starting_price;
    is_valid = is_valid && derivedState.state.display.is_valid;
    if (first_invalid_group_id === undefined && !derivedState.state.display.is_valid) {
      first_invalid_group_id = item.modifierGroups[0]?.modifierGroupId;
    }

    derivedModifierGroupState.set(modifierGroupId, derivedState);
  }

  const modifierGroups = [...modifierGroupServices.values()];

  const orderLimitReachedCheck = isOrderLimitReached(
    quantityLimitPerOrder,
    cartStoreState,
    itemState.quantity
  );
  const itemQuantityLimitCheck = orderLimitReachedCheck
    ? true
    : isItemLimitReached(
        quantityLimitPerOrder,
        quantityLimitPerItem,
        cartStoreState,
        item,
        itemState
      );
  const isItem86 = eightySixItems.has(item.menuItemId);
  return {
    derivedModifierGroupState,
    media: mediaStateManager.getItemMedia(item.menuItemId),
    context: {
      sourceCategory: itemState.sourceCategory,
      price_with_modifiers: price,
      // order_details: {
      //   ...orderDetails,
      //   modifiers: itemModifiers,
      // },
      last_mod_group_id:
        item.modifierGroups.length === 0
          ? ''
          : item.modifierGroups[item.modifierGroups.length - 1].modifierGroupId,
    },
    modifierGroups,
    selectedModifierGroupIndex: modifierGroups.findIndex(
      (x) => x.group.modifierGroupId === itemState.selected_group_id
    ),
    display: {
      is_valid: is_valid && !isItem86,
      can_decrease: itemState.quantity > MIN_QUANTITY,
      quantity: itemState.quantity,
      out_of_stock: isItem86,
      can_increase: true,
      unitPrice: price,
      price: price * itemState.quantity,
      first_invalid_group_id: first_invalid_group_id,
      starting_price: starting_price,
      selected_group_id: itemState.selected_group_id,
      special_request: itemState.special_request,
      cartItemId: itemState.cartItemId,
      selPromotionalOption: itemState.promotionalOption,
      hasModifierGroups: modifierGroups.length > 0,
      itemLimitReached: itemQuantityLimitCheck,
      orderLimitReached: orderLimitReachedCheck,
      isSelectedChildModifierGroupValid: isSelectedChildModifierGroupValid(
        itemState,
        derivedModifierGroupState
      ),
    },
    itemSessionId,
  };
};

export const shouldShowModifierOnCheckout = (
  modifier: ModifierService,
  appearance: AppearanceConfig
) => {
  const modifierState = get(modifier);

  // always show selected modifiers that are not default
  if (modifierState.display.isSelectedNonDefaultModifier) {
    return true;
  }

  // show selected default modifiers when that setting is on
  if (
    (appearance.styleOptions?.orderReviewShowDefaultModifiers &&
      modifierState.display.isSelectedDefaultModifier) ||
    (modifierState.display.isSelectedDefaultModifier && modifierState.display.quantity > 1)
  ) {
    return true;
  }

  // show modifiers with attached modifier codes
  if (
    (modifierState.display.isSelectedNonDefaultModifier ||
      modifierState.display.isSelectedDefaultModifier) &&
    modifierState.display?.selectedModifierCode?.name
  ) {
    return true;
  }

  // always show unselected default modifiers
  if (modifierState.display.isUnselectedDefaultModifier) {
    return true;
  }

  return false;
};
// ModGroupServiceContext
export const shouldShowModifierPrice = (
  modifier: KioskModifier,
  modState: ModifierServiceDerivedState,
  modifierGroup: KioskModifierGroup | undefined,
  modGroup: ModGroupServiceContext
) => {
  if (!modState || !modGroup || !modifierGroup) {
    return true;
  }
  const freeModifierCount = modifierGroup.freeModifierCount;
  const selectedFreeMods = modGroup.display.selected_free_mods;
  const isFree = selectedFreeMods < freeModifierCount;
  const freeQuantity = modState.display.free_quantity;
  const selectedModifierCode = modState.display.selectedModifierCode;

  const showPrice =
    modState.startingPrice > 0 &&
    !isFree &&
    (freeQuantity < 1 || modState.display.quantity > freeQuantity) &&
    (modifier.isDefault && selectedModifierCode ? isExtraModifierCode(selectedModifierCode) : true);
  return showPrice;
};

export const scheduleCheck = (categories: KioskCategory[], currentDate: Date) => {
  const currentTime = currentDate.getTime();
  const currentDay = currentDate.getDay();
  return categories.filter((category) => {
    if (category.subCategories?.length > 0) {
      category.subCategories = scheduleCheck(category.subCategories, currentDate);
    }

    category.displayableItems = checkItemSchedule(
      category.displayableItems,
      currentDay,
      currentTime
    );

    if (!category.timeSchedule?.isScheduled) return category;

    return getAvailableScheduledTime(category.timeSchedule.availableTimes, currentDay, currentTime);
  });
};

export const checkItemSchedule = (
  displayableItems: KioskCategoryDisplayableItem[],
  currentDay: number,
  currentTime: number
) => {
  const scheduledItems = displayableItems?.filter((item) => {
    if (item.type === 'item') {
      if (!item.item.timeSchedule.isScheduled) return item;

      return getAvailableScheduledTime(
        item.item.timeSchedule.availableTimes,
        currentDay,
        currentTime
      );
    } else if (item.type === 'combo') {
      if (!item.combo.timeSchedule.isScheduled) return item;

      return getAvailableScheduledTime(
        item.combo.timeSchedule.availableTimes,
        currentDay,
        currentTime
      );
    }
  });
  return scheduledItems;
};

export const getAvailableScheduledTime = (
  availableTimes: KioskAvailableTimes[] | undefined,
  currentDay: number,
  currentTime: number
) => {
  if (!availableTimes) return true;

  return (
    availableTimes
      .filter((time) => time.startDayOfWeek >= currentDay && time.endDayOfWeek <= currentDay)
      .map((timeOftheDay) => {
        const startTime = convertTime(timeOftheDay.startTime);
        const endTime = convertTime(timeOftheDay.endTime);

        if (startTime && endTime) {
          return currentTime >= startTime && currentTime <= endTime;
          // we only hit this if start time is set and end time is unable to be parsed
        } else if (startTime) {
          return currentTime >= startTime;
          // we only hit this if end time is set and start time is unable to be parsed
        } else if (endTime) {
          return currentTime <= endTime;
        } else {
          return true;
        }
      })
      .filter((time) => time).length > 0
  );
};

export const convertTime = (ts: string) => {
  if (!ts) {
    return undefined;
  }

  const targetTime = new Date();
  const [hours, minutes] = ts.split(':');
  targetTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);
  return targetTime.getTime();
};
