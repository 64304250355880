/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "menu.proto" (package "Kiosk", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ConditionsVM } from "./common_pb";
import { OptionalDayOfTheDayOfWeekVM } from "./common_pb";
import { ModifierCodeBehavior } from "./common_pb";
import { Int32Value } from "./google/protobuf/wrappers_pb";
import { BoolValue } from "./google/protobuf/wrappers_pb";
import { StringValue } from "./google/protobuf/wrappers_pb";
import { NullValue } from "./google/protobuf/struct_pb";
/**
 * @generated from protobuf message Kiosk.Menu
 */
export interface Menu {
    /**
     * @generated from protobuf field: repeated Kiosk.Category categories = 1;
     */
    categories: Category[];
    /**
     * @generated from protobuf field: repeated Kiosk.MenuItem menuItems = 2;
     */
    menuItems: MenuItem[];
    /**
     * @generated from protobuf field: repeated Kiosk.ModifierGroup modifierGroups = 3;
     */
    modifierGroups: ModifierGroup[];
    /**
     * @generated from protobuf field: repeated Kiosk.Modifier modifiers = 4;
     */
    modifiers: Modifier[];
    /**
     * @deprecated
     * @generated from protobuf field: Kiosk.PriceBooks pricebooks = 5 [deprecated = true];
     */
    pricebooks?: PriceBooks;
    /**
     * @generated from protobuf field: repeated Kiosk.ModifierCode modifierCodes = 6;
     */
    modifierCodes: ModifierCode[];
    /**
     * @generated from protobuf field: repeated Kiosk.MenuComboFamily comboFamilies = 7;
     */
    comboFamilies: MenuComboFamily[];
    /**
     * @generated from protobuf field: repeated Kiosk.MenuCombo combos = 8;
     */
    combos: MenuCombo[];
    /**
     * @generated from protobuf field: repeated Kiosk.MenuComboComponent comboComponents = 9;
     */
    comboComponents: MenuComboComponent[];
}
/**
 * @generated from protobuf message Kiosk.SectionMenuItemVM
 */
export interface SectionMenuItemVM {
    /**
     * @generated from protobuf field: string menuItemId = 1;
     */
    menuItemId: string;
}
/**
 * @generated from protobuf message Kiosk.MenuLayout
 */
export interface MenuLayout {
    /**
     * @generated from protobuf field: string menuLayoutId = 1;
     */
    menuLayoutId: string;
    /**
     * @generated from protobuf field: repeated Kiosk.CategoryLayout categories = 2;
     */
    categories: CategoryLayout[];
}
/**
 * @generated from protobuf message Kiosk.CategoryLayout
 */
export interface CategoryLayout {
    /**
     * @generated from protobuf field: string categoryId = 1;
     */
    categoryId: string;
    /**
     * @generated from protobuf field: repeated string menuItemIds = 2;
     */
    menuItemIds: string[];
    /**
     * @generated from protobuf field: repeated Kiosk.CategoryLayout subCategories = 4;
     */
    subCategories: CategoryLayout[];
    /**
     * @generated from protobuf field: int32 numberOfColumns = 5;
     */
    numberOfColumns: number;
    /**
     * @generated from protobuf field: bool loyaltyOnly = 6;
     */
    loyaltyOnly: boolean;
    /**
     * @generated from protobuf field: repeated Kiosk.CategoryLayoutDisplayableItem displayableItems = 7;
     */
    displayableItems: CategoryLayoutDisplayableItem[];
    /**
     * @generated from protobuf field: Kiosk.EntityAvailability availability = 8;
     */
    availability?: EntityAvailability;
}
/**
 * @generated from protobuf message Kiosk.EntityAvailability
 */
export interface EntityAvailability {
    /**
     * @generated from protobuf oneof: availability
     */
    availability: {
        oneofKind: "alwaysAvailable";
        /**
         * @generated from protobuf field: google.protobuf.NullValue alwaysAvailable = 1;
         */
        alwaysAvailable: NullValue;
    } | {
        oneofKind: "schedule";
        /**
         * @generated from protobuf field: Kiosk.MenuAvailabilityScheduleVM schedule = 2;
         */
        schedule: MenuAvailabilityScheduleVM;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message Kiosk.CategoryLayoutDisplayableItem
 */
export interface CategoryLayoutDisplayableItem {
    /**
     * @generated from protobuf field: string menuItemId = 1;
     */
    menuItemId: string;
    /**
     * @generated from protobuf field: string comboId = 2;
     */
    comboId: string;
    /**
     * @generated from protobuf field: string comboFamilyId = 3;
     */
    comboFamilyId: string;
    /**
     * @generated from protobuf field: Kiosk.CategoryLayout subCategory = 4;
     */
    subCategory?: CategoryLayout;
}
/**
 * @generated from protobuf message Kiosk.PriceBooks
 */
export interface PriceBooks {
    /**
     * @generated from protobuf field: map<string, Kiosk.PriceBook> books = 1;
     */
    books: {
        [key: string]: PriceBook;
    };
}
/**
 * @generated from protobuf message Kiosk.PriceBook
 */
export interface PriceBook {
    /**
     * @generated from protobuf field: map<string, int32> prices = 1;
     */
    prices: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message Kiosk.Category
 */
export interface Category {
    /**
     * @generated from protobuf field: string categoryId = 1;
     */
    categoryId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated string media = 3;
     */
    media: string[];
    /**
     * @generated from protobuf field: string selectedImage = 4;
     */
    selectedImage: string;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedName = 5;
     */
    localizedName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet displayNameLocalized = 6;
     */
    displayNameLocalized?: LocalizedStringSet;
}
/**
 * @generated from protobuf message Kiosk.LocalizedStringSet
 */
export interface LocalizedStringSet {
    /**
     * @generated from protobuf field: map<string, string> translations = 1;
     */
    translations: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message Kiosk.MenuItem
 */
export interface MenuItem {
    /**
     * @generated from protobuf field: string menuItemId = 1;
     */
    menuItemId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated string media = 3;
     */
    media: string[];
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * @generated from protobuf field: repeated string modifierGroupIds = 5;
     */
    modifierGroupIds: string[];
    /**
     * @generated from protobuf field: string transformRulesMetadata = 6;
     */
    transformRulesMetadata: string;
    /**
     * @generated from protobuf field: Kiosk.MenuItemDisplayFlow flow = 7;
     */
    flow: MenuItemDisplayFlow;
    /**
     * @generated from protobuf field: string barcode = 8;
     */
    barcode: string;
    /**
     * @generated from protobuf field: string calorieText = 9;
     */
    calorieText: string;
    /**
     * @generated from protobuf field: string selectedImage = 10;
     */
    selectedImage: string;
    /**
     * @generated from protobuf field: string selectedDisplayName = 11;
     */
    selectedDisplayName: string;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedName = 12;
     */
    localizedName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedDescription = 13;
     */
    localizedDescription?: LocalizedStringSet;
    /**
     * @generated from protobuf field: int32 price = 14;
     */
    price: number;
    /**
     * @generated from protobuf field: repeated Kiosk.MenuItemModifierOverrides modifierOverrides = 15;
     */
    modifierOverrides: MenuItemModifierOverrides[];
    /**
     * @generated from protobuf field: Kiosk.MenuItemPromotionalDetails promotionalDetails = 16;
     */
    promotionalDetails?: MenuItemPromotionalDetails;
    /**
     * @generated from protobuf field: Kiosk.EntityAvailability availability = 17;
     */
    availability?: EntityAvailability;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedDisplayName = 18;
     */
    localizedDisplayName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: string itemExternalId = 19;
     */
    itemExternalId: string;
    /**
     * @generated from protobuf field: string posData = 20;
     */
    posData: string; // pos metadata needed for agents
    /**
     * @generated from protobuf field: Kiosk.MenuItemDomainSpecificAttributes domainSpecificAttributes = 21;
     */
    domainSpecificAttributes?: MenuItemDomainSpecificAttributes;
    /**
     * @generated from protobuf field: bool isVariablyPriced = 22;
     */
    isVariablyPriced: boolean;
    /**
     * @generated from protobuf field: repeated Kiosk.MenuItemModifierGroup menuItemModifierGroup = 23;
     */
    menuItemModifierGroup: MenuItemModifierGroup[];
}
/**
 * @generated from protobuf message Kiosk.MenuItemModifierGroup
 */
export interface MenuItemModifierGroup {
    /**
     * @generated from protobuf field: string modifierGroupId = 1;
     */
    modifierGroupId: string;
    /**
     * @generated from protobuf field: int32 maxSelection = 2;
     */
    maxSelection: number;
    /**
     * @generated from protobuf field: int32 minSelection = 3;
     */
    minSelection: number;
    /**
     * @generated from protobuf field: double freeCount = 4;
     */
    freeCount: number;
    /**
     * @generated from protobuf field: bool isActive = 5;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: bool isInvisible = 6;
     */
    isInvisible: boolean;
}
/**
 * @generated from protobuf message Kiosk.MenuItemDomainSpecificAttributes
 */
export interface MenuItemDomainSpecificAttributes {
    /**
     * @generated from protobuf oneof: attributes
     */
    attributes: {
        oneofKind: "pizzaAttributes";
        /**
         * @generated from protobuf field: Kiosk.MenuItemPizzaSpecificAttributes pizzaAttributes = 1;
         */
        pizzaAttributes: MenuItemPizzaSpecificAttributes;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message Kiosk.MenuModiferDomainSpecificAttributes
 */
export interface MenuModiferDomainSpecificAttributes {
    /**
     * @generated from protobuf oneof: attributes
     */
    attributes: {
        oneofKind: "pizzaAttributes";
        /**
         * @generated from protobuf field: Kiosk.MenuItemPizzaSpecificAttributes pizzaAttributes = 1;
         */
        pizzaAttributes: MenuItemPizzaSpecificAttributes;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message Kiosk.MenuItemPizzaSpecificAttributes
 */
export interface MenuItemPizzaSpecificAttributes {
    /**
     * @generated from protobuf field: map<string, string> backgroundPreviewImages = 1;
     */
    backgroundPreviewImages: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: map<string, string> toppingPreviewImages = 2;
     */
    toppingPreviewImages: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message Kiosk.MenuItemPromotionalDetails
 */
export interface MenuItemPromotionalDetails {
    /**
     * @generated from protobuf field: repeated string triggerCodes = 1;
     */
    triggerCodes: string[];
    /**
     * @generated from protobuf field: bool isExclusivelyPromotionalItem = 2;
     */
    isExclusivelyPromotionalItem: boolean;
}
/**
 * @generated from protobuf message Kiosk.MenuItemModifierOverrides
 */
export interface MenuItemModifierOverrides {
    /**
     * @generated from protobuf field: google.protobuf.StringValue modifierGroupId = 2;
     */
    modifierGroupId?: StringValue; // modifier group uses StringValue b/c it can be nullable
    /**
     * @generated from protobuf field: string modifierId = 3;
     */
    modifierId: string;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isDefault = 4;
     */
    isDefault?: BoolValue;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value defaultQuantity = 5;
     */
    defaultQuantity?: Int32Value;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isHidden = 6;
     */
    isHidden?: BoolValue;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value maxQuantity = 7;
     */
    maxQuantity?: Int32Value;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value incrementStep = 8;
     */
    incrementStep?: Int32Value;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue hideNestedModifiers = 9;
     */
    hideNestedModifiers?: BoolValue;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isIncludedInItemPrice = 11;
     */
    isIncludedInItemPrice?: BoolValue;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isActive = 12;
     */
    isActive?: BoolValue;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isInvisible = 13;
     */
    isInvisible?: BoolValue;
}
/**
 * @generated from protobuf message Kiosk.ModifierGroup
 */
export interface ModifierGroup {
    /**
     * @generated from protobuf field: string modifierGroupId = 1;
     */
    modifierGroupId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 selectMin = 3;
     */
    selectMin: number;
    /**
     * @generated from protobuf field: int32 selectMax = 4;
     */
    selectMax: number;
    /**
     * @generated from protobuf field: repeated string modifierIds = 5;
     */
    modifierIds: string[];
    /**
     * @generated from protobuf field: int32 freeModifierCount = 6;
     */
    freeModifierCount: number;
    /**
     * @generated from protobuf field: string displayName = 7;
     */
    displayName: string;
    /**
     * @generated from protobuf field: Kiosk.NegativeModifierBehavior negativeModifierBehavior = 8;
     */
    negativeModifierBehavior: NegativeModifierBehavior;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedName = 9;
     */
    localizedName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedDisplayName = 10;
     */
    localizedDisplayName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: repeated Kiosk.GroupModifierCodes groupModifierCodes = 11;
     */
    groupModifierCodes: GroupModifierCodes[];
    /**
     * @generated from protobuf field: google.protobuf.StringValue posId = 12;
     */
    posId?: StringValue;
    /**
     * @generated from protobuf field: Kiosk.ModifierGroupDomainSpecificAttributes domainSpecificAttributes = 13;
     */
    domainSpecificAttributes?: ModifierGroupDomainSpecificAttributes;
    /**
     * @generated from protobuf field: string shortcutName = 14;
     */
    shortcutName: string;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedShortcutName = 15;
     */
    localizedShortcutName?: LocalizedStringSet;
}
/**
 * @generated from protobuf message Kiosk.ModifierGroupDomainSpecificAttributes
 */
export interface ModifierGroupDomainSpecificAttributes {
    /**
     * @generated from protobuf oneof: attributes
     */
    attributes: {
        oneofKind: "pizzaAttributes";
        /**
         * @generated from protobuf field: Kiosk.ModifierGroupPizzaSpecificAttributes pizzaAttributes = 1;
         */
        pizzaAttributes: ModifierGroupPizzaSpecificAttributes;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message Kiosk.ModifierGroupPizzaSpecificAttributes
 */
export interface ModifierGroupPizzaSpecificAttributes {
    /**
     * @generated from protobuf field: Kiosk.ModifierGroupPizzaSpecificAttributes.ModifierGroupPizzaSpecificType type = 1;
     */
    type: ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType;
}
/**
 * @generated from protobuf enum Kiosk.ModifierGroupPizzaSpecificAttributes.ModifierGroupPizzaSpecificType
 */
export enum ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType {
    /**
     * @generated from protobuf enum value: Unknown = 0;
     */
    Unknown = 0,
    /**
     * @generated from protobuf enum value: Crust = 1;
     */
    Crust = 1,
    /**
     * @generated from protobuf enum value: Sauce = 2;
     */
    Sauce = 2,
    /**
     * @generated from protobuf enum value: Topping = 3;
     */
    Topping = 3,
    /**
     * @generated from protobuf enum value: Coverage = 4;
     */
    Coverage = 4,
    /**
     * @generated from protobuf enum value: Size = 5;
     */
    Size = 5
}
/**
 * @generated from protobuf message Kiosk.Modifier
 */
export interface Modifier {
    /**
     * @generated from protobuf field: string modifierId = 1;
     */
    modifierId: string;
    /**
     * @generated from protobuf field: string menuItemId = 2;
     */
    menuItemId: string;
    /**
     * @generated from protobuf field: bool isDefault = 3;
     */
    isDefault: boolean;
    /**
     * @generated from protobuf field: int32 maxQuantity = 4;
     */
    maxQuantity: number;
    /**
     * @generated from protobuf field: int32 incrementStep = 5;
     */
    incrementStep: number;
    /**
     * @generated from protobuf field: bool allowQuantityIncrement = 6;
     */
    allowQuantityIncrement: boolean;
    /**
     * @generated from protobuf field: int32 defaultQuantity = 7;
     */
    defaultQuantity: number;
    /**
     * @generated from protobuf field: string calorieText = 8;
     */
    calorieText: string;
    /**
     * @generated from protobuf field: bool isInvisible = 9;
     */
    isInvisible: boolean;
    /**
     * @generated from protobuf field: repeated Kiosk.ModifierModifierCodeGlue modifierCodes = 11;
     */
    modifierCodes: ModifierModifierCodeGlue[];
    /**
     * @generated from protobuf field: string selectedDisplayName = 12;
     */
    selectedDisplayName: string;
    /**
     * @generated from protobuf field: int32 price = 13;
     */
    price: number;
    /**
     * @generated from protobuf field: int32 displayOrder = 14;
     */
    displayOrder: number;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedName = 15;
     */
    localizedName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedDescription = 16;
     */
    localizedDescription?: LocalizedStringSet;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedDisplayName = 17;
     */
    localizedDisplayName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: Kiosk.MenuModiferDomainSpecificAttributes domainSpecificAttributes = 18;
     */
    domainSpecificAttributes?: MenuModiferDomainSpecificAttributes;
    /**
     * @generated from protobuf field: bool HasShortcutName = 19 [json_name = "HasShortcutName"];
     */
    hasShortcutName: boolean;
}
/**
 * @generated from protobuf message Kiosk.GroupModifierCodes
 */
export interface GroupModifierCodes {
    /**
     * @generated from protobuf field: string modifierId = 1;
     */
    modifierId: string;
    /**
     * @generated from protobuf field: repeated Kiosk.ModifierModifierCodeGlue modifierCodes = 11;
     */
    modifierCodes: ModifierModifierCodeGlue[];
}
/**
 * @generated from protobuf message Kiosk.ModifierModifierCodeGlue
 */
export interface ModifierModifierCodeGlue {
    /**
     * @generated from protobuf field: string modifierCodeId = 1;
     */
    modifierCodeId: string;
    /**
     * @generated from protobuf field: bool isDefault = 2;
     */
    isDefault: boolean;
}
/**
 * @generated from protobuf message Kiosk.ModifierCode
 */
export interface ModifierCode {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: Kiosk.ModifierCodeBehavior modifierCodeBehavior = 3;
     */
    modifierCodeBehavior: ModifierCodeBehavior;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedName = 4;
     */
    localizedName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedDisplayName = 5;
     */
    localizedDisplayName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: int32 DisplayOrder = 6 [json_name = "DisplayOrder"];
     */
    displayOrder: number;
}
/**
 * @generated from protobuf message Kiosk.MenuComboFamily
 */
export interface MenuComboFamily {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: string selectedImagePath = 4;
     */
    selectedImagePath: string;
    /**
     * @generated from protobuf field: repeated Kiosk.MenuComboFamilyMember members = 5;
     */
    members: MenuComboFamilyMember[];
    /**
     * @generated from protobuf field: int32 startingPrice = 6;
     */
    startingPrice: number;
    /**
     * @generated from protobuf field: string calorieText = 7;
     */
    calorieText: string;
    /**
     * @generated from protobuf field: string selectedDisplayName = 8;
     */
    selectedDisplayName: string;
    /**
     * @generated from protobuf field: string listingImagePath = 9;
     */
    listingImagePath: string;
    /**
     * @generated from protobuf field: Kiosk.MenuItemPromotionalDetails promotionalDetails = 10;
     */
    promotionalDetails?: MenuItemPromotionalDetails;
    /**
     * @generated from protobuf field: Kiosk.EntityAvailability availability = 11;
     */
    availability?: EntityAvailability;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedName = 12;
     */
    localizedName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedDescription = 13;
     */
    localizedDescription?: LocalizedStringSet;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedDisplayName = 14;
     */
    localizedDisplayName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: bool isVariablyPriced = 15;
     */
    isVariablyPriced: boolean;
}
/**
 * @generated from protobuf message Kiosk.MenuComboFamilyMember
 */
export interface MenuComboFamilyMember {
    /**
     * @generated from protobuf oneof: comboFamilyMember
     */
    comboFamilyMember: {
        oneofKind: "menuComboId";
        /**
         * @generated from protobuf field: string menuComboId = 1;
         */
        menuComboId: string;
    } | {
        oneofKind: "menuItemId";
        /**
         * @generated from protobuf field: string menuItemId = 2;
         */
        menuItemId: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: bool isDefault = 3;
     */
    isDefault: boolean;
    /**
     * @generated from protobuf field: int32 displayOrder = 4;
     */
    displayOrder: number;
}
/**
 * @generated from protobuf message Kiosk.MenuCombo
 */
export interface MenuCombo {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: string selectedImagePath = 5;
     */
    selectedImagePath: string;
    /**
     * @deprecated
     * @generated from protobuf field: repeated string menuComboComponentIds = 7 [deprecated = true];
     */
    menuComboComponentIds: string[];
    /**
     * @generated from protobuf field: int32 startingPrice = 8;
     */
    startingPrice: number;
    /**
     * @generated from protobuf field: string calorieText = 9;
     */
    calorieText: string;
    /**
     * @generated from protobuf field: string listingImagePath = 10;
     */
    listingImagePath: string;
    /**
     * @generated from protobuf field: string selectedDisplayName = 11;
     */
    selectedDisplayName: string;
    /**
     * @generated from protobuf field: Kiosk.MenuItemPromotionalDetails promotionalDetails = 12;
     */
    promotionalDetails?: MenuItemPromotionalDetails;
    /**
     * @generated from protobuf field: Kiosk.EntityAvailability availability = 13;
     */
    availability?: EntityAvailability;
    /**
     * @generated from protobuf field: string sizeLabel = 14;
     */
    sizeLabel: string;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedName = 15;
     */
    localizedName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedDescription = 16;
     */
    localizedDescription?: LocalizedStringSet;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedSizeLabel = 17;
     */
    localizedSizeLabel?: LocalizedStringSet;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedDisplayName = 18;
     */
    localizedDisplayName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: repeated Kiosk.MenuComboComponentGlue menuComboComponent = 19;
     */
    menuComboComponent: MenuComboComponentGlue[];
    /**
     * @generated from protobuf field: repeated Kiosk.MenuComboItemModifierGroupOverride itemModifierGroupOverrides = 20;
     */
    itemModifierGroupOverrides: MenuComboItemModifierGroupOverride[];
    /**
     * @generated from protobuf field: repeated Kiosk.MenuComboItemModifierOverride itemModifierOverrides = 21;
     */
    itemModifierOverrides: MenuComboItemModifierOverride[];
    /**
     * @generated from protobuf field: bool isVariablyPriced = 22;
     */
    isVariablyPriced: boolean;
    /**
     * @generated from protobuf field: google.protobuf.StringValue posId = 23;
     */
    posId?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue posData = 24;
     */
    posData?: StringValue; // pos metadata needed for agents
}
/**
 * @generated from protobuf message Kiosk.MenuComboItemModifierGroupOverride
 */
export interface MenuComboItemModifierGroupOverride {
    /**
     * @generated from protobuf field: string menuItemId = 1;
     */
    menuItemId: string;
    /**
     * @generated from protobuf field: string modifierGroupId = 2;
     */
    modifierGroupId: string;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isInvisible = 3;
     */
    isInvisible?: BoolValue;
    /**
     * @generated from protobuf field: double freeModifierCount = 4;
     */
    freeModifierCount: number;
    /**
     * @generated from protobuf field: string comboComponentId = 5;
     */
    comboComponentId: string;
}
/**
 * @generated from protobuf message Kiosk.MenuComboItemModifierOverride
 */
export interface MenuComboItemModifierOverride {
    /**
     * @generated from protobuf field: string menuItemId = 1;
     */
    menuItemId: string;
    /**
     * @generated from protobuf field: string modifierId = 2;
     */
    modifierId: string;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isDefault = 3;
     */
    isDefault?: BoolValue;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value price = 4;
     */
    price?: Int32Value;
}
/**
 * @generated from protobuf message Kiosk.MenuComboComponentGlue
 */
export interface MenuComboComponentGlue {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: int32 displayOrder = 2;
     */
    displayOrder: number;
    /**
     * @generated from protobuf field: bool isDefault = 3;
     */
    isDefault: boolean;
    /**
     * @generated from protobuf field: bool isHidden = 4;
     */
    isHidden: boolean;
}
/**
 * @generated from protobuf message Kiosk.MenuComboComponent
 */
export interface MenuComboComponent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: Kiosk.MenuComboComponentType type = 3;
     */
    type: MenuComboComponentType;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value maxSelectionCount = 4;
     */
    maxSelectionCount?: Int32Value;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value minSelectionCount = 5;
     */
    minSelectionCount?: Int32Value;
    /**
     * @generated from protobuf field: repeated string menuItemIdChoices = 6;
     */
    menuItemIdChoices: string[];
    /**
     * @generated from protobuf field: string selectedImagePath = 7;
     */
    selectedImagePath: string;
    /**
     * @generated from protobuf field: string listingImagePath = 8;
     */
    listingImagePath: string;
    /**
     * @generated from protobuf field: string selectedDisplayName = 9;
     */
    selectedDisplayName: string;
    /**
     * @generated from protobuf field: repeated Kiosk.MenuComboComponentChoice choices = 10;
     */
    choices: MenuComboComponentChoice[];
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedName = 11;
     */
    localizedName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: Kiosk.LocalizedStringSet localizedDisplayName = 12;
     */
    localizedDisplayName?: LocalizedStringSet;
    /**
     * @generated from protobuf field: repeated Kiosk.MenuComboComponentGrouping choiceGroups = 13;
     */
    choiceGroups: MenuComboComponentGrouping[];
    /**
     * @generated from protobuf field: google.protobuf.StringValue posId = 14;
     */
    posId?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue posData = 15;
     */
    posData?: StringValue; // pos metadata needed for agents
}
/**
 * @generated from protobuf message Kiosk.MenuComboComponentGrouping
 */
export interface MenuComboComponentGrouping {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message Kiosk.MenuComboComponentChoice
 */
export interface MenuComboComponentChoice {
    /**
     * @generated from protobuf field: string menuItemId = 1;
     */
    menuItemId: string;
    /**
     * @generated from protobuf field: bool isDefault = 2;
     */
    isDefault: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value price = 3;
     */
    price?: Int32Value;
    /**
     * @generated from protobuf field: google.protobuf.StringValue groupingId = 4;
     */
    groupingId?: StringValue;
}
/**
 * @generated from protobuf message Kiosk.MenuVM
 */
export interface MenuVM {
    /**
     * @generated from protobuf field: Kiosk.OrderUpsellGroupListVM orderUpsellGroups = 3;
     */
    orderUpsellGroups?: OrderUpsellGroupListVM;
    /**
     * @generated from protobuf field: Kiosk.ItemUpsellGroupListVM itemUpsellGroups = 4;
     */
    itemUpsellGroups?: ItemUpsellGroupListVM;
    /**
     * @generated from protobuf field: repeated string item86List = 5;
     */
    item86List: string[];
    /**
     * @generated from protobuf field: Kiosk.ItemUpsellMappingsVM itemUpsellMappings = 6;
     */
    itemUpsellMappings?: ItemUpsellMappingsVM;
}
/**
 * @generated from protobuf message Kiosk.MenuAvailabilityScheduleAvailableTimeVM
 */
export interface MenuAvailabilityScheduleAvailableTimeVM {
    /**
     * @generated from protobuf field: google.protobuf.StringValue startTime = 1;
     */
    startTime?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue endTime = 2;
     */
    endTime?: StringValue;
    /**
     * @generated from protobuf field: Kiosk.OptionalDayOfTheDayOfWeekVM startDayOfWeek = 3;
     */
    startDayOfWeek?: OptionalDayOfTheDayOfWeekVM;
    /**
     * @generated from protobuf field: Kiosk.OptionalDayOfTheDayOfWeekVM endDayOfWeek = 4;
     */
    endDayOfWeek?: OptionalDayOfTheDayOfWeekVM;
}
/**
 * @generated from protobuf message Kiosk.MenuAvailabilityScheduleVM
 */
export interface MenuAvailabilityScheduleVM {
    /**
     * @generated from protobuf field: repeated Kiosk.MenuAvailabilityScheduleAvailableTimeVM availableTimes = 1;
     */
    availableTimes: MenuAvailabilityScheduleAvailableTimeVM[];
}
/**
 * @generated from protobuf message Kiosk.UpsellConfiguration
 */
export interface UpsellConfiguration {
    /**
     * @generated from protobuf field: Kiosk.OrderUpsellGroupListVM orderUpsellGroups = 3;
     */
    orderUpsellGroups?: OrderUpsellGroupListVM;
    /**
     * @generated from protobuf field: Kiosk.ItemUpsellGroupListVM itemUpsellGroups = 4;
     */
    itemUpsellGroups?: ItemUpsellGroupListVM;
    /**
     * @generated from protobuf field: Kiosk.ItemUpsellMappingsVM itemUpsellMappings = 6;
     */
    itemUpsellMappings?: ItemUpsellMappingsVM;
}
/**
 * @generated from protobuf message Kiosk.OrderUpsellGroupListVM
 */
export interface OrderUpsellGroupListVM {
    /**
     * @generated from protobuf field: repeated Kiosk.OrderUpsellGroupVM groups = 1;
     */
    groups: OrderUpsellGroupVM[];
    /**
     * @generated from protobuf field: bool enabled = 2;
     */
    enabled: boolean;
}
/**
 * @generated from protobuf message Kiosk.OrderUpsellOfferVM
 */
export interface OrderUpsellOfferVM {
    /**
     * @generated from protobuf oneof: id
     */
    id: {
        oneofKind: "menuItemId";
        /**
         * @deprecated
         * @generated from protobuf field: string menuItemId = 1 [deprecated = true];
         */
        menuItemId: string;
    } | {
        oneofKind: "categoryId";
        /**
         * @deprecated
         * @generated from protobuf field: string categoryId = 2 [deprecated = true];
         */
        categoryId: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: Kiosk.SectionMenuItemVM item = 3;
     */
    item?: SectionMenuItemVM;
    /**
     * @generated from protobuf field: string sectionId = 4;
     */
    sectionId: string;
    /**
     * @generated from protobuf field: string comboId = 5;
     */
    comboId: string;
}
/**
 * @generated from protobuf message Kiosk.OrderUpsellGroupPropertiesVM
 */
export interface OrderUpsellGroupPropertiesVM {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string id = 2;
     */
    id: string;
    /**
     * @generated from protobuf field: bool enabled = 3;
     */
    enabled: boolean;
}
/**
 * @generated from protobuf message Kiosk.OrderUpsellGroupVM
 */
export interface OrderUpsellGroupVM {
    /**
     * @generated from protobuf field: Kiosk.OrderUpsellGroupPropertiesVM properties = 1;
     */
    properties?: OrderUpsellGroupPropertiesVM;
    /**
     * @generated from protobuf field: repeated Kiosk.OrderUpsellOfferVM offers = 2;
     */
    offers: OrderUpsellOfferVM[];
    /**
     * @generated from protobuf field: Kiosk.ConditionsVM conditions = 3;
     */
    conditions?: ConditionsVM;
}
/**
 * @generated from protobuf message Kiosk.ItemUpsellGroupListVM
 */
export interface ItemUpsellGroupListVM {
    /**
     * @generated from protobuf field: repeated Kiosk.ItemUpsellGroupVM groups = 1;
     */
    groups: ItemUpsellGroupVM[];
    /**
     * @generated from protobuf field: bool enabled = 2;
     */
    enabled: boolean;
}
/**
 * @generated from protobuf message Kiosk.ItemUpsellOfferVM
 */
export interface ItemUpsellOfferVM {
    /**
     * @generated from protobuf field: Kiosk.SectionMenuItemVM item = 1;
     */
    item?: SectionMenuItemVM;
    /**
     * @generated from protobuf field: string sectionId = 2;
     */
    sectionId: string;
    /**
     * @generated from protobuf field: string comboId = 3;
     */
    comboId: string;
}
/**
 * @generated from protobuf message Kiosk.ItemUpsellGroupPropertiesVM
 */
export interface ItemUpsellGroupPropertiesVM {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string id = 2;
     */
    id: string;
    /**
     * @generated from protobuf field: bool enabled = 3;
     */
    enabled: boolean;
}
/**
 * @generated from protobuf message Kiosk.ItemUpsellGroupVM
 */
export interface ItemUpsellGroupVM {
    /**
     * @generated from protobuf field: Kiosk.ItemUpsellGroupPropertiesVM properties = 1;
     */
    properties?: ItemUpsellGroupPropertiesVM;
    /**
     * @generated from protobuf field: repeated Kiosk.ItemUpsellOfferVM offers = 2;
     */
    offers: ItemUpsellOfferVM[];
    /**
     * @generated from protobuf field: Kiosk.ConditionsVM conditions = 3;
     */
    conditions?: ConditionsVM;
}
/**
 * @generated from protobuf message Kiosk.ItemUpsellMappingsVM
 */
export interface ItemUpsellMappingsVM {
    /**
     * @generated from protobuf field: map<string, string> map = 1;
     */
    map: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message Kiosk.PosData
 */
export interface PosData {
    /**
     * @generated from protobuf field: string menuItemId = 1;
     */
    menuItemId: string;
    /**
     * @generated from protobuf field: string data = 2;
     */
    data: string;
}
/**
 * @generated from protobuf enum Kiosk.NegativeModifierBehavior
 */
export enum NegativeModifierBehavior {
    /**
     * @generated from protobuf enum value: ShowNegativeModifiersAsUnselected = 0;
     */
    ShowNegativeModifiersAsUnselected = 0,
    /**
     * @generated from protobuf enum value: ShowDefaultModifiersAsPreSelected = 1;
     */
    ShowDefaultModifiersAsPreSelected = 1,
    /**
     * @generated from protobuf enum value: Unsupported = 2;
     */
    Unsupported = 2
}
/**
 * @generated from protobuf enum Kiosk.MenuComboComponentType
 */
export enum MenuComboComponentType {
    /**
     * @generated from protobuf enum value: Main = 0;
     */
    Main = 0,
    /**
     * @generated from protobuf enum value: Side = 1;
     */
    Side = 1,
    /**
     * @generated from protobuf enum value: UnknownComboComponent = 2;
     */
    UnknownComboComponent = 2
}
/**
 * @generated from protobuf enum Kiosk.MenuItemDisplayFlow
 */
export enum MenuItemDisplayFlow {
    /**
     * @generated from protobuf enum value: Default = 0;
     */
    Default = 0,
    /**
     * @generated from protobuf enum value: Builder = 1;
     */
    Builder = 1,
    /**
     * @generated from protobuf enum value: Pizza = 2;
     */
    Pizza = 2,
    /**
     * @generated from protobuf enum value: Taco = 3;
     */
    Taco = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class Menu$Type extends MessageType<Menu> {
    constructor() {
        super("Kiosk.Menu", [
            { no: 1, name: "categories", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Category },
            { no: 2, name: "menuItems", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MenuItem },
            { no: 3, name: "modifierGroups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ModifierGroup },
            { no: 4, name: "modifiers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Modifier },
            { no: 5, name: "pricebooks", kind: "message", T: () => PriceBooks },
            { no: 6, name: "modifierCodes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ModifierCode },
            { no: 7, name: "comboFamilies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MenuComboFamily },
            { no: 8, name: "combos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MenuCombo },
            { no: 9, name: "comboComponents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MenuComboComponent }
        ]);
    }
    create(value?: PartialMessage<Menu>): Menu {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.categories = [];
        message.menuItems = [];
        message.modifierGroups = [];
        message.modifiers = [];
        message.modifierCodes = [];
        message.comboFamilies = [];
        message.combos = [];
        message.comboComponents = [];
        if (value !== undefined)
            reflectionMergePartial<Menu>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Menu): Menu {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.Category categories */ 1:
                    message.categories.push(Category.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Kiosk.MenuItem menuItems */ 2:
                    message.menuItems.push(MenuItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Kiosk.ModifierGroup modifierGroups */ 3:
                    message.modifierGroups.push(ModifierGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Kiosk.Modifier modifiers */ 4:
                    message.modifiers.push(Modifier.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.PriceBooks pricebooks = 5 [deprecated = true];*/ 5:
                    message.pricebooks = PriceBooks.internalBinaryRead(reader, reader.uint32(), options, message.pricebooks);
                    break;
                case /* repeated Kiosk.ModifierCode modifierCodes */ 6:
                    message.modifierCodes.push(ModifierCode.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Kiosk.MenuComboFamily comboFamilies */ 7:
                    message.comboFamilies.push(MenuComboFamily.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Kiosk.MenuCombo combos */ 8:
                    message.combos.push(MenuCombo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Kiosk.MenuComboComponent comboComponents */ 9:
                    message.comboComponents.push(MenuComboComponent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Menu, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.Category categories = 1; */
        for (let i = 0; i < message.categories.length; i++)
            Category.internalBinaryWrite(message.categories[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.MenuItem menuItems = 2; */
        for (let i = 0; i < message.menuItems.length; i++)
            MenuItem.internalBinaryWrite(message.menuItems[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.ModifierGroup modifierGroups = 3; */
        for (let i = 0; i < message.modifierGroups.length; i++)
            ModifierGroup.internalBinaryWrite(message.modifierGroups[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.Modifier modifiers = 4; */
        for (let i = 0; i < message.modifiers.length; i++)
            Modifier.internalBinaryWrite(message.modifiers[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.PriceBooks pricebooks = 5 [deprecated = true]; */
        if (message.pricebooks)
            PriceBooks.internalBinaryWrite(message.pricebooks, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.ModifierCode modifierCodes = 6; */
        for (let i = 0; i < message.modifierCodes.length; i++)
            ModifierCode.internalBinaryWrite(message.modifierCodes[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.MenuComboFamily comboFamilies = 7; */
        for (let i = 0; i < message.comboFamilies.length; i++)
            MenuComboFamily.internalBinaryWrite(message.comboFamilies[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.MenuCombo combos = 8; */
        for (let i = 0; i < message.combos.length; i++)
            MenuCombo.internalBinaryWrite(message.combos[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.MenuComboComponent comboComponents = 9; */
        for (let i = 0; i < message.comboComponents.length; i++)
            MenuComboComponent.internalBinaryWrite(message.comboComponents[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.Menu
 */
export const Menu = new Menu$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SectionMenuItemVM$Type extends MessageType<SectionMenuItemVM> {
    constructor() {
        super("Kiosk.SectionMenuItemVM", [
            { no: 1, name: "menuItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SectionMenuItemVM>): SectionMenuItemVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.menuItemId = "";
        if (value !== undefined)
            reflectionMergePartial<SectionMenuItemVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SectionMenuItemVM): SectionMenuItemVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string menuItemId */ 1:
                    message.menuItemId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SectionMenuItemVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string menuItemId = 1; */
        if (message.menuItemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.menuItemId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.SectionMenuItemVM
 */
export const SectionMenuItemVM = new SectionMenuItemVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuLayout$Type extends MessageType<MenuLayout> {
    constructor() {
        super("Kiosk.MenuLayout", [
            { no: 1, name: "menuLayoutId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "categories", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CategoryLayout }
        ]);
    }
    create(value?: PartialMessage<MenuLayout>): MenuLayout {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.menuLayoutId = "";
        message.categories = [];
        if (value !== undefined)
            reflectionMergePartial<MenuLayout>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuLayout): MenuLayout {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string menuLayoutId */ 1:
                    message.menuLayoutId = reader.string();
                    break;
                case /* repeated Kiosk.CategoryLayout categories */ 2:
                    message.categories.push(CategoryLayout.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuLayout, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string menuLayoutId = 1; */
        if (message.menuLayoutId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.menuLayoutId);
        /* repeated Kiosk.CategoryLayout categories = 2; */
        for (let i = 0; i < message.categories.length; i++)
            CategoryLayout.internalBinaryWrite(message.categories[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuLayout
 */
export const MenuLayout = new MenuLayout$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CategoryLayout$Type extends MessageType<CategoryLayout> {
    constructor() {
        super("Kiosk.CategoryLayout", [
            { no: 1, name: "categoryId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "menuItemIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "subCategories", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CategoryLayout },
            { no: 5, name: "numberOfColumns", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "loyaltyOnly", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "displayableItems", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CategoryLayoutDisplayableItem },
            { no: 8, name: "availability", kind: "message", T: () => EntityAvailability }
        ]);
    }
    create(value?: PartialMessage<CategoryLayout>): CategoryLayout {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.categoryId = "";
        message.menuItemIds = [];
        message.subCategories = [];
        message.numberOfColumns = 0;
        message.loyaltyOnly = false;
        message.displayableItems = [];
        if (value !== undefined)
            reflectionMergePartial<CategoryLayout>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CategoryLayout): CategoryLayout {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string categoryId */ 1:
                    message.categoryId = reader.string();
                    break;
                case /* repeated string menuItemIds */ 2:
                    message.menuItemIds.push(reader.string());
                    break;
                case /* repeated Kiosk.CategoryLayout subCategories */ 4:
                    message.subCategories.push(CategoryLayout.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 numberOfColumns */ 5:
                    message.numberOfColumns = reader.int32();
                    break;
                case /* bool loyaltyOnly */ 6:
                    message.loyaltyOnly = reader.bool();
                    break;
                case /* repeated Kiosk.CategoryLayoutDisplayableItem displayableItems */ 7:
                    message.displayableItems.push(CategoryLayoutDisplayableItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.EntityAvailability availability */ 8:
                    message.availability = EntityAvailability.internalBinaryRead(reader, reader.uint32(), options, message.availability);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CategoryLayout, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string categoryId = 1; */
        if (message.categoryId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.categoryId);
        /* repeated string menuItemIds = 2; */
        for (let i = 0; i < message.menuItemIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.menuItemIds[i]);
        /* repeated Kiosk.CategoryLayout subCategories = 4; */
        for (let i = 0; i < message.subCategories.length; i++)
            CategoryLayout.internalBinaryWrite(message.subCategories[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 numberOfColumns = 5; */
        if (message.numberOfColumns !== 0)
            writer.tag(5, WireType.Varint).int32(message.numberOfColumns);
        /* bool loyaltyOnly = 6; */
        if (message.loyaltyOnly !== false)
            writer.tag(6, WireType.Varint).bool(message.loyaltyOnly);
        /* repeated Kiosk.CategoryLayoutDisplayableItem displayableItems = 7; */
        for (let i = 0; i < message.displayableItems.length; i++)
            CategoryLayoutDisplayableItem.internalBinaryWrite(message.displayableItems[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.EntityAvailability availability = 8; */
        if (message.availability)
            EntityAvailability.internalBinaryWrite(message.availability, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.CategoryLayout
 */
export const CategoryLayout = new CategoryLayout$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EntityAvailability$Type extends MessageType<EntityAvailability> {
    constructor() {
        super("Kiosk.EntityAvailability", [
            { no: 1, name: "alwaysAvailable", kind: "enum", oneof: "availability", T: () => ["google.protobuf.NullValue", NullValue] },
            { no: 2, name: "schedule", kind: "message", oneof: "availability", T: () => MenuAvailabilityScheduleVM }
        ]);
    }
    create(value?: PartialMessage<EntityAvailability>): EntityAvailability {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.availability = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<EntityAvailability>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EntityAvailability): EntityAvailability {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.NullValue alwaysAvailable */ 1:
                    message.availability = {
                        oneofKind: "alwaysAvailable",
                        alwaysAvailable: reader.int32()
                    };
                    break;
                case /* Kiosk.MenuAvailabilityScheduleVM schedule */ 2:
                    message.availability = {
                        oneofKind: "schedule",
                        schedule: MenuAvailabilityScheduleVM.internalBinaryRead(reader, reader.uint32(), options, (message.availability as any).schedule)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EntityAvailability, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.NullValue alwaysAvailable = 1; */
        if (message.availability.oneofKind === "alwaysAvailable")
            writer.tag(1, WireType.Varint).int32(message.availability.alwaysAvailable);
        /* Kiosk.MenuAvailabilityScheduleVM schedule = 2; */
        if (message.availability.oneofKind === "schedule")
            MenuAvailabilityScheduleVM.internalBinaryWrite(message.availability.schedule, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.EntityAvailability
 */
export const EntityAvailability = new EntityAvailability$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CategoryLayoutDisplayableItem$Type extends MessageType<CategoryLayoutDisplayableItem> {
    constructor() {
        super("Kiosk.CategoryLayoutDisplayableItem", [
            { no: 1, name: "menuItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "comboId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "comboFamilyId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "subCategory", kind: "message", T: () => CategoryLayout }
        ]);
    }
    create(value?: PartialMessage<CategoryLayoutDisplayableItem>): CategoryLayoutDisplayableItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.menuItemId = "";
        message.comboId = "";
        message.comboFamilyId = "";
        if (value !== undefined)
            reflectionMergePartial<CategoryLayoutDisplayableItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CategoryLayoutDisplayableItem): CategoryLayoutDisplayableItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string menuItemId */ 1:
                    message.menuItemId = reader.string();
                    break;
                case /* string comboId */ 2:
                    message.comboId = reader.string();
                    break;
                case /* string comboFamilyId */ 3:
                    message.comboFamilyId = reader.string();
                    break;
                case /* Kiosk.CategoryLayout subCategory */ 4:
                    message.subCategory = CategoryLayout.internalBinaryRead(reader, reader.uint32(), options, message.subCategory);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CategoryLayoutDisplayableItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string menuItemId = 1; */
        if (message.menuItemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.menuItemId);
        /* string comboId = 2; */
        if (message.comboId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.comboId);
        /* string comboFamilyId = 3; */
        if (message.comboFamilyId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.comboFamilyId);
        /* Kiosk.CategoryLayout subCategory = 4; */
        if (message.subCategory)
            CategoryLayout.internalBinaryWrite(message.subCategory, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.CategoryLayoutDisplayableItem
 */
export const CategoryLayoutDisplayableItem = new CategoryLayoutDisplayableItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PriceBooks$Type extends MessageType<PriceBooks> {
    constructor() {
        super("Kiosk.PriceBooks", [
            { no: 1, name: "books", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => PriceBook } }
        ]);
    }
    create(value?: PartialMessage<PriceBooks>): PriceBooks {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.books = {};
        if (value !== undefined)
            reflectionMergePartial<PriceBooks>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PriceBooks): PriceBooks {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, Kiosk.PriceBook> books */ 1:
                    this.binaryReadMap1(message.books, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: PriceBooks["books"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof PriceBooks["books"] | undefined, val: PriceBooks["books"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = PriceBook.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Kiosk.PriceBooks.books");
            }
        }
        map[key ?? ""] = val ?? PriceBook.create();
    }
    internalBinaryWrite(message: PriceBooks, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, Kiosk.PriceBook> books = 1; */
        for (let k of globalThis.Object.keys(message.books)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            PriceBook.internalBinaryWrite(message.books[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.PriceBooks
 */
export const PriceBooks = new PriceBooks$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PriceBook$Type extends MessageType<PriceBook> {
    constructor() {
        super("Kiosk.PriceBook", [
            { no: 1, name: "prices", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } }
        ]);
    }
    create(value?: PartialMessage<PriceBook>): PriceBook {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.prices = {};
        if (value !== undefined)
            reflectionMergePartial<PriceBook>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PriceBook): PriceBook {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, int32> prices */ 1:
                    this.binaryReadMap1(message.prices, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: PriceBook["prices"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof PriceBook["prices"] | undefined, val: PriceBook["prices"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Kiosk.PriceBook.prices");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: PriceBook, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, int32> prices = 1; */
        for (let k of globalThis.Object.keys(message.prices))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.prices[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.PriceBook
 */
export const PriceBook = new PriceBook$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Category$Type extends MessageType<Category> {
    constructor() {
        super("Kiosk.Category", [
            { no: 1, name: "categoryId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "media", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "selectedImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "localizedName", kind: "message", T: () => LocalizedStringSet },
            { no: 6, name: "displayNameLocalized", kind: "message", T: () => LocalizedStringSet }
        ]);
    }
    create(value?: PartialMessage<Category>): Category {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.categoryId = "";
        message.name = "";
        message.media = [];
        message.selectedImage = "";
        if (value !== undefined)
            reflectionMergePartial<Category>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Category): Category {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string categoryId */ 1:
                    message.categoryId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated string media */ 3:
                    message.media.push(reader.string());
                    break;
                case /* string selectedImage */ 4:
                    message.selectedImage = reader.string();
                    break;
                case /* Kiosk.LocalizedStringSet localizedName */ 5:
                    message.localizedName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedName);
                    break;
                case /* Kiosk.LocalizedStringSet displayNameLocalized */ 6:
                    message.displayNameLocalized = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.displayNameLocalized);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Category, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string categoryId = 1; */
        if (message.categoryId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.categoryId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated string media = 3; */
        for (let i = 0; i < message.media.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.media[i]);
        /* string selectedImage = 4; */
        if (message.selectedImage !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.selectedImage);
        /* Kiosk.LocalizedStringSet localizedName = 5; */
        if (message.localizedName)
            LocalizedStringSet.internalBinaryWrite(message.localizedName, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet displayNameLocalized = 6; */
        if (message.displayNameLocalized)
            LocalizedStringSet.internalBinaryWrite(message.displayNameLocalized, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.Category
 */
export const Category = new Category$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocalizedStringSet$Type extends MessageType<LocalizedStringSet> {
    constructor() {
        super("Kiosk.LocalizedStringSet", [
            { no: 1, name: "translations", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<LocalizedStringSet>): LocalizedStringSet {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.translations = {};
        if (value !== undefined)
            reflectionMergePartial<LocalizedStringSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocalizedStringSet): LocalizedStringSet {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> translations */ 1:
                    this.binaryReadMap1(message.translations, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: LocalizedStringSet["translations"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LocalizedStringSet["translations"] | undefined, val: LocalizedStringSet["translations"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Kiosk.LocalizedStringSet.translations");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: LocalizedStringSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> translations = 1; */
        for (let k of globalThis.Object.keys(message.translations))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.translations[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.LocalizedStringSet
 */
export const LocalizedStringSet = new LocalizedStringSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuItem$Type extends MessageType<MenuItem> {
    constructor() {
        super("Kiosk.MenuItem", [
            { no: 1, name: "menuItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "media", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "modifierGroupIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "transformRulesMetadata", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "flow", kind: "enum", T: () => ["Kiosk.MenuItemDisplayFlow", MenuItemDisplayFlow] },
            { no: 8, name: "barcode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "calorieText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "selectedImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "selectedDisplayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "localizedName", kind: "message", T: () => LocalizedStringSet },
            { no: 13, name: "localizedDescription", kind: "message", T: () => LocalizedStringSet },
            { no: 14, name: "price", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "modifierOverrides", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MenuItemModifierOverrides },
            { no: 16, name: "promotionalDetails", kind: "message", T: () => MenuItemPromotionalDetails },
            { no: 17, name: "availability", kind: "message", T: () => EntityAvailability },
            { no: 18, name: "localizedDisplayName", kind: "message", T: () => LocalizedStringSet },
            { no: 19, name: "itemExternalId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "posData", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "domainSpecificAttributes", kind: "message", T: () => MenuItemDomainSpecificAttributes },
            { no: 22, name: "isVariablyPriced", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 23, name: "menuItemModifierGroup", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MenuItemModifierGroup }
        ]);
    }
    create(value?: PartialMessage<MenuItem>): MenuItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.menuItemId = "";
        message.name = "";
        message.media = [];
        message.description = "";
        message.modifierGroupIds = [];
        message.transformRulesMetadata = "";
        message.flow = 0;
        message.barcode = "";
        message.calorieText = "";
        message.selectedImage = "";
        message.selectedDisplayName = "";
        message.price = 0;
        message.modifierOverrides = [];
        message.itemExternalId = "";
        message.posData = "";
        message.isVariablyPriced = false;
        message.menuItemModifierGroup = [];
        if (value !== undefined)
            reflectionMergePartial<MenuItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuItem): MenuItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string menuItemId */ 1:
                    message.menuItemId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated string media */ 3:
                    message.media.push(reader.string());
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* repeated string modifierGroupIds */ 5:
                    message.modifierGroupIds.push(reader.string());
                    break;
                case /* string transformRulesMetadata */ 6:
                    message.transformRulesMetadata = reader.string();
                    break;
                case /* Kiosk.MenuItemDisplayFlow flow */ 7:
                    message.flow = reader.int32();
                    break;
                case /* string barcode */ 8:
                    message.barcode = reader.string();
                    break;
                case /* string calorieText */ 9:
                    message.calorieText = reader.string();
                    break;
                case /* string selectedImage */ 10:
                    message.selectedImage = reader.string();
                    break;
                case /* string selectedDisplayName */ 11:
                    message.selectedDisplayName = reader.string();
                    break;
                case /* Kiosk.LocalizedStringSet localizedName */ 12:
                    message.localizedName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedName);
                    break;
                case /* Kiosk.LocalizedStringSet localizedDescription */ 13:
                    message.localizedDescription = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedDescription);
                    break;
                case /* int32 price */ 14:
                    message.price = reader.int32();
                    break;
                case /* repeated Kiosk.MenuItemModifierOverrides modifierOverrides */ 15:
                    message.modifierOverrides.push(MenuItemModifierOverrides.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.MenuItemPromotionalDetails promotionalDetails */ 16:
                    message.promotionalDetails = MenuItemPromotionalDetails.internalBinaryRead(reader, reader.uint32(), options, message.promotionalDetails);
                    break;
                case /* Kiosk.EntityAvailability availability */ 17:
                    message.availability = EntityAvailability.internalBinaryRead(reader, reader.uint32(), options, message.availability);
                    break;
                case /* Kiosk.LocalizedStringSet localizedDisplayName */ 18:
                    message.localizedDisplayName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedDisplayName);
                    break;
                case /* string itemExternalId */ 19:
                    message.itemExternalId = reader.string();
                    break;
                case /* string posData */ 20:
                    message.posData = reader.string();
                    break;
                case /* Kiosk.MenuItemDomainSpecificAttributes domainSpecificAttributes */ 21:
                    message.domainSpecificAttributes = MenuItemDomainSpecificAttributes.internalBinaryRead(reader, reader.uint32(), options, message.domainSpecificAttributes);
                    break;
                case /* bool isVariablyPriced */ 22:
                    message.isVariablyPriced = reader.bool();
                    break;
                case /* repeated Kiosk.MenuItemModifierGroup menuItemModifierGroup */ 23:
                    message.menuItemModifierGroup.push(MenuItemModifierGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string menuItemId = 1; */
        if (message.menuItemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.menuItemId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated string media = 3; */
        for (let i = 0; i < message.media.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.media[i]);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* repeated string modifierGroupIds = 5; */
        for (let i = 0; i < message.modifierGroupIds.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.modifierGroupIds[i]);
        /* string transformRulesMetadata = 6; */
        if (message.transformRulesMetadata !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.transformRulesMetadata);
        /* Kiosk.MenuItemDisplayFlow flow = 7; */
        if (message.flow !== 0)
            writer.tag(7, WireType.Varint).int32(message.flow);
        /* string barcode = 8; */
        if (message.barcode !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.barcode);
        /* string calorieText = 9; */
        if (message.calorieText !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.calorieText);
        /* string selectedImage = 10; */
        if (message.selectedImage !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.selectedImage);
        /* string selectedDisplayName = 11; */
        if (message.selectedDisplayName !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.selectedDisplayName);
        /* Kiosk.LocalizedStringSet localizedName = 12; */
        if (message.localizedName)
            LocalizedStringSet.internalBinaryWrite(message.localizedName, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet localizedDescription = 13; */
        if (message.localizedDescription)
            LocalizedStringSet.internalBinaryWrite(message.localizedDescription, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* int32 price = 14; */
        if (message.price !== 0)
            writer.tag(14, WireType.Varint).int32(message.price);
        /* repeated Kiosk.MenuItemModifierOverrides modifierOverrides = 15; */
        for (let i = 0; i < message.modifierOverrides.length; i++)
            MenuItemModifierOverrides.internalBinaryWrite(message.modifierOverrides[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.MenuItemPromotionalDetails promotionalDetails = 16; */
        if (message.promotionalDetails)
            MenuItemPromotionalDetails.internalBinaryWrite(message.promotionalDetails, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.EntityAvailability availability = 17; */
        if (message.availability)
            EntityAvailability.internalBinaryWrite(message.availability, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet localizedDisplayName = 18; */
        if (message.localizedDisplayName)
            LocalizedStringSet.internalBinaryWrite(message.localizedDisplayName, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* string itemExternalId = 19; */
        if (message.itemExternalId !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.itemExternalId);
        /* string posData = 20; */
        if (message.posData !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.posData);
        /* Kiosk.MenuItemDomainSpecificAttributes domainSpecificAttributes = 21; */
        if (message.domainSpecificAttributes)
            MenuItemDomainSpecificAttributes.internalBinaryWrite(message.domainSpecificAttributes, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* bool isVariablyPriced = 22; */
        if (message.isVariablyPriced !== false)
            writer.tag(22, WireType.Varint).bool(message.isVariablyPriced);
        /* repeated Kiosk.MenuItemModifierGroup menuItemModifierGroup = 23; */
        for (let i = 0; i < message.menuItemModifierGroup.length; i++)
            MenuItemModifierGroup.internalBinaryWrite(message.menuItemModifierGroup[i], writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuItem
 */
export const MenuItem = new MenuItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuItemModifierGroup$Type extends MessageType<MenuItemModifierGroup> {
    constructor() {
        super("Kiosk.MenuItemModifierGroup", [
            { no: 1, name: "modifierGroupId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "maxSelection", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "minSelection", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "freeCount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "isActive", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "isInvisible", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MenuItemModifierGroup>): MenuItemModifierGroup {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.modifierGroupId = "";
        message.maxSelection = 0;
        message.minSelection = 0;
        message.freeCount = 0;
        message.isActive = false;
        message.isInvisible = false;
        if (value !== undefined)
            reflectionMergePartial<MenuItemModifierGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuItemModifierGroup): MenuItemModifierGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modifierGroupId */ 1:
                    message.modifierGroupId = reader.string();
                    break;
                case /* int32 maxSelection */ 2:
                    message.maxSelection = reader.int32();
                    break;
                case /* int32 minSelection */ 3:
                    message.minSelection = reader.int32();
                    break;
                case /* double freeCount */ 4:
                    message.freeCount = reader.double();
                    break;
                case /* bool isActive */ 5:
                    message.isActive = reader.bool();
                    break;
                case /* bool isInvisible */ 6:
                    message.isInvisible = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuItemModifierGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modifierGroupId = 1; */
        if (message.modifierGroupId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modifierGroupId);
        /* int32 maxSelection = 2; */
        if (message.maxSelection !== 0)
            writer.tag(2, WireType.Varint).int32(message.maxSelection);
        /* int32 minSelection = 3; */
        if (message.minSelection !== 0)
            writer.tag(3, WireType.Varint).int32(message.minSelection);
        /* double freeCount = 4; */
        if (message.freeCount !== 0)
            writer.tag(4, WireType.Bit64).double(message.freeCount);
        /* bool isActive = 5; */
        if (message.isActive !== false)
            writer.tag(5, WireType.Varint).bool(message.isActive);
        /* bool isInvisible = 6; */
        if (message.isInvisible !== false)
            writer.tag(6, WireType.Varint).bool(message.isInvisible);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuItemModifierGroup
 */
export const MenuItemModifierGroup = new MenuItemModifierGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuItemDomainSpecificAttributes$Type extends MessageType<MenuItemDomainSpecificAttributes> {
    constructor() {
        super("Kiosk.MenuItemDomainSpecificAttributes", [
            { no: 1, name: "pizzaAttributes", kind: "message", oneof: "attributes", T: () => MenuItemPizzaSpecificAttributes }
        ]);
    }
    create(value?: PartialMessage<MenuItemDomainSpecificAttributes>): MenuItemDomainSpecificAttributes {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.attributes = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<MenuItemDomainSpecificAttributes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuItemDomainSpecificAttributes): MenuItemDomainSpecificAttributes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.MenuItemPizzaSpecificAttributes pizzaAttributes */ 1:
                    message.attributes = {
                        oneofKind: "pizzaAttributes",
                        pizzaAttributes: MenuItemPizzaSpecificAttributes.internalBinaryRead(reader, reader.uint32(), options, (message.attributes as any).pizzaAttributes)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuItemDomainSpecificAttributes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.MenuItemPizzaSpecificAttributes pizzaAttributes = 1; */
        if (message.attributes.oneofKind === "pizzaAttributes")
            MenuItemPizzaSpecificAttributes.internalBinaryWrite(message.attributes.pizzaAttributes, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuItemDomainSpecificAttributes
 */
export const MenuItemDomainSpecificAttributes = new MenuItemDomainSpecificAttributes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuModiferDomainSpecificAttributes$Type extends MessageType<MenuModiferDomainSpecificAttributes> {
    constructor() {
        super("Kiosk.MenuModiferDomainSpecificAttributes", [
            { no: 1, name: "pizzaAttributes", kind: "message", oneof: "attributes", T: () => MenuItemPizzaSpecificAttributes }
        ]);
    }
    create(value?: PartialMessage<MenuModiferDomainSpecificAttributes>): MenuModiferDomainSpecificAttributes {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.attributes = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<MenuModiferDomainSpecificAttributes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuModiferDomainSpecificAttributes): MenuModiferDomainSpecificAttributes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.MenuItemPizzaSpecificAttributes pizzaAttributes */ 1:
                    message.attributes = {
                        oneofKind: "pizzaAttributes",
                        pizzaAttributes: MenuItemPizzaSpecificAttributes.internalBinaryRead(reader, reader.uint32(), options, (message.attributes as any).pizzaAttributes)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuModiferDomainSpecificAttributes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.MenuItemPizzaSpecificAttributes pizzaAttributes = 1; */
        if (message.attributes.oneofKind === "pizzaAttributes")
            MenuItemPizzaSpecificAttributes.internalBinaryWrite(message.attributes.pizzaAttributes, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuModiferDomainSpecificAttributes
 */
export const MenuModiferDomainSpecificAttributes = new MenuModiferDomainSpecificAttributes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuItemPizzaSpecificAttributes$Type extends MessageType<MenuItemPizzaSpecificAttributes> {
    constructor() {
        super("Kiosk.MenuItemPizzaSpecificAttributes", [
            { no: 1, name: "backgroundPreviewImages", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 2, name: "toppingPreviewImages", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<MenuItemPizzaSpecificAttributes>): MenuItemPizzaSpecificAttributes {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.backgroundPreviewImages = {};
        message.toppingPreviewImages = {};
        if (value !== undefined)
            reflectionMergePartial<MenuItemPizzaSpecificAttributes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuItemPizzaSpecificAttributes): MenuItemPizzaSpecificAttributes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> backgroundPreviewImages */ 1:
                    this.binaryReadMap1(message.backgroundPreviewImages, reader, options);
                    break;
                case /* map<string, string> toppingPreviewImages */ 2:
                    this.binaryReadMap2(message.toppingPreviewImages, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: MenuItemPizzaSpecificAttributes["backgroundPreviewImages"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof MenuItemPizzaSpecificAttributes["backgroundPreviewImages"] | undefined, val: MenuItemPizzaSpecificAttributes["backgroundPreviewImages"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Kiosk.MenuItemPizzaSpecificAttributes.backgroundPreviewImages");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    private binaryReadMap2(map: MenuItemPizzaSpecificAttributes["toppingPreviewImages"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof MenuItemPizzaSpecificAttributes["toppingPreviewImages"] | undefined, val: MenuItemPizzaSpecificAttributes["toppingPreviewImages"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Kiosk.MenuItemPizzaSpecificAttributes.toppingPreviewImages");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: MenuItemPizzaSpecificAttributes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> backgroundPreviewImages = 1; */
        for (let k of globalThis.Object.keys(message.backgroundPreviewImages))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.backgroundPreviewImages[k]).join();
        /* map<string, string> toppingPreviewImages = 2; */
        for (let k of globalThis.Object.keys(message.toppingPreviewImages))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.toppingPreviewImages[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuItemPizzaSpecificAttributes
 */
export const MenuItemPizzaSpecificAttributes = new MenuItemPizzaSpecificAttributes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuItemPromotionalDetails$Type extends MessageType<MenuItemPromotionalDetails> {
    constructor() {
        super("Kiosk.MenuItemPromotionalDetails", [
            { no: 1, name: "triggerCodes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "isExclusivelyPromotionalItem", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MenuItemPromotionalDetails>): MenuItemPromotionalDetails {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.triggerCodes = [];
        message.isExclusivelyPromotionalItem = false;
        if (value !== undefined)
            reflectionMergePartial<MenuItemPromotionalDetails>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuItemPromotionalDetails): MenuItemPromotionalDetails {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string triggerCodes */ 1:
                    message.triggerCodes.push(reader.string());
                    break;
                case /* bool isExclusivelyPromotionalItem */ 2:
                    message.isExclusivelyPromotionalItem = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuItemPromotionalDetails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string triggerCodes = 1; */
        for (let i = 0; i < message.triggerCodes.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.triggerCodes[i]);
        /* bool isExclusivelyPromotionalItem = 2; */
        if (message.isExclusivelyPromotionalItem !== false)
            writer.tag(2, WireType.Varint).bool(message.isExclusivelyPromotionalItem);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuItemPromotionalDetails
 */
export const MenuItemPromotionalDetails = new MenuItemPromotionalDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuItemModifierOverrides$Type extends MessageType<MenuItemModifierOverrides> {
    constructor() {
        super("Kiosk.MenuItemModifierOverrides", [
            { no: 2, name: "modifierGroupId", kind: "message", T: () => StringValue },
            { no: 3, name: "modifierId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "isDefault", kind: "message", T: () => BoolValue },
            { no: 5, name: "defaultQuantity", kind: "message", T: () => Int32Value },
            { no: 6, name: "isHidden", kind: "message", T: () => BoolValue },
            { no: 7, name: "maxQuantity", kind: "message", T: () => Int32Value },
            { no: 8, name: "incrementStep", kind: "message", T: () => Int32Value },
            { no: 9, name: "hideNestedModifiers", kind: "message", T: () => BoolValue },
            { no: 11, name: "isIncludedInItemPrice", kind: "message", T: () => BoolValue },
            { no: 12, name: "isActive", kind: "message", T: () => BoolValue },
            { no: 13, name: "isInvisible", kind: "message", T: () => BoolValue }
        ]);
    }
    create(value?: PartialMessage<MenuItemModifierOverrides>): MenuItemModifierOverrides {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.modifierId = "";
        if (value !== undefined)
            reflectionMergePartial<MenuItemModifierOverrides>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuItemModifierOverrides): MenuItemModifierOverrides {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.StringValue modifierGroupId */ 2:
                    message.modifierGroupId = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.modifierGroupId);
                    break;
                case /* string modifierId */ 3:
                    message.modifierId = reader.string();
                    break;
                case /* google.protobuf.BoolValue isDefault */ 4:
                    message.isDefault = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.isDefault);
                    break;
                case /* google.protobuf.Int32Value defaultQuantity */ 5:
                    message.defaultQuantity = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.defaultQuantity);
                    break;
                case /* google.protobuf.BoolValue isHidden */ 6:
                    message.isHidden = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.isHidden);
                    break;
                case /* google.protobuf.Int32Value maxQuantity */ 7:
                    message.maxQuantity = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.maxQuantity);
                    break;
                case /* google.protobuf.Int32Value incrementStep */ 8:
                    message.incrementStep = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.incrementStep);
                    break;
                case /* google.protobuf.BoolValue hideNestedModifiers */ 9:
                    message.hideNestedModifiers = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.hideNestedModifiers);
                    break;
                case /* google.protobuf.BoolValue isIncludedInItemPrice */ 11:
                    message.isIncludedInItemPrice = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.isIncludedInItemPrice);
                    break;
                case /* google.protobuf.BoolValue isActive */ 12:
                    message.isActive = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.isActive);
                    break;
                case /* google.protobuf.BoolValue isInvisible */ 13:
                    message.isInvisible = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.isInvisible);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuItemModifierOverrides, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.StringValue modifierGroupId = 2; */
        if (message.modifierGroupId)
            StringValue.internalBinaryWrite(message.modifierGroupId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string modifierId = 3; */
        if (message.modifierId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.modifierId);
        /* google.protobuf.BoolValue isDefault = 4; */
        if (message.isDefault)
            BoolValue.internalBinaryWrite(message.isDefault, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value defaultQuantity = 5; */
        if (message.defaultQuantity)
            Int32Value.internalBinaryWrite(message.defaultQuantity, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.BoolValue isHidden = 6; */
        if (message.isHidden)
            BoolValue.internalBinaryWrite(message.isHidden, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value maxQuantity = 7; */
        if (message.maxQuantity)
            Int32Value.internalBinaryWrite(message.maxQuantity, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value incrementStep = 8; */
        if (message.incrementStep)
            Int32Value.internalBinaryWrite(message.incrementStep, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.BoolValue hideNestedModifiers = 9; */
        if (message.hideNestedModifiers)
            BoolValue.internalBinaryWrite(message.hideNestedModifiers, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.BoolValue isIncludedInItemPrice = 11; */
        if (message.isIncludedInItemPrice)
            BoolValue.internalBinaryWrite(message.isIncludedInItemPrice, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.BoolValue isActive = 12; */
        if (message.isActive)
            BoolValue.internalBinaryWrite(message.isActive, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.BoolValue isInvisible = 13; */
        if (message.isInvisible)
            BoolValue.internalBinaryWrite(message.isInvisible, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuItemModifierOverrides
 */
export const MenuItemModifierOverrides = new MenuItemModifierOverrides$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModifierGroup$Type extends MessageType<ModifierGroup> {
    constructor() {
        super("Kiosk.ModifierGroup", [
            { no: 1, name: "modifierGroupId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "selectMin", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "selectMax", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "modifierIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "freeModifierCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "displayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "negativeModifierBehavior", kind: "enum", T: () => ["Kiosk.NegativeModifierBehavior", NegativeModifierBehavior] },
            { no: 9, name: "localizedName", kind: "message", T: () => LocalizedStringSet },
            { no: 10, name: "localizedDisplayName", kind: "message", T: () => LocalizedStringSet },
            { no: 11, name: "groupModifierCodes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GroupModifierCodes },
            { no: 12, name: "posId", kind: "message", T: () => StringValue },
            { no: 13, name: "domainSpecificAttributes", kind: "message", T: () => ModifierGroupDomainSpecificAttributes },
            { no: 14, name: "shortcutName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "localizedShortcutName", kind: "message", T: () => LocalizedStringSet }
        ]);
    }
    create(value?: PartialMessage<ModifierGroup>): ModifierGroup {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.modifierGroupId = "";
        message.name = "";
        message.selectMin = 0;
        message.selectMax = 0;
        message.modifierIds = [];
        message.freeModifierCount = 0;
        message.displayName = "";
        message.negativeModifierBehavior = 0;
        message.groupModifierCodes = [];
        message.shortcutName = "";
        if (value !== undefined)
            reflectionMergePartial<ModifierGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModifierGroup): ModifierGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modifierGroupId */ 1:
                    message.modifierGroupId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 selectMin */ 3:
                    message.selectMin = reader.int32();
                    break;
                case /* int32 selectMax */ 4:
                    message.selectMax = reader.int32();
                    break;
                case /* repeated string modifierIds */ 5:
                    message.modifierIds.push(reader.string());
                    break;
                case /* int32 freeModifierCount */ 6:
                    message.freeModifierCount = reader.int32();
                    break;
                case /* string displayName */ 7:
                    message.displayName = reader.string();
                    break;
                case /* Kiosk.NegativeModifierBehavior negativeModifierBehavior */ 8:
                    message.negativeModifierBehavior = reader.int32();
                    break;
                case /* Kiosk.LocalizedStringSet localizedName */ 9:
                    message.localizedName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedName);
                    break;
                case /* Kiosk.LocalizedStringSet localizedDisplayName */ 10:
                    message.localizedDisplayName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedDisplayName);
                    break;
                case /* repeated Kiosk.GroupModifierCodes groupModifierCodes */ 11:
                    message.groupModifierCodes.push(GroupModifierCodes.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.StringValue posId */ 12:
                    message.posId = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.posId);
                    break;
                case /* Kiosk.ModifierGroupDomainSpecificAttributes domainSpecificAttributes */ 13:
                    message.domainSpecificAttributes = ModifierGroupDomainSpecificAttributes.internalBinaryRead(reader, reader.uint32(), options, message.domainSpecificAttributes);
                    break;
                case /* string shortcutName */ 14:
                    message.shortcutName = reader.string();
                    break;
                case /* Kiosk.LocalizedStringSet localizedShortcutName */ 15:
                    message.localizedShortcutName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedShortcutName);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModifierGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modifierGroupId = 1; */
        if (message.modifierGroupId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modifierGroupId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 selectMin = 3; */
        if (message.selectMin !== 0)
            writer.tag(3, WireType.Varint).int32(message.selectMin);
        /* int32 selectMax = 4; */
        if (message.selectMax !== 0)
            writer.tag(4, WireType.Varint).int32(message.selectMax);
        /* repeated string modifierIds = 5; */
        for (let i = 0; i < message.modifierIds.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.modifierIds[i]);
        /* int32 freeModifierCount = 6; */
        if (message.freeModifierCount !== 0)
            writer.tag(6, WireType.Varint).int32(message.freeModifierCount);
        /* string displayName = 7; */
        if (message.displayName !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.displayName);
        /* Kiosk.NegativeModifierBehavior negativeModifierBehavior = 8; */
        if (message.negativeModifierBehavior !== 0)
            writer.tag(8, WireType.Varint).int32(message.negativeModifierBehavior);
        /* Kiosk.LocalizedStringSet localizedName = 9; */
        if (message.localizedName)
            LocalizedStringSet.internalBinaryWrite(message.localizedName, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet localizedDisplayName = 10; */
        if (message.localizedDisplayName)
            LocalizedStringSet.internalBinaryWrite(message.localizedDisplayName, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.GroupModifierCodes groupModifierCodes = 11; */
        for (let i = 0; i < message.groupModifierCodes.length; i++)
            GroupModifierCodes.internalBinaryWrite(message.groupModifierCodes[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue posId = 12; */
        if (message.posId)
            StringValue.internalBinaryWrite(message.posId, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.ModifierGroupDomainSpecificAttributes domainSpecificAttributes = 13; */
        if (message.domainSpecificAttributes)
            ModifierGroupDomainSpecificAttributes.internalBinaryWrite(message.domainSpecificAttributes, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* string shortcutName = 14; */
        if (message.shortcutName !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.shortcutName);
        /* Kiosk.LocalizedStringSet localizedShortcutName = 15; */
        if (message.localizedShortcutName)
            LocalizedStringSet.internalBinaryWrite(message.localizedShortcutName, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ModifierGroup
 */
export const ModifierGroup = new ModifierGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModifierGroupDomainSpecificAttributes$Type extends MessageType<ModifierGroupDomainSpecificAttributes> {
    constructor() {
        super("Kiosk.ModifierGroupDomainSpecificAttributes", [
            { no: 1, name: "pizzaAttributes", kind: "message", oneof: "attributes", T: () => ModifierGroupPizzaSpecificAttributes }
        ]);
    }
    create(value?: PartialMessage<ModifierGroupDomainSpecificAttributes>): ModifierGroupDomainSpecificAttributes {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.attributes = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<ModifierGroupDomainSpecificAttributes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModifierGroupDomainSpecificAttributes): ModifierGroupDomainSpecificAttributes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.ModifierGroupPizzaSpecificAttributes pizzaAttributes */ 1:
                    message.attributes = {
                        oneofKind: "pizzaAttributes",
                        pizzaAttributes: ModifierGroupPizzaSpecificAttributes.internalBinaryRead(reader, reader.uint32(), options, (message.attributes as any).pizzaAttributes)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModifierGroupDomainSpecificAttributes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.ModifierGroupPizzaSpecificAttributes pizzaAttributes = 1; */
        if (message.attributes.oneofKind === "pizzaAttributes")
            ModifierGroupPizzaSpecificAttributes.internalBinaryWrite(message.attributes.pizzaAttributes, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ModifierGroupDomainSpecificAttributes
 */
export const ModifierGroupDomainSpecificAttributes = new ModifierGroupDomainSpecificAttributes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModifierGroupPizzaSpecificAttributes$Type extends MessageType<ModifierGroupPizzaSpecificAttributes> {
    constructor() {
        super("Kiosk.ModifierGroupPizzaSpecificAttributes", [
            { no: 1, name: "type", kind: "enum", T: () => ["Kiosk.ModifierGroupPizzaSpecificAttributes.ModifierGroupPizzaSpecificType", ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType] }
        ]);
    }
    create(value?: PartialMessage<ModifierGroupPizzaSpecificAttributes>): ModifierGroupPizzaSpecificAttributes {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<ModifierGroupPizzaSpecificAttributes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModifierGroupPizzaSpecificAttributes): ModifierGroupPizzaSpecificAttributes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.ModifierGroupPizzaSpecificAttributes.ModifierGroupPizzaSpecificType type */ 1:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModifierGroupPizzaSpecificAttributes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.ModifierGroupPizzaSpecificAttributes.ModifierGroupPizzaSpecificType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ModifierGroupPizzaSpecificAttributes
 */
export const ModifierGroupPizzaSpecificAttributes = new ModifierGroupPizzaSpecificAttributes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Modifier$Type extends MessageType<Modifier> {
    constructor() {
        super("Kiosk.Modifier", [
            { no: 1, name: "modifierId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "menuItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "isDefault", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "maxQuantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "incrementStep", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "allowQuantityIncrement", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "defaultQuantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "calorieText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "isInvisible", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "modifierCodes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ModifierModifierCodeGlue },
            { no: 12, name: "selectedDisplayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "price", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "displayOrder", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "localizedName", kind: "message", T: () => LocalizedStringSet },
            { no: 16, name: "localizedDescription", kind: "message", T: () => LocalizedStringSet },
            { no: 17, name: "localizedDisplayName", kind: "message", T: () => LocalizedStringSet },
            { no: 18, name: "domainSpecificAttributes", kind: "message", T: () => MenuModiferDomainSpecificAttributes },
            { no: 19, name: "HasShortcutName", kind: "scalar", jsonName: "HasShortcutName", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Modifier>): Modifier {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.modifierId = "";
        message.menuItemId = "";
        message.isDefault = false;
        message.maxQuantity = 0;
        message.incrementStep = 0;
        message.allowQuantityIncrement = false;
        message.defaultQuantity = 0;
        message.calorieText = "";
        message.isInvisible = false;
        message.modifierCodes = [];
        message.selectedDisplayName = "";
        message.price = 0;
        message.displayOrder = 0;
        message.hasShortcutName = false;
        if (value !== undefined)
            reflectionMergePartial<Modifier>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Modifier): Modifier {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modifierId */ 1:
                    message.modifierId = reader.string();
                    break;
                case /* string menuItemId */ 2:
                    message.menuItemId = reader.string();
                    break;
                case /* bool isDefault */ 3:
                    message.isDefault = reader.bool();
                    break;
                case /* int32 maxQuantity */ 4:
                    message.maxQuantity = reader.int32();
                    break;
                case /* int32 incrementStep */ 5:
                    message.incrementStep = reader.int32();
                    break;
                case /* bool allowQuantityIncrement */ 6:
                    message.allowQuantityIncrement = reader.bool();
                    break;
                case /* int32 defaultQuantity */ 7:
                    message.defaultQuantity = reader.int32();
                    break;
                case /* string calorieText */ 8:
                    message.calorieText = reader.string();
                    break;
                case /* bool isInvisible */ 9:
                    message.isInvisible = reader.bool();
                    break;
                case /* repeated Kiosk.ModifierModifierCodeGlue modifierCodes */ 11:
                    message.modifierCodes.push(ModifierModifierCodeGlue.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string selectedDisplayName */ 12:
                    message.selectedDisplayName = reader.string();
                    break;
                case /* int32 price */ 13:
                    message.price = reader.int32();
                    break;
                case /* int32 displayOrder */ 14:
                    message.displayOrder = reader.int32();
                    break;
                case /* Kiosk.LocalizedStringSet localizedName */ 15:
                    message.localizedName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedName);
                    break;
                case /* Kiosk.LocalizedStringSet localizedDescription */ 16:
                    message.localizedDescription = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedDescription);
                    break;
                case /* Kiosk.LocalizedStringSet localizedDisplayName */ 17:
                    message.localizedDisplayName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedDisplayName);
                    break;
                case /* Kiosk.MenuModiferDomainSpecificAttributes domainSpecificAttributes */ 18:
                    message.domainSpecificAttributes = MenuModiferDomainSpecificAttributes.internalBinaryRead(reader, reader.uint32(), options, message.domainSpecificAttributes);
                    break;
                case /* bool HasShortcutName = 19 [json_name = "HasShortcutName"];*/ 19:
                    message.hasShortcutName = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Modifier, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modifierId = 1; */
        if (message.modifierId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modifierId);
        /* string menuItemId = 2; */
        if (message.menuItemId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.menuItemId);
        /* bool isDefault = 3; */
        if (message.isDefault !== false)
            writer.tag(3, WireType.Varint).bool(message.isDefault);
        /* int32 maxQuantity = 4; */
        if (message.maxQuantity !== 0)
            writer.tag(4, WireType.Varint).int32(message.maxQuantity);
        /* int32 incrementStep = 5; */
        if (message.incrementStep !== 0)
            writer.tag(5, WireType.Varint).int32(message.incrementStep);
        /* bool allowQuantityIncrement = 6; */
        if (message.allowQuantityIncrement !== false)
            writer.tag(6, WireType.Varint).bool(message.allowQuantityIncrement);
        /* int32 defaultQuantity = 7; */
        if (message.defaultQuantity !== 0)
            writer.tag(7, WireType.Varint).int32(message.defaultQuantity);
        /* string calorieText = 8; */
        if (message.calorieText !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.calorieText);
        /* bool isInvisible = 9; */
        if (message.isInvisible !== false)
            writer.tag(9, WireType.Varint).bool(message.isInvisible);
        /* repeated Kiosk.ModifierModifierCodeGlue modifierCodes = 11; */
        for (let i = 0; i < message.modifierCodes.length; i++)
            ModifierModifierCodeGlue.internalBinaryWrite(message.modifierCodes[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string selectedDisplayName = 12; */
        if (message.selectedDisplayName !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.selectedDisplayName);
        /* int32 price = 13; */
        if (message.price !== 0)
            writer.tag(13, WireType.Varint).int32(message.price);
        /* int32 displayOrder = 14; */
        if (message.displayOrder !== 0)
            writer.tag(14, WireType.Varint).int32(message.displayOrder);
        /* Kiosk.LocalizedStringSet localizedName = 15; */
        if (message.localizedName)
            LocalizedStringSet.internalBinaryWrite(message.localizedName, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet localizedDescription = 16; */
        if (message.localizedDescription)
            LocalizedStringSet.internalBinaryWrite(message.localizedDescription, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet localizedDisplayName = 17; */
        if (message.localizedDisplayName)
            LocalizedStringSet.internalBinaryWrite(message.localizedDisplayName, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.MenuModiferDomainSpecificAttributes domainSpecificAttributes = 18; */
        if (message.domainSpecificAttributes)
            MenuModiferDomainSpecificAttributes.internalBinaryWrite(message.domainSpecificAttributes, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* bool HasShortcutName = 19 [json_name = "HasShortcutName"]; */
        if (message.hasShortcutName !== false)
            writer.tag(19, WireType.Varint).bool(message.hasShortcutName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.Modifier
 */
export const Modifier = new Modifier$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GroupModifierCodes$Type extends MessageType<GroupModifierCodes> {
    constructor() {
        super("Kiosk.GroupModifierCodes", [
            { no: 1, name: "modifierId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "modifierCodes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ModifierModifierCodeGlue }
        ]);
    }
    create(value?: PartialMessage<GroupModifierCodes>): GroupModifierCodes {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.modifierId = "";
        message.modifierCodes = [];
        if (value !== undefined)
            reflectionMergePartial<GroupModifierCodes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GroupModifierCodes): GroupModifierCodes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modifierId */ 1:
                    message.modifierId = reader.string();
                    break;
                case /* repeated Kiosk.ModifierModifierCodeGlue modifierCodes */ 11:
                    message.modifierCodes.push(ModifierModifierCodeGlue.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GroupModifierCodes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modifierId = 1; */
        if (message.modifierId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modifierId);
        /* repeated Kiosk.ModifierModifierCodeGlue modifierCodes = 11; */
        for (let i = 0; i < message.modifierCodes.length; i++)
            ModifierModifierCodeGlue.internalBinaryWrite(message.modifierCodes[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.GroupModifierCodes
 */
export const GroupModifierCodes = new GroupModifierCodes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModifierModifierCodeGlue$Type extends MessageType<ModifierModifierCodeGlue> {
    constructor() {
        super("Kiosk.ModifierModifierCodeGlue", [
            { no: 1, name: "modifierCodeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "isDefault", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ModifierModifierCodeGlue>): ModifierModifierCodeGlue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.modifierCodeId = "";
        message.isDefault = false;
        if (value !== undefined)
            reflectionMergePartial<ModifierModifierCodeGlue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModifierModifierCodeGlue): ModifierModifierCodeGlue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modifierCodeId */ 1:
                    message.modifierCodeId = reader.string();
                    break;
                case /* bool isDefault */ 2:
                    message.isDefault = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModifierModifierCodeGlue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modifierCodeId = 1; */
        if (message.modifierCodeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modifierCodeId);
        /* bool isDefault = 2; */
        if (message.isDefault !== false)
            writer.tag(2, WireType.Varint).bool(message.isDefault);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ModifierModifierCodeGlue
 */
export const ModifierModifierCodeGlue = new ModifierModifierCodeGlue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModifierCode$Type extends MessageType<ModifierCode> {
    constructor() {
        super("Kiosk.ModifierCode", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "modifierCodeBehavior", kind: "enum", T: () => ["Kiosk.ModifierCodeBehavior", ModifierCodeBehavior] },
            { no: 4, name: "localizedName", kind: "message", T: () => LocalizedStringSet },
            { no: 5, name: "localizedDisplayName", kind: "message", T: () => LocalizedStringSet },
            { no: 6, name: "DisplayOrder", kind: "scalar", jsonName: "DisplayOrder", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ModifierCode>): ModifierCode {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.modifierCodeBehavior = 0;
        message.displayOrder = 0;
        if (value !== undefined)
            reflectionMergePartial<ModifierCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModifierCode): ModifierCode {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* Kiosk.ModifierCodeBehavior modifierCodeBehavior */ 3:
                    message.modifierCodeBehavior = reader.int32();
                    break;
                case /* Kiosk.LocalizedStringSet localizedName */ 4:
                    message.localizedName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedName);
                    break;
                case /* Kiosk.LocalizedStringSet localizedDisplayName */ 5:
                    message.localizedDisplayName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedDisplayName);
                    break;
                case /* int32 DisplayOrder = 6 [json_name = "DisplayOrder"];*/ 6:
                    message.displayOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModifierCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* Kiosk.ModifierCodeBehavior modifierCodeBehavior = 3; */
        if (message.modifierCodeBehavior !== 0)
            writer.tag(3, WireType.Varint).int32(message.modifierCodeBehavior);
        /* Kiosk.LocalizedStringSet localizedName = 4; */
        if (message.localizedName)
            LocalizedStringSet.internalBinaryWrite(message.localizedName, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet localizedDisplayName = 5; */
        if (message.localizedDisplayName)
            LocalizedStringSet.internalBinaryWrite(message.localizedDisplayName, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int32 DisplayOrder = 6 [json_name = "DisplayOrder"]; */
        if (message.displayOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.displayOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ModifierCode
 */
export const ModifierCode = new ModifierCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuComboFamily$Type extends MessageType<MenuComboFamily> {
    constructor() {
        super("Kiosk.MenuComboFamily", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "selectedImagePath", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "members", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MenuComboFamilyMember },
            { no: 6, name: "startingPrice", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "calorieText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "selectedDisplayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "listingImagePath", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "promotionalDetails", kind: "message", T: () => MenuItemPromotionalDetails },
            { no: 11, name: "availability", kind: "message", T: () => EntityAvailability },
            { no: 12, name: "localizedName", kind: "message", T: () => LocalizedStringSet },
            { no: 13, name: "localizedDescription", kind: "message", T: () => LocalizedStringSet },
            { no: 14, name: "localizedDisplayName", kind: "message", T: () => LocalizedStringSet },
            { no: 15, name: "isVariablyPriced", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MenuComboFamily>): MenuComboFamily {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.description = "";
        message.selectedImagePath = "";
        message.members = [];
        message.startingPrice = 0;
        message.calorieText = "";
        message.selectedDisplayName = "";
        message.listingImagePath = "";
        message.isVariablyPriced = false;
        if (value !== undefined)
            reflectionMergePartial<MenuComboFamily>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuComboFamily): MenuComboFamily {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string selectedImagePath */ 4:
                    message.selectedImagePath = reader.string();
                    break;
                case /* repeated Kiosk.MenuComboFamilyMember members */ 5:
                    message.members.push(MenuComboFamilyMember.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 startingPrice */ 6:
                    message.startingPrice = reader.int32();
                    break;
                case /* string calorieText */ 7:
                    message.calorieText = reader.string();
                    break;
                case /* string selectedDisplayName */ 8:
                    message.selectedDisplayName = reader.string();
                    break;
                case /* string listingImagePath */ 9:
                    message.listingImagePath = reader.string();
                    break;
                case /* Kiosk.MenuItemPromotionalDetails promotionalDetails */ 10:
                    message.promotionalDetails = MenuItemPromotionalDetails.internalBinaryRead(reader, reader.uint32(), options, message.promotionalDetails);
                    break;
                case /* Kiosk.EntityAvailability availability */ 11:
                    message.availability = EntityAvailability.internalBinaryRead(reader, reader.uint32(), options, message.availability);
                    break;
                case /* Kiosk.LocalizedStringSet localizedName */ 12:
                    message.localizedName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedName);
                    break;
                case /* Kiosk.LocalizedStringSet localizedDescription */ 13:
                    message.localizedDescription = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedDescription);
                    break;
                case /* Kiosk.LocalizedStringSet localizedDisplayName */ 14:
                    message.localizedDisplayName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedDisplayName);
                    break;
                case /* bool isVariablyPriced */ 15:
                    message.isVariablyPriced = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuComboFamily, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string selectedImagePath = 4; */
        if (message.selectedImagePath !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.selectedImagePath);
        /* repeated Kiosk.MenuComboFamilyMember members = 5; */
        for (let i = 0; i < message.members.length; i++)
            MenuComboFamilyMember.internalBinaryWrite(message.members[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int32 startingPrice = 6; */
        if (message.startingPrice !== 0)
            writer.tag(6, WireType.Varint).int32(message.startingPrice);
        /* string calorieText = 7; */
        if (message.calorieText !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.calorieText);
        /* string selectedDisplayName = 8; */
        if (message.selectedDisplayName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.selectedDisplayName);
        /* string listingImagePath = 9; */
        if (message.listingImagePath !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.listingImagePath);
        /* Kiosk.MenuItemPromotionalDetails promotionalDetails = 10; */
        if (message.promotionalDetails)
            MenuItemPromotionalDetails.internalBinaryWrite(message.promotionalDetails, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.EntityAvailability availability = 11; */
        if (message.availability)
            EntityAvailability.internalBinaryWrite(message.availability, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet localizedName = 12; */
        if (message.localizedName)
            LocalizedStringSet.internalBinaryWrite(message.localizedName, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet localizedDescription = 13; */
        if (message.localizedDescription)
            LocalizedStringSet.internalBinaryWrite(message.localizedDescription, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet localizedDisplayName = 14; */
        if (message.localizedDisplayName)
            LocalizedStringSet.internalBinaryWrite(message.localizedDisplayName, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* bool isVariablyPriced = 15; */
        if (message.isVariablyPriced !== false)
            writer.tag(15, WireType.Varint).bool(message.isVariablyPriced);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuComboFamily
 */
export const MenuComboFamily = new MenuComboFamily$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuComboFamilyMember$Type extends MessageType<MenuComboFamilyMember> {
    constructor() {
        super("Kiosk.MenuComboFamilyMember", [
            { no: 1, name: "menuComboId", kind: "scalar", oneof: "comboFamilyMember", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "menuItemId", kind: "scalar", oneof: "comboFamilyMember", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "isDefault", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "displayOrder", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<MenuComboFamilyMember>): MenuComboFamilyMember {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.comboFamilyMember = { oneofKind: undefined };
        message.isDefault = false;
        message.displayOrder = 0;
        if (value !== undefined)
            reflectionMergePartial<MenuComboFamilyMember>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuComboFamilyMember): MenuComboFamilyMember {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string menuComboId */ 1:
                    message.comboFamilyMember = {
                        oneofKind: "menuComboId",
                        menuComboId: reader.string()
                    };
                    break;
                case /* string menuItemId */ 2:
                    message.comboFamilyMember = {
                        oneofKind: "menuItemId",
                        menuItemId: reader.string()
                    };
                    break;
                case /* bool isDefault */ 3:
                    message.isDefault = reader.bool();
                    break;
                case /* int32 displayOrder */ 4:
                    message.displayOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuComboFamilyMember, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string menuComboId = 1; */
        if (message.comboFamilyMember.oneofKind === "menuComboId")
            writer.tag(1, WireType.LengthDelimited).string(message.comboFamilyMember.menuComboId);
        /* string menuItemId = 2; */
        if (message.comboFamilyMember.oneofKind === "menuItemId")
            writer.tag(2, WireType.LengthDelimited).string(message.comboFamilyMember.menuItemId);
        /* bool isDefault = 3; */
        if (message.isDefault !== false)
            writer.tag(3, WireType.Varint).bool(message.isDefault);
        /* int32 displayOrder = 4; */
        if (message.displayOrder !== 0)
            writer.tag(4, WireType.Varint).int32(message.displayOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuComboFamilyMember
 */
export const MenuComboFamilyMember = new MenuComboFamilyMember$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuCombo$Type extends MessageType<MenuCombo> {
    constructor() {
        super("Kiosk.MenuCombo", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "selectedImagePath", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "menuComboComponentIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "startingPrice", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "calorieText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "listingImagePath", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "selectedDisplayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "promotionalDetails", kind: "message", T: () => MenuItemPromotionalDetails },
            { no: 13, name: "availability", kind: "message", T: () => EntityAvailability },
            { no: 14, name: "sizeLabel", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "localizedName", kind: "message", T: () => LocalizedStringSet },
            { no: 16, name: "localizedDescription", kind: "message", T: () => LocalizedStringSet },
            { no: 17, name: "localizedSizeLabel", kind: "message", T: () => LocalizedStringSet },
            { no: 18, name: "localizedDisplayName", kind: "message", T: () => LocalizedStringSet },
            { no: 19, name: "menuComboComponent", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MenuComboComponentGlue },
            { no: 20, name: "itemModifierGroupOverrides", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MenuComboItemModifierGroupOverride },
            { no: 21, name: "itemModifierOverrides", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MenuComboItemModifierOverride },
            { no: 22, name: "isVariablyPriced", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 23, name: "posId", kind: "message", T: () => StringValue },
            { no: 24, name: "posData", kind: "message", T: () => StringValue }
        ]);
    }
    create(value?: PartialMessage<MenuCombo>): MenuCombo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.description = "";
        message.selectedImagePath = "";
        message.menuComboComponentIds = [];
        message.startingPrice = 0;
        message.calorieText = "";
        message.listingImagePath = "";
        message.selectedDisplayName = "";
        message.sizeLabel = "";
        message.menuComboComponent = [];
        message.itemModifierGroupOverrides = [];
        message.itemModifierOverrides = [];
        message.isVariablyPriced = false;
        if (value !== undefined)
            reflectionMergePartial<MenuCombo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuCombo): MenuCombo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string selectedImagePath */ 5:
                    message.selectedImagePath = reader.string();
                    break;
                case /* repeated string menuComboComponentIds = 7 [deprecated = true];*/ 7:
                    message.menuComboComponentIds.push(reader.string());
                    break;
                case /* int32 startingPrice */ 8:
                    message.startingPrice = reader.int32();
                    break;
                case /* string calorieText */ 9:
                    message.calorieText = reader.string();
                    break;
                case /* string listingImagePath */ 10:
                    message.listingImagePath = reader.string();
                    break;
                case /* string selectedDisplayName */ 11:
                    message.selectedDisplayName = reader.string();
                    break;
                case /* Kiosk.MenuItemPromotionalDetails promotionalDetails */ 12:
                    message.promotionalDetails = MenuItemPromotionalDetails.internalBinaryRead(reader, reader.uint32(), options, message.promotionalDetails);
                    break;
                case /* Kiosk.EntityAvailability availability */ 13:
                    message.availability = EntityAvailability.internalBinaryRead(reader, reader.uint32(), options, message.availability);
                    break;
                case /* string sizeLabel */ 14:
                    message.sizeLabel = reader.string();
                    break;
                case /* Kiosk.LocalizedStringSet localizedName */ 15:
                    message.localizedName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedName);
                    break;
                case /* Kiosk.LocalizedStringSet localizedDescription */ 16:
                    message.localizedDescription = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedDescription);
                    break;
                case /* Kiosk.LocalizedStringSet localizedSizeLabel */ 17:
                    message.localizedSizeLabel = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedSizeLabel);
                    break;
                case /* Kiosk.LocalizedStringSet localizedDisplayName */ 18:
                    message.localizedDisplayName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedDisplayName);
                    break;
                case /* repeated Kiosk.MenuComboComponentGlue menuComboComponent */ 19:
                    message.menuComboComponent.push(MenuComboComponentGlue.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Kiosk.MenuComboItemModifierGroupOverride itemModifierGroupOverrides */ 20:
                    message.itemModifierGroupOverrides.push(MenuComboItemModifierGroupOverride.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Kiosk.MenuComboItemModifierOverride itemModifierOverrides */ 21:
                    message.itemModifierOverrides.push(MenuComboItemModifierOverride.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool isVariablyPriced */ 22:
                    message.isVariablyPriced = reader.bool();
                    break;
                case /* google.protobuf.StringValue posId */ 23:
                    message.posId = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.posId);
                    break;
                case /* google.protobuf.StringValue posData */ 24:
                    message.posData = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.posData);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuCombo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string selectedImagePath = 5; */
        if (message.selectedImagePath !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.selectedImagePath);
        /* repeated string menuComboComponentIds = 7 [deprecated = true]; */
        for (let i = 0; i < message.menuComboComponentIds.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.menuComboComponentIds[i]);
        /* int32 startingPrice = 8; */
        if (message.startingPrice !== 0)
            writer.tag(8, WireType.Varint).int32(message.startingPrice);
        /* string calorieText = 9; */
        if (message.calorieText !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.calorieText);
        /* string listingImagePath = 10; */
        if (message.listingImagePath !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.listingImagePath);
        /* string selectedDisplayName = 11; */
        if (message.selectedDisplayName !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.selectedDisplayName);
        /* Kiosk.MenuItemPromotionalDetails promotionalDetails = 12; */
        if (message.promotionalDetails)
            MenuItemPromotionalDetails.internalBinaryWrite(message.promotionalDetails, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.EntityAvailability availability = 13; */
        if (message.availability)
            EntityAvailability.internalBinaryWrite(message.availability, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* string sizeLabel = 14; */
        if (message.sizeLabel !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.sizeLabel);
        /* Kiosk.LocalizedStringSet localizedName = 15; */
        if (message.localizedName)
            LocalizedStringSet.internalBinaryWrite(message.localizedName, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet localizedDescription = 16; */
        if (message.localizedDescription)
            LocalizedStringSet.internalBinaryWrite(message.localizedDescription, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet localizedSizeLabel = 17; */
        if (message.localizedSizeLabel)
            LocalizedStringSet.internalBinaryWrite(message.localizedSizeLabel, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet localizedDisplayName = 18; */
        if (message.localizedDisplayName)
            LocalizedStringSet.internalBinaryWrite(message.localizedDisplayName, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.MenuComboComponentGlue menuComboComponent = 19; */
        for (let i = 0; i < message.menuComboComponent.length; i++)
            MenuComboComponentGlue.internalBinaryWrite(message.menuComboComponent[i], writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.MenuComboItemModifierGroupOverride itemModifierGroupOverrides = 20; */
        for (let i = 0; i < message.itemModifierGroupOverrides.length; i++)
            MenuComboItemModifierGroupOverride.internalBinaryWrite(message.itemModifierGroupOverrides[i], writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.MenuComboItemModifierOverride itemModifierOverrides = 21; */
        for (let i = 0; i < message.itemModifierOverrides.length; i++)
            MenuComboItemModifierOverride.internalBinaryWrite(message.itemModifierOverrides[i], writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* bool isVariablyPriced = 22; */
        if (message.isVariablyPriced !== false)
            writer.tag(22, WireType.Varint).bool(message.isVariablyPriced);
        /* google.protobuf.StringValue posId = 23; */
        if (message.posId)
            StringValue.internalBinaryWrite(message.posId, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue posData = 24; */
        if (message.posData)
            StringValue.internalBinaryWrite(message.posData, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuCombo
 */
export const MenuCombo = new MenuCombo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuComboItemModifierGroupOverride$Type extends MessageType<MenuComboItemModifierGroupOverride> {
    constructor() {
        super("Kiosk.MenuComboItemModifierGroupOverride", [
            { no: 1, name: "menuItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "modifierGroupId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "isInvisible", kind: "message", T: () => BoolValue },
            { no: 4, name: "freeModifierCount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "comboComponentId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MenuComboItemModifierGroupOverride>): MenuComboItemModifierGroupOverride {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.menuItemId = "";
        message.modifierGroupId = "";
        message.freeModifierCount = 0;
        message.comboComponentId = "";
        if (value !== undefined)
            reflectionMergePartial<MenuComboItemModifierGroupOverride>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuComboItemModifierGroupOverride): MenuComboItemModifierGroupOverride {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string menuItemId */ 1:
                    message.menuItemId = reader.string();
                    break;
                case /* string modifierGroupId */ 2:
                    message.modifierGroupId = reader.string();
                    break;
                case /* google.protobuf.BoolValue isInvisible */ 3:
                    message.isInvisible = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.isInvisible);
                    break;
                case /* double freeModifierCount */ 4:
                    message.freeModifierCount = reader.double();
                    break;
                case /* string comboComponentId */ 5:
                    message.comboComponentId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuComboItemModifierGroupOverride, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string menuItemId = 1; */
        if (message.menuItemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.menuItemId);
        /* string modifierGroupId = 2; */
        if (message.modifierGroupId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.modifierGroupId);
        /* google.protobuf.BoolValue isInvisible = 3; */
        if (message.isInvisible)
            BoolValue.internalBinaryWrite(message.isInvisible, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* double freeModifierCount = 4; */
        if (message.freeModifierCount !== 0)
            writer.tag(4, WireType.Bit64).double(message.freeModifierCount);
        /* string comboComponentId = 5; */
        if (message.comboComponentId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.comboComponentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuComboItemModifierGroupOverride
 */
export const MenuComboItemModifierGroupOverride = new MenuComboItemModifierGroupOverride$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuComboItemModifierOverride$Type extends MessageType<MenuComboItemModifierOverride> {
    constructor() {
        super("Kiosk.MenuComboItemModifierOverride", [
            { no: 1, name: "menuItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "modifierId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "isDefault", kind: "message", T: () => BoolValue },
            { no: 4, name: "price", kind: "message", T: () => Int32Value }
        ]);
    }
    create(value?: PartialMessage<MenuComboItemModifierOverride>): MenuComboItemModifierOverride {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.menuItemId = "";
        message.modifierId = "";
        if (value !== undefined)
            reflectionMergePartial<MenuComboItemModifierOverride>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuComboItemModifierOverride): MenuComboItemModifierOverride {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string menuItemId */ 1:
                    message.menuItemId = reader.string();
                    break;
                case /* string modifierId */ 2:
                    message.modifierId = reader.string();
                    break;
                case /* google.protobuf.BoolValue isDefault */ 3:
                    message.isDefault = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.isDefault);
                    break;
                case /* google.protobuf.Int32Value price */ 4:
                    message.price = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.price);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuComboItemModifierOverride, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string menuItemId = 1; */
        if (message.menuItemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.menuItemId);
        /* string modifierId = 2; */
        if (message.modifierId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.modifierId);
        /* google.protobuf.BoolValue isDefault = 3; */
        if (message.isDefault)
            BoolValue.internalBinaryWrite(message.isDefault, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value price = 4; */
        if (message.price)
            Int32Value.internalBinaryWrite(message.price, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuComboItemModifierOverride
 */
export const MenuComboItemModifierOverride = new MenuComboItemModifierOverride$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuComboComponentGlue$Type extends MessageType<MenuComboComponentGlue> {
    constructor() {
        super("Kiosk.MenuComboComponentGlue", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "displayOrder", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "isDefault", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "isHidden", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MenuComboComponentGlue>): MenuComboComponentGlue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.displayOrder = 0;
        message.isDefault = false;
        message.isHidden = false;
        if (value !== undefined)
            reflectionMergePartial<MenuComboComponentGlue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuComboComponentGlue): MenuComboComponentGlue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* int32 displayOrder */ 2:
                    message.displayOrder = reader.int32();
                    break;
                case /* bool isDefault */ 3:
                    message.isDefault = reader.bool();
                    break;
                case /* bool isHidden */ 4:
                    message.isHidden = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuComboComponentGlue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int32 displayOrder = 2; */
        if (message.displayOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.displayOrder);
        /* bool isDefault = 3; */
        if (message.isDefault !== false)
            writer.tag(3, WireType.Varint).bool(message.isDefault);
        /* bool isHidden = 4; */
        if (message.isHidden !== false)
            writer.tag(4, WireType.Varint).bool(message.isHidden);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuComboComponentGlue
 */
export const MenuComboComponentGlue = new MenuComboComponentGlue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuComboComponent$Type extends MessageType<MenuComboComponent> {
    constructor() {
        super("Kiosk.MenuComboComponent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["Kiosk.MenuComboComponentType", MenuComboComponentType] },
            { no: 4, name: "maxSelectionCount", kind: "message", T: () => Int32Value },
            { no: 5, name: "minSelectionCount", kind: "message", T: () => Int32Value },
            { no: 6, name: "menuItemIdChoices", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "selectedImagePath", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "listingImagePath", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "selectedDisplayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "choices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MenuComboComponentChoice },
            { no: 11, name: "localizedName", kind: "message", T: () => LocalizedStringSet },
            { no: 12, name: "localizedDisplayName", kind: "message", T: () => LocalizedStringSet },
            { no: 13, name: "choiceGroups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MenuComboComponentGrouping },
            { no: 14, name: "posId", kind: "message", T: () => StringValue },
            { no: 15, name: "posData", kind: "message", T: () => StringValue }
        ]);
    }
    create(value?: PartialMessage<MenuComboComponent>): MenuComboComponent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.type = 0;
        message.menuItemIdChoices = [];
        message.selectedImagePath = "";
        message.listingImagePath = "";
        message.selectedDisplayName = "";
        message.choices = [];
        message.choiceGroups = [];
        if (value !== undefined)
            reflectionMergePartial<MenuComboComponent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuComboComponent): MenuComboComponent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* Kiosk.MenuComboComponentType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* google.protobuf.Int32Value maxSelectionCount */ 4:
                    message.maxSelectionCount = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.maxSelectionCount);
                    break;
                case /* google.protobuf.Int32Value minSelectionCount */ 5:
                    message.minSelectionCount = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.minSelectionCount);
                    break;
                case /* repeated string menuItemIdChoices */ 6:
                    message.menuItemIdChoices.push(reader.string());
                    break;
                case /* string selectedImagePath */ 7:
                    message.selectedImagePath = reader.string();
                    break;
                case /* string listingImagePath */ 8:
                    message.listingImagePath = reader.string();
                    break;
                case /* string selectedDisplayName */ 9:
                    message.selectedDisplayName = reader.string();
                    break;
                case /* repeated Kiosk.MenuComboComponentChoice choices */ 10:
                    message.choices.push(MenuComboComponentChoice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.LocalizedStringSet localizedName */ 11:
                    message.localizedName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedName);
                    break;
                case /* Kiosk.LocalizedStringSet localizedDisplayName */ 12:
                    message.localizedDisplayName = LocalizedStringSet.internalBinaryRead(reader, reader.uint32(), options, message.localizedDisplayName);
                    break;
                case /* repeated Kiosk.MenuComboComponentGrouping choiceGroups */ 13:
                    message.choiceGroups.push(MenuComboComponentGrouping.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.StringValue posId */ 14:
                    message.posId = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.posId);
                    break;
                case /* google.protobuf.StringValue posData */ 15:
                    message.posData = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.posData);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuComboComponent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* Kiosk.MenuComboComponentType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* google.protobuf.Int32Value maxSelectionCount = 4; */
        if (message.maxSelectionCount)
            Int32Value.internalBinaryWrite(message.maxSelectionCount, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Int32Value minSelectionCount = 5; */
        if (message.minSelectionCount)
            Int32Value.internalBinaryWrite(message.minSelectionCount, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated string menuItemIdChoices = 6; */
        for (let i = 0; i < message.menuItemIdChoices.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.menuItemIdChoices[i]);
        /* string selectedImagePath = 7; */
        if (message.selectedImagePath !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.selectedImagePath);
        /* string listingImagePath = 8; */
        if (message.listingImagePath !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.listingImagePath);
        /* string selectedDisplayName = 9; */
        if (message.selectedDisplayName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.selectedDisplayName);
        /* repeated Kiosk.MenuComboComponentChoice choices = 10; */
        for (let i = 0; i < message.choices.length; i++)
            MenuComboComponentChoice.internalBinaryWrite(message.choices[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet localizedName = 11; */
        if (message.localizedName)
            LocalizedStringSet.internalBinaryWrite(message.localizedName, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.LocalizedStringSet localizedDisplayName = 12; */
        if (message.localizedDisplayName)
            LocalizedStringSet.internalBinaryWrite(message.localizedDisplayName, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.MenuComboComponentGrouping choiceGroups = 13; */
        for (let i = 0; i < message.choiceGroups.length; i++)
            MenuComboComponentGrouping.internalBinaryWrite(message.choiceGroups[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue posId = 14; */
        if (message.posId)
            StringValue.internalBinaryWrite(message.posId, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue posData = 15; */
        if (message.posData)
            StringValue.internalBinaryWrite(message.posData, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuComboComponent
 */
export const MenuComboComponent = new MenuComboComponent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuComboComponentGrouping$Type extends MessageType<MenuComboComponentGrouping> {
    constructor() {
        super("Kiosk.MenuComboComponentGrouping", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MenuComboComponentGrouping>): MenuComboComponentGrouping {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<MenuComboComponentGrouping>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuComboComponentGrouping): MenuComboComponentGrouping {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuComboComponentGrouping, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuComboComponentGrouping
 */
export const MenuComboComponentGrouping = new MenuComboComponentGrouping$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuComboComponentChoice$Type extends MessageType<MenuComboComponentChoice> {
    constructor() {
        super("Kiosk.MenuComboComponentChoice", [
            { no: 1, name: "menuItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "isDefault", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "price", kind: "message", T: () => Int32Value },
            { no: 4, name: "groupingId", kind: "message", T: () => StringValue }
        ]);
    }
    create(value?: PartialMessage<MenuComboComponentChoice>): MenuComboComponentChoice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.menuItemId = "";
        message.isDefault = false;
        if (value !== undefined)
            reflectionMergePartial<MenuComboComponentChoice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuComboComponentChoice): MenuComboComponentChoice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string menuItemId */ 1:
                    message.menuItemId = reader.string();
                    break;
                case /* bool isDefault */ 2:
                    message.isDefault = reader.bool();
                    break;
                case /* google.protobuf.Int32Value price */ 3:
                    message.price = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.price);
                    break;
                case /* google.protobuf.StringValue groupingId */ 4:
                    message.groupingId = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.groupingId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuComboComponentChoice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string menuItemId = 1; */
        if (message.menuItemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.menuItemId);
        /* bool isDefault = 2; */
        if (message.isDefault !== false)
            writer.tag(2, WireType.Varint).bool(message.isDefault);
        /* google.protobuf.Int32Value price = 3; */
        if (message.price)
            Int32Value.internalBinaryWrite(message.price, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue groupingId = 4; */
        if (message.groupingId)
            StringValue.internalBinaryWrite(message.groupingId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuComboComponentChoice
 */
export const MenuComboComponentChoice = new MenuComboComponentChoice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuVM$Type extends MessageType<MenuVM> {
    constructor() {
        super("Kiosk.MenuVM", [
            { no: 3, name: "orderUpsellGroups", kind: "message", T: () => OrderUpsellGroupListVM },
            { no: 4, name: "itemUpsellGroups", kind: "message", T: () => ItemUpsellGroupListVM },
            { no: 5, name: "item86List", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "itemUpsellMappings", kind: "message", T: () => ItemUpsellMappingsVM }
        ]);
    }
    create(value?: PartialMessage<MenuVM>): MenuVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.item86List = [];
        if (value !== undefined)
            reflectionMergePartial<MenuVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuVM): MenuVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.OrderUpsellGroupListVM orderUpsellGroups */ 3:
                    message.orderUpsellGroups = OrderUpsellGroupListVM.internalBinaryRead(reader, reader.uint32(), options, message.orderUpsellGroups);
                    break;
                case /* Kiosk.ItemUpsellGroupListVM itemUpsellGroups */ 4:
                    message.itemUpsellGroups = ItemUpsellGroupListVM.internalBinaryRead(reader, reader.uint32(), options, message.itemUpsellGroups);
                    break;
                case /* repeated string item86List */ 5:
                    message.item86List.push(reader.string());
                    break;
                case /* Kiosk.ItemUpsellMappingsVM itemUpsellMappings */ 6:
                    message.itemUpsellMappings = ItemUpsellMappingsVM.internalBinaryRead(reader, reader.uint32(), options, message.itemUpsellMappings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.OrderUpsellGroupListVM orderUpsellGroups = 3; */
        if (message.orderUpsellGroups)
            OrderUpsellGroupListVM.internalBinaryWrite(message.orderUpsellGroups, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.ItemUpsellGroupListVM itemUpsellGroups = 4; */
        if (message.itemUpsellGroups)
            ItemUpsellGroupListVM.internalBinaryWrite(message.itemUpsellGroups, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated string item86List = 5; */
        for (let i = 0; i < message.item86List.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.item86List[i]);
        /* Kiosk.ItemUpsellMappingsVM itemUpsellMappings = 6; */
        if (message.itemUpsellMappings)
            ItemUpsellMappingsVM.internalBinaryWrite(message.itemUpsellMappings, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuVM
 */
export const MenuVM = new MenuVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuAvailabilityScheduleAvailableTimeVM$Type extends MessageType<MenuAvailabilityScheduleAvailableTimeVM> {
    constructor() {
        super("Kiosk.MenuAvailabilityScheduleAvailableTimeVM", [
            { no: 1, name: "startTime", kind: "message", T: () => StringValue },
            { no: 2, name: "endTime", kind: "message", T: () => StringValue },
            { no: 3, name: "startDayOfWeek", kind: "message", T: () => OptionalDayOfTheDayOfWeekVM },
            { no: 4, name: "endDayOfWeek", kind: "message", T: () => OptionalDayOfTheDayOfWeekVM }
        ]);
    }
    create(value?: PartialMessage<MenuAvailabilityScheduleAvailableTimeVM>): MenuAvailabilityScheduleAvailableTimeVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<MenuAvailabilityScheduleAvailableTimeVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuAvailabilityScheduleAvailableTimeVM): MenuAvailabilityScheduleAvailableTimeVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.StringValue startTime */ 1:
                    message.startTime = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.startTime);
                    break;
                case /* google.protobuf.StringValue endTime */ 2:
                    message.endTime = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.endTime);
                    break;
                case /* Kiosk.OptionalDayOfTheDayOfWeekVM startDayOfWeek */ 3:
                    message.startDayOfWeek = OptionalDayOfTheDayOfWeekVM.internalBinaryRead(reader, reader.uint32(), options, message.startDayOfWeek);
                    break;
                case /* Kiosk.OptionalDayOfTheDayOfWeekVM endDayOfWeek */ 4:
                    message.endDayOfWeek = OptionalDayOfTheDayOfWeekVM.internalBinaryRead(reader, reader.uint32(), options, message.endDayOfWeek);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuAvailabilityScheduleAvailableTimeVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.StringValue startTime = 1; */
        if (message.startTime)
            StringValue.internalBinaryWrite(message.startTime, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue endTime = 2; */
        if (message.endTime)
            StringValue.internalBinaryWrite(message.endTime, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.OptionalDayOfTheDayOfWeekVM startDayOfWeek = 3; */
        if (message.startDayOfWeek)
            OptionalDayOfTheDayOfWeekVM.internalBinaryWrite(message.startDayOfWeek, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.OptionalDayOfTheDayOfWeekVM endDayOfWeek = 4; */
        if (message.endDayOfWeek)
            OptionalDayOfTheDayOfWeekVM.internalBinaryWrite(message.endDayOfWeek, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuAvailabilityScheduleAvailableTimeVM
 */
export const MenuAvailabilityScheduleAvailableTimeVM = new MenuAvailabilityScheduleAvailableTimeVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuAvailabilityScheduleVM$Type extends MessageType<MenuAvailabilityScheduleVM> {
    constructor() {
        super("Kiosk.MenuAvailabilityScheduleVM", [
            { no: 1, name: "availableTimes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MenuAvailabilityScheduleAvailableTimeVM }
        ]);
    }
    create(value?: PartialMessage<MenuAvailabilityScheduleVM>): MenuAvailabilityScheduleVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.availableTimes = [];
        if (value !== undefined)
            reflectionMergePartial<MenuAvailabilityScheduleVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuAvailabilityScheduleVM): MenuAvailabilityScheduleVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.MenuAvailabilityScheduleAvailableTimeVM availableTimes */ 1:
                    message.availableTimes.push(MenuAvailabilityScheduleAvailableTimeVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuAvailabilityScheduleVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.MenuAvailabilityScheduleAvailableTimeVM availableTimes = 1; */
        for (let i = 0; i < message.availableTimes.length; i++)
            MenuAvailabilityScheduleAvailableTimeVM.internalBinaryWrite(message.availableTimes[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.MenuAvailabilityScheduleVM
 */
export const MenuAvailabilityScheduleVM = new MenuAvailabilityScheduleVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsellConfiguration$Type extends MessageType<UpsellConfiguration> {
    constructor() {
        super("Kiosk.UpsellConfiguration", [
            { no: 3, name: "orderUpsellGroups", kind: "message", T: () => OrderUpsellGroupListVM },
            { no: 4, name: "itemUpsellGroups", kind: "message", T: () => ItemUpsellGroupListVM },
            { no: 6, name: "itemUpsellMappings", kind: "message", T: () => ItemUpsellMappingsVM }
        ]);
    }
    create(value?: PartialMessage<UpsellConfiguration>): UpsellConfiguration {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpsellConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpsellConfiguration): UpsellConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.OrderUpsellGroupListVM orderUpsellGroups */ 3:
                    message.orderUpsellGroups = OrderUpsellGroupListVM.internalBinaryRead(reader, reader.uint32(), options, message.orderUpsellGroups);
                    break;
                case /* Kiosk.ItemUpsellGroupListVM itemUpsellGroups */ 4:
                    message.itemUpsellGroups = ItemUpsellGroupListVM.internalBinaryRead(reader, reader.uint32(), options, message.itemUpsellGroups);
                    break;
                case /* Kiosk.ItemUpsellMappingsVM itemUpsellMappings */ 6:
                    message.itemUpsellMappings = ItemUpsellMappingsVM.internalBinaryRead(reader, reader.uint32(), options, message.itemUpsellMappings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpsellConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.OrderUpsellGroupListVM orderUpsellGroups = 3; */
        if (message.orderUpsellGroups)
            OrderUpsellGroupListVM.internalBinaryWrite(message.orderUpsellGroups, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.ItemUpsellGroupListVM itemUpsellGroups = 4; */
        if (message.itemUpsellGroups)
            ItemUpsellGroupListVM.internalBinaryWrite(message.itemUpsellGroups, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.ItemUpsellMappingsVM itemUpsellMappings = 6; */
        if (message.itemUpsellMappings)
            ItemUpsellMappingsVM.internalBinaryWrite(message.itemUpsellMappings, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.UpsellConfiguration
 */
export const UpsellConfiguration = new UpsellConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderUpsellGroupListVM$Type extends MessageType<OrderUpsellGroupListVM> {
    constructor() {
        super("Kiosk.OrderUpsellGroupListVM", [
            { no: 1, name: "groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderUpsellGroupVM },
            { no: 2, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<OrderUpsellGroupListVM>): OrderUpsellGroupListVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.groups = [];
        message.enabled = false;
        if (value !== undefined)
            reflectionMergePartial<OrderUpsellGroupListVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderUpsellGroupListVM): OrderUpsellGroupListVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.OrderUpsellGroupVM groups */ 1:
                    message.groups.push(OrderUpsellGroupVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool enabled */ 2:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderUpsellGroupListVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.OrderUpsellGroupVM groups = 1; */
        for (let i = 0; i < message.groups.length; i++)
            OrderUpsellGroupVM.internalBinaryWrite(message.groups[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool enabled = 2; */
        if (message.enabled !== false)
            writer.tag(2, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderUpsellGroupListVM
 */
export const OrderUpsellGroupListVM = new OrderUpsellGroupListVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderUpsellOfferVM$Type extends MessageType<OrderUpsellOfferVM> {
    constructor() {
        super("Kiosk.OrderUpsellOfferVM", [
            { no: 1, name: "menuItemId", kind: "scalar", oneof: "id", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "categoryId", kind: "scalar", oneof: "id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "item", kind: "message", T: () => SectionMenuItemVM },
            { no: 4, name: "sectionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "comboId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OrderUpsellOfferVM>): OrderUpsellOfferVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = { oneofKind: undefined };
        message.sectionId = "";
        message.comboId = "";
        if (value !== undefined)
            reflectionMergePartial<OrderUpsellOfferVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderUpsellOfferVM): OrderUpsellOfferVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string menuItemId = 1 [deprecated = true];*/ 1:
                    message.id = {
                        oneofKind: "menuItemId",
                        menuItemId: reader.string()
                    };
                    break;
                case /* string categoryId = 2 [deprecated = true];*/ 2:
                    message.id = {
                        oneofKind: "categoryId",
                        categoryId: reader.string()
                    };
                    break;
                case /* Kiosk.SectionMenuItemVM item */ 3:
                    message.item = SectionMenuItemVM.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                case /* string sectionId */ 4:
                    message.sectionId = reader.string();
                    break;
                case /* string comboId */ 5:
                    message.comboId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderUpsellOfferVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string menuItemId = 1 [deprecated = true]; */
        if (message.id.oneofKind === "menuItemId")
            writer.tag(1, WireType.LengthDelimited).string(message.id.menuItemId);
        /* string categoryId = 2 [deprecated = true]; */
        if (message.id.oneofKind === "categoryId")
            writer.tag(2, WireType.LengthDelimited).string(message.id.categoryId);
        /* Kiosk.SectionMenuItemVM item = 3; */
        if (message.item)
            SectionMenuItemVM.internalBinaryWrite(message.item, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string sectionId = 4; */
        if (message.sectionId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.sectionId);
        /* string comboId = 5; */
        if (message.comboId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.comboId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderUpsellOfferVM
 */
export const OrderUpsellOfferVM = new OrderUpsellOfferVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderUpsellGroupPropertiesVM$Type extends MessageType<OrderUpsellGroupPropertiesVM> {
    constructor() {
        super("Kiosk.OrderUpsellGroupPropertiesVM", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<OrderUpsellGroupPropertiesVM>): OrderUpsellGroupPropertiesVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.id = "";
        message.enabled = false;
        if (value !== undefined)
            reflectionMergePartial<OrderUpsellGroupPropertiesVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderUpsellGroupPropertiesVM): OrderUpsellGroupPropertiesVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                case /* bool enabled */ 3:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderUpsellGroupPropertiesVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        /* bool enabled = 3; */
        if (message.enabled !== false)
            writer.tag(3, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderUpsellGroupPropertiesVM
 */
export const OrderUpsellGroupPropertiesVM = new OrderUpsellGroupPropertiesVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderUpsellGroupVM$Type extends MessageType<OrderUpsellGroupVM> {
    constructor() {
        super("Kiosk.OrderUpsellGroupVM", [
            { no: 1, name: "properties", kind: "message", T: () => OrderUpsellGroupPropertiesVM },
            { no: 2, name: "offers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderUpsellOfferVM },
            { no: 3, name: "conditions", kind: "message", T: () => ConditionsVM }
        ]);
    }
    create(value?: PartialMessage<OrderUpsellGroupVM>): OrderUpsellGroupVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.offers = [];
        if (value !== undefined)
            reflectionMergePartial<OrderUpsellGroupVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderUpsellGroupVM): OrderUpsellGroupVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.OrderUpsellGroupPropertiesVM properties */ 1:
                    message.properties = OrderUpsellGroupPropertiesVM.internalBinaryRead(reader, reader.uint32(), options, message.properties);
                    break;
                case /* repeated Kiosk.OrderUpsellOfferVM offers */ 2:
                    message.offers.push(OrderUpsellOfferVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.ConditionsVM conditions */ 3:
                    message.conditions = ConditionsVM.internalBinaryRead(reader, reader.uint32(), options, message.conditions);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderUpsellGroupVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.OrderUpsellGroupPropertiesVM properties = 1; */
        if (message.properties)
            OrderUpsellGroupPropertiesVM.internalBinaryWrite(message.properties, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.OrderUpsellOfferVM offers = 2; */
        for (let i = 0; i < message.offers.length; i++)
            OrderUpsellOfferVM.internalBinaryWrite(message.offers[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.ConditionsVM conditions = 3; */
        if (message.conditions)
            ConditionsVM.internalBinaryWrite(message.conditions, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderUpsellGroupVM
 */
export const OrderUpsellGroupVM = new OrderUpsellGroupVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemUpsellGroupListVM$Type extends MessageType<ItemUpsellGroupListVM> {
    constructor() {
        super("Kiosk.ItemUpsellGroupListVM", [
            { no: 1, name: "groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemUpsellGroupVM },
            { no: 2, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ItemUpsellGroupListVM>): ItemUpsellGroupListVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.groups = [];
        message.enabled = false;
        if (value !== undefined)
            reflectionMergePartial<ItemUpsellGroupListVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemUpsellGroupListVM): ItemUpsellGroupListVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Kiosk.ItemUpsellGroupVM groups */ 1:
                    message.groups.push(ItemUpsellGroupVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool enabled */ 2:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemUpsellGroupListVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Kiosk.ItemUpsellGroupVM groups = 1; */
        for (let i = 0; i < message.groups.length; i++)
            ItemUpsellGroupVM.internalBinaryWrite(message.groups[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool enabled = 2; */
        if (message.enabled !== false)
            writer.tag(2, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ItemUpsellGroupListVM
 */
export const ItemUpsellGroupListVM = new ItemUpsellGroupListVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemUpsellOfferVM$Type extends MessageType<ItemUpsellOfferVM> {
    constructor() {
        super("Kiosk.ItemUpsellOfferVM", [
            { no: 1, name: "item", kind: "message", T: () => SectionMenuItemVM },
            { no: 2, name: "sectionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "comboId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ItemUpsellOfferVM>): ItemUpsellOfferVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sectionId = "";
        message.comboId = "";
        if (value !== undefined)
            reflectionMergePartial<ItemUpsellOfferVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemUpsellOfferVM): ItemUpsellOfferVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.SectionMenuItemVM item */ 1:
                    message.item = SectionMenuItemVM.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                case /* string sectionId */ 2:
                    message.sectionId = reader.string();
                    break;
                case /* string comboId */ 3:
                    message.comboId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemUpsellOfferVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.SectionMenuItemVM item = 1; */
        if (message.item)
            SectionMenuItemVM.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string sectionId = 2; */
        if (message.sectionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sectionId);
        /* string comboId = 3; */
        if (message.comboId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.comboId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ItemUpsellOfferVM
 */
export const ItemUpsellOfferVM = new ItemUpsellOfferVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemUpsellGroupPropertiesVM$Type extends MessageType<ItemUpsellGroupPropertiesVM> {
    constructor() {
        super("Kiosk.ItemUpsellGroupPropertiesVM", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ItemUpsellGroupPropertiesVM>): ItemUpsellGroupPropertiesVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.id = "";
        message.enabled = false;
        if (value !== undefined)
            reflectionMergePartial<ItemUpsellGroupPropertiesVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemUpsellGroupPropertiesVM): ItemUpsellGroupPropertiesVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                case /* bool enabled */ 3:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemUpsellGroupPropertiesVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        /* bool enabled = 3; */
        if (message.enabled !== false)
            writer.tag(3, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ItemUpsellGroupPropertiesVM
 */
export const ItemUpsellGroupPropertiesVM = new ItemUpsellGroupPropertiesVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemUpsellGroupVM$Type extends MessageType<ItemUpsellGroupVM> {
    constructor() {
        super("Kiosk.ItemUpsellGroupVM", [
            { no: 1, name: "properties", kind: "message", T: () => ItemUpsellGroupPropertiesVM },
            { no: 2, name: "offers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemUpsellOfferVM },
            { no: 3, name: "conditions", kind: "message", T: () => ConditionsVM }
        ]);
    }
    create(value?: PartialMessage<ItemUpsellGroupVM>): ItemUpsellGroupVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.offers = [];
        if (value !== undefined)
            reflectionMergePartial<ItemUpsellGroupVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemUpsellGroupVM): ItemUpsellGroupVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Kiosk.ItemUpsellGroupPropertiesVM properties */ 1:
                    message.properties = ItemUpsellGroupPropertiesVM.internalBinaryRead(reader, reader.uint32(), options, message.properties);
                    break;
                case /* repeated Kiosk.ItemUpsellOfferVM offers */ 2:
                    message.offers.push(ItemUpsellOfferVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Kiosk.ConditionsVM conditions */ 3:
                    message.conditions = ConditionsVM.internalBinaryRead(reader, reader.uint32(), options, message.conditions);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemUpsellGroupVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Kiosk.ItemUpsellGroupPropertiesVM properties = 1; */
        if (message.properties)
            ItemUpsellGroupPropertiesVM.internalBinaryWrite(message.properties, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Kiosk.ItemUpsellOfferVM offers = 2; */
        for (let i = 0; i < message.offers.length; i++)
            ItemUpsellOfferVM.internalBinaryWrite(message.offers[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.ConditionsVM conditions = 3; */
        if (message.conditions)
            ConditionsVM.internalBinaryWrite(message.conditions, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ItemUpsellGroupVM
 */
export const ItemUpsellGroupVM = new ItemUpsellGroupVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemUpsellMappingsVM$Type extends MessageType<ItemUpsellMappingsVM> {
    constructor() {
        super("Kiosk.ItemUpsellMappingsVM", [
            { no: 1, name: "map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<ItemUpsellMappingsVM>): ItemUpsellMappingsVM {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.map = {};
        if (value !== undefined)
            reflectionMergePartial<ItemUpsellMappingsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemUpsellMappingsVM): ItemUpsellMappingsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> map */ 1:
                    this.binaryReadMap1(message.map, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: ItemUpsellMappingsVM["map"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ItemUpsellMappingsVM["map"] | undefined, val: ItemUpsellMappingsVM["map"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Kiosk.ItemUpsellMappingsVM.map");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: ItemUpsellMappingsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> map = 1; */
        for (let k of globalThis.Object.keys(message.map))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.map[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.ItemUpsellMappingsVM
 */
export const ItemUpsellMappingsVM = new ItemUpsellMappingsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosData$Type extends MessageType<PosData> {
    constructor() {
        super("Kiosk.PosData", [
            { no: 1, name: "menuItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PosData>): PosData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.menuItemId = "";
        message.data = "";
        if (value !== undefined)
            reflectionMergePartial<PosData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosData): PosData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string menuItemId */ 1:
                    message.menuItemId = reader.string();
                    break;
                case /* string data */ 2:
                    message.data = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string menuItemId = 1; */
        if (message.menuItemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.menuItemId);
        /* string data = 2; */
        if (message.data !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.PosData
 */
export const PosData = new PosData$Type();
