/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "kioskOrderService.proto" (package "Kiosk", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { KioskOrderService } from "./kioskOrderService_pb";
import type { GetPreviousOrderReply } from "./kioskOrder_pb";
import type { GetPreviousOrderRequest } from "./kioskOrder_pb";
import type { LogOrderReply } from "./kioskOrder_pb";
import type { LogOrderRequest } from "./kioskOrder_pb";
import type { CloseOrderReply } from "./kioskOrder_pb";
import type { CloseOrderRequest } from "./kioskOrder_pb";
import type { UpdateOrderRequest } from "./kioskOrder_pb";
import type { DeleteOrderReply } from "./kioskOrder_pb";
import type { DeleteOrderRequest } from "./kioskOrder_pb";
import type { ValidateOrderResponse } from "./kioskOrder_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { OrderDetails } from "./kioskOrder_pb";
import type { ValidateOrderRequest } from "./kioskOrder_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Kiosk.KioskOrderService
 */
export interface IKioskOrderServiceClient {
    /**
     * @deprecated
     * @generated from protobuf rpc: ValidateOrder(Kiosk.ValidateOrderRequest) returns (Kiosk.OrderDetails);
     */
    validateOrder(input: ValidateOrderRequest, options?: RpcOptions): UnaryCall<ValidateOrderRequest, OrderDetails>;
    /**
     * @generated from protobuf rpc: ValidateOrderWithStatus(Kiosk.ValidateOrderRequest) returns (Kiosk.ValidateOrderResponse);
     */
    validateOrderWithStatus(input: ValidateOrderRequest, options?: RpcOptions): UnaryCall<ValidateOrderRequest, ValidateOrderResponse>;
    /**
     * @generated from protobuf rpc: DeleteOrder(Kiosk.DeleteOrderRequest) returns (Kiosk.DeleteOrderReply);
     */
    deleteOrder(input: DeleteOrderRequest, options?: RpcOptions): UnaryCall<DeleteOrderRequest, DeleteOrderReply>;
    /**
     * @generated from protobuf rpc: UpdateOrder(Kiosk.UpdateOrderRequest) returns (Kiosk.OrderDetails);
     */
    updateOrder(input: UpdateOrderRequest, options?: RpcOptions): UnaryCall<UpdateOrderRequest, OrderDetails>;
    /**
     * @generated from protobuf rpc: CloseOrder(Kiosk.CloseOrderRequest) returns (Kiosk.CloseOrderReply);
     */
    closeOrder(input: CloseOrderRequest, options?: RpcOptions): UnaryCall<CloseOrderRequest, CloseOrderReply>;
    /**
     * @generated from protobuf rpc: LogOrder(Kiosk.LogOrderRequest) returns (Kiosk.LogOrderReply);
     */
    logOrder(input: LogOrderRequest, options?: RpcOptions): UnaryCall<LogOrderRequest, LogOrderReply>;
    /**
     * @generated from protobuf rpc: GetPreviousOrder(Kiosk.GetPreviousOrderRequest) returns (Kiosk.GetPreviousOrderReply);
     */
    getPreviousOrder(input: GetPreviousOrderRequest, options?: RpcOptions): UnaryCall<GetPreviousOrderRequest, GetPreviousOrderReply>;
}
/**
 * @generated from protobuf service Kiosk.KioskOrderService
 */
export class KioskOrderServiceClient implements IKioskOrderServiceClient, ServiceInfo {
    typeName = KioskOrderService.typeName;
    methods = KioskOrderService.methods;
    options = KioskOrderService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @deprecated
     * @generated from protobuf rpc: ValidateOrder(Kiosk.ValidateOrderRequest) returns (Kiosk.OrderDetails);
     */
    validateOrder(input: ValidateOrderRequest, options?: RpcOptions): UnaryCall<ValidateOrderRequest, OrderDetails> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ValidateOrderRequest, OrderDetails>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ValidateOrderWithStatus(Kiosk.ValidateOrderRequest) returns (Kiosk.ValidateOrderResponse);
     */
    validateOrderWithStatus(input: ValidateOrderRequest, options?: RpcOptions): UnaryCall<ValidateOrderRequest, ValidateOrderResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ValidateOrderRequest, ValidateOrderResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteOrder(Kiosk.DeleteOrderRequest) returns (Kiosk.DeleteOrderReply);
     */
    deleteOrder(input: DeleteOrderRequest, options?: RpcOptions): UnaryCall<DeleteOrderRequest, DeleteOrderReply> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteOrderRequest, DeleteOrderReply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateOrder(Kiosk.UpdateOrderRequest) returns (Kiosk.OrderDetails);
     */
    updateOrder(input: UpdateOrderRequest, options?: RpcOptions): UnaryCall<UpdateOrderRequest, OrderDetails> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateOrderRequest, OrderDetails>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CloseOrder(Kiosk.CloseOrderRequest) returns (Kiosk.CloseOrderReply);
     */
    closeOrder(input: CloseOrderRequest, options?: RpcOptions): UnaryCall<CloseOrderRequest, CloseOrderReply> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<CloseOrderRequest, CloseOrderReply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: LogOrder(Kiosk.LogOrderRequest) returns (Kiosk.LogOrderReply);
     */
    logOrder(input: LogOrderRequest, options?: RpcOptions): UnaryCall<LogOrderRequest, LogOrderReply> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<LogOrderRequest, LogOrderReply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPreviousOrder(Kiosk.GetPreviousOrderRequest) returns (Kiosk.GetPreviousOrderReply);
     */
    getPreviousOrder(input: GetPreviousOrderRequest, options?: RpcOptions): UnaryCall<GetPreviousOrderRequest, GetPreviousOrderReply> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPreviousOrderRequest, GetPreviousOrderReply>("unary", this._transport, method, opt, input);
    }
}
