import type { DownloadedImageUrl } from '../data-management/types';
import type { KioskComboFamily } from './kiosk_combo_family';
import type { KioskComboFamilyMember } from './kiosk_combo_family_member';
import type { LocalizedStringSet } from '../../generated/menu_pb';

export class KioskTranslator {
  public static localizedComboFamilyName(comboFamily: KioskComboFamily, locale: string) {
    return comboFamily?.localizedDisplayName?.translations[locale]
      ? comboFamily.localizedDisplayName.translations[locale]
      : comboFamily.selectedDisplayName
        ? comboFamily.selectedDisplayName
        : comboFamily.name;
  }

  public static localizedComboFamilyDescription(comboFamily: KioskComboFamily, locale: string) {
    return comboFamily?.localizedDescription?.translations[locale]
      ? comboFamily.localizedDescription.translations[locale]
      : comboFamily.description;
  }

  public static localizedComboFamilyMemberName(member: KioskComboFamilyMember, locale: string) {
    return member.type === 'combo'
      ? member.combo?.localizedDisplayName?.translations &&
        member.combo?.localizedDisplayName?.translations[locale]
        ? member.combo?.localizedDisplayName?.translations[locale]
        : member.combo.name
      : member.item?.localizedDisplayName?.translations &&
          member.item?.localizedDisplayName?.translations[locale]
        ? member.item?.localizedDisplayName?.translations[locale]
        : member.item.name;
  }

  public static localizedComboFamilyMemberDescription(
    member: KioskComboFamilyMember,
    locale: string
  ) {
    return member.type === 'combo'
      ? member.combo?.localizedDescription?.translations &&
        member.combo?.localizedDescription?.translations[locale]
        ? member.combo?.localizedDescription?.translations[locale]
        : member.combo.description
      : member.item?.localizedDescription?.translations &&
          member.item?.localizedDescription?.translations[locale]
        ? member.item?.localizedDescription?.translations[locale]
        : member.item.description;
  }

  public static localizedComboFamilyMemberTitle(
    comboFamily: KioskComboFamily,
    member: KioskComboFamilyMember,
    locale: string
  ) {
    return member.type === 'combo'
      ? member.combo?.localizedSizeLabel?.translations &&
        member.combo?.localizedSizeLabel?.translations[locale]
        ? member.combo?.localizedSizeLabel?.translations[locale]
        : member.combo.sizeLabel && member.combo.sizeLabel !== ''
          ? member.combo.sizeLabel
          : member.combo.selectedDisplayName && member.combo.selectedDisplayName !== ''
            ? member.combo.selectedDisplayName
            : member.combo.name
      : comboFamily?.localizedDisplayName?.translations[locale]
        ? comboFamily?.localizedDisplayName?.translations[locale]
        : member.item?.localizedDisplayName?.translations &&
            member.item?.localizedDisplayName?.translations[locale]
          ? member.item?.localizedDisplayName?.translations[locale]
          : member.item.selectedDisplayName && member.item.selectedDisplayName !== ''
            ? member.item.selectedDisplayName
            : member.item.name;
  }

  public static getLocalizedValue(
    localizedStringSet: LocalizedStringSet | undefined,
    locale: string
  ) {
    return localizedStringSet?.translations[locale]
      ? localizedStringSet.translations[locale]
      : undefined;
  }

  public static getImagePath(itemImage: DownloadedImageUrl | string, defaultItemImage: string) {
    return itemImage && itemImage !== '' ? itemImage : defaultItemImage;
  }
}
