import { assign, createMachine, type Receiver, type Sender } from 'xstate';
import { pure, sendTo } from 'xstate/lib/actions';
import { gem_service } from '../events/gem_event_service';

export type Proxy_Context = {
  type: 'tcp' | 'serial';
  options: Record<string, string | number>;
  send_data: (string | ArrayBufferLike | Blob | ArrayBufferView)[];
  received_data?: Uint8Array;
};

export type Proxy_Event =
  | {
      type: 'SEND';
      data: string | ArrayBufferLike | Blob | ArrayBufferView;
    }
  | { type: 'CLOSE' };

export type Proxy_Events =
  | {
      type: 'CONNECTED';
    }
  | { type: 'RECEIVED'; data: Uint8Array }
  | Proxy_Event;

function* merge_array_buffers(...buffers: Uint8Array[]) {
  for (const buffer of buffers) {
    yield* buffer;
  }
}

export const web_proxy_callback =
  ({ type, options }: Proxy_Context) =>
  (callback: Sender<Proxy_Events>, onReceive: Receiver<Proxy_Event>) => {
    try {
      const params = Object.entries(options)
        .map((x) => x.join('='))
        .join('&');
      const socket = new WebSocket(`ws://localhost:4200/${type}?${params}`);

      socket.binaryType = 'arraybuffer';
      onReceive((ev) => {
        switch (ev.type) {
          case 'CLOSE':
            console.log('CLOSER');
            socket.close();
            return;
          case 'SEND':
            if (ev.data) {
              socket.send(ev.data);
            }
            return;
        }
      });

      socket.addEventListener('open', () => {
        callback('CONNECTED');
      });

      socket.addEventListener('message', (ev: MessageEvent<ArrayBuffer>) => {
        callback({ type: 'RECEIVED', data: new Uint8Array(ev.data) });
      });

      socket.addEventListener('close', (event) => {
        if (event.code !== 1000 && event.code !== 1001) {
          const ex = `WEBSOCKET CLOSED DUE TO ERROR CODE: ${event.code} TARGET: ${
            (event?.target as WebSocket).url
          }`;
          gem_service.session_websocket_connection_failed(ex);
        }

        callback('CLOSE');
      });

      return () => {
        if (socket.readyState === 1) {
          socket.close();
        }
      };
    } catch (ex) {
      console.error(`Cannot load Websocket: ${ex}`);
      callback('CLOSE');
      return;
    }
  };

export const proxy_machine = createMachine(
  {
    id: 'proxy-machine',
    predictableActionArguments: true,
    preserveActionOrder: true,
    initial: 'connection',
    tsTypes: {} as import('./proxy_machine.typegen').Typegen0,
    schema: {} as {
      context: Proxy_Context;
      events: Proxy_Events;
    },
    context: {
      type: 'serial',
      send_data: [],
      options: {},
    },
    on: {
      SEND: {
        actions: ['queue_send'],
      },
      CLOSE: { target: 'disconnected' },
    },
    states: {
      connection: {
        invoke: {
          src: 'proxy_callback',
          id: 'socket',
          onError: { target: 'disconnected' },
        },
        initial: 'unavailable',
        states: {
          unavailable: {
            entry: ['clear_receive'],
            on: {
              CONNECTED: { target: 'connecting' },
            },
          },
          connecting: {
            after: {
              50: { target: 'available' },
            },
          },
          available: {
            entry: ['dequeue_send', 'clear_send_queue'],
            on: {
              RECEIVED: { actions: ['received_data'] },
              SEND: { actions: ['clear_receive', 'send_to_socket'] },
            },
          },
        },
      },
      disconnected: {
        on: {
          SEND: {
            target: 'connection',
            actions: ['queue_send'],
          },
          CLOSE: { target: 'disconnected' },
        },
      },
    },
  },
  {
    services: {
      proxy_callback: web_proxy_callback,
    },
    actions: {
      clear_receive: assign({ received_data: undefined }),
      received_data: assign({
        received_data: (ctx, evt) => {
          if (evt.type !== 'RECEIVED') {
            return ctx.received_data;
          }
          if (!ctx.received_data) {
            return evt.data;
          }
          return new Uint8Array(merge_array_buffers(ctx.received_data, evt.data));
        },
      }),
      queue_send: assign({ send_data: ({ send_data }, evt) => send_data.concat(evt.data) }),
      dequeue_send: pure(({ send_data }) => {
        if (!send_data.length) {
          return;
        }
        return send_data.map((d) => sendTo('socket', { type: 'SEND', data: d }));
      }),
      send_to_socket: sendTo('socket', (ctx, evt) => ({
        type: 'SEND',
        data: evt.data,
      })),
      clear_send_queue: assign({ send_data: [] }),
    },
  }
);
