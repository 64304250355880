/* eslint-disable @typescript-eslint/no-explicit-any */
import type { DiscountOrder } from '@grubbrr/grubbrr-discounts';
import { DiscountEngine, ValidationMessages } from '@grubbrr/grubbrr-discounts';
import { assign, createMachine, interpret, type AnyEventObject } from 'xstate';
import type { LogOrderRequest } from '../../../generated/kioskOrder_pb';
import type { LocationDb } from '../../storage/location_db';
import type { MinimumSubtotalCondition } from '@grubbrr/grubbrr-discounts/dist/models/discount';
import { getOverridePrice, type KioskDiscount, type KioskPromotionalItem } from '../../models';
import { assertExists } from '../../services/utils';
import type { IBarcodeScannerService } from '../barcodes/barcode_machine';
import { error_service } from '../kiosk/error_machine';
import { routing_service } from '../routing/routing_machine';
import type { MenuItemPromotionalDetails } from '../../../generated/menu_pb';
import { order_service, type PartialILocationStateManager } from '../order/order_machine';
import { get } from 'svelte/store';
import type { ApplicationResult } from '@grubbrr/grubbrr-discounts/dist/engine/rules';
import { loyalty_service } from '../loyalty/loyalty_machine';

export const enum UserPerks {
  Rewards,
  Discounts,
  None,
}

type DiscountContext = {
  location_db?: LocationDb;
  discountList?: KioskDiscount[];
  promotionalItemList?: KioskPromotionalItem[];
  selectedDiscount?: KioskDiscount[];
  discountAmount?: number;
  appliedDiscounts: KioskDiscount[];
  priceFormatter: any;
  textFormatter: any;
  locationStateManager?: PartialILocationStateManager;
  appliedDiscountCode?: string;
  isScannerInput?: boolean;
  promoCode: string;
  inputCode: string;
  addPromoItemToCart?: (item: KioskPromotionalItem | undefined) => void;
  promoItem?: KioskPromotionalItem;
  errorMessage?: Error;
  barcodeScanner?: IBarcodeScannerService;
};

// this is gross - I want this separated from the xstate machine
// so that I can call it synchronously when a user tries to check out
// but I also need it in the discount machine to respond to the order
// machine events
export const showErrorsIfDiscountsInvalid = (
  order: DiscountOrder | undefined,
  ctx: DiscountContext
): { isValid: boolean } => {
  if (ctx.appliedDiscounts?.length == 0) {
    return { isValid: true };
  } else {
    const discountOrder = {
      order: order?.order,
      orderDetails: order?.orderDetails,
      appliedDiscounts: ctx.appliedDiscounts ?? [],
    };
    // Check in the engine about the cart update affects the applied disocunt or not
    // we are passing in an empty list b/c no new discounts should be applied
    // this is solely to validate that the discounts that are already applied are still valid
    const result = engine.validateAppliedDiscounts(discountOrder);
    console.log('update discount result', JSON.stringify(result));

    if (!result.areAllDiscountsValid) {
      showErrors(result.messages, ctx);
    }

    return { isValid: result.areAllDiscountsValid };
  }
};

type DiscountMachineEvent =
  | {
      type: 'OPEN_MODAL';
      data: {
        selectedDiscount: KioskDiscount;
        priceFormatter: any;
        textFormatter: any;
      };
    }
  | {
      type: 'CLOSE';
      data: undefined;
    }
  | {
      type: 'OPEN_DISC_CODE_MODAL';
      data: undefined;
    }
  | {
      type: 'CLOSE_DISC_CODE';
      data: undefined;
    }
  | {
      type: 'APPLY';
      data: { order?: DiscountOrder; orderItemId?: string };
    }
  | {
      type: 'CHECK_DISC_ENGINE';
      data: { order?: DiscountOrder };
    }
  | {
      type: 'CLEAR_DISCOUNT';
      data: undefined;
    }
  | {
      type: 'CLEAR_PROMO_CODE';
      data: undefined;
    }
  | {
      type: 'REMOVE_DISCOUNT';
      data: {
        discount?: KioskDiscount;
        orderItemId?: string;
        itemQuantity?: number;
        menuItemId?: string;
        subtotal?: number;
      };
    }
  | {
      type: 'ERROR';
      data: { message?: string };
    }
  | {
      type: 'SET_LOCATION_STATE_MANAGER';
      data: PartialILocationStateManager;
    }
  | {
      type: 'SET_BARCODE_SCANNER_SERVICE';
      data: IBarcodeScannerService;
    }
  | {
      type: 'SET_DISC';
      data: undefined;
    }
  | {
      type: 'UPDATE_CART_DISCOUNT';
      data: { order?: DiscountOrder; isUpdateNewItemDisc?: boolean };
    }
  | {
      type: 'STARTSCANONOPEN';
      data: {
        cancel?: (...args: any[]) => any;
        barcodeFn: (...args: any[]) => any;
        timeout: number;
        addPromoItemToCart: (item: KioskPromotionalItem | undefined) => void;
        isScanDiscount: boolean;
      };
    }
  | {
      type: 'STARTSCAN';
      data: {
        barcodeFn: (...args: any[]) => any;
        timeout: number;
      };
    }
  | {
      type: 'STOPSCAN';
      data: undefined;
    }
  | {
      type: 'FIND_DISC_CODE';
      data: { input_code?: string; isScannerInput?: boolean };
    };

const engine = new DiscountEngine();
const discount_machine = createMachine(
  {
    id: 'discount_machine',
    initial: 'idle',
    predictableActionArguments: true,
    preserveActionOrder: true,
    context: {
      selectedDiscount: undefined,
      discountAmount: 0,
      appliedDiscounts: [],
      discountList: [],
      priceFormatter: undefined,
      textFormatter: undefined,
      appliedDiscountCode: '',
      isScannerInput: false,
      promoCode: '',
      inputCode: '',
      promoItem: undefined,
    },
    tsTypes: {} as import('./discount_service.typegen').Typegen0,
    schema: {} as {
      context: DiscountContext;
      events: DiscountMachineEvent;
      services: {
        log_order: { data: LogOrderRequest | undefined };
      };
    },
    on: {
      SET_LOCATION_STATE_MANAGER: {
        actions: ['set_location_state_manager'],
      },
      SET_BARCODE_SCANNER_SERVICE: {
        actions: ['set_barcode_scanner_service'],
      },
      SET_DISC: {
        actions: ['set_discount'],
      },
      OPEN_MODAL: {
        actions: ['open_modal', 'assign_fn'],
      },
      CLOSE: {
        actions: ['close_modal', 'assign_fn'],
      },
      STARTSCANONOPEN: {
        actions: ['open_disc_code_modal', 'assignAddPromoItemToCart', 'scan'],
      },
      CLOSE_DISC_CODE: {
        actions: ['stop_scan', 'close_disc_code_modal'],
      },
      STARTSCAN: {
        actions: ['scan'],
      },
      STOPSCAN: {
        actions: ['stop_scan'],
      },
      APPLY: {
        target: ['apply_selected_discount'],
      },
      CHECK_DISC_ENGINE: {
        actions: ['check_discount_engine'],
      },
      CLEAR_DISCOUNT: {
        actions: ['clear_discount'],
      },
      CLEAR_PROMO_CODE: {
        actions: ['clear_promo_code'],
      },
      REMOVE_DISCOUNT: {
        actions: ['remove_discount'],
      },
      ERROR: {
        actions: ['throw_error'],
      },
      UPDATE_CART_DISCOUNT: {
        actions: ['update_cart_discount'],
      },
      FIND_DISC_CODE: {
        target: 'find_promo_code',
      },
    },
    states: {
      idle: {
        on: {},
      },
      find_promo_code: {
        tags: ['find_promo_code'],
        invoke: {
          src: 'find_promo_code',
          onDone: {
            target: 'wait',
          },
        },
      },
      find_discount_code: {
        tags: ['find_discount_code'],
        invoke: {
          src: 'find_discount_code',
          onDone: {
            target: 'waitDisc',
          },
          onError: {
            target: 'redirectToLoyalty',
          },
        },
      },
      wait: {
        after: {
          200: {
            target: 'check_promo_item',
          },
        },
      },
      waitDisc: {
        after: {
          200: {
            target: 'apply_discount_code',
          },
        },
      },
      check_promo_item: {
        invoke: {
          src: 'find_promo_items',
          onDone: {
            target: 'open_promo_item',
            actions: ['open_promo_item'],
          },
          onError: {
            target: 'find_discount_code',
          },
        },
      },
      failure: {
        tags: ['no_promo_item', 'no_loyalty_item'],
        entry: ['set_error'],
        after: {
          500: {
            target: 'idle',
            actions: ['display_error', 'stop_scan', 'close_disc_code_modal'],
          },
        },
      },
      open_promo_item: {
        tags: ['open_promo_item'],
        after: {
          3000: {
            target: 'show_promo_item',
          },
        },
      },
      show_promo_item: {
        tags: ['show_promo_item'],
        entry: ['show_promo_item', 'stop_scan', 'close_disc_code_modal'],
      },
      apply_discount_code: {
        tags: ['apply_discount_code'],
        entry: ['apply_discount_code'],
      },
      redirectToLoyalty: {
        tags: ['redirect_to_loyalty'],
        invoke: {
          src: 'redirectToLoyalty',
          onDone: {
            actions: 'on_loyalty_success',
          },
          onError: {
            target: 'failure',
          },
        },
      },
      apply_selected_discount: {
        tags: ['apply_selected_discount'],
        entry: ['apply_discount', 'stop_scan', 'close_disc_code_modal'],
      },
    },
  },
  {
    services: {
      async find_promo_items(ctx, _) {
        if (ctx.promoItem) return;
        const filteredPromotionItem = ctx.promotionalItemList?.filter((x) => {
          let promotionalDetails: MenuItemPromotionalDetails | undefined = undefined;
          if (x.item.type === 'item') {
            promotionalDetails = x.item.item.promotionalDetails;
          } else if (x.item.type === 'combo') {
            promotionalDetails = x.item.combo.promotionalDetails ?? undefined;
          } else if (x.item.type === 'combo-family') {
            promotionalDetails = x.item.comboFamily.promotionalDetails ?? undefined;
          }

          return promotionalDetails?.triggerCodes.includes(ctx.promoCode ?? '');
        });
        if (filteredPromotionItem === undefined || filteredPromotionItem?.length === 0) {
          ctx.promoCode = '';
          throw new Error('');
        }
        return filteredPromotionItem;
      },

      async find_promo_code(ctx, evt) {
        ctx.isScannerInput = evt.data?.isScannerInput;
        ctx.inputCode = evt.data?.input_code ?? '';
        if (ctx.promotionalItemList) {
          if (ctx.promoCode != '') {
            throw new Error('One coupon code already applied');
          } else {
            ctx.promoCode = ctx.inputCode ?? '';
          }
        }
        return ctx;
      },

      async find_discount_code(ctx, _) {
        const sel_discount = ctx.discountList?.filter((x) => {
          return x.discountCode === ctx.inputCode;
        });
        if (sel_discount && sel_discount?.length > 0) {
          ctx.appliedDiscountCode = ctx.inputCode;
        } else {
          throw new Error('');
        }
        return ctx;
      },
      async redirectToLoyalty(ctx) {
        const code = ctx.inputCode;
        assertExists(loyalty_service, 'Loyalty service is missing');
        const context = loyalty_service.getSnapshot().context;
        if (context.client !== undefined && context.profile !== undefined && code.length === 6) {
          loyalty_service.send({
            type: 'LOOKUP_PROFILE',
            query: { qrCodeValue: code, isRewardCode: true },
          });
        } else {
          throw new Error('');
        }
        return ctx;
      },
    },
    actions: {
      set_error: assign({
        errorMessage: (_, evt) => {
          if ('data' in evt) {
            return evt.data as Error;
          }
        },
      }),
      set_location_state_manager: assign({
        locationStateManager: (ctx, evt) =>
          evt.type === 'SET_LOCATION_STATE_MANAGER' ? evt.data : ctx.locationStateManager,
      }),
      set_barcode_scanner_service: assign({
        barcodeScanner: (ctx, evt) =>
          evt.type === 'SET_BARCODE_SCANNER_SERVICE' ? evt.data : ctx.barcodeScanner,
      }),
      open_modal: () => {
        routing_service.send('OPEN_DISCOUNT_DETAIL_MODAL');
      },
      close_modal: () => {
        routing_service.send('CLOSE_DISCOUNT_DETAIL_MODAL');
      },
      open_disc_code_modal: () => {
        routing_service.send('OPEN_DISCOUNT_CODE_MODAL');
      },
      close_disc_code_modal: (ctx) => {
        ctx.appliedDiscountCode = '';
        routing_service.send('CLOSE_DISCOUNT_CODE_MODAL');
      },
      assign_fn: assign((ctx, evt) => {
        const discount = evt.data?.selectedDiscount;
        if (discount) {
          ctx.selectedDiscount = [discount];
        }
        ctx.priceFormatter = evt.data?.priceFormatter;
        ctx.textFormatter = evt.data?.textFormatter;
        return ctx;
      }),
      apply_discount: assign((ctx, evt) => {
        if (evt.data?.orderItemId != '') {
          const appliedDisc = ctx.appliedDiscounts?.filter((disc) => {
            return disc.cartItemId === evt.data?.orderItemId;
          });
          ctx.selectedDiscount = appliedDisc.length > 0 ? [appliedDisc[0]] : undefined;
        } else {
          if (ctx.appliedDiscountCode && ctx.selectedDiscount === undefined) {
            const disCodeList = ctx.appliedDiscounts?.filter((x) => {
              return x.discountCode === ctx.appliedDiscountCode;
            });
            if (!(disCodeList?.length > 0)) {
              const appDiscCodeList = ctx.discountList?.filter((x) => {
                return x.discountCode === ctx.appliedDiscountCode;
              });
              ctx.selectedDiscount = appDiscCodeList;
            }
          }
        }
        if (ctx.selectedDiscount) {
          const discountOrder = {
            order: evt.data.order?.order,
            orderDetails: evt.data.order?.orderDetails,
            appliedDiscounts: ctx.appliedDiscounts ?? [],
          };

          // Check in the engine about the selected discount is valid to apply or not
          const result = engine.apply(ctx.selectedDiscount!, discountOrder);
          if (!result.canApplyDiscount) showErrors(result.messages, ctx);
          else applyDiscount(ctx, result);
        }
        return ctx;
      }),

      apply_discount_code: async () => {
        assertExists(order_service, 'Order service is missing');
        order_service.send({ type: 'APPLY_DISC', data: { orderItemId: '' } });
      },

      set_discount: async (ctx, _) => {
        assertExists(ctx.locationStateManager, 'Discount storage service is missing');
        ctx.appliedDiscounts = [];
        const discountStore = get(ctx.locationStateManager.discountStateManager.store);
        ctx.promotionalItemList = discountStore.promotionalItemList;
        ctx.discountList = discountStore.discountList;
        ctx.discountList = Array.from(ctx.discountList ?? []).map((disc) => {
          if (
            disc?.condition.kind === 'and' &&
            disc?.condition?.and?.filter((x) => x.kind === 'minimumSubtotal')?.length === 0
          ) {
            disc.condition.and = [
              ...disc.condition.and,
              {
                kind: 'minimumSubtotal',
                minimumSubtotal: 0,
              } as MinimumSubtotalCondition,
            ];
          }
          return disc;
        });
      },

      check_discount_engine: async (ctx, evt) => {
        const result = engine.run(ctx.discountList!, evt.data.order!);
        ctx.discountList = result.availableDiscounts;
      },

      clear_discount: assign((ctx, _) => {
        ctx.discountAmount = 0;
        ctx.selectedDiscount = undefined;
        ctx.appliedDiscounts = [];
        return ctx;
      }),

      clear_promo_code: assign((ctx, _) => {
        ctx.promoCode = '';
        ctx.promoItem = undefined;
        return ctx;
      }),

      remove_discount: assign((ctx, evt) => {
        if (ctx.appliedDiscounts && ctx.appliedDiscounts.length > 0) {
          ctx.selectedDiscount = undefined;
          const appliedDisc = ctx.appliedDiscounts?.filter(
            (disc) =>
              disc.cartItemId === evt.data?.orderItemId ||
              disc.qualifyingIDs?.find((x) => x === evt.data?.menuItemId)
          );

          if (evt.data?.orderItemId != '' && appliedDisc.length > 0) {
            const itemQnt = evt.data?.itemQuantity ?? 0;
            if (appliedDisc[0].redeemableLimit > itemQnt || appliedDisc.length >= itemQnt) {
              ctx.discountAmount = (ctx.discountAmount ?? 0) - (appliedDisc[0].discountAmount ?? 0);
              ctx.appliedDiscounts.splice(ctx.appliedDiscounts.indexOf(appliedDisc[0]), 1);
            }
          } else {
            if (evt.data.discount) {
              ctx.discountAmount =
                (ctx.discountAmount ?? 0) - (evt.data.discount.discountAmount ?? 0);
              ctx.appliedDiscounts.splice(ctx.appliedDiscounts.indexOf(evt.data.discount), 1);
            } else if (evt.data.subtotal) {
              const noReqDisc = ctx.appliedDiscounts?.filter(
                (disc) =>
                  disc.cartItemId !== evt.data?.orderItemId ||
                  disc.qualifyingIDs?.find((x) => x !== evt.data?.menuItemId)
              );

              if (
                evt.data.subtotal - (noReqDisc[0]?.discountAmount ?? 0) < 0 ||
                evt.data.subtotal - (getOverridePrice(noReqDisc[0]) ?? 0) < 0
              ) {
                ctx.discountAmount =
                  (ctx.discountAmount ?? 0) - (noReqDisc[0]?.discountAmount ?? 0);
                ctx.appliedDiscounts.splice(ctx.appliedDiscounts.indexOf(noReqDisc[0]), 1);
              }
            }
          }
        }
        return ctx;
      }),

      throw_error: (_, evt: AnyEventObject) =>
        error_service.send({
          type: 'SHOW_GENERIC_ERROR',
          data: { error_message: evt.data.message },
        }),

      update_cart_discount: assign((ctx, evt) => {
        showErrorsIfDiscountsInvalid(evt.data.order, ctx);
        return ctx;
      }),

      scan: (ctx, evt) => {
        if (evt.type === 'STARTSCANONOPEN' && evt.data.isScanDiscount) {
          ctx.barcodeScanner?.send({
            type: 'START_SCAN',
            data: {
              timeout: evt.data.timeout,
              callback: evt.data.barcodeFn,
            },
          });
        }
      },

      stop_scan: (ctx) => {
        ctx.barcodeScanner?.send({ type: 'STOP_SCAN', data: undefined });
      },

      open_promo_item: (ctx, evt) => {
        ctx.promoItem = evt.data ? evt.data[0] : [];
      },

      show_promo_item: (ctx, _) => {
        if (ctx.addPromoItemToCart && ctx.promoItem) {
          ctx.addPromoItemToCart(ctx.promoItem);
        }
      },

      on_loyalty_success: () => {},

      display_error: (ctx) => {
        error_service.send({
          type: 'SHOW_GENERIC_ERROR',
          data: {
            error_message:
              ctx.errorMessage?.message && ctx.errorMessage?.message != ''
                ? ctx.errorMessage?.message
                : 'Coupon code not found. Please check the code and try again',
          },
        });
      },

      assignAddPromoItemToCart: assign({
        addPromoItemToCart: (ctx, event) => {
          if (event.type === 'STARTSCANONOPEN') {
            return event.data.addPromoItemToCart;
          }
          return ctx.addPromoItemToCart;
        },
      }),
    },
  }
);

function applyDiscount(ctx, result: ApplicationResult) {
  const applicableDiscount = result.orderDetails && result.orderDetails?.discountList;
  if (applicableDiscount) {
    applicableDiscount?.map((disc) => {
      const selDiscount = ctx.selectedDiscount.filter((x) => x.id === disc.discountId)?.[0];
      const subtotal = result.orderDetails?.subtotal ?? 0;
      const isDiscountApplicable = subtotal - disc.discountAmount >= 0;
      if (isDiscountApplicable && disc.discountAmount > 0) {
        ctx.discountAmount += disc.discountAmount;

        const newAppDiscount = { ...selDiscount } as KioskDiscount;
        const updateOfferCondition = updateCanOfferItems(
          newAppDiscount?.condition,
          disc.menuItemId
        );
        newAppDiscount.condition = updateOfferCondition;
        newAppDiscount.discountAmount = disc.discountAmount;
        newAppDiscount.cartItemId = disc.cartItemId as string;
        newAppDiscount.qualifyingIDs = disc.qualifyingIDs;
        ctx.appliedDiscounts.push(newAppDiscount);
      }
    });
    ctx.selectedDiscount = undefined;
  } else {
    error_service.send({
      type: 'SHOW_GENERIC_ERROR',
      data: { error_message: getErrorMessage([-1]) },
    });
  }
  routing_service.send('CLOSE_DISCOUNT_DETAIL_MODAL');
}

function updateCanOfferItems(condition, discMenuItemId) {
  const orCondition = condition.and.filter((cond) => cond.kind === 'or');

  if (orCondition) {
    const canOffer: any = [];
    orCondition.filter((item) => {
      if (item.kind === 'or' && item.or.some((subItem) => subItem.kind === 'canOfferItem')) {
        item.or.forEach((offerItem) => {
          if (offerItem.kind === 'canOfferItem') {
            if (discMenuItemId === offerItem.itemId) {
              canOffer.push(offerItem);
            }
          }
        });
        item.or = canOffer;
      }
    });
  }
  return condition;
}

function showErrors(messages: ValidationMessages[], ctx: DiscountContext) {
  ctx.appliedDiscountCode = '';
  ctx.inputCode = '';
  ctx.barcodeScanner?.send({ type: 'STOP_SCAN', data: undefined });
  routing_service.send('CLOSE_DISCOUNT_DETAIL_MODAL');

  const errorMessage = getErrorMessage(
    messages,
    ctx.selectedDiscount?.[0].minimumSubtotal,
    ctx.priceFormatter,
    ctx.selectedDiscount?.[0].name,
    ctx.textFormatter
  );
  console.log('DISC MESSAGE', errorMessage);

  error_service.send({
    type: 'SHOW_GENERIC_ERROR',
    data: {
      error_message: errorMessage,
    },
  });
  ctx.selectedDiscount = undefined;
}

function getErrorMessage(
  // TODO: we're using special values in this file (-1 and -2), we should replace them with real error codes
  messages: (ValidationMessages | -1 | -2)[],
  minimumSubtotal?: number,
  priceFormatter?: any,
  discountName?: string,
  textFormatter?: any
) {
  let errorMsg = '';
  messages.map((msg) => {
    switch (msg) {
      case ValidationMessages.DiscountLimitExceeded:
        errorMsg = textFormatter('discount.discount_error_messages.discount_limit_per_item');
        break;
      case ValidationMessages.DiscountLimitPerOrderExceeded:
        errorMsg = textFormatter('discount.discount_error_messages.discount_limit_per_order');
        break;
      case ValidationMessages.NotEnabled:
        errorMsg = textFormatter('discount.discount_error_messages.discount_not_enabled');
        break;
      case ValidationMessages.NotInCategory:
        errorMsg = textFormatter('discount.discount_error_messages.discount_not_in_category');
        break;
      case ValidationMessages.QualifyingItemNotIn:
        errorMsg = textFormatter('discount.discount_error_messages.discount_not_qualifying_item');
        break;
      case ValidationMessages.NotInCart:
        errorMsg = textFormatter('discount.discount_error_messages.discount_not_in_cart');
        break;
      case ValidationMessages.NotInTime:
        errorMsg = textFormatter('discount.discount_error_messages.discount_not_in_time');
        break;
      case ValidationMessages.SubtotalNotMet:
        errorMsg =
          priceFormatter !== undefined && minimumSubtotal && minimumSubtotal > 0
            ? textFormatter('discount.discount_error_messages.discount_subtotal_met_with_amount', {
                values: { name: discountName, amount: `${priceFormatter(minimumSubtotal)}` },
              })
            : textFormatter('discount.discount_error_messages.discount_subtotal_met', {
                values: { name: discountName },
              });
        break;
      case ValidationMessages.Unknown:
      default:
        errorMsg = textFormatter('discount.discount_error_messages.discount_unknown_error');
    }
  });
  return errorMsg;
}

export const discount_service = interpret(discount_machine).start();
(window as any).loyalty_service = discount_service;

// TODO: Move this to where we want to clear out the database cache
export const resetDiscountStoreServiceToApplyDiscount = (
  locationStateManager: PartialILocationStateManager,
  barcodeScanner: IBarcodeScannerService
) => {
  discount_service.send({ type: 'SET_BARCODE_SCANNER_SERVICE', data: barcodeScanner });
  discount_service.send({ type: 'SET_LOCATION_STATE_MANAGER', data: locationStateManager });
};
