import type { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';
import { assign, createMachine, type ActorRefFrom } from 'xstate';
import { sendParent } from 'xstate/lib/actions';
import { KioskPaymentServiceClient } from '../../../../generated/kioskPaymentService_pb.client';
import type {
  BalanceRequest,
  OrderPaymentRequest,
  OrderRefundRequest,
} from '../../../../generated/kioskPay_pb';
import type { CloudPaymentDevice, GiftCardPaymentDevice } from '../../../../generated/kiosk_pb';
import { error_service } from '../../kiosk/error_machine';
import { payment_request_total } from '../payment_request_total';
import type { PaymentMachineEvent } from '../types';

export type PaytronixGiftContext = {
  client?: KioskPaymentServiceClient;
  balance?: number;
  cardNumber?: string;
  regCode?: string;
  transport: GrpcWebFetchTransport;
  config: CloudPaymentDevice | GiftCardPaymentDevice;
};

export type PaymentWithBalanceMachineEvent = PaymentMachineEvent;

export type PaytronixSharedValueEvent =
  | {
      type: 'SET_PAYMENT_INFO';
      data: { cardNumber: string; regCode: string };
    }
  | PaymentWithBalanceMachineEvent;

export const paytronix_gift_machine = createMachine(
  {
    id: 'paytronix_gift_machine',
    tsTypes: {} as import('./paytronix_gift_machine.typegen').Typegen0,
    schema: {
      context: {} as PaytronixGiftContext,
      events: {} as PaytronixSharedValueEvent,
      services: {} as {
        get_balance: {
          data: { card_number: string; regCode: string; balance: number | undefined } | undefined;
        };
        pay: {
          data: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            response: any;
            info: {
              time: Date;
              transactionId: string;
              amount: number;
              type: string;
            };
          };
        };
      },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
    initial: 'idle',
    entry: 'assignPaymentClient',
    states: {
      idle: {
        tags: 'can_get_balance',
        on: {
          SET_PAYMENT_INFO: {
            actions: 'set_payment_info',
          },
          GET_BALANCE: {
            target: 'getting_balance',
          },
          PAY: {
            target: 'paying',
          },
          REFUND: {
            target: 'refunding',
          },
        },
      },
      getting_balance: {
        tags: 'getting_balance',
        invoke: {
          src: 'get_balance',
          onDone: {
            target: 'idle',
            actions: ['set_balance', 'send_balance'],
          },
          onError: {
            target: 'idle',
            actions: ['set_error', 'display_error'],
          },
        },
      },
      paying: {
        invoke: {
          src: 'pay',
          onDone: {
            target: 'idle',
            actions: 'pay',
          },
          onError: {
            target: 'idle',
            actions: ['pay_error'],
          },
        },
      },
      refunding: {
        invoke: {
          src: 'refund',
          onDone: {
            target: 'idle',
            actions: 'refunded',
          },
          onError: {
            target: 'idle',
            actions: ['pay_error'],
          },
        },
      },
    },
  },
  {
    actions: {
      set_payment_info: assign({
        cardNumber: (_, evt) => evt.data.cardNumber,
        regCode: (_, evt) => evt.data.regCode,
      }),
      set_balance: assign({
        balance: (_, evt) => evt.data?.balance,
        cardNumber: (_, evt) => evt.data?.card_number,
        regCode: (_, evt) => evt.data?.regCode,
      }),
      pay: sendParent((ctx, evt) => ({ type: 'PAID', data: evt.data })),
      pay_error: sendParent({ type: 'ERROR' }),
      send_balance: sendParent((ctx, evt) => ({
        type: 'GOT_BALANCE',
        data: evt.data?.balance != undefined ? evt.data.balance.toString() : undefined,
      })),
      refunded: sendParent((ctx, evt) => ({ type: 'REFUNDED', data: evt.data })),
      set_error: sendParent({ type: 'ERROR' }),
      display_error: () => {
        error_service.send({
          type: 'SHOW_GENERIC_ERROR',
          data: { error_message: 'Invalid Card Number | Registration code' },
        });
      },
      assignPaymentClient: assign((ctx) => {
        ctx.client = new KioskPaymentServiceClient(ctx.transport);
        return ctx;
      }),
    },
    services: {
      get_balance: async (ctx, evt) => {
        if (!ctx.client) throw new Error('No client');
        if (!evt.data.query || !('regCode' in evt.data.query)) throw new Error();

        const request: BalanceRequest = {
          paymentIntegrationId: 'payment-integration-paytronix-giftcard',
          accountId: '',
          values: {
            oneofKind: 'paytronixGiftCard',
            paytronixGiftCard: {
              cardNumber: evt.data.query.cardNumber,
              regCode: evt.data.query.regCode,
            },
          },
        };

        const result = await ctx.client.getBalance(request);
        if (!result.response.name) throw new Error();
        return {
          card_number: evt.data.query.cardNumber,
          regCode: evt.data.query.regCode,
          balance: result.response.credit,
        };
      },
      pay: async (ctx, evt) => {
        if (!ctx.client) throw new Error('No client');
        // if (!evt.data.transactData || !evt.data.requestData) throw new Error("Missing request data or transact data");
        const amountDue = payment_request_total(evt.data.requestData);
        const paymentAmount = Math.min(ctx.balance ?? 0, amountDue);

        const request: OrderPaymentRequest = {
          paymentIntegrationId: 'payment-integration-paytronix-giftcard',
          paymentAmount: paymentAmount,
          accountId: '',
          values: {
            oneofKind: 'paytronixGiftCard',
            paytronixGiftCard: {
              cardNumber: ctx.cardNumber ?? '',
              regCode: ctx.regCode ?? '',
            },
          },
        };

        const result = await ctx.client.pay(request);

        //  sendParent({
        //   type: 'PAID',
        //   data: {
        //     response: result.response,
        //     info: {
        //       time: new Date(),
        //       transactionId: result.response.transactionId,
        //       amount: result.response.totalPaid,
        //     },
        //   },
        // });
        return {
          response: result.response,
          info: {
            time: new Date(),
            transactionId: result.response.transactionId,
            amount: result.response.totalPaid,
            type: 'Gift Card',
          },
        };
      },
      refund: async (ctx, evt) => {
        if (!ctx.client) throw new Error('No client');
        if (typeof evt.data !== 'string') throw new Error('Bad transaction Id!');
        const request: OrderRefundRequest = {
          paymentIntegrationId: 'payment-integration-paytronix-giftcard',
          transactionId: evt.data,
        };

        const result = await ctx.client.refund(request);

        return {
          response: result.response,
          info: {
            time: new Date(),
            transactionId: result.response.transactionId,
            amount: result.response.totalPaid,
            type: 'Gift Card',
          },
        };
      },
    },
  }
);

export type PaytronixService = ActorRefFrom<typeof paytronix_gift_machine>;
