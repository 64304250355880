import { derived, type Updater } from 'svelte/store';
import type { KioskModifier, KioskModifierCode } from '../../models';
import { load_modifier_groups } from './load_modifier_groups.js';
import type {
  ModifierState,
  ModifierStateManager,
  ModifierService,
  ModifierServiceDerivedState,
} from './types';

const MIN_QUANTITY = 0;

export function modifier_service({
  modifierStateManager,
  modifier,
}: {
  modifierStateManager: ModifierStateManager;
  /**
   * undefined indicates it is loaded from a past order
   */
  modifier: KioskModifier;
}): ModifierService {
  const item = modifier.menuItem;

  const { incrementStep, maxQuantity } = modifier;

  function set_quantity(update_function: (current_state: ModifierState) => number) {
    modifierStateManager.updateModStateForId(modifier.modifierId, (item_state) => {
      const increment_qty = Math.max(MIN_QUANTITY, update_function(item_state) * incrementStep);
      const new_quantity = maxQuantity > 0 ? Math.min(increment_qty, maxQuantity) : increment_qty;

      item_state.quantity = new_quantity;

      return item_state;
    });
  }

  function update_index(index: number, ctx: ModifierState) {
    const keys = Array.from(ctx.mod_groups.keys());
    if (index >= keys.length) {
      index = 0;
    }
    if (index < 0) {
      index = keys.length - 1;
    }
    ctx.selected_index = index;
    ctx.selected_group_id = keys[index];
    return ctx;
  }

  const update = (s: Updater<ModifierState>) => {
    modifierStateManager.updateModStateForId(modifier.modifierId, s);
  };

  const modifierGroupServices = load_modifier_groups(
    modifierStateManager.getChildModifierGroupStateManager(modifier.modifierId),
    item
  );

  const modState = derived(
    modifierStateManager.getDerivedModStateForId(modifier.modifierId),
    (s): ModifierServiceDerivedState => {
      if (!s) throw new Error('Missing mod state');

      return s.state;
    }
  );

  return {
    subscribe: modState.subscribe,

    modifier,

    item: modifier.menuItem,

    modifierGroupServices,

    /**
     * selects the next mod group for display
     * defaults to first mod group if none selected or currently on last item
     */
    select_next_mod_group: () => {
      update((s) => {
        if (!s.mod_groups.size) {
          return s;
        }
        return update_index(s.selected_index + 1, s);
      });
    },

    select_modifier_code: (modifierCode: KioskModifierCode) => {
      modifierStateManager.updateModStateForId(modifier.modifierId, (item_state) => {
        item_state.selectedModifierCode = modifierCode;
        return item_state;
      });
    },

    /**
     * selects mod group for display, deselects it if it matches the current displayed group
     * @param group_id modifier_group_id
     */
    select_mod_group: (group_id: string) => {
      update((s) => {
        const keys = Array.from(s.mod_groups.keys());
        const index = keys.findIndex((x) => x === group_id);
        return update_index(index, s);
      });
    },

    /**
     * sets quantity to a new value clamped by min and max
     * @param quantity new value for quantity
     * @returns
     */
    set_quantity: (quantity: number) => set_quantity(() => quantity),

    /**
     * increments quantity by one
     *
     * only call when **display.can_increase** is true
     * @returns void
     */
    increase_quantity: () => set_quantity((state) => state.quantity + 1),

    /**
     * decrements quantity by one
     *
     * only call when **display.can_decrease** is true
     * @returns void
     */
    decrease_quantity: () => set_quantity((state) => state.quantity - 1),

    toggle: () => set_quantity((state) => (state.quantity === 0 ? 1 : 0)),

    getInvalidRequiredModGroups: () => [],
  };
}
