import { createMachine, sendParent } from 'xstate';
import { payment_request_total } from '../payment_request_total';
import type { PaymentMachineEvent, PaymentResponse } from '../types';

export const cash_machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGMCGsAWB9Atq5GAlgHZgB0hEANmAMQAKAggJoDaADALqKgAOA9rEIAXQv2I8QAD0QBWdmQAsAZgDsigJwAOAIwbZyjTp0AaEAE9EO1arLL2D4wCZlOp241OAvl7NpMuPhEpBTUdABKAKIAYgCqAHIAIhzcSCACQqLikjIIik5mlgi6ZABsqvLsyobW7E6lGj6+IMT8EHCS-th4BCRgkhkiYhJpuQC0pYWIY7JkGvMLi4vKPn7o3UF9oTQDgkPZo3Jac1VuWrJOqqVudZMWiJe2nvJ67KpvLt5NQA */
  createMachine<unknown, PaymentMachineEvent>(
    {
      id: 'cash_machine',
      predictableActionArguments: true,
      preserveActionOrder: true,
      initial: 'idle',
      states: {
        idle: {
          on: {
            PAY: {
              actions: 'no_op_pay',
            },
            REFUND: {
              actions: 'no_op_refund',
            },
          },
        },
      },
    },
    {
      actions: {
        no_op_pay: sendParent((_, evt) => {
          const data: PaymentResponse = {
            response: {},
            info: {
              time: new Date(),
              transactionId: '',
              invoiceId: '',
              amount: evt.type === 'PAY' ? payment_request_total(evt.data.requestData) : 0,
              tipAmount: evt.type === 'PAY' ? evt.data.requestData.tip : 0,
              type: 'CASH',
            },
          };
          return {
            type: 'PAID',
            data,
          };
        }),
        no_op_refund: sendParent({ type: 'REFUNDED' }),
      },
    }
  );
