import Dexie, { type DexieOptions, type Table } from 'dexie';

export type Kiosk_Settings = {
  id: string;
  theme: string;
  location_ids: string[];
};

export type Device_Storage = {
  id: string;
} & Record<string | number | symbol, unknown>;

export type EventLogMessage = {
  id?: number;
  sessionId: string | undefined;
  eventId: string;

  category: string;
  type: string;
  severity: LogLevel;
  data: string;
  summary: string;
  instant: string;
};

export enum LogLevel {
  Trace = 0,
  Debug = 1,
  Information = 2,
  Warning = 3,
  Error = 4,
  Critical = 5,
}

export class KioskDb extends Dexie {
  device_storage!: Table<Device_Storage>;
  settings!: Table<Kiosk_Settings>;
  eventLog!: Table<EventLogMessage>;

  constructor(indexedDB?: DexieOptions['indexedDB'] | undefined) {
    super('kiosk_store', indexedDB ? { indexedDB: indexedDB } : undefined);
    this.version(1).stores({
      device_storage: '&id',
      settings: '++id, *location_ids',
      eventLog: '++id',
    });
  }
}

export const KioskStore = new KioskDb();
