import { CardNetworkType as AgentCardNetworkType, type OpenOrder } from '@grubbrr/agent-api';
import type { ItemDiscount } from '../data-management/types';
import type {
  CloseOrderRequest,
  ItemModifier,
  MenuOrder,
  OrderItem,
  OrderPayment,
} from '../../generated/kioskOrder_pb';
import { CardInfo_CardNetworkType } from '../../generated/kioskOrder_pb';
import { CardNetwork } from '../states/payment_processors/types';
import { CalculationType, type Tip } from '../../generated/kiosk_pb';
import type { Int32Value, StringValue } from '../../generated/google/protobuf/wrappers_pb';

export type KioskMenuOrder = Omit<MenuOrder, 'items'> & {
  items: KioskOrderItem[];
};

export type KioskPastOrder = OpenOrder;

export type OrderStateManagerDerivedApiParams = KioskMenuOrder & { subtotal: number };

export type KioskCardInfo = {
  cardType: CardInfo_CardNetworkType;
  lastFour: string;
  zipCode: string | null;
  processorAuthCode: string | null;
  expirationMonth: string | null;
  expirationYear: string | null;
  maskedCardNumber: string | null;
  availableBalance: number | null;
};

export const getCardType = (network: CardNetwork | undefined): CardInfo_CardNetworkType => {
  switch (network) {
    case CardNetwork.AMEX:
      return CardInfo_CardNetworkType.AMERICAN_EXPRESS;
    case CardNetwork.Discover:
      return CardInfo_CardNetworkType.DISCOVER;
    case CardNetwork.MasterCard:
      return CardInfo_CardNetworkType.MASTERCARD;
    case CardNetwork.Visa:
      return CardInfo_CardNetworkType.VISA;
    default:
      return CardInfo_CardNetworkType.UNKNOWN;
  }
};

export const getCardTypeForAgent = (
  network: CardInfo_CardNetworkType | undefined
): AgentCardNetworkType => {
  switch (network) {
    case CardInfo_CardNetworkType.AMERICAN_EXPRESS:
      return AgentCardNetworkType.AmericanExpress;
    case CardInfo_CardNetworkType.DISCOVER:
      return AgentCardNetworkType.Discover;
    case CardInfo_CardNetworkType.MASTERCARD:
      return AgentCardNetworkType.Mastercard;
    case CardInfo_CardNetworkType.VISA:
      return AgentCardNetworkType.Visa;
    default:
      return AgentCardNetworkType.Unknown;
  }
};

export type KioskOrderPayment = Omit<OrderPayment, 'tenderInfo'> & {
  tenderInfo?: {
    cardInfo: KioskCardInfo;
  };
  paymentTransactionTime: string;
  preTipTotal: number;
};

export const mapOptionalStringForProto = (
  value: string | null | undefined
): StringValue | undefined => {
  return value ? { value } : undefined;
};

export const mapOptionalNumberForProto = (
  value: number | null | undefined
): Int32Value | undefined => {
  return value ? { value } : undefined;
};

export const convertPayment = (payment: KioskOrderPayment): OrderPayment => {
  return {
    ...payment,
    tenderInfo: payment.tenderInfo
      ? {
          kind: {
            oneofKind: 'cardInfo',
            cardInfo: {
              cardType: payment.tenderInfo.cardInfo.cardType,
              lastFour: payment.tenderInfo.cardInfo.lastFour,
              zipCode: mapOptionalStringForProto(payment.tenderInfo.cardInfo.zipCode),
              processorAuthCode: mapOptionalStringForProto(
                payment.tenderInfo.cardInfo.processorAuthCode
              ),
              expirationMonth: mapOptionalStringForProto(
                payment.tenderInfo.cardInfo.expirationMonth
              ),
              expirationYear: mapOptionalStringForProto(payment.tenderInfo.cardInfo.expirationYear),
              maskedCardNumber: mapOptionalStringForProto(
                payment.tenderInfo.cardInfo.maskedCardNumber
              ),
              availableBalance: mapOptionalNumberForProto(
                payment.tenderInfo.cardInfo.availableBalance
              ),
            },
          },
        }
      : undefined,
  };
};

export type KioskCloseOrderRequest = Omit<CloseOrderRequest, 'payments' | 'details'> & {
  isPayAtCounter?: boolean;
  payments: KioskOrderPayment[];
  paymentIntegrationId?: string;
  invoiceId: string;
};

export type KioskPosCloseOrderRequest = Omit<CloseOrderRequest, 'payments'> & {
  isPayAtCounter?: boolean;
  payments: KioskOrderPayment[];
  paymentIntegrationId?: string;
  invoiceId: string;
};

export type KioskOrderItem = Omit<OrderItem, 'modifiers'> & {
  finalItemPrice?: number; // This is final price of the item i.e. with x of Quantity as well as modifier price. As this required in the Discount Engine to calculate some discount prices
  discounts?: ItemDiscount[];
  cartItemId?: string;
  posId: string;
  posData: string;
  modifiers: KioskItemModifier[];
};

export type KioskItemModifier = Omit<ItemModifier, 'modifiers'> & {
  posData: string;
  posId: string;
  posOptionGroupId: string | null;
  modifiers: KioskItemModifier[];
};

export type KioskTip = Tip;

export const getAmountForTip = (subtotal: number, tip: Tip) => {
  if (tip.type === CalculationType.addFlat) {
    return tip.rate;
  }
  return Math.floor(subtotal * (tip.rate / 100));
};
