/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "kioskPay.proto" (package "Kiosk", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message Kiosk.OrderPaymentRequest
 */
export interface OrderPaymentRequest {
    /**
     * @generated from protobuf field: string paymentIntegrationId = 1;
     */
    paymentIntegrationId: string;
    /**
     * @deprecated
     * @generated from protobuf field: string accountId = 2 [deprecated = true];
     */
    accountId: string;
    /**
     * @generated from protobuf field: int32 paymentAmount = 3;
     */
    paymentAmount: number;
    /**
     * @generated from protobuf oneof: values
     */
    values: {
        oneofKind: "idPay";
        /**
         * @generated from protobuf field: Kiosk.IdPayPayment idPay = 4;
         */
        idPay: IdPayPayment;
    } | {
        oneofKind: "paytronixGiftCard";
        /**
         * @generated from protobuf field: Kiosk.PaytronixGiftCard paytronixGiftCard = 5;
         */
        paytronixGiftCard: PaytronixGiftCard;
    } | {
        oneofKind: "paytronixStoredValue";
        /**
         * @generated from protobuf field: Kiosk.PaytronixStoredValue paytronixStoredValue = 6;
         */
        paytronixStoredValue: PaytronixStoredValue;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message Kiosk.IdPayPayment
 */
export interface IdPayPayment {
    /**
     * @generated from protobuf field: string accountId = 1;
     */
    accountId: string;
}
/**
 * @generated from protobuf message Kiosk.PaytronixGiftCard
 */
export interface PaytronixGiftCard {
    /**
     * @generated from protobuf field: string cardNumber = 1;
     */
    cardNumber: string;
    /**
     * @generated from protobuf field: string regCode = 2;
     */
    regCode: string;
}
/**
 * @generated from protobuf message Kiosk.PaytronixStoredValue
 */
export interface PaytronixStoredValue {
    /**
     * @generated from protobuf field: string cardNumber = 1;
     */
    cardNumber: string;
}
/**
 * @generated from protobuf message Kiosk.BalanceRequest
 */
export interface BalanceRequest {
    /**
     * @generated from protobuf field: string paymentIntegrationId = 1;
     */
    paymentIntegrationId: string;
    /**
     * @deprecated
     * @generated from protobuf field: string accountId = 2 [deprecated = true];
     */
    accountId: string;
    /**
     * @generated from protobuf oneof: values
     */
    values: {
        oneofKind: "idPay";
        /**
         * @generated from protobuf field: Kiosk.IdPayPayment idPay = 4;
         */
        idPay: IdPayPayment;
    } | {
        oneofKind: "paytronixGiftCard";
        /**
         * @generated from protobuf field: Kiosk.PaytronixGiftCard paytronixGiftCard = 5;
         */
        paytronixGiftCard: PaytronixGiftCard;
    } | {
        oneofKind: "paytronixStoredValue";
        /**
         * @generated from protobuf field: Kiosk.PaytronixStoredValue paytronixStoredValue = 6;
         */
        paytronixStoredValue: PaytronixStoredValue;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message Kiosk.Balance
 */
export interface Balance {
    /**
     * @deprecated
     * @generated from protobuf field: string accountId = 1 [deprecated = true];
     */
    accountId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 credit = 3;
     */
    credit: number;
}
/**
 * @generated from protobuf message Kiosk.OrderRefundRequest
 */
export interface OrderRefundRequest {
    /**
     * @generated from protobuf field: string paymentIntegrationId = 1;
     */
    paymentIntegrationId: string;
    /**
     * @generated from protobuf field: string transactionId = 2;
     */
    transactionId: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class OrderPaymentRequest$Type extends MessageType<OrderPaymentRequest> {
    constructor() {
        super("Kiosk.OrderPaymentRequest", [
            { no: 1, name: "paymentIntegrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "paymentAmount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "idPay", kind: "message", oneof: "values", T: () => IdPayPayment },
            { no: 5, name: "paytronixGiftCard", kind: "message", oneof: "values", T: () => PaytronixGiftCard },
            { no: 6, name: "paytronixStoredValue", kind: "message", oneof: "values", T: () => PaytronixStoredValue }
        ]);
    }
    create(value?: PartialMessage<OrderPaymentRequest>): OrderPaymentRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.paymentIntegrationId = "";
        message.accountId = "";
        message.paymentAmount = 0;
        message.values = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<OrderPaymentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderPaymentRequest): OrderPaymentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string paymentIntegrationId */ 1:
                    message.paymentIntegrationId = reader.string();
                    break;
                case /* string accountId = 2 [deprecated = true];*/ 2:
                    message.accountId = reader.string();
                    break;
                case /* int32 paymentAmount */ 3:
                    message.paymentAmount = reader.int32();
                    break;
                case /* Kiosk.IdPayPayment idPay */ 4:
                    message.values = {
                        oneofKind: "idPay",
                        idPay: IdPayPayment.internalBinaryRead(reader, reader.uint32(), options, (message.values as any).idPay)
                    };
                    break;
                case /* Kiosk.PaytronixGiftCard paytronixGiftCard */ 5:
                    message.values = {
                        oneofKind: "paytronixGiftCard",
                        paytronixGiftCard: PaytronixGiftCard.internalBinaryRead(reader, reader.uint32(), options, (message.values as any).paytronixGiftCard)
                    };
                    break;
                case /* Kiosk.PaytronixStoredValue paytronixStoredValue */ 6:
                    message.values = {
                        oneofKind: "paytronixStoredValue",
                        paytronixStoredValue: PaytronixStoredValue.internalBinaryRead(reader, reader.uint32(), options, (message.values as any).paytronixStoredValue)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderPaymentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string paymentIntegrationId = 1; */
        if (message.paymentIntegrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.paymentIntegrationId);
        /* string accountId = 2 [deprecated = true]; */
        if (message.accountId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountId);
        /* int32 paymentAmount = 3; */
        if (message.paymentAmount !== 0)
            writer.tag(3, WireType.Varint).int32(message.paymentAmount);
        /* Kiosk.IdPayPayment idPay = 4; */
        if (message.values.oneofKind === "idPay")
            IdPayPayment.internalBinaryWrite(message.values.idPay, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.PaytronixGiftCard paytronixGiftCard = 5; */
        if (message.values.oneofKind === "paytronixGiftCard")
            PaytronixGiftCard.internalBinaryWrite(message.values.paytronixGiftCard, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.PaytronixStoredValue paytronixStoredValue = 6; */
        if (message.values.oneofKind === "paytronixStoredValue")
            PaytronixStoredValue.internalBinaryWrite(message.values.paytronixStoredValue, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderPaymentRequest
 */
export const OrderPaymentRequest = new OrderPaymentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IdPayPayment$Type extends MessageType<IdPayPayment> {
    constructor() {
        super("Kiosk.IdPayPayment", [
            { no: 1, name: "accountId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IdPayPayment>): IdPayPayment {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountId = "";
        if (value !== undefined)
            reflectionMergePartial<IdPayPayment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IdPayPayment): IdPayPayment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountId */ 1:
                    message.accountId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IdPayPayment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountId = 1; */
        if (message.accountId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.IdPayPayment
 */
export const IdPayPayment = new IdPayPayment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaytronixGiftCard$Type extends MessageType<PaytronixGiftCard> {
    constructor() {
        super("Kiosk.PaytronixGiftCard", [
            { no: 1, name: "cardNumber", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "regCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PaytronixGiftCard>): PaytronixGiftCard {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.cardNumber = "";
        message.regCode = "";
        if (value !== undefined)
            reflectionMergePartial<PaytronixGiftCard>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaytronixGiftCard): PaytronixGiftCard {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cardNumber */ 1:
                    message.cardNumber = reader.string();
                    break;
                case /* string regCode */ 2:
                    message.regCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaytronixGiftCard, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cardNumber = 1; */
        if (message.cardNumber !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.cardNumber);
        /* string regCode = 2; */
        if (message.regCode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.regCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.PaytronixGiftCard
 */
export const PaytronixGiftCard = new PaytronixGiftCard$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaytronixStoredValue$Type extends MessageType<PaytronixStoredValue> {
    constructor() {
        super("Kiosk.PaytronixStoredValue", [
            { no: 1, name: "cardNumber", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PaytronixStoredValue>): PaytronixStoredValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.cardNumber = "";
        if (value !== undefined)
            reflectionMergePartial<PaytronixStoredValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaytronixStoredValue): PaytronixStoredValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cardNumber */ 1:
                    message.cardNumber = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaytronixStoredValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cardNumber = 1; */
        if (message.cardNumber !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.cardNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.PaytronixStoredValue
 */
export const PaytronixStoredValue = new PaytronixStoredValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BalanceRequest$Type extends MessageType<BalanceRequest> {
    constructor() {
        super("Kiosk.BalanceRequest", [
            { no: 1, name: "paymentIntegrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "idPay", kind: "message", oneof: "values", T: () => IdPayPayment },
            { no: 5, name: "paytronixGiftCard", kind: "message", oneof: "values", T: () => PaytronixGiftCard },
            { no: 6, name: "paytronixStoredValue", kind: "message", oneof: "values", T: () => PaytronixStoredValue }
        ]);
    }
    create(value?: PartialMessage<BalanceRequest>): BalanceRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.paymentIntegrationId = "";
        message.accountId = "";
        message.values = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<BalanceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BalanceRequest): BalanceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string paymentIntegrationId */ 1:
                    message.paymentIntegrationId = reader.string();
                    break;
                case /* string accountId = 2 [deprecated = true];*/ 2:
                    message.accountId = reader.string();
                    break;
                case /* Kiosk.IdPayPayment idPay */ 4:
                    message.values = {
                        oneofKind: "idPay",
                        idPay: IdPayPayment.internalBinaryRead(reader, reader.uint32(), options, (message.values as any).idPay)
                    };
                    break;
                case /* Kiosk.PaytronixGiftCard paytronixGiftCard */ 5:
                    message.values = {
                        oneofKind: "paytronixGiftCard",
                        paytronixGiftCard: PaytronixGiftCard.internalBinaryRead(reader, reader.uint32(), options, (message.values as any).paytronixGiftCard)
                    };
                    break;
                case /* Kiosk.PaytronixStoredValue paytronixStoredValue */ 6:
                    message.values = {
                        oneofKind: "paytronixStoredValue",
                        paytronixStoredValue: PaytronixStoredValue.internalBinaryRead(reader, reader.uint32(), options, (message.values as any).paytronixStoredValue)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BalanceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string paymentIntegrationId = 1; */
        if (message.paymentIntegrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.paymentIntegrationId);
        /* string accountId = 2 [deprecated = true]; */
        if (message.accountId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountId);
        /* Kiosk.IdPayPayment idPay = 4; */
        if (message.values.oneofKind === "idPay")
            IdPayPayment.internalBinaryWrite(message.values.idPay, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.PaytronixGiftCard paytronixGiftCard = 5; */
        if (message.values.oneofKind === "paytronixGiftCard")
            PaytronixGiftCard.internalBinaryWrite(message.values.paytronixGiftCard, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Kiosk.PaytronixStoredValue paytronixStoredValue = 6; */
        if (message.values.oneofKind === "paytronixStoredValue")
            PaytronixStoredValue.internalBinaryWrite(message.values.paytronixStoredValue, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.BalanceRequest
 */
export const BalanceRequest = new BalanceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Balance$Type extends MessageType<Balance> {
    constructor() {
        super("Kiosk.Balance", [
            { no: 1, name: "accountId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "credit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Balance>): Balance {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountId = "";
        message.name = "";
        message.credit = 0;
        if (value !== undefined)
            reflectionMergePartial<Balance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Balance): Balance {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountId = 1 [deprecated = true];*/ 1:
                    message.accountId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 credit */ 3:
                    message.credit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Balance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountId = 1 [deprecated = true]; */
        if (message.accountId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 credit = 3; */
        if (message.credit !== 0)
            writer.tag(3, WireType.Varint).int32(message.credit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.Balance
 */
export const Balance = new Balance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderRefundRequest$Type extends MessageType<OrderRefundRequest> {
    constructor() {
        super("Kiosk.OrderRefundRequest", [
            { no: 1, name: "paymentIntegrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "transactionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OrderRefundRequest>): OrderRefundRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.paymentIntegrationId = "";
        message.transactionId = "";
        if (value !== undefined)
            reflectionMergePartial<OrderRefundRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderRefundRequest): OrderRefundRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string paymentIntegrationId */ 1:
                    message.paymentIntegrationId = reader.string();
                    break;
                case /* string transactionId */ 2:
                    message.transactionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderRefundRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string paymentIntegrationId = 1; */
        if (message.paymentIntegrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.paymentIntegrationId);
        /* string transactionId = 2; */
        if (message.transactionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.transactionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Kiosk.OrderRefundRequest
 */
export const OrderRefundRequest = new OrderRefundRequest$Type();
