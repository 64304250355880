import type { MenuItem } from '../../../generated/menu_pb';
import type { LocationDb } from '../../storage/location_db';
import { gem_service } from '../events/gem_event_service';
import { get, writable, type Writable } from 'svelte/store';
import { assertExists } from '../../services/utils';

export interface IEightySixStateManager {
  store: Writable<EightySixedItems>;

  setDb(db: LocationDb): Promise<void>;

  addEightySixedItem(menuItemId: MenuItem['menuItemId']): Promise<void>;
  removeEightySixedItem(menuItemId: MenuItem['menuItemId']): Promise<void>;

  bulkUpdateEightySixedItems(menuItemIds: MenuItem['menuItemId'][]): Promise<void>;
}

export type EightySixedItems = Set<MenuItem['menuItemId']>;

export class EightySixStateManager implements IEightySixStateManager {
  store: Writable<EightySixedItems> = writable(new Set());

  private db: LocationDb | undefined;

  async setDb(db: LocationDb) {
    this.db = db;

    const newState = new Set(
      (await db.transaction('r?', db.item86, () => db.item86.toCollection().primaryKeys())).map(
        (x) => x.toString()
      )
    );

    this.store.set(newState);
  }

  async addEightySixedItem(menuItemId: MenuItem['menuItemId']) {
    const db = this.db;
    assertExists(db, 'Missing database');
    const dict = await db.item86.get(menuItemId);
    if (!dict) {
      await db.transaction('rw?', db.item86, () => db.item86.add({ id: menuItemId }));
      const current = get(this.store);
      current.add(menuItemId);
      this.store.set(current);

      // report to GEM
      gem_service.sync_item86_success({ added: menuItemId });
    }
  }

  async removeEightySixedItem(menuItemId: MenuItem['menuItemId']) {
    const db = this.db;
    assertExists(db, 'Missing database');

    await db.transaction('rw?', db.item86, () => db.item86.delete(menuItemId));

    const current = get(this.store);
    current.delete(menuItemId);
    this.store.set(current);

    // report to GEM
    gem_service.sync_item86_success({ removed: menuItemId });
  }

  async bulkUpdateEightySixedItems(menuItemIds): Promise<void> {
    const db = this.db;
    assertExists(db, 'Missing database');

    await db.transaction('rw?', db.item86, () => {
      db.item86.clear();
      db.item86.bulkAdd(menuItemIds.map((x) => ({ id: x })));
    });

    this.store.set(new Set(menuItemIds));

    // report to GEM
    gem_service.sync_item86_success({ replaced: menuItemIds });
  }
}

export const eighty_six_service: IEightySixStateManager = new EightySixStateManager();
