import * as Sentry from '@sentry/svelte';
import '@unocss/reset/tailwind.css';
import 'uno.css';
import App from './App.svelte';

Sentry.init({
  dsn: import.meta.env.DEV
    ? ''
    : 'https://94a723df44f0be24ece557985c85871a@o86468.ingest.sentry.io/4505760093241344',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', import.meta.env.VITE_GRPC_ENDPOINT, /.*\/api\/.*/],
    }),
    new Sentry.Replay({
      stickySession: false,
      maskAllInputs: true,
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
  release: __APP_VERSION__,
  environment: import.meta.env.MODE,
  // Performance Monitoring
  tracesSampleRate: parseInt(
    import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE as unknown as string,
    10
  ), // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: parseInt(
    import.meta.env.VITE_SENTRY_REPLAY_SAMPLE_RATE as unknown as string,
    10
  ), // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: parseInt(
    import.meta.env.VITE_SENTRY_REPLAY_ERROR_SAMPLE_RATE as unknown as string,
    10
  ), // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const element = document.getElementById('app');
if (!element) {
  throw new Error('Bad');
}
const app = new App({
  target: element,
});

export default app;
