import type { LocalizedStringSet, MenuComboComponentType } from '../../generated/menu_pb';

import type { DownloadedImageUrl } from '../data-management/types';
import type { IPricesStateManager } from '../state-managers';
import type { KioskMenuItem } from './kiosk_menu_item';

export type KioskComboComponentChoiceGrouping = {
  id: string;
  name: string;
  choiceIndexes: number[]; // indexes of the choices in the 'choices' array of 'KioskComboComponent'
};

export type KioskComboComponent = {
  id: string;
  name: string;
  selectedDisplayName: string;
  choices: KioskComboComponentChoice[];
  localizedName: LocalizedStringSet | undefined;
  minSelectionCount: number | undefined;
  maxSelectionCount: number | undefined;
  displayOrder: number | undefined;
  localizedDisplayName: LocalizedStringSet | undefined;
  componentType: MenuComboComponentType;
  choiceGroupings: KioskComboComponentChoiceGrouping[];
  isHidden: boolean;
};

export type KioskComboComponentReduced = Omit<KioskComboComponent, 'choices'> & {
  choices: KioskComboComponentChoiceWithoutItem[];
};

export type KioskComboComponentMedia = {
  selectedImagePath: DownloadedImageUrl | null;
  listingImagePath: DownloadedImageUrl | null;
};

export type KioskComboComponentChoice = {
  isDefault: boolean;
  groupingId?: string;
  item: KioskMenuItem;
};

export type KioskComboComponentChoiceWithoutItem = {
  itemId: string;
  isDefault: boolean;
  groupingId?: string;
};

export const getDefaultComboComponentChoice = (comboComponent: KioskComboComponent) => {
  const defaultComponent = comboComponent.choices.find((item) => item.isDefault);
  return defaultComponent;
};

export type KioskComboComponentChoicePricing = {
  priceDeltaFromDefault: number;
  componentPrice: number;
};

export interface IComboComponentChoicePricingService {
  getChoiceDisplayPrice(choice: KioskComboComponentChoice): number;
  getCalculationPrice(choice: KioskComboComponentChoice): number;
}

export class ComboComponentChoicePricingService implements IComboComponentChoicePricingService {
  private pricing: Map<string, KioskComboComponentChoicePricing>;

  constructor(component: KioskComboComponent, priceStateManager: IPricesStateManager) {
    this.pricing = new Map(
      component.choices.map(({ item }): [string, KioskComboComponentChoicePricing] => {
        const pricebookPrice =
          priceStateManager.getPriceForItemInComboComponent(component.id, item.menuItemId) ?? 0;

        return [
          item.menuItemId,
          {
            priceDeltaFromDefault: pricebookPrice,
            componentPrice: pricebookPrice,
          },
        ];
      })
    );

    const defaultChoice = component.choices.find((choice) => choice.isDefault);

    const defaultChoicePrice =
      this.pricing.get(defaultChoice?.item.menuItemId ?? '')?.componentPrice ?? 0;

    // adjust prices to be the difference from the default
    if (component.choices.length > 1) {
      component.choices.forEach((choice) => {
        this.pricing.get(choice.item.menuItemId)!.priceDeltaFromDefault =
          (this.pricing.get(choice.item.menuItemId)?.componentPrice ?? 0) - defaultChoicePrice;
      });
    }
  }

  getChoiceDisplayPrice(choice: KioskComboComponentChoice) {
    return this.pricing.get(choice.item.menuItemId)?.priceDeltaFromDefault ?? 0;
  }

  getCalculationPrice(choice: KioskComboComponentChoice) {
    return this.pricing.get(choice.item.menuItemId)?.componentPrice ?? 0;
  }
}
