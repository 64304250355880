import type { ModifierGroup } from '../../../generated/menu_pb';
import type { ModifierGroupStateManager, ModGroupService } from './types';
import { mod_group_service } from './mod_group_service.js';
import type { KioskMenuItem } from '../../models';

export function load_modifier_groups(
  modifierGroupStateManager: ModifierGroupStateManager,
  item: KioskMenuItem
): Map<ModifierGroup['modifierGroupId'], ModGroupService> {
  const modifierGroups = item.modifierGroups;
  const mod_services = new Map<ModifierGroup['modifierGroupId'], ModGroupService>();

  for (const modifierGroup of modifierGroups) {
    const modifierGroupService = mod_group_service({
      modifierGroupStateManager,
      group: modifierGroup,
    });
    mod_services.set(modifierGroup.modifierGroupId, modifierGroupService);
  }

  return mod_services;
}
