import { createMachine, interpret } from 'xstate';
import { order_service } from '../order/order_machine';

const routingMachine = createMachine(
  {
    id: 'promise',
    predictableActionArguments: true,
    preserveActionOrder: true,
    context: {
      currentPage: '',
      currentPopup: '',
    },
    initial: 'core',
    tsTypes: {} as import('./routing_machine.typegen').Typegen0,
    schema: {},
    states: {
      core: {
        type: 'parallel',
        states: {
          page: {
            initial: 'logged_out',
            on: {
              RESTART_KIOSK: {
                actions: 'restart_kiosk',
              },
            },
            states: {
              logged_out: {
                tags: 'LOGGED_OUT',
                on: {
                  REGISTERED: {
                    target: 'logged_in.start.admin',
                  },
                  REGISTERING: {
                    target: 'registering',
                  },
                  LOGGED_IN: {
                    target: 'logged_in',
                  },
                },
              },
              registering: {
                tags: 'REGISTERING',
                on: {
                  REGISTERED: {
                    target: 'logged_in.start.admin',
                  },
                  FAILED: {
                    target: 'logged_out',
                  },
                },
              },
              logged_in: {
                tags: 'LOGGED_IN',
                on: {
                  LOYALTY_ACCOUNT: {
                    target: '.session.loyalty_account',
                  },
                  LOG_OUT: {
                    target: 'logged_out',
                  },
                },
                initial: 'start',
                states: {
                  start: {
                    tags: 'NOT_IN_SESSION',
                    initial: 'order_start',
                    on: {
                      ADMIN: {
                        target: '.admin',
                      },
                      ORDER_START: {
                        target: '.order_start',
                      },
                      START: {
                        target: 'session',
                      },
                    },
                    states: {
                      idle: {},
                      admin: {
                        tags: 'ADMIN',
                      },
                      loyalty_account: {
                        tags: 'LOYALTY_ACCOUNT',
                      },
                      order_start: {
                        tags: 'ORDER_START',
                        on: {
                          PICKUP_PAYMENT: {
                            target: '.pickup_payment_session',
                          },
                        },
                        states: {
                          pickup_payment_session: {
                            tags: 'IN_PICKUP_PAYMENT_SESSION',
                            initial: 'checkout',
                            on: {
                              CHECKOUT: {
                                target: '.checkout',
                              },
                            },
                            states: {
                              checkout: {
                                tags: 'CHECKING_OUT',
                                initial: 'PAYING',
                                states: {
                                  PAYING: {
                                    tags: 'PAYING',
                                    initial: 'SELECT_PAYMENT',
                                    on: {
                                      SELECT_PAYMENT: { target: '.SELECT_PAYMENT' },
                                      GIFTCARD: { target: '.GIFTCARD' },
                                      CARD: { target: '.CARD' },
                                      STORED_VALUE: { target: '.STORED_VALUE' },
                                      CASH: { target: '.CASH' },
                                      PAY_AT_COUNTER: { target: '.PAY_AT_COUNTER' },
                                    },
                                    states: {
                                      SELECT_PAYMENT: {
                                        tags: 'SELECT_PAYMENT',
                                      },
                                      GIFTCARD: {
                                        tags: 'GIFTCARD',
                                      },
                                      CARD: {
                                        tags: 'CARD',
                                      },
                                      STORED_VALUE: {
                                        tags: 'STORED_VALUE',
                                      },
                                      CASH: {
                                        tags: ['CASH'],
                                      },
                                      PAY_AT_COUNTER: {
                                        tags: ['PAY_AT_COUNTER'],
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  session: {
                    tags: 'IN_SESSION',
                    initial: 'menu',
                    on: {
                      CHECKOUT: {
                        target: '.checkout',
                      },
                      MENU: {
                        target: '.menu',
                      },
                      REVIEW_ORDER: {
                        target: '.menu.REVIEWING',
                      },
                    },
                    states: {
                      menu: {
                        initial: 'ORDERING',
                        states: {
                          ORDERING: { tags: 'ORDERING' },
                          REVIEWING: { tags: 'REVIEWING' },
                        },
                      },
                      checkout: {
                        tags: 'CHECKING_OUT',
                        initial: 'PAYING',
                        on: {
                          PAY: {
                            target: '.PAYING',
                          },
                          PAYMENT_SELECTION: {
                            target: '.PAYMENT_SELECTION',
                          },
                          TIPS: { target: '.TIPS' },
                          PROCESS_PAYMENT: { target: '.PAYMENT_PROCESSING' },
                          POINTS_EARNED: { target: '.POINTS_EARNED' },
                          ORDER_CONFIRMATION: { target: '.ORDER_CONFIRMATION' },
                          PAYMENT_SUCCESS: { target: '.PAYMENT_SUCCESS' },
                          PAID: { target: '.PAID' },
                          NOTPAID: { target: '.NOTPAID' },
                        },
                        states: {
                          PAYMENT_SELECTION: {
                            tags: 'SELECTING_PAYMENT',
                          },
                          TIPS: {
                            tags: 'TIPS',
                          },
                          PAYING: {
                            tags: 'PAYING',
                            initial: 'SELECT_PAYMENT',
                            on: {
                              SELECT_PAYMENT: { target: '.SELECT_PAYMENT' },
                              GIFTCARD: { target: '.GIFTCARD' },
                              CARD: { target: '.CARD' },
                              STORED_VALUE: { target: '.STORED_VALUE' },
                              CASH: { target: '.CASH' },
                              PAY_AT_COUNTER: { target: '.PAY_AT_COUNTER' },
                            },
                            states: {
                              SELECT_PAYMENT: {
                                tags: 'SELECT_PAYMENT',
                              },
                              GIFTCARD: {
                                tags: 'GIFTCARD',
                              },
                              CARD: {
                                tags: 'CARD',
                              },
                              STORED_VALUE: {
                                tags: 'STORED_VALUE',
                              },
                              CASH: {
                                tags: ['CASH'],
                              },
                              PAY_AT_COUNTER: {
                                tags: ['PAY_AT_COUNTER'],
                              },
                            },
                          },
                          PAYMENT_PROCESSING: {
                            tags: 'PAYMENT_PROCESSING',
                          },
                          POINTS_EARNED: {
                            tags: 'POINTS_EARNED',
                          },
                          PAYMENT_SUCCESS: {
                            tags: 'PAYMENT_SUCCESS',
                          },
                          ORDER_CONFIRMATION: {
                            tags: 'ORDER_CONFIRMATION',
                          },
                          PAID: {
                            tags: 'PAID',
                          },
                          NOTPAID: {
                            tags: 'NOTPAID',
                          },
                        },
                      },
                      checked_out: {
                        tags: 'CHECKED_OUT',
                        states: {
                          paid: {
                            tags: 'PAID',
                          },
                          points_earned: {
                            tags: 'POINTS_EARNED',
                          },
                          confirmation: {
                            tags: 'ORDER_CONFIRMATION',
                          },
                        },
                      },
                      loyalty_account: {
                        tags: 'LOYALTY_ACCOUNT',
                      },
                    },
                  },
                },
              },
            },
          },
          popup: {
            initial: 'empty',
            type: 'parallel',
            states: {
              message: {
                initial: 'closed',
                states: {
                  visible: {},
                  closed: {},
                },
                on: {
                  OPEN_MESSAGES: {
                    target: '.visible',
                  },
                  CLOSE_MESSAGES: {
                    target: '.closed',
                  },
                },
              },
              order_upsell: {
                initial: 'closed',
                states: {
                  visible: {},
                  closed: {},
                },
                on: {
                  OPEN_ORDER_UPSELLS: {
                    target: '.visible',
                  },
                  CLOSE_ORDER_UPSELLS: {
                    target: '.closed',
                  },
                },
              },
              item_upsell: {
                initial: 'closed',
                states: {
                  visible: {},
                  closed: {},
                },
                on: {
                  CLOSE_ITEM_UPSELLS: {
                    target: '.closed',
                  },
                  OPEN_ITEM_UPSELLS: {
                    target: '.visible',
                  },
                },
              },
              makemeal: {
                initial: 'closed',
                states: {
                  visible: {},
                  closed: {},
                },
                on: {
                  CLOSE_MAKEMEAL: {
                    target: '.closed',
                  },
                  OPEN_MAKEMEAL: {
                    target: '.visible',
                  },
                },
              },
              item_special_request: {
                initial: 'closed',
                states: {
                  visible: {},
                  closed: {},
                },
                on: {
                  CLOSE_ITEM_SPECIAL_REQUEST: {
                    target: '.closed',
                  },
                  OPEN_ITEM_SPECIAL_REQUEST: {
                    target: '.visible',
                  },
                },
              },

              error_modal: {
                initial: 'closed',
                states: {
                  submit_error: {
                    tags: 'submit_error',
                  },
                  calculate_error: {
                    tags: 'calculate_error',
                  },
                  generic_error: {
                    tags: 'generic_error',
                  },
                  proxy_pos_error: {
                    tags: 'proxy_pos_error',
                  },
                  refresh_error: {
                    tags: 'refresh_error',
                  },
                  closed: {},
                },
                on: {
                  CLOSE_ERROR_MODAL: {
                    target: '.closed',
                  },
                  OPEN_CALCULATE_ERROR_MODAL: {
                    target: '.calculate_error',
                  },
                  OPEN_SUBMIT_ERROR_MODAL: {
                    target: '.submit_error',
                  },
                  OPEN_GENERIC_ERROR_MODAL: {
                    target: '.generic_error',
                  },
                  OPEN_PROXY_POS_ERROR_MODAL: {
                    target: '.proxy_pos_error',
                  },
                  OPEN_REFRESH_ERROR_MODAL: {
                    target: '.refresh_error',
                  },
                },
              },
              loyalty_modal: {
                initial: 'closed',
                states: {
                  visible: {},
                  closed: {},
                },
                on: {
                  CLOSE_LOYALTY_MODAL: {
                    target: '.closed',
                  },
                  OPEN_LOYALTY_MODAL: {
                    target: '.visible',
                  },
                },
              },
              discount_detail_modal: {
                initial: 'closed',
                states: {
                  visible: {},
                  closed: {},
                },
                on: {
                  CLOSE_DISCOUNT_DETAIL_MODAL: {
                    target: '.closed',
                  },
                  OPEN_DISCOUNT_DETAIL_MODAL: {
                    target: '.visible',
                  },
                },
              },
              combo_builder: {
                initial: 'closed',
                states: {
                  visible: {},
                  closed: {},
                },
                on: {
                  COMBO_BUILDER_CLOSE: {
                    target: '.closed',
                  },
                  COMBO_BUILDER_OPEN: {
                    target: '.visible',
                  },
                },
              },

              discount_code_modal: {
                initial: 'closed',
                states: {
                  visible: {},
                  closed: {},
                },
                on: {
                  CLOSE_DISCOUNT_CODE_MODAL: {
                    target: '.closed',
                  },
                  OPEN_DISCOUNT_CODE_MODAL: {
                    target: '.visible',
                  },
                },
              },

              pay_at_counter_modal: {
                initial: 'closed',
                states: {
                  visible: {},
                  closed: {},
                },
                on: {
                  OPEN_PAY_AT_COUNTER_MODAL: {
                    target: '.visible',
                  },
                  CLOSE_PAY_AT_COUNTER_MODAL: {
                    target: '.closed',
                  },
                },
              },
              reward_code_modal: {
                initial: 'closed',
                states: {
                  visible: {},
                  closed: {},
                },
                on: {
                  CLOSE_REWARD_CODE_MODAL: {
                    target: '.closed',
                  },
                  OPEN_REWARD_CODE_MODAL: {
                    target: '.visible',
                  },
                },
              },
              customize_item_modal: {
                initial: 'closed',
                states: {
                  visible: {},
                  closed: {},
                },
                on: {
                  OPEN_CUSTOMIZE_ITEM: {
                    target: '.visible',
                  },
                  CLOSE_CUSTOMIZE_ITEM: {
                    target: '.closed',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      restart_kiosk: () => {
        const snapshot = order_service.getSnapshot();
        const context = snapshot.context;
        const transactionsService = context.locationStateManager?.transactionsService;
        (async () => {
          if (context.transactions.length > 0 && !snapshot.hasTag('paid')) {
            await Promise.all(
              context.transactions.map(async (transaction) => {
                if (transaction.status === 'paid') {
                  const device = context.payment_services?.find(
                    (f) =>
                      f.getSnapshot()?.context.config.paymentIntegrationId ===
                      transaction.device.paymentIntegrationId
                  );
                  if (device) {
                    await transactionsService?.deleteTransaction(transaction.id!);
                    device.send({ type: 'REFUND', data: transaction.response.info?.transactionId });
                  }
                }
              })
            );
          }

          await context.locationStateManager?.destroy();

          window.location.reload();
        })();
      },
    },
  }
);

export const routing_service = interpret(routingMachine, { execute: false })
  .onTransition((s) => {
    requestAnimationFrame(() => {
      routing_service.execute(s);
    });
  })
  .start();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).routing_service = routing_service;
