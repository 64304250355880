import type { ItemModifier, OrderItem } from '../../../generated/kioskOrder_pb';
import type { KioskModifierGroup } from '../../models';

/**
 * loads previous selections for last 5 / item recreation
 * @returns Map of generated ModifierGroup from an existing item order details
 */
export function group_modifier_order_details(order_details: ItemModifier | OrderItem | null) {
  return (
    order_details?.modifiers.reduce<
      Map<string, { mod_group: KioskModifierGroup; order_details: ItemModifier[] }>
    >((groups, item_mod) => {
      const existing_mods = groups.get(item_mod.optionGroupId);
      if (!existing_mods) {
        groups.set(item_mod.optionGroupId, {
          mod_group: {
            modifierGroupId: item_mod.optionGroupId,
            name: '',
            selectMin: 0,
            selectMax: 0,
            modifierIds: [item_mod.modifierId],
            freeModifierCount: 0,
            displayName: '',
            modifiers: [],
            negativeModifierBehavior: 0,
            groupModifierCodes: [],
            shortcutName: '',
          },
          order_details: [item_mod],
        });
        return groups;
      }
      groups.set(
        item_mod.optionGroupId,
        Object.assign({}, existing_mods, {
          order_details: existing_mods.order_details.concat([item_mod]),
          mod_group: Object.assign({}, existing_mods.mod_group, {
            modifierIds: existing_mods.mod_group.modifierIds.concat([item_mod.modifierId]),
          }),
        })
      );
      return groups;
    }, new Map()) ?? new Map()
  );
}
