import {
  type Stores,
  type StoresValues,
  derived,
  readable,
  writable,
  type Readable,
} from 'svelte/store';

export interface IIdleStateManager {
  store: Readable<IdleStoreState>;
  updateConfig(config: IdleConfigOptions): void;
  pause(): void;
  resume(): void;
  recordActivity(): void;
  hidePopupAndResetIdleTracker(): void;
}

export type IdleConfigOptions = {
  idle: number;
  countdown: number;
};

export type IdleStoreState =
  | {
      idlePopup: 'hidden';
    }
  | {
      idlePopup: 'shown';
      secondsLeftBeforeReset: number;
    };

export type IdleInternalStoreState = {
  lastActivityTime: number;
  paused: boolean;
};

const defaultTickStore = readable<number>(Date.now(), (set) => {
  const handle = setInterval(() => {
    set(Date.now());
  }, 1000);

  return () => clearInterval(handle);
});

export interface ITimeProvider {
  tickStore: Readable<number>;
  getNow(): number;
}

const defaultTimeProvider: ITimeProvider = {
  tickStore: defaultTickStore,
  getNow: () => Date.now(),
};

const areIdleStatesEqual = (state1: IdleStoreState, state2: IdleStoreState) => {
  if (state1.idlePopup === 'shown' && state2.idlePopup === 'shown') {
    return state1.secondsLeftBeforeReset === state2.secondsLeftBeforeReset;
  }

  return state1.idlePopup === state2.idlePopup;
};

const derivedWithEqualityCheck = <S extends Stores, T>(
  stores: S,
  deriveFn: (values: StoresValues<S>) => T,
  areEqual: (prev: T, next: T) => boolean
): Readable<T> => {
  let prevValue: T;
  return derived(stores, ($stores) => {
    const nextValue = deriveFn($stores);
    if (prevValue && areEqual(prevValue, nextValue)) {
      return prevValue;
    }

    prevValue = nextValue;
    return nextValue;
  });
};

export class IdleStateManager implements IIdleStateManager {
  private config: IdleConfigOptions;
  private timeProvider: ITimeProvider;
  private internalStore = writable<IdleInternalStoreState>({
    lastActivityTime: Date.now(),
    paused: true,
  });

  store: Readable<IdleStoreState>;

  constructor(config: IdleConfigOptions, timeProvider: ITimeProvider = defaultTimeProvider) {
    this.config = config;
    this.timeProvider = timeProvider;

    this.store = derivedWithEqualityCheck(
      [this.internalStore, timeProvider.tickStore],
      this.deriveStore,
      areIdleStatesEqual
    );
  }

  private deriveStore = ([$internalStore, $tickStore]: [
    IdleInternalStoreState,
    number,
  ]): IdleStoreState => {
    if ($internalStore.paused) {
      return {
        idlePopup: 'hidden',
      };
    }

    const diff = $tickStore - $internalStore.lastActivityTime;
    const showIdlePopup = diff >= this.config.idle;
    const totalTimeout = this.config.idle + this.config.countdown;
    if (showIdlePopup) {
      return {
        idlePopup: 'shown',
        secondsLeftBeforeReset: Math.max((totalTimeout - diff) / 1000, 0),
      };
    }

    return {
      idlePopup: 'hidden',
    };
  };

  updateConfig(config: IdleConfigOptions): void {
    this.config = config;
  }

  pause(): void {
    this.internalStore.update((state) => {
      state.paused = true;
      return state;
    });
  }

  resume(): void {
    this.internalStore.update((state) => {
      state.paused = false;
      state.lastActivityTime = this.timeProvider.getNow();
      return state;
    });
  }

  hidePopupAndResetIdleTracker = () => {
    this.internalStore.update((state) => {
      state.lastActivityTime = this.timeProvider.getNow();
      state.paused = false;
      return state;
    });
  };

  recordActivity(): void {
    this.internalStore.update((state) => {
      const diff = this.timeProvider.getNow() - state.lastActivityTime;
      const showIdlePopup = diff > this.config.idle;
      if (showIdlePopup) {
        return state;
      }

      state.lastActivityTime = this.timeProvider.getNow();
      return state;
    });
  }
}
