import type { KioskPaymentDevice } from '../../../generated/kiosk_pb';
import type { VerifoneSafRecords } from './verifone/types';

export type ErroredEvent = {
  type: 'ERRORED';
  session_data?: Record<string | number | symbol, unknown>;
  data: { messages: string[]; trace: Record<string | number | symbol, unknown> } | Error;
};

export type PaymentTransaction<T extends object = object> = {
  id?: number;
  device: KioskPaymentDevice;
  request: PaymentRequestData;
  time: Date;
  posOrderId?: string;
} & (
  | {
      status: 'incomplete';
    }
  | {
      status: 'paid' | 'notpaid';
      response: Partial<PaymentResponse<T>>;
    }
  | {
      status: 'refunded';
      response: Partial<PaymentResponse<T>>;
      refund: Partial<PaymentResponse<T>>;
      refund_time: Date;
    }
  | {
      status: 'cancelled' | 'rejected' | 'errored';
      response: object;
    }
  | {
      status: 'failedToSend';
      response: object;
    }
) & {
    CTROUTD?: string;
    TROUTD?: string;
  };

export type FilteredPaymentTransaction<T extends object = object> = {
  id: number;
  response: Partial<PaymentResponse<T>>;
  CTROUTD: string;
  TROUTD: string;
};

export type PaymentRequestData = {
  sub_total: number;
  taxes: Record<string, number>;
  tip: number;
  divisor: number;
  order_id?: string;
  identity?: string;
  preTipTotal: number;
};

export type PaytronixGiftCardBalanceQuery = {
  cardNumber: string;
  regCode: string;
};

// type testing
type RandomGiftCardBalanceQuery = {
  securityCode: string;
  cardNumber: string;
  expiration: string;
};

export type BalanceEventData = {
  query: PaytronixGiftCardBalanceQuery | RandomGiftCardBalanceQuery | undefined;
};

export type PaytronixTransactData = {
  cardNumber: string;
  regCode: string;
};

export type PayRequest = {
  requestData: PaymentRequestData;
  transactData: TransactData | undefined;
};

export type TransactData = {
  data: PaytronixTransactData | undefined;
};

export type PaymentResponse<T extends object = object> = {
  response: T;
  info: PaymentInfo;
  isOffline?: boolean;
};

export type OfflinePaymentSyncResponse = {
  response: VerifoneSafRecords[];
};

export type PaymentMachineEvent<T extends object = object> =
  | {
      type: 'PAY';
      data: PayRequest;
    }
  | {
      type: 'REFUND';
      data: T;
    }
  | {
      type: 'SETCONTEXT';
      data: T;
    }
  | {
      type: 'CANCEL' | 'CANCELLED';
      data: undefined;
    }
  | {
      type: 'ERROR';
      data: object;
    }
  | {
      type: 'PAID';
      data: PaymentResponse<T>;
    }
  | {
      type: 'NOTPAID';
      data: PaymentResponse<T>;
    }
  | {
      type: 'TRANSACTED';
      data: PaymentTransaction<T>;
    }
  | {
      type: 'REFUNDED';
      data: T;
    }
  | {
      type: 'REJECTED';
      data: T;
    }
  | {
      type: 'GET_BALANCE';
      data: BalanceEventData;
    }
  | {
      type: 'PROCESS_OFFLINE_TRANSACTIONS';
      data: PaymentResponse<T>;
    }
  | {
      type: 'PROCESS_OFFLINE_NON_CARD_TRANSACTIONS';
      data: PaymentResponse<T>;
    }
  | {
      type: 'OFFLINE_PROCESSED_SUCCESSFULLY';
      data: OfflinePaymentSyncResponse;
    };

export enum CardNetwork {
  Visa,
  MasterCard,
  Discover,
  AMEX,
  Debit,
  Unknown,
}

export type CardInfo = {
  holder_name?: string;
  /** mastercard | visa | amex */
  network?: CardNetwork;
  /** swipe | chip */
  method?: string;
  /** last 4 digits */
  ending?: string;
  expirationMonth?: string;
  expirationYear?: string;
  maskedCardNumber?: string;
  availableBalance?: number;
};

export type ReceiptRow = {
  left?: string;
  center?: string;
  right?: string;
  bold?: boolean;
};

export type PaymentInfo = {
  additional_info?: ReceiptRow[];
  signature?: string;
  signature_mime?: string;
  card_info?: CardInfo;
  transactionId: string;
  amount: number;
  tipAmount: number;
  type?: string; // credit | debit...
  time: Date;
  status?: string;
  invoiceId: string;
  authorizationCode?: string;
  zipCode?: string;
};

export interface PaymentService<
  T extends object = object,
  U extends object = object,
  O extends object = object,
> {
  initialize: (options: O) => Promise<boolean>;
  begin_session: () => Promise<boolean>;
  pay: (request: { request: PaymentRequestData; pre_auth?: U }) => Promise<PaymentResponse<T>>;
  cancel: () => Promise<boolean>;
  refund: (request: PaymentTransaction<T>) => Promise<boolean>;
  card_info: () => Promise<{ pre_auth: U; balance: number }>;
  pre_auth: (request: PaymentRequestData) => Promise<{ pre_auth: U; balance: number }>;
  end_session: () => Promise<boolean>;
  self_test: () => Promise<boolean>;
}
