<script lang="ts">
  // @ts-expect-error something is wrong with the pwa virtual generator, this actually works
  import { registerSW } from 'virtual:pwa-register';

  import { app_service } from 'business-states/src/lib/states/';
  import { screensaver } from 'business-states/src/lib/storage/screensaver';
  import Media from 'svelte-ui/src/lib/templates/components/Media.svelte';
  import type { MouseEventHandler } from 'svelte/elements';
  import { fade } from 'svelte/transition';

  registerSW();

  function load_app() {
    return new Promise<typeof import('./LazyApp.svelte')>((resolve) => {
      requestAnimationFrame(() => {
        import('./LazyApp.svelte').then((app) => {
          return resolve(app);
        });
      });
    });
  }

  const loadAppPromise = load_app();

  app_service.start();

  const handleRightClick: MouseEventHandler<Window> = (event) => {
    if (import.meta.env.VITE_DISABLE_RIGHT_CLICK) {
      // Prevent the default right click // context menu from showing up
      event.preventDefault();
    }
  };

  const screensaverStore = screensaver.stateStore;
  const screensaverMediaStore = screensaver.mediaStore;

  $: screenSaverMedia = $screensaverStore.show ? $screensaverMediaStore : null;
</script>

<svelte:window on:contextmenu={handleRightClick} />

{#if screenSaverMedia}
  <button
    class="absolute top-0 left-0 right-0 bottom-0 w-full h-full"
    type="button"
    out:fade|local
  >
    <Media
      on:load={screensaver.playing}
      id="background-media"
      src={screenSaverMedia}
      alt="Screen Saver"
    />
  </button>
{/if}

{#await loadAppPromise}
  {#if !$screensaverStore.show && $screensaverStore.loaded}
    <div class="w-full h-full flex flex-col bg-black text-white justify-center items-center">
      Loading...
    </div>
  {/if}
{:then { default: LazyApp }}
  <LazyApp />
{/await}

<style>
  :global(*::-webkit-scrollbar) {
    width: 0.5rem;
    height: 0.5rem;
  }
  :global(*::-webkit-scrollbar-track) {
    background-color: transparent;
  }
  :global(*::-webkit-scrollbar-thumb) {
    --un-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--un-bg-opacity));
  }
  :global(*::-webkit-scrollbar-thumb:focus) {
    --un-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--un-bg-opacity));
  }
  :global(*::-webkit-resizer) {
    background-color: transparent;
  }
  :global(*::-webkit-scrollbar-corner) {
    background-color: transparent;
  }
</style>
