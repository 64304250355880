import { derived, get } from 'svelte/store';
import type { ItemModifier, OrderItem } from '../../../generated/kioskOrder_pb';
import type { KioskMenuItem, KioskModifierGroup } from '../../models';
import { group_modifier_order_details } from './group_modifier_order_details.js';
import type {
  ModifierGroupState,
  ModifierGroupStateManager,
  ModifierState,
  ModGroupServiceContext,
  ModifierService,
  ModGroupService,
} from './types';
import { modifier_service } from './modifier_service.js';
import { firstModifierGroupId } from './utils';
import { NegativeModifierBehavior } from '../../../generated/menu_pb';
import { eighty_six_service } from '../eighty_six/eighty_six_service';

export const createInitialModifierGroupsState = ({
  item,
  order_details,
  load_defaults,
}: {
  item: KioskMenuItem;
  order_details: OrderItem | ItemModifier | null;
  load_defaults: boolean;
}): Map<string, ModifierGroupState> => {
  const mod_group_order_detail_lookup = group_modifier_order_details(order_details);
  for (const group of item.modifierGroups) {
    if (!group) {
      continue;
    }
    const existing_order_detail = mod_group_order_detail_lookup.get(group.modifierGroupId);
    if (!existing_order_detail) {
      mod_group_order_detail_lookup.set(group.modifierGroupId, {
        mod_group: group,
        order_details: [],
      });
      continue;
    } else {
      mod_group_order_detail_lookup.delete(group.modifierGroupId);
    }
    mod_group_order_detail_lookup.set(
      group.modifierGroupId,
      Object.assign({}, existing_order_detail, {
        mod_group: Object.assign({}, group, {
          modifierIds: Array.from(
            new Set(group.modifierIds.concat(existing_order_detail.mod_group.modifierIds))
          ),
        }),
      })
    );
  }

  return new Map(
    item.modifierGroups.map((modifierGroup) => {
      const state = createInitialModifierGroupState({
        order_details: mod_group_order_detail_lookup.get(modifierGroup.modifierGroupId)!
          .order_details,
        group: modifierGroup,
        load_defaults,
      });

      return [modifierGroup.modifierGroupId, state];
    })
  );
};

export const createInitialModifiersStateForGroup = ({
  order_details,
  group,
  load_defaults,
}: {
  order_details: ItemModifier[] | null;
  group: KioskModifierGroup;
  load_defaults: boolean;
}): Map<string, ModifierState> => {
  const order_mod_lookup = (order_details ?? [])
    .filter((x) => x.quantity > 0)
    .reduce(
      (groups, order_mod) => {
        const { optionGroupId, modifierId } = order_mod;
        (groups[optionGroupId] ?? (groups[optionGroupId] = {}))[modifierId] = order_mod;
        return groups;
      },
      {} as Record<string, undefined | Record<string, undefined | ItemModifier>>
    );
  const eightySixItems = get(eighty_six_service.store);
  const modStates: [string, ModifierState][] = group.modifiers.map(
    (modifier): [string, ModifierState] => {
      const { incrementStep, defaultQuantity } = modifier;
      const item = modifier.menuItem;
      const isModifier86 = item?.menuItemId ? eightySixItems.has(item.menuItemId) : false;
      const order_details =
        (order_mod_lookup[group.modifierGroupId] ?? {})[modifier.modifierId] ??
        ({
          menuItemId: item?.menuItemId ?? '',
          modifierId: modifier.modifierId,
          optionGroupId: group.modifierGroupId,
          quantity:
            (load_defaults
              ? group.negativeModifierBehavior ===
                  NegativeModifierBehavior.ShowNegativeModifiersAsUnselected && defaultQuantity > 0
                ? 0
                : isModifier86
                  ? 0
                  : defaultQuantity
              : 0) / incrementStep,
          modifiers: [],
          transformRulesMetadata: item?.transformRulesMetadata ?? '',
          name: item?.name ?? '',
          freeQuantity: 0,
          modifierCode: '',
          modifierCodeSelection: undefined,
          isDefault: modifier.isDefault,
        } as ItemModifier);

      const selectedModifierCode = order_details.modifierCodeSelection
        ? modifier.modifierCodes.find(
            (x) => x.id === order_details.modifierCodeSelection?.modifierCodeId
          )
        : undefined;

      const defaultModifierCode = (modifierId: string) => {
        const groupModifierCode = group?.groupModifierCodes?.find(
          (x) => x.modifierId === modifierId
        );
        const isDefaultModifierCode = groupModifierCode?.modifierCodes?.find((x) => x.isDefault);
        const modModifierCode = modifier?.modifierCodes?.find(
          (x) => x.id === isDefaultModifierCode?.modifierCodeId
        );
        return modModifierCode;
      };

      return [
        modifier.modifierId,
        {
          selected_group_id: firstModifierGroupId(item) ?? undefined,
          selected_index: item.modifierGroups.length === 0 ? -1 : 0,
          selectedModifierCode:
            selectedModifierCode ?? defaultModifierCode(modifier.menuItemId) ?? null,
          quantity:
            (load_defaults
              ? group.negativeModifierBehavior ===
                  NegativeModifierBehavior.ShowNegativeModifiersAsUnselected && defaultQuantity > 0
                ? 0
                : isModifier86
                  ? 0
                  : defaultQuantity
              : isModifier86
                ? 0
                : order_details.quantity) / incrementStep,
          mod_groups: createInitialModifierGroupsState({
            item,
            order_details: order_details,
            load_defaults,
          }),
        },
      ];

      // () =>
    }
  );

  return new Map(modStates);
};

export const createInitialModifierGroupState = ({
  order_details,
  group,
  load_defaults,
}: {
  group: KioskModifierGroup;
  order_details: ItemModifier[];
  load_defaults: boolean;
}): ModifierGroupState => {
  return {
    modifiers: createInitialModifiersStateForGroup({ order_details, group, load_defaults }),
  };
};

export function mod_group_service({
  modifierGroupStateManager,
  group,
}: {
  modifierGroupStateManager: ModifierGroupStateManager;
  group: KioskModifierGroup;
}): ModGroupService {
  const modState = derived(
    modifierGroupStateManager.getDerivedModGroupStateForId(group.modifierGroupId),
    (s): ModGroupServiceContext => {
      if (!s) throw new Error('Missing mod state');

      return s.state;
    }
  );

  return {
    subscribe: modState.subscribe,
    group,
    modifierServices: load_modifiers(modifierGroupStateManager, group),
  };
}

function load_modifiers(
  modifierGroupStateManager: ModifierGroupStateManager,
  group: KioskModifierGroup
): ModifierService[] {
  const modifierStateManager = modifierGroupStateManager.getChildModifierStateManager(
    group.modifierGroupId
  );
  const modServices: ModifierService[] = group.modifiers.map((modifier) => {
    return modifier_service({
      modifierStateManager,
      modifier,
    });
  });

  return modServices;
}
