import { assign, createMachine, interpret } from 'xstate';
import { load_script } from './load_script';

async function wait(delay: number) {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay);
  });
}

const nodeUrl = 'http://localhost:4200/';
interface INodeProcessResponse {
  arch: string;
  platform: string;
  versions: {
    ares: string;
    http_parser: string;
    modules: string;
    node: string;
    openssl: string;
    uv: string;
    v8: string;
    zlib: string;
  };
}

async function get_tizen_info(tries: number): Promise<INodeProcessResponse> {
  try {
    const res = await fetch(`${nodeUrl}node`);
    const json = await res.json();
    return json;
  } catch {
    const newTries = tries - 1;
    if (newTries <= 0) {
      throw 'Server not found';
    }
    await wait(250);
    return get_tizen_info(newTries);
  }
}

function set_env_css(env: string) {
  document.getElementById('app')?.classList.add(env);
}

type AppContext = {
  init_retries: number;
  platform: string[];
};

const userAgentRegex = /Mozilla\/5.0 \((.*?)\)/y;

const app_machine = createMachine<AppContext>(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QEMAOqC0BbZBjAFgJYB2YAxAKIBKVA8lRQCIDaADALqKioD2shAF0I9iXEAA9EAFgBMAGhABPRAFYAnCoB0UtboAcANgDsKo1ICMBlQF9rCtJhwESYTSUGaIYAGbIArgA2AmRsnEggvPxCImKSCAbGmqzmKgYAzFIG5qx6MkbmCsoIMjqaMiqslSrlOUZqaWm29ujYeESkbsQeQgBeYMRkECKuJABuPADWrg6tzh3uApq9-QhjPLjI0cShoWKRgsKi4XHVhYh6KnpJlZUp6Sm6TSAzTu0jXYvLA2AATj88P00qACm28AKwmhebRcnW6hD6xFWxHGGy2Ow4ez4Bxix0QeRkZT05nKhgMej0slYUjOCDS6k0FVuKnMaiMMkedmeLVeMJ+YAEP0UAH0FmRxLABJtpt4BL8ABQySoASjIULmrj5AuFC124X2W1ieLZhOJlwS5Mp1KUeJyZR0ulYMhk5nMJgSTzVb00msFIo+YolUshMvlitYKs9vP5vp15jC3CxBtxxQMBJkRJJ5opiqtRSkRiMmhMlSdUlSLPStk5xB4Xng4UjpExUUOhoQGAMNIwJQ93Oh8w+zexR1AJ0qSTu1SMZIyrJp5jSBgZN1Y+TUejZrBUUl7jn77w8Xl8gQEQ6To9UUiuyVSU5nOiMNJKhcZyWJaRuUhyu9mXoWS3hfoz1bZM6ikbRnQ3RV01ZEwnxkNJNF0ZCzD0So2XXH8eQ6PAhFGMBgJxC9ihSTRcjQtRjFTBcEM7a0EAuJdkPXNQnVYVkNyw-dvWjbVBz1RMQOI2Qn1YJc0mLV1tzSSwZJsTlG1cX5-j5CBCJHCRVGJTRiR0Dccg-As0hpK9EMsBJMmqEoVFSAwq2sIA */
    id: 'app-machine',
    predictableActionArguments: true,
    preserveActionOrder: true,
    initial: 'init',
    context: {
      init_retries: 5,
      platform: ['N/A'],
    },
    states: {
      init: {
        entry: 'set_platform',
        always: [
          { target: 'server', cond: 'is_server' },
          { target: 'tizen', cond: 'is_tizen' },
          { target: 'tauri', cond: 'is_tauri' },
          { target: 'web' },
        ],
      },
      web: {
        entry: 'set_css_to_web',
        initial: 'active',
        states: {
          active: {
            tags: 'active',
          },
          errored: {
            tags: 'errored',
          },
        },
      },
      tauri: {
        entry: 'set_css_to_tauri',
        initial: 'active',
        states: {
          active: {
            tags: 'active',
          },
          errored: {
            tags: 'errored',
          },
        },
      },
      tizen: {
        entry: 'set_css_to_tizen',
        initial: 'init',
        states: {
          init: {
            tags: 'initializing',
            invoke: {
              src: 'init_tizen',
              onDone: { target: 'active' },
              onError: { target: 'retry_init' },
            },
          },
          retry_init: {
            tags: 'initializing',
            exit: 'decrement_retry',
            after: {
              200: [{ target: 'init', cond: 'can_retry' }, { target: 'errored' }],
            },
          },
          active: {
            tags: 'active',
          },
          errored: {
            tags: 'errored',
          },
        },
      },
      server: {
        initial: 'active',
        states: {
          active: {
            tags: 'active',
          },
          errored: {
            tags: 'errored',
          },
        },
      },
    },
  },
  {
    guards: {
      can_retry: (ctx) => ctx.init_retries > 0,
      is_server: () => !window,
      is_tizen: () => 'tizen' in window,
      is_tauri: () => '__TAURI__' in window,
    },
    services: {
      init_tizen: async () => {
        // try{
        //   const nodeInfo = await get_tizen_info(1);
        //   send({type: 'INITIALIZED', data: nodeInfo});
        //   return;
        // }catch{
        //   // server not loaded yet
        // }
        await load_script('/$B2BAPIS/b2bapis/b2bapis.js');
        return new Promise<INodeProcessResponse>((res, rej) => {
          window?.b2bapis?.b2bcontrol.startNodeServer(
            '../server.js',
            'SSSPServer',
            function () {
              get_tizen_info(64).then(res).catch(rej);
            },
            rej
          );
        });
      },
    },
    actions: {
      set_platform: assign({
        platform: (_, evt) => {
          const agent = navigator?.userAgent
            ? navigator.userAgent.match(userAgentRegex)
            : undefined;
          const result = agent ? agent[1] : '';
          const splitResult = result.split('; ');
          const platform =
            typeof evt?.data?.platform === 'string' && typeof evt?.data?.arch === 'string'
              ? [evt.data.platform as string, evt.data.arch as string].concat(splitResult)
              : splitResult;
          return platform;
        },
      }),
      set_css_to_web: () => set_env_css('web'),
      set_css_to_tizen: () => set_env_css('tizen'),
      set_css_to_tauri: () => set_env_css('tauri'),
      decrement_retry: assign({ init_retries: ({ init_retries }) => init_retries - 1 }),
    },
  }
);

export const app_service = interpret(app_machine, { execute: false }).onTransition((s) => {
  requestAnimationFrame(() => {
    app_service.execute(s);
  });
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).app_service = app_service;
