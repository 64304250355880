import type { IndexableType } from 'dexie';
import {
  assign,
  createMachine,
  sendParent,
  sendTo,
  spawn,
  type ActorRef,
  type ActorRefFrom,
} from 'xstate';

import type { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';
import { PaymentMethod, type KioskPaymentDevice } from '../../../generated/kiosk_pb';
import { isValidOneOf } from '../../data-management/utils';
import type { LocationDb } from '../../storage/location_db';
import { gem_service } from '../events/gem_event_service';
import { onlineStateManager } from '../kiosk/offline_check_service';
import { routing_service } from '../routing/routing_machine';
import { cash_machine } from './cash/cash_machine';
import { pay_at_counter_machine } from './pay_at_counter/pay_at_counter_machine';
import { paytronix_gift_machine } from './paytronix/paytronix_gift_machine';
import { paytronix_stored_value_machine } from './paytronix/paytronix_stored_value_machine';
import type { PayRequest, PaymentMachineEvent, PaymentResponse, PaymentTransaction } from './types';
import { verifone_machine } from './verifone/verifone_machine';

type PaymentProcessorContext = {
  location_db: LocationDb;
  config: KioskPaymentDevice;
  device?: ActorRef<
    PaymentMachineEvent,
    ActorRefFrom<
      | typeof verifone_machine
      | typeof cash_machine
      | typeof paytronix_gift_machine
      | typeof paytronix_stored_value_machine
      | typeof pay_at_counter_machine
    >
  >;
  pay_request?: PayRequest;
  refund_request?: object;
  transaction_id?: IndexableType;
  transport?: GrpcWebFetchTransport;
  balance: string | undefined;
};

const getTransactions = async (ctx) => {
  const transactions: PaymentTransaction[] = await ctx.location_db.transactions.toArray();

  return transactions;
};

function ready_payment_device(ctx: PaymentProcessorContext) {
  if (!isValidOneOf(ctx.config.paymentDevice)) {
    return;
  }

  switch (ctx.config.paymentDevice.oneofKind) {
    case 'cloudPaymentDevice':
      if (!ctx.transport) {
        throw new Error('Not Implemented Config Transport');
      }
      return paytronix_gift_machine.withContext({
        config: ctx.config.paymentDevice.cloudPaymentDevice,
        transport: ctx.transport,
      });
    case 'paytronixStoredValuePaymentDevice':
      if (!ctx.transport) {
        throw new Error('Not Implemented Config Transport');
      }
      return paytronix_stored_value_machine.withContext({
        config: ctx.config.paymentDevice.paytronixStoredValuePaymentDevice,
        transport: ctx.transport,
      });
    case 'verifonePaymentDevice': {
      const { authorizeOnly, ...rest } = ctx.config.paymentDevice.verifonePaymentDevice;
      return verifone_machine.withContext({
        ...rest,
        mode: authorizeOnly?.value ? 'auth' : 'capture',
      });
      // When connecting to the pizza hut lab this can be used - or the backend can be set to localhost
      // return verifone_machine.withContext({
      //   ipAddress: 'localhost'
      // });
    }
    case 'giftCardPaymentDevice':
      if (!ctx.transport) {
        throw new Error('Not Implemented Config Transport');
      }
      return paytronix_gift_machine.withContext({
        config: ctx.config.paymentDevice.giftCardPaymentDevice,
        transport: ctx.transport,
      });
  }
}

function ready_payment_machine(ctx: PaymentProcessorContext) {
  switch (ctx.config.paymentMethod) {
    case PaymentMethod.cash:
      return cash_machine;
    case PaymentMethod.credit:
      return ready_payment_device(ctx);
    case PaymentMethod.giftCard:
      return ready_payment_device(ctx);
    case PaymentMethod.storedValue:
      return ready_payment_device(ctx);
    case PaymentMethod.PayAtCounter:
      return pay_at_counter_machine;
  }
  throw new Error('Not Implemented Payment Method');
}

type PaymentTransactionEvent =
  | PaymentMachineEvent
  | {
      type: 'done.invoke.starting_transaction';
      data: { transaction_id: IndexableType };
    }
  | {
      type: 'GOT_BALANCE';
      data: string | undefined;
    }
  | {
      type: 'REFUND';
      data: string | undefined;
    }
  | {
      type: 'RESET';
      data: string | undefined;
    }
  | {
      type: 'PROCESS_OFFLINE_TRANSACTION';
      data: string | undefined;
    };

function transaction_status(evt: PaymentTransactionEvent): PaymentTransaction['status'] {
  switch (evt.type) {
    case 'PAID':
    case 'PAY':
      return 'paid';
    case 'CANCELLED':
    case 'CANCEL':
      return 'cancelled';
    case 'REJECTED':
      return 'rejected';
    case 'REFUNDED':
    case 'REFUND':
      return 'refunded';
    case 'NOTPAID':
      return 'notpaid';
  }
  return 'errored';
}

export const payment_transaction_machine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QAcCGBPAtmAdgFwH08AnVHWVAYzwEsB7HAzKgCxpzADpixUJ12UAMQBtAAwBdRCjqwatBtJAAPRAHYxazgBYAzGu0AObQYCMAJlNiAnABoQ6RLoBshzmvPOArM-NexAQGmAL7B9mhYuIQkZBTU9IzMlGwcnDQQADZgQgAKAIIAmuJSSCDIsvIJSqoIpnXmnLpe1k3WZpam9o4IztYN5ia61l6GumNjXqHhGNj4RKTkVAqJrOxc6VlCAGIAqgAyewQASgCiuwByACLFSuVyy9WIdRaNza3tdV3qhm7mAV5-UbjJpTMozKLzWJLBJMVapWB4VDEQgRSGLeIMIQQBjrHAANzoAGsuBFZtEFnFlrDkmtOAikSiMGjKQkEOwCZRUMtijdSndKopSjVng1Wu9tBZPg5EH9+v5AgqgqDSRCYuiqUkUlx6ciCKi1SzMWBiMQ6MROMgMlyAGZmzAW8FzA3QhjUrV0xG6-UUl04Nn4uic7mSXkye5VIVPZymZycHwDXpGAaWOzShAaXScMReDzWGPOAsFtTKx3kqEYlY01IRQRCE5HI4AeSOobKFQekYQ5kMse7Fi8XwQhlMccVY7qJciTp9FbdtJrOGEAGE8uclyc9q3+R3QDVrG41GpdHo1AO09o-I19yf5YEQmEwVOy+qYZr5xhayu1xu9idrpJbnbCNdxlHtOD7PxBwLLxOEMLxTHjW8lQfFVp3LDU4RJD9F1yPIAEl-xKMMBRwR4uzAiCz26aMYKaBDfCQsR72mJ9mV9Odq2w4RTgAKROJcABU-y3IDBRA8je2HSC0x8bRYPgxDFWYx8yTY2c31SXAID1JlnQrLEcTSANiQdVi9IwqsuC0nT0DU5Z-Q5LkEh5AC+VE0jOw0LQTyMEwJUsGwoM0ThTD0cxzDzQsi0nVTzNfTDOGs710ISIRjVNc1LRtO1TNimcLPdJLdPy1l2UDJyGBcoi23DMSVHUYKfOMD5ArTbw5OcMKIvzQtixQ0s7PiyyPQZAgeGtABXHBtLizFsVSMqTNQ59DUrd0dUIcappmkqGAc8rgwkETao88S6m0MQs10Cxjw+To02sPQs1zHqor6li8pS10NO1T1NrASbpsGo0TTNC0rTwW1iHtZbgbW2kNrGgHtrh-ag2ckNXOInd6tqaNY3jbRE0MZNTFTbpHusWCSakxjlNh2b4dSLbptresmxbLGapIsivJ0fRfJa8mnisXs1GcMQJSi6L+rM3ama4FmIE-Vd103Lnt2A3H4MugZrH18x9DECWvBMQdh1jXpnCaKWosMGLVXljjFeR1mcNOC5LmEjX3N5xqBea-yrGF9MxAaULdHCyK3odtCX2+hKldrXj+KEwjAJOv3vIDvzJVa7o1H3LNrGt03Xucd6VMdr6FcSoGlbhgyFuMrC5Zr526+0hvGbRiqcCqjOec8-2DEDvOQ-8GDDBsQxhiBcZJllz749r6zu-ltLQcyiGoZhgbGY7tfXZ2mve8O46h-EvmmtzgKQ+0EZGhnufgRBUEcDoCA4FufenZ+wecY1AALT3W6EAmC+tIFQMgT8ZwscVrsR+twXg-BBAAK1jUIwMFvC+FukHGw2hBzHjkuFHBfglLwLhh3DYYB0F1T3C0V4LRmhC3NtmLMVhbbS0rgzP+CVEbJRXnQ06uNTBNEzA-a6Ng7pQTUG4LwOZurSwrpQg+SCFxQGEWRM2MlPCvHouQhU9Nf7tyQUVWyjMtGdj0FTKiiARgjlIYpIxqi+HDURuvGuVizouBHExMQQxraWGjA-QcfRaI037BQpe1cV4dyToubxuNtBGHcCXQwp4wmmC0J1R6hdy72xiXHVah967HzhkkzBPhOCdWNtI-Bj1BwpKph4MhdNXGmISulM0kBKlPAybGa6CZHokwvGTQcwdMytMluXVwoRQhAA */
    predictableActionArguments: true,
    preserveActionOrder: true,
    id: 'payment_transaction_machine',
    initial: 'readying',
    on: {
      ERROR: { target: 'errored' },
    },
    tsTypes: {} as import('./payment_transaction_service.typegen').Typegen0,
    schema: {} as {
      context: PaymentProcessorContext;
      events: PaymentTransactionEvent;
      services: {
        start_pay_transaction: { data: IndexableType | undefined };
        end_pay_transaction: { data: PaymentTransaction | undefined };
        end_offline_transaction_process: { data: PaymentTransaction[] | undefined };
        end_offline_non_card_transaction_process: { data: PaymentTransaction[] | undefined };
        start_offline_processing: { data: undefined };
      };
    },
    states: {
      readying: {
        entry: 'ready_device',
        always: [
          { cond: 'has_device', target: 'idle' },
          {
            target: 'errored',
          },
        ],
      },
      idle: {
        on: {
          PAY: {
            target: 'start_pay_transaction',
            actions: ['store_pay_request', 'record_pay_start'],
          },
          REFUND: {
            target: 'start_refund_transaction',
            actions: ['store_refund_request', 'refund'],
          },
          GET_BALANCE: {
            target: 'getting_balance',
          },
          RESET: {
            actions: 'reset_balance',
            target: 'idle',
          },
          PROCESS_OFFLINE_TRANSACTIONS: {
            target: 'start_offline_processing',
          },
          PROCESS_OFFLINE_NON_CARD_TRANSACTIONS: {
            target: 'end_offline_non_card_transaction_process',
            actions: 'record_offline_payment_process',
          },
        },
      },

      getting_balance: {
        entry: 'get_balance',
        on: {
          GOT_BALANCE: {
            actions: 'store_balance',
            target: 'idle',
          },
          ERROR: { target: 'idle' },
        },
      },

      start_pay_transaction: {
        tags: 'paying',
        invoke: {
          src: 'start_pay_transaction',
          onDone: {
            target: 'paying',
            actions: 'store_transaction_id',
          },
          onError: {
            target: 'errored',
          },
        },
      },
      paying: {
        tags: 'paying',
        entry: 'send_pay',
        exit: ['clear_pay_request'],
        on: {
          ERROR: { target: 'end_pay_transaction', actions: 'record_pay_failed' },
          CANCEL: { actions: 'forward' },
          CANCELLED: { target: 'end_pay_transaction' },
          PAID: { target: 'end_pay_transaction', actions: 'record_pay_complete' },
          REJECTED: { target: 'end_pay_transaction', actions: 'record_pay_invalid' },
          NOTPAID: { target: 'end_pay_transaction', actions: 'record_pay_complete' },
        },
      },
      end_pay_transaction: {
        tags: 'paying',
        exit: 'clear_transaction_id',
        invoke: {
          src: 'end_pay_transaction',
          onDone: {
            target: 'idle',
            actions: ['send_transact_to_parent'],
          },
          onError: {
            target: 'idle',
          },
        },
      },

      start_refund_transaction: {
        tags: 'refunding',
        // invoke: {
        //   src: 'start_transaction',
        //   onDone: {
        //     target: 'refunding',
        //     actions: 'store_transaction_id',
        //   },
        //   onError: {
        //     target: 'errored',
        //   },
        // },
      },
      refunding: {
        tags: 'refunding',
        entry: 'send_refund',
        exit: 'clear_refund_request',
        on: {
          CANCEL: { actions: 'forward' },
          REFUNDED: { target: 'idle', actions: 'sendParent' },
          REJECTED: { target: 'idle', actions: 'sendParent' },
        },
      },
      end_refund_transaction: {
        tags: 'refunding',
        exit: 'clear_transaction_id',
        invoke: {
          src: 'end_transaction',
          onDone: {
            target: 'idle',
          },
          onError: {
            target: 'idle',
          },
        },
      },

      errored: {},

      start_offline_processing: {
        tags: 'start_offline_processing',
        entry: 'send_offline_request',
        on: {
          OFFLINE_PROCESSED_SUCCESSFULLY: {
            target: 'end_offline_transaction_process',
            actions: 'record_offline_payment_process',
          },
        },
      },

      end_offline_transaction_process: {
        tags: 'end_offline_processing',
        invoke: {
          src: 'end_offline_transaction_process',
          onDone: {
            target: 'idle',
            actions: ['send_offline_transact_to_parent'],
          },
        },
      },

      end_offline_non_card_transaction_process: {
        tags: 'end_offline_processing',
        invoke: {
          src: 'end_offline_non_card_transaction_process',
          onDone: {
            target: 'idle',
            actions: ['send_offline_transact_to_parent'],
          },
        },
      },
    },
  },
  {
    guards: {
      has_device: (ctx) => ctx.device !== undefined,
    },
    services: {
      start_pay_transaction: async (ctx) => {
        if (ctx.pay_request === undefined) {
          throw new Error('Missing pay request');
        }
        const transaction_id = await ctx.location_db.transactions.add({
          request: ctx.pay_request.requestData,
          device: ctx.config,
          status: 'incomplete',
          time: new Date(),
          posOrderId: ctx.pay_request.requestData.order_id!,
        });
        return transaction_id;
      },
      end_pay_transaction: async (ctx, evt) => {
        if (ctx.transaction_id === undefined) {
          throw new Error('Missing transaction id');
        }

        const status = transaction_status(evt);
        const transaction_id =
          typeof evt.data === 'object' && 'info' in evt.data && evt.data.info.transactionId
            ? evt.data.info.transactionId
            : ctx.transaction_id;

        const primary_response: PaymentResponse<object> | undefined =
          typeof evt.data === 'object' && 'response' in evt.data ? evt.data : undefined;

        const isOffline = primary_response
          ? !primary_response.isOffline
          : onlineStateManager.getIsOnline();
        await ctx.location_db.transactions.update(ctx.transaction_id, {
          response: Object.assign({}, evt.data, {
            transactionId: transaction_id,
            isOffline: !isOffline,
          }),
          status,
        });
        return await ctx.location_db.transactions.get(ctx.transaction_id);
      },
      end_offline_transaction_process: async (ctx, evt) => {
        const transactions = await getTransactions(ctx);
        const offline_processed_records = evt.data?.response ?? [];
        const filteredTransactions = transactions
          .filter((x) => {
            return (
              x.status === 'paid' &&
              x.device.paymentMethod === PaymentMethod.credit &&
              x.response.isOffline
            );
          })
          .filter((x) => {
            for (let i = 0; i < offline_processed_records.length; i++) {
              if (
                x.status === 'paid' &&
                x.id &&
                x.response.info?.invoiceId === offline_processed_records[i].INVOICE &&
                offline_processed_records[i].CTROUTD &&
                offline_processed_records[i].TROUTD
              ) {
                x.CTROUTD = offline_processed_records[i].CTROUTD ?? '';
                x.TROUTD = offline_processed_records[i].TROUTD ?? '';
                return x;
              }
            }
          });

        const filteredNonCardTRansactions = transactions.filter((x) => {
          return (
            (x.status === 'paid' || x.status === 'notpaid') &&
            x.device.paymentMethod !== PaymentMethod.credit &&
            x.response.isOffline
          );
        });
        return filteredTransactions.concat(filteredNonCardTRansactions);
      },
      end_offline_non_card_transaction_process: async (ctx) => {
        const transactions = await getTransactions(ctx);
        const filteredTransactions = transactions.filter((x) => {
          return (
            (x.status === 'paid' || x.status === 'notpaid') &&
            x.device.paymentMethod !== PaymentMethod.credit &&
            x.response.isOffline
          );
        });
        return filteredTransactions;
      },
    },
    actions: {
      // log: log(),
      store_pay_request: assign({
        pay_request: (_, evt) => (evt.type === 'PAY' ? evt.data : undefined),
      }),
      clear_pay_request: assign({ pay_request: undefined }),
      store_refund_request: assign({
        //refund_request: (_, evt) => (evt.type === 'REFUND' ? evt.data : undefined),
      }),
      clear_refund_request: assign({ refund_request: undefined }),
      store_transaction_id: assign({
        transaction_id: (_, evt) => evt.data,
      }),
      clear_transaction_id: assign({ transaction_id: undefined }),
      send_pay: sendTo('device', (ctx) => ({ type: 'PAY', data: ctx.pay_request })),
      //send_refund: sendTo('device', (ctx) => ({ type: 'PAY', data: ctx.pay_request })),
      send_transact_to_parent: sendParent((_, e) =>
        routing_service.getSnapshot().hasTag('IN_PICKUP_PAYMENT_SESSION')
          ? { type: 'TRANSACTED_PENDING', data: e.data }
          : routing_service.getSnapshot().hasTag('PAY_AT_COUNTER')
            ? { type: 'TRANSACTED_TO_COUNTER', data: e.data }
            : { type: 'TRANSACTED', data: e.data }
      ),
      get_balance: sendTo('device', (_, e) => e),
      refund: sendTo('device', (_, e) => e),
      forward: sendTo('device', (_, e) => e),
      sendParent: sendParent((_, e) => e),
      send_offline_request: sendTo('device', () => {
        return { type: 'PROCESS_OFFLINE_TRANSACTION', data: undefined };
      }),
      ready_device: assign({
        device: (ctx) => {
          try {
            const device_service = ready_payment_machine(ctx);
            if (!device_service) return;
            return spawn(device_service, 'device');
          } catch {
            return undefined;
          }
        },
      }),
      record_pay_start: () => {
        gem_service.payment_start();
      },
      record_pay_invalid: (ctx) => {
        gem_service.payment_invalid(ctx.transaction_id?.toString(), ctx.pay_request);
      },
      record_pay_failed: (ctx, event) => {
        gem_service.payment_failed(ctx.transaction_id?.toString(), ctx.pay_request, event);
      },
      store_balance: assign({
        balance: (ctx, evt) => evt.data,
      }),
      reset_balance: assign({
        balance: undefined,
      }),
      record_pay_complete: (ctx) => {
        gem_service.payment_complete(ctx.transaction_id?.toString(), ctx.pay_request);
      },
      record_offline_payment_process: () => {
        gem_service.process_offline_payment();
      },
      send_offline_transact_to_parent: sendParent((_, e) => ({
        type: 'SUBMIT_OFFLINE_ORDER',
        data: e.data,
      })),
    },
  }
);

export type PaymentTransactionService = ActorRefFrom<typeof payment_transaction_machine>;
